var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    style: {
      zIndex: _vm.options.zIndex
    },
    attrs: {
      "max-width": _vm.options.width,
      "persistent": "",
      "retain-focus": false
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        !_vm.rejection && _vm.cancel;
      },
      "click:outside": function ($event) {
        !_vm.rejection && _vm.cancel;
      }
    },
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', {
    staticClass: "pa-5"
  }, [_c('v-card-text', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !!_vm.message,
      expression: "!!message"
    }],
    staticClass: "pa-4 text-h6 text-center"
  }, [_vm._v(" " + _vm._s(_vm.message) + " ")]), _vm.showHideMessage ? _c('v-checkbox', {
    staticClass: "pa-4",
    attrs: {
      "label": "Don't show this message again"
    },
    model: {
      value: _vm.hideDialog,
      callback: function ($$v) {
        _vm.hideDialog = $$v;
      },
      expression: "hideDialog"
    }
  }) : _vm._e(), _c('v-card-actions', {
    staticClass: "py-4 px-8 justify-center"
  }, [_vm.rejection ? _c('v-btn', {
    staticClass: "text-none text-subtitle-1 font-weight-medium px-2",
    attrs: {
      "large": "",
      "elevation": "0"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.cancel.apply(null, arguments);
      }
    }
  }, [_vm._v(" Cancel ")]) : _vm._e(), _vm.rejection ? _c('v-spacer') : _vm._e(), _c('v-btn', {
    staticClass: "text-none text-subtitle-1 font-weight-medium px-2",
    attrs: {
      "large": "",
      "color": _vm.options.color,
      "elevation": "0"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.agree.apply(null, arguments);
      }
    }
  }, [_vm.rejection ? _c('span', [_vm._v(" OK ")]) : _c('span', [_vm._v(" Yes ")])])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }