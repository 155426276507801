import user from 'codedrills_proto/io/codedrills/proto/user/user_service_grpc_web_pb'
import hire from 'codedrills_proto/io/codedrills/proto/user/hiring_service_grpc_web_pb'
import builder from 'codedrills_proto/io/codedrills/proto/builder/content_service_grpc_web_pb'
import site from 'codedrills_proto/io/codedrills/proto/site/content_view_service_grpc_web_pb'

import 'codedrills_proto/io/codedrills/proto/site/content_view_service_pb'
import { ApiCall } from '@/utils/api.js'
import customer from 'codedrills_proto/io/codedrills/proto/user/customer_service_grpc_web_pb'

const userService = new user.UserServicePromiseClient(
  process.env.VUE_APP_USER_API_URL + '/user',
  null,
  null,
)

const customerService = new customer.CustomerServicePromiseClient(
  process.env.VUE_APP_USER_API_URL + '/user',
  null,
  null,
)

const hiringService = new hire.HiringServicePromiseClient(
  process.env.VUE_APP_USER_API_URL + '/user',
  null,
  null,
)

const builderService = new builder.ContentServicePromiseClient(
  process.env.VUE_APP_BUILDER_API_URL + '/builder',
  null,
  null,
)
const siteService = new site.ContentViewServicePromiseClient(
  process.env.VUE_APP_SITE_API_URL + '/site',
  null,
  null,
)

var site_proto = proto.io.codedrills.proto.site
var user_proto = proto.io.codedrills.proto.user
var builder_proto = proto.io.codedrills.proto.builder
var content_proto = proto.io.codedrills.proto.content
var customer_proto = proto.io.codedrills.proto.user

const getDefaultState = () => ({
  recruiter: null,
  candidate: null,
  fetchRecruiterStatus: 0,
  assessment: null,
  assessmentView: null,
  fetchAssessmentStatus: 0,
  manageSubUrl: null,
  checkoutUrl: null,
  admins: null,
  allTests: null,
  skills: null,
  currentSkill: null,
  currentSkillStatus: null,
  allCandidates: null,
  listAdminStatus: null,
  candidateReport: null,
  fetchCandidateReportStatus: 0,
  createAssessmentResponse: null,
  fetchCustomerTestsStatus: 0,
  fetchCandidateStatus: 0,
  getRecruiterProfileStatus: 0,
  profileImageUrl: null,
  uploadUserImageStatus: 0,
  assessmentInviteBatchStatus: 0,
  createdAssessment: null,
  getVideoStatus: 0,
  archiveAssessmentStatus: 0,
  updateMetaDataStatus: 0,
  fetchQuestionStatus: 0,
  exportFullCandidateReportRequestStatus: 0,
})

const state = getDefaultState()

const getters = {
  cheatingSettings() {
    return Object.keys(content_proto.AntiCheatingSettings)
  },
  cheatingSettingsValues() {
    var ret = {}
    for (var key in content_proto.AntiCheatingSettings) {
      ret[key] = content_proto.AntiCheatingSettings[key]
    }
    return ret
  },
  //Todo: Fix this
  cheatingPrettyMessage() {
    return [
      'None Selected',
      'Disallow External Copy Paste',
      'Make Fullscreen',
      'Log Tab Change Events',
      'Take Camera Snapshots',
      'Take Video Recordings',
    ]
  },
  testStatuses() {
    return Object.keys(user_proto.TestStatus)
  },
  testStatusPrettyMessage() {
    // 0, 1, 2, 3
    return ['Unknown', 'Invited', 'In Progress', 'Completed']
  },
  testStatusColor() {
    return ['', 'grey', 'orange', 'primary']
  },
  testStages() {
    return Object.keys(user_proto.CandidateStage)
  },
  testStagesPretty() {
    const prettyStages = {}
    Object.keys(user_proto.CandidateStage).forEach((sk) => {
      const ps = Array.from(sk.split('_').pop())
        .map((c, i) => {
          return i === 0 ? c : c.toLowerCase()
        })
        .join('')
      prettyStages[sk] = ps
    })
    return prettyStages
  },
  testStagesSelector: (state, getters) => {
    const sel = []
    Object.entries(getters.testStagesPretty).forEach(([key, val]) => {
      sel.push({
        stage: key,
        text: val,
      })
    })
    return sel
  },
  addonKeys() {
    return Object.keys(customer_proto.AddOn)
  },
  adminRoles() {
    return Object.keys(user_proto.AdminRole)
  },
  adminRolesPretty() {
    return {
      UNKNOWN_ADMIN_ROLE: 'Admin',
      ADMIN_ROLE_ADMIN: 'Admin',
      ADMIN_ROLE_EDITOR: 'Editor',
      ADMIN_ROLE_READER: 'Reader',
    }
  },
  emailTypes() {
    return Object.keys(user_proto.EmailCandidateRequest.EmailType)
  },
  // getUploadUserImageStatus
  getProfileImageUrl(state) {
    return state.profileImageUrl
  },
  skillLevelKeys() {
    return Object.keys(content_proto.SkillLevel)
  },
  prettySkillLevels() {
    const prettyLevels = {}
    Object.keys(content_proto.SkillLevel).forEach((sk) => {
      const ps = Array.from(sk.split('_').pop())
        .map((c, i) => {
          return i === 0 ? c : c.toLowerCase()
        })
        .join('')
      prettyLevels[sk] = ps
    })
    return prettyLevels
  },
}

const actions = {
  listCustomerAdmins: new ApiCall('listCustomerAdmins')
    .authRequired()
    .withServiceCall((r, h) => customerService.listAdmins(r, h))
    .withRequest((_, __, { commit }) => {
      return new user_proto.ListAdminsRequest()
    })
    .onSuccess(({ commit }, res) => commit('setAdmins', res))
    .build(),

  fetchCustomerAllTest: new ApiCall('fetchCustomerAllTest')
    .authRequired()
    .withServiceCall((r, h) => hiringService.fetchCustomerAllTest(r, h))
    .withRequest((_, __, { commit }) => {
      return new user_proto.FetchCustomerAllTestRequest()
    })
    .onSuccess(({ commit }, res) =>
      commit('setAllTests', res?.getTestPreviewList()),
    )
    .build(),

  listSkills: new ApiCall('listSkills')
    .withServiceCall((r, h) => siteService.listContent(r, h))
    .withRequest(({ userId }) => {
      return new site_proto.ListContentRequest()
        .setContentType(content_proto.ContentType.SKILL)
        .setUserId(userId)
    })
    .onSuccess(({ commit }, res) => {
      commit('setSkills', res?.toObject()?.contentsList)
    })
    .build(),

  createAssessment: new ApiCall('createAssessment')
    .withServiceCall((r, h) => builderService.createContent(r, h))
    .withRequest(({ title }) => {
      return new builder_proto.CreateContentRequest()
        .setTitle(title)
        .setType(content_proto.ContentType.TEST)
        .setAutoPublish(true)
    })
    .onSuccess(({ commit }, res) => {
      commit('setAssessmentResponse', res?.getContent())
    })
    .build(),

  updateAssessment: new ApiCall('updateAssessment')
    .withServiceCall((r, h) => builderService.updateContent(r, h))
    .withRequest(
      ({
        about,
        skills,
        role,
        anticheat,
        enableLink,
        expiryEpoch,
        setMinScore,
        minScore,
        sendEmail,
        windowStartEpoch,
        windowEndEpoch,
        deadline,
      }) => {
        // skills is a set
        const acList = []
        anticheat.forEach((ac) => {
          const single =
            new content_proto.AntiCheating().setAntiCheatingSettings(ac)
          acList.push(single)
        })

        const testData = new content_proto.Test()
          .setAbout(about)
          .setRole(role)
          .setSkillsList(Array.from(skills))

        const testMeta = new content_proto.TestMeta()
          .setAntiCheatingList(acList)
          .setTestByUrlEnabled(enableLink)
          .setCandidateDeadline(
            new content_proto.CandidateDeadline().setCandidateDeadlineInSeconds(
              deadline,
            ),
          )

        if (enableLink) {
          testMeta.setTestUrlExpiry(new Date(expiryEpoch).getTime())
        }
        console.log(`setMinScore`, setMinScore, minScore)

        if (setMinScore) {
          if (testMeta.getAutoSelectSetting() == null) {
            testMeta.setAutoSelectSetting(new content_proto.AutoSelectSetting())
          }
          testMeta.getAutoSelectSetting().setMinScore(minScore)
          testMeta.getAutoSelectSetting().setAutoRejectEmail(sendEmail)
        }

        if (windowStartEpoch) {
          if (testMeta.getTestWindow() == null) {
            testMeta.setTestWindow(new content_proto.TestWindow())
          }
          console.log('test winow is ..', testMeta.getTestWindow())
          testMeta.getTestWindow().setStartAt(windowStartEpoch)
        }
        if (windowEndEpoch) {
          if (testMeta.getTestWindow() == null) {
            testMeta.setTestWindow(new content_proto.TestWindow())
          }
          console.log('test winow is ..', testMeta.getTestWindow())
          testMeta.getTestWindow().setEndAt(windowEndEpoch)
        }

        // console.log(`testData`, testData.toObject());
        return new builder_proto.UpdateContentRequest()
          .setId(state?.createAssessmentResponse?.getId())
          .setTitle(state?.createAssessmentResponse?.getTitle())
          .setVersion(state?.createAssessmentResponse?.getVersion())
          .setData(new content_proto.Data().setTest(testData))
          .setMetaData(new content_proto.MetaData().setTestMeta(testMeta))
          .setContentType(content_proto.ContentType.TEST)
          .setPublishType(content_proto.PublishType.PRIVATE)
          .setAutoPublish(true)
      },
    )
    .onSuccess(({ commit }, res) => {
      commit('setCreatedAssessment', res.getContent())
    })
    .build(),

  archiveAssessment: new ApiCall('archiveAssessment')
    .withServiceCall((r, h) => builderService.archiveContent(r, h))
    .withRequest(({ url, type }) => {
      return new builder_proto.ArchiveContentRequest().setContentId(
        new content_proto.ContentId().setContentAddress(
          new content_proto.ContentAddress()
            .setUrl(url)
            .setContentType(content_proto.ContentType.TEST),
        ),
      )
    })
    .onSuccess(({ commit }, res) => {
      console.log('Successful archieveAssessment....', res)
    })
    .build(),

  fetchAssessment: new ApiCall('fetchAssessment')
    .withServiceCall((r, h) => siteService.getContentView(r, h))
    .withRequest(({ url }) => {
      return new site_proto.GetContentViewRequest().setContentId(
        new content_proto.ContentId().setContentAddress(
          new content_proto.ContentAddress()
            .setUrl(url)
            .setContentType(content_proto.ContentType.TEST),
        ),
      )
    })
    .onSuccess(({ commit }, res) => {
      commit('setAssessment', res.getContentView())
      commit('setAssessmentView', res.getContentView())
      console.log('Got assessment', res?.toObject())
    })
    .build(),

  fetchSkill: new ApiCall('fetchSkill')
    .withServiceCall((r, h) => siteService.getContentView(r, h))
    .withRequest(({ id }) => {
      return new site_proto.GetContentViewRequest().setContentId(
        new content_proto.ContentId().setId(id),
      )
    })
    .onSuccess(({ commit }, res) => {
      console.log(res.getContentView()?.toObject())
      commit('setCurrentSkill', res?.getContentView())
      return res.getContentView()
    })
    .build(),

  fetchQuestion: new ApiCall('fetchQuestion')
    .withServiceCall((r, h) => siteService.getContentView(r, h))
    .withRequest(({ skillId, questionId }) => {
      return new site_proto.GetContentViewRequest()
        .setContentId(new content_proto.ContentId().setId(questionId))
        .setParentId(new content_proto.ContentId().setId(skillId))
    })
    .build(),

  fetchCandidatesForTest: new ApiCall('fetchCandidatesForTest')
    .withServiceCall((r, h) => hiringService.getCandidatesForTest(r, h))
    .withRequest(({ id }) => {
      return new user_proto.GetCandidatesForTestRequest().setTestId(
        new content_proto.ContentId().setId(id),
      )
    })
    .onSuccess(({ commit }, res) => {
      commit('setAllCandidates', res?.getCandidatePreviewsList())
    })
    .build(),

  assessmentInvite: new ApiCall('assessmentInvite')
    .withServiceCall((r, h) => hiringService.testInvite(r, h))
    .withRequest(({ id, candidates, duplicatehandling }) => {
      const candidateList = []
      candidates.forEach((candidate) => {
        const invite = new user_proto.NameEmail()
          .setName(candidate.name)
          .setEmail(candidate.email)
        candidateList.push(invite)
      })

      return new user_proto.TestInviteRequest()
        .setTestId(new content_proto.ContentId().setId(id))
        .setNameEmailList(candidateList)
        .setDuplicateCandidateHandling(duplicatehandling)
    })
    .onSuccess(({ commit }, res) => {
      console.log()
    })
    .build(),

  assessmentInviteBatch: new ApiCall('assessmentInviteBatch')
    .withServiceCall((r, h) => hiringService.testInviteBatch(r, h))
    .withRequest(({ candidatesCsv, id }) => {
      return new user_proto.TestInviteBatchRequest()
        .setTestId(new content_proto.ContentId().setId(id))
        .setCandidatesCsv(candidatesCsv)
    })
    .onSuccess(({ commit }, res) => {
      // same as above
      console.log('Invited candidates', res)
    })
    .build(),

  fetchCandidateReport: new ApiCall('fetchCandidateReport')
    .withServiceCall((r, h) => hiringService.fetchCandidateReport(r, h))
    .withRequest(({ cid, testId }) => {
      return new user_proto.FetchCandidateReportRequest()
        .setTestId(new content_proto.ContentId().setId(testId))
        .setCandidateId(cid)
    })
    .onSuccess(({ commit }, res) => {
      commit('setCandidateReport', res?.getCandidateReport())
    })
    .build(),

  exportFullCandidateReport: new ApiCall('exportFullCandidateReportRequest')
    .withServiceCall((r, h) => hiringService.exportFullCandidateReport(r, h))
    .withRequest(({ url, candidateIds }) => {
      return new user_proto.ExportFullCandidateReportRequest()
        .setTestId(
          new content_proto.ContentId().setContentAddress(
            new content_proto.ContentAddress()
              .setUrl(url)
              .setContentType(content_proto.ContentType.TEST),
          ),
        )
        .setUserIdList(candidateIds)
    })
    .onSuccess(({ commit }, res) => {
      console.log('Exported full candidate report', res)
    })
    .build(),

  updateCandidateRating: new ApiCall('updateCandidateRating')
    .withServiceCall((r, h) => hiringService.updateCandidateRating(r, h))
    .withRequest(({ cid, testId, rating }) => {
      return new user_proto.UpdateCandidateRatingRequest()
        .setTestId(new content_proto.ContentId().setId(testId))
        .setCandidateId(cid)
        .setRating(rating)
    })
    .onSuccess(({ commit }, res) => {
      console.log(`Updated Rating`)
    })
    .build(),

  updateCandidateNotes: new ApiCall('updateCandidateNotes')
    .withServiceCall((r, h) => hiringService.updateCandidateNotes(r, h))
    .withRequest(({ cid, testId, note }) => {
      return new user_proto.UpdateCandidateNotesRequest()
        .setTestId(new content_proto.ContentId().setId(testId))
        .setCandidateId(cid)
        .setNotes(note)
    })
    .onSuccess(({ commit }, res) => {
      console.log(`Updated Candidate Note`)
    })
    .build(),

  assignCandidateScore: new ApiCall('assignCandidateScore')
    .withServiceCall((r, h) => hiringService.assignCandidateScore(r, h))
    .withRequest(({ cid, testId, qid, score }) => {
      return new user_proto.AssignCandidateScoreRequest()
        .setTestId(new content_proto.ContentId().setId(testId))
        .setCandidateId(cid)
        .setQuestionId(new content_proto.ContentId().setId(qid))
        .setAchievedScore(score)
    })
    .onSuccess(({ commit }, res) => {})
    .build(),

  updateCandidateStage: new ApiCall('updateCandidateStage')
    .withServiceCall((r, h) => hiringService.updateCandidateStage(r, h))
    .withRequest(({ cid, testId, stage }) => {
      return new user_proto.UpdateCandidateStageRequest()
        .setTestId(new content_proto.ContentId().setId(testId))
        .setCandidateId(cid)
        .setStage(user_proto.CandidateStage[stage])
    })
    .onSuccess(({ commit }, res) => {
      commit('setCandidate', res?.getCandidatePreview())
    })
    .build(),
  inviteAdmin: new ApiCall('inviteAdmin')
    .withServiceCall((r, h) => customerService.inviteAdmin(r, h))
    .withRequest(({ email }) => {
      return new user_proto.InviteAdminRequest().setAdmin(
        new user_proto.Admin()
          .setEmail(email)
          .setRole(user_proto.AdminRole.ADMIN_ROLE_ADMIN),
      )
    })
    .onSuccess(({ commit }, res) => {
      console.log(`Invited`)
    })
    .build(),
  deleteAdmin: new ApiCall('deleteAdmin')
    .withServiceCall((r, h) => customerService.deleteAdmin(r, h))
    .withRequest(({ email }) => {
      return new user_proto.DeleteAdminRequest().setEmail(email)
    })
    .onSuccess(({ commit }, res) => {
      console.log(`Deleted`)
    })
    .build(),

  emailCandidate: new ApiCall('emailCandidate')
    .withServiceCall((r, h) => hiringService.emailCandidate(r, h))
    .withRequest(({ testId, cid, etype }) => {
      console.log(
        { testId, cid, etype },
        user_proto.EmailCandidateRequest.EmailType[etype],
      )
      return new user_proto.EmailCandidateRequest()
        .setTestId(new content_proto.ContentId().setId(testId))
        .setCandidateId(cid)
        .setEmailType(user_proto.EmailCandidateRequest.EmailType[etype])
    })
    .onSuccess(({ commit }, res) => {
      console.log('Emailed Candidate')
    })
    .build(),

  updateRecruiterProfile: new ApiCall('updateRecruiterProfile')
    .authRequired()
    .withServiceCall((r, h) => customerService.updateRecruiterProfile(r, h))
    .withRequest((_, __, { commit }) => {
      return new customer_proto.UpdateRecruiterProfileRequest().setAdmin(
        state.recruiter,
      )
    })
    .onSuccess(({ commit }, res) => console.log('Success'))
    .build(),

  fetchRecruiterProfile: new ApiCall('fetchRecruiterProfile')
    .withServiceCall((r, h) => customerService.getRecruiterProfile(r, h))
    .withRequest(() => {
      return new user_proto.GetRecruiterProfileRequest()
    })
    .onSuccess(({ commit }, res) => {
      console.log(res.toObject())
      commit('setRecruiter', res?.getAdmin())
    })
    .build(),

  uploadUserImage: new ApiCall('uploadUserImage')
    .authRequired()
    .withServiceCall((r, h) => userService.uploadUserImage(r, h))
    .withRequest(({ image }) => {
      // console.log('Data at API', image);
      var req = new user_proto.UploadUserImageRequest()
        .setData(image)
        .setType('image/png')
      return req
    })
    .onSuccess(({ commit }, res) =>
      commit('setProfileImage', res?.getImageUrl()),
    )
    .build(),

  getCustomer: new ApiCall('getCustomer')
    .authRequired()
    .withServiceCall((r, h) => customerService.getCustomer(r, h))
    .withRequest((_, __, { commit }) => {
      return new user_proto.GetCustomerRequest()
    })
    .onSuccess(({ commit }, res) => commit('setCustomer', res))
    .build(),

  initiateCheckout: new ApiCall('initiateCheckout')
    .authRequired()
    .withServiceCall((r, h) => customerService.checkout(r, h))
    .withRequest(({ planType, planFreq, addonsList }, __, { commit }) => {
      console.log({ planType, planFreq, addonsList })
      const selectedAddonsList = []
      addonsList.forEach((addon) => {
        const selected = new customer_proto.AddOnPlan()
          .setAddOn(customer_proto.AddOn[addon.id])
          .setFrequency(user_proto.PlanFrequency[planFreq])
        selectedAddonsList.push(selected)
      })
      return new user_proto.CheckoutRequest()
        .setHiringPlan(
          new user_proto.HiringPlan()
            .setPlanType(user_proto.HiringPlanType[planType])
            .setFrequency(user_proto.PlanFrequency[planFreq]),
        )
        .setAddOnPlanList(selectedAddonsList)
    })
    .onSuccess(({ commit }, res) => {
      console.log(`init check`)
      commit('setCheckoutUrl', res?.getRedirectUrl())
    })
    .build(),

  manageSubscription: new ApiCall('manageSubscription')
    .authRequired()
    .withServiceCall((r, h) => customerService.managePayment(r, h))
    .withRequest((_, __, { commit }) => {
      return new user_proto.ManagePaymentRequest()
    })
    .onSuccess(({ commit }, res) => {
      commit('setSubUrl', res?.getRedirectUrl())
    })
    .build(),

  testInviteByUrl: new ApiCall('testInviteByUrl')
    .authOptional()
    .withServiceCall((r, h) => hiringService.testInviteByUrl(r, h))
    .withRequest(({ name, email, url }, __, { commit }) => {
      return new customer_proto.TestInviteByUrlRequest()
        .setTestId(
          new content_proto.ContentId().setContentAddress(
            new content_proto.ContentAddress()
              .setUrl(url)
              .setContentType(content_proto.ContentType.TEST),
          ),
        )
        .setEmail(email)
        .setName(name)
    })
    .onSuccess(({ commit }, res) => {
      console.log('Invited successfully')
    })
    .build(),

  getVideo: new ApiCall('getVideo')
    .authRequired()
    .withServiceCall((r, h) => hiringService.getVideo(r, h))
    .withRequest(({ url, testId, candidateId }) => {
      console.log('Video is ..' + url)
      var req = new customer_proto.GetVideoRequest()
      req.setVideoUrl(url)
      req.setCandidateId(candidateId)
      req.setTestId(new content_proto.ContentId().setId(testId))
      // console.log("Video req is .." + req);
      return req
    })
    .onSuccess(({ commit }, res) => {
      // console.log("Video got successfully", res);
      return res
    })
    .build(),

  updateMetaData: new ApiCall('updateMetaData')
    .withServiceCall((r, h) => builderService.updateMetaData(r, h))
    .withRequest(({ url }) => {
      return new builder_proto.UpdateMetaDataRequest()
        .setMetaData(state.assessment.getMetaData())
        .setContentId(
          new content_proto.ContentId().setContentAddress(
            new content_proto.ContentAddress()
              .setUrl(url)
              .setContentType(content_proto.ContentType.TEST),
          ),
        )
    })
    .build(),

  downloadCandidatesReport: new ApiCall('downloadCandidatesReport')
    .withServiceCall((r, h) => hiringService.downloadCandidatesReport(r, h))
    .withRequest(({ url }) => {
      return new user_proto.DownloadCandidatesReportRequest().setTestId(
        new content_proto.ContentId().setContentAddress(
          new content_proto.ContentAddress()
            .setUrl(url)
            .setContentType(content_proto.ContentType.TEST),
        ),
      )
    })
    .onSuccess(({ commit }, res) => {
      console.log('Downloaded candidates report ...', res)
      return res
    })
    .build(),
}

const mutations = {
  setRecruiter(state, res) {
    state.recruiter = res
  },
  fetchRecruiterProfileStatus(state, status) {
    state.fetchRecruiterStatus = status
  },
  setAdmins(state, list) {
    state.admins = list
  },
  listCustomerAdminsStatus(state, status) {
    state.listAdminStatus = status
  },
  setAllTests(state, allTests) {
    state.allTests = allTests
  },
  fetchCustomerAllTestStatus(state, status) {
    state.fetchCustomerTestsStatus = status
  },
  setSkills(state, skills) {
    state.skills = skills
  },
  setAssessment(state, asmt) {
    state.assessment = asmt
  },
  setAssessmentView(state, aview) {
    state.assessmentView = aview
  },
  setAllCandidates(state, cds) {
    state.allCandidates = cds
  },
  setCandidate(state, cand) {
    state.candidate = cand
  },
  setCandidateReport(state, report) {
    state.candidateReport = report
  },
  setCurrentSkill(state, skill) {
    state.currentSkill = skill
  },
  setSubUrl(state, url) {
    state.manageSubUrl = url
  },
  setCheckoutUrl(state, url) {
    state.checkoutUrl = url
  },
  listSkillsStatus(state, status) {
    // @TODO: add status
  },
  setAssessmentResponse(state, res) {
    state.createAssessmentResponse = res
  },
  createAssessmentStatus(state, status) {
    // @TODO: status
  },
  updateAssessmentStatus(state, status) {
    // @TODO: status
  },
  fetchAssessmentStatus(state, status) {
    state.fetchAssessmentStatus = status
  },
  assessmentInviteStatus(state, status) {
    // @TODO: assessmentInvite status
  },
  fetchCandidatesForTestStatus(state, status) {
    state.fetchCandidateStatus = status
  },
  fetchCandidateReportStatus(state, status) {
    state.fetchCandidateReportStatus = status
  },
  updateCandidateRatingStatus(state, status) {
    //
  },
  updateCandidateStageStatus(state, status) {
    //
  },
  updateCandidateNotesStatus(state, status) {
    //
  },
  assignCandidateScoreStatus(state, status) {
    //
  },
  deleteAdminStatus(state, status) {},
  inviteAdminStatus(state, status) {},
  emailCandidateStatus(state, status) {},
  setRecruiterProfileX(state, res) {
    state.profileImageUrl = state.recruiter.getImageUrl()
  },
  setName(state, name) {
    state.recruiter.setName(name)
  },
  setDescription(state, description) {
    state.recruiter.setAbout(description)
  },
  setProfileImage(state, image) {
    state.profileImageUrl = image
  },
  updateRecruiterProfileStatus(state, status) {
    state.updateRecruiterProfileStatus = status
  },
  uploadUserImageStatus(state, status) {
    state.uploadUserImageStatus = status
  },
  getRecruiterProfileStatus(state) {
    return state.getRecruiterProfileStatus
  },
  setProfileImageUrl(state, url) {
    state.profileImageUrl = url
  },
  resetProfileImageUrl(state) {
    state.profileImageUrl = state.recruiter.getImageUrl()
  },
  fetchSkillStatus(state, status) {
    state.currentSkillStatus = status
  },
  manageSubscriptionStatus(state, status) {
    //
  },
  initiateCheckoutStatus(state, status) {},
  resetRecruiterState(state) {
    Object.assign(state, getDefaultState())
  },
  assessmentInviteBatchStatus(state, status) {
    state.assessmentInviteBatch = status
  },
  testInviteByUrlStatus(state, status) {
    //
  },
  setCreatedAssessment(state, res) {
    state.createdAssessment = res
  },
  getVideoStatus(state, status) {
    state.getVideoStatus = status
  },
  archiveAssessmentStatus(state, status) {
    state.archiveAssessmentStatus = status
  },
  setExpiry(state, expiry) {
    state.assessment.getMetaData().getTestMeta().setTestByUrlEnabled(true)
    state.assessment
      .getMetaData()
      .getTestMeta()
      .setTestUrlExpiry(expiry * 1000)
  },
  setMinScore(state, minScore) {
    if (
      state.assessment.getMetaData().getTestMeta().getAutoSelectSetting() ==
      null
    ) {
      state.assessment
        .getMetaData()
        .getTestMeta()
        .setAutoSelectSetting(new content_proto.AutoSelectSetting())
    }
    state.assessment
      .getMetaData()
      .getTestMeta()
      .getAutoSelectSetting()
      .setMinScore(minScore)
  },
  setSendEmail(state, sendEmail) {
    if (
      state.assessment.getMetaData().getTestMeta().getAutoSelectSetting() ==
      null
    ) {
      state.assessment
        .getMetaData()
        .getTestMeta()
        .setAutoSelectSetting(new content_proto.AutoSelectSetting())
    }
    state.assessment
      .getMetaData()
      .getTestMeta()
      .getAutoSelectSetting()
      .setAutoRejectEmail(sendEmail)
  },
  setTestUrlEnabled(state, enabled) {
    state.assessment.getMetaData().getTestMeta().setTestByUrlEnabled(enabled)
    if (!enabled) {
      state.assessment.getMetaData().getTestMeta().setTestUrlExpiry(0)
    }
    console.log(`Test url expiry`, state.assessment.getMetaData().getTestMeta())
  },
  setTestStartAt(state, startAt) {
    if (state.assessment.getMetaData().getTestMeta().getTestWindow() == null) {
      state.assessment
        .getMetaData()
        .getTestMeta()
        .setTestWindow(new content_proto.TestWindow())
    }
    state.assessment
      .getMetaData()
      .getTestMeta()
      .getTestWindow()
      .setStartAt(startAt)
    console.log(
      `Test start at`,
      state.assessment.getMetaData().getTestMeta().toObject(),
    )
  },
  setTestEndAt(state, startAt) {
    if (state.assessment.getMetaData().getTestMeta().getTestWindow() == null) {
      state.assessment
        .getMetaData()
        .getTestMeta()
        .setTestWindow(new content_proto.TestWindow())
    }
    state.assessment
      .getMetaData()
      .getTestMeta()
      .getTestWindow()
      .setEndAt(startAt)
    console.log(
      `Test start at`,
      state.assessment.getMetaData().getTestMeta().toObject(),
    )
  },
  setTestDeadline(state, { deadlineInSeconds }) {
    console.log('Setting deadline', deadlineInSeconds)

    state.assessment
      .getMetaData()
      .getTestMeta()
      .setCandidateDeadline(
        new content_proto.CandidateDeadline().setCandidateDeadlineInSeconds(
          deadlineInSeconds,
        ),
      )

    console.log(`Deadline set in seconds: ${deadlineInSeconds}`)
  },

  updateMetaDataStatus(state, status) {
    state.updateMetaDataStatus = status
  },
  fetchQuestionStatus(state, status) {
    state.fetchQuestionStatus = status
  },
  exportFullCandidateReportRequestStatus(state, status) {
    state.exportFullCandidateReportRequestStatus = status
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
