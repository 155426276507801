<template>
  <div class="mt-n8">
    <Navbar />
    <v-row align="center" justify="center" class="py-16">
      <v-col cols="12" md="10" lg="8">
        <v-card class="px-4 px-lg-16 py-16">
          <v-card-title class="text-h5 font-weight-medium black--text">
            {{ title }}
          </v-card-title>
          <v-card-subtitle class="text-h6 px-6 font-weight-medium black--text">
            <v-row>
              <v-col :cols="4">
                <v-icon color="primary">{{ mdiAlarm }} </v-icon>Test duration:
                {{ this.testDuration }}
              </v-col>
              <v-col :cols="4">
                <v-icon class="mr-2">{{ mdiInformation }}</v-icon>
                Total Questions: {{ this.testQuestions }}
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                Question Details
                <v-simple-table>
                  <thead>
                    <tr>
                      <th>Order</th>
                      <th>Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(question, index) in questions" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td>
                        <v-icon>{{ question.icon }}</v-icon> {{ question.type }}
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                Read the instructions carefully before you start the assessment!
              </v-col>
            </v-row>
          </v-card-subtitle>
          <v-card-text class="px-8">
            <v-row class="px-5">
              <ul class="text-h6 black--text px-1 pb-1">
                <li class="my-1">Before you start</li>
                <ul class="text-subtitle-1 black--text px-1">
                  <li>
                    Make sure you are using
                    <b
                      >Google Chrome
                      <v-icon>$custom</v-icon>
                    </b>
                  </li>
                  <li>
                    Disable all <b>ad-blockers</b>
                    <v-icon>$adblocker</v-icon>
                    for the duration of the test
                  </li>
                  <li>We recommend completing the assessment in one go.</li>
                  <li>Make sure you have a stable internet connection</li>
                  <li>
                    We recommend you to attempt this
                    <a
                      href="https://codedrills.io/signup/test-gbtf4z/testInvite"
                      >sample assessment</a
                    >
                    before starting this assessment to familiarize yourself with
                    the platform.
                  </li>
                </ul>
                <li class="my-1">About the assessment</li>
                <ul class="text-subtitle-1 black--text px-1">
                  <li>
                    This assessment is <b>timed</b>. A timer for overall
                    assessment is shown at the top right.
                  </li>
                  <li>
                    <b>Each question</b> is also <b>timed</b>. A timer per
                    question is shown as well.
                  </li>
                  <li>
                    Once you click <b>Start Assessment</b>, the timer starts for
                    the defined duration.
                  </li>
                  <li>
                    Once started, the timer
                    <b>can not be paused or restarted </b>.
                  </li>
                  <li>
                    During the assessment, you can only move to the next
                    question by pressing the <b>Next</b> button. Previous
                    questions <b>can not </b> be visited again.
                  </li>
                  <li>
                    The assessment will
                    <b
                      >end automatically when the last question is submitted
                    </b>
                  </li>
                </ul>
                <li class="my-1">Coding problem</li>
                <ul class="text-subtitle-1 black--text px-1">
                  <li>
                    Your assessment may contain one or more coding problems. You
                    can submit code <b>multiple times</b> using the
                    <b>Submit</b> button, and only the best result will be
                    considered.
                  </li>
                  <!-- <li>
                    There maybe SQL problems. SQL problems are considered coding
                    problems with language restriction to only SQL languages. For
                    such problems, you need to write SQL queries as the code.
                  </li> -->
                  <li>
                    The programming language might be <b>restricted</b>, in case
                    there is any restriction, you would be able to submit only
                    in those languages.
                  </li>
                  <li>
                    <b>Run Code</b> button is only for running your code against
                    sample test cases and it is not considered for scoring.
                  </li>
                  <li>
                    <b>Submit</b> button is for making a valid submission and it
                    runs on hidden test cases; on success it shows score based
                    on how many test cases passed.
                  </li>
                  <li>
                    The highest scoring submission out of all the submissions
                    made with 'Submit' button is considered as your answer.
                  </li>
                  <li>
                    On clicking the <b>Next</b> button, all the submissions made
                    with <b>Submit</b> button would <b>automatically</b> be
                    considered for scoring.
                  </li>
                  If there is no submission made with
                  <b>Submit</b>
                  button, the current code in the code editor will not be
                  auto-submitted and not considered for scoring. On clicking
                  <b>Next</b>
                  without any submissions, the problem would be considered as
                  skipped.
                </ul>
                <li class="my-1">MCQs (Multiple Choice Questions)</li>
                <ul class="text-subtitle-1 black--text px-1">
                  <li>There is no negative marking for wrong answers</li>
                  <li>There maybe one or more than one correct options.</li>
                  <li>
                    On clicking the <b>Next</b> button, chosen answers for MCQ
                    will be <b>submitted automatically</b>.
                  </li>
                  <li>
                    If the <b>Next</b> button is clicked without selecting any
                    options then the problem is considered as skipped.
                  </li>
                </ul>
              </ul>
            </v-row>
            <v-row v-if="isVideoEnabled">
              <v-col cols="12">
                <p class="mb-4">
                  <strong>Video and Audio Preview:</strong> Before you start the
                  assessment, we will guide you through a quick video and audio
                  preview to ensure that your camera and microphone are working
                  properly.
                </p>
              </v-col>
              <v-col>
                <div class="mt-n8">
                  <v-container class="py-4">
                    <v-row align="center" justify="center">
                      <v-col cols="12" md="8">
                        <v-card class="px-4 px-lg-8 py-8">
                          <Video ref="videoComponent" />
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                </div>
              </v-col>
              <v-col cols="12">
                <strong>Note:</strong> We will be recording both video and audio
                during this assessment for security and monitoring purposes.
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-checkbox
                  v-model="agreeToTOS"
                  :error-messages="TOSError"
                  @change="validateTOS"
                  :rules="[(v) => !!v || 'You must agree to continue!']"
                >
                  <template v-slot:label>
                    <div v-if="isVideoEnabled">
                      I hereby give my consent to the recording, storage, and
                      processing of my audio and video data for the purpose of
                      conducting the assessment.
                    </div>
                    <div>
                      I have also read all the rules clearly and will follow
                      them during the assessment. I will not use any means of
                      cheating. If found cheating during the assessment, I
                      understand that I will be banned from the CodeDrills
                      platform.
                    </div>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-center mt-8">
            <v-btn
              :disabled="!agreeToTOS"
              @click="start"
              elevation="0"
              :color="brandColor"
              :loading="startLoading"
              class="white--text text-h6 pa-5 text-none"
            >
              Start Assessment
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import moment, { duration } from 'moment'
import Video from './Video.vue'

import {
  mdiTimer,
  mdiVideoOff,
  mdiAlarm,
  mdiVideo,
  mdiCloseBoxOutline,
  mdiInformation,
  mdiCodeBraces,
  mdiCheckboxMultipleMarked,
  mdiListStatus,
  mdiGestureTapButton,
} from '@mdi/js'
import { toggleFullScreen } from '../utils/helper'
import InstructionLabel from '../components/assessment/InstructionLabel.vue'
import Navbar from '../components/Navbar.vue'
import formattedTimeMixin from '@/mixins/formattedTimeMixin'
import { mdiAlert } from '@mdi/js'
import 'codedrills_proto/io/codedrills/proto/content/content_pb'

const ContentType = proto.io.codedrills.proto.content.ContentType

export default {
  props: {
    testView: {
      type: Object,
      required: true,
    },
  },
  mixins: [formattedTimeMixin],
  data() {
    return {
      brandColor: '#2EAC68',
      brandError: '#D30001',
      mdiVideoOff,
      mdiAlarm,
      mdiVideo,
      mdiTimer,
      mdiCloseBoxOutline,
      mdiInformation,
      mdiCodeBraces,
      mdiCheckboxMultipleMarked,
      mdiListStatus,
      mdiGestureTapButton,
      startLoading: false,
      isTestStarted: false,
      rules: [
        'This assessment is timed. A timer for overall assessment is shown at the top right.',
        'Each question is also timed. A timer per question is shown as well.',
        "Once you click 'Start Assessment', the timer starts for the defined duration.",
        'Once started, the timer can not be paused or restarted.',
        "During the assessment, you can only move to the next question by 'Next' button. Previous questions can not be visited again.",
        'The assessment will end automatically when the last question is submitted.',
        'Make sure you are using a chromium-based browser, e.g. **Google Chrome** .',
        "Your assessment may contain one or more coding problems. You can submit code multiple times using the 'Submit' button, and only the best result will be considered.",
        'There maybe SQL problems. SQL problems are considered coding problems with language restriction to only SQL languages. For such problems, you need to write SQL queries as the code.',
        'For coding problems - the language might be restricted, in case there is any restriction, you would be able to submit only in those languages.',
        "For coding problems - 'Run Code' button is only for running your code against sample test cases and it is not considered for scoring.",
        "For coding problems - 'Submit' button is for making a valid submission and it runs on hidden test cases; on success it shows score based on how many test cases passed.",
        "For coding problems - the highest scoring submission out of all the submissions made with 'Submit' button is considered as your answer.",
        "For coding problems - on clicking the 'Next' button, all the submissions made with 'Submit' button would automatically be considered for scoring.",
        "For coding problems - if there is no submission made with 'Submit' button, the current code in the code editor will not be auto-submitted and not considered for \
        scoring. On clicking 'Next' without any submissions, the problem would be considered as skipped.",
        'For MCQ problems - there maybe one or more than one correct options.',
        "For MCQ problems - on clicking the 'Next' button, chosen answers for MCQ will be submitted automatically.",
        "For MCQ problems - if the 'Next' button is clicked without selecting any options then the problem is considered as skipped.",
        'We recommend completing the assessment in one go.',
      ],
      agreeToTOS: undefined,
      consentGiven: undefined,
      TOSError: [],
      signUpForm: false,
      mdiAlert: mdiAlert,
    }
  },
  computed: {
    ...mapState('user', ['hasPremiumAccess', 'editorConfig', 'user']),
    ...mapState('candidate', ['currentTestView']),
    title() {
      return (
        this.testView?.getTestView()?.getTestPreview()?.getTitle() ??
        'Unknown Title'
      )
    },
    testDuration() {
      return this.displayTime(
        this.testView?.getTestView()?.getTestTimeInfo()?.getDuration(),
      )
    },
    durationInHours() {
      return Math.floor(this.duration / 3600)
    },
    durationInMinutes() {
      return Math.floor((this.duration % 3600) / 60)
    },
    durationInSeconds() {
      return Math.round(this.duration % 60)
    },
    isVideoEnabled() {
      var anticheatings = this.testView
        ?.getTestView()
        ?.getTestPreview()
        ?.getMetaData()
        ?.getTestMeta()
        ?.getAntiCheatingList()
      var isVideoRecordingEnabled = anticheatings?.some(
        (a) => a.getAntiCheatingSettings() == 5,
      )
      return isVideoRecordingEnabled
    },
    testQuestions() {
      return this.testView?.getTestView()?.getTotalQuestions()
    },
    questions() {
      return this.testView
        ?.getTestView()
        ?.getQuestionPreviewsList()
        .map((q) => {
          switch (q.getContentType()) {
            case ContentType.PROBLEM:
              return {
                type: 'Coding Question',
                icon: mdiCodeBraces,
              }
            case ContentType.MCQ:
              return {
                type: 'Multiple Choice Question',
                icon: mdiListStatus,
              }
            case ContentType.SUBJECTIVE:
              return {
                type: 'Subjective Question',
                icon: mdiGestureTapButton,
              }
          }
        })
    },
  },
  methods: {
    ...mapActions('candidate', ['startHiringTest']),
    questionType(question) {},
    validateTOS() {
      if (!this.agreeToTOS || this.consentGiven) {
        this.TOSError.push('You must agree to continue!')
        return false
      } else {
        this.TOSError = []
        return true
      }
    },
    startVideoPreview() {
      return this.$refs.videoComponent.showVideoPreview()
    },
    start() {
      if (this.isVideoEnabled) this.$refs.videoComponent.stopRecorder()
      this.startLoading = true
      this.startHiringTest({
        test_id: this.currentTestView.getTestView().getTestPreview().getId(),
      })
        .then((__) => {
          this.startLoading = false
          // window.location.reload();
          const d = document?.documentElement
          if (d.requestFullscreen) {
            d.requestFullscreen()
          } else if (d.mozRequestFullScreen) {
            d.mozRequestFullScreen()
          } else if (d.webkitRequestFullScreen) {
            d.webkitRequestFullScreen()
          } else if (d.msRequestFullscreen) {
            d.msRequestFullscreen()
          }
        })
        .catch((error) => {
          this.startLoading = false
          console.log(error)
          var errorMessage = error.message
          if (
            error.code === 14 &&
            error.message.includes('upstream connect error')
          ) {
            errorMessage = 'An error occurred. Please try again.'
          }
          this.$store.dispatch('notifs/addNotif', {
            text: errorMessage,
            type: 'error',
          })
        })
    },
  },
  created() {
    document.title = `${this.title} - CodeDrills`
  },
  mounted() {
    if (this.isVideoEnabled) {
      this.$nextTick(() => {
        this.startVideoPreview()
      })
    }
  },
  components: {
    InstructionLabel,
    Navbar,
    Video,
  },
}
</script>
<style scoped>
li::marker {
  color: #2eac68;
}
</style>
