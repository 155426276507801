var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.videoVisible ? _c('div', [_c('div', {
    staticClass: "video-container"
  }, [_c('video', {
    ref: "video",
    attrs: {
      "controls": "",
      "autoplay": "",
      "playsinline": ""
    }
  }), _c('button', {
    on: {
      "click": _vm.closeVideo
    }
  }, [_vm._v("Close Video")])])]) : _vm._e(), _vm.showPermissionGuide ? _c('div', {
    staticClass: "permission-guide"
  }, [_c('v-icon', {
    attrs: {
      "color": _vm.brandError
    }
  }, [_vm._v(_vm._s(_vm.mdiVideoOff))]), _c('p', {
    staticStyle: {
      "white-space": "pre-line"
    }
  }, [_vm._v(_vm._s(_vm.permissionGuideMessage))]), _c('p', [_vm._v("Please refresh the page after updating the settings.")])], 1) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }