var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page', {
    attrs: {
      "title": "Settings",
      "apiStatusList": [_vm.getMailSubscriptionsStatus]
    }
  }, [_c('v-tabs', {
    attrs: {
      "vertical": _vm.$vuetify.breakpoint.smAndDown,
      "color": "accent",
      "align-with-title": ""
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab', [_vm._v(" Email subscription ")])], 1), _c('v-tabs-items', {
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab-item', [_c('v-row', {
    staticClass: "mt-8"
  }, [_c('v-col', [_c('v-checkbox', {
    attrs: {
      "label": "General CodeDrills Announcements"
    },
    on: {
      "change": function (state) {
        return _vm.setSubscriptionlocal(_vm.subscriptionKeys.SUBSCRIBE_GROUP_GENERAL, state);
      }
    },
    model: {
      value: _vm.all,
      callback: function ($$v) {
        _vm.all = $$v;
      },
      expression: "all"
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": "Interview Announcements"
    },
    on: {
      "change": function (state) {
        return _vm.setSubscriptionlocal(_vm.subscriptionKeys.SUBSCRIBE_GROUP_INTERVIEW, state);
      }
    },
    model: {
      value: _vm.interview,
      callback: function ($$v) {
        _vm.interview = $$v;
      },
      expression: "interview"
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": "Contest Announcements"
    },
    on: {
      "change": function (state) {
        return _vm.setSubscriptionlocal(_vm.subscriptionKeys.SUBSCRIBE_GROUP_CONTEST, state);
      }
    },
    model: {
      value: _vm.contest,
      callback: function ($$v) {
        _vm.contest = $$v;
      },
      expression: "contest"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-btn', {
    attrs: {
      "loading": _vm.loading,
      "color": "accent"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("Save")]), _c('error-message', {
    attrs: {
      "ex": _vm.ex
    }
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }