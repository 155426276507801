var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "py-16",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [!_vm.submittedFeedback ? _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "10"
    }
  }, [_c('v-card', {
    staticClass: "px-4 px-lg-10 py-16"
  }, [_c('v-card-title', {
    staticClass: "text-h2 danger--text justify-center font-weight-bold pb-8"
  }, [_vm._v(" Congrats! ")]), _c('v-card-subtitle', {
    staticClass: "text-h4 text-center black--text"
  }, [_vm._v(" You have completed the Assessment. ")]), _c('v-card-text', {}, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-img', {
    attrs: {
      "src": require('../assets/images/TestEnd.png'),
      "height": "100%",
      "width": "auto",
      "contain": ""
    }
  })], 1), _c('v-col', {
    staticClass: "text-body-1",
    attrs: {
      "cols": "8"
    }
  }, [_c('v-row', {
    staticClass: "pt-5 justify-center"
  }, [_c('v-col', {
    staticClass: "mt-3 text-center text-sm-left",
    attrs: {
      "cols": "10",
      "lg": "8"
    }
  }, [_vm._v(" How would you rate your experience with Assessment? ")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-rating', {
    attrs: {
      "color": "yellow darken-2",
      "size": "42"
    },
    model: {
      value: _vm.assessmentRating,
      callback: function ($$v) {
        _vm.assessmentRating = $$v;
      },
      expression: "assessmentRating"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "rating justify-center"
  }, [_c('v-col', {
    staticClass: "mt-3 text-center text-sm-left",
    attrs: {
      "cols": "10",
      "lg": "8"
    }
  }, [_vm._v(" How would you rate your experience with CodeDrills? ")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-rating', {
    attrs: {
      "color": "yellow darken-2",
      "size": "42"
    },
    model: {
      value: _vm.codedrillsRating,
      callback: function ($$v) {
        _vm.codedrillsRating = $$v;
      },
      expression: "codedrillsRating"
    }
  })], 1)], 1), _c('v-textarea', {
    staticClass: "mt-12",
    attrs: {
      "outlined": "",
      "placeholder": "Share your experience with us"
    },
    model: {
      value: _vm.writtenFeedback,
      callback: function ($$v) {
        _vm.writtenFeedback = $$v;
      },
      expression: "writtenFeedback"
    }
  })], 1)], 1)], 1), _c('v-card-actions', {
    staticClass: "justify-end"
  }, [_c('v-btn', {
    staticClass: "px-5",
    attrs: {
      "color": "accent"
    },
    on: {
      "click": _vm.finishTest
    }
  }, [_vm._v(" Finish ")])], 1)], 1)], 1) : _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "10"
    }
  }, [_c('v-card', {
    staticClass: "px-4 px-lg-8 py-16"
  }, [_c('v-card-title', {
    staticClass: "text-h3 text-center justify-center font-weight-medium pb-8"
  }, [_vm._v(" Thanks for submitting your feedback! ")]), _c('v-card-text', [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-img', {
    attrs: {
      "src": require('../assets/images/feedback.png')
    }
  })], 1)], 1)], 1), _c('v-card-actions', {
    staticClass: "justify-center"
  }, [!_vm.customDomain ? _c('v-btn', {
    staticClass: "px-5 py-2 text-none text-body-1 font-weight-medium",
    attrs: {
      "to": "/",
      "color": "primary"
    }
  }, [_vm._v(" Continue "), _c('v-icon', {
    attrs: {
      "right": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.mdiChevronRight) + " ")])], 1) : _vm._e()], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }