var render = function render(){
  var _vm$parentPreview, _vm$parentPreview$get, _vm$parentPreview$get2, _vm$parentPreview$get3, _vm$contentView$getMe, _vm$contentView$getMe2, _vm$contentView, _vm$contentView$getMe3, _vm$contentView$getMe4, _vm$submissionStatus;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [!_vm.user ? _c('v-row', [_c('v-col', {
    staticClass: "pt-4 pb-1"
  }, [_vm._v(" Please "), _c('router-link', {
    attrs: {
      "to": "/community/login"
    }
  }, [_vm._v(" login ")]), _vm._v(" to submit. ")], 1)], 1) : _vm._e(), _c('v-row', {
    staticClass: "px-2"
  }, [_c('v-col', [_c('Editor', {
    attrs: {
      "allowedLanguages": (_vm$parentPreview = _vm.parentPreview) === null || _vm$parentPreview === void 0 ? void 0 : (_vm$parentPreview$get = _vm$parentPreview.getMetaData()) === null || _vm$parentPreview$get === void 0 ? void 0 : (_vm$parentPreview$get2 = _vm$parentPreview$get.getContestMeta()) === null || _vm$parentPreview$get2 === void 0 ? void 0 : (_vm$parentPreview$get3 = _vm$parentPreview$get2.getLanguageRestriction()) === null || _vm$parentPreview$get3 === void 0 ? void 0 : _vm$parentPreview$get3.getAllowLanguageList(),
      "evaluationType": (_vm$contentView$getMe = _vm.contentView.getMetaData()) === null || _vm$contentView$getMe === void 0 ? void 0 : (_vm$contentView$getMe2 = _vm$contentView$getMe.getProblemMeta()) === null || _vm$contentView$getMe2 === void 0 ? void 0 : _vm$contentView$getMe2.getEvaluationType()
    },
    on: {
      "setLanguage": _vm.setLanguage,
      "resetCode": _vm.handleResetCode
    },
    model: {
      value: _vm.code,
      callback: function ($$v) {
        _vm.code = $$v;
      },
      expression: "code"
    }
  })], 1)], 1), _vm.user ? _c('v-row', [_c('v-col', {
    class: {
      'text-center': _vm.$vuetify.breakpoint.mobile
    },
    attrs: {
      "cols": 12,
      "lg": 4
    }
  }, [_c('v-btn', {
    staticClass: "mr-2",
    attrs: {
      "color": "accent",
      "height": "35"
    },
    on: {
      "click": _vm.openFileInput
    }
  }, [_vm._v("Load File")]), _c('input', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    ref: "codeFileInput",
    attrs: {
      "type": "file",
      "accept": ".c,.c99,.cxx,.cpp,.h,.java,.py,.txt,text/*"
    },
    on: {
      "change": _vm.loadFile
    }
  })], 1), _c('v-spacer'), ((_vm$contentView = _vm.contentView) === null || _vm$contentView === void 0 ? void 0 : (_vm$contentView$getMe3 = _vm$contentView.getMetaData()) === null || _vm$contentView$getMe3 === void 0 ? void 0 : (_vm$contentView$getMe4 = _vm$contentView$getMe3.getProblemMeta()) === null || _vm$contentView$getMe4 === void 0 ? void 0 : _vm$contentView$getMe4.getEvaluationType()) !== _vm.EvaluationType.EVALUATION_TYPE_SQL ? _c('v-col', {
    class: {
      'text-center': _vm.$vuetify.breakpoint.mobile
    },
    attrs: {
      "cols": 11,
      "lg": 2
    }
  }, [!_vm.hasWrappedIo ? _c('v-checkbox', {
    attrs: {
      "label": "Custom input"
    },
    model: {
      value: _vm.isUserInput,
      callback: function ($$v) {
        _vm.isUserInput = $$v;
      },
      expression: "isUserInput"
    }
  }) : _vm._e()], 1) : _vm._e(), _c('v-col', {
    class: {
      'text-center': _vm.$vuetify.breakpoint.mobile,
      'text-end': !_vm.$vuetify.breakpoint.mobile
    },
    attrs: {
      "cols": 12,
      "lg": 4
    }
  }, [_c('v-btn', {
    staticClass: "mr-2",
    attrs: {
      "disabled": _vm.submitting,
      "color": "accent",
      "height": "35"
    },
    on: {
      "click": _vm.testRun
    }
  }, [_vm._v("Run Code")]), _c('v-btn', {
    attrs: {
      "disabled": _vm.submitting,
      "color": "accent",
      "height": "35"
    },
    on: {
      "click": _vm.fullSubmit
    }
  }, [_vm._v("Submit")])], 1)], 1) : _c('v-row', [_c('v-spacer'), _c('v-col', {
    staticClass: "pt-2 pb-1",
    attrs: {
      "cols": 2
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/community/login"
    }
  }, [_vm._v(" Login ")]), _vm._v(" to submit. ")], 1)], 1), _vm.isUserInput ? _c('v-row', {
    staticClass: "mx-2"
  }, [_c('v-textarea', {
    attrs: {
      "outlined": "",
      "label": "input"
    },
    model: {
      value: _vm.stdin,
      callback: function ($$v) {
        _vm.stdin = $$v;
      },
      expression: "stdin"
    }
  })], 1) : _vm._e(), _c('error-message', {
    attrs: {
      "ex": _vm.ex
    }
  }), _c('v-row', {
    ref: "judgingStatus",
    staticClass: "mx-2"
  }, [_c('v-col', [_vm.submissionId ? _c('div', [_vm.submissionStatus && _vm.submissionStatus.getStatus() == 0 ? _c('submission-status', {
    attrs: {
      "title": "Waiting...",
      "subtitle": "Waiting in the queue for your turn. Please be patient.",
      "imageUrl": "judgingImg.svg",
      "status": _vm.submissionStatus && _vm.submissionStatus.getStatus()
    }
  }) : !_vm.judgingCompleted ? _c('submission-status', {
    attrs: {
      "title": "Judging...",
      "subtitle": 'We are processing your submission. ' + _vm.testsProgress,
      "imageUrl": "judgingImg.svg",
      "status": _vm.submissionStatus && _vm.submissionStatus.getStatus()
    }
  }) : _vm.judgingCompleted && _vm.submissionStatus.getStatus() == 13 && _vm.submissionStatus.getSubmissionType() == 0 ? _c('submission-status', {
    attrs: {
      "title": _vm.submissionStatusTitle(_vm.submissionStatus),
      "subtitle": _vm.scoreSubtitle(_vm.submissionStatus),
      "imageUrl": _vm.randomCorrectImage(),
      "status": _vm.submissionStatus && _vm.submissionStatus.getStatus()
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": 6,
      "md": 3,
      "lg": 2
    }
  }, [_c('stats-col', {
    attrs: {
      "title": "Time Taken"
    }
  }, [_vm._v(" " + _vm._s(_vm.submissionStatus.getTimeTaken()) + " ms ")])], 1), _c('v-col', {
    attrs: {
      "cols": 6,
      "md": 3,
      "lg": 2
    }
  }, [_c('stats-col', {
    attrs: {
      "title": "Memory Taken"
    }
  }, [_vm._v(" " + _vm._s(_vm.submissionStatus.getMemoryTaken()) + " KiB ")])], 1), _c('v-spacer')], 1), _vm.submissionStatus.getSubtasks() ? _c('v-row', {
    staticClass: "mt-5"
  }, [_c('v-col', [_vm._v(" Subtasks "), _c('v-data-table', {
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.subtasksHeaders,
      "items": _vm.subtaskList
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('tr', [_c('td', [_vm._v(_vm._s(item.getSubtaskId()))]), _c('td', {
          class: _vm.statusClass(item.getStatus())
        }, [_vm._v(" " + _vm._s(_vm.statusTitle(item.getStatus())) + " ")]), _c('td', [_vm._v(_vm._s(_vm.prettyScore(100 * item.getScore())))])])];
      }
    }], null, false, 3815785116)
  })], 1)], 1) : _vm._e(), _vm.publicTestCase ? _c('v-row', [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": 6,
      "md": 4,
      "lg": 3
    }
  }, [_vm.submissionStatus && _vm.submissionStatus.getSubmissionId() ? _c('router-link', {
    staticClass: "view_details_btn",
    attrs: {
      "to": `/submissions/${_vm.submissionStatus.getSubmissionId()}`,
      "height": "25",
      "target": "_blank"
    }
  }, [_vm._v(" See Test Case Details ")]) : _vm._e()], 1)], 1) : _vm._e()], 1) : _vm.judgingCompleted && _vm.submissionStatus.getStatus() == 13 && _vm.submissionStatus.getSubmissionType() == 1 ? _c('submission-status', {
    attrs: {
      "title": "Samples Passed",
      "subtitle": "Great! Submit to run against full test set",
      "imageUrl": _vm.randomCorrectImage(),
      "status": _vm.submissionStatus && _vm.submissionStatus.getStatus()
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": 6,
      "md": 3,
      "lg": 2
    }
  }, [_c('stats-col', {
    attrs: {
      "title": "Time Taken"
    }
  }, [_vm._v(" " + _vm._s(_vm.submissionStatus.getTimeTaken()) + " ms ")])], 1), _c('v-col', {
    attrs: {
      "cols": 6,
      "md": 3,
      "lg": 2
    }
  }, [_c('stats-col', {
    attrs: {
      "title": "Memory Taken"
    }
  }, [_vm._v(" " + _vm._s(_vm.submissionStatus.getMemoryTaken()) + " KiB ")])], 1), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": 6,
      "md": 4,
      "lg": 3,
      "align": "end"
    }
  }, [_c('v-btn', {
    attrs: {
      "disabled": _vm.submitting,
      "color": "accent",
      "height": "35"
    },
    on: {
      "click": _vm.fullSubmit
    }
  }, [_vm._v("Submit")])], 1)], 1), _c('tests-results', {
    attrs: {
      "resultViews": _vm.submissionStatus.getSampleResultViewsList()
    }
  })], 1) : _vm.judgingCompleted && _vm.submissionStatus.getStatus() == 13 && _vm.submissionStatus.getSubmissionType() == 2 ? _c('submission-status', {
    attrs: {
      "title": "Successful",
      "subtitle": "Code ran successfully for custom input",
      "imageUrl": _vm.randomCorrectImage(),
      "status": _vm.submissionStatus && _vm.submissionStatus.getStatus()
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": 6,
      "md": 3,
      "lg": 2
    }
  }, [_c('stats-col', {
    attrs: {
      "title": "Time Taken"
    }
  }, [_vm._v(" " + _vm._s(_vm.submissionStatus.getTimeTaken()) + " ms ")])], 1), _c('v-col', {
    attrs: {
      "cols": 6,
      "md": 3,
      "lg": 2
    }
  }, [_c('stats-col', {
    attrs: {
      "title": "Memory Taken"
    }
  }, [_vm._v(" " + _vm._s(_vm.submissionStatus.getMemoryTaken()) + " KiB ")])], 1), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": 6,
      "md": 4,
      "lg": 3,
      "align": "end"
    }
  }, [_c('v-btn', {
    attrs: {
      "disabled": _vm.submitting,
      "color": "accent",
      "height": "35"
    },
    on: {
      "click": _vm.fullSubmit
    }
  }, [_vm._v("Submit")])], 1)], 1), _c('tests-results', {
    attrs: {
      "isUserInput": _vm.isUserInput,
      "resultViews": _vm.submissionStatus.getSampleResultViewsList()
    }
  })], 1) : _c('submission-status', {
    attrs: {
      "title": _vm.statusTitle(_vm.submissionStatus.getStatus()),
      "subtitle": _vm.statusSubtitle(_vm.submissionStatus),
      "imageUrl": "Error.svg",
      "status": _vm.submissionStatus && _vm.submissionStatus.getStatus()
    }
  }, [_vm.submissionStatus.getResult() ? _c('text-block', {
    staticClass: "mb-2",
    staticStyle: {
      "overflow-x": "scroll"
    }
  }, [_c('pre', [_vm._v(_vm._s(_vm.submissionStatus && _vm.submissionStatus.getResult()) + "\n          ")])]) : _vm._e(), _vm.submissionStatus.getSampleResultViewsList() ? _c('tests-results', {
    staticClass: "ma-2",
    attrs: {
      "resultViews": _vm.submissionStatus.getSampleResultViewsList()
    }
  }) : _vm._e(), (_vm$submissionStatus = _vm.submissionStatus) !== null && _vm$submissionStatus !== void 0 && _vm$submissionStatus.getSubtasks() ? _c('v-row', {
    staticClass: "mt-5"
  }, [_c('v-col', [_vm._v(" Subtasks "), _c('v-data-table', {
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.subtasksHeaders,
      "items": _vm.subtaskList
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('tr', [_c('td', [_vm._v(_vm._s(item.getSubtaskId()))]), _c('td', {
          class: _vm.statusClass(item.getStatus())
        }, [_vm._v(" " + _vm._s(_vm.statusTitle(item.getStatus())) + " ")]), _c('td', [_vm._v(_vm._s(_vm.prettyScore(100 * item.getScore())))])])];
      }
    }], null, false, 3815785116)
  })], 1)], 1) : _vm._e(), _vm.publicTestCase && _vm.submissionStatus.getSubmissionType() === _vm.SubmissionType.FULL ? _c('v-row', [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": 6,
      "md": 4,
      "lg": 3
    }
  }, [_vm.submissionStatus && _vm.submissionStatus.getSubmissionId() ? _c('router-link', {
    staticClass: "view_details_btn",
    attrs: {
      "to": `/submissions/${_vm.submissionStatus.getSubmissionId()}`,
      "height": "25",
      "target": "_blank"
    }
  }, [_vm._v(" See Test Case Details ")]) : _vm._e()], 1)], 1) : _vm._e()], 1)], 1) : _vm._e()])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }