;(function (mod) {
  mod(require('codemirror/lib/codemirror'))
})(function (CodeMirror) {
  'use strict'

  var WORD = /[\w$|#]+/,
    RANGE = 500

  CodeMirror.registerHelper('hint', 'dart', function (editor, options) {
    var word = (options && options.word) || WORD
    var range = (options && options.range) || RANGE
    var cur = editor.getCursor(),
      curLine = editor.getLine(cur.line)
    var end = cur.ch,
      start = end
    while (start && word.test(curLine.charAt(start - 1))) --start
    var curWord = start != end && curLine.slice(start, end)
    var list = (options && options.list) || [],
      seen = {}
    var re = new RegExp(word.source, 'g')
    for (var dir = -1; dir <= 1; dir += 2) {
      var line = cur.line,
        endLine =
          Math.min(
            Math.max(line + dir * range, editor.firstLine()),
            editor.lastLine(),
          ) + dir
      for (; line != endLine; line += dir) {
        var text = editor.getLine(line),
          m
        while ((m = re.exec(text))) {
          if (line == cur.line && m[0] === curWord) continue
          if (
            (!curWord || m[0].lastIndexOf(curWord, 0) == 0) &&
            !Object.prototype.hasOwnProperty.call(seen, m[0])
          ) {
            seen[m[0]] = true
            list.push(m[0])
          }
        }
      }
    }
    const keywords = [
      'abstract',
      'else',
      'import',
      'show',
      'as',
      'enum',
      'in',
      'static',
      'assert',
      'export',
      'interface',
      'super',
      'async',
      'extends',
      'is',
      'switch',
      'await',
      'extension',
      'late',
      'sync',
      'base',
      'external',
      'library',
      'this',
      'break',
      'factory',
      'mixin',
      'throw',
      'case',
      'false',
      'new',
      'true',
      'catch',
      'null',
      'try',
      'class',
      'final',
      'on',
      'typedef',
      'const',
      'finally',
      'operator',
      'var',
      'continue',
      'for',
      'part',
      'void',
      'covariant',
      'function',
      'required',
      'when',
      'default',
      'get',
      'rethrow',
      'while',
      'deferred',
      'hide',
      'return',
      'with',
      'do',
      'if',
      'sealed',
      'yield',
      'dynamic',
      'implements',
      'set',
    ]

    for (const element of keywords) {
      if (!seen[element] && element.startsWith(curWord)) {
        list.push(element)
        seen[element] = true
      }
    }
    return {
      list: list,
      from: CodeMirror.Pos(cur.line, start),
      to: CodeMirror.Pos(cur.line, end),
    }
  })
})
