var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('v-tabs', {
    attrs: {
      "vertical": _vm.$vuetify.breakpoint.smAndDown,
      "color": "accent"
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab', [_vm._v("Summary")]), _vm.teamContest ? _c('v-tab', [_vm._v("My Registration")]) : _vm._e(), _vm.teamContest ? _c('v-tab', [_vm._v("All Registrants")]) : _vm._e()], 1), _c('v-tabs-items', {
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab-item', [_c('v-row', {
    staticClass: "mt-8"
  }, [_c('v-col', [!_vm.registrationContest ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": 6,
      "sm": 4,
      "lg": 2
    }
  }, [_vm._v(" Total Participations ")]), _c('v-col', {
    attrs: {
      "cols": 6,
      "sm": 4,
      "lg": 2
    }
  }, [_vm._v(" Total Submissions ")])], 1) : _vm._e(), _vm.scoreboard && !_vm.registrationContest ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": 6,
      "sm": 4,
      "lg": 2
    }
  }, [_vm._v(" " + _vm._s(_vm.scoreboard.getScoreboardStats().getTotalParticipants()) + " ")]), _c('v-col', {
    attrs: {
      "cols": 6,
      "sm": 4,
      "lg": 2
    }
  }, [_vm._v(" " + _vm._s(_vm.scoreboard.getScoreboardStats().getTotalSubmissions()) + " ")])], 1) : _vm._e(), !_vm.registrationContest ? _c('v-row', [_c('v-col', [_c('v-card', [_c('v-data-table', {
    attrs: {
      "items-per-page": 15,
      "hide-default-footer": true,
      "headers": _vm.headers,
      "items": _vm.userScores
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('tr', [_c('td', [_vm._v(" " + _vm._s(item.rank) + " ")]), _c('td', [_vm.isTeamContest ? _c('router-link', {
          staticClass: "text-decoration-none router_link",
          attrs: {
            "to": _vm.teamUrl(item)
          }
        }, [_vm._v(" " + _vm._s(_vm.getName(item)) + " ")]) : item.userPreview.handle ? _c('router-link', {
          staticClass: "text-decoration-none router_link",
          attrs: {
            "to": `/profiles/${item.userPreview.handle}`
          }
        }, [_vm._v(" " + _vm._s(item.userPreview.handle) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.getName(item)) + " ")])], 1), _vm.isTeamContest ? _c('td', [_vm._v(" " + _vm._s(_vm.teamOrganization(item)) + " ")]) : _vm._e(), _c('td', [_vm._v(" " + _vm._s(_vm.prettyScore(item.score)) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.prettyPenalty(item.penalty)) + " ")])])];
      }
    }], null, false, 3036522902)
  })], 1)], 1)], 1) : _vm._e(), !_vm.registrationContest ? _c('v-row', {
    staticClass: "mt-10"
  }, [_c('v-col', [_c('problem-list', {
    attrs: {
      "urlPrefix": '/contests/' + this.contentView.getUrl(),
      "problems": _vm.problems,
      "showIds": ""
    }
  })], 1)], 1) : _vm._e(), _c('v-row', [_c('v-col', [_c('div', {
    staticClass: "display-1"
  }, [_vm._v("About")])])], 1), _c('v-row', [_c('v-col', [_c('v-card', {
    staticClass: "pl-5 py-3",
    attrs: {
      "flat": "",
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "d-flex flex-no-wrap"
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('html-view', {
    attrs: {
      "html": _vm.view.getAboutHtml()
    }
  })], 1)])])], 1)], 1), _c('v-row', [_c('v-col', [_c('div', {
    staticClass: "display-1"
  }, [_vm._v("Rules")])])], 1), _c('v-row', [_c('v-col', [_c('v-card', {
    staticClass: "pl-5 py-3",
    attrs: {
      "flat": "",
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "d-flex flex-no-wrap"
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('html-view', {
    attrs: {
      "html": _vm.view.getRulesHtml()
    }
  })], 1)])])], 1)], 1)], 1)], 1)], 1), _c('v-tab-item', [!_vm.user ? _c('v-row', [_c('v-col', [_vm._v(" Please "), _c('router-link', {
    attrs: {
      "to": "/community/login"
    }
  }, [_vm._v(" login ")]), _vm._v(" to create team ")], 1)], 1) : _vm.userContestTeams && _vm.userContestTeams.length == 0 ? _c('v-row', [_c('v-col', [_vm._v(" You are not member of any team ")])], 1) : !_vm.acceptedTeam ? _c('v-row', [_c('v-col', [_vm._v(" No Accepted Invitation ")])], 1) : _vm._e(), _vm.acceptedTeam ? _c('v-row', [_c('v-col', [_c('team-detail', {
    attrs: {
      "teamView": _vm.acceptedTeam,
      "editableContest": _vm.editableContest
    }
  })], 1)], 1) : _vm._e()], 1), _c('v-tab-item', [_c('v-row', [_c('v-col', [_c('teams-list', {
    attrs: {
      "contestId": _vm.contentView.getId(),
      "contestUrl": _vm.contentView.getUrl(),
      "contestMetadata": _vm.contentView.getMetaData().getContestMeta()
    }
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }