import Vue from 'vue'
import Router from 'vue-router'
import CompetitiveHome from './views/CompetitiveHome.vue'
import Login from './views/Login.vue'
import Logout from './views/Logout.vue'
import ContestListPage from './views/ContestListPage.vue'
import Homepage from './views/recruiter/Homepage'
import InterviewProblems from './views/InterviewProblems'
import InterviewHome from './views/InterviewHome.vue'
import Dashboard from './views/Dashboard.vue'
import Drill from './views/Drill'
import Content from './views/Content'
import Scoreboard from './views/Scoreboard'
import BasicProfile from './views/BasicProfile'
import SubmissionDetails from './views/SubmissionDetails'
import Settings from './views/Settings'
import { getWhitelabelConfig } from './utils/whitelabel'

Vue.use(Router)
const displayName = getWhitelabelConfig().displayName
const COMMUNITY_ROUTES = [
  {
    path: '/campus-chapter-program',
    name: 'Campus Chapter Program',
    component: Content,
    props: { types: 'articles', url: 'campus-chapter-program' },
  },
  {
    path: '/competitive',
    name: 'home',
    component: CompetitiveHome,
    meta: { title: 'Competitive programming problems - ' + displayName },
  },

  {
    path: '/community',
    name: 'InterviewHome',
    component: InterviewHome,
    meta: { title: 'CodeDrills - Candidates' },
  },
  {
    path: '/problems',
    name: 'Interview Problems',
    component: InterviewProblems,
    meta: { title: 'Interview Problems - ' + displayName },
  },
  {
    path: '/community/jobs',
    name: 'Job Applications',
    component: () =>
      import(
        /* webpackChunkName: "communityJobs" */ './views/CommunityJobs.vue'
      ),
    meta: { title: 'Jobs - ' + displayName },
  },
  {
    path: '/community/jobs/:jobUrl',
    name: 'Community Jobs',
    component: () => import('./components/jobs/JobView.vue'),
    meta: { title: 'Jobs - ' + displayName },
    props: true,
  },
  {
    path: '/community/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: { title: 'Candidate Dashboard - ' + displayName },
  },
  {
    path: '/subscription',
    name: 'Settings',
    component: Settings,
    meta: { title: 'Settings - ' + displayName },
  },

  {
    path: '/drills',
    name: 'Drills',
    component: Drill,
    meta: { title: 'Drills - ' + displayName },
  },
  {
    path: '/basicProfile',
    name: 'basicProfile',
    component: BasicProfile,
    meta: { title: 'Basic Profile - ' + displayName },
  },
  {
    path: '/careers',
    name: 'career',
    component: () =>
      import(/* webpackChunkName: "career" */ './views/Career.vue'),
    meta: { title: 'Career- ' + displayName },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () =>
      import(/* webpackChunkName: "profile" */ './views/Profile.vue'),
    props: true,
    meta: { title: 'Profile - ' + displayName },
  },
  {
    path: '/profiles/:userHandle',
    name: 'profiles',
    component: () =>
      import(
        /* webpackChunkName: "publicProfile" */ './views/PublicProfile.vue'
      ),
    props: true,
    meta: { title: 'Profiles - ' + displayName },
  },
  {
    path: '/profile/edit',
    name: 'editProfile',
    component: () =>
      import(/* webpackChunkName: "editProfile" */ './views/EditProfile.vue'),
    props: true,
    meta: { title: 'Edit Profile - ' + displayName },
  },
  {
    path: '/submissions/:id',
    name: 'Submission Details',
    component: SubmissionDetails,
    props: true,
    meta: { title: 'Submission Details - ' + displayName },
  },
  {
    path: '/contests/algoqueen-2022-practice-contest',
    redirect: '/contests/algoqueen-2023-practice-contest',
  },
  {
    path: '/community/dashboardSample',
    name: 'DashboardSample',
    component: () =>
      import(
        /* webpackChunkName: "sampleDashboard" */ './views/DashboardSample.vue'
      ),
    meta: { title: 'Dashboard Sample - ' + displayName },
  },
]

var CONTEST_ROUTES = [
  {
    path: '/contests/:contestUrl/teams/:teamId',
    name: 'TeamView',
    props: true,
    component: () =>
      import(/* webpackChunkName: "teamid" */ './views/TeamView.vue'),
    meta: { title: 'Team - ' + displayName },
  },
  {
    path: '/contests',
    name: 'Contests',
    component: ContestListPage,
    meta: { title: 'Contests - ' + displayName },
  },
  {
    path: '/contests/:url/scoreboard',
    name: 'Scoreboard',
    component: Scoreboard,
    props: true,
    meta: { title: 'Scoreboard' },
  },
]

if (!getWhitelabelConfig().enableCommunity) {
  // Redirect community dashboard and other routes to contests
  CONTEST_ROUTES = CONTEST_ROUTES.concat([
    {
      path: '/community/dashboard',
      redirect: '/contests',
    },
    {
      path: '/community',
      redirect: '/contests',
    },
  ])
}

const COMMON_ROUTES = [
  {
    path: '/',
    name: 'RecruiterHome',
    component: Homepage,
    meta: { title: 'CodeDrills - Hire Fast', layout: 'RecruiterAuth' },
  },
  {
    path: '/terms-of-service',
    name: 'terms-of-service',
    component: () => import(/* webpackChunkName: "tos" */ './views/TOS.vue'),
    meta: { title: 'Terms of Service - ' + displayName },
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout,
    meta: { title: 'Logout - ' + displayName },
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () =>
      import(/* webpackChunkName: "info" */ './views/Privacy.vue'),
    meta: { title: 'Privacy Policy - ' + displayName },
  },
  {
    path: '/community/login',
    name: 'login',
    component: Login,
    meta: { title: 'Login - ' + displayName },
  },
  // Keep this last always
  {
    path: '/:parentTypes/:parentUrl/:types/:url',
    name: 'ContentWithParent',
    component: Content,
    props: true,
    meta: { title: '' + displayName },
  },
  {
    path: '/:types/:url',
    name: 'Content',
    component: Content,
    props: true,
    meta: { title: '' + displayName },
  },
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "info" */ './views/404.vue'),
    meta: { title: 'Not Found - ' + displayName },
  },
]

const RECRUITER_ROUTES = [
  {
    path: '/try',
    name: 'try',
    component: () =>
      import(
        /* webpackChunkName: "landing-campaign" */ './views/campaign/Try.vue'
      ),
    meta: {
      title: 'CodeDrills - Hire Great Talent',
      layout: 'RecruiterAuth',
    },
  },
  {
    path: '/forgotPassword',
    name: 'forgotPassword',
    component: () =>
      import(
        /* webpackChunkName: "forgotPassword" */ './views/campaign/ForgotPass.vue'
      ),
    meta: {
      title: 'Forgot Password - ' + displayName,
      layout: 'RecruiterAuth',
    },
  },
  {
    path: '/confirmPassword',
    name: 'confirmPassword',
    component: () =>
      import(
        /* webpackChunkName: "confirmPassword" */ './views/campaign/ConfirmPass.vue'
      ),
    meta: {
      title: 'Confirm Password - ' + displayName,
      layout: 'RecruiterAuth',
      skipAuth: true,
    },
  },
  {
    path: '/signup/:testurl/testInvite',
    name: 'TestInvite',
    component: () =>
      import(
        /* webpackChunkName: "customerSignUp" */ './views/TestLinkInvite.vue'
      ),
    meta: { title: 'Test Sign Up - ' + displayName },
  },
  {
    path: '/signup',
    name: 'signUpCustomer',
    component: () =>
      import(
        /* webpackChunkName: "customerSignUp" */ './views/campaign/SignUp.vue'
      ),
    meta: {
      title: 'Customer Sign Up - ' + displayName,
      layout: 'RecruiterAuth',
    },
  },
  {
    path: '/signUpCustomer',
    name: 'signUpCustomer_old',
    component: () =>
      import(
        /* webpackChunkName: "customerSignUp_old" */ './views/campaign/SignUp.vue'
      ),
    meta: {
      title: 'Customer Sign Up - ' + displayName,
      layout: 'RecruiterAuth',
    },
    beforeEnter(to, from, next) {
      next('/signup')
    },
  },
  {
    path: '/login',
    name: 'loginCustomer',
    component: () =>
      import(
        /* webpackChunkName: "recruiterSignIn" */ './views/campaign/LogIn.vue'
      ),
    meta: {
      title: 'Recruiter Login - ' + displayName,
      layout: 'RecruiterAuth',
    },
  },
  {
    path: '/loginCustomer',
    name: 'loginCustomer_old',
    component: () =>
      import(
        /* webpackChunkName: "recruiterSignIn_old" */ './views/campaign/LogIn.vue'
      ),
    meta: {
      title: 'Recruiter Login - ' + displayName,
      layout: 'RecruiterAuth',
    },
    beforeEnter(to, from, next) {
      next('/login')
    },
  },
  {
    path: '/dashboard',
    name: 'Recruiter Dashboard',
    component: () =>
      import(
        /* webpackChunkName: "recruiterDash" */ './views/recruiter/Assessment.vue'
      ),
    props: true,
    meta: {
      title: 'Recruiter Dashboard | ' + displayName,
      layout: 'Recruiter',
    },
  },
  {
    path: '/assessments',
    name: 'Assessments | Recruiter Dashboard',
    component: () =>
      import(
        /*webpackChunkName: "recruiterDas"*/ './views/recruiter/Assessment.vue'
      ),
    props: true,
    meta: {
      title: 'Recruiter Dashboard | ' + displayName,
      layout: 'Recruiter',
    },
  },
  {
    path: '/skillpool',
    name: 'Skill Pool | Recruiter Dashboard',
    component: () =>
      import(
        /*webpackChunkName: "recruiterDas"*/ './views/recruiter/SkillPool.vue'
      ),
    props: true,
    meta: { title: 'Skill  | Recruiter Dashboard', layout: 'Recruiter' },
  },
  {
    path: '/assessments/create/:jobId?',
    name: 'Create Assessment | Recruiter Dashboard',
    component: () =>
      import(
        /*webpackChunkName: "createAssessment"*/ './views/recruiter/CreateAssessment.vue'
      ),
    props: true,
    meta: {
      title: 'Recruiter Dashboard | ' + displayName,
      layout: 'Recruiter',
    },
  },
  {
    path: '/assessments/:url',
    name: 'Single Assessment',
    component: () =>
      import(
        /*webpackChunkName: "singleAssessment"*/ './views/recruiter/SingleAssessment.vue'
      ),
    props: true,
    meta: { title: 'Assessment | Recruiter', layout: 'Recruiter' },
  },
  // {
  //   path: "/dashboard/candidates",
  //   name: "Candidates | Recruiter Dashboard",
  //   component: () => import(/*webpackChunkName: "hiringDashboardcandidates"*/'./views/recruiter/Candidates.vue'),
  //   props: true,
  //   meta: {title: "Candidates | Recruiter Dashboard"},
  // },
  {
    path: '/assessments/:url/:userId',
    name: 'Candidate | Recruiter Dashboard',
    component: () =>
      import(
        /*webpackChunkName: "singleCandidate"*/ './views/recruiter/SingleCandidate.vue'
      ),
    props: true,
    meta: { title: 'Candidate | Recruiter Dashboard', layout: 'Recruiter' },
  },
  {
    path: '/jobs',
    name: 'Jobs | Recruiter Dashboard',
    component: () =>
      import(
        /*webpackChunkName: "jobsDashboard"*/ './views/recruiter/Jobs.vue'
      ),
    props: true,
    meta: { title: 'Job List | ' + displayName, layout: 'Recruiter' },
  },
  {
    path: '/jobs/create',
    name: 'Create Job | Recruiter Dashboard',
    component: () =>
      import(
        /*webpackChunkName: "jobsCreate"*/ './views/recruiter/CreateJob.vue'
      ),
    props: true,
    meta: { title: 'Job | ' + displayName, layout: 'Recruiter' },
  },
  {
    path: '/jobs/:url',
    name: 'Job | Recruiter Dashboard',
    component: () =>
      import(
        /*webpackChunkName: "singleJob"*/ './views/recruiter/SingleJob.vue'
      ),
    props: true,
    meta: { title: 'Job | ' + displayName, layout: 'Recruiter' },
  },
  {
    path: '/jobs/:url/edit',
    name: 'Edit Job | Recruiter Dashboard',
    component: () =>
      import(/*webpackChunkName: "singleJob"*/ './views/recruiter/EditJob.vue'),
    props: true,
    meta: { title: 'Job | ' + displayName, layout: 'Recruiter' },
  },
  {
    path: '/billing',
    name: 'Plans and Billing',
    component: () =>
      import(
        /*webpackChunkName: "hiringDashboardbilling"*/ './views/recruiter/Billing.vue'
      ),
    props: true,
    meta: {
      title: 'Plans and Billing | Recruiter Dashboard',
      layout: 'Recruiter',
    },
  },
  {
    path: '/help',
    name: 'Help | Recruiter Dashboard',
    component: () =>
      import(
        /*webpackChunkName: "hiringDashboardhelp"*/ './views/recruiter/Help.vue'
      ),
    props: true,
    meta: { title: 'Help | Recruiter Dashboard', layout: 'Recruiter' },
  },
  {
    path: '/team',
    name: 'Teams | Recruiter Dashboard',
    component: () =>
      import(
        /*webpackChunkName: "hiringDashboardteams"*/ './views/recruiter/Team.vue'
      ),
    props: true,
    meta: { title: 'Teams | Recruiter Dashboard', layout: 'Recruiter' },
  },
  {
    path: '/settings',
    name: 'Settings | Recruiter Dashboard',
    component: () =>
      import(
        /*webpackChunkName: "hiringDashboardsettings"*/ './views/recruiter/Settings.vue'
      ),
    props: true,
    meta: { title: 'Settings | Recruiter Dashboard', layout: 'Recruiter' },
  },
  {
    path: '/hiring/tests/:url',
    name: 'Hiring Test View',
    component: () =>
      import(/* webpackChunkName: "hiringTest" */ './views/TestDetails.vue'),
    props: true,
    meta: { title: 'Hiring Test' },
  },
  {
    path: '/hiring/tests/:url/reports/:userId',
    name: 'UserActivity',
    component: () =>
      import(/* webpackChunkName: "userActivity" */ './views/UserActivity.vue'),
    props: true,
    meta: { title: 'Candidate Report' },
  },
  {
    path: '/tests/:url',
    name: 'Test',
    component: () =>
      import(/* webpackChunkName: "testView" */ './components/hiring/TestView'),
    props: true,
    meta: { title: 'Test - ' + displayName, layout: 'TestLayout' },
  },
]

var routes = []
var whitelabelConfig = getWhitelabelConfig()

if (whitelabelConfig.enableCommunity) {
  routes = routes.concat(COMMUNITY_ROUTES)
}

if (whitelabelConfig.enableContests) {
  routes = routes.concat(CONTEST_ROUTES)
}

if (whitelabelConfig.enableRecruiter) {
  routes = routes.concat(RECRUITER_ROUTES)
}

// Keep this last always
routes = routes.concat(COMMON_ROUTES)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})
