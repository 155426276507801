var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [!_vm.minimal ? _c('v-sheet', {
    staticClass: "pr-5 grey lighten-5",
    attrs: {
      "height": 200
    }
  }, [_c('v-row', {
    staticClass: "ml-4"
  }, [_c('v-col', {
    attrs: {
      "cols": 0,
      "md": 1
    }
  }), _vm.imageUrl ? _c('v-col', {
    staticClass: "pt-10 mr-5 hidden-sm-and-down",
    attrs: {
      "cols": 2,
      "lg": 1
    }
  }, [!_vm.loadingCompleted && _vm.appendImageUrl ? _c('v-skeleton-loader', {
    attrs: {
      "type": "avatar",
      "tile": "",
      "width": 130
    }
  }) : _vm._e(), _c('v-avatar', {
    attrs: {
      "size": 130
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.imageUrl
    }
  })])], 1) : _vm._e(), this.$slots.subtitle ? _c('v-col', {
    attrs: {
      "cols": 12,
      "md": 8
    }
  }, [_c('v-row', {
    staticClass: "grey lighten-5"
  }, [_c('v-col', {
    attrs: {
      "cols": 10
    }
  }, [!_vm.loadingCompleted ? _c('v-skeleton-loader', {
    attrs: {
      "height": 120,
      "width": 400
    }
  }) : _c('span', {
    staticClass: "grey lighten-5 font-weight-bold text-truncate",
    class: {
      'display-1': _vm.$vuetify.breakpoint.lgAndUp,
      headline: _vm.$vuetify.breakpoint.mdAndDown,
      title: _vm.$vuetify.breakpoint.smAndDown
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")])], 1)], 1), !_vm.loadingCompleted ? _c('v-skeleton-loader', {
    attrs: {
      "type": "sentence",
      "height": 30,
      "width": 400
    }
  }) : _vm._t("subtitle")], 2) : _c('v-col', {
    attrs: {
      "cols": 12,
      "md": 8
    }
  }, [_c('v-row', {
    staticClass: "grey lighten-5 mt-10"
  }, [_c('v-col', {
    attrs: {
      "cols": 10
    }
  }, [!_vm.loadingCompleted ? _c('v-skeleton-loader', {
    attrs: {
      "type": "image",
      "height": 100
    }
  }) : _c('span', {
    staticClass: "grey lighten-5 font-weight-bold text-truncate ml-8",
    class: {
      'display-1': _vm.$vuetify.breakpoint.lgAndUp,
      headline: _vm.$vuetify.breakpoint.mdAndDown,
      title: _vm.$vuetify.breakpoint.smAndDown
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")])], 1)], 1)], 1), _c('v-col', {
    staticClass: "pt-10 hidden-sm-and-down",
    attrs: {
      "cols": 2,
      "lg": 1
    }
  }, [!_vm.loadingCompleted && _vm.appendImageUrl ? _c('v-skeleton-loader', {
    attrs: {
      "type": "avatar",
      "tile": "",
      "width": 130
    }
  }) : _vm.appendImageUrl ? _c('v-avatar', {
    attrs: {
      "tile": "",
      "size": 130
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.appendImageUrl
    }
  })]) : _vm._t("appendBanner")], 2)], 1)], 1) : _c('v-sheet', {
    staticClass: "pr-5 grey lighten-5",
    attrs: {
      "height": 60
    }
  }, [_c('v-row', {
    staticClass: "ml-4"
  }, [_c('v-col', {
    attrs: {
      "cols": 0,
      "md": 1
    }
  }), _c('v-col', {
    attrs: {
      "cols": 12,
      "md": 8
    }
  }, [_c('v-row', {
    staticClass: "grey lighten-5"
  }, [_c('v-col', {
    attrs: {
      "cols": 10
    }
  }, [!_vm.loadingCompleted ? _c('v-skeleton-loader', {
    attrs: {
      "type": "image",
      "height": 40
    }
  }) : _c('span', {
    staticClass: "grey lighten-5 font-weight-bold text-truncate ml-8",
    class: {
      'text-h5': _vm.$vuetify.breakpoint.lgAndUp,
      headline: _vm.$vuetify.breakpoint.mdAndDown,
      title: _vm.$vuetify.breakpoint.smAndDown
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")])], 1)], 1)], 1), _c('v-col', {
    staticClass: "pt-10 hidden-sm-and-down",
    attrs: {
      "cols": 2,
      "lg": 1
    }
  }, [!_vm.loadingCompleted && _vm.appendImageUrl ? _c('v-skeleton-loader', {
    attrs: {
      "type": "avatar",
      "tile": "",
      "width": 130
    }
  }) : _vm.appendImageUrl ? _c('v-avatar', {
    attrs: {
      "tile": "",
      "size": 130
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.appendImageUrl
    }
  })]) : _vm._t("appendBanner")], 2)], 1)], 1), _c('v-container', {
    staticStyle: {
      "min-height": "100vh"
    },
    attrs: {
      "fluid": ""
    }
  }, [_vm.loadingError ? _c('error', [_vm._v(_vm._s(_vm.customErrorMessage))]) : !_vm.loadingCompleted ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": 12,
      "md": 10,
      "offset-md": 1,
      "offset-sm": 0
    }
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "image",
      "height": 600
    }
  })], 1)], 1) : _c('v-row', [_c('v-col', {
    attrs: {
      "cols": 12,
      "md": 10,
      "offset-md": 1,
      "offset-sm": 0
    }
  }, [_vm.breadcrumbs && _vm.breadcrumbs.length > 0 ? _c('v-breadcrumbs', {
    attrs: {
      "divider": ">",
      "items": _vm.breadcrumbs
    }
  }) : _vm._e(), _vm._t("default")], 2)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }