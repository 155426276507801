import { mdiNotificationClearAll } from '@mdi/js'
import store from '../store'
import { getWhitelabelConfig } from './whitelabel'

function authChange(user) {
  console.log('---Auth change', user)

  store.commit('user/initialized')
  if (user) {
    console.log('user', user.toJSON())
    var whitelabelConfig = getWhitelabelConfig()
    if (
      whitelabelConfig.restrictLogin &&
      !isSameDomain(user.email, window.location.hostname)
    ) {
      console.log('User domain mismatch')
      store.dispatch('user/logout')
      return
    }
    store.dispatch('user/userLoggedIn', { user: user })
    store.dispatch('user/register')
    console.log('Dispatching to checkSSO')
    store.dispatch('user/checkSSO')
  } else {
    store.dispatch('user/userLoggedOut')
  }
}

function signInSuccess(authResult) {
  console.log(
    'New user?',
    authResult.additionalUserInfo.isNewUser,
    authResult.user.emailVerified,
  )
  if (
    authResult.additionalUserInfo.isNewUser &&
    !authResult.user.emailVerified
  ) {
    console.log('New signin')
    authResult.user
      .sendEmailVerification()
      .catch((err) =>
        console.log('Error while sending email verification', err),
      )
  }

  return true
}

function isSameDomain(email, hostname) {
  console.log('email', email)
  var emailDomain = email.split('@')[1]
  console.log('h e', hostname, emailDomain)
  return (
    hostname === emailDomain ||
    hostname === 'localhost' ||
    hostname === 'codedrills.io'
  )
}

export default {
  authChange,
  signInSuccess,
}
