import candidate from 'codedrills_proto/io/codedrills/proto/user/candidate_service_grpc_web_pb'

import site from 'codedrills_proto/io/codedrills/proto/site/content_view_service_grpc_web_pb'
import { ApiCall } from '@/utils/api.js'
import content from './content'

var candidateService = new candidate.CandidateServicePromiseClient(
  process.env.VUE_APP_USER_API_URL + '/user',
  null,
  null,
)

const siteService = new site.ContentViewServicePromiseClient(
  process.env.VUE_APP_SITE_API_URL + '/site',
  null,
  null,
)

var candidate_proto = proto.io.codedrills.proto.user
var content_proto = proto.io.codedrills.proto.content
var site_proto = proto.io.codedrills.proto.site

const state = {
  currentTestView: null,
  contentPreview: null,
  startHiringTestStatus: null,
  getHiringTest: null,
  getHiringTestStatus: 0,
  currentAnswer: null,
  submitAnswerStatus: 0,
  skipQuestionStatus: null,
  timeoutQuestionStatus: null,
  // set at last question, meaning clicking next when this is set
  // leads to the feedback screen
  testEndedLocally: false,
  sendCandidateFeedbackStatus: 0,
  endTestStatus: 0,
  problemTab: 0,
  hiringCodeEditorFullscreen: false,
  jobList: null,
  currentJob: null,
  resumeResponse: null,
  savedJobs: null,
  checkJobAppliedStatus: 0,
  fetchJobViewStatus: 0,
  fetchContentPreviewStatus: 0,
  checkCandidateInviteStatus: 0,
  fetchTestPreviewStatus: 0,
  loginCandidateStatus: 0,
}

const getters = {
  getContentTypes() {
    return Object.keys(content_proto.ContentType)
  },
  getCurrentAnswer(state) {
    return state.currentAnswer
  },
  jobTypes() {
    return Object.keys(content_proto.JobType)
  },
  jobPrefs() {
    return Object.keys(content_proto.JobPreference)
  },
}

const actions = {
  getHiringTest: new ApiCall('getHiringTest')
    .authRequired()
    .withServiceCall((r, h) => candidateService.getTest(r, h))
    .withRequest(({ url }, __, { commit }) => {
      console.log(`getHiringTest:`, { url })
      return new candidate_proto.GetTestRequest().setTestId(
        new content_proto.ContentId().setContentAddress(
          new content_proto.ContentAddress()
            .setUrl(url)
            .setContentType(content_proto.ContentType.TEST),
        ),
      )
    })
    .onSuccess(({ commit }, res) => {
      console.log('getHiringTest:setCurrentTestView:', res?.toObject())
      commit('setCurrentTestView', res)
    })
    .build(),

  startHiringTest: new ApiCall('startHiringTest')
    .authRequired()
    .withServiceCall((r, h) => candidateService.startTest(r, h))
    .withRequest(({ test_id }, __, { commit }) => {
      return new candidate_proto.StartTestRequest().setTestId(test_id)
    })
    .onSuccess(({ commit }, res) => {
      console.log('startHiringTest:setCurrentTestView:', res)
      console.log('startHiringTest:setCurrentTestView:Object', res?.toObject())
      commit('setCurrentTestView', res)
      commit('setProblemTab', 0)
    })
    .build(),

  submitAnswer: new ApiCall('submitAnswer')
    .authRequired()
    .withServiceCall((r, h) => candidateService.submitAnswer(r, h))
    .withRequest(
      (
        { test_id, qnum, qid, qType, qTypeId, candidate_id, answer },
        __,
        { commit },
      ) => {
        let answerData = new candidate_proto.AnswerData()
        if (qType === 'MCQ') {
          answerData.setMcqAnswerData(
            new candidate_proto.McqAnswerData().setOptionIdList(answer),
          )
        } else if (qType === 'PROBLEM') {
          console.log('submitAnswer::PROBLEM', answer)
          answerData.setProblemAnswerData(
            new candidate_proto.ProblemAnswerData().setSubmissionId(answer),
          )
        } else if (qType === 'SUBJECTIVE') {
          answerData.setSubjectiveAnswerData(
            new candidate_proto.SubjectiveAnswerData().setSubjectiveAnswer(
              answer,
            ),
          )
        }
        return new candidate_proto.SubmitAnswerRequest()
          .setTestId(test_id)
          .setQuestionNumber(qnum)
          .setAnswer(
            new candidate_proto.Answer()
              .setCandidateId(candidate_id)
              .setQuestionId(qid)
              .setParentId(test_id)
              .setContentType(qTypeId)
              .setAnswerData(answerData),
          )
      },
    )
    .onSuccess(({ commit }, res) => {
      console.log('submitAnswer::', res)
      console.log('submitAnswer::Object', res?.toObject())
      commit('setCurrentTestView', res)
      commit('setProblemTab', 0)
    })
    .build(),
  skipQuestion: new ApiCall('skipQuestion')
    .authRequired()
    .withServiceCall((r, h) => candidateService.submitAnswer(r, h))
    .withRequest(({ test_id, qnum }, __, { commit }) => {
      return new candidate_proto.SubmitAnswerRequest()
        .setTestId(test_id)
        .setQuestionNumber(qnum)
    })
    .onSuccess(({ commit }, res) => {
      console.log('skipQuestion::', res)
      console.log('skipQuestion::Object', res?.toObject())
      commit('setCurrentTestView', res)
    })
    .build(),
  timeoutQuestion: new ApiCall('timeoutQuestion')
    .authRequired()
    .withServiceCall((r, h) => candidateService.timeoutQuestion(r, h))
    .withRequest(({ test_id, qnum }, __, { commit }) => {
      return new candidate_proto.TimeoutQuestionRequest()
        .setTestId(test_id)
        .setQuestionNumber(qnum)
    })
    .onSuccess(({ commit }, res) => {
      console.log('timeoutQuestion::', res)
      console.log('timeoutQuestion::Object', res?.toObject())
      commit('setCurrentTestView', res)
    })
    .build(),
  sendCandidateFeedback: new ApiCall('sendCandidateFeedback')
    .authRequired()
    .withServiceCall((r, h) => candidateService.candidateFeedback(r, h))
    .withRequest(
      ({ test_id, test_stars, cd_stars, written }, __, { commit }) => {
        return new candidate_proto.CandidateFeedbackRequest()
          .setTestId(test_id)
          .setFeedback(
            new candidate_proto.CandidateFeedback()
              .setAssessmentStars(test_stars)
              .setPlatformStars(cd_stars)
              .setWrittenFeedback(written),
          )
      },
    )
    .onSuccess(({ commit }, res) => {
      console.log('sendCandidateFeedback:', res?.toObject() ?? res)
      commit('setCurrentTestView', res)
    })
    .build(),

  endTest: new ApiCall('endTest')
    .authRequired()
    .withServiceCall((r, h) => candidateService.endTest(r, h))
    .withRequest(({ test_id }, __, { commit }) => {
      return new candidate_proto.EndTestRequest().setTestId(test_id)
    })
    .onSuccess(({ commit }, res) => {
      console.log('endTest:', res?.toObject() ?? res)
      commit('setCurrentTestView', res)
    })
    .build(),

  logProctoringEvent: new ApiCall('logProctoringEvent')
    .authRequired()
    .withServiceCall((r, h) => candidateService.logProctoringEvent(r, h))
    .withRequest(
      (
        { test_id, candidate_id, qid, proctoring_event, video, type },
        __,
        { commit },
      ) => {
        return new candidate_proto.LogProctoringEventRequest().setProctoringLog(
          new candidate_proto.ProctoringLog()
            .setTestId(test_id)
            .setCandidateId(candidate_id)
            .setQuestionId(qid)
            .setProctoringEvent(proctoring_event)
            .setVideo(
              new candidate_proto.Video().setVideo(video).setType(type),
            ),
        )
      },
    )
    .onSuccess(({ commit }, res) => {
      console.log('Proctoring log sent successfully.')
      // commit("something", res);
    })
    .build(),
  fetchJobList: new ApiCall('fetchJobList')
    .withServiceCall((r, h) => candidateService.listJobs(r, h))
    .withRequest(() => {
      return new candidate_proto.ListJobsRequest()
    })
    .onSuccess(({ commit }, res) => {
      console.log(res.toObject())
      commit('setJobList', res?.getJobList())
    })
    .build(),

  fetchJobView: new ApiCall('fetchJobView')
    .withServiceCall((r, h) => siteService.getContentView(r, h))
    .withRequest(({ url }) => {
      return new site_proto.GetContentViewRequest().setContentId(
        new content_proto.ContentId().setContentAddress(
          new content_proto.ContentAddress()
            .setUrl(url)
            .setContentType(content_proto.ContentType.JOB),
        ),
      )
    })
    .onSuccess(({ commit }, res) => {
      // console.log(res?.toObject());
      commit('setCurrentJob', res.getContentView())
    })
    .build(),

  applyToJob: new ApiCall('applyToJob')
    .withServiceCall((r, h) => candidateService.applyJob(r, h))
    .withRequest(({ id, resume, name, type }) => {
      return new candidate_proto.ApplyJobRequest()
        .setJobId(id)
        .setResumeFile(
          new candidate_proto.File()
            .setData(resume)
            .setName(name)
            .setType(type),
        )
    })
    .onSuccess(({ commit }, res) => {
      return res
    })
    .build(),

  checkJobApplied: new ApiCall('checkJobApplied')
    .withServiceCall((r, h) => candidateService.checkJobAppliedStatus(r, h))
    .withRequest(({ id }) => {
      return new candidate_proto.CheckJobAppliedStatusRequest().setJobId(id)
    })
    .onSuccess(({ commit }, res) => {
      console.log(res?.getApplied())
    })
    .build(),

  fetchResume: new ApiCall('fetchResume')
    .withServiceCall((r, h) => candidateService.getResume(r, h))
    .withRequest(() => {
      return new candidate_proto.GetResumeRequest()
    })
    .onSuccess(({ commit }, res) => {
      console.log(res?.getResume())
      commit('setResumeResponse', res?.getResume())
    })
    .build(),
  saveJob: new ApiCall('saveJob')
    .withServiceCall((r, h) => candidateService.saveJob(r, h))
    .withRequest(({ id }) => {
      return new candidate_proto.SaveJobRequest().setJobId(id)
    })
    .onSuccess(({ commit, dispatch }, res) => {
      dispatch('fetchSavedJobs')
    })
    .build(),

  fetchSavedJobs: new ApiCall('fetchSavedJobs')
    .withServiceCall((r, h) => candidateService.getSavedJobs(r, h))
    .withRequest(() => {
      return new candidate_proto.GetSavedJobsRequest()
    })
    .onSuccess(({ commit }, res) => {
      commit('setSavedJobs', res?.getJobIdList())
    })
    .build(),

  fetchContentPreview: new ApiCall('fetchContentPreview')
    .withServiceCall((r, h) => siteService.getContentPreview(r, h))
    .withRequest(({ url }) => {
      return new site_proto.GetContentViewRequest().setContentId(
        new content_proto.ContentId().setContentAddress(
          new content_proto.ContentAddress()
            .setUrl(url)
            .setContentType(content_proto.ContentType.TEST),
        ),
      )
    })
    .onSuccess(({ commit }, res) => {
      console.log('ContentPreview is .....', res.getContentPreview().toObject())
      commit('setContentPreview', res.getContentPreview())
    })
    .build(),

  checkCandidateInvite: new ApiCall('checkCandidateInvite')
    .withServiceCall((r, h) => candidateService.checkCandidateInvited(r, h))
    .withRequest(({ url, type }) => {
      return new candidate_proto.CheckCandidateInvitedRequest().setTestId(
        new content_proto.ContentId().setContentAddress(
          new content_proto.ContentAddress()
            .setUrl(url)
            .setContentType(content_proto.ContentType[type]),
        ),
      )
    })
    .onSuccess(({ commit }, res) => {
      console.log('Invite status is .....', res.toObject())
      return res
    })
    .build(),

  fetchTestPreview: new ApiCall('fetchTestPreview')
    .authOptional()
    .withServiceCall((r, h) => candidateService.testPreview(r, h))
    .withRequest(({ url, type }) => {
      return new candidate_proto.TestPreviewRequest().setTestId(
        new content_proto.ContentId().setContentAddress(
          new content_proto.ContentAddress()
            .setUrl(url)
            .setContentType(content_proto.ContentType[type]),
        ),
      )
    })
    .onSuccess(({ commit }, res) => {
      return res
    })
    .build(),

  loginCandidate: new ApiCall('loginCandidate')
    .authOptional()
    .withServiceCall((r, h) => candidateService.loginCandidate(r, h))
    .withRequest(({ url, inviteCode }) => {
      return new candidate_proto.LoginCandidateRequest()
        .setInviteCode(inviteCode)
        .setTestId(
          new content_proto.ContentId().setContentAddress(
            new content_proto.ContentAddress()
              .setUrl(url)
              .setContentType(content_proto.ContentType.TEST),
          ),
        )
    })
    .onSuccess(({ commit }, res) => {
      return res
    })
    .build(),
}

const mutations = {
  setCurrentTestView(state, ques) {
    state.currentTestView = ques
  },
  setContentPreview(state, contentPreview) {
    state.contentPreview = contentPreview
  },
  startHiringTestStatus(state, status) {
    state.startHiringTestStatus = status
  },
  getHiringTestStatus(state, status) {
    state.getHiringTestStatus = status
  },
  setCurrentAnswer(state, ans) {
    state.currentAnswer = ans
  },
  submitAnswerStatus(state, status) {
    state.submitAnswerStatus = status
  },
  skipQuestionStatus(state, status) {
    state.skipQuestionStatus = status
  },
  timeoutQuestionStatus(state, status) {
    state.timeoutQuestionStatus = status
  },
  setTestEndedLocally(state, param) {
    state.testEndedLocally = param
  },
  sendCandidateFeedbackStatus(state, status) {
    state.sendCandidateFeedback = status
  },
  endTestStatus(state, status) {
    state.endTestStatus = status
  },
  logProctoringEventStatus(state, status) {
    // empty
  },
  setProblemTab(state, tab) {
    state.problemTab = tab
  },
  setHiringCodeEditorFullscreen(state, val) {
    state.hiringCodeEditorFullscreen = val
  },
  setJobList(state, res) {
    state.jobList = res
  },
  setCurrentJob(state, job) {
    state.currentJob = job
  },
  setResumeResponse(state, res) {
    state.resumeResponse = res
  },
  setSavedJobs(state, jobs) {
    state.savedJobs = jobs
  },
  applyToJobStatus(state, status) {
    state.applyToJobStatus = status
  },
  fetchJobListStatus(state, status) {
    state.fetchJobListStatus = status
  },
  fetchJobViewStatus(state, status) {
    state.fetchJobViewStatus = status
  },
  checkJobAppliedStatus(state, status) {
    state.checkJobAppliedStatus = status
  },
  fetchResumeStatus(state, status) {
    state.fetchResumeStatus = status
  },
  saveJobStatus(state, status) {
    state.saveJobStatus = status
  },
  fetchSavedJobsStatus(state, status) {
    state.fetchSavedJobsStatus = status
  },
  checkJobAppliedStatus(state, status) {
    state.checkJobAppliedStatus = status
  },
  fetchContentPreviewStatus(state, status) {
    state.fetchContentPreviewStatus = status
  },
  checkCandidateInviteStatus(state, status) {
    state.checkCandidateInviteStatus = status
  },
  fetchTestPreviewStatus(state, status) {
    state.fetchTestPreviewStatus = status
  },
  loginCandidateStatus(state, status) {
    state.loginCandidateStatus = status
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
