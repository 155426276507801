<template>
  <span>
    <v-row v-if="!user">
      <v-col class="pt-4 pb-1">
        Please <router-link to="/community/login"> login </router-link> to
        submit.
      </v-col>
    </v-row>
    <v-row class="px-2">
      <v-col>
        <Editor
          v-model="code"
          :allowedLanguages="
            parentPreview
              ?.getMetaData()
              ?.getContestMeta()
              ?.getLanguageRestriction()
              ?.getAllowLanguageList()
          "
          :evaluationType="
            contentView.getMetaData()?.getProblemMeta()?.getEvaluationType()
          "
          @setLanguage="setLanguage"
          @resetCode="handleResetCode"
        />
      </v-col>
    </v-row>
    <v-row v-if="user">
      <v-col
        :cols="12"
        :lg="4"
        v-bind:class="{ 'text-center': $vuetify.breakpoint.mobile }"
      >
        <v-btn class="mr-2" color="accent" height="35" @click="openFileInput"
          >Load File</v-btn
        >
        <input
          v-show="false"
          type="file"
          ref="codeFileInput"
          accept=".c,.c99,.cxx,.cpp,.h,.java,.py,.txt,text/*"
          @change="loadFile"
        />
      </v-col>
      <v-spacer></v-spacer>
      <v-col
        v-if="
          contentView?.getMetaData()?.getProblemMeta()?.getEvaluationType() !==
          EvaluationType.EVALUATION_TYPE_SQL
        "
        :cols="11"
        :lg="2"
        v-bind:class="{ 'text-center': $vuetify.breakpoint.mobile }"
      >
        <v-checkbox
          v-if="!hasWrappedIo"
          v-model="isUserInput"
          label="Custom input"
        ></v-checkbox>
      </v-col>
      <v-col
        :cols="12"
        :lg="4"
        v-bind:class="{
          'text-center': $vuetify.breakpoint.mobile,
          'text-end': !$vuetify.breakpoint.mobile,
        }"
      >
        <v-btn
          class="mr-2"
          :disabled="submitting"
          color="accent"
          height="35"
          @click="testRun"
          >Run Code</v-btn
        >
        <v-btn
          :disabled="submitting"
          color="accent"
          height="35"
          @click="fullSubmit"
          >Submit</v-btn
        >
      </v-col>
    </v-row>
    <v-row v-else>
      <v-spacer></v-spacer>
      <v-col :cols="2" class="pt-2 pb-1">
        <router-link to="/community/login"> Login </router-link> to submit.
      </v-col>
    </v-row>

    <v-row class="mx-2" v-if="isUserInput">
      <v-textarea outlined label="input" v-model="stdin"></v-textarea>
    </v-row>

    <error-message :ex="ex"></error-message>

    <v-row ref="judgingStatus" class="mx-2">
      <v-col>
        <div v-if="submissionId">
          <submission-status
            v-if="submissionStatus && submissionStatus.getStatus() == 0"
            title="Waiting..."
            subtitle="Waiting in the queue for your turn. Please be patient."
            imageUrl="judgingImg.svg"
            :status="submissionStatus && submissionStatus.getStatus()"
          >
          </submission-status>

          <submission-status
            v-else-if="!judgingCompleted"
            title="Judging..."
            :subtitle="'We are processing your submission. ' + testsProgress"
            imageUrl="judgingImg.svg"
            :status="submissionStatus && submissionStatus.getStatus()"
          >
          </submission-status>

          <submission-status
            v-else-if="
              judgingCompleted &&
              submissionStatus.getStatus() == 13 &&
              submissionStatus.getSubmissionType() == 0
            "
            :title="submissionStatusTitle(submissionStatus)"
            :subtitle="scoreSubtitle(submissionStatus)"
            :imageUrl="randomCorrectImage()"
            :status="submissionStatus && submissionStatus.getStatus()"
          >
            <v-row>
              <v-col :cols="6" :md="3" :lg="2">
                <stats-col title="Time Taken">
                  {{ submissionStatus.getTimeTaken() }} ms
                </stats-col>
              </v-col>
              <v-col :cols="6" :md="3" :lg="2">
                <stats-col title="Memory Taken">
                  {{ submissionStatus.getMemoryTaken() }} KiB
                </stats-col>
              </v-col>
              <v-spacer> </v-spacer>
            </v-row>
            <v-row v-if="submissionStatus.getSubtasks()" class="mt-5">
              <v-col>
                Subtasks
                <v-data-table
                  :headers="subtasksHeaders"
                  :items="subtaskList"
                  class="elevation-1"
                >
                  <template v-slot:item="{ item }">
                    <tr>
                      <td>{{ item.getSubtaskId() }}</td>
                      <td v-bind:class="statusClass(item.getStatus())">
                        {{ statusTitle(item.getStatus()) }}
                      </td>
                      <td>{{ prettyScore(100 * item.getScore()) }}</td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row v-if="publicTestCase">
              <v-spacer></v-spacer>
              <v-col :cols="6" :md="4" :lg="3">
                <router-link
                  v-if="submissionStatus && submissionStatus.getSubmissionId()"
                  :to="`/submissions/${submissionStatus.getSubmissionId()}`"
                  height="25"
                  target="_blank"
                  class="view_details_btn"
                >
                  See Test Case Details
                </router-link>
              </v-col>
            </v-row>
          </submission-status>

          <submission-status
            v-else-if="
              judgingCompleted &&
              submissionStatus.getStatus() == 13 &&
              submissionStatus.getSubmissionType() == 1
            "
            title="Samples Passed"
            subtitle="Great! Submit to run against full test set"
            :imageUrl="randomCorrectImage()"
            :status="submissionStatus && submissionStatus.getStatus()"
          >
            <v-row>
              <v-col :cols="6" :md="3" :lg="2">
                <stats-col title="Time Taken">
                  {{ submissionStatus.getTimeTaken() }} ms
                </stats-col>
              </v-col>
              <v-col :cols="6" :md="3" :lg="2">
                <stats-col title="Memory Taken">
                  {{ submissionStatus.getMemoryTaken() }} KiB
                </stats-col>
              </v-col>
              <v-spacer> </v-spacer>
              <v-col :cols="6" :md="4" :lg="3" align="end">
                <v-btn
                  :disabled="submitting"
                  color="accent"
                  height="35"
                  @click="fullSubmit"
                  >Submit</v-btn
                >
              </v-col>
            </v-row>
            <tests-results
              :resultViews="submissionStatus.getSampleResultViewsList()"
            ></tests-results>
          </submission-status>

          <submission-status
            v-else-if="
              judgingCompleted &&
              submissionStatus.getStatus() == 13 &&
              submissionStatus.getSubmissionType() == 2
            "
            title="Successful"
            subtitle="Code ran successfully for custom input"
            :imageUrl="randomCorrectImage()"
            :status="submissionStatus && submissionStatus.getStatus()"
          >
            <v-row>
              <v-col :cols="6" :md="3" :lg="2">
                <stats-col title="Time Taken">
                  {{ submissionStatus.getTimeTaken() }} ms
                </stats-col>
              </v-col>
              <v-col :cols="6" :md="3" :lg="2">
                <stats-col title="Memory Taken">
                  {{ submissionStatus.getMemoryTaken() }} KiB
                </stats-col>
              </v-col>
              <v-spacer> </v-spacer>
              <v-col :cols="6" :md="4" :lg="3" align="end">
                <v-btn
                  :disabled="submitting"
                  color="accent"
                  height="35"
                  @click="fullSubmit"
                  >Submit</v-btn
                >
              </v-col>
            </v-row>
            <tests-results
              :isUserInput="isUserInput"
              :resultViews="submissionStatus.getSampleResultViewsList()"
            ></tests-results>
          </submission-status>

          <submission-status
            v-else
            :title="statusTitle(submissionStatus.getStatus())"
            :subtitle="statusSubtitle(submissionStatus)"
            imageUrl="Error.svg"
            :status="submissionStatus && submissionStatus.getStatus()"
          >
            <text-block
              v-if="submissionStatus.getResult()"
              class="mb-2"
              style="overflow-x: scroll"
            >
              <pre
                >{{ submissionStatus && submissionStatus.getResult() }}
            </pre
              >
            </text-block>
            <tests-results
              class="ma-2"
              v-if="submissionStatus.getSampleResultViewsList()"
              :resultViews="submissionStatus.getSampleResultViewsList()"
            ></tests-results>
            <v-row v-if="submissionStatus?.getSubtasks()" class="mt-5">
              <v-col>
                Subtasks
                <v-data-table
                  :headers="subtasksHeaders"
                  :items="subtaskList"
                  class="elevation-1"
                >
                  <template v-slot:item="{ item }">
                    <tr>
                      <td>{{ item.getSubtaskId() }}</td>
                      <td v-bind:class="statusClass(item.getStatus())">
                        {{ statusTitle(item.getStatus()) }}
                      </td>
                      <td>{{ prettyScore(100 * item.getScore()) }}</td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row
              v-if="
                publicTestCase &&
                submissionStatus.getSubmissionType() === SubmissionType.FULL
              "
            >
              <v-spacer></v-spacer>
              <v-col :cols="6" :md="4" :lg="3">
                <router-link
                  v-if="submissionStatus && submissionStatus.getSubmissionId()"
                  :to="`/submissions/${submissionStatus.getSubmissionId()}`"
                  height="25"
                  target="_blank"
                  class="view_details_btn"
                >
                  See Test Case Details
                </router-link>
              </v-col>
            </v-row>
          </submission-status>
        </div>
      </v-col>
    </v-row>
  </span>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import Editor from '../components/Editor'
import StatsCol from '../components/StatsCol'
import SubmissionStatus from '../components/SubmissionStatus'
import TestsResults from './TestsResults.vue'
import TextBlock from './TextBlock.vue'
import ErrorMessage from '../components/ErrorMessage'
import { notifyError } from '../utils/notify.js'
import {
  statusTitle,
  statusClass,
  isFullyCorrect,
  submissionStatusTitle,
  submissionScoreString,
} from '../utils/status.js'
import { prettyScore } from '../utils/helper.js'

import judge from 'codedrills_proto/io/codedrills/proto/judge/judge_service_grpc_web_pb'
const Status = proto.io.codedrills.proto.judge.Status
const TestCaseVisibility = proto.io.codedrills.proto.content.TestCaseVisibility
const SubmissionType = proto.io.codedrills.proto.judge.SubmissionType
const EvaluationType = proto.io.codedrills.proto.judge.EvaluationType
const IOType = proto.io.codedrills.proto.judge.IOType

export default {
  props: {
    contentView: {
      type: Object,
      required: true,
    },
    parentPreview: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      code: null,
      codeFile: null,
      language: null,
      ex: null,
      stdin: null,
      isUserInput: false,
      subtasksHeaders: [
        { text: 'Subtask ID' },
        { text: 'Status' },
        { text: 'Score' },
      ],
      Status,
      SubmissionType,
      EvaluationType,
    }
  },
  name: 'codingArea',
  computed: {
    ...mapState('user', ['hasPremiumAccess', 'editorConfig', 'user']),
    ...mapGetters('judge', [
      'submissionStatus',
      'judgingCompleted',
      'submitStatus',
      'submitted',
      'submitting',
    ]),
    ...mapState('judge', ['submissionId', 'submissionStatus', 'codeTemplate']),
    subtaskList() {
      if (this.submissionStatus) {
        return this.submissionStatus.getSubtasks()?.getSubtaskResultsList()
      }
      return []
    },
    publicTestCase() {
      return (
        this.parentPreview
          ?.getMetaData()
          ?.getContestMeta()
          ?.getTestCaseVisibility() ===
        TestCaseVisibility.TEST_CASE_VISIBILITY_PUBLIC
      )
    },
    urlPrefix() {
      if (this.parentPreview) {
        return (
          '/' +
          this.parentPreview.getContentType().toLowerCase() +
          's/' +
          this.parentPreview.getUrl()
        )
      }
      return null
    },
    parentId() {
      if (this.parentPreview) {
        return this.parentPreview.getId()
      }
      return null
    },
    testsProgress() {
      if (!this.submissionStatus || this.submissionStatus.getTotalTests() == 0)
        return ''
      return (
        '' +
        this.submissionStatus.getProcessedTests() +
        '/' +
        this.submissionStatus.getTotalTests() +
        ' tests processed.'
      )
    },
    hasWrappedIo() {
      console.log(
        'hasWrappedIo',
        this.contentView?.getMetaData()?.getProblemMeta()?.getIoType(),
      )
      return (
        this.contentView?.getMetaData()?.getProblemMeta()?.getIoType() ===
        IOType.WRAPPED
      )
    },
  },

  methods: {
    ...mapActions('judge', ['submit', 'fetchCodeTemplate']),
    ...mapMutations('judge', ['clearSubmission']),
    statusClass,
    statusTitle,
    submissionStatusTitle,
    prettyScore,
    async handleResetCode(done) {
      // console.log('handleResetCode', done)
      this.removeOldCode(done)
    },
    fullSubmit() {
      //console.log("loadtesting submit"); for(var i = 0; i < 500; ++i)
      this.submitCode(true)
    },
    testRun() {
      this.submitCode(false)
    },
    submitCode(isFull) {
      console.log('Contest id', this.parentId)
      this.clearSubmission()
      console.log(isFull, this.isUserInput, this.stdin)
      this.submit({
        id: this.contentView.getId(),
        version: this.contentView.getVersion(),
        code: this.code,
        language: this.language,
        isFull: isFull,
        parentId: this.parentId,
        isUserInput: this.isUserInput,
        stdin: this.isUserInput && !isFull ? this.stdin : '',
      })
        .then((__) =>
          this.$nextTick(() => {
            this.$vuetify.goTo(this.$refs.judgingStatus)
          }),
        )
        .catch(notifyError)
    },
    autopick() {
      var url = (this.urlPrefix || '/') + '?autopick=true'
      this.$router.push(url)
    },
    statusSubtitle(submissionStatus) {
      var status = submissionStatus.getStatus()
      if (status == Status.COMPILE_ERROR) return 'Your solution did not compile'
      if (status == Status.CORRECT_ANSWER)
        return 'Congratulations, your solution passed'
      if (11 <= status && status <= 16) {
        if (submissionStatus.getFirstIncorrectTestSet() != '') {
          return (
            'When running test #' + submissionStatus.getFirstIncorrectTestId()
          )
        } else {
          return 'There was an error when running one of the tests'
        }
      }
      if (status == Status.JUDGE_ERROR)
        return 'Something went wrong when running your solution. Please report to hello@codedrills.io'
      return ''
    },
    scoreSubtitle(submissionStatus) {
      if (isFullyCorrect(submissionStatus))
        return 'Whoaa! You solved this challenge.'

      return (
        'Score: ' + submissionScoreString(submissionStatus, this.subtaskList)
      )
    },
    randomCorrectImage() {
      return ['Congratulations.svg', 'Cheers.svg', 'Party.svg'][
        this.submissionId % 3
      ]
    },
    loadFile() {
      var file = this.$refs.codeFileInput.files.item(0)
      var ctx = this
      file.text().then((code) => {
        this.code = code
        this.codeFile = null
      })
    },
    openFileInput() {
      this.$refs.codeFileInput.click()
    },
    setLanguage(language) {
      console.log('setLanguage in coding Area ...', language)
      this.language = language
    },
    loadTemplateOrLocalCode() {
      console.log('loadTemplateOrLocalCode')
      if (this.user) {
        var key =
          this.user.uid + '-' + this.language + '-' + this.contentView.getId()
        console.log('key on get', key)
        if (localStorage.getItem(key)) {
          console.log('loading old code', key, localStorage.getItem(key))
          var val = JSON.parse(localStorage.getItem(key))
          const now = Date.now()
          console.log('val', val)
          if (now > val.ttl || val.code === '') {
            console.log('removing old code')
            localStorage.removeItem(key)
            this.loadTemplate()
          } else {
            this.code = val.code
          }
        } else {
          this.loadTemplate()
        }
      } else {
        this.loadTemplate()
      }
    },
    async removeOldCode(done) {
      // console.log('removeOldCode', done)
      if (this.user) {
        var key =
          this.user.uid + '-' + this.language + '-' + this.contentView.getId()
        if (localStorage.getItem(key)) {
          localStorage.removeItem(key)
        }
      }
      this.loadTemplate(done)
    },
    loadTemplate(done) {
      // console.log('loading template .....', this.language, done)
      console.log(
        'code template map',
        this.contentView
          .getMetaData()
          .getProblemMeta()
          .getCodeTemplateMap()
          ?.get(this.language),
      )
      if (
        this.contentView
          .getMetaData()
          .getProblemMeta()
          .getCodeTemplateMap()
          ?.get(this.language)
      ) {
        console.log('loading template', this.language)
        this.fetchCodeTemplate({
          problemId: this.contentView.getId(),
          version: this.contentView.getVersion(),
          language: this.language,
        })
          .then((__) => {
            this.code = this.codeTemplate
            // console.log('resolving done', done);
            if (done) done('Code Reset Successful', 'success') //this will tigger a callback to Editor
          })
          .catch((ex) => {
            console.log('Error in fetching code template..', ex)
            //TODO: return proper error from backend
            this.$store.dispatch('notifs/addNotif', {
              text: 'Code reset failed!' + '!',
              type: 'error',
            })
          })
      } else if (
        this.contentView
          .getMetaData()
          ?.getProblemMeta()
          ?.getEvaluationType() === 1
      ) {
        this.code = '## Write your code here'
        // console.log('resolving done', done);
        if (done) done('Code Reset successful', 'success') //this will tigger a callback to Editor
        return
      } else if (
        this.contentView.getMetaData().getProblemMeta().getIoType() === 2 ||
        this.contentView.getMetaData().getProblemMeta().getEvaluationType() ===
          2
      ) {
        this.fetchCodeTemplate({
          problemId: this.contentView.getId(),
          version: this.contentView.getVersion(),
          language: this.language,
        })
          .then((__) => {
            this.code = this.codeTemplate
            // console.log('resolving done', done);
            if (done) done('Code Reset successful', 'success') //this will tigger a callback to Editor
          })
          .catch((ex) => {
            console.log('Error in fetching code template ///', ex)
            this.$store.dispatch('notifs/addNotif', {
              text: 'Code reset failed!' + '!',
              type: 'error',
            })
          })
      } else {
        this.code = ''
        if (done) done('Code Reset successful', 'success') //this will tigger a callback to Editor
      }
    },
  },
  components: {
    Editor,
    StatsCol,
    SubmissionStatus,
    TestsResults,
    TextBlock,
    ErrorMessage,
  },
  mounted() {
    this.clearSubmission()
  },
  watch: {
    code: {
      handler() {
        console.log('Code Changed !')
        const now = Date.now()
        var expiry = now + 14 * 24 * 60 * 60 * 1000
        if (this.user) {
          // Only save if code is not empty and no item in the local storage
          var key =
            this.user.uid + '-' + this.language + '-' + this.contentView.getId()
          console.log('adding to local storage', key, this.code)
          localStorage.setItem(
            key,
            JSON.stringify({
              code: this.code,
              language: this.language,
              ttl: expiry,
            }),
          )
        }
      },
      deep: true,
    },
    language: {
      handler() {
        console.log('Language Changed !', this.language)
        if (this.language) {
          console.log('Loading template on languge change', this.language)
          this.loadTemplateOrLocalCode()
        } else {
          console.log('Language is null')
          //commented this because on refresh it was removing the code as language was set to null for few instants before setting it to the choosen/default language
          // this.code = ''
        }
      },
      immediate: true,
    },
  },
}
</script>
<style scoped>
.view_details_btn {
  color: #33a79d;
  text-decoration: none;
}
</style>
