<template>
  <page
    :title="title"
    :apiStatusList="[fetchContentViewStatus]"
    :imageUrl="contentView && contentView.getMetaData().getImageUrl()"
    :appendImageUrl="appendImageUrl"
    :titleLink="parentUrl"
    :minimal="isMinimal"
    :breadcrumbs="breadcrumbs"
  >
    <span v-if="contentView">
      <article-view
        v-if="contentView.getDataView().getArticleView()"
        :contentView="contentView"
      ></article-view>
      <problem-view
        :parentPreview="parentPreview"
        v-else-if="contentView.getDataView().getProblemView()"
        :contentView="contentView"
      ></problem-view>
      <contest-view
        v-else-if="contentView.getDataView().getContestView()"
        :contentView="contentView"
      ></contest-view>
      <drill-view
        v-else-if="contentView.getDataView().getDrillView()"
        :contentView="contentView"
      ></drill-view>
      <!-- <test-view v-else-if="contentView.getDataView().getTestView()" :contentView="contentView"></test-view> -->
    </span>

    <template v-slot:subtitle v-if="hasSubtitle">
      <problem-subtitle
        v-if="parentPreview && contentView.getDataView().getProblemView()"
        :contentView="contentView"
      ></problem-subtitle>
      <contest-subtitle
        v-if="contentView.getMetaData().getContestMeta()"
        :contentMeta="contentView.getMetaData()"
        :contestUrl="contentView.getUrl()"
      ></contest-subtitle>
    </template>
    <template v-slot:appendBanner v-if="hasBanner">
      <div v-if="$vuetify.breakpoint.lgAndUp">
        <mascot :preposition="preposition" :time="contestTime"></mascot>
      </div>
      <div v-else>{{ preposition }} : {{ contestTime }}</div>
    </template>
  </page>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import Page from '../components/Page'
import ArticleView from '../components/ArticleView'
import ProblemView from '../components/ProblemView'
import ContestView from '../components/ContestView'
import TestView from '../components/hiring/TestView'
import ProblemSubtitle from '../components/ProblemSubtitle'
import ContestSubtitle from '../components/ContestSubtitle'
import DrillView from '../components/DrillView'
import Mascot from '../components/Mascot'
import { prettyday } from '../utils/helper.js'

export default {
  props: {
    parentTypes: {
      type: String,
      required: false,
    },
    parentUrl: {
      type: String,
      required: false,
    },
    types: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState('content', [
      'contentView',
      'fetchContentViewStatus',
      'parentPreview',
    ]),
    ...mapState('timesync', ['serverTime']),
    contentType() {
      return (
        this.types &&
        this.types.substring(0, this.types.length - 1).toUpperCase()
      )
    },
    parentContentType() {
      return (
        this.parentTypes &&
        this.parentTypes.substring(0, this.parentTypes.length - 1).toUpperCase()
      )
    },
    title() {
      return this.contentView ? this.contentView.getTitle() : ''
    },
    appendImageUrl() {
      return null
    },
    isMinimal() {
      if (this.contentType == 'ARTICLE') return true
      if (this.contentType == 'TEST') return true
      if (this.contentType == 'PROBLEM')
        return (
          this.parentContentType == 'CONTEST' ||
          this.parentContentType == 'TEST'
        )
      return false
    },
    liveContest() {
      return (
        !!this.contentView &&
        !!this.contentView.getMetaData().getContestMeta() &&
        this.contentView.getMetaData().getContestMeta().getStartAt() <=
          this.serverTime &&
        this.contentView.getMetaData().getContestMeta().getEndAt() >=
          this.serverTime
      )
    },
    hasBanner() {
      return (
        (this.contentType == 'CONTEST' ||
          this.parentContentType == 'CONTEST') &&
        (this.liveContest || this.upcomingContest)
      )
    },
    upcomingContest() {
      return (
        !!this.contentView &&
        !!this.contentView.getMetaData().getContestMeta() &&
        this.contentView.getMetaData().getContestMeta().getStartAt() >
          this.serverTime
      )
    },
    registrationContest() {
      return this.contentView
        ?.getMetaData()
        ?.getTagsList()
        .includes('types/registration')
    },
    hasSubtitle() {
      return (
        this.contentView &&
        (this.contentView.getDataView().getContestView() ||
          this.contentView.getDataView().getProblemView())
      )
    },
    breadcrumbs() {
      var crumbs = []
      if (!this.contentView) return null
      if (
        this.contentType == 'CONTEST' ||
        this.parentContentType == 'CONTEST'
      ) {
        crumbs.push({
          text: 'Contests',
          href: '/contests',
        })
      }
      if (this.contentType == 'DRILL' || this.parentContentType == 'DRILL') {
        crumbs.push({
          text: 'Drills',
          href: '/drills',
        })
      }
      if (this.parentUrl && this.parentPreview) {
        crumbs.push({
          text: this.parentPreview.getTitle(),
          href: this.getUrl(this.parentContentType, this.parentUrl),
        })
      }
      crumbs.push({
        text: this.title,
        href: this.getUrl(this.contentType, this.url),
        disabled: true,
      })
      if (crumbs.length == 1) return null
      return crumbs
    },
    preposition() {
      if (this.registrationContest) {
        if (this.upcomingContest) return 'Ends in'
        else return ''
      }
      if (this.liveContest) return 'Ends in'
      if (this.upcomingContest) return 'Starts in'
      else return ''
    },
    contestTime() {
      if (
        this.contentView &&
        this.contentView.getMetaData() &&
        this.contentView.getMetaData().getContestMeta()
      ) {
        var diff = this.serverTime
        if (this.liveContest)
          diff =
            this.contentView.getMetaData().getContestMeta().getEndAt() -
            this.serverTime
        if (this.upcomingContest)
          diff =
            this.contentView.getMetaData().getContestMeta().getStartAt() -
            this.serverTime
        return prettyday(diff)
      }
    },
  },
  methods: {
    ...mapActions('content', ['fetchContentView']),
    ...mapMutations('content', ['clearContentView']),
    refreshContent() {
      this.clearContentView()
      this.fetchContentView({
        parentType: this.parentContentType,
        parentUrl: this.parentUrl,
        type: this.contentType,
        url: this.url,
      }).then((__) => {
        document.title = this.contentView.getTitle() + ' - CodeDrills'
      })
    },

    getUrl(type, url) {
      return '/' + type.toLowerCase() + 's/' + url
    },
  },
  created() {
    console.log('Content', this.types, this.url)
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => vm.refreshContent())
  },
  components: {
    Page,
    ArticleView,
    ProblemView,
    ContestView,
    TestView,
    ProblemSubtitle,
    ContestSubtitle,
    DrillView,
    Mascot,
  },
}
</script>
