var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page', {
    attrs: {
      "minimal": "",
      "title": "Problems",
      "apiStatusList": [_vm.fetchUserContentPreviewsStatus]
    },
    scopedSlots: _vm._u([{
      key: "subtitle",
      fn: function () {
        return undefined;
      },
      proxy: true
    }])
  }, [_c('problemList', {
    attrs: {
      "problems": _vm.userContentPreviews,
      "autopick": !!this.$route.query.autopick
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }