<template>
  <span>
    <v-sheet v-if="!minimal" class="pr-5 grey lighten-5" :height="200">
      <v-row class="ml-4">
        <v-col :cols="0" :md="1"> </v-col>
        <v-col
          v-if="imageUrl"
          :cols="2"
          :lg="1"
          class="pt-10 mr-5 hidden-sm-and-down"
        >
          <v-skeleton-loader
            v-if="!loadingCompleted && appendImageUrl"
            type="avatar"
            tile
            :width="130"
          />
          <v-avatar :size="130"> <img :src="imageUrl" /> </v-avatar>
        </v-col>
        <v-col v-if="this.$slots.subtitle" :cols="12" :md="8">
          <v-row class="grey lighten-5">
            <v-col :cols="10">
              <v-skeleton-loader
                v-if="!loadingCompleted"
                :height="120"
                :width="400"
              />
              <span
                v-else
                class="grey lighten-5 font-weight-bold text-truncate"
                :class="{
                  'display-1': $vuetify.breakpoint.lgAndUp,
                  headline: $vuetify.breakpoint.mdAndDown,
                  title: $vuetify.breakpoint.smAndDown,
                }"
              >
                {{ title }}
              </span>
            </v-col>
          </v-row>
          <v-skeleton-loader
            v-if="!loadingCompleted"
            type="sentence"
            :height="30"
            :width="400"
          />
          <slot v-else name="subtitle"> </slot>
        </v-col>
        <v-col v-else :cols="12" :md="8">
          <v-row class="grey lighten-5 mt-10">
            <v-col :cols="10">
              <v-skeleton-loader
                v-if="!loadingCompleted"
                type="image"
                :height="100"
              />
              <span
                v-else
                class="grey lighten-5 font-weight-bold text-truncate ml-8"
                :class="{
                  'display-1': $vuetify.breakpoint.lgAndUp,
                  headline: $vuetify.breakpoint.mdAndDown,
                  title: $vuetify.breakpoint.smAndDown,
                }"
              >
                {{ title }}
              </span>
            </v-col>
          </v-row>
        </v-col>
        <v-col :cols="2" :lg="1" class="pt-10 hidden-sm-and-down">
          <v-skeleton-loader
            v-if="!loadingCompleted && appendImageUrl"
            type="avatar"
            tile
            :width="130"
          />
          <v-avatar v-else-if="appendImageUrl" tile :size="130">
            <img :src="appendImageUrl" />
          </v-avatar>
          <slot v-else name="appendBanner"> </slot>
        </v-col>
      </v-row>
    </v-sheet>
    <v-sheet v-else class="pr-5 grey lighten-5" :height="60">
      <v-row class="ml-4">
        <v-col :cols="0" :md="1"> </v-col>
        <v-col :cols="12" :md="8">
          <v-row class="grey lighten-5">
            <v-col :cols="10">
              <v-skeleton-loader
                v-if="!loadingCompleted"
                type="image"
                :height="40"
              />
              <span
                v-else
                class="grey lighten-5 font-weight-bold text-truncate ml-8"
                :class="{
                  'text-h5': $vuetify.breakpoint.lgAndUp,
                  headline: $vuetify.breakpoint.mdAndDown,
                  title: $vuetify.breakpoint.smAndDown,
                }"
              >
                {{ title }}
              </span>
            </v-col>
          </v-row>
        </v-col>
        <v-col :cols="2" :lg="1" class="pt-10 hidden-sm-and-down">
          <v-skeleton-loader
            v-if="!loadingCompleted && appendImageUrl"
            type="avatar"
            tile
            :width="130"
          />
          <v-avatar v-else-if="appendImageUrl" tile :size="130">
            <img :src="appendImageUrl" />
          </v-avatar>
          <slot v-else name="appendBanner"> </slot>
        </v-col>
      </v-row>
    </v-sheet>

    <v-container fluid style="min-height: 100vh">
      <error v-if="loadingError">{{ customErrorMessage }}</error>
      <v-row v-else-if="!loadingCompleted">
        <v-col :cols="12" :md="10" :offset-md="1" :offset-sm="0">
          <v-skeleton-loader type="image" :height="600" />
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col :cols="12" :md="10" :offset-md="1" :offset-sm="0">
          <v-breadcrumbs
            divider=">"
            v-if="breadcrumbs && breadcrumbs.length > 0"
            :items="breadcrumbs"
          >
          </v-breadcrumbs>
          <slot></slot>
        </v-col>
      </v-row>
    </v-container>
  </span>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Loader from '@/components/Loader.vue'
import Error from '@/components/Error.vue'
import { ApiCallStatus } from '../utils/api.js'

export default {
  name: 'page',
  props: {
    title: {
      type: String,
      required: true,
    },
    apiStatusList: {
      type: Array,
      required: false,
    },
    imageUrl: {
      type: String,
      required: false,
    },
    appendImageUrl: {
      type: String,
      required: false,
    },
    loginRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    breadcrumbs: {
      type: Array,
      required: false,
      default: null,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    minimal: {
      type: Boolean,
      required: false,
      default: false,
    },
    errorMessage: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    ...mapState('user', ['user']),
    loadingCompleted() {
      var completed = this.apiStatusList.reduce(
        (t, s) => t && (s == ApiCallStatus.SUCCESS || s == ApiCallStatus.ERROR),
        true,
      )
      return completed && this.loading == false
    },
    loadingError() {
      return this.apiStatusList.reduce(
        (t, s) => t || s == ApiCallStatus.ERROR,
        false,
      )
    },
    customErrorMessage() {
      if (this.errorMessage) return this.errorMessage
      return 'An error ocurred while loading this page. Please try again'
    },
  },
  watch: {
    loginRequired: function () {
      this.checkUser()
    },
    user: function () {
      this.checkUser()
    },
  },
  methods: {
    checkUser() {
      //console.log("LoginRequired", this.loginRequired);
      if (!this.loginRequired) return
      if (!this.user || this.user.isAnnonymous)
        return this.$router.push('/community/login')
    },
  },
  mounted() {
    this.checkUser()
    //console.log("Title link ....", this.titleLink);
  },
  components: {
    Loader,
    Error,
  },
}
</script>
<style scoped>
.dashboard_link {
  color: #000000;
  text-decoration: none;
}
</style>
