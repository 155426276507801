<template>
  <v-expansion-panels class="mb-2" multiple v-if="resultViews">
    <v-expansion-panel v-for="rv in resultViews" :key="rv.getId()">
      <v-expansion-panel-header>
        <v-row>
          <v-col> Test #{{ rv.getId() }} </v-col>
          <v-spacer />
          <v-col>
            <span
              class="text-capitalize"
              v-bind:class="{
                'grey--text': isSkippedTest(rv),
                'green--text': isCorrectTest(rv),
                'red--text': isIncorrectTest(rv),
              }"
            >
              {{ resolveVerdict(rv) }}
            </span>
          </v-col>
        </v-row>
      </v-expansion-panel-header>

      <v-expansion-panel-content
        v-if="rv.getInputView().getArgInputViewsList().length > 0"
      >
        <v-row> <v-col class="subtitle-2"> Input </v-col> </v-row>
        <v-row
          v-for="input in rv.getInputView().getArgInputViewsList()"
          :key="input.getName()"
        >
          <v-col cols="4">
            <v-text-field
              single
              outlined
              readonly
              :label="input.getName()"
              :value="input.getValue()"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row> <v-col class="subtitle-2"> Output </v-col> </v-row>
        <v-row
          ><v-col>
            <text-block
              v-if="rv.getActualView() && rv.getActualView().getArgOutputView()"
            >
              <pre>{{ rv.getActualView().getArgOutputView().getValue() }}</pre>
            </text-block>
          </v-col></v-row
        >

        <v-row v-if="rv.getExpectedView()">
          <v-col class="subtitle-2"> Expected </v-col>
        </v-row>
        <v-row v-if="rv.getExpectedView()"
          ><v-col>
            <text-block>
              <pre>{{
                rv.getExpectedView().getArgOutputView().getValue()
              }}</pre>
            </text-block>
          </v-col></v-row
        >

        <v-row> <v-col class="subtitle-2"> Logs </v-col> </v-row>
        <v-row
          ><v-col>
            <text-block>
              <pre>{{ rv.getLogs() }}</pre>
            </text-block>
          </v-col></v-row
        >
      </v-expansion-panel-content>

      <v-expansion-panel-content v-else>
        <v-row> <v-col class="subtitle-2"> Input </v-col> </v-row>
        <v-row>
          <v-col>
            <text-block>
              <pre>{{ rv.getInputView().getRawInput() }}</pre>
            </text-block>
          </v-col>
        </v-row>

        <v-row> <v-col class="subtitle-2"> Output </v-col> </v-row>
        <v-row
          ><v-col>
            <text-block v-if="rv.getActualView()">
              <pre>{{ rv.getActualView().getRawOutput() }}</pre>
            </text-block>
          </v-col></v-row
        >

        <v-row v-if="rv.getExpectedView()">
          <v-col class="subtitle-2"> Expected </v-col>
        </v-row>
        <v-row v-if="rv.getExpectedView()"
          ><v-col>
            <text-block>
              <pre>{{ rv.getExpectedView().getRawOutput() }}</pre>
            </text-block>
          </v-col></v-row
        >
        <v-row> <v-col class="subtitle-2"> Logs </v-col> </v-row>
        <v-row
          ><v-col>
            <text-block>
              <pre>{{ rv.getLogs() }}</pre>
            </text-block>
          </v-col></v-row
        >
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import Editor from '../components/Editor'
import StatsCol from '../components/StatsCol'
import SubmissionStatus from '../components/SubmissionStatus'
import TextBlock from './TextBlock.vue'
import { f } from 'vue-carousel-3d'

export default {
  props: {
    resultViews: {
      type: Array,
      required: true,
    },
    isUserInput: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    ...mapGetters('judge', ['statusKeys']),
  },
  methods: {
    isSkippedTest(rv) {
      return rv.getStatus() === 17 ? true : false
    },
    isCorrectTest(rv) {
      return rv.getStatus() === 13 ? true : false
    },
    isIncorrectTest(rv) {
      return rv.getStatus() >= 10 &&
        rv.getStatus() != 13 &&
        rv.getStatus() != 17
        ? true
        : false
    },
    resolveVerdict(rv) {
      let res = this.statusKeys[rv.getStatus()]
        .split('_')
        .join(' ')
        .toLowerCase()
      console.log('res...', res)
      if (this.isUserInput) {
        return 'Custom run completed'
      }
      return res
    },
  },
  components: {
    TextBlock,
  },
}
</script>
<style scoped></style>
