var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    key: _vm.renderCount,
    attrs: {
      "grid-list-xs": ""
    }
  }, [_vm.teamView ? _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "444",
      "tile": "",
      "elevation": "1"
    }
  }, [_c('v-card-title', [!_vm.isSingleMemberTeam ? _c('v-row', [_c('v-col', [_vm._v(" " + _vm._s(_vm.teamView.getName()) + " ")])], 1) : _vm._e(), !_vm.isSingleMemberTeam ? _c('v-row', [_c('v-col', [_vm._v(" " + _vm._s(_vm.teamView.getOrganizationsList()[0]) + " ")])], 1) : _vm._e(), _c('v-row', [_c('v-col', _vm._l(_vm.teamAttributeValues, function (item) {
    return _c('span', {
      key: item[0]
    }, [_vm._v(" " + _vm._s(item[0]) + " : " + _vm._s(item[1].stringValue) + " | ")]);
  }), 0)], 1)], 1), _c('v-card-text', [_c('v-list', {
    attrs: {
      "dense": ""
    }
  }, [_vm.isSingleMemberTeam ? _c('v-list-item', [_vm._v(" " + _vm._s(_vm.teamView.getOrganizationsList()[0]) + " ")]) : _vm._e(), _vm._l(_vm.teamView.getMembersList(), function (m, idx) {
    return _c('v-list-item', {
      key: idx
    }, [_c('v-list-item-content', [_c('v-list-item-title', [m.getHandle() ? _c('router-link', {
      staticClass: "text-decoration-none router_link",
      attrs: {
        "to": `/profiles/${m.getHandle()}`
      }
    }, [_vm._v(" " + _vm._s(m.getHandle()) + " ")]) : _vm.isCoach(m) ? _c('span', [_vm._v(" " + _vm._s(_vm.memberName(m)) + " Coach ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.memberName(m)) + " ")])], 1), _vm.isTeamMember ? _c('v-list-item-subtitle', _vm._l(_vm.memberAttributeValues(m), function (item) {
      return _c('span', {
        key: item[0] + ':' + idx
      }, [_vm._v(" " + _vm._s(item[0]) + " : " + _vm._s(item[1].stringValue) + " | ")]);
    }), 0) : _vm._e()], 1), _c('v-list-item-action', [_c('v-tooltip', {
      attrs: {
        "top": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref) {
          var on = _ref.on,
            attrs = _ref.attrs;
          return [_c('v-icon', _vm._g(_vm._b({
            attrs: {
              "color": _vm.memberShipStatus[m.getMembershipState()].color
            }
          }, 'v-icon', attrs, false), on), [_vm._v(" " + _vm._s(_vm.memberShipStatus[m.getMembershipState()].icon) + " ")])];
        }
      }], null, true)
    }, [_c('span', [_vm._v(_vm._s(_vm.memberShipStatus[m.getMembershipState()].status))])])], 1)], 1);
  })], 2), _c('v-row')], 1), _c('confirm-dialog', {
    ref: "confirm"
  }), _vm.user && _vm.isFutureContest && _vm.editableContest ? _c('v-card-actions', [_c('v-row', {
    staticClass: "mx-1"
  }, [_vm.userStatus && !_vm.isTeamCreator && _vm.userStatus === 1 ? _c('v-btn', {
    attrs: {
      "plain": "",
      "text": "",
      "color": "green"
    },
    on: {
      "click": function ($event) {
        return _vm.handleTeamInvite('MEMBERSHIP_ACCEPTED');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.memberShipStatus[_vm.userStatus].message) + " ")]) : _vm._e(), _vm.userStatus && !_vm.isTeamCreator && (_vm.userStatus === 1 || _vm.userStatus === 2) ? _c('v-btn', {
    attrs: {
      "plain": "",
      "text": "",
      "color": "red"
    },
    on: {
      "click": function ($event) {
        return _vm.handleTeamInvite('MEMBERSHIP_DECLINED');
      }
    }
  }, [_vm._v(" Decline Invite ")]) : _vm._e(), _vm.isTeamCreator ? _c('v-btn', {
    attrs: {
      "plain": "",
      "text": "",
      "color": "danger"
    },
    on: {
      "click": _vm.handleDeleteTeam
    }
  }, [_vm._v("Delete")]) : _vm._e(), _c('error-message', {
    attrs: {
      "ex": _vm.ex
    }
  })], 1)], 1) : _vm._e(), _c('br')], 1)], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }