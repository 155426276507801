var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_vm.user && (_vm.past || _vm.live) && _vm.contestExtended ? _c('v-alert', {
    attrs: {
      "type": "info",
      "border": "left",
      "colored-border": "",
      "elevation": "2",
      "dismissible": ""
    }
  }, [_vm._v(" This contest is extended for " + _vm._s(_vm.formatTime(_vm.contestExtended)) + " for you. ")]) : _vm._e(), _c('v-row', [_c('v-col', [_vm.upcoming ? _c('upcoming-contest', {
    attrs: {
      "contentView": _vm.contentView
    }
  }) : _vm.past ? _c('past-contest', {
    attrs: {
      "contentView": _vm.contentView
    }
  }) : _c('live-contest', {
    attrs: {
      "contentView": _vm.contentView
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }