var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', {
    attrs: {
      "cols": 11
    }
  }, [_c('router-link', {
    staticClass: "text-decoration-none",
    attrs: {
      "to": '/contests/' + _vm.contentPreview.getUrl()
    }
  }, [_c('v-card', {
    staticClass: "pl-5 py-3",
    attrs: {
      "flat": "",
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "d-flex flex-no-wrap"
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-avatar', {
    staticClass: "d-flex align-center",
    attrs: {
      "size": "70",
      "tile": ""
    }
  }, [_c('v-img', {
    attrs: {
      "src": _vm.contentPreview.getMetaData().getImageUrl()
    }
  })], 1)], 1), _c('div', {
    staticClass: "mt-2"
  }, [_c('v-card-title', {
    staticClass: "text-caption-1",
    domProps: {
      "textContent": _vm._s(_vm.contentPreview.getTitle())
    }
  }), _c('v-card-subtitle', [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v(_vm._s(_vm.mdiCalendar))]), _vm._v(" " + _vm._s(_vm.formatDate(_vm.contentPreview.getMetaData().getContestMeta().getStartAt())) + " "), _c('v-icon', {
    staticClass: "ml-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(_vm._s(_vm.mdiClock))]), _vm._v(" " + _vm._s(_vm.contestDuration(_vm.contentPreview.getMetaData().getContestMeta())) + " ")], 1)], 1)])])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }