<template>
  <span>
    <v-row>
      <v-col :cols="6" :sm="4" :md="2">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title> To-do </v-list-item-title>
            <v-list-item-subtitle>
              {{ todo }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col :cols="6" :sm="4" :md="2">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title> Completed </v-list-item-title>
            <v-list-item-subtitle>
              {{ completed }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card class="pl-5 small">
          <pie-chart
            :data="submissionStats"
            :options="submissionChartOptions"
          ></pie-chart>
        </v-card>
      </v-col>
    </v-row>
  </span>
</template>
<script>
import PieChart from '../components/PieChart'

export default {
  data() {
    return {
      submissionChartOptions: {
        legend: {
          position: 'right',
          align: 'middle',
          labels: {
            boxWidth: 10,
          },
        },
        maintainAspectRatio: true,
      },
    }
  },
  props: {
    dashboardView: {
      type: Object,
      required: true,
    },
  },
  computed: {
    todo() {
      return (
        this.dashboardView &&
        this.dashboardView.getOverAllStatusView().getTotal() - this.completed
      )
    },
    completed() {
      if (this.dashboardView)
        return (
          this.dashboardView.getOverAllStatusView().getEasy() +
          this.dashboardView.getOverAllStatusView().getMedium() +
          this.dashboardView.getOverAllStatusView().getHard()
        )
    },
    submissionStats() {
      return {
        labels: ['Easy', 'Medium', 'Hard'],
        datasets: [
          {
            data: [
              this.dashboardView.getOverAllStatusView().getEasy(),
              this.dashboardView.getOverAllStatusView().getMedium(),
              this.dashboardView.getOverAllStatusView().getHard(),
            ],
            backgroundColor: ['#CEF2F2', '#17D8DA', '#116E6F'],
          },
        ],
      }
    },
  },
  components: {
    PieChart,
  },
}
</script>
<style scoped>
.small {
  width: 305px;
}
</style>
