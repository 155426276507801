var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_vm._l(_vm.getTopicTags, function (t) {
    return _c('v-chip', {
      key: t,
      staticClass: "mr-2 mt-2",
      attrs: {
        "outlined": "",
        "color": "grey lighten-2",
        "text-color": "grey",
        "label": ""
      }
    }, [_vm._v(" " + _vm._s(t) + " ")]);
  }), _c('v-row', [_vm.companyTags.length ? _c('v-col', {
    staticClass: "font-weight-medium",
    attrs: {
      "cols": 6,
      "md": 3,
      "lg": 6
    }
  }, [_c('stats-col', {
    attrs: {
      "title": "Companies"
    }
  }, [_vm._l(_vm.companyTags.slice(0, 3), function (t) {
    return _c('v-chip', {
      key: t,
      staticClass: "mr-2",
      attrs: {
        "outlined": "",
        "color": "grey lighten-2",
        "text-color": "grey",
        "label": ""
      }
    }, [_vm._v(" " + _vm._s(t) + " ")]);
  }), _vm.companyTags.length > 3 ? _c('v-btn', {
    staticClass: "grey--text button_style",
    attrs: {
      "outlined": "",
      "height": "32"
    },
    on: {
      "click": function ($event) {
        _vm.showMore = true;
      }
    }
  }, [_vm._v(" ... ")]) : _vm._e(), _c('v-dialog', {
    attrs: {
      "max-width": "610"
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        _vm.showMore = false;
      }
    },
    model: {
      value: _vm.showMore,
      callback: function ($$v) {
        _vm.showMore = $$v;
      },
      expression: "showMore"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v("Companies")]), _c('v-card-text', _vm._l(_vm.companyTags, function (t) {
    return _c('v-chip', {
      key: t,
      staticClass: "mr-2 mt-6",
      attrs: {
        "outlined": "",
        "color": "grey lighten-2",
        "text-color": "grey",
        "label": ""
      }
    }, [_vm._v(" " + _vm._s(t) + " ")]);
  }), 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "blue darken-1",
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.showMore = false;
      }
    }
  }, [_vm._v("CLOSE")])], 1)], 1)], 1)], 2)], 1) : _vm._e()], 1)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }