<template>
  <span>
    <v-row>
      <v-col>
        Stay on the top of your game, track your performance in various topics
      </v-col>
    </v-row>

    <v-row v-if="completed >= 10">
      <v-col>
        <v-row>
          <v-col class="font-weight-bold text-truncate text-h6">
            Strengths
          </v-col>
        </v-row>
        <v-row>
          <v-col
            :xs="12"
            :sm="6"
            :md="3"
            v-for="skill in dashboardView.getStrengthsList()"
            v-bind:key="skill.getDrillPreview().getContentPreview().getId()"
          >
            <v-card
              id="next"
              outlined
              class="strength"
              :width="270"
              :height="100"
              :to="
                '/drills/' +
                skill.getDrillPreview().getContentPreview().getUrl()
              "
            >
              <div class="d-flex flex-no-wrap">
                <v-avatar class="mt-3 ml-3" size="imageWidth" tile>
                  <v-img
                    :src="
                      skill
                        .getDrillPreview()
                        .getContentPreview()
                        .getMetaData()
                        .getImageUrl()
                    "
                  ></v-img>
                </v-avatar>
                <div>
                  <v-card-title class="text-caption-1">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <span
                          v-bind:style="{ width: titleWidth + 'px' }"
                          class="d-inline-block text-truncate"
                          v-on="on"
                        >
                          {{
                            skill
                              .getDrillPreview()
                              .getContentPreview()
                              .getTitle()
                          }}
                        </span>
                      </template>
                      {{
                        skill.getDrillPreview().getContentPreview().getTitle()
                      }}
                    </v-tooltip>
                  </v-card-title>
                  <v-card-subtitle>
                    {{ solvedCount(skill) }}/{{ totalContent(skill) }} Completed
                    <br />
                  </v-card-subtitle>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="mt-5 font-weight-bold text-truncate text-h6">
            Weaknesses
          </v-col>
        </v-row>
        <v-row>
          <v-col
            :xs="12"
            :sm="6"
            :md="3"
            v-for="skill in dashboardView.getWeaknessesList()"
            v-bind:key="skill.getDrillPreview().getContentPreview().getId()"
          >
            <v-card
              outlined
              class="weakness"
              :width="270"
              :height="100"
              :to="
                '/drills/' +
                skill.getDrillPreview().getContentPreview().getUrl()
              "
            >
              <div class="d-flex flex-no-wrap">
                <v-avatar class="mt-3 ml-3" size="imageWidth" tile>
                  <v-img
                    :src="
                      skill
                        .getDrillPreview()
                        .getContentPreview()
                        .getMetaData()
                        .getImageUrl()
                    "
                  ></v-img>
                </v-avatar>
                <div>
                  <v-card-title class="text-caption-1">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <span
                          v-bind:style="{ width: titleWidth + 'px' }"
                          class="d-inline-block text-truncate"
                          v-on="on"
                        >
                          {{
                            skill
                              .getDrillPreview()
                              .getContentPreview()
                              .getTitle()
                          }}
                        </span>
                      </template>
                      {{
                        skill.getDrillPreview().getContentPreview().getTitle()
                      }}
                    </v-tooltip>
                  </v-card-title>
                  <v-card-subtitle>
                    {{ solvedCount(skill) }}/{{ totalContent(skill) }} Completed
                    <br />
                  </v-card-subtitle>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col>
        <status-card
          startNow="/problems"
          :height="97"
          :width="107"
          title="Skills"
          cardText="Solve 10 or more problems to start seeing Strengths"
          imageName="Strengths"
        ></status-card>
      </v-col>
    </v-row>
  </span>
</template>
<script>
import StatusCard from '../components/StatusCard'

export default {
  props: {
    dashboardView: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      titleWidth: 0,
      imageWidth: 0,
    }
  },
  computed: {
    completed() {
      if (this.dashboardView)
        return (
          this.dashboardView.getOverAllStatusView().getEasy() +
          this.dashboardView.getOverAllStatusView().getMedium() +
          this.dashboardView.getOverAllStatusView().getHard()
        )
    },
  },
  methods: {
    solvedCount(drill) {
      if (
        drill &&
        drill.getDrillPreview().getUserActivity() &&
        drill.getDrillPreview().getUserActivity().getData().getDrillReport()
      )
        return drill
          .getDrillPreview()
          .getUserActivity()
          .getData()
          .getDrillReport()
          .getCompletedContentCount()
      return 0
    },
    totalContent(drill) {
      if (
        drill &&
        drill.getDrillPreview().getContentPreview().getMetaData() &&
        drill.getDrillPreview().getContentPreview().getMetaData().getDrillMeta()
      )
        return drill
          .getDrillPreview()
          .getContentPreview()
          .getMetaData()
          .getDrillMeta()
          .getTotalContent()
      return 0
    },
    getCardWidth() {
      if (this.completed >= 10) {
        this.titleWidth = (document.getElementById('next').clientWidth * 3) / 5
        this.imageWidth = (document.getElementById('next').clientWidth * 1) / 5
      }
    },
  },
  mounted() {
    this.getCardWidth()
  },
  components: {
    StatusCard,
  },
}
</script>
<style scoped>
.strength {
  border-left-color: #33a79d;
  border-left-width: 10px !important;
}
.weakness {
  border-left-color: #eeeeee;
  border-left-width: 10px !important;
}
</style>
