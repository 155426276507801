var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "mx-auto center-cards px-1",
    attrs: {
      "height": "100%",
      "color": "transparent",
      "elevation": "0"
    }
  }, [_c('v-img', {
    staticStyle: {
      "border-radius": "10px"
    },
    attrs: {
      "src": require('../../../assets/images/recruiter' + _vm.reason.image),
      "height": "250px",
      "width": "250px"
    }
  }), _c('v-card-title', {
    staticClass: "justify-center text-body-1 title"
  }, [_vm._v(" " + _vm._s(_vm.reason.title) + " ")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }