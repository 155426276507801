;(function (mod) {
  mod(require('codemirror/lib/codemirror'))
})(function (CodeMirror) {
  'use strict'

  var WORD = /[\w$|#]+/,
    RANGE = 500

  CodeMirror.registerHelper('hint', 'csharp', function (editor, options) {
    var word = (options && options.word) || WORD
    var range = (options && options.range) || RANGE
    var cur = editor.getCursor(),
      curLine = editor.getLine(cur.line)
    var end = cur.ch,
      start = end
    while (start && word.test(curLine.charAt(start - 1))) --start
    var curWord = start != end && curLine.slice(start, end)
    var list = (options && options.list) || [],
      seen = {}
    var re = new RegExp(word.source, 'g')
    for (var dir = -1; dir <= 1; dir += 2) {
      var line = cur.line,
        endLine =
          Math.min(
            Math.max(line + dir * range, editor.firstLine()),
            editor.lastLine(),
          ) + dir
      for (; line != endLine; line += dir) {
        var text = editor.getLine(line),
          m
        while ((m = re.exec(text))) {
          if (line == cur.line && m[0] === curWord) continue
          if (
            (!curWord || m[0].lastIndexOf(curWord, 0) == 0) &&
            !Object.prototype.hasOwnProperty.call(seen, m[0])
          ) {
            seen[m[0]] = true
            list.push(m[0])
          }
        }
      }
    }
    const keywords = [
      'abstract',
      'as',
      'add',
      'alias',
      'ascending',
      'args',
      'async',
      'await',
      'base',
      'bool',
      'break',
      'byte',
      'case',
      'catch',
      'char',
      'checked',
      'class',
      'const',
      'continue',
      'decimal',
      'default',
      'delegate',
      'descending',
      'do',
      'double',
      'else',
      'enum',
      'equals',
      'event',
      'explicit',
      'extern',
      'false',
      'finally',
      'file',
      'float',
      'for',
      'foreach',
      'from',
      'get',
      'global',
      'group',
      'if',
      'implicit',
      'in',
      'init',
      'int',
      'interface',
      'internal',
      'into',
      'is',
      'join',
      'let',
      'lock',
      'long',
      'managed',
      'nameof',
      'nint',
      'not',
      'notnull',
      'nuint',
      'on',
      'or',
      'orderby',
      'out',
      'override',
      'params',
      'partial',
      'record',
      'remove',
      'required',
      'return',
      'sbyte',
      'sealed',
      'select',
      'set',
      'short',
      'sizeof',
      'stackalloc',
      'static',
      'string',
      'struct',
      'switch',
      'this',
      'throw',
      'true',
      'try',
      'typeof',
      'uint',
      'ulong',
      'unchecked',
      'unmanaged',
      'unsafe',
      'ushort',
      'using',
      'using static',
      'value',
      'var',
      'virtual',
      'void',
      'volatile',
      'when',
      'where',
      'while',
      'with',
      'yield',
      'System',
      'System.Collections',
      'System.Collections.Generic',
      'System.Data',
      'System.IO',
      'System.Linq',
      'System.Net',
      'System.Reflection',
      'System.Text',
      'System.Threading',
    ]

    for (let i = 0; i < keywords.length; i++) {
      if (!seen[keywords[i]] && keywords[i].startsWith(curWord)) {
        list.push(keywords[i])
        seen[keywords[i]] = true
      }
    }
    return {
      list: list,
      from: CodeMirror.Pos(cur.line, start),
      to: CodeMirror.Pos(cur.line, end),
    }
  })
})
