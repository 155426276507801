<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="!rejection && cancel"
  >
    <v-card class="pa-5">
      <v-card-title
        v-show="!!message"
        class="pa-4 text-h5 justify-center text-center"
      >
        {{ message }}
      </v-card-title>
      <v-card-text>
        <v-row align="center" justify="center">
          <v-col cols="6" class="d-flex justify-center">
            <v-img
              max-width="400px"
              height="400px"
              :src="require(`../../assets/${imageUrl}`)"
            >
            </v-img>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="py-4 px-8 justify-center">
        <v-btn
          v-if="rejection"
          large
          @click.native="cancel"
          class="text-none text-subtitle-1 font-weight-medium px-2"
        >
          Cancel
        </v-btn>
        <v-spacer v-if="rejection"></v-spacer>
        <v-btn
          large
          :color="options.color"
          @click.native="agree"
          class="text-none text-subtitle-1 font-weight-medium px-2"
        >
          <span v-if="rejection"> OK </span>
          <span v-else> Yes </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    rejection: {
      default: true,
      required: false,
    },
    imageUrl: {
      type: String,
      default: 'images/TestTimeOver.png',
    },
  },
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    // title: null,
    options: {
      color: 'primary',
      width: '1000px',
      zIndex: 200,
    },
  }),
  methods: {
    open(message, options) {
      this.dialog = true
      //   this.title = title;
      this.message = message
      this.options = Object.assign(this.options, options)
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree() {
      this.resolve(true)
      this.dialog = false
    },
    cancel() {
      this.resolve(false)
      this.dialog = false
    },
    clear() {
      if(this.dialog) this.dialog = false;
      if(this.resolve) {
        this.reject(new Error("Force closed"));
        this.resolve = null,
        this.reject = null
      }
    },
  },
}
</script>
