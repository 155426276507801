var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "py-8 text-h5 font-weight-bold text-center"
  }, [_vm._v("Pricing")]), _c('v-tabs', {
    staticClass: "text-body-1",
    model: {
      value: _vm.pricingTab,
      callback: function ($$v) {
        _vm.pricingTab = $$v;
      },
      expression: "pricingTab"
    }
  }, [_c('v-tab', [_vm._v(" Free ")]), _c('v-tab', [_vm._v(" Premium ")])], 1), _c('v-tabs-items', {
    staticClass: "pa-5",
    model: {
      value: _vm.pricingTab,
      callback: function ($$v) {
        _vm.pricingTab = $$v;
      },
      expression: "pricingTab"
    }
  }, [_c('v-tab-item', {
    staticClass: "mb-5"
  }, [_c('v-row', {
    staticClass: "mb-5"
  }, [_c('div', {
    staticClass: "text-h5 font-weight-medium py-3"
  }, [_vm._v(" Test Our Platform with our Free Tier ")])]), _c('v-row', [_c('span', {
    staticClass: "font-italic font-weight-medium"
  }, [_vm._v(" To activate your free plan mail us at hello@codedrills.io ")])]), _c('v-row', {
    staticClass: "text-body-2"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('ul', _vm._l(_vm.freeFeatures, function (f) {
    return _c('li', {
      key: f,
      staticClass: "py-2"
    }, [_vm._v(" " + _vm._s(f) + " ")]);
  }), 0)])], 1)], 1), _c('v-tab-item', [_c('v-row', {
    staticClass: "mb-5"
  }, [_c('div', {
    staticClass: "text-h5 font-weight-medium py-3"
  }, [_vm._v(" Start Hiring Today ")])]), _c('v-row', [_c('span', {
    staticClass: "font-italic font-weight-medium"
  }, [_vm._v(" For Pricing details send an email to hello@codedrills.io ")])]), _c('v-row', {
    staticClass: "text-h6",
    attrs: {
      "align": "center"
    }
  }), _c('v-row', {
    staticClass: "mb-5 text-body-2"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('ul', _vm._l(_vm.features, function (f) {
    return _c('li', {
      key: f,
      staticClass: "py-2"
    }, [_vm._v(" " + _vm._s(f) + " ")]);
  }), 0)])], 1)], 1), _c('hr')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }