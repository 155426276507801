<template>
  <span>
    <v-alert
      v-if="user && (past || live) && contestExtended"
      type="info"
      border="left"
      colored-border
      elevation="2"
      dismissible
    >
      This contest is extended for {{ formatTime(contestExtended) }} for you.
    </v-alert>
    <v-row>
      <v-col>
        <upcoming-contest
          v-if="upcoming"
          :contentView="contentView"
        ></upcoming-contest>
        <past-contest
          v-else-if="past"
          :contentView="contentView"
        ></past-contest>
        <live-contest v-else :contentView="contentView"></live-contest>
      </v-col>
    </v-row>
  </span>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import UpcomingContest from '../components/UpcomingContest'
import LiveContest from '../components/LiveContest'
import PastContest from '../components/PastContest'
import ProblemList from '../components/ProblemList'

export default {
  props: {
    contentView: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions('content', ['fetchContentView']),
    ...mapActions('user', ['getUserContestTeams']),
    refreshContent() {
      this.fetchContentView({
        type: 'CONTEST',
        url: this.contentView.getUrl(),
      }).then((__) => console.log('Fetched contentView'))
    },
    formatTime(timeInSeconds) {
      const hours = Math.floor(timeInSeconds / 3600)
      const minutes = Math.floor((timeInSeconds % 3600) / 60)
      const seconds = timeInSeconds % 60
      return `${hours} hours, ${minutes} minutes, and ${seconds} seconds`
    },
  },
  computed: {
    ...mapState('timesync', ['serverTime']),
    ...mapState('user', ['userContestTeams', 'user']),
    upcoming() {
      return (
        this.contentView.getMetaData().getContestMeta().getStartAt() >
        this.serverTime
      )
    },
    past() {
      return (
        this.contentView.getMetaData().getContestMeta().getEndAt() <
        this.serverTime
      )
    },
    live() {
      return !this.upcoming && !this.past
    },
    teamContest() {
      return (
        this.contentView &&
        this.contentView.getMetaData().getContestMeta().getContestType() == 1
      )
    },
    contestExtended() {
      if (!this.user) return false
      var acceptedTeam = this.acceptedTeam
      var extended = acceptedTeam && acceptedTeam.getContestExtendedBySeconds()
      return extended
    },

    acceptedTeam() {
      if (!this.user) return
      return (
        this.userContestTeams &&
        this.userContestTeams.find((t) => {
          var accepted =
            t
              .getMembersList()
              .find((u) => u.getEmail() === this.user.email)
              .getMembershipState() == 2
          return accepted
        })
      )
    },
  },
  components: {
    UpcomingContest,
    LiveContest,
    PastContest,
    ProblemList,
  },
  watch: {
    upcoming() {
      console.log('Watching upcoming,,,')
      this.refreshContent()
    },
    past() {
      console.log('Watching past ,,,')
      this.refreshContent()
    },
  },
  created() {
    if (this.contentView && this.teamContest && this.user)
      this.getUserContestTeams({ contestId: this.contentView.getId() })
  },
}
</script>
