var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('v-row', [_c('v-col', {
    staticClass: "px-1"
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-list-item', {
    attrs: {
      "two-line": ""
    }
  }, [_c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "text-caption-1",
    class: _vm.statusClass(_vm.status)
  }, [_vm._v(_vm._s(_vm.title))]), _c('v-list-item-subtitle', {
    staticClass: "subtitle-2"
  }, [_vm._v(" " + _vm._s(_vm.subtitle))])], 1), _vm.imageUrl ? _c('v-list-item-avatar', {
    attrs: {
      "right": "",
      "min-height": 100,
      "min-width": 100
    }
  }, [_c('v-img', {
    attrs: {
      "src": require('./../assets/images/' + this.imageUrl),
      "min-height": 100,
      "contain": ""
    }
  })], 1) : _vm._e()], 1), _c('div', {
    staticClass: "px-5 ma-2"
  }, [_vm._t("default")], 2)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }