var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page', {
    attrs: {
      "title": _vm.title,
      "apiStatusList": [_vm.fetchContentViewStatus],
      "imageUrl": _vm.contentView && _vm.contentView.getMetaData().getImageUrl(),
      "appendImageUrl": _vm.appendImageUrl,
      "titleLink": _vm.parentUrl,
      "minimal": _vm.isMinimal,
      "breadcrumbs": _vm.breadcrumbs
    },
    scopedSlots: _vm._u([_vm.hasSubtitle ? {
      key: "subtitle",
      fn: function () {
        return [_vm.parentPreview && _vm.contentView.getDataView().getProblemView() ? _c('problem-subtitle', {
          attrs: {
            "contentView": _vm.contentView
          }
        }) : _vm._e(), _vm.contentView.getMetaData().getContestMeta() ? _c('contest-subtitle', {
          attrs: {
            "contentMeta": _vm.contentView.getMetaData(),
            "contestUrl": _vm.contentView.getUrl()
          }
        }) : _vm._e()];
      },
      proxy: true
    } : null, _vm.hasBanner ? {
      key: "appendBanner",
      fn: function () {
        return [_vm.$vuetify.breakpoint.lgAndUp ? _c('div', [_c('mascot', {
          attrs: {
            "preposition": _vm.preposition,
            "time": _vm.contestTime
          }
        })], 1) : _c('div', [_vm._v(_vm._s(_vm.preposition) + " : " + _vm._s(_vm.contestTime))])];
      },
      proxy: true
    } : null], null, true)
  }, [_vm.contentView ? _c('span', [_vm.contentView.getDataView().getArticleView() ? _c('article-view', {
    attrs: {
      "contentView": _vm.contentView
    }
  }) : _vm.contentView.getDataView().getProblemView() ? _c('problem-view', {
    attrs: {
      "parentPreview": _vm.parentPreview,
      "contentView": _vm.contentView
    }
  }) : _vm.contentView.getDataView().getContestView() ? _c('contest-view', {
    attrs: {
      "contentView": _vm.contentView
    }
  }) : _vm.contentView.getDataView().getDrillView() ? _c('drill-view', {
    attrs: {
      "contentView": _vm.contentView
    }
  }) : _vm._e()], 1) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }