var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.initialized ? _c('v-app', [_c('List'), _c('BaseLayout', [_c('router-view', {
    key: _vm.$route.fullPath
  })], 1), _c('footer', [_c('cookie-law', {
    attrs: {
      "theme": "blood-orange",
      "buttonText": "Agree"
    }
  }, [_c('div', {
    attrs: {
      "slot": "message"
    },
    slot: "message"
  }, [_vm._v(" We use cookies to ensure you get the best user experience on our website. By continuing to use this site, you agree to the use of these cookies. "), _c('router-link', {
    attrs: {
      "to": "privacy-policy"
    }
  }, [_vm._v("Find out more")])], 1)])], 1), _c('v-snackbar', {
    attrs: {
      "color": _vm.snackbar.color,
      "bottom": "",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function (_ref) {
        var attrs = _ref.attrs;
        return [_c('v-btn', _vm._b({
          attrs: {
            "color": "white",
            "text": ""
          },
          on: {
            "click": function ($event) {
              _vm.snackbar.show = false;
            }
          }
        }, 'v-btn', attrs, false), [_vm._v(" Close ")])];
      }
    }], null, false, 2769898872),
    model: {
      value: _vm.snackbar.show,
      callback: function ($$v) {
        _vm.$set(_vm.snackbar, "show", $$v);
      },
      expression: "snackbar.show"
    }
  }, [_vm._v(" " + _vm._s(_vm.snackbar.message) + " ")])], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }