<template>
  <v-card elevation="0" outlined class="d-flex flex-column">
    <v-card-text>
      <v-row :class="questionType === 'PROBLEM' ? 'joining-line-bottom' : ''">
        <v-col cols="6" class="pa-0 ma-0">
          <v-tabs v-if="questionType === 'PROBLEM'" v-model="currProblemTab">
            <v-tab>Description</v-tab>

            <v-tab>Submissions</v-tab>

            <v-tab
              v-if="
                currentQuestion
                  .getMetaData()
                  ?.getProblemMeta()
                  ?.getEvaluationType() === 1
              "
              >SQL Schema</v-tab
            >
          </v-tabs>
        </v-col>
      </v-row>

      <v-row>
        <v-col :cols="editorFS" class="pl-0 py-0 ma-0 pr-2">
          <TestQuestionStatement
            :questionStatement="questionStatement"
            :currentQuestion="currentQuestion"
            :currProblemTab="currProblemTab"
            :questionType="questionType"
            class="border__right"
          />
        </v-col>
        <v-col :cols="12 - editorFS" class="pa-0 ma-0">
          <div v-if="questionType === 'MCQ'" class="pt-8 pr-8">
            <span class="text-body-1 font-italic" v-if="mcqHasMultipleAnswers"
              >This question might have multiple answers</span
            >
            <v-checkbox
              v-for="opt in mcqOptions"
              :multiple="mcqHasMultipleAnswers"
              v-model="mcqSelectedAnswer"
              :key="opt.getStatementHtml()"
              :value="opt.getId()"
              class="radio__option"
              color="primary"
              @change="updateMcqAnswer(mcqSelectedAnswer)"
            >
              <template v-slot:label>
                <div
                  class="radio__parent mt-3"
                  v-html="opt.getStatementHtml()"
                ></div>
              </template>
            </v-checkbox>
          </div>
          <div v-else-if="questionType === 'SUBJECTIVE'" class="pt-8 pr-8">
            <v-textarea
              outlined
              placeholder="Enter your answer here"
              v-model="subjectiveAnswer"
              rows="20"
              @input="setSubjectiveAns"
              @paste.prevent
            >
            </v-textarea>
          </div>
          <div v-else-if="questionType === 'PROBLEM'">
            <TestProblemSolution
              :currentQuestion="currentQuestion"
              ref="problemSolution"
            />
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <!-- <v-spacer></v-spacer> -->
    <v-card-actions
      class="justify-space-between align-center px-8 py-4 top-clearance"
    >
      <!-- <v-btn
        elevation="0"
        class="px-5 text-none text-body-1 font-weight-medium"
        @click="skipQs"
      >
        Skip
      </v-btn> -->
      <v-row v-if="questionType === 'PROBLEM'">
        <v-col>
          You can submit multiple times before going to the next question!
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col>
          <!-- MCQ info -->
        </v-col>
      </v-row>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            elevation="0"
            color="success"
            class="px-5 text-none text-body-1 font-weight-medium"
            @click="nextQuestion"
            v-bind="attrs"
            v-on="on"
            :disabled="loadingNext"
            :loading="loadingNext"
          >
            Next
          </v-btn>
        </template>
        <span>
          This button will submit this question and take you to the next
          question.
        </span>
      </v-tooltip>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import TestQuestionStatement from './questions/TestQuestionStatement.vue'
import TestProblemSolution from './solution/TestProblemSolution.vue'
import 'codedrills_proto/io/codedrills/proto/content/content_pb'

const McqType = proto.io.codedrills.proto.content.McqType

export default {
  components: {
    TestQuestionStatement,
    TestProblemSolution,
  },
  props: {
    currentQuestion: {
      type: Object,
      required: true,
    },
    skipQs: {
      type: Function,
      required: true,
    },
    nextQs: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      mcqSelectedAnswer: [],
      subjectiveAnswer: '',
      // problemTab: null,
      problemSolCode: '',
      codeBar: null,
      language: null,
      loadingNext: false,
    }
  },
  created() {},
  computed: {
    ...mapGetters('candidate', ['getContentTypes']),
    ...mapState('candidate', ['problemTab', 'hiringCodeEditorFullscreen']),
    ...mapGetters('user', ['userId']),
    ...mapState('user', ['user', 'userPreference']),
    ...mapGetters('judge', ['languageKeys']),
    questionType() {
      return this.getContentTypes[this.currentQuestion.getType()]
    },
    editorFS() {
      // FS = fullscreen
      return this.questionType === 'PROBLEM' && this.hiringCodeEditorFullscreen
        ? 3
        : 5
    },
    currProblemTab: {
      get: function () {
        return this.problemTab
      },
      set: function (val) {
        this.setProblemTab(val)
      },
    },
    questionStatement() {
      console.log('Calculating questionStatement ...', this.currentQuestion)
      if (this.questionType === 'MCQ') {
        return this.currentQuestion
          ?.getDataView()
          ?.getMcqView()
          ?.getStatementHtml()
      } else if (this.questionType === 'SUBJECTIVE') {
        return this.currentQuestion
          ?.getDataView()
          ?.getSubjectiveView()
          ?.getDescriptionHtml()
      } else if (this.questionType === 'PROBLEM') {
        return this.currentQuestion
          ?.getDataView()
          ?.getProblemView()
          ?.getStatementView()
      } else {
        return `<div>Error loading question!</div>`
      }
    },
    mcqOptions() {
      if (this.questionType === 'MCQ') {
        return this.currentQuestion
          ?.getDataView()
          ?.getMcqView()
          ?.getOptionList()
      }
      return null
    },
    mcqHasMultipleAnswers() {
      return (
        this.currentQuestion?.getMetaData()?.getMcqMeta()?.getMcqType() ===
          McqType.MCQ_MULTIPLE_ANSWER ||
        this.currentQuestion?.getMetaData()?.getMcqMeta()?.getMcqType() ===
          McqType.MCQ_MULTIPLE_ANSWER_PARTIAL_SCORING
      )
    },
  },
  methods: {
    ...mapMutations('candidate', ['setCurrentAnswer', 'setProblemTab']),
    ...mapActions('judge', ['fetchCodeTemplate']),
    nextQuestion() {
      this.loadingNext = true
      this.nextQs()
        .then((res) => {
          console.log(res)
        })
        .catch((ex) => {
          console.log('Error loading next question', ex)
          this.$store.dispatch('notifs/addNotif', {
            text: 'Error loading next question, please retry!',
            type: 'error',
          })
        })
        .finally(() => {
          this.loadingNext = false
        })
    },
    updateMcqAnswer() {
      this.setCurrentAnswer(this.mcqSelectedAnswer)
    },
    setSubjectiveAns() {
      this.setCurrentAnswer(this.subjectiveAnswer)
    },
    clearState() {
      this.loadingNext = false
    },
    disableNext() {
      this.loadingNext = true
    },
    submitFromProblemSolution() {
      console.log('submitFromProblemSolution ...')
      return this.$refs.problemSolution.submitCode(true)
    },
  },
  watch: {
    mcqSelectedAnswer: function (n, o) {
      console.log('mcqSelectedAnswer', n, o)
    },
    currentQuestion: function (n, o) {
      this.mcqSelectedAnswer = []
    },
  },
}
</script>
<style scoped>
.radio__option {
  padding-top: 1rem;
  padding-left: 1rem;
  border-radius: 1rem;
  border: 2px solid #c4c4c4;
}

.v-input--checkbox.v-input--is-label-active {
  border: 2px solid #2eac68 !important;
}

.joining-line-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  z-index: 20;
}

.top-clearance {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.border__right {
  border-right: 1px solid #c4c4c4;
}
</style>
