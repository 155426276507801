import { render, staticRenderFns } from "./TestProblemSolution.vue?vue&type=template&id=7c27fad7&scoped=true"
import script from "./TestProblemSolution.vue?vue&type=script&lang=js"
export * from "./TestProblemSolution.vue?vue&type=script&lang=js"
import style0 from "./TestProblemSolution.vue?vue&type=style&index=0&id=7c27fad7&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c27fad7",
  null
  
)

export default component.exports