var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', {
    attrs: {
      "cols": 12
    }
  }, [_c('v-sheet', {
    staticClass: "grey lighten-5 pt-10",
    attrs: {
      "height": 550
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "offset": 1,
      "md": 7,
      "lg": 5,
      "xl": 5
    }
  }, [_c('v-row', {}, [_c('v-col', {
    attrs: {
      "cols": 12
    }
  }, [_vm.$vuetify.breakpoint.lgAndUp ? _c('span', {
    staticClass: "font-weight-bold text-h3",
    staticStyle: {
      "line-height": "106px"
    }
  }, [_vm._v(" Prepare for coding interviews ")]) : _c('span', {
    staticClass: "font-weight-bold text-h3",
    staticStyle: {
      "line-height": "76px"
    }
  }, [_vm._v(" Prepare for coding interviews ")])])], 1), _c('v-row', [_c('v-col', {
    staticClass: "text-h6",
    attrs: {
      "cols": 12
    }
  }, [_vm._v(" AI powered adaptive learning to get "), _c('br'), _vm._v(" hired at top tech companies. ")])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "offset": 3,
      "offset-md": 0
    }
  }, [_c('v-btn', {
    staticClass: "text-capitalize",
    attrs: {
      "color": "accent",
      "height": "42"
    },
    on: {
      "click": _vm.redirectToDashboard
    }
  }, [_vm._v(" Start Preparing")])], 1)], 1)], 1), _vm.$vuetify.breakpoint.mdAndUp ? _c('v-col', {
    staticClass: "pt-16 mt-16",
    attrs: {
      "md": 4,
      "lg": 2,
      "xl": 2
    }
  }, [_c('img', {
    staticClass: "mt-12",
    attrs: {
      "alt": "",
      "src": require('./../assets/images/home_1.jpg')
    }
  })]) : _vm._e(), _vm.$vuetify.breakpoint.lgAndUp ? _c('v-col', {
    attrs: {
      "cols": 2
    }
  }, [_c('v-row', {}, [_c('v-col', [_c('img', {
    attrs: {
      "alt": "",
      "src": require('./../assets/images/home_2.jpg')
    }
  })])], 1), _c('v-row', [_c('v-col', [_c('img', {
    attrs: {
      "alt": "",
      "src": require('./../assets/images/home_3.jpg')
    }
  })])], 1)], 1) : _vm._e()], 1)], 1), _vm.$vuetify.breakpoint.mdAndUp ? _c('v-sheet', {
    staticClass: "white",
    attrs: {
      "height": 100
    }
  }) : _vm._e(), _c('v-sheet', {
    staticClass: "mt-10 white",
    attrs: {
      "height": _vm.$vuetify.breakpoint.mdAndUp ? 370 : 3 * 370 - 150
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "offset": 1,
      "cols": 10
    }
  }, [_vm.$vuetify.breakpoint.mdAndUp ? _c('span', {
    staticClass: "font-weight-bold text-truncate display-1"
  }, [_vm._v(" How it works ")]) : _c('span', {
    staticClass: "font-weight-bold text-truncate text-h6"
  }, [_vm._v(" How it works ")])])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "offset": 1,
      "cols": 9,
      "md": 3,
      "lg": 3
    }
  }, [_c('v-card', {
    staticClass: "pt-1",
    attrs: {
      "flat": ""
    }
  }, [_c('v-row', {
    attrs: {
      "height": 100
    }
  }, [_c('v-col', [_c('v-img', {
    attrs: {
      "alt": "",
      "width": 119,
      "height": "100",
      "src": require('./../assets/images/Practice.svg')
    }
  })], 1)], 1), _c('v-card-title', [_vm._v(" Practice ")]), _c('v-card-text', [_vm._v(" Our AI powered engine will suggest problems to practice and artilces to read based on your experience, skills and target companies. ")])], 1)], 1), _c('v-col', {
    attrs: {
      "offset": 1,
      "cols": 9,
      "offset-md": 0,
      "md": 3,
      "lg": 3
    }
  }, [_c('v-card', {
    staticClass: "pt-1",
    attrs: {
      "flat": ""
    }
  }, [_c('v-row', {
    attrs: {
      "height": 100
    }
  }, [_c('v-col', [_c('v-img', {
    attrs: {
      "alt": "",
      "width": 131,
      "height": "83",
      "src": require('./../assets/images/Analyze.svg')
    }
  })], 1)], 1), _c('v-card-title', [_vm._v(" Analyze ")]), _c('v-card-text', [_vm._v(" Track your progress and get actionable feedback on your practice performance. "), _c('br'), _c('router-link', {
    staticClass: "ml-1 font-weight-bold dashboard_link",
    attrs: {
      "to": "/community/dashboardSample"
    }
  }, [_vm._v(" See Sample Dashboard > ")])], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "offset": 1,
      "cols": 9,
      "offset-md": 0,
      "md": 4,
      "lg": 3
    }
  }, [_c('v-card', {
    staticClass: "pt-1",
    attrs: {
      "flat": ""
    }
  }, [_c('v-row', {
    attrs: {
      "height": 100
    }
  }, [_c('v-col', [_c('v-img', {
    attrs: {
      "alt": "",
      "width": 108,
      "height": "89",
      "src": require('./../assets/images/GetHired.svg')
    }
  })], 1)], 1), _c('v-card-title', [_vm._v(" Get Hired ")]), _c('v-card-text', [_vm._v(" Our drills will ensure you perform well in tech interviews at top companies like Google, Facebook, Microsoft, Amazon etc. ")])], 1)], 1)], 1)], 1), _c('v-sheet', {
    staticClass: "mt-10 white lighten-5",
    attrs: {
      "height": _vm.$vuetify.breakpoint.mdAndUp ? 370 : 2 * 370 - 100
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "offset": 1,
      "cols": 10
    }
  }, [_vm.$vuetify.breakpoint.mdAndUp ? _c('span', {
    staticClass: "font-weight-bold text-truncate display-1"
  }, [_vm._v(" What's in it for you ")]) : _c('span', {
    staticClass: "font-weight-bold text-truncate text-h6"
  }, [_vm._v(" What's in it for you ")])])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "offset": 1,
      "cols": 10,
      "sm": 8,
      "offset-md": 1,
      "md": 5
    }
  }, [_c('v-card', {
    staticClass: "pl-2 pt-1",
    attrs: {
      "max-width": "570",
      "height": 270,
      "outlined": ""
    }
  }, [_c('v-card-title', [_vm._v(" Candidates ")]), _c('v-card-text', {
    staticClass: "mt-n4"
  }, [_c('v-row', {
    attrs: {
      "height": 90
    }
  }, [_c('v-col', {
    attrs: {
      "cols": 12,
      "lg": 9
    }
  }, [_vm._v(" Practice algorithm problems in any of the supported language and get analysis on your performance including strong/weak topics, suggestions on improving your implementation and many more. ")])], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": 8
    }
  }, [_c('v-btn', {
    staticClass: "text-capitalize",
    attrs: {
      "color": "accent",
      "size": "35"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push('/community/dashboard');
      }
    }
  }, [_vm._v(" Start Now ")])], 1), _c('v-spacer'), _vm.$vuetify.breakpoint.mdAndUp ? _c('v-col', {
    attrs: {
      "cols": 4
    }
  }, [_c('v-img', {
    attrs: {
      "alt": "",
      "height": "95",
      "width": "150",
      "src": require('./../assets/images/Candidates.svg')
    }
  })], 1) : _vm._e()], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "offset": 1,
      "cols": 10,
      "sm": 8,
      "offset-md": 0,
      "md": 5
    }
  }, [_c('v-card', {
    staticClass: "pl-2 pt-1",
    attrs: {
      "outlined": "",
      "max-width": "570",
      "height": "270"
    }
  }, [_c('v-card-title', [_vm._v(" Recruiters ")]), _c('v-card-text', {
    staticClass: "mt-n4"
  }, [_c('v-row', {
    attrs: {
      "height": 90
    }
  }, [_c('v-col', {
    attrs: {
      "xs": 12,
      "lg": 9
    }
  }, [_vm._v(" Use our test creation platform to easily setup online screening rounds. ")])], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": 8
    }
  }, [_c('v-btn', {
    staticClass: "text-capitalize",
    attrs: {
      "color": "accent",
      "size": "35",
      "to": "/",
      "rel": "noopener"
    }
  }, [_vm._v(" Know More ")])], 1), _c('v-spacer'), _vm.$vuetify.breakpoint.mdAndUp ? _c('v-col', {
    attrs: {
      "cols": 4
    }
  }, [_c('v-img', {
    attrs: {
      "alt": "",
      "height": "95",
      "width": "150",
      "src": require('./../assets/images/Recruiters.svg')
    }
  })], 1) : _vm._e()], 1)], 1)], 1)], 1)], 1)], 1), _c('v-sheet', {
    staticClass: "mt-10 white lighten-5",
    attrs: {
      "height": _vm.$vuetify.breakpoint.lgAndUp ? 300 : _vm.$vuetify.breakpoint.smAndUp ? 2 * 300 - 200 : 5 * 300 - 700
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "offset": 1,
      "cols": 10
    }
  }, [_vm.$vuetify.breakpoint.mdAndUp ? _c('span', {
    staticClass: "font-weight-bold text-truncate display-1"
  }, [_vm._v(" Land your dream job ")]) : _c('span', {
    staticClass: "font-weight-bold text-truncate text-h6"
  }, [_vm._v(" Land your dream job ")])])], 1), _c('v-row', {
    staticClass: "mt-5"
  }, [_c('v-col', {
    attrs: {
      "offset": 1,
      "cols": 10,
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-row', _vm._l(_vm.companies, function (s) {
    return _c('v-col', {
      key: s
    }, [_c('v-card', {
      attrs: {
        "outlined": "",
        "flat": "",
        "height": 112,
        "width": 170
      }
    }, [_c('v-row', {
      attrs: {
        "align": "center",
        "justify": "center"
      }
    }, [_c('v-col', {
      staticClass: "mt-2",
      attrs: {
        "cols": "6"
      }
    }, [_c('v-avatar', {
      attrs: {
        "size": 66.1,
        "tile": ""
      }
    }, [_c('img', {
      attrs: {
        "alt": s,
        "src": require('./../assets/images/company/' + s + '@2x.png')
      }
    })])], 1)], 1)], 1)], 1);
  }), 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }