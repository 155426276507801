<template>
  <v-row v-if="!user">
    <v-col class="pt-2 pb-1">
      Please <router-link to="/community/login"> login </router-link> to submit.
    </v-col>
  </v-row>
  <v-row class="fill-height" v-else align="center" justify="center">
    <v-alert
      v-if="showOfflineAlert"
      :type="isOnline ? 'success' : 'error'"
      dense
      text
    >
      {{
        isOnline ? 'You are currently online.' : 'You are currently offline.'
      }}
    </v-alert>
    <v-overlay :value="submitLoading" opacity="0.9" color="black">
      <v-progress-circular
        indeterminate
        color="accent"
        size="70"
        width="10"
      ></v-progress-circular>
      <div>Please wait next page is loading...</div>
    </v-overlay>
    <SmallConfirmation :rejection="false" ref="qtime" />
    <PopUpWarning ref="tabsw" />
    <v-col cols="12" lg="11" class="fill-height">
      <div class="pa-16" v-if="testTimeOver">
        <TimeoverModal />
      </div>
      <v-card
        class="current-question"
        height="100%"
        elevation="0"
        v-if="!testTimeOver && currentQuestion"
      >
        <div class="top-completion px-16 pb-4 mb-6">
          <v-row justify="center" align="center">
            <div>{{ currentQuestionNum }} / {{ totalQuestions }}</div>
          </v-row>
          <v-row>
            <v-progress-linear
              :value="finishLine"
              @click="() => {}"
              color="green"
              height="10"
              rounded
            >
            </v-progress-linear>
          </v-row>
        </div>
        <div class="mt-n6 mb-6 navshadow" />
        <SmallConfirmation
          :showHideMessage="true"
          :rejection="true"
          ref="skip"
        ></SmallConfirmation>
        <SmallConfirmation :rejection="false" ref="fs"></SmallConfirmation>
        <TestQuestion
          ref="question"
          :currentQuestion="currentQuestion"
          :nextQs="nextQs"
          :skipQs="skipQs"
        />
      </v-card>
      <Video
        :url="$route.params.url"
        ref="videoComponent"
        controls
        :hidden="hideVideo"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import Vue from 'vue'
import { mdiLock, mdiTimer } from '@mdi/js'
import TestQuestion from '../components/assessment/TestQuestion.vue'
import SmallConfirmation from '../components/assessment/SmallConfirmation.vue'
import PopUpWarning from '../components/assessment/PopUpWarning.vue'
import TimeoverModal from '../components/assessment/TimeoverModal.vue'
import { isFullScreen } from '../utils/helper'
import { E_CANCELED, Mutex, Semaphore, withTimeout } from 'async-mutex'
import Video from '../views/Video.vue'

export default {
  props: {
    testView: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isOnline: navigator.onLine,
      showOfflineAlert: false,
      mdiTimer,
      mdiLock,
      submitLoading: false,
      currentQuestionLoading: true,
      tabSwitchingFalseFlag: false,
      questions: [],
      testTimeOver: false,
      timeoutMutex: new Mutex(),
      hideVideo: true,
    }
  },
  methods: {
    ...mapMutations('candidate', [
      'setTestEndedLocally',
      'setProblemTab',
      'setCurrentAnswer',
    ]),
    ...mapMutations('judge', ['submissionsClear']),
    ...mapActions('judge', ['submit']),
    ...mapActions('candidate', [
      'startHiringTest',
      'submitAnswer',
      'skipQuestion',
      'timeoutQuestion',
      'logProctoringEvent',
    ]),
    ...mapActions('timesync', ['initTimeSync']),
    async skipQs() {
      this.setProblemTab(0)
      if (
        await this.$refs.skip.open(
          'Are you sure you want to skip this question? If skipped, you cannot go back again.',
        )
      ) {
        const test_id = this.testView.getTestView()?.getTestPreview()?.getId()
        const qnum = this.currentQuestionNum
        this.skipQuestion({
          test_id,
          qnum,
        }).then((r) => {
          console.log(r)
        })
      }
    },
    async nextQs() {
      this.setProblemTab(0)
      if (
        await this.$refs.skip.open(
          'Are you sure you want to submit this question? Once submitted, you cannot go back again.',
        )
      ) {
        let promise = this.submitOnNext(this.currentQuestionNum)
        return promise
      }
    },
    submitOnNext(qnum) {
      console.log(
        'Current q / cq',
        qnum,
        this.currentQuestionNum,
        this.getCurrentAnswer,
      )
      if (qnum !== this.currentQuestionNum) return
      console.log('Q matching so submitting....')
      console.log(
        'qType is ..',
        this.getContentTypes[
          this.testView.getTestView()?.getQuestionView()?.getType()
        ],
      )
      this.submitLoading = true
      const test_id = this.testView.getTestView()?.getTestPreview()?.getId()
      const qid = this.testView.getTestView()?.getQuestionView()?.getId()
      const qTypeId = this.testView.getTestView()?.getQuestionView()?.getType()
      const qType = this.getContentTypes[qTypeId]
      const answer = this.getCurrentAnswer
      const candidate_id = this.user?.uid
      console.log('userId', this.user.uid, qType === 'PROBLEM')
      if (qType === 'PROBLEM') {
        console.log('it is problem and submitting code from next button ...')
        return this.submitCode()
          .then(() => {
            console.log('code submitted successfully and skipping')
            return this.skipQuestion({
              test_id,
              qnum,
            })
          })
          .then((r) => {
            // clear submission on problem submit
            console.log('Clearing state after submitting code', r)
            this.clearState()
          })
          .catch((error) => {
            console.error('Error in submitting code', error)
            this.ex = error
            var errorMessage = error.message
            if (
              error.code === 14 &&
              error.message.includes('upstream connect error')
            ) {
              errorMessage = 'An error occurred. Please try again.'
            }
            this.$store.dispatch('notifs/addNotif', {
              text: errorMessage,
              type: 'error',
            })
          })
          .finally(() => {
            console.log('Finally clearing state in submit code')
            this.submitLoading = false
          })
      } else {
        return this.submitAnswer({
          test_id,
          qnum,
          qid,
          qType,
          candidate_id,
          qTypeId,
          answer,
        })
          .then((r) => {
            console.log('This is submit answer response', r)
            this.clearState()
          })
          .catch((error) => {
            console.error('Error in submitting answer', error)
            this.ex = error
            var errorMessage = error.message
            if (
              error.code === 14 &&
              error.message.includes('upstream connect error')
            ) {
              errorMessage = 'An error occurred. Please try again.'
            }
            this.$store.dispatch('notifs/addNotif', {
              text: errorMessage,
              type: 'error',
            })
          })
          .finally(() => {
            console.log('Finally clearing state in submit answer')
            this.submitLoading = false
          })
      }
    },

    submitCode() {
      console.log('here in problem')
      return this.$refs.question.submitFromProblemSolution()
    },
    triggerTimeout(qnum) {
      this.$refs.question?.disableNext()
      this.timeoutMutex.cancel()
      console.log('Timeout q / cq', qnum, this.currentQuestionNum)
      if (qnum !== this.currentQuestionNum) return
      console.log('Q matching so timingout')
      const test_id = this.testView.getTestView()?.getTestPreview()?.getId()
      return this.timeoutQuestion({
        test_id,
        qnum,
      }).then((r) => {
        this.clearState()
      })
    },
    async checkForTimeout() {
      var release = null
      try {
        release = await this.timeoutMutex.acquire()
      } catch (e) {
        if (e === E_CANCELED) {
          console.log('Mutex cancelled')
        } else {
          console.log('Error acquiring mutex for timeout', e)
        }
      }
      if (!release) return
      try {
        if (this.alreadyEnded) return
        const testEnd = this.testEndEpoch / 1000
        const questionEnd = this.questionEndEpoch / 1000
        var currentQuestion = this.currentQuestionNum

        if (this.alreadyStarted) {
          if (this.serverTime > testEnd) {
            this.$refs.skip?.clear()
            this.$refs.qtime?.clear()
            if (
              await this.$refs.qtime.open(
                'Time over for this test. Click yes to submit and continue',
              )
            ) {
              await this.submitOnNext(currentQuestion)
              return
            }
          }
          if (this.serverTime > questionEnd) {
            console.log('Timeout detected')
            console.log('Timeout continue')
            this.$refs.skip?.clear()
            if (
              await this.$refs.qtime.open(
                'Time over for this question. Click yes to continue',
              )
            ) {
              await this.submitOnNext(currentQuestion)
            }
          }
        }
      } finally {
        release()
      }
    },
    detectFocusOut() {
      const onWindowFocusChange = (e) => {
        if (this.testEndedLocally) {
          return
        }
        if (!document.hasFocus()) {
          if (this.tabSwitchingFalseFlag) {
            this.tabSwitchingFalseFlag = false
            return
          }
          this.$refs.tabsw.open('You cannot switch tabs during the test')
          console.log('User switched to other tab')
          const test_id = this.testView.getTestView()?.getTestPreview()?.getId()
          const qid = this.testView.getTestView()?.getQuestionView()?.getId()
          const candidate_id = this.user?.uid
          this.logProctoringEvent({
            test_id,
            candidate_id,
            qid,
            proctoring_event: 1,
          }).then((r) => {
            // console.log(r);
          })
        }
      }
      window.addEventListener('focus', onWindowFocusChange)
      window.addEventListener('blur', onWindowFocusChange)
      window.addEventListener('pageshow', onWindowFocusChange)
      window.addEventListener('pagehide', onWindowFocusChange)
    },
    detectFullScreenOut() {
      window.addEventListener('fullscreenchange', () => {
        if (this.testEndedLocally) {
          return
        }
        if (!isFullScreen()) {
          this.logRejectOrExitFS()
          this.fullScreenReq()
        }
      })
    },
    fullScreenReq() {
      if (this.testEndedLocally) {
        return
      }
      setTimeout(async () => {
        if (isFullScreen()) {
          return
        }
        var docelem = document.documentElement
        if (
          await this.$refs.fs.open(
            'Please return to fullscreen to take the test',
          )
        ) {
          if (docelem.requestFullscreen) {
            docelem.requestFullscreen()
          } else if (docelem.mozRequestFullScreen) {
            docelem.mozRequestFullScreen()
          } else if (docelem.webkitRequestFullScreen) {
            docelem.webkitRequestFullScreen()
          } else if (docelem.msRequestFullscreen) {
            docelem.msRequestFullscreen()
          }
        } else {
          this.logRejectOrExitFS()
        }
      }, 80)
    },
    enforceFullScreen() {
      window.addEventListener('click', this.fullScreenReq)
    },
    logRejectOrExitFS() {
      console.log('User exited fs / reject fs')
      const test_id = this.testView.getTestView()?.getTestPreview()?.getId()
      const qid = this.testView.getTestView()?.getQuestionView()?.getId()
      const candidate_id = this.user?.uid
      this.logProctoringEvent({
        test_id,
        candidate_id,
        qid,
        proctoring_event: 3,
      }).then((r) => {
        console.log(r)
      })
    },
    clearState() {
      this.submissionsClear()
      this.setCurrentAnswer(null)
      this.$refs.question?.clearState()
    },
    processTestView() {
      this.setTestEndedLocally(this.alreadyEnded)
      var anticheatings = this.testView
        .getTestView()
        .getTestPreview()
        .getMetaData()
        .getTestMeta()
        .getAntiCheatingList()
      var isVideoRecordingEnabled = anticheatings.some(
        (a) => a.getAntiCheatingSettings() == 5,
      )

      if (!this.alreadyEnded && isVideoRecordingEnabled) {
        console.log('start recording .......')
        this.$nextTick(() => {
          this.$refs.videoComponent.startRecording({
            testId: this.testView.getTestView()?.getTestPreview()?.getId(),
            candidateId: this.user?.uid,
            qid: this.testView.getTestView()?.getQuestionView()?.getId(),
          })
        })
      }
    },
    reportLostInternet() {
      console.log('Updating Lost internet connection in meta data ...')
      const test_id = this.testView.getTestView()?.getTestPreview()?.getId()
      const qid = this.testView.getTestView()?.getQuestionView()?.getId()
      const candidate_id = this.user?.uid
      this.logProctoringEvent({
        test_id,
        candidate_id,
        qid,
        proctoring_event: 4,
      }).then((r) => {
        console.log(r)
      })
    },
    updateOnlineStatus(event) {
      this.isOnline = navigator.onLine
      console.log('Online status changed to', this.isOnline)
      if (navigator.onLine) {
        // Connection has come back online
        console.log('Internet connection is back online.')
        // Send "internet lost" event to backend here
        this.reportLostInternet()
      } else {
        // Connection has gone offline
        console.log('Internet connection is offline.')
      }
    },
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapGetters('user', ['userId']),
    ...mapState('timesync', ['serverTime']),
    ...mapState('candidate', ['testEndedLocally']),
    ...mapGetters('candidate', ['getContentTypes', 'getCurrentAnswer']),
    finishLine() {
      return Math.floor(
        ((this.currentQuestionNum - 1) * 100) / this.totalQuestions,
      )
    },
    currentQuestionNum() {
      return this.testView?.getTestView()?.getCurrentQuestionNumber()
    },
    totalQuestions() {
      return this.testView?.getTestView()?.getTotalQuestions()
    },
    currentQuestion() {
      return this.testView.getTestView()?.getQuestionView()
    },
    alreadyStarted() {
      return this.testView.getTestView()?.getTestTimeInfo()?.getStartedAt() > 0
    },
    alreadyEnded() {
      return this.testView.getTestView()?.getTestTimeInfo()?.getEndedAt() > 0
    },
    testDuration() {
      return this.testView?.getTestView()?.getTestTimeInfo()?.getDuration()
    },
    testStartEpoch() {
      return this.testView?.getTestView()?.getTestTimeInfo()?.getStartedAt()
    },
    testEndEpoch() {
      console.log('tEE', this.testStartEpoch, this.testDuration)
      return this.testStartEpoch + this.testDuration * 1000
    },
    questionStartEpoch() {
      return this.testView?.getTestView()?.getQuestionTimeInfo()?.getStartedAt()
    },
    questionEndEpoch() {
      // in ms
      return (
        this.questionStartEpoch +
        this.testView?.getTestView()?.getQuestionTimeInfo()?.getDuration() *
          1000
      )
    },
  },
  created() {
    this.initTimeSync()
    if (!this.alreadyStarted) {
      this.startHiringTest({
        test_id: this.testView.getTestView().getTestPreview().getId(),
      }).then((__) => {
        this.startLoading = false
        // window.location.reload();
      })
    } else {
      this.processTestView()
      //this.setTestEndedLocally(this.alreadyEnded)
    }
    this.detectFocusOut()
    this.enforceFullScreen()
    this.detectFullScreenOut()
  },
  mounted() {
    window.addEventListener('online', this.updateOnlineStatus)
    window.addEventListener('offline', this.updateOnlineStatus)
  },

  components: {
    TestQuestion,
    SmallConfirmation,
    TimeoverModal,
    PopUpWarning,
    Video,
  },
  watch: {
    testView: function () {
      console.log('view .....>>>>', this.alreadyEnded, this.testView)
      this.processTestView()
    },
    serverTime: async function (n, o) {
      this.checkForTimeout()
    },
    isOnline(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (!newVal) {
          // If offline
          this.showOfflineAlert = true // Show alert indefinitely
        } else if (newVal && !oldVal) {
          // If just came back online from offline
          this.showOfflineAlert = true
          // Automatically hide the alert after some time
          setTimeout(() => {
            this.showOfflineAlert = false
          }, 3000) // Hide after 3 seconds
        }
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener('online', this.updateOnlineStatus)
    window.removeEventListener('offline', this.updateOnlineStatus)
  },
}
</script>
<style scoped>
.navshadow {
  position: absolute;
  width: 120vw;
  left: -10rem;
  box-shadow: 1px 1px 1px 1px #c4c4c4;
}

html,
body,
video,
canvas {
  margin: 0 !important;
  padding: 0 !important;
}

/* Add the following CSS to hide the video element */
video[hidden] {
  display: none;
}
</style>
