<template>
  <v-card
    id="next"
    class="mt-2"
    :width="270"
    :height="100"
    :to="'/drills/' + drill.getContentPreview().getUrl()"
  >
    <div class="d-flex flex-no-wrap">
      <v-avatar class="mt-3 ml-3" :size="imageWidth" tile>
        <v-img :src="drill.getContentPreview().getMetaData().getImageUrl()" />
      </v-avatar>
      <div>
        <v-card-title>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <span
                v-bind:style="{ width: titleWidth + 'px' }"
                class="d-inline-block text-truncate"
                v-on="on"
              >
                {{ drill.getContentPreview().getTitle() }}
              </span>
            </template>
            {{ drill.getContentPreview().getTitle() }}
          </v-tooltip>
        </v-card-title>
        <v-card-subtitle
          >{{ solved }}/{{ totalContent }} Problem Solved
        </v-card-subtitle>
      </div>
    </div>
    <v-progress-linear
      bottom
      absolute
      color="accent"
      class="mt-5"
      :value="progress"
      :height="10"
    ></v-progress-linear>
  </v-card>
</template>
<script>
export default {
  props: {
    drill: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      titleWidth: 0,
      imageWidth: 0,
    }
  },
  computed: {
    solved() {
      if (this.drill && this.drill.getUserActivity()) {
        return this.drill
          .getUserActivity()
          .getData()
          .getDrillReport()
          .getCompletedContentCount()
      } else {
        return 0
      }
    },
    progress() {
      if (this.drill && this.drill.getUserActivity()) {
        var sol =
          this.drill
            .getUserActivity()
            .getData()
            .getDrillReport()
            .getCompletedContentCount() / this.totalContent
        return sol * 100
      } else {
        return 0
      }
    },
    totalContent() {
      if (
        this.drill &&
        this.drill.getContentPreview().getMetaData().getDrillMeta()
      ) {
        return this.drill
          .getContentPreview()
          .getMetaData()
          .getDrillMeta()
          .getTotalContent()
      } else {
        return 1
      }
    },
  },

  mounted() {
    this.getCardWidth()
  },

  methods: {
    getCardWidth() {
      this.titleWidth = (document.getElementById('next').clientWidth * 3) / 5
      this.imageWidth = (document.getElementById('next').clientWidth * 1) / 5
    },
  },
}
</script>

<style></style>
