<template>
  <div id="button-container">
    <div id="google-calendar-button"></div>
  </div>
</template>

<script>
export default {
  name: 'DemoPage',
  mounted() {
    this.loadScript(
      'https://calendar.google.com/calendar/scheduling-button-script.js',
      () => {
        window.calendar.schedulingButton.load({
          url: 'https://calendar.google.com/calendar/appointments/schedules/AcZssZ23IPM1-5Jh3eB-tgfI4NEsTZ6is1BYHTVXNlegU9aomcZnxOtPLc5CAyQJjmk5Gi3idG2S2ZML?gv=true',
          color: '#2eac68',
          label: 'Book a Quick Demo',
          target: document.getElementById('google-calendar-button'),
        })
      },
    )
  },
  methods: {
    loadScript(url, callback) {
      let script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = url
      script.onreadystatechange = callback
      script.onload = callback
      document.head.appendChild(script)
    },
  },
}
</script>

<style scoped>
@import url('https://calendar.google.com/calendar/scheduling-button-script.css');

#button-container {
  display: flex;
  justify-content: center;
  height: auto;
}
</style>
