<template>
  <v-card>
    <v-card outlined :height="75" class="px-2">
      <v-row no-gutters class="pt-2" align="center">
        <v-col v-if="autopick" :cols="4" :md="2">
          <a
            color="accent"
            class="link_btn font-weight-bold"
            @click.stop="pickAProblem"
          >
            Auto pick a problem
          </a>
        </v-col>
        <v-spacer></v-spacer>
        <v-col v-if="showOption" :cols="3" :lg="2" class="hidden-sm-and-down">
          <v-checkbox v-model="showTags" label="Show tags"></v-checkbox>
        </v-col>

        <v-col v-if="showOption" :cols="4" :lg="3" class="hidden-sm-and-down">
          <v-checkbox
            v-model="hideSolved"
            label="Hide solved problems"
          ></v-checkbox>
        </v-col>
        <v-col :cols="12" :lg="3" :sm="8" md="4">
          <v-text-field
            v-model="search"
            :append-icon="mdiMagnify"
            :label="live ? 'Search' : 'Search (title, tags)'"
            clearable
            single-line
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card>
    <v-data-table
      :headers="headers"
      :items="problemsObject"
      :footer-props.sync="footerOptions"
      :hide-default-footer="hideFooter"
      :search="search"
      :custom-sort="customSort"
    >
      <template v-slot:item="{ item }">
        <tr>
          <td v-if="showIds">
            <div class="status_dot" :class="getStatusColor(item)" />
            {{ item.contentPreview.orderId }}
          </td>
          <td>
            <div
              v-if="!showIds"
              class="status_dot"
              :class="getStatusColor(item)"
            />
            <router-link
              class="ml-1 problem_link"
              :to="urlPrefix + '/problems/' + item.contentPreview.url"
            >
              {{ item.contentPreview.title }}
            </router-link>
          </td>
          <td v-if="$vuetify.breakpoint.lgAndUp && showSolved">
            {{ solved(item) }}
          </td>
          <td v-if="$vuetify.breakpoint.lgAndUp">
            {{ item.contentPreview.metaData.difficulty }}
          </td>
          <td :colspan="3" v-if="!live && $vuetify.breakpoint.lgAndUp">
            <v-chip
              outlined
              color="grey lighten-2"
              text-color="grey"
              class="mr-2"
              v-for="t in item.contentPreview.metaData.tagsList"
              label
              :key="t"
              @click="searchTag"
            >
              {{ t }}
            </v-chip>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mdiMagnify } from '@mdi/js'
import { resolvePath } from '@/utils/helper.js'
export default {
  props: {
    problems: {
      type: Array,
      required: true,
    },
    autopick: {
      type: Boolean,
      required: false,
      default: false,
    },
    showOption: {
      type: Boolean,
      required: false,
      default: true,
    },
    hideFooter: {
      type: Boolean,
      required: false,
      default: false,
    },
    live: {
      type: Boolean,
      required: false,
      default: false,
    },
    urlPrefix: {
      type: String,
      required: false,
      default: '',
    },
    showDifficultyTag: {
      type: Boolean,
      required: false,
      default: true,
    },
    showIds: {
      type: Boolean,
      required: false,
      default: false,
    },
    showSolved: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data: function () {
    return {
      showTags: true,
      hideSolved: false,
      footerOptions: {
        itemsPerPageOptions: [25, 50, 100, -1],
      },
      search: null,
      mdiMagnify: mdiMagnify,
    }
  },
  computed: {
    headers() {
      var h = []
      if (this.showIds) {
        h.push({
          text: 'Id',
          align: 'left',
          sortable: true,
          value: 'contentPreview.orderId',
        })
      }
      h.push({
        text: 'Title',
        align: 'left',
        sortable: true,
        value: 'contentPreview.title',
      })
      if (this.showSolved) {
        h.push({
          text: 'Users who solved',
          align: 'left',
          sortable: true,
          value: 'contentPreview.metaData.problemMeta.problemStats.solved',
        })
      }
      if (!this.live) {
        h.push({
          text: 'Difficulty',
          align: 'left',
          sortable: true,
          value: 'contentPreview.metaData.difficulty',
        })
        h.push({
          text: 'Tags',
          align: 'left',
          sortable: false,
          value: 'contentPreview.metaData.tagsList',
        })
      }

      var h1 = [
        {
          text: 'Title',
          align: 'left',
          sortable: true,
          value: 'contentPreview.title',
        },
      ]
      if (!this.$vuetify.breakpoint.mobile) return h
      else return h1
    },
    headersMobile() {
      var h = [
        {
          text: 'Title',
          align: 'left',
          sortable: true,
          value: 'contentPreview.title',
        },
      ]
      return h
    },
    problemsObject() {
      // Needed because of https://github.com/vuetifyjs/vuetify/issues/7066
      if (!this.problems) return []
      var idx = 0
      var po =
        this.problems &&
        this.problems
          .map((p) => p.toObject())
          .map((p) => {
            p.contentPreview.orderId = String.fromCharCode(
              'A'.charCodeAt(0) + idx,
            )
            ++idx
            return p
          })
          .filter(
            (p) =>
              !this.hideSolved ||
              !p.userActivity ||
              p.userActivity.activityStatus != 1,
          )
          .map((p) => {
            p.contentPreview.metaData.difficulty = this.getDifficulty(
              p.contentPreview.metaData.tagsList,
            )
            p.contentPreview.metaData.tagsList = this.getTags(
              p.contentPreview.metaData.tagsList,
            )
            return p
          })
      return po
    },
    unsolvedProblems() {
      return this.problems.filter(
        (p) =>
          p.getUserActivity() && p.getUserActivity().getActivityStatus() != 1,
      )
    },
  },
  methods: {
    getStatusColor(p) {
      if (!p.userActivity || !p.userActivity.activityStatus)
        return 'grey lighten-2'
      else if (p.userActivity.activityStatus == 1) return 'green lighten-2'
      else return 'red lighten-2'
    },
    getTags(t) {
      if (!this.showTags) return []
      return t.filter((t) => t.startsWith('topics/')).map((t) => t.substring(7))
    },
    getDifficulty(t) {
      var d = t.find((t) => t.startsWith('difficulties/'))
      if (!d) return ''
      d = d.substring(13)
      d = d.charAt(0).toUpperCase() + d.substring(1)
      return d
    },
    customSort(items, index, isDescending) {
      if (index.length == 0) return items
      items.sort((a, b) => {
        a = resolvePath(a, index[0])
        b = resolvePath(b, index[0])
        if (index[0] == 'contentPreview.metaData.difficulty') {
          var ia, ib
          ia = this.getIdx(a)
          ib = this.getIdx(b)
          if (!isDescending[0]) {
            if (ia == -1) ia = 100
            if (ib == -1) ib = 100
            return ia < ib ? -1 : 1
          } else {
            return ib < ia ? -1 : 1
          }
        } else {
          if (!isDescending[0]) {
            return a < b ? -1 : 1
          } else {
            return b < a ? -1 : 1
          }
        }
      })

      return items
    },
    getIdx(d) {
      if (d === 'Easy') return 0
      else if (d === 'Medium') return 1
      else if (d === 'Hard') return 2
      return -1
    },
    pickAProblem() {
      var nextProblem = this.live
        ? this.getNextBestProblem()
        : this.getRandomProblem()
      var url =
        this.urlPrefix + '/problems/' + nextProblem.getContentPreview().getUrl()
      this.$router.push(url)
    },
    getRandomProblem() {
      var universe = this.unsolvedProblems
      if (universe.length == 0) universe = this.problems
      return universe[Math.floor(Math.random() * universe.length)]
    },
    getNextBestProblem() {
      var universe = this.unsolvedProblems
      if (universe.length == 0) universe = this.problems
      var bestIdx = 0,
        bestVal = -1
      for (var i = 0; i < universe.length; ++i) {
        var val = universe[i]
          .getContentPreview()
          .getMetaData()
          .getProblemMeta()
          .getProblemStats()
          .getSolved()
        if (val > bestVal) {
          bestVal = val
          bestIdx = i
        }
      }
      return universe[bestIdx]
    },
    searchTag(e) {
      this.search = e.target.innerText
    },
    solved(item) {
      if (item.contentPreview.metaData.problemMeta) {
        return item.contentPreview.metaData.problemMeta.problemStats.solved
      }
    },

    getAccuracy(item) {
      if (item.contentPreview.metaData.problemMeta) {
        var a = item.contentPreview.metaData.problemMeta.problemStats.accuracy
        return (Math.round(100 * a * 100) / 100.0).toFixed(2)
      }
    },
  },
  mounted() {
    console.log('Problem list ...', this.problems)
    if (this.autopick) {
      this.pickAProblem()
    }
  },
}
</script>
<style scoped>
.status_dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
}
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.02);
}

.problem_link {
  text-decoration: none;
  color: black;
}

.v-data-table td {
  height: 64px;
}

.link_btn {
  color: #33a79d;
  text-decoration: none;
}
</style>
