var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.fetchSubmissionsStatus !== undefined && _vm.fetchSubmissionsStatus == 3 ? _c('error', [_vm._v(" An error ocurred while loading this page. Please try again ")]) : _c('div', [_c('v-card', {
    staticClass: "pl-2"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "mt-3"
  }, [_vm.isTeamContest ? _c('span', {
    staticClass: "subtitle-1"
  }, [_vm._v(" Team Submission History ")]) : _c('span', {
    staticClass: "subtitle-1"
  }, [_vm._v("Submission History ")]), _c('v-tooltip', {
    attrs: {
      "left": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": "",
            "color": "accent",
            "large": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          staticClass: "text-right",
          attrs: {
            "x-large": ""
          },
          on: {
            "click": _vm.load
          }
        }, [_vm._v(_vm._s(_vm.mdiCached))])], 1)];
      }
    }])
  }, [_c('span', {
    attrs: {
      "color": "red"
    }
  }, [_vm._v("Load submissions")])])], 1)], 1), _vm.submissionHistory ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-data-table', {
    staticClass: "submission_history rounded-md",
    attrs: {
      "dense": "",
      "server-items-length": _vm.totalItems,
      "headers": _vm.headers,
      "items": _vm.submissionHistory,
      "footer-props": _vm.footerOptions,
      "loading": _vm.pageLoading,
      "options": _vm.pagination
    },
    on: {
      "update:footerProps": function ($event) {
        _vm.footerOptions = $event;
      },
      "update:footer-props": function ($event) {
        _vm.footerOptions = $event;
      },
      "update:options": function ($event) {
        _vm.pagination = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (_ref2) {
        var item = _ref2.item,
          index = _ref2.index;
        return [_c('tr', [_c('td', [_vm._v(_vm._s(_vm.formatDate(item.getSubmittedOn())))]), _c('td', {
          staticClass: "text-capitalize",
          class: {
            'green--text': _vm.isCorrectSubmission(item),
            'red--text': _vm.isIncorrectSubmission(item)
          }
        }, [_vm._v(" " + _vm._s(_vm.getStatus(item.getStatus())) + " ")]), _c('td', [_vm._v(_vm._s(item.getTimeTaken()) + " ms")]), _c('td', [_vm._v(_vm._s(item.getMemoryTaken()) + " KiB")]), _vm.isTeamContest ? _c('td', [_vm._v(" " + _vm._s(item.getUserPreview().getName()) + " ")]) : _vm._e(), _c('td', [_c('v-dialog', {
          key: item.getId(),
          attrs: {
            "width": "1000"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref3) {
              var on = _ref3.on,
                attrs = _ref3.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "text": "",
                  "small": "",
                  "outlined": "",
                  "color": "primary"
                },
                on: {
                  "click": function ($event) {
                    _vm.fetchDetail(item.getId(), index);
                  }
                }
              }, 'v-btn', attrs, false), on), [_vm._v(" See Code ")])];
            }
          }], null, true),
          model: {
            value: _vm.submissionDialog[index],
            callback: function ($$v) {
              _vm.$set(_vm.submissionDialog, index, $$v);
            },
            expression: "submissionDialog[index]"
          }
        }, [_vm.viewingSubmission ? _c('submission-modal', {
          attrs: {
            "submission": _vm.viewingSubmission
          },
          on: {
            "closesubmodal": function ($event) {
              return _vm.closeSubmission(index);
            }
          }
        }) : _vm._e()], 1)], 1)])];
      }
    }], null, false, 86461858)
  })], 1)], 1) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }