var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('v-tabs', {
    attrs: {
      "vertical": _vm.$vuetify.breakpoint.smAndDown,
      "color": "accent"
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab', {
    attrs: {
      "href": "#overview"
    }
  }, [_vm._v("Overview")]), _vm.teamContest ? _c('v-tab', {
    attrs: {
      "href": "#myteam"
    }
  }, [_vm._v("My Registration")]) : _vm._e(), _vm.teamContest ? _c('v-tab', {
    attrs: {
      "href": "#allteams"
    }
  }, [_vm._v("All Registrants")]) : _vm._e()], 1), _c('v-tabs-items', {
    attrs: {
      "value": _vm.tab
    }
  }, [_c('v-tab-item', {
    attrs: {
      "value": "overview"
    }
  }, [_vm.acceptedTeam ? _c('v-row', [_c('v-col', [_c('v-alert', {
    attrs: {
      "outlined": "",
      "border": "top",
      "color": "green",
      "type": "info"
    }
  }, [_vm._v(" You are registered for this contest, check details "), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "blue"
    },
    on: {
      "click": _vm.goToTeam
    }
  }, [_vm._v("My Registration")])], 1)], 1)], 1) : _vm._e(), _c('v-row', [_c('v-col', [_c('problem-list', {
    attrs: {
      "live": "",
      "urlPrefix": '/contests/' + this.contentView.getUrl(),
      "problems": _vm.problems,
      "showOption": false,
      "showIds": "",
      "showSolved": !_vm.isPrivateScoreboard
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('div', {
    staticClass: "display-1"
  }, [_vm._v("About")])])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": 11
    }
  }, [_c('v-card', {
    staticClass: "pl-5 py-3",
    attrs: {
      "flat": "",
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "d-flex flex-no-wrap"
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('html-view', {
    attrs: {
      "html": _vm.view.getAboutHtml()
    }
  })], 1)])])], 1)], 1), _c('v-row', {
    staticClass: "mt-8"
  }, [_c('v-col', [_c('div', {
    staticClass: "display-1"
  }, [_vm._v("Rules")])])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": 11
    }
  }, [_c('v-card', {
    staticClass: "pl-5 py-3",
    attrs: {
      "flat": "",
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "d-flex flex-no-wrap"
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('html-view', {
    attrs: {
      "html": _vm.view.getRulesHtml()
    }
  })], 1)])])], 1)], 1)], 1), _c('v-tab-item', {
    attrs: {
      "value": "myteam"
    }
  }, [!_vm.user ? _c('v-row', [_c('v-col', [_vm._v(" Please "), _c('router-link', {
    attrs: {
      "to": "/community/login"
    }
  }, [_vm._v(" login ")]), _vm._v(" to create team ")], 1)], 1) : _vm.userContestTeams && _vm.userContestTeams.length == 0 ? _c('v-row', [_c('v-col', [_vm._v(" You are not member of any team ")])], 1) : !_vm.acceptedTeam ? _c('v-row', [_c('v-col', [_vm._v(" No Accepted Invitation ")])], 1) : _vm._e(), _vm.acceptedTeam ? _c('v-row', [_c('v-col', [_c('team-detail', {
    attrs: {
      "teamView": _vm.acceptedTeam
    }
  })], 1)], 1) : _vm._e()], 1), _c('v-tab-item', {
    attrs: {
      "value": "allteams"
    }
  }, [_c('v-row', [_c('v-col', [_c('teams-list', {
    attrs: {
      "contestId": _vm.contentView.getId(),
      "contestUrl": _vm.contentView.getUrl(),
      "contestMetadata": _vm.contentView.getMetaData().getContestMeta()
    }
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }