var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('v-row', [_c('v-col', [_c('code-display', {
    attrs: {
      "value": _vm.value,
      "availableLanguages": _vm.editorialLanguages,
      "language": _vm.language
    },
    on: {
      "setLanguage": _vm.onLanguageChange
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }