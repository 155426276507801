<template>
  <v-row>
    <v-col :cols="10" :offset="1">
      <p>
        <v-icon color="danger" class="mr-2">{{ mdiAlert }}</v-icon>
        <slot></slot>
      </p>
    </v-col>
  </v-row>
</template>

<script>
import { mdiAlert } from '@mdi/js'

export default {
  name: 'error',
  data() {
    return {
      mdiAlert: mdiAlert,
    }
  },
}
</script>
