<template>
  <v-card elevation="0" rounded="0">
    <v-card-title class="justify-center">
      <div class="text-h5 font-weight-bold">FAQs</div>
    </v-card-title>
    <v-row justify="center" class="px-8 py-8">
      <v-expansion-panels>
        <v-expansion-panel v-for="(item, i) in faqs" :key="i" class="my-2">
          <v-expansion-panel-header class="font-weight-medium text-body-1">
            {{ item.question }}
          </v-expansion-panel-header>
          <v-expansion-panel-content class="text-subtitle-1">
            {{ item.answer }}
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      faqs: [
        {
          question: 'How does CodeDrills assess candidates?',
          answer:
            'When creating an assessment, just select the skills that you are looking for and our AI will automatically create an unique test for each candidate. The questions in the skills are curated by our team of industry experts who have vast experience in hiring candidates.',
        },
        // {
        //   question: 'Unlimited candidates and assessments? What is the catch?',
        //   answer:
        //     "There is no catch! It is truly unlimited. Unlike our competitiors we don't keep artificial limits and combination. Our plans are designed to give you the best value.",
        // },
        {
          question:
            'What can I do with the free plan? Is credit card required?',
          answer:
            'The free plan is designed to help you familiarize with the CodeDrills platform. We provide a sample of our skills. You can create assessments but only emails of your own work domain can be invited to the assessment.',
        },
        {
          question: 'The skill I want does not exist',
          answer:
            'Just drop us a message using the chat assistant that is present on the bottom right corner and we will get started with adding the skill!',
        },
        {
          question: 'Need more help?',
          answer:
            'Just drop us a message using the chat assistant that is present on the bottom right corner and we will help you. You can also mail us at hello@codedrills.io',
        },
      ],
    }
  },
}
</script>
