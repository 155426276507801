var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "pl-2",
    attrs: {
      "max-width": "570",
      "height": "195",
      "outlined": ""
    }
  }, [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": 8
    }
  }, [_vm._v(" " + _vm._s(_vm.cardText) + " "), _c('br'), _c('br'), _c('v-btn', {
    staticClass: "text-capitalize",
    attrs: {
      "color": "accent",
      "size": "35"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push(_vm.startNow);
      }
    }
  }, [_vm._v(" Start Now ")])], 1), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": 4,
      "md": 3,
      "sm": 3
    }
  }, [_c('v-img', {
    attrs: {
      "height": "height",
      "width": "width",
      "src": require('./../assets/images/' + _vm.imageName + '.svg')
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }