<template>
  <!-- <video ref="video" controls autoplay playsinline></video> -->

  <div>
    <div v-if="videoVisible">
      <div class="video-container">
        <video ref="video" controls autoplay playsinline></video>
        <button @click="closeVideo">Close Video</button>
      </div>
    </div>
    <div v-if="showPermissionGuide" class="permission-guide">
      <v-icon :color="brandError">{{ mdiVideoOff }}</v-icon>
      <p style="white-space: pre-line">{{ permissionGuideMessage }}</p>
      <p>Please refresh the page after updating the settings.</p>
    </div>
  </div>
</template>

<script>
import RecordRTC from 'recordrtc'
import { mapActions } from 'vuex'
import { mdiVideoOff } from '@mdi/js'

export default {
  data() {
    return {
      recorder: null,
      camera: null,
      brandError: '#D30001',
      mdiVideoOff,
      duration: 5000,
      recording: false,
      paused: false,
      test_id: null,
      candidate_id: null,
      qid: null,
      videoWidth: 250, // Set the desired video width
      videoHeight: 200, // Set the desired video height
      videoVisible: false,
      showPermissionGuide: false,
      permissionGuideMessage: '',
      config: {
        type: 'video',
        mimeType: 'video/webm;codecs=vp8',
        videoBitsPerSecond: 96000, // Lower video bitrate
        audioBitsPerSecond: 32000, // Lower audio bitrate
        canvas: {
          width: 480, // Lower resolution
          height: 360,
        },
        sampleRate: 48000, // Lower sample rate for audio
        bufferSize: 4096, // Smaller buffer size for audio
        numberOfAudioChannels: 1, // Mono audio
        frameRate: 15, // Lower frame rate
      },
    }
  },
  methods: {
    ...mapActions('candidate', ['logProctoringEvent']),
    captureCamera() {
      const constraints = {
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          sampleRate: 16000,
          ChannelCount: 1,
        },
        video: {
          width: this.videoWidth,
          height: this.videoHeight,
        },
      }

      return navigator.mediaDevices.getUserMedia(constraints).catch((error) => {
        console.error(error)
        throw new Error('Error accessing camera or microphone: ', error)
      })
    },
    async stopRecordingCallback() {
      this.camera.stop()

      const blob = this.recorder.getBlob()

      console.log('blob of length', blob.size)

      this.logProctoringEvent({
        test_id: this.test_id,
        candidate_id: this.candidate_id,
        qid: this.qid,
        proctoring_event: 5,
        video: new Uint8Array(await blob.arrayBuffer()),
        type: blob.type,
      })
        .then((response) => {
          console.log('Video uploaded successfully!' + response)
        })
        .catch((error) => {
          console.error('Error uploading video: ', error)
        })
        .finally(async () => {
          this.stopRecorder()
          console.log('Recording stopped...')
        })
    },
    startRecording(args) {
      console.log('Recording started...', args)

      if (this.recorder) {
        this.stopRecorder()
      }
      this.test_id = args.testId
      this.candidate_id = args.candidateId
      this.qid = args.qid

      this.recording = true

      this.captureCamera()
        .then((camera) => {
          this.recorder = RecordRTC(camera, this.config)

          const recordingDuration = parseInt(this.duration) || 5000
          this.recorder
            .setRecordingDuration(recordingDuration)
            .onRecordingStopped(this.stopRecordingCallback)

          this.camera = camera
          this.recorder.startRecording()
        })
        .catch((error) => {
          console.error('Error accessing camera or microphone: ', error)
          this.recording = false
        })
    },
    /*
    stopRecording() {
      return this.recorder.stopRecording(this.stopRecordingCallback)
      .then(async () => {
        this.camera.stop();
        await this.recorder.destroy();
        this.recording = false;
        console.log('Recording stopped...');
      })
    },
    */
    async stopRecorder() {
      try {
        if (this.recorder) {
          await this.camera.stop()
          this.camera.getTracks().forEach((track) => {
            console.log('Stopping track...')
            track.stop()
          })
          await this.recorder.destroy()
          this.recorder = null
          console.log('Recording stopped...')
        }
      } catch (error) {
        console.error('Error stopping recording: ', error)
      } finally {
        this.recording = false
      }
    },

    async showVideoPreview() {
      console.log('Recording started...')
      if (this.recorder) {
        await this.stopRecording()
      }
      this.videoVisible = true
      this.recording = true

      return this.captureCamera()
        .then((camera) => {
          const video = this.$refs.video
          video.srcObject = camera

          this.recorder = RecordRTC(camera, this.config)

          this.camera = camera
          this.recorder.startRecording()
          video.muted = true
        })
        .catch((error) => {
          console.error('Error accessing camera or microphone: ', error)
          // Log an alert or display a message to inform the user about the permission issue
          alert(
            'Please allow access to camera and microphone to use this feature.',
          )

          // Guide the user to change the settings manually
          let guideMessage = ''
          if (navigator.userAgent.indexOf('Chrome') !== -1) {
            guideMessage = this.chromePermissionGuide()
          } else if (navigator.userAgent.indexOf('Safari') !== -1) {
            guideMessage = this.safariPermissionGuide()
          }

          // Display the guide message to the user
          this.showPermissionGuide = true
          this.permissionGuideMessage = guideMessage
        })
    },

    chromePermissionGuide() {
      var permissionGuide =
        'To enable camera and microphone permissions in Google Chrome, please follow these steps:\n\n'
      permissionGuide +=
        "1. Click on the lock icon or 'i' icon in the address bar.\n"
      permissionGuide += "2. In the dropdown menu, click on 'Site settings'.\n"
      permissionGuide += "3. Scroll down and find 'Camera' and 'Microphone'.\n"
      permissionGuide +=
        "4. Select 'Allow' from the dropdown for the website you are using.\n"

      return permissionGuide
    },
    safariPermissionGuide() {
      var permissionGuide =
        'To enable camera and microphone permissions in Safari, please follow these steps:\n\n'
      permissionGuide += '1. Open Safari on your Mac.\n'
      permissionGuide +=
        "2. Click on the 'Safari' menu in the top menu bar and select 'Preferences'.\n"
      permissionGuide += "3. Go to the 'Websites' tab.\n"
      permissionGuide +=
        "4. In the left sidebar, click on 'Camera' or 'Microphone' depending on the permission you want to enable.\n"
      permissionGuide +=
        '5. On the right side, you will see a list of websites. Locate the website you are using.\n'
      permissionGuide +=
        "6. Set the permission to 'Allow' for the website you want to grant camera or microphone access.\n"
      permissionGuide += '7. Close the Preferences window.\n'
      return permissionGuide
    },
    async closeVideo() {
      if (this.recording) {
        const video = this.$refs.video
        video.src = ''
        this.videoVisible = false
        this.$emit('closePreview', true)
        this.stopRecorder()
        this.recording = false
      } else {
        this.videoVisible = false
        this.$emit('closePreview', true)
      }
    },
  },
}
</script>
<style>
.video-container {
  position: relative;
  width: 100%;
}

.video-container video {
  width: 100%;
  height: auto;
}

.video-container button {
  position: absolute;
  top: 10px;
  right: 10px;
}
.permission-guide {
  background-color: #fdf6e3;
  border-color: #faebcc;
  color: #8a6d3b;
  padding: 12px;
  border-radius: 4px;
  margin-top: 10px;
}
</style>
