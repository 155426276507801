var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [!_vm.userId ? _c('error', [_vm._v("Please "), _c('router-link', {
    attrs: {
      "to": "/community/login"
    }
  }, [_vm._v("login")]), _vm._v(" to view submission history")], 1) : _vm.fetchSubmissionsStatus !== undefined && _vm.fetchSubmissionsStatus == 3 ? _c('error', [_vm._v("An error ocurred while loading this page. Please try again")]) : _c('span', [_c('v-row', {
    staticClass: "py-5 px-2"
  }, [_c('v-col', [_c('v-card', [_c('v-row', [_c('v-col', {
    staticClass: "pl-10 mt-3"
  }, [_vm.isTeamContest ? _c('span', {
    staticClass: "subtitle-1"
  }, [_vm._v("Team Submission History")]) : _c('span', {
    staticClass: "subtitle-1"
  }, [_vm._v("Submission History ")]), _c('v-tooltip', {
    attrs: {
      "left": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": "",
            "color": "accent",
            "large": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          staticClass: "text-right",
          attrs: {
            "x-large": ""
          },
          on: {
            "click": _vm.load
          }
        }, [_vm._v(_vm._s(_vm.mdiCached))])], 1)];
      }
    }])
  }, [_c('span', {
    attrs: {
      "color": "red"
    }
  }, [_vm._v("Load submissions")])])], 1)], 1), _vm.submissionHistory ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-data-table', {
    staticClass: "mx-6 mb-10 submission_history rounded-md",
    attrs: {
      "server-items-length": _vm.totalItems,
      "headers": _vm.headers,
      "items": _vm.submissionHistory,
      "footer-props": _vm.footerOptions,
      "loading": _vm.pageLoading,
      "options": _vm.pagination
    },
    on: {
      "update:footerProps": function ($event) {
        _vm.footerOptions = $event;
      },
      "update:footer-props": function ($event) {
        _vm.footerOptions = $event;
      },
      "update:options": function ($event) {
        _vm.pagination = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('tr', [_c('td', [_vm._v(_vm._s(_vm.formatDate(item.getSubmittedOn())))]), _c('td', {
          staticClass: "text-capitalize",
          class: _vm.statusClass(item.getStatus())
        }, [_vm._v(" " + _vm._s(_vm.submissionStatusTitle(item)) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.submissionScoreString(item)) + " ")]), _c('td', [_vm._v(_vm._s(item.getTimeTaken()) + " ms")]), _c('td', [_vm._v(_vm._s(item.getMemoryTaken()) + " KiB")]), _vm.isTeamContest ? _c('td', [_vm._v(" " + _vm._s(item.getUserPreview().getName()) + " ")]) : _vm._e(), _c('td', [_c('router-link', {
          staticClass: "view_details_btn",
          attrs: {
            "outlined": "",
            "height": "25",
            "to": '/submissions/' + item.getId(),
            "target": "_blank"
          }
        }, [_vm._v(" View Details ")])], 1)])];
      }
    }], null, false, 3883148760)
  })], 1)], 1) : _vm._e()], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }