/* eslint-disable no-undef */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import site from 'codedrills_proto/io/codedrills/proto/site/content_view_service_grpc_web_pb'
import judge from 'codedrills_proto/io/codedrills/proto/judge/judge_service_grpc_web_pb'
import { ApiCall } from '@/utils/api.js'

var judge_proto = proto.io.codedrills.proto.judge
var content_proto = proto.io.codedrills.proto.content

const judgeService = new judge.JudgeServicePromiseClient(
  process.env.VUE_APP_JUDGE_API_URL + '/judge',
  null,
  null,
)

const state = {
  submissionStatus: null,
  submissionId: null,
  submission: null,
  solved: null,
  submissionHistory: null,
  submissionError: null,
  submissionDetail: null,
  codeTemplate: null,
  submitStatus: 0,
  submissionStatusStatus: 0,
  fetchSubmissionsStatus: 0,
  fetchSubmissionDetailStatus: 0,
  fetchCodeTemplateStatus: 0,
}

const actions = {
  submit: new ApiCall('submit')
    .authRequired()
    .withServiceCall((r, h) => judgeService.submitCode(r, h))
    .withRequest(
      (
        { id, version, code, isFull, isUserInput, parentId, language, stdin },
        ut,
        { commit, rootGetters },
      ) => {
        console.log('Submit Code in judge')
        var type = judge_proto.SubmissionType.FULL
        if (!isFull) {
          if (isUserInput) {
            type = judge_proto.SubmissionType.USER_INPUT
          } else {
            type = judge_proto.SubmissionType.SAMPLE
          }
        }
        console.log('Submitted Code for', language, code, type, stdin)
        return new judge_proto.master.SubmitCodeRequest()
          .setProblemId(id)
          .setVersion(version)
          .setCode(code)
          .setLanguage(judge_proto.Language[language])
          .setParentId(parentId)
          .setUserStdinInput(stdin)
          .setSubmissionType(type)
      },
    )
    .onSuccess(({ commit, dispatch }, res, { id }) => {
      console.log('Submit success: ', res.getSubmissionId())
      commit('setSubmissionId', res.getSubmissionId())
      dispatch('submissionStatus', { submissionId: res.getSubmissionId() })
    })
    .build(),

  submissionStatus: new ApiCall('submissionStatus')
    .stream()
    .authRequired()
    .withServiceCall((r, h) => judgeService.submissionStatusListener(r, h))
    .withRequest(({ submissionId }) => {
      if (!submissionId) return null
      return new judge_proto.master.SubmissionStatusRequest().setSubmissionId(
        submissionId,
      )
    })
    .onUpdate(({ status, commit, getters, dispatch }, res) => {
      if (res.getSubmissionId() === state.submissionId) {
        commit('setSubmissionStatus', res)
      }
    })
    .onSuccess(({ state, getters, dispatch }, _, req) => {
      if (!state.submissionStatus || !getters.judgingCompleted) {
        if (req.submissionId === state.submissionId) {
          setTimeout(() => dispatch('submissionStatus', req), 3000)
        }
      }
    })
    .onError((err, { dispatch }, req) => {
      if (req.submissionId === state.submissionId) {
        setTimeout(() => dispatch('submissionStatus', req), 4000)
      }
    })
    .build(),

  fetchSubmissions: new ApiCall('fetchSubmissions')
    .authRequired()
    .withServiceCall((r, h) => judgeService.submissionHistory(r, h))
    .withRequest(({ problemId, userId, parentId, paginationQuery }, _, __) => {
      console.log('pagination', paginationQuery)
      return new judge_proto.master.SubmissionHistoryRequest()
        .setProblemId(problemId)
        .setParentId(new content_proto.ContentId().setId(parentId))
        .setPaginationQuery(
          new content_proto.PaginationQuery()
            .setItemsPerPage(paginationQuery.itemsPerPage || 10)
            .setPageIndex((paginationQuery.page || 1) - 1),
        )
    })
    .onSuccess(({ commit, getters, dispatch }, res) => {
      console.log('Subs ..', res.toObject())
      commit('setSubmissionHistory', res.getSubmissionStatusList())
      return res
    })
    .build(),

  fetchSubmissionDetail: new ApiCall('fetchSubmissionDetail')
    .authRequired()
    .withServiceCall((r, h) => judgeService.submission(r, h))
    .withRequest(({ submissionId }, _, { commit }) => {
      commit('setSubmissionDetail', null)
      return new judge_proto.master.SubmissionRequest().setSubmissionId(
        submissionId,
      )
    })
    .onSuccess(({ commit, getters, dispatch }, res) => {
      commit('setSubmissionDetail', res)
      return res
    })
    .build(),

  async loadHistory({ state, dispatch, commit }, args) {
    commit('setSubmissionDetail', null)
    return dispatch('fetchSubmissions', args)
  },

  fetchCodeTemplate: new ApiCall('fetchCodeTemplate')
    .withServiceCall((r, h) => judgeService.createSolutionTemplate(r, h))
    .withRequest(({ problemId, version, language }, _, __) => {
      console.log('langs', judge_proto.Language)
      console.log(
        'Fetching template ff',
        problemId,
        version,
        language,
        judge_proto.Language[language],
      )
      var req = new judge_proto.master.CreateSolutionTemplateRequest()
        .setProblemId(problemId)
        .setVersion(version)
        .setLanguage(judge_proto.Language[language])
      return req
    })
    .onSuccess(({ commit, getters, dispatch }, res) => {
      commit('setCodeTemplate', res.getCode())
    })
    .build(),
}

const getters = {
  userToken: function (state, getters, rootState, rootGetters) {
    return rootGetters['user/userToken']
  },
  types: function () {
    return Object.keys(proto.io.codedrills.proto.content.ContentType)
  },
  submissionStatus: (state) => state.submissionStatus,
  statusKeys() {
    var retStatus = {}
    console.log('statusKeys', judge_proto.Status)
    for (var key in judge_proto.Status) {
      retStatus[judge_proto.Status[key]] = key
    }
    return retStatus
  },
  judgingCompleted: (state) =>
    state.submissionStatus && state.submissionStatus.getStatus() >= 10,
  submitting: (state) => state.submitStatus == 1 && !state.submissionId,
  languageKeys() {
    var retLanguage = {}
    console.log('statusKeys', judge_proto.Language)
    for (var key in judge_proto.Language) {
      retLanguage[judge_proto.Language[key]] = key
    }
    return retLanguage
  },
  langaugeOptions() {
    console.log('statusKeys', judge_proto.Language)
    return judge_proto.Language
  },
}

const mutations = {
  setSubmissionId(state, submissionId) {
    state.submissionId = submissionId
  },
  setSubmissionStatus(state, response) {
    state.submissionStatus = response
  },
  submissionStatusStatus(state, response) {
    state.submissionStatusStatus = response
  },
  setSubmissionHistory(state, response) {
    state.submissionHistory = response
  },
  clearHistory(state) {
    if (state.submissionHistory == null) state.submissionHistory = []
    else state.submissionHistory = null
  },
  setSubmission(state, submission) {
    state.submission = submission
  },
  setSubmissionDetail(state, submissionDetail) {
    state.submissionDetail = submissionDetail
  },
  clearSubmissionDetail(state) {
    state.submissionDetail = null
  },
  clearSubmission(state) {
    state.submission = null
    state.submissionStatus = null
    state.submissionId = null
  },
  setSubmitted(state, value) {
    state.hasSubmitted = value
  },
  setSubmissionError(state, error) {
    state.submissionError = error
  },
  submitStatus(state, value) {
    state.submitStatus = value
  },
  fetchSubmissionDetailStatus(state, value) {
    state.fetchSubmissionDetailStatus = value
  },
  fetchSubmissionsStatus(state, value) {
    state.fetchSubmissionsStatus = value
  },
  fetchCodeTemplateStatus(state, value) {
    state.fetchCodeTemplateStatus = value
  },
  setCodeTemplate(state, code) {
    state.codeTemplate = code
  },
  submissionsClear(state) {
    state.submissionStatus = null
    state.submissionId = null
    state.submission = null
    state.solved = null
    state.submissionHistory = null
    state.submissionError = null
    state.submissionDetail = null
    state.codeTemplate = null
    state.submitStatus = 0
    state.submissionStatusStatus = 0
    state.fetchSubmissionsStatus = 0
    state.fetchSubmissionDetailStatus = 0
    state.fetchCodeTemplateStatus = 0
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
