var render = function render(){
  var _vm$currentQuestion$g, _vm$currentQuestion$g2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "d-flex flex-column",
    attrs: {
      "elevation": "0",
      "outlined": ""
    }
  }, [_c('v-card-text', [_c('v-row', {
    class: _vm.questionType === 'PROBLEM' ? 'joining-line-bottom' : ''
  }, [_c('v-col', {
    staticClass: "pa-0 ma-0",
    attrs: {
      "cols": "6"
    }
  }, [_vm.questionType === 'PROBLEM' ? _c('v-tabs', {
    model: {
      value: _vm.currProblemTab,
      callback: function ($$v) {
        _vm.currProblemTab = $$v;
      },
      expression: "currProblemTab"
    }
  }, [_c('v-tab', [_vm._v("Description")]), _c('v-tab', [_vm._v("Submissions")]), ((_vm$currentQuestion$g = _vm.currentQuestion.getMetaData()) === null || _vm$currentQuestion$g === void 0 ? void 0 : (_vm$currentQuestion$g2 = _vm$currentQuestion$g.getProblemMeta()) === null || _vm$currentQuestion$g2 === void 0 ? void 0 : _vm$currentQuestion$g2.getEvaluationType()) === 1 ? _c('v-tab', [_vm._v("SQL Schema")]) : _vm._e()], 1) : _vm._e()], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "pl-0 py-0 ma-0 pr-2",
    attrs: {
      "cols": _vm.editorFS
    }
  }, [_c('TestQuestionStatement', {
    staticClass: "border__right",
    attrs: {
      "questionStatement": _vm.questionStatement,
      "currentQuestion": _vm.currentQuestion,
      "currProblemTab": _vm.currProblemTab,
      "questionType": _vm.questionType
    }
  })], 1), _c('v-col', {
    staticClass: "pa-0 ma-0",
    attrs: {
      "cols": 12 - _vm.editorFS
    }
  }, [_vm.questionType === 'MCQ' ? _c('div', {
    staticClass: "pt-8 pr-8"
  }, [_vm.mcqHasMultipleAnswers ? _c('span', {
    staticClass: "text-body-1 font-italic"
  }, [_vm._v("This question might have multiple answers")]) : _vm._e(), _vm._l(_vm.mcqOptions, function (opt) {
    return _c('v-checkbox', {
      key: opt.getStatementHtml(),
      staticClass: "radio__option",
      attrs: {
        "multiple": _vm.mcqHasMultipleAnswers,
        "value": opt.getId(),
        "color": "primary"
      },
      on: {
        "change": function ($event) {
          return _vm.updateMcqAnswer(_vm.mcqSelectedAnswer);
        }
      },
      scopedSlots: _vm._u([{
        key: "label",
        fn: function () {
          return [_c('div', {
            staticClass: "radio__parent mt-3",
            domProps: {
              "innerHTML": _vm._s(opt.getStatementHtml())
            }
          })];
        },
        proxy: true
      }], null, true),
      model: {
        value: _vm.mcqSelectedAnswer,
        callback: function ($$v) {
          _vm.mcqSelectedAnswer = $$v;
        },
        expression: "mcqSelectedAnswer"
      }
    });
  })], 2) : _vm.questionType === 'SUBJECTIVE' ? _c('div', {
    staticClass: "pt-8 pr-8"
  }, [_c('v-textarea', {
    attrs: {
      "outlined": "",
      "placeholder": "Enter your answer here",
      "rows": "20"
    },
    on: {
      "input": _vm.setSubjectiveAns,
      "paste": function ($event) {
        $event.preventDefault();
      }
    },
    model: {
      value: _vm.subjectiveAnswer,
      callback: function ($$v) {
        _vm.subjectiveAnswer = $$v;
      },
      expression: "subjectiveAnswer"
    }
  })], 1) : _vm.questionType === 'PROBLEM' ? _c('div', [_c('TestProblemSolution', {
    ref: "problemSolution",
    attrs: {
      "currentQuestion": _vm.currentQuestion
    }
  })], 1) : _vm._e()])], 1)], 1), _c('v-card-actions', {
    staticClass: "justify-space-between align-center px-8 py-4 top-clearance"
  }, [_vm.questionType === 'PROBLEM' ? _c('v-row', [_c('v-col', [_vm._v(" You can submit multiple times before going to the next question! ")])], 1) : _c('v-row', [_c('v-col')], 1), _c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "px-5 text-none text-body-1 font-weight-medium",
          attrs: {
            "elevation": "0",
            "color": "success",
            "disabled": _vm.loadingNext,
            "loading": _vm.loadingNext
          },
          on: {
            "click": _vm.nextQuestion
          }
        }, 'v-btn', attrs, false), on), [_vm._v(" Next ")])];
      }
    }])
  }, [_c('span', [_vm._v(" This button will submit this question and take you to the next question. ")])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }