<template></template>
<script>
import * as monaco from 'monaco-editor'
export default {
  name: 'Autocomplete',

  methods: {
    setupJavaAutocomplete() {
      monaco.languages.registerCompletionItemProvider('java', {
        provideCompletionItems: () => {
          const suggestions = []

          const javaSuggestions = [
            // Primitive Types in Java
            {
              label: 'int',
              kind: monaco.languages.CompletionItemKind.Keyword,
              insertText: 'int ${1:varName};',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Declare an integer variable',
            },
            {
              label: 'boolean',
              kind: monaco.languages.CompletionItemKind.Keyword,
              insertText: 'boolean ${1:varName};',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Declare a boolean variable',
            },
            {
              label: 'char',
              kind: monaco.languages.CompletionItemKind.Keyword,
              insertText: 'char ${1:varName};',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Declare a char variable',
            },
            {
              label: 'float',
              kind: monaco.languages.CompletionItemKind.Keyword,
              insertText: 'float ${1:varName};',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Declare a float variable',
            },
            {
              label: 'double',
              kind: monaco.languages.CompletionItemKind.Keyword,
              insertText: 'double ${1:varName};',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Declare a double variable',
            },
            {
              label: 'long',
              kind: monaco.languages.CompletionItemKind.Keyword,
              insertText: 'long ${1:varName};',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Declare a long variable',
            },
            {
              label: 'byte',
              kind: monaco.languages.CompletionItemKind.Keyword,
              insertText: 'byte ${1:varName};',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Declare a byte variable',
            },
            {
              label: 'short',
              kind: monaco.languages.CompletionItemKind.Keyword,
              insertText: 'short ${1:varName};',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Declare a short variable',
            },

            // Operations on Primitive Types
            {
              label: 'Math.max',
              kind: monaco.languages.CompletionItemKind.Function,
              insertText: 'Math.max(${1:value1}, ${2:value2});',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Find the maximum of two values',
            },
            {
              label: 'Math.min',
              kind: monaco.languages.CompletionItemKind.Function,
              insertText: 'Math.min(${1:value1}, ${2:value2});',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Find the minimum of two values',
            },
            {
              label: 'Math.abs',
              kind: monaco.languages.CompletionItemKind.Function,
              insertText: 'Math.abs(${1:value});',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Get the absolute value of a number',
            },
            {
              label: 'boolean expression',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText:
                'boolean ${1:condition} = ${2:value1} == ${3:value2};',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Create a boolean expression comparing two values',
            },

            // Primitive Arrays
            {
              label: 'int[]',
              kind: monaco.languages.CompletionItemKind.Keyword,
              insertText: 'int[] ${1:array} = new int[${2:size}];',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Declare and initialize an integer array',
            },
            {
              label: 'char[]',
              kind: monaco.languages.CompletionItemKind.Keyword,
              insertText: 'char[] ${1:array} = new char[${2:size}];',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Declare and initialize a char array',
            },
            {
              label: 'double[]',
              kind: monaco.languages.CompletionItemKind.Keyword,
              insertText: 'double[] ${1:array} = new double[${2:size}];',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Declare and initialize a double array',
            },

            // Array Operations
            {
              label: 'Arrays.sort',
              kind: monaco.languages.CompletionItemKind.Function,
              insertText: 'Arrays.sort(${1:array});',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Sort an array using Arrays.sort',
            },
            {
              label: 'Arrays.fill',
              kind: monaco.languages.CompletionItemKind.Function,
              insertText: 'Arrays.fill(${1:array}, ${2:value});',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Fill an array with a specific value',
            },
            {
              label: 'Arrays.binarySearch',
              kind: monaco.languages.CompletionItemKind.Function,
              insertText: 'Arrays.binarySearch(${1:array}, ${2:key});',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Perform binary search on a sorted array',
            },
            {
              label: 'System.out.println',
              kind: monaco.languages.CompletionItemKind.Function,
              insertText: 'System.out.println(${1:expression});',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Prints to the console',
            },
            {
              label: 'public static void main',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText: [
                'public static void main(String[] args) {',
                '\t${1:// body}',
                '}',
              ].join('\n'),
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Main method in Java',
            },
            {
              label: 'if statement',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText: ['if (${1:condition}) {', '\t${2:// body}', '}'].join(
                '\n',
              ),
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'If statement',
            },
            {
              label: 'for loop',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText: [
                'for (int ${1:i} = 0; ${1:i} < ${2:length}; ${1:i}++) {',
                '\t${3:// body}',
                '}',
              ].join('\n'),
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Basic for loop',
            },
            {
              label: 'while loop',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText: [
                'while (${1:condition}) {',
                '\t${2:// body}',
                '}',
              ].join('\n'),
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Basic while loop',
            },
            {
              label: 'do-while loop',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText: [
                'do {',
                '\t${1:// body}',
                '} while (${2:condition});',
              ].join('\n'),
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Basic do-while loop',
            },
            {
              label: 'switch statement',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText: [
                'switch (${1:expression}) {',
                '\tcase ${2:value}:',
                '\t\t${3:// body}',
                '\t\tbreak;',
                '\tdefault:',
                '\t\tbreak;',
                '}',
              ].join('\n'),
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Switch statement',
            },
            {
              label: 'try-catch block',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText: [
                'try {',
                '\t${1:// body}',
                '} catch (${2:Exception e}) {',
                '\t${3:// handle exception}',
                '}',
              ].join('\n'),
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Try-catch block',
            },
            {
              label: 'class',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText: [
                'public class ${1:ClassName} {',
                '\t${2:// body}',
                '}',
              ].join('\n'),
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Create a class',
            },
            //Array
            {
              label: 'int array',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText: 'int[] ${1:arr} = new int[${2:size}];',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Create an integer array',
            },
            {
              label: 'String array',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText: 'String[] ${1:arr} = new String[${2:size}];',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Create a string array',
            },
            {
              label: 'Multi-dimensional array',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText:
                'int[][] ${1:matrix} = new int[${2:rows}][${3:cols}];',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Create a multi-dimensional array',
            },
            // ArrayList
            {
              label: 'ArrayList',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText: 'ArrayList<${1:Type}> ${2:list} = new ArrayList<>();',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Create an ArrayList',
            },
            {
              label: 'ArrayList add',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText: '${1:list}.add(${2:item});',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Add an item to an ArrayList',
            },
            {
              label: 'ArrayList get',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText: '${1:list}.get(${2:index});',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Get an item from an ArrayList',
            },
            // HashMap
            {
              label: 'HashMap',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText:
                'HashMap<${1:Key}, ${2:Value}> ${3:map} = new HashMap<>();',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Create a HashMap',
            },
            {
              label: 'HashMap put',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText: '${1:map}.put(${2:key}, ${3:value});',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Put a key-value pair into a HashMap',
            },
            {
              label: 'HashMap get',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText: '${1:map}.get(${2:key});',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Get a value from a HashMap by key',
            },
            // HashSet
            {
              label: 'HashSet',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText: 'HashSet<${1:Type}> ${2:set} = new HashSet<>();',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Create a HashSet',
            },
            {
              label: 'HashSet add',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText: '${1:set}.add(${2:item});',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Add an item to a HashSet',
            },
            // TreeMap
            {
              label: 'TreeMap',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText:
                'TreeMap<${1:Key}, ${2:Value}> ${3:map} = new TreeMap<>();',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Create a TreeMap',
            },
            // PriorityQueue
            {
              label: 'PriorityQueue',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText:
                'PriorityQueue<${1:Type}> ${2:pq} = new PriorityQueue<>();',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Create a PriorityQueue',
            },
            {
              label: 'PriorityQueue add',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText: '${1:pq}.add(${2:item});',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Add an item to a PriorityQueue',
            },
            {
              label: 'PriorityQueue poll',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText: '${1:pq}.poll();',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Poll the smallest item from a PriorityQueue',
            },
            // LinkedList
            {
              label: 'LinkedList',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText:
                'LinkedList<${1:Type}> ${2:list} = new LinkedList<>();',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Create a LinkedList',
            },
            {
              label: 'LinkedList add',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText: '${1:list}.add(${2:item});',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Add an item to a LinkedList',
            },
            // String operations
            {
              label: 'String length',
              kind: monaco.languages.CompletionItemKind.Function,
              insertText: '${1:str}.length();',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Get the length of a string',
            },
            {
              label: 'String substring',
              kind: monaco.languages.CompletionItemKind.Function,
              insertText: '${1:str}.substring(${2:start}, ${3:end});',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Extract a substring from a string',
            },
            {
              label: 'String split',
              kind: monaco.languages.CompletionItemKind.Function,
              insertText: '${1:str}.split("${2:regex}");',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Split a string using a regex',
            },
            {
              label: 'StringBuilder append',
              kind: monaco.languages.CompletionItemKind.Function,
              insertText:
                'StringBuilder ${1:sb} = new StringBuilder();\n${1:sb}.append(${2:text});',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Create and append to a StringBuilder',
            },

            // Math operations
            {
              label: 'Math max',
              kind: monaco.languages.CompletionItemKind.Function,
              insertText: 'Math.max(${1:a}, ${2:b});',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Get the maximum of two numbers',
            },
            {
              label: 'Math min',
              kind: monaco.languages.CompletionItemKind.Function,
              insertText: 'Math.min(${1:a}, ${2:b});',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Get the minimum of two numbers',
            },
            {
              label: 'Math abs',
              kind: monaco.languages.CompletionItemKind.Function,
              insertText: 'Math.abs(${1:value});',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Get the absolute value of a number',
            },
            {
              label: 'Math pow',
              kind: monaco.languages.CompletionItemKind.Function,
              insertText: 'Math.pow(${1:base}, ${2:exp});',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Raise a number to a power',
            },

            // Scanner input
            {
              label: 'Scanner input',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText:
                'Scanner ${1:scanner} = new Scanner(System.in);\nint ${2:value} = ${1:scanner}.nextInt();',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Read input from the console using Scanner',
            },

            // Arrays and Collections
            {
              label: 'Arrays sort',
              kind: monaco.languages.CompletionItemKind.Function,
              insertText: 'Arrays.sort(${1:arr});',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Sort an array',
            },
            {
              label: 'Collections sort',
              kind: monaco.languages.CompletionItemKind.Function,
              insertText: 'Collections.sort(${1:list});',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Sort a list using Collections.sort()',
            },
            {
              label: 'Collections reverse',
              kind: monaco.languages.CompletionItemKind.Function,
              insertText: 'Collections.reverse(${1:list});',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Reverse a list',
            },

            // Stack
            {
              label: 'Stack push',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText:
                'Stack<${1:Type}> ${2:stack} = new Stack<>();\n${2:stack}.push(${3:item});',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Create a stack and push an item',
            },
            {
              label: 'Stack pop',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText: '${1:stack}.pop();',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Pop an item from a stack',
            },
            {
              label: 'Stack peek',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText: '${1:stack}.peek();',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Peek the top item of a stack',
            },

            // Queue
            {
              label: 'Queue offer',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText:
                'Queue<${1:Type}> ${2:queue} = new LinkedList<>();\n${2:queue}.offer(${3:item});',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Offer an item to a queue',
            },
            {
              label: 'Queue poll',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText: '${1:queue}.poll();',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Poll an item from a queue',
            },

            // TreeSet
            {
              label: 'TreeSet',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText: 'TreeSet<${1:Type}> ${2:set} = new TreeSet<>();',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Create a TreeSet',
            },
            {
              label: 'TreeSet add',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText: '${1:set}.add(${2:item});',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Add an item to a TreeSet',
            },
            {
              label: 'TreeSet first',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText: '${1:set}.first();',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Get the first element of a TreeSet',
            },
            {
              label: 'TreeSet last',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText: '${1:set}.last();',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Get the last element of a TreeSet',
            },
          ]
          suggestions.push(...javaSuggestions)
          return { suggestions }
        },
      })
    },
    setupCppAutocomplete() {
      monaco.languages.registerCompletionItemProvider('cpp', {
        provideCompletionItems: () => {
          const suggestions = []

          const primitiveTypes = [
            {
              label: 'int',
              kind: monaco.languages.CompletionItemKind.Keyword,
              insertText: 'int ${1:var};',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Integer type.',
            },
            {
              label: 'double',
              kind: monaco.languages.CompletionItemKind.Keyword,
              insertText: 'double ${1:var};',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Double precision floating-point type.',
            },
            {
              label: 'char',
              kind: monaco.languages.CompletionItemKind.Keyword,
              insertText: 'char ${1:var};',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Character type.',
            },
            {
              label: 'bool',
              kind: monaco.languages.CompletionItemKind.Keyword,
              insertText: 'bool ${1:var};',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Boolean type.',
            },
            {
              label: 'float',
              kind: monaco.languages.CompletionItemKind.Keyword,
              insertText: 'float ${1:var};',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Single precision floating-point type.',
            },
            {
              label: 'long',
              kind: monaco.languages.CompletionItemKind.Keyword,
              insertText: 'long ${1:var};',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Long integer type.',
            },
          ]
          suggestions.push(...primitiveTypes)

          const controlStructures = [
            {
              label: 'for-loop',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText:
                'for (int ${1:i} = 0; ${1:i} < ${2:n}; ++${1:i}) {\n\t${0}\n}',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Standard for loop in C++.',
            },
            {
              label: 'while-loop',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText: 'while (${1:condition}) {\n\t${0}\n}',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Standard while loop in C++.',
            },
            {
              label: 'if-else',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText: 'if (${1:condition}) {\n\t${0}\n} else {\n\t\n}',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'If-else conditional statement.',
            },
          ]
          suggestions.push(...controlStructures)

          const stl = [
            {
              label: 'std::vector',
              kind: monaco.languages.CompletionItemKind.Class,
              insertText: 'std::vector<${1:int}> ${2:vec};',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation:
                'Vector from the C++ Standard Library (dynamic array).',
            },
            {
              label: 'std::set',
              kind: monaco.languages.CompletionItemKind.Class,
              insertText: 'std::set<${1:int}> ${2:s};',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Set container (unique, ordered elements).',
            },
            {
              label: 'std::map',
              kind: monaco.languages.CompletionItemKind.Class,
              insertText: 'std::map<${1:int}, ${2:int}> ${3:m};',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation:
                'Map (key-value pairs) from the C++ Standard Library.',
            },
            {
              label: 'std::unordered_map',
              kind: monaco.languages.CompletionItemKind.Class,
              insertText: 'std::unordered_map<${1:int}, ${2:int}> ${3:m};',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Unordered map for fast key-value pair access.',
            },
            {
              label: 'std::stack',
              kind: monaco.languages.CompletionItemKind.Class,
              insertText: 'std::stack<${1:int}> ${2:stk};',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Stack container for LIFO operations.',
            },
            {
              label: 'std::queue',
              kind: monaco.languages.CompletionItemKind.Class,
              insertText: 'std::queue<${1:int}> ${2:q};',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Queue container for FIFO operations.',
            },
            {
              label: 'std::deque',
              kind: monaco.languages.CompletionItemKind.Class,
              insertText: 'std::deque<${1:int}> ${2:dq};',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation:
                'Double-ended queue for efficient insertion and deletion at both ends.',
            },
          ]
          suggestions.push(...stl)

          const alogs = [
            {
              label: 'std::sort',
              kind: monaco.languages.CompletionItemKind.Function,
              insertText: 'std::sort(${1:begin}, ${2:end});',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Sort a range of elements.',
            },
            {
              label: 'std::reverse',
              kind: monaco.languages.CompletionItemKind.Function,
              insertText: 'std::reverse(${1:begin}, ${2:end});',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Reverse the order of elements in a range.',
            },
            {
              label: 'std::find',
              kind: monaco.languages.CompletionItemKind.Function,
              insertText: 'std::find(${1:begin}, ${2:end}, ${3:value});',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Find an element in a range.',
            },
            {
              label: 'std::min',
              kind: monaco.languages.CompletionItemKind.Function,
              insertText: 'std::min(${1:a}, ${2:b});',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Find the smaller of two values.',
            },
            {
              label: 'std::max',
              kind: monaco.languages.CompletionItemKind.Function,
              insertText: 'std::max(${1:a}, ${2:b});',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Find the larger of two values.',
            },
            {
              label: 'std::accumulate',
              kind: monaco.languages.CompletionItemKind.Function,
              insertText: 'std::accumulate(${1:begin}, ${2:end}, ${3:init});',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Accumulate values in a range.',
            },
          ]
          suggestions.push(...alogs)

          const inputOutput = [
            {
              label: 'std::cout',
              kind: monaco.languages.CompletionItemKind.Function,
              insertText: 'std::cout << ${1:message} << std::endl;',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Print output to the console.',
            },
            {
              label: 'std::cin',
              kind: monaco.languages.CompletionItemKind.Function,
              insertText: 'std::cin >> ${1:input};',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Take input from the console.',
            },
          ]
          suggestions.push(...inputOutput)

          const strinOperations = [
            {
              label: 'std::string',
              kind: monaco.languages.CompletionItemKind.Class,
              insertText: 'std::string ${1:str};',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Defines a string in C++.',
            },
            {
              label: 'str.append',
              kind: monaco.languages.CompletionItemKind.Function,
              insertText: '${1:str}.append(${2:other_str});',
              documentation: 'Appends another string to this string.',
            },
            {
              label: 'str.length',
              kind: monaco.languages.CompletionItemKind.Function,
              insertText: '${1:str}.length();',
              documentation: 'Returns the length of the string.',
            },
            {
              label: 'str.find',
              kind: monaco.languages.CompletionItemKind.Function,
              insertText: '${1:str}.find(${2:substr});',
              documentation: 'Finds a substring in the string.',
            },
          ]
          suggestions.push(...strinOperations)

          const commonDS = [
            {
              label: 'std::stack',
              kind: monaco.languages.CompletionItemKind.Class,
              insertText: 'std::stack<${1:int}> ${2:stk};',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation:
                'Stack container for Last-In-First-Out operations.',
            },
            {
              label: 'std::queue',
              kind: monaco.languages.CompletionItemKind.Class,
              insertText: 'std::queue<${1:int}> ${2:que};',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation:
                'Queue container for First-In-First-Out operations.',
            },
            {
              label: 'std::priority_queue',
              kind: monaco.languages.CompletionItemKind.Class,
              insertText: 'std::priority_queue<${1:int}> ${2:pq};',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Priority queue for element management.',
            },
            {
              label: 'std::pair',
              kind: monaco.languages.CompletionItemKind.Class,
              insertText: 'std::pair<${1:int}, ${2:int}> ${3:p};',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Pair structure for holding two values.',
            },
          ]
          suggestions.push(...commonDS)

          const cppMathSnippets = [
            {
              label: 'std::sqrt',
              kind: monaco.languages.CompletionItemKind.Function,
              insertText: 'std::sqrt(${1:x});',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Returns the square root of a number.',
            },
            {
              label: 'std::pow',
              kind: monaco.languages.CompletionItemKind.Function,
              insertText: 'std::pow(${1:base}, ${2:exponent});',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation:
                'Returns the base raised to the power of the exponent.',
            },
            {
              label: 'std::abs',
              kind: monaco.languages.CompletionItemKind.Function,
              insertText: 'std::abs(${1:x});',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Returns the absolute value of a number.',
            },
            {
              label: 'std::max',
              kind: monaco.languages.CompletionItemKind.Function,
              insertText: 'std::max(${1:a}, ${2:b});',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Returns the larger of the two arguments.',
            },
            {
              label: 'std::min',
              kind: monaco.languages.CompletionItemKind.Function,
              insertText: 'std::min(${1:a}, ${2:b});',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Returns the smaller of the two arguments.',
            },
            {
              label: 'std::ceil',
              kind: monaco.languages.CompletionItemKind.Function,
              insertText: 'std::ceil(${1:x});',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation:
                'Returns the smallest integer greater than or equal to x.',
            },
            {
              label: 'std::floor',
              kind: monaco.languages.CompletionItemKind.Function,
              insertText: 'std::floor(${1:x});',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation:
                'Returns the largest integer less than or equal to x.',
            },
            {
              label: 'std::round',
              kind: monaco.languages.CompletionItemKind.Function,
              insertText: 'std::round(${1:x});',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Rounds x to the nearest integer.',
            },
            {
              label: 'std::log',
              kind: monaco.languages.CompletionItemKind.Function,
              insertText: 'std::log(${1:x});',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Returns the natural logarithm of a number.',
            },
            {
              label: 'std::exp',
              kind: monaco.languages.CompletionItemKind.Function,
              insertText: 'std::exp(${1:x});',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Returns e raised to the power of x.',
            },
            {
              label: 'std::sin',
              kind: monaco.languages.CompletionItemKind.Function,
              insertText: 'std::sin(${1:x});',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Returns the sine of an angle in radians.',
            },
            {
              label: 'std::cos',
              kind: monaco.languages.CompletionItemKind.Function,
              insertText: 'std::cos(${1:x});',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Returns the cosine of an angle in radians.',
            },
            {
              label: 'std::tan',
              kind: monaco.languages.CompletionItemKind.Function,
              insertText: 'std::tan(${1:x});',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Returns the tangent of an angle in radians.',
            },
            {
              label: 'std::gcd',
              kind: monaco.languages.CompletionItemKind.Function,
              insertText: 'std::gcd(${1:a}, ${2:b});',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation:
                'Returns the greatest common divisor of two numbers.',
            },
            {
              label: 'std::lcm',
              kind: monaco.languages.CompletionItemKind.Function,
              insertText: 'std::lcm(${1:a}, ${2:b});',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation:
                'Returns the least common multiple of two numbers.',
            },
          ]
          suggestions.push(...cppMathSnippets)

          return { suggestions }
        },
      })
    },
    setupPythonAutocomplete() {
      monaco.languages.registerCompletionItemProvider('python', {
        provideCompletionItems: () => {
          const suggestions = []

          const primitiveTypes = [
            {
              label: 'int',
              kind: monaco.languages.CompletionItemKind.Keyword,
              insertText: 'int = 0',
              documentation: 'Integer type',
            },
            {
              label: 'float',
              kind: monaco.languages.CompletionItemKind.Keyword,
              insertText: 'float = 0.0',
              documentation: 'Float type',
            },
            {
              label: 'str',
              kind: monaco.languages.CompletionItemKind.Keyword,
              insertText: 'str = ""',
              documentation: 'String type',
            },
            {
              label: 'bool',
              kind: monaco.languages.CompletionItemKind.Keyword,
              insertText: 'bool = True',
              documentation: 'Boolean type',
            },
          ]
          suggestions.push(...primitiveTypes)

          const loops = [
            {
              label: 'for',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText: 'for ${1:element} in ${2:iterable}:\n\t${0:pass}',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation:
                'For loop iterates over items in an iterable (list, tuple, string, etc.)',
            },
            {
              label: 'for range',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText:
                'for ${1:i} in range(${2:start}, ${3:stop}):\n\t${0:pass}',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation:
                'For loop using range() to iterate over a sequence of numbers',
            },
            {
              label: 'for enumerate',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText:
                'for ${1:i}, ${2:item} in enumerate(${3:iterable}):\n\t${0:pass}',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation:
                'For loop using enumerate() to get both index and item from an iterable',
            },
            {
              label: 'while',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText: 'while ${1:condition}:\n\t${0:pass}',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation:
                'While loop executes as long as the condition is true',
            },
            {
              label: 'while True',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText: 'while True:\n\t${0:pass}',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Infinite loop using while True',
            },
            {
              label: 'break',
              kind: monaco.languages.CompletionItemKind.Keyword,
              insertText: 'break',
              documentation: 'Break out of the nearest enclosing loop',
            },
            {
              label: 'continue',
              kind: monaco.languages.CompletionItemKind.Keyword,
              insertText: 'continue',
              documentation:
                'Skip the rest of the loop and continue with the next iteration',
            },
            {
              label: 'for-else',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText:
                'for ${1:element} in ${2:iterable}:\n\t${3:pass}\nelse:\n\t${0:pass}',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation:
                'For loop with an else clause that executes if the loop completes without encountering a break statement',
            },
            {
              label: 'while-else',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText:
                'while ${1:condition}:\n\t${2:pass}\nelse:\n\t${0:pass}',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation:
                'While loop with an else clause that executes if the loop completes without encountering a break statement',
            },
          ]
          suggestions.push(...loops)

          const controlStructures = [
            {
              label: 'if',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText: 'if ${1:condition}:\n\t${0:pass}',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'If statement',
            },
            {
              label: 'elif',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText: 'elif ${1:condition}:\n\t${0:pass}',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Else if statement',
            },
            {
              label: 'else',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText: 'else:\n\t${0:pass}',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Else statement',
            },
          ]
          suggestions.push(...controlStructures)

          const list = [
            {
              label: 'list',
              kind: monaco.languages.CompletionItemKind.Keyword,
              insertText: 'list = []',
              documentation: 'List type',
            },
            {
              label: 'append',
              kind: monaco.languages.CompletionItemKind.Method,
              insertText: 'list.append(${1:value})',
              documentation: 'Append item to list',
            },
            {
              label: 'extend',
              kind: monaco.languages.CompletionItemKind.Method,
              insertText: 'list.extend(${1:iterable})',
              documentation: 'Extend list with iterable',
            },
            {
              label: 'pop',
              kind: monaco.languages.CompletionItemKind.Method,
              insertText: 'list.pop(${1:index})',
              documentation: 'Pop item from list',
            },
          ]
          suggestions.push(...list)

          const dict = [
            {
              label: 'dict',
              kind: monaco.languages.CompletionItemKind.Keyword,
              insertText: 'dict = {}',
              documentation: 'Dictionary type',
            },
            {
              label: 'keys',
              kind: monaco.languages.CompletionItemKind.Method,
              insertText: 'dict.keys()',
              documentation: 'Get dictionary keys',
            },
            {
              label: 'values',
              kind: monaco.languages.CompletionItemKind.Method,
              insertText: 'dict.values()',
              documentation: 'Get dictionary values',
            },
            {
              label: 'get',
              kind: monaco.languages.CompletionItemKind.Method,
              insertText: 'dict.get(${1:key}, ${2:default})',
              documentation: 'Get value from dictionary',
            },
          ]
          suggestions.push(...dict)

          const set = [
            {
              label: 'set',
              kind: monaco.languages.CompletionItemKind.Keyword,
              insertText: 'set = set()',
              documentation: 'Set type',
            },
            {
              label: 'add',
              kind: monaco.languages.CompletionItemKind.Method,
              insertText: 'set.add(${1:value})',
              documentation: 'Add item to set',
            },
            {
              label: 'remove',
              kind: monaco.languages.CompletionItemKind.Method,
              insertText: 'set.remove(${1:value})',
              documentation: 'Remove item from set',
            },
          ]
          suggestions.push(...set)

          const basicFunctions = [
            {
              label: 'def',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText:
                'def ${1:function_name}(${2:parameters}):\n\t${0:pass}',
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: 'Function definition',
            },
            {
              label: 'return',
              kind: monaco.languages.CompletionItemKind.Keyword,
              insertText: 'return ${1:value}',
              documentation: 'Return statement',
            },
          ]
          suggestions.push(...basicFunctions)

          const stringMethods = [
            {
              label: 'split',
              kind: monaco.languages.CompletionItemKind.Method,
              insertText: 'str.split(${1:separator})',
              documentation: 'Split string by separator',
            },
            {
              label: 'replace',
              kind: monaco.languages.CompletionItemKind.Method,
              insertText: 'str.replace(${1:old}, ${2:new})',
              documentation: 'Replace string content',
            },
          ]
          suggestions.push(...stringMethods)

          const mathsFunctions = [
            {
              label: 'abs',
              kind: monaco.languages.CompletionItemKind.Function,
              insertText: 'abs(${1:value})',
              documentation: 'Return the absolute value of a number',
            },
            {
              label: 'round',
              kind: monaco.languages.CompletionItemKind.Function,
              insertText: 'round(${1:value}, ${2:ndigits})',
              documentation: 'Round a number to a given precision',
            },
            {
              label: 'min',
              kind: monaco.languages.CompletionItemKind.Function,
              insertText: 'min(${1:iterable})',
              documentation: 'Return the minimum of an iterable',
            },
            {
              label: 'max',
              kind: monaco.languages.CompletionItemKind.Function,
              insertText: 'max(${1:iterable})',
              documentation: 'Return the maximum of an iterable',
            },
          ]
          suggestions.push(...mathsFunctions)

          const inputOutput = [
            {
              label: 'print',
              kind: monaco.languages.CompletionItemKind.Function,
              insertText: 'print(${1:object})',
              documentation: 'Print object to the console',
            },
            {
              label: 'input',
              kind: monaco.languages.CompletionItemKind.Function,
              insertText: 'input(${1:prompt})',
              documentation: 'Get input from user',
            },
          ]
          suggestions.push(...inputOutput)

          const randomLibrary = [
            {
              label: 'import random',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText: 'import random',
              documentation: 'Import random module',
            },
            {
              label: 'random.randint',
              kind: monaco.languages.CompletionItemKind.Method,
              insertText: 'random.randint(${1:a}, ${2:b})',
              documentation: 'Return a random integer N such that a <= N <= b',
            },
            {
              label: 'random.choice',
              kind: monaco.languages.CompletionItemKind.Method,
              insertText: 'random.choice(${1:sequence})',
              documentation: 'Return a random element from a sequence',
            },
          ]
          suggestions.push(...randomLibrary)

          const mathLibrary = [
            {
              label: 'import math',
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText: 'import math',
              documentation: 'Import math module',
            },
            {
              label: 'math.sqrt',
              kind: monaco.languages.CompletionItemKind.Method,
              insertText: 'math.sqrt(${1:value})',
              documentation: 'Return the square root of a number',
            },
            {
              label: 'math.pi',
              kind: monaco.languages.CompletionItemKind.Property,
              insertText: 'math.pi',
              documentation: 'The mathematical constant π',
            },
          ]
          suggestions.push(...mathLibrary)

          return { suggestions }
        },
      })
    },
  },
}
</script>
