<template>
  <v-row>
    <v-col :cols="12">
      <slot name="default"></slot>
    </v-col>
  </v-row>
</template>

<script>
export default {}
</script>
<style scoped></style>
