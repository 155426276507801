<template>
  <page
    minimal
    title="Problems"
    :apiStatusList="[fetchUserContentPreviewsStatus]"
  >
    <template v-slot:subtitle>
      <!-- <v-alert color="accent" colored-border border="left" class="d-flex align-center justify-stretch">
        <span class="d-fles align-center">
          Check out our <router-link to="/contests/"> contests </router-link> and participate in <router-link to="/contests/codedrills-beta-contest-0">Beta contest #0</router-link>
        </span>
      </v-alert> -->
    </template>
    <problemList
      :problems="userContentPreviews"
      :autopick="!!this.$route.query.autopick"
    ></problemList>
  </page>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import Page from '@/components/Page.vue'
import ProblemList from '../components/ProblemList.vue'
import TextBlock from '@/components/TextBlock.vue'

export default {
  name: 'home',

  methods: {
    ...mapGetters('content', ['getContentType']),
    ...mapActions('content', ['fetchUserContentPreviews']),
  },
  computed: {
    ...mapState('content', [
      'userContentPreviews',
      'fetchUserContentPreviewsStatus',
    ]),
  },
  async mounted() {
    this.fetchUserContentPreviews({
      tags: ['types/competitive'],
      contentTypes: [this.getContentType()['PROBLEM']],
    })
  },
  components: {
    Page,
    ProblemList,
    TextBlock,
  },
}
</script>
<style scoped></style>
