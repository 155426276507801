<template>
  <svg
    width="155px"
    height="155px"
    viewBox="0 0 155 155"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>Group-27</title>
    <defs>
      <polygon
        id="path-1"
        points="0.789058303 0.0766071558 73.1625743 0.0766071558 73.1625743 72.4489796 0.789058303 72.4489796"
      ></polygon>
      <polygon
        id="path-3"
        points="0.180270194 0.538037388 154.368752 0.269530791 154.368752 70.9307166 0.180270194 70.9307166"
      ></polygon>
      <polygon
        id="path-5"
        points="0 113.304082 99.222449 113.304082 99.222449 0.02040816 0 0.02040816"
      ></polygon>
      <polygon
        id="path-7"
        points="0 113.304082 99.222449 113.304082 99.222449 0.02040816 0 0.02040816"
      ></polygon>
      <polygon
        id="path-9"
        points="0 113.304082 99.222449 113.304082 99.222449 0.02040816 0 0.02040816"
      ></polygon>
      <polygon
        id="path-11"
        points="0 113.304082 99.222449 113.304082 99.222449 0.02040816 0 0.02040816"
      ></polygon>
      <polygon
        id="path-13"
        points="0 113.304082 99.222449 113.304082 99.222449 0.02040816 0 0.02040816"
      ></polygon>
      <polygon
        id="path-15"
        points="0 154.304082 99.222449 154.304082 99.222449 0.304082 0 0.304082"
      ></polygon>
    </defs>
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="contestMascot" transform="translate(-687.000000, -1154.000000)">
        <g id="Group-27" transform="translate(687.000000, 1154.000000)">
          <path
            d="M38.1018367,99.0267347 C31.3691837,89.967551 28.504898,71.6522449 28.504898,71.6522449"
            id="Stroke-1"
            stroke="#000000"
            stroke-width="2"
          ></path>
          <path
            d="M96.3119388,99.0267347 C103.044592,89.967551 105.908878,71.6522449 105.908878,71.6522449"
            id="Stroke-3"
            stroke="#000000"
            stroke-width="2"
          ></path>
          <g id="Fill-5-Clipped" transform="translate(32.265306, 81.855102)">
            <mask id="mask-2" fill="white">
              <use xlink:href="#path-1"></use>
            </mask>
            <g id="path-1"></g>
            <path
              d="M73.1625976,36.3052041 C73.1390816,56.289898 56.9186735,72.472551 36.9329592,72.4491325 C16.9482653,72.4245918 0.766632653,56.2052041 0.789035022,36.2205102 C0.813571429,16.2347959 17.0329592,0.0531632653 37.0186735,0.0765817139 C57.0033673,0.100102041 73.185,16.3194898 73.1625976,36.3052041"
              id="Fill-5"
              fill="#EEEEEE"
              fill-rule="nonzero"
              mask="url(#mask-2)"
            ></path>
          </g>
          <path
            d="M53.5733673,107.160083 C47.2447959,107.151939 42.1080612,112.277449 42.1009035,118.60602 C42.0937755,124.934592 47.2172449,130.070306 53.5468367,130.077468 C59.8754082,130.085612 65.0111224,124.961122 65.0193006,118.632551 C65.0264286,112.30398 59.9019388,107.167245 53.5733673,107.160083"
            id="Fill-8"
            fill="#FFFFFF"
            fill-rule="nonzero"
          ></path>
          <path
            d="M53.5733673,107.160083 C47.2447959,107.151939 42.1080612,112.277449 42.1009035,118.60602 C42.0937755,124.934592 47.2172449,130.070306 53.5468367,130.077468 C59.8754082,130.085612 65.0111224,124.961122 65.0193006,118.632551 C65.0264286,112.30398 59.9019388,107.167245 53.5733673,107.160083 Z"
            id="Stroke-10"
            stroke="#000000"
            stroke-width="2"
          ></path>
          <path
            d="M82.522449,107.193965 C76.1938776,107.186837 71.0571429,112.311326 71.0499806,118.639898 C71.0418367,124.968469 76.1663265,130.104184 82.4959184,130.112362 C88.8244898,130.11949 93.9602041,124.995 93.9683822,118.666429 C93.9755102,112.337857 88.8510204,107.201122 82.522449,107.193965"
            id="Fill-12"
            fill="#FFFFFF"
            fill-rule="nonzero"
          ></path>
          <path
            d="M82.522449,107.193965 C76.1938776,107.186837 71.0571429,112.311326 71.0499806,118.639898 C71.0418367,124.968469 76.1663265,130.104184 82.4959184,130.112362 C88.8244898,130.11949 93.9602041,124.995 93.9683822,118.666429 C93.9755102,112.337857 88.8510204,107.201122 82.522449,107.193965 Z"
            id="Stroke-14"
            stroke="#000000"
            stroke-width="2"
          ></path>
          <polygon
            id="Fill-16"
            fill="#000000"
            fill-rule="nonzero"
            points="71.0488776 119.620204 64.5764286 119.612041 64.5784694 117.650816 71.0509184 117.65898"
          ></polygon>
          <g id="Group-20" transform="translate(0.000000, 2.018367)">
            <g id="Fill-18-Clipped" transform="translate(0.000000, 0.981633)">
              <mask id="mask-4" fill="white">
                <use xlink:href="#path-3"></use>
              </mask>
              <g id="path-3"></g>
              <path
                d="M-26.7155543,71.0803957 C-28.5524345,71.0899741 -82.5250548,72.8682061 -82.6073298,70.3081927 L-33.5247101,-37.5295706 C-33.6141395,-40.2690093 -32.0205073,-42.5022125 -30.0602145,-42.3932758 L137.086942,-32.9478194 C138.956017,-32.8420867 140.435179,-30.6409237 140.389743,-28.0328499 L138.736021,65.7712976 C138.693094,68.2992711 137.230029,70.2985807 135.416401,70.3081927 L-26.7155543,71.0803957 Z"
                id="Fill-18"
                fill="#33A79D"
                fill-rule="nonzero"
                mask="url(#mask-4)"
              ></path>
            </g>
          </g>
          <g id="L-I-V-E-Clipped" transform="translate(19.000000, 41.000000)">
            <mask id="mask-6" fill="white">
              <use xlink:href="#path-5"></use>
            </mask>
            <g id="path-5"></g>
          </g>
          <g id="Fill-22-Clipped" transform="translate(19.000000, 41.000000)">
            <mask id="mask-8" fill="white">
              <use xlink:href="#path-7"></use>
            </mask>
            <g id="path-5"></g>
            <path
              d="M16.0117347,87.9348938 C9.61581633,87.9348938 4.43112245,82.7639646 4.43112245,76.3850265 C4.43112245,70.0071062 9.61581633,64.836177 16.0117347,64.836177 L16.0117347,87.9348938 Z"
              id="Fill-22"
              fill="#000000"
              fill-rule="nonzero"
              mask="url(#mask-8)"
            ></path>
          </g>
          <g id="Fill-23-Clipped" transform="translate(19.000000, 41.000000)">
            <mask id="mask-10" fill="white">
              <use xlink:href="#path-9"></use>
            </mask>
            <g id="path-5"></g>
            <path
              d="M82.8365306,87.9348938 C89.232449,87.9348938 94.4171429,82.7639646 94.4171429,76.3850265 C94.4171429,70.0071062 89.232449,64.836177 82.8365306,64.836177 L82.8365306,87.9348938 Z"
              id="Fill-23"
              fill="#000000"
              fill-rule="nonzero"
              mask="url(#mask-10)"
            ></path>
          </g>
          <g id="Stroke-24-Clipped" transform="translate(19.000000, 41.000000)">
            <mask id="mask-12" fill="white">
              <use xlink:href="#path-11"></use>
            </mask>
            <g id="path-5"></g>
            <path
              d="M64.4033673,103.711164 C86.2360204,103.711164 83.7727551,83.7907212 83.7727551,83.7907212"
              id="Stroke-24"
              stroke="#000000"
              stroke-width="2"
              mask="url(#mask-12)"
            ></path>
          </g>
          <g id="Fill-25-Clipped" transform="translate(19.000000, 41.000000)">
            <mask id="mask-14" fill="white">
              <use xlink:href="#path-13"></use>
            </mask>
            <g id="path-5"></g>
            <path
              d="M68.5736735,103.711164 C68.5736735,105.540987 67.0859184,107.024792 65.2512245,107.024792 C63.4155102,107.024792 61.9277551,105.540987 61.9277551,103.711164 C61.9277551,101.880323 63.4155102,100.396518 65.2512245,100.396518 C67.0859184,100.396518 68.5736735,101.880323 68.5736735,103.711164"
              id="Fill-25"
              fill="#000000"
              fill-rule="nonzero"
              mask="url(#mask-14)"
            ></path>
          </g>
          <g id="Stroke-26-Clipped" transform="translate(19.000000, 0.000000)">
            <mask id="mask-16" fill="white">
              <use xlink:href="#path-15"></use>
            </mask>
            <g id="path-5"></g>
            <path
              d="M14.9913265,106.853571 C14.9913265,106.853571 23.9607143,81.820208 50.2841837,81.820208 C77.2627551,81.820208 83.8566327,106.853571 83.8566327,106.853571"
              id="Stroke-26"
              stroke="#000000"
              stroke-width="2"
              mask="url(#mask-16)"
            ></path>
          </g>
          <text
            id="Title"
            fill="#FFFFFF"
            fill-rule="nonzero"
            font-family="Helvetica"
            font-size="28"
            font-weight="normal"
          >
            <tspan x="16.7440816" y="33.4003507">{{ preposition }}</tspan>
          </text>
          <text
            id="time"
            fill="#FFFFFF"
            fill-rule="nonzero"
            font-family="Helvetica"
            font-size="28"
            font-weight="normal"
          >
            <tspan x="14.7440816" y="68.4003507">{{ time }}</tspan>
          </text>
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    preposition: {
      type: String,
      required: true,
    },
    time: {
      type: String,
      required: true,
    },
  },
}
</script>
