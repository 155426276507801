<template>
  <span>
    <v-tabs
      :vertical="$vuetify.breakpoint.smAndDown"
      color="accent"
      v-model="tab"
    >
      <v-tab href="#overview">Overview</v-tab>
      <v-tab href="#myteam" v-if="teamContest">My Registration</v-tab>
      <v-tab href="#allteams" v-if="teamContest">All Registrants</v-tab>
    </v-tabs>

    <v-tabs-items :value="tab">
      <v-tab-item value="overview">
        <v-row v-if="acceptedTeam">
          <v-col>
            <v-alert outlined border="top" color="green" type="info">
              You are registered for this contest, check details
              <v-btn text color="blue" @click="goToTeam">My Registration</v-btn>
            </v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <problem-list
              live
              :urlPrefix="'/contests/' + this.contentView.getUrl()"
              :problems="problems"
              :showOption="false"
              showIds
              :showSolved="!isPrivateScoreboard"
            ></problem-list>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="display-1">About</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="11">
            <v-card flat outlined class="pl-5 py-3">
              <div class="d-flex flex-no-wrap">
                <div class="d-flex align-center">
                  <html-view :html="view.getAboutHtml()"></html-view>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mt-8">
          <v-col>
            <div class="display-1">Rules</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="11">
            <v-card flat outlined class="pl-5 py-3">
              <div class="d-flex flex-no-wrap">
                <div class="d-flex align-center">
                  <html-view :html="view.getRulesHtml()"></html-view>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item value="myteam">
        <v-row v-if="!user">
          <v-col>
            Please <router-link to="/community/login"> login </router-link> to
            create team
          </v-col>
        </v-row>
        <v-row v-else-if="userContestTeams && userContestTeams.length == 0">
          <v-col> You are not member of any team </v-col>
        </v-row>
        <v-row v-else-if="!acceptedTeam">
          <v-col> No Accepted Invitation </v-col>
        </v-row>
        <v-row v-if="acceptedTeam">
          <v-col>
            <team-detail :teamView="acceptedTeam"></team-detail>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item value="allteams">
        <v-row>
          <v-col>
            <teams-list
              :contestId="contentView.getId()"
              :contestUrl="contentView.getUrl()"
              :contestMetadata="contentView.getMetaData().getContestMeta()"
            ></teams-list>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </span>
</template>

<script>
import HtmlView from '../components/HtmlView'
import ProblemList from '../components/ProblemList'
import TeamsList from '../components/TeamsList'
import TeamDetail from '../components/TeamDetail'
import { mapActions, mapState } from 'vuex'

import 'codedrills_proto/io/codedrills/proto/site/content_view_service_grpc_web_pb'

const content_proto = proto.io.codedrills.proto.content

export default {
  data: function () {
    return {}
  },
  props: {
    contentView: {
      type: Object,
      required: true,
    },
  },

  methods: {
    ...mapActions('content', ['fetchContentView']),
    goToTeam() {
      this.$router.push({ path: this.$route.path, query: { tab: 'myteam' } })
    },
  },
  computed: {
    ...mapState('timesync', ['serverTime']),
    ...mapState('user', ['team', 'userContestTeams', 'user']),
    view() {
      return this.contentView.getDataView().getContestView()
    },
    problems() {
      return this.contentView.getChildPreviewsList()
    },
    teamContest() {
      return (
        this.contentView.getMetaData().getContestMeta().getContestType() == 1
      )
    },
    isPrivateScoreboard() {
      return (
        this.contentView
          .getMetaData()
          .getContestMeta()
          .getScoreboardVisibility() ==
        content_proto.ScoreboardVisibility.SCOREBOARD_VISIBILITY_PRIVATE
      )
    },
    acceptedTeam() {
      return (
        this.userContestTeams &&
        this.userContestTeams.find((t) => {
          var isAccepted =
            t
              .getMembersList()
              .find((u) => u.getEmail() === this.user.email)
              .getMembershipState() == 2
          console.log(t.toObject(), isAccepted)
          return isAccepted
        })
      )
    },
    tab: {
      set(tab) {
        console.log('TAB is ..', tab)
        this.$router.replace({ query: { ...this.$route.query, tab } })
      },
      get() {
        console.log('TAB returning ...', this.$route.query.tab)
        return this.$route.query.tab
      },
    },
  },

  components: {
    HtmlView,
    ProblemList,
    TeamsList,
    TeamDetail,
  },
}
</script>
TeamsView
<style scoped>
.v-tabs--vertical > .v-tabs-bar .v-tab {
  min-width: 100%;
}
</style>
