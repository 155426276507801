<template>
  <div>
    <div class="py-2">
      <slot name="top" />
    </div>
    <v-row v-if="loadingError" align="center" justify="center">
      <div class="text-h5 text-center text-bold danger--text py-16">
        <Navbar />
        {{ errMsg }}
      </div>
    </v-row>
    <v-row v-else-if="loading"> Page Loading ... </v-row>
    <slot v-else name="content" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Loader from '@/components/Loader.vue'
import Error from '@/components/Error.vue'
import { ApiCallStatus } from '@/utils/api.js'
import Navbar from '../Navbar.vue'

export default {
  name: 'page',
  props: {
    title: {
      type: String,
      required: false,
    },
    apiStatusList: {
      type: Array,
      required: false,
    },
    appendImageUrl: {
      type: String,
      required: false,
    },
    breadcrumbs: {
      type: Array,
      required: false,
      default: null,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    errMsg: {
      type: String,
    },
  },
  computed: {
    ...mapState('user', ['user']),
    loadingCompleted() {
      var completed = this.apiStatusList.reduce(
        (t, s) => t && (s == ApiCallStatus.SUCCESS || s == ApiCallStatus.ERROR),
        true,
      )
      return completed && this.loading === false
    },
    loadingError() {
      return this.apiStatusList.reduce(
        (t, s) => t || s == ApiCallStatus.ERROR,
        false,
      )
    },
  },
  components: {
    Loader,
    Error,
    Navbar,
  },
}
</script>
<style scoped>
.dashboard_link {
  color: #000000;
  text-decoration: none;
}
</style>
