<template>
  <page title="Settings" :apiStatusList="[getMailSubscriptionsStatus]">
    <v-tabs
      :vertical="$vuetify.breakpoint.smAndDown"
      color="accent"
      align-with-title
      v-model="tab"
    >
      <v-tab> Email subscription </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-row class="mt-8">
          <v-col>
            <v-checkbox
              v-model="all"
              label="General CodeDrills Announcements"
              @change="
                (state) =>
                  setSubscriptionlocal(
                    subscriptionKeys.SUBSCRIBE_GROUP_GENERAL,
                    state,
                  )
              "
            ></v-checkbox>
            <v-checkbox
              v-model="interview"
              label="Interview Announcements"
              @change="
                (state) =>
                  setSubscriptionlocal(
                    subscriptionKeys.SUBSCRIBE_GROUP_INTERVIEW,
                    state,
                  )
              "
            ></v-checkbox>
            <v-checkbox
              v-model="contest"
              label="Contest Announcements"
              @change="
                (state) =>
                  setSubscriptionlocal(
                    subscriptionKeys.SUBSCRIBE_GROUP_CONTEST,
                    state,
                  )
              "
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn :loading="loading" color="accent" @click="save">Save</v-btn>
            <error-message :ex="ex"></error-message>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </page>
</template>
<script>
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex'
import Page from '../components/Page'
import ErrorMessage from '../components/ErrorMessage'

export default {
  data() {
    return {
      tab: null,
      all: false,
      interview: false,
      contest: false,
      ex: null,
      loading: false,
    }
  },
  computed: {
    ...mapState('user', [
      'userMailSubscriptions',
      'getMailSubscriptionsStatus',
    ]),
    ...mapGetters('user', ['subscriptionKeys']),
  },
  methods: {
    ...mapActions('user', ['getMailSubscriptions', 'updateMailSubscriptions']),
    ...mapMutations('user', ['setSubscription', 'getSubscription']),
    save() {
      this.loading = true
      this.updateMailSubscriptions()
        .then(() => {
          this.setUpdatedStates()
          this.loading = false
        })
        .catch((ex) => {
          this.ex = ex
          this.loading = false
        })
    },
    setSubscriptionlocal(sub, st) {
      this.setSubscription({ subscription: sub, isSet: st })
    },
    setUpdatedStates() {
      this.all = this.userMailSubscriptions
        .getSubscriptionList()
        .includes(this.subscriptionKeys.SUBSCRIBE_GROUP_GENERAL)
      this.interview = this.userMailSubscriptions
        .getSubscriptionList()
        .includes(this.subscriptionKeys.SUBSCRIBE_GROUP_INTERVIEW)
      this.contest = this.userMailSubscriptions
        .getSubscriptionList()
        .includes(this.subscriptionKeys.SUBSCRIBE_GROUP_CONTEST)
    },
  },
  mounted() {
    this.getMailSubscriptions()
      .then(() => {
        this.setUpdatedStates()
      })
      .catch((ex) => (this.ex = ex))
  },
  components: {
    Page,
    ErrorMessage,
  },
}
</script>
