<template>
  <page
    :title="title"
    :apiStatusList="[getDashboardStatus]"
    loginRequired
    minimal
  >
    <v-row class="ml-4">
      <v-col :cols="12">
        <LiveContestList></LiveContestList>

        <v-row class="mt-12">
          <v-col class="font-weight-bold text-truncate text-h5"> Status </v-col>
        </v-row>
        <over-all-status :dashboardView="dashboardView"></over-all-status>

        <v-row class="mt-10">
          <v-col class="font-weight-bold text-truncate text-h5">
            Problems
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            Solve problems that have been recommended just for you. You can also
            check out
            <a class="dashboard_link" href="/problems"> all problems </a>.
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="10">
            <!-- WHY is this not working? -->
            <problem-list
              v-if="dashboardView"
              :problems="dashboardView.getRecommendedProblemPreviewsList()"
              :autopick="false"
              :showOption="false"
              :hideFooter="true"
            ></problem-list>
          </v-col>
        </v-row>

        <v-row class="mt-12">
          <v-col>
            <span class="font-weight-bold text-truncate text-h5">
              Drills Status
            </span>
          </v-col>
        </v-row>
        <drill-status :dashboardView="dashboardView"></drill-status>

        <v-row class="mt-12">
          <v-col class="font-weight-bold text-truncate text-h5">
            Skill Insights
          </v-col>
        </v-row>
        <skill-analysis :dashboardView="dashboardView"></skill-analysis>

        <!---	<v-row class="mt-12">
				<v-col class="font-weight-bold text-truncate text-h5">
						Code Analysis
				</v-col>
			</v-row>
			<code-analysis :dashboardView="dashboardView"></code-analysis> -->
      </v-col>
    </v-row>
  </page>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import Page from '../components/Page'
import ProblemList from '../components/ProblemList'
import StatusChart from '../components/StatusChart'
import DrillStatus from '../components/DrillStatus'
import CodeAnalysis from '../components/CodeAnalysis'
import SkillAnalysis from '../components/SkillAnalysis'
import OverAllStatus from '../components/OverAllStatus'
import LiveContestList from '../components/LiveContestList.vue'

export default {
  data() {
    return {
      companies: ['Facebook', 'Google', 'Amazon', 'Apple', 'Microsoft', 'Uber'],
    }
  },
  methods: {
    ...mapActions('user', ['getDashboard']),
  },
  computed: {
    ...mapState('user', ['dashboardView', 'getDashboardStatus', 'user']),
    title() {
      if (this.user && this.user.displayName) {
        return 'Hello, ' + this.user.displayName
      } else {
        return 'Hello, User'
      }
    },
  },
  created() {
    this.getDashboard().then((__) =>
      console.log('Dashboard ....', this.dashboardView.toObject()),
    )
  },
  components: {
    Page,
    ProblemList,
    StatusChart,
    OverAllStatus,
    DrillStatus,
    SkillAnalysis,
    CodeAnalysis,
    LiveContestList,
  },
}
</script>
<style scoped>
.dashboard_link {
  color: #33a79d;
  text-decoration: none;
}
</style>
