var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', [_c('nav', {
    staticClass: "px-12 py-8"
  }, [_c('v-row', {
    staticClass: "d-flex flex-md-col",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('router-link', {
    staticClass: "logo",
    attrs: {
      "to": "/"
    }
  }, [_c('v-img', {
    attrs: {
      "src": _vm.logo,
      "height": "64",
      "width": "180",
      "alt": "CodeDrills Logo",
      "contain": ""
    }
  })], 1), _vm.$vuetify.breakpoint.smAndUp ? _c('v-spacer') : _vm._e(), _vm.whitelabelConfig.enableCommunity ? _c('v-btn', {
    staticClass: "text-none mr-3",
    attrs: {
      "text": "",
      "color": "success",
      "to": "/community/dashboard",
      "outlined": !_vm.$vuetify.breakpoint.mdAndUp
    }
  }, [_vm._v(" Community ")]) : _vm._e(), !_vm.whitelabelConfig.enableCommunity && _vm.whitelabelConfig.enableContests ? _c('v-btn', {
    staticClass: "text-none mr-3",
    attrs: {
      "text": "",
      "color": "success",
      "to": "/contests",
      "outlined": !_vm.$vuetify.breakpoint.mdAndUp
    }
  }, [_vm._v(" Contests ")]) : _vm._e(), _vm.whitelabelDefault && _vm.$vuetify.breakpoint.mdAndUp ? _c('v-btn', {
    staticClass: "text-none mr-3",
    attrs: {
      "text": "",
      "href": "https://blog.codedrills.io"
    }
  }, [_vm._v(" Blog ")]) : _vm._e(), _vm.whitelabelDefault && _vm.$vuetify.breakpoint.mdAndUp ? _c('v-btn', {
    staticClass: "text-none mr-3",
    attrs: {
      "text": "",
      "href": "mailto:hello@codedrills.io"
    }
  }, [_vm._v(" Contact Us ")]) : _vm._e(), _vm.$vuetify.breakpoint.mdAndUp ? _c('v-btn', {
    staticClass: "text-none mr-3",
    attrs: {
      "text": "",
      "to": "/login"
    }
  }, [_vm._v(" Login ")]) : _vm._e()], 1)], 1), _c('div', {
    staticClass: "hero mb-8"
  }, [_c('v-img', {
    attrs: {
      "src": require("@/assets/images/recruiter/home_banner.png"),
      "max-height": "600px"
    }
  }), _vm.$vuetify.breakpoint.lgAndUp ? _c('span', {
    staticClass: "py-5 hero-btns"
  }, [_c('v-btn', {
    staticClass: "text-none text-body-1 mx-5 font-weight-bold underline",
    attrs: {
      "to": "/login",
      "text": "",
      "color": "primary",
      "outlined": "",
      "x-large": ""
    }
  }, [_vm._v(" Login ")]), _c('v-btn', {
    staticClass: "text-none text-body-1 mx-5 font-weight-medium",
    attrs: {
      "to": "/signup",
      "color": "primary",
      "elevation": "0",
      "x-large": ""
    }
  }, [_vm._v(" Sign Up ")])], 1) : _vm._e(), _vm.$vuetify.breakpoint.mdAndDown ? _c('div', {
    staticClass: "py-5 d-flex justify-space-around"
  }, [_c('v-btn', {
    staticClass: "text-none text-body-2 mx-5 font-weight-bold underline",
    attrs: {
      "to": "/login",
      "text": "",
      "color": "primary"
    }
  }, [_vm._v(" Login ")]), _c('v-btn', {
    staticClass: "text-none text-body-2 mx-5 font-weight-medium",
    attrs: {
      "to": "/signup",
      "color": "primary",
      "elevation": "0"
    }
  }, [_vm._v(" Sign Up ")])], 1) : _vm._e()], 1), _c('div', {
    staticClass: "reason-container mx-auto"
  }, [_c('v-card', {
    staticClass: "reason-section mx-auto text-center",
    attrs: {
      "elevation": "0",
      "rounded": "0",
      "dark": "",
      "color": "black"
    }
  }, [_vm.whitelabelDefault ? _c('v-card-title', {
    staticClass: "justify-center font-weight-bold text-h5 pt-8"
  }, [_vm._v(" Why CodeDrills? ")]) : _vm._e(), _c('v-card-text', {
    staticClass: "pa-0 ma-0"
  }, [_c('v-row', {
    staticClass: "px-4 my-16"
  }, _vm._l(_vm.reasons, function (r) {
    return _c('v-col', {
      key: r.title,
      staticClass: "px-4",
      attrs: {
        "cols": "12",
        "sm": "6",
        "lg": "3"
      }
    }, [_c('ReasonCard', {
      attrs: {
        "reason": r
      }
    })], 1);
  }), 1)], 1)], 1)], 1), _vm.whitelabelDefault ? _c('div', {
    staticClass: "reviews mx-auto"
  }, [_c('div', {
    staticClass: "text-h5 font-weight-bold text-center mb-8"
  }, [_vm._v(" What our customers say about us ")]), _c('Reviews')], 1) : _vm._e(), _vm.whitelabelDefault ? _c('div', {
    staticClass: "pricing mx-auto"
  }, [_c('Pricing')], 1) : _vm._e(), _vm.whitelabelDefault ? _c('div', {
    staticClass: "text-h5 font-weight-bold text-center mt-5"
  }, [_vm._v(" Book a Demo with us ")]) : _vm._e(), _vm.whitelabelDefault ? _c('div', [_c('demo-page')], 1) : _vm._e(), _vm.whitelabelDefault ? _c('div', {
    staticClass: "faqs mx-auto"
  }, [_c('Faqs')], 1) : _vm._e(), _vm.whitelabelDefault ? _c('footer', {
    staticClass: "my-5"
  }, [_c('HomeFooter')], 1) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }