var render = function render(){
  var _vm$currentQuestion, _vm$currentQuestion$g, _vm$currentQuestion2, _vm$currentQuestion2$, _vm$currentQuestion3, _vm$currentQuestion$g2, _vm$currentQuestion$g3, _vm$currentQuestion4, _vm$currentQuestion4$;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tabs-items', {
    model: {
      value: _vm.currProblemTab,
      callback: function ($$v) {
        _vm.currProblemTab = $$v;
      },
      expression: "currProblemTab"
    }
  }, [_c('v-tab-item', [_vm.questionType === 'PROBLEM' ? _c('div', [_c('v-card', {
    attrs: {
      "elevation": "0",
      "height": "650px"
    }
  }, [_c('v-card-text', {
    staticStyle: {
      "overflow": "auto",
      "height": "100%"
    }
  }, [_c('html-view', {
    staticClass: "pt-5",
    attrs: {
      "html": _vm.questionStatement
    }
  }), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": 6
    }
  }, [_c('stats-col', {
    attrs: {
      "title": "Time Limit"
    }
  }, [_vm._v(" " + _vm._s((_vm$currentQuestion = _vm.currentQuestion) === null || _vm$currentQuestion === void 0 ? void 0 : (_vm$currentQuestion$g = _vm$currentQuestion.getMetaData()) === null || _vm$currentQuestion$g === void 0 ? void 0 : _vm$currentQuestion$g.getProblemMeta().getConstraints().getTimeLimitInMs()) + " ms ")])], 1), _c('v-col', {
    attrs: {
      "cols": 6
    }
  }, [_c('stats-col', {
    attrs: {
      "title": "Memory Limit"
    }
  }, [_vm._v(" " + _vm._s((_vm$currentQuestion2 = _vm.currentQuestion) === null || _vm$currentQuestion2 === void 0 ? void 0 : (_vm$currentQuestion2$ = _vm$currentQuestion2.getMetaData()) === null || _vm$currentQuestion2$ === void 0 ? void 0 : _vm$currentQuestion2$.getProblemMeta().getConstraints().getMemoryLimitInKb()) + " KiB ")])], 1)], 1)], 1)], 1)], 1) : _c('v-card', {
    attrs: {
      "elevation": "0",
      "height": "650px"
    }
  }, [_c('v-card-text', {
    staticStyle: {
      "overflow": "auto",
      "height": "100%"
    }
  }, [_c('html-view', {
    staticClass: "pt-5",
    attrs: {
      "html": _vm.questionStatement
    }
  })], 1)], 1)], 1), _c('v-tab-item', [_c('TestProblemSubmission', {
    attrs: {
      "id": (_vm$currentQuestion3 = _vm.currentQuestion) === null || _vm$currentQuestion3 === void 0 ? void 0 : _vm$currentQuestion3.getId(),
      "userId": _vm.userId
    }
  })], 1), ((_vm$currentQuestion$g2 = _vm.currentQuestion.getMetaData()) === null || _vm$currentQuestion$g2 === void 0 ? void 0 : (_vm$currentQuestion$g3 = _vm$currentQuestion$g2.getProblemMeta()) === null || _vm$currentQuestion$g3 === void 0 ? void 0 : _vm$currentQuestion$g3.getEvaluationType()) === 1 ? _c('v-tab-item', [_c('code-view', {
    attrs: {
      "code": (_vm$currentQuestion4 = _vm.currentQuestion) === null || _vm$currentQuestion4 === void 0 ? void 0 : (_vm$currentQuestion4$ = _vm$currentQuestion4.getDataView()) === null || _vm$currentQuestion4$ === void 0 ? void 0 : _vm$currentQuestion4$.getProblemView().getSqlInitView(),
      "lang": "MY_SQL_8",
      "availableLanguages": _vm.availableLanguages
    }
  })], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }