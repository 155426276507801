import { base64DecToArr, base64EncArr } from './base64'

export const ApiCallStatus = {
  UNCALLED: 0,
  CALLING: 1,
  SUCCESS: 2,
  ERROR: 3,
}
export class ApiCall {
  constructor(n) {
    this.name = n
    this.statusName = n + 'Status'
    this.requireAuth = false
    this.isStream = false
    this.cache = false
    this.shouldInvalidateCache = false
    return this
  }
  stream() {
    this.isStream = true
    return this
  }
  unary() {
    this.isStream = false
    return this
  }
  withRequest(f) {
    this.reqBuilder = f
    return this
  }
  withServiceCall(f) {
    this.serviceCall = f
    return this
  }
  onSuccess(f) {
    this.resProcesser = f
    return this
  }
  onUpdate(f) {
    this.updateProcesser = f
    return this
  }
  onError(f) {
    this.errProcesser = f
    return this
  }
  authRequired() {
    this.requireAuth = true
    return this
  }
  authOptional() {
    this.requireAuth = false
    return this
  }
  withTimeout(timeoutInSeconds) {
    this.timeoutInSeconds = timeoutInSeconds
    return this
  }
  cached({ cacheName, cachedMessage, keyFn, ttlInSeconds }) {
    this.cache = true
    this.cacheName = cacheName
    this.cacheTtlInSeconds = ttlInSeconds
    this.cachedMessage = cachedMessage
    this.keyFn = keyFn
    return this
  }
  invalidatingCache({ cacheName, keyFn }) {
    this.shouldInvalidateCache = true
    this.invalidateCacheName = cacheName
    this.invalidateKeyFn = keyFn
    return this
  }
  protobufToString(msg) {
    return base64EncArr(msg.serializeBinary())
  }
  stringToProtobuf(protobuf, data) {
    return protobuf.deserializeBinary(base64DecToArr(data))
  }

  build() {
    let apiCall = this
    return async function (ctx, data) {
      try {
        const token = await apiCall.handleToken(ctx)
        const cacheResult = await apiCall.checkAndReturnCache(ctx, data, token)
        if (cacheResult) return cacheResult

        const req = apiCall.buildRequest(ctx, data, token)
        const headers = apiCall.buildHeaders(token)

        return apiCall.isStream
          ? apiCall.handleStream(ctx, data, req, headers)
          : apiCall.handleApiCall(ctx, data, req, headers)
      } catch (err) {
        console.error('Error in API call', apiCall.name, err)
        throw err // Rethrow after logging
      }
    }
  }

  async handleToken(ctx) {
    const tokenCall = this.requireAuth
      ? 'user/optionalUserToken'
      : 'user/ensureUserToken'
    const ut = await ctx.dispatch(tokenCall, null, { root: true })
    if (this.requireAuth && !ut) throw 'Auth required for ' + this.name
    return ut
  }

  async checkAndReturnCache(ctx, data, token) {
    if (!this.cache) return null
    const cacheKey = this.cacheName + ':' + this.keyFn(data, ctx)
    console.log('Checking cache with key: ', cacheKey)
    let cachedResponse = localStorage.getItem(cacheKey)
    if (cachedResponse) {
      cachedResponse = JSON.parse(cachedResponse)
      console.log('Found cached object', cachedResponse)
      if (
        cachedResponse.savedAt &&
        cachedResponse.savedAt >= Date.now() - this.cacheTtlInSeconds * 1000
      ) {
        const res = this.stringToProtobuf(
          this.cachedMessage,
          cachedResponse.value,
        )
        ctx.commit(this.statusName, ApiCallStatus.SUCCESS)
        console.log('Success [Cache]', this.name, res.toObject())
        if (this.resProcesser) this.resProcesser(ctx, res, data)
        return Promise.resolve(res)
      }
    }
    return null
  }

  buildRequest(ctx, data, token) {
    const req = this.reqBuilder(data, token, ctx)
    if (req === null) return null
    console.log('Calling', this.name, req.toObject())
    return req
  }

  buildHeaders(token) {
    const headers = { domain: window.location.hostname }
    console.log('Domain', headers['domain'])
    if (token && token !== '') headers['Authorization'] = token
    if (this.timeoutInSeconds) {
      const deadline = new Date()
      deadline.setSeconds(deadline.getSeconds() + this.timeoutInSeconds)
      headers['deadline'] = deadline.getTime()
    }
    return headers
  }

  handleMessage(message, type = 'info') {
    // Emit an event with the message and type
    // For simplicity, using document as the event emitter
    const event = new CustomEvent('apiMessage', {
      detail: { message, type },
    })
    console.log('Emitting event ..', event)
    document.dispatchEvent(event)
  }

  async handleApiCall(ctx, data, req, headers) {
    ctx.commit(this.statusName, ApiCallStatus.CALLING)
    let attempts = 0
    const maxAttempts = 5 // Maximum number of retries
    const initialDelayMs = 2000 // Initial delay of 2 seconds
    const maxDelayMs = 60000 // Maximum delay of 60 seconds

    while (attempts < maxAttempts) {
      try {
        const res = await this.serviceCall(req, headers)
        ctx.commit(this.statusName, ApiCallStatus.SUCCESS)
        console.log('Success', this.name, res.toObject())
        if (attempts > 1) this.handleMessage(`Success: ${this.name}`, 'success') // Success message
        this.cacheResponse(ctx, data, res)
        this.invalidateCache(ctx, data)
        if (this.resProcesser) this.resProcesser(ctx, res, data)
        return res
      } catch (err) {
        console.log('Error ............', this.name, err)
        attempts++
        if (attempts >= maxAttempts || !this.isUpstreamConnectError(err)) {
          ctx.commit(this.statusName, ApiCallStatus.ERROR)
          console.error('Error', this.name, err)
          if (this.errProcesser) this.errProcesser(err, ctx)
          throw err
        }
        const delay = Math.min(
          initialDelayMs * Math.pow(2, attempts - 1) + Math.random() * 1000,
          maxDelayMs,
        ) // Exponential backoff with jitter and max delay
        this.handleMessage(
          `Attempt ${attempts} failed with error ${err.message}. Retrying in ${delay} milliseconds...`,
          'error',
        ) // Error message
        console.log(
          `Attempt ${attempts} failed with error ${err.message}. Retrying in ${delay} milliseconds...`,
        )
        await new Promise((resolve) => setTimeout(resolve, delay))
      }
    }
  }

  isUpstreamConnectError(err) {
    // Implement logic to determine if the error is an upstream connect error
    // This could be based on the error message, status code, or other properties of the error
    return err.message.includes('upstream connect error') && err.code === 14
  }

  cacheResponse(ctx, data, res) {
    if (!this.cache) return
    const cacheKey = this.cacheName + ':' + this.keyFn(data, ctx)
    const value = this.protobufToString(res)
    const cachedObject = JSON.stringify({ value: value, savedAt: Date.now() })
    localStorage.setItem(cacheKey, cachedObject)
    console.log('Caching', cacheKey, cachedObject)
  }

  invalidateCache(ctx, data) {
    if (!this.shouldInvalidateCache) return
    const invalidateCacheKey =
      this.invalidateCacheName + ':' + this.invalidateKeyFn(data, ctx)
    localStorage.removeItem(invalidateCacheKey)
    console.log('Invalidating', invalidateCacheKey)
  }

  handleStream(ctx, data, req, headers) {
    const streamCall = this.serviceCall(req, headers)
    streamCall.on('data', (res) => {
      console.log('Update', this.name, res.toObject())
      if (this.updateProcesser) this.updateProcesser(ctx, res, data)
    })
    streamCall.on('end', () => {
      ctx.commit(this.statusName, ApiCallStatus.SUCCESS)
      console.log('Success', this.name)
      if (this.resProcesser) this.resProcesser(ctx, null, data)
    })
    streamCall.on('error', (err) => {
      ctx.commit(this.statusName, ApiCallStatus.ERROR)
      console.log('Error', this.name, err)
      if (this.errProcesser) this.errProcesser(err, ctx, data)
    })
  }
}
