/* eslint-disable no-undef */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import judge from 'codedrills_proto/io/codedrills/proto/judge/judge_service_grpc_web_pb'
import { ApiCall } from '@/utils/api.js'
import '@sentry/vue'
import { metrics } from '@sentry/vue'

var judge_proto = proto.io.codedrills.proto.judge

const judgeService = new judge.JudgeServicePromiseClient(
  process.env.VUE_APP_JUDGE_API_URL + '/judge',
  null,
  null,
)

const state = {
  serverTime: Date.now() / 1000, // Epoch s
  syncRequestedAt: 0,
  fetchServerTimeStatus: 0,
  serverTimeDiff: 0,
  syncInitStarted: false,
  timeSyncId: null,
}

const actions = {
  async fetchServerTimeNew({ state, commit, dispatch }) {
    metrics.increment('timesync.fetch')
    //console.log("FetchServerTime");
    var leastRtt = Number.MAX_VALUE
    var bestDiff = null
    var checkUrl = '/'

    // Check if serverTimeDiff is already stored in local storage in last 1 hr
    var storedTimeDiff = localStorage.getItem('serverTimeDiff')
    if (storedTimeDiff != null) {
      storedTimeDiff = JSON.parse(storedTimeDiff)
      if (Date.now() - storedTimeDiff.timestamp < 30 * 60 * 1000) {
        console.log('Using stored time diff', storedTimeDiff.diff)
        metrics.increment('timesync.stored')
        bestDiff = storedTimeDiff.diff
      }
    }

    if (bestDiff == null) {
      metrics.increment('timesync.fetch')
      const MAX_ATTEMPTS = 3
      // Sleep for random seconds up to 2s to avoid all clients hitting at same time
      await new Promise((resolve) =>
        setTimeout(resolve, Math.floor(Math.random() * 2 * 1000)),
      )
      for (var i = 0; i < MAX_ATTEMPTS; ++i) {
        // Sleep for random seconds up to 2s to avoid all clients hitting at same time
        await new Promise((resolve) =>
          setTimeout(resolve, Math.floor(Math.random() * 2 * 1000)),
        )
        const requestAt = new Date()

        const { headers, ok } = await fetch(checkUrl, {
          cache: 'no-store',
          method: 'HEAD',
        })
        const responseAt = new Date()

        if (ok) {
          var rtt = responseAt - requestAt
          var age = 0
          /*
          Seems assumption of age is wrong. It returns current date irrespective of age param
          if (headers.has('Age')) {
            age = parseInt(headers.get('Age')) * 1000
          }
          console.log("Age", age);
          */
          const serverDate = new Date(
            new Date(headers.get('Date')).valueOf() + age,
          )
          //console.log("serverDate", serverDate);
          //console.log("diff", serverDate - responseAt);
          //console.log("rtt", rtt);
          if (rtt < leastRtt) {
            leastRtt = rtt
            bestDiff = serverDate - responseAt + rtt / 2
          }
          metrics.increment('timesync.fetch.success')
        } else {
          metrics.increment('timesync.fetch.error')
          break
        }
      }
    }
    console.log('bestDiff', bestDiff)
    var recheckAfter
    if (bestDiff != null) {
      commit('setServerTimeDiff', bestDiff)
      // 1 hr plus random seconds up to 10 mins to avoid all clients hitting at same time
      recheckAfter = 60 * 60 * 1000 + Math.floor(Math.random() * 60 * 10 * 1000)
      // Store the time difference in local storage with timestamp
      localStorage.setItem(
        'serverTimeDiff',
        JSON.stringify({ diff: bestDiff, timestamp: Date.now() }),
      )
      metrics.gauge('timesync.diff', bestDiff)
    } else {
      // Retry after random seconds up to 2 mins
      recheckAfter = Math.floor(Math.random() * 2 * 60 * 1000)
    }

    var id = setTimeout(() => dispatch('fetchServerTimeNew'), recheckAfter)
    commit('setTimeSyncId', id)
  },
  fetchServerTime: new ApiCall('fetchServerTime')
    .authOptional()
    .withServiceCall((r, h) => judgeService.serverTime(r, h))
    .withRequest(() => new judge_proto.master.ServerTimeRequest())
    .onSuccess(({ commit, dispatch }, res) => {
      commit('adjustServerTime', res)
      setTimeout(() => dispatch('fetchServerTime'), 60000)
    })
    .onError((__, { dispatch }) => {
      setTimeout((__, { dispatch }) => dispatch('fetchServerTime'), 120000)
    })
    .build(),

  async initTimeSync({ state, commit, dispatch }) {
    if (state.syncInitStarted === true) return
    console.log('Initing time sync', judge_proto.master)
    metrics.increment('timesync.init')
    commit('syncInitStarted')
    setInterval(() => dispatch('timeTick'), 1000)
    dispatch('fetchServerTimeNew')
  },
  async timeTick({ state, commit, dispatch }) {
    //setTimeout(() => dispatch('timeTick'), 1000)
    var serverTime = (Date.now() + state.serverTimeDiff) / 1000
    commit('setCurrentTime', serverTime)
    const timeSinceLastUpdate = Math.abs(serverTime - state.serverTime)
    if (timeSinceLastUpdate > 30) {
      console.log('RESYNC')
      metrics.increment('timesync.resync')
      metrics.gauge('timesync.tickUpdateDuration', timeSinceLastUpdate)
      if (state.timeSyncId != null) {
        clearTimeout(state.timeSyncId)
      }
      dispatch('fetchServerTimeNew')
    }
  },
}

const mutations = {
  fetchServerTimeStatus(state, value) {
    if (value == 1) {
      state.syncRequestedAt = Date.now()
    }
    state.fetchServerTimeStatus = value
  },
  setServerTimeDiff(state, serverTimeDiff) {
    state.serverTimeDiff = serverTimeDiff
  },
  adjustServerTime(state, res) {
    var localTime = Date.now()
    var requestDuration = Math.floor((localTime - state.syncRequestedAt) / 2)
    var returnedTime = res.getServerTime()
    state.serverTimeDiff = (returnedTime - (localTime - requestDuration)) / 1000
    console.log('Server time difference', state.serverTimeDiff)
  },
  setCurrentTime(state, serverTime) {
    state.serverTime = serverTime
  },
  syncInitStarted(state) {
    state.syncInitStarted = true
  },
  setTimeSyncId(state, id) {
    state.timeSyncId = id
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
