const schoolValues = [
  'A little flower the leader, Andhra Pradesh',
  'A.P. MODEL SCHOOL, Andhra Pradesh',
  'A.P.R.Junior College , Andhra Pradesh',
  'AAKASH IIT ACADEMY, Andhra Pradesh',
  'Aakash junior college, Andhra Pradesh',
  'AANM&VVRSR POLYTECHNIC, Andhra Pradesh',
  'Abhayass jr college, Andhra Pradesh',
  'ACCORD SCHOOL , Andhra Pradesh',
  'Acsent junior college, Andhra Pradesh',
  'ADITHYA JUNIOR COLLEGE, Andhra Pradesh',
  'ADITYA HIGH SCHOOL, Andhra Pradesh',
  'Aditya IIT Talent School, Andhra Pradesh',
  'ADITYA RJ.COLLEGE, Andhra Pradesh',
  'agricultural polytechnic, Andhra Pradesh',
  'Aiims junior college, Andhra Pradesh',
  'AIM COLLEGE, Andhra Pradesh',
  'AIM ENGLISH MEDIUM SCHOOL, Andhra Pradesh',
  'Aim IIT & Medical academy, Andhra Pradesh',
  'AIM JUNIOR COLLEGE, Andhra Pradesh',
  'AKHIL JUNIOR COLLEGE , Andhra Pradesh',
  'AKRG JUNIOR COLLEGE, Andhra Pradesh',
  'AKSHARA VIDYALYA, Andhra Pradesh',
  'Allen Career Institute, Andhra Pradesh',
  'Alwardas Public School, Andhra Pradesh',
  'AMAR SCHOOL / NARAYANA SPARK A/C CAMPUS, Andhra Pradesh',
  'Amaravathi college, Andhra Pradesh',
  'Amaravati IIT-JEE Academy, Andhra Pradesh',
  'ANARAVATHI JR COLLEGE, Andhra Pradesh',
  'andhra loyola college, Andhra Pradesh',
  'Andhra Pradesh Residential Junior College, Andhra Pradesh',
  'ANNAPURNA JUNIOR COLLEGE , Andhra Pradesh',
  'AP Residential junior college (girls), Andhra Pradesh',
  'Apex high school, Andhra Pradesh',
  'Apex Junior College, Andhra Pradesh',
  'APRJC nagarjuna sagar, Andhra Pradesh',
  'APSWRS JUNIOR COLLEGE, Andhra Pradesh',
  'Ascent Junior College, Andhra Pradesh',
  'Ashram public school, Andhra Pradesh',
  'Aspirants IIT Academy, Andhra Pradesh',
  'ATKINSON HIGH SCHOOL , Andhra Pradesh',
  'B S S B JR COLLEGE, Andhra Pradesh',
  "B V B'SSRI VENKATESWARA VIDYALAYA , Andhra Pradesh",
  'BALAJI EM HIGH SCHOOL, Andhra Pradesh',
  'BALAJI junior college, Andhra Pradesh',
  'BALAMANIKANTA ENGLISH MEDIUM SCHOOL, Andhra Pradesh',
  'Bapatla Public School, Andhra Pradesh',
  'Bashyam IITJEE junior college, Andhra Pradesh',
  'bharathiya vidhya nikethan, Andhra Pradesh',
  'Bharathiya Vidya Bhavan, Andhra Pradesh',
  'Bharatiya Vidya Bhavans international residential School , Andhra Pradesh',
  "BHARTIYA VIDYABHAVAN'S SRI VENKATESWARA VIDYALAYA, Andhra Pradesh",
  'Bhashayam college, Andhra Pradesh',
  'BHASHAYAM HIGH SCHOOL, Andhra Pradesh',
  'BHASHAYAM JUNIOR COLLEGE RAJAHMUNDRY, Andhra Pradesh',
  'BHASHYAM ENGLISH MEDIUM SCHOOL, Andhra Pradesh',
  'Bhashyam gorantla girls campus, Andhra Pradesh',
  'Bhashyam IIT JEE JUNIOR COLLEGE, Andhra Pradesh',
  'BHASHYAM IITJEE ACADEMY, Andhra Pradesh',
  'Bhashyam intermediate college, Andhra Pradesh',
  'BHASHYAM PUBLIC SCHOOL, Andhra Pradesh',
  'Bhavya cements DAV SCHOOL, Andhra Pradesh',
  'BIIT JUNIOR COLLEGE , Andhra Pradesh',
  'Board of Intermediate Education, Andhra Pradesh',
  'boon school kakinada, Andhra Pradesh',
  'Brahmani Public School, Andhra Pradesh',
  'BVB VIVEKANANDA VIDYA MANDIR, Andhra Pradesh',
  'BVK JUNIOR COLLEGE, Andhra Pradesh',
  'CAREER POINT JUNIOR COLLEGE, Andhra Pradesh',
  'CHAITHANYA ACADEMY, Andhra Pradesh',
  'CHAKRADAR JR COLLEGE , Andhra Pradesh',
  'Chalapathi public school, Andhra Pradesh',
  'CHITANYA JUNIOR COLLEGE , Andhra Pradesh',
  'CITADEL RESIDENTIAL SCHOOL, Andhra Pradesh',
  'CMR National Public School , Andhra Pradesh',
  'CPE junior college, Andhra Pradesh',
  'CV RAMAN JUNIOR COLLEGE, Andhra Pradesh',
  'D.V.C VIVEKA JUNIOR COLLEGE, Andhra Pradesh',
  'DAV HIGH SCHOOL, Andhra Pradesh',
  'DAV PUBLIC SCHOOL , Andhra Pradesh',
  'De Paul Junior college, Andhra Pradesh',
  'DEEPTHI JUNIOR COLLEGE, Andhra Pradesh',
  'DEESHA JUNIOR COLLEGE, Andhra Pradesh',
  'Deeskha Junior College, Andhra Pradesh',
  'DELHI PUBLIC SCHOOL, Andhra Pradesh',
  'DEPAUL SCHOOL, Andhra Pradesh',
  'DKNP JUNIOR COLLEGE, Andhra Pradesh',
  'DR BRRS GENSIS JUNIOR COLLEGE, Andhra Pradesh',
  'Dr Junior College, Andhra Pradesh',
  'DR KISHORES RATNAM EM HS, Andhra Pradesh',
  'DR KKR HAPPY VALLEY, Andhra Pradesh',
  "DR KKR'S GOWTHAM INT SCHOOL , Andhra Pradesh",
  "DR KKR's GOWTHAM SCHOOL, Andhra Pradesh",
  'DR KKRS HUKUMPETA, Andhra Pradesh',
  'DR SRK ENGLISH MEDIUM SCHOOL, Andhra Pradesh',
  'Dr. KLP Public School, Andhra Pradesh',
  'DR.K.K.R.GOWTHAM ENGLISH MEDIUM SCHOOL, Andhra Pradesh',
  'DR.KKR GOWTHAM CONCEPT SCHOOL , Andhra Pradesh',
  'DVC VIVEKA JUNIOR COLLEGE, Andhra Pradesh',
  'EMMAUS SWISS REFERRAL HOSPITAL SCHOOL, Andhra Pradesh',
  'ETASI TIMPANY SENIOR SECONDARY SCHOOL, Andhra Pradesh',
  'experts junior college, Andhra Pradesh',
  'FIIJEE COLLEGE, Andhra Pradesh',
  'FIIJEE JUNIOR COLLEGE, Andhra Pradesh',
  'FIITEE INTERNATIONAL CAMPUS, Andhra Pradesh',
  'fort city school, Andhra Pradesh',
  'Forum for Indian Institute of Technology, Andhra Pradesh',
  'FUTURE FOCUS JUNIOR COLLEGE, Andhra Pradesh',
  'G V N P S R JUNIOR COLLEGE, Andhra Pradesh',
  'GBR JUNIOR COLLEGE, Andhra Pradesh',
  'Gitam Junior college, Andhra Pradesh',
  'GOMATHY SCHOOL, Andhra Pradesh',
  'Goutham junior college, Andhra Pradesh',
  'GOVERNMENT POLYTECHNIC , Andhra Pradesh',
  'GOVT HIGH SCHOOL , Andhra Pradesh',
  'GOVT JUNIOR COLLEGE , Andhra Pradesh',
  'GOVT.Junior for girls, Andhra Pradesh',
  'GOWTHAM CONCEPT SCHOOL , Andhra Pradesh',
  'Gowtham junior college , Andhra Pradesh',
  'Gravity Junior college, Andhra Pradesh',
  'Grey friars junior college, Andhra Pradesh',
  'GSB NARAYANA GUDAVALLI CAMPUS , Andhra Pradesh',
  "GURU'S EDUCATIONAL INSTITUE, Andhra Pradesh",
  "Guru's educational society, Andhra Pradesh",
  "GURU'S JUNIOR COLLEGE, Andhra Pradesh",
  'GURUS VIDYA NIKETAN, Andhra Pradesh',
  'Gurus Vidyanikethan , Andhra Pradesh',
  'HAMSAVAHINI VIDYALAYA, Andhra Pradesh',
  'HAPPY VALLEY SCHOOL, Andhra Pradesh',
  'HARVEST PUBLIC SCHOOL, Andhra Pradesh',
  'Hayagriva IIT and MEDICAL HUB, Andhra Pradesh',
  'HT HIGH SCHOOL, Andhra Pradesh',
  'IIIT Nuzvid, Andhra Pradesh',
  'INTERMEDIATE BOARD OF ANDHRA PRADESH, Andhra Pradesh',
  'Jagans Junior college, Andhra Pradesh',
  'Jaggayyapeta Junior College, Andhra Pradesh',
  'JAWAHAR NAVODAYA VIDYALAYA, Andhra Pradesh',
  'JCNRM JUNIOR COLLEGE, Andhra Pradesh',
  'JUPITER JUNIOR COLEGE, Andhra Pradesh',
  'K.S.N junior college, Andhra Pradesh',
  'Kakaraparti Bhavanarayana college, Andhra Pradesh',
  'Kakatiya Public School, Andhra Pradesh',
  'Kamala junior college, Andhra Pradesh',
  'KASHYAP JUNIOR COLLEGE, Andhra Pradesh',
  'katamraju junior college, Andhra Pradesh',
  'Kayshap college/ Aakash Institute, Andhra Pradesh',
  'KCP SIDDHARTHA ADARSH RESIDENTIAL PUBLIC SCHOOL, Andhra Pradesh',
  'KENDRIYA VIDYALAYA, Andhra Pradesh',
  'KENNEDY EM HIGH SCHOOL , Andhra Pradesh',
  'KERALA HIGH SCHOOL, Andhra Pradesh',
  'KESAVA REDDY EM HS SCHOOL , Andhra Pradesh',
  'KESAVAREDDY CONCEPT SCHOOL, Andhra Pradesh',
  'KLP PUBLIC SCHOOL, Andhra Pradesh',
  'kondabolu lakshmi prasad public school, Andhra Pradesh',
  'KP Junior college for girls, Andhra Pradesh',
  'Krishna chaitanya collage, Andhra Pradesh',
  'krishna murty junior collage, Andhra Pradesh',
  'KRISHNA REDDY SIDDARTHA JR COLLEGE, Andhra Pradesh',
  'Krishnareddy Chaitanya college, Andhra Pradesh',
  'Krishnareddy siddartha college, Andhra Pradesh',
  'Krishnaveni Junior college, Andhra Pradesh',
  'Krishnaveni talent school, Andhra Pradesh',
  'KSN JUNIOUR COLLEGE, Andhra Pradesh',
  'L.R.G Junior college, Andhra Pradesh',
  'LAKSHYA JUNIOR COLLEGE , Andhra Pradesh',
  'LAKSHYA JUNIOR KALASALA, Andhra Pradesh',
  'lal bahadur shastri sch Chaitanya Techo schoolool , Andhra Pradesh',
  'lalitha iit and jee academy, Andhra Pradesh',
  'LALITHA JUNIOR COLLEGE, Andhra Pradesh',
  'LITTLE ANGLES HIGH SCHOOL, Andhra Pradesh',
  'Little flower em school, Andhra Pradesh',
  'Little Flower High School, Andhra Pradesh',
  'Loyola public school, Andhra Pradesh',
  'Lrg naidu Jr college, Andhra Pradesh',
  'LRG NAIDU JUNIOR COLLEGE, Andhra Pradesh',
  'M P AND E V ENGLISH MEDIUM SCHOOL, Andhra Pradesh',
  'M. P. R. M SRI VISWASANTHI JUNIOR COLLEG, Andhra Pradesh',
  'M.P.R.M Sri viswasanth junior College, Andhra Pradesh',
  'M.P.R.M.Mahila junior kalashala , Andhra Pradesh',
  'M.P.S JUNIOR COLLEGE, Andhra Pradesh',
  'Mahabodhi (EM & TM) High School, Andhra Pradesh',
  'MAHARISHI INT RES SCHOOL, Andhra Pradesh',
  'Mallidi Narayana Reddy junior college, Andhra Pradesh',
  'Managl Vidyalayam, Andhra Pradesh',
  'MANGAL JUNIOR COLLEGE , Andhra Pradesh',
  'MANGAL VIDYALAYAM PETAMITTA, Andhra Pradesh',
  'Mangalakara Jr College, Andhra Pradesh',
  'MARG CHINMAYA VIDYALAYA , Andhra Pradesh',
  'MATRIX IIT ACADAMY, Andhra Pradesh',
  'Mayuri Bhavan Srichaitanya Jr College, Andhra Pradesh',
  'MGM SCHOOL, Andhra Pradesh',
  'MNR JUNIOR COLLEGE , Andhra Pradesh',
  'MODEL SCHOOL JUNIOR COLLEGE, Andhra Pradesh',
  'Modern school, Andhra Pradesh',
  'Montessori Indus EM High School, Andhra Pradesh',
  'MONTESSORI SCHOOL KURNOOL, Andhra Pradesh',
  'MOTHER THERESA JUNIOR COLLEGE, Andhra Pradesh',
  'MPRM sri viswashanthi mahila jr collage, Andhra Pradesh',
  'MUNICIPAL RESIDENTIAL JUNIOR COLLEGE, Andhra Pradesh',
  'N R I JUNIOR COLLEGE, Andhra Pradesh',
  'NAGARJUNA JUNIOR COLLEGE, Andhra Pradesh',
  'Nagarjuna model school , Andhra Pradesh',
  'Nalanda vidhya niketan, Andhra Pradesh',
  'NANDI ACADEMY UP SCHOOL, Andhra Pradesh',
  'Naraayana Junior College, Andhra Pradesh',
  'NARAYANA CONCEPT SCHOOL, Andhra Pradesh',
  'narayana e techno school , Andhra Pradesh',
  'NARAYANA EDUCATOINAL INSTITUTIONS, Andhra Pradesh',
  'Narayana EM High School, Andhra Pradesh',
  'NARAYANA HIGH SCHOOL, Andhra Pradesh',
  'NARAYANA IIT ACADEMY, Andhra Pradesh',
  'narayana jee mains academy, Andhra Pradesh',
  'NARAYANA NAID CAMPUS , Andhra Pradesh',
  'Narayana narasimhakonda, Andhra Pradesh',
  'NARAYANA NARMADHA JUNIOR COLLEGE, Andhra Pradesh',
  'NARAYANA OLYMPIAD SCHOOL, Andhra Pradesh',
  'Narayana PU College , Andhra Pradesh',
  'narayana school, Andhra Pradesh',
  'NARAYANA SENIOR COLLEGE, Andhra Pradesh',
  'Narayana Shivani bhavan, Andhra Pradesh',
  'NARAYANA SPARK CAMPUS , Andhra Pradesh',
  'NATIONAL SANSKRIT UNIVERSITY, Andhra Pradesh',
  'NAVY CHILDREN SCHOOL, Andhra Pradesh',
  'NIRMALA HIGH SCHOOL, Andhra Pradesh',
  'NIRMALA JUNIOR COLLEGE, Andhra Pradesh',
  'NLVRGSRV Junior College, Andhra Pradesh',
  "NR's BRILLIANT JUNIOR COLLEGE, Andhra Pradesh",
  'NRI ARTS AND SCIENCE JUNIOR COLLEGE , Andhra Pradesh',
  'NRI CO- EDUCATIONAL JUNIOR COLLEGE, Andhra Pradesh',
  'NRI COEDUCATIONAL SOCIETY, Andhra Pradesh',
  'Nri college, Andhra Pradesh',
  'NRI EDUCATION SOCIETY, Andhra Pradesh',
  'NRI INDIAN SPRINGS E.M HIGH SCHOOL, Andhra Pradesh',
  'NRI JUNIOR COLLEGE, Andhra Pradesh',
  'NRI VIDYA JUNIOR COLLEGE, Andhra Pradesh',
  'NR`S BRILLIANT JUNIOR COLLEGE, Andhra Pradesh',
  'Nucleus junior college, Andhra Pradesh',
  'OAKRIDGE INTERNATIONAL SCHOOL, Andhra Pradesh',
  'Oxford concept school, Andhra Pradesh',
  'OXFORD JUNIOR COLLEGE, Andhra Pradesh',
  'OXFORD PUBLIC SCHOOL, Andhra Pradesh',
  'P B Siddhartha Junior College, Andhra Pradesh',
  'PADMAVATHI JUNIOR COLLEGE FOR GIRLS, Andhra Pradesh',
  'PANDIT JR COLLEGE, Andhra Pradesh',
  'pavan junior collage, Andhra Pradesh',
  'PBN college, Andhra Pradesh',
  'Penchal reddy cbse secondary school, Andhra Pradesh',
  'Prabha Junior Cllege, Andhra Pradesh',
  'PRAGATI VIDYALAYA JUNIOR COLLEGE, Andhra Pradesh',
  'PRATHIBHA JUNIOR COLLEGE, Andhra Pradesh',
  'Prathibha Public School, Andhra Pradesh',
  'Presedential school, Andhra Pradesh',
  'PRESIDENCY JUNIOR COLLEGE, Andhra Pradesh',
  'Presidencys Engineering college, Andhra Pradesh',
  'PRIYADARSHINI JUNIOR COLLEGE, Andhra Pradesh',
  'Q I S PUBLIC SCHOOL, Andhra Pradesh',
  'R k junior college, Andhra Pradesh',
  'R R G R Jr college, Andhra Pradesh',
  'RACE JUNIOR COLLEGE, Andhra Pradesh',
  'Rajadhani Junior College, Andhra Pradesh',
  'Raju high school, Andhra Pradesh',
  'raju junior college, Andhra Pradesh',
  'RAMANBHAVAN 1 srichaitanya , Andhra Pradesh',
  "RAO'S JR COLLEGE, Andhra Pradesh",
  'RATNAM HIGH SCHOOL, Andhra Pradesh',
  'RATNAM JUNIOR COLLEGE, Andhra Pradesh',
  'Ravindra Bharathi public school , Andhra Pradesh',
  'Rayala seema junior college, Andhra Pradesh',
  'regency junior college, Andhra Pradesh',
  'Rishi teja vidyanikethan, Andhra Pradesh',
  'RISHI VALLEY SCHOOL, Andhra Pradesh',
  'RK COLLEGE , Andhra Pradesh',
  'Rk junior college, Andhra Pradesh',
  'Rotary High School, Andhra Pradesh',
  'Royal Junior College, Andhra Pradesh',
  'RVSM HSS PRAYAR, Andhra Pradesh',
  'S D H R JUNIOR COLLEGE, Andhra Pradesh',
  'S K V S JR KALASALA, Andhra Pradesh',
  'S V K JUNIOR COLLEGE, Andhra Pradesh',
  'S V L KRANTHI JUNIOR COLLEGE , Andhra Pradesh',
  'S. V. L. N. S. V. P. Junior College, Andhra Pradesh',
  'S.E. RAILWAY JUNIOR COLLEGE, Andhra Pradesh',
  'S.K.R.B.R Junior College, Andhra Pradesh',
  'S.K.V.S. JUNIOR KALASALA, Andhra Pradesh',
  'Sai Sri Chaitanya Junior College, Andhra Pradesh',
  'Sai teja em high school, Andhra Pradesh',
  'SAIBABA CENTRAL SCHOOL , Andhra Pradesh',
  'Sainik school, Andhra Pradesh',
  'Saint Francis School, Andhra Pradesh',
  'Sanskruthi global school, Andhra Pradesh',
  'Saradha Educational Institutions, Andhra Pradesh',
  'Sasi college for women, Andhra Pradesh',
  'Sasi educational institutions, Andhra Pradesh',
  'sasi engish medium high school, Andhra Pradesh',
  'Sasi New Gen Junior College, Andhra Pradesh',
  'SASSI JUNIOR COLLEGE, Andhra Pradesh',
  'SASTRA ACADEMEY , Andhra Pradesh',
  'SASTRA JUNIOR COLLEGE, Andhra Pradesh',
  'Satyam High School, Andhra Pradesh',
  'SCHOLARS JUNIOR COLLEGE, Andhra Pradesh',
  'SDHR JUNIOR COLLEGE, Andhra Pradesh',
  'SDR E.M HIGH SCHOOL, Andhra Pradesh',
  'Seetharamaiah Junior College, Andhra Pradesh',
  'Seicom junior college, Andhra Pradesh',
  'SER Junior college, Andhra Pradesh',
  'SGM KRISHNAMURTHY JUNIOR COLLEGE, Andhra Pradesh',
  'SHASTRA JUNIOR COLLEGE, Andhra Pradesh',
  'SHIRDI SAI SCHOOL, Andhra Pradesh',
  'Shri gnanambica junior college, Andhra Pradesh',
  'SHRI VEDA JUNIOR COLLEGE, Andhra Pradesh',
  'si chaitanya educational institution, Andhra Pradesh',
  'SIDDHARTHA ENGLISH MEDIUM HIGH SCHOOL, Andhra Pradesh',
  'Siddhartha high school, Andhra Pradesh',
  'Siddhartha junior college, Andhra Pradesh',
  'Siddhartha Sahakara Junior Kalasala, Andhra Pradesh',
  'SIRI CHAITANYA JUNIOR COLLAGE, Andhra Pradesh',
  'Sitaramaiah junior college, Andhra Pradesh',
  'Sivani public school, Andhra Pradesh',
  'SKVS JUNIOR COLLEGE, Andhra Pradesh',
  'South East Railway junior college, Andhra Pradesh',
  'SPACE CENTRAL SCHOOL , Andhra Pradesh',
  'SPRM Convert school, Andhra Pradesh',
  'Sr Viswasanthi Educational Institutions, Andhra Pradesh',
  'SREE CHAITHANYA MAHILA JUNIOR COLLEGE, Andhra Pradesh',
  'SREE KASYAP JUNIOR COLLEGE, Andhra Pradesh',
  'sree Krishna high school, Andhra Pradesh',
  'SREE PRAKASH JR COLLEGE, Andhra Pradesh',
  'Sree Vanni Nikethan E. M school, Andhra Pradesh',
  'SREE VIDYA JUNIOR COLLEGE, Andhra Pradesh',
  'sree vidyanikethan international school, Andhra Pradesh',
  'SREECHAITANYA BOYS JR COLLEGE, Andhra Pradesh',
  'Sreemitra junior college, Andhra Pradesh',
  'Sreenivasulu jr college, Andhra Pradesh',
  'Sri Aakash Junior College, Andhra Pradesh',
  'Sri Aditya junior college, Andhra Pradesh',
  'Sri AKSHARA JUNIOR COllege, Andhra Pradesh',
  'Sri Basara Junior College, Andhra Pradesh',
  'Sri Chaitanya academy junior college, Andhra Pradesh',
  'Sri Chaitanya Apoorva Soudham, Andhra Pradesh',
  'Sri Chaitanya aria bhavan, Andhra Pradesh',
  'SRI CHAITANYA ARTS AND SCIENCE JUNIOR COLLEGE, Andhra Pradesh',
  'sri chaitanya arya bhavan , Andhra Pradesh',
  'Sri Chaitanya Bhaskara Bhavan, Andhra Pradesh',
  'SRI CHAITANYA BOYS JUNIOR COLLEGE, Andhra Pradesh',
  'SRI CHAITANYA CHILDRENS ACADEMY, Andhra Pradesh',
  'SRI CHAITANYA CO ED JR COLLEGE, Andhra Pradesh',
  'SRI CHAITANYA EDUCATIONAL INSTITUTIONS, Andhra Pradesh',
  'SRI CHAITANYA GIRLS JR COLLEGE , Andhra Pradesh',
  'SRI CHAITANYA HIGH SCHOOL, Andhra Pradesh',
  'Sri Chaitanya icon campus , Andhra Pradesh',
  'SRI CHAITANYA IIT-JEE ACADEMY, Andhra Pradesh',
  'Sri Chaitanya jr Abdul Kalam, Andhra Pradesh',
  'sri chaitanya Junior kalasala, Andhra Pradesh',
  'SRI CHAITANYA MAHILA JR COLLEGE, Andhra Pradesh',
  'Sri Chaitanya Mayuri Bhavan, Andhra Pradesh',
  'Sri chaitanya Raman bavan, Andhra Pradesh',
  'SRI CHAITANYA SCHOOL, Andhra Pradesh',
  'SRI CHAITANYA SENIOR COLLEGE, Andhra Pradesh',
  'Sri Chaitanya sumedha bavan , Andhra Pradesh',
  'SRI CHANTANYA TECHNO SCHOOL, Andhra Pradesh',
  'SRI CHINTA SEETHAIAH JUNIOR COLLEGE , Andhra Pradesh',
  'Sri durga malleswara siddhartha mahila kalasala, Andhra Pradesh',
  'sri g.c.s.r junior college, Andhra Pradesh',
  'SRI GAYATHRI JUNIOR COLLEGE, Andhra Pradesh',
  'Sri Gayatri enducationl institutions, Andhra Pradesh',
  'SRI GNANAMBIKA JUNIOR COLLEGE, Andhra Pradesh',
  'Sri Gowthami Girls Junior College, Andhra Pradesh',
  'SRI GOWTHAMI JUNIOR COLLEGE, Andhra Pradesh',
  'Sri Gowthami Smart school, Andhra Pradesh',
  'sri guru defence academy, Andhra Pradesh',
  'sri harshini junior colleage, Andhra Pradesh',
  'SRI I CHAITHANYA JR COLLEGE, Andhra Pradesh',
  'Sri kashyap Junior collage, Andhra Pradesh',
  'Sri Krishna junior college , Andhra Pradesh',
  'Sri krishna reddy siddartha junior college, Andhra Pradesh',
  'Sri Mahatma junior college, Andhra Pradesh',
  'SRI MEDHA V JUNIOR COLLEGE, Andhra Pradesh',
  'Sri Nalanda Jr college, Andhra Pradesh',
  'Sri Narayana Junior College, Andhra Pradesh',
  'SRI NEW VISION JR COLLEGE, Andhra Pradesh',
  'sri nidhi juniour colleage, Andhra Pradesh',
  'Sri Prakash Junior College, Andhra Pradesh',
  'SRI PRAKASH SYNERGY SCHOOL, Andhra Pradesh',
  'SRI PRAKASH VIDYANIKETAN, Andhra Pradesh',
  'Sri Pratibha Junior College, Andhra Pradesh',
  'Sri Sadana Jr College, Andhra Pradesh',
  'Sri sai Aditya Junior college, Andhra Pradesh',
  'SRI SAI BHARATHI JUNIOR COLLEGE, Andhra Pradesh',
  'sri sai junior college, Andhra Pradesh',
  'SRI SAI JYOTHI JUNIOR COLLEGE, Andhra Pradesh',
  'SRI SAI SAKTHI JUNIOR COLLEGE, Andhra Pradesh',
  'sri sai sakti junior college, Andhra Pradesh',
  'Sri Sai vijetha high school, Andhra Pradesh',
  'Sri sairam junior college, Andhra Pradesh',
  'SRI SAKTHI JUNIOR COLLEGE, Andhra Pradesh',
  'Sri Sarada Educational Institutions, Andhra Pradesh',
  'Sri sarada junior college, Andhra Pradesh',
  'SRI SARADA SUNFLOWER JR COLLEGE, Andhra Pradesh',
  'Sri Saraswati junior college, Andhra Pradesh',
  'SRI SATHYA SAI HIGHER SECONDARY SCHOOL, Andhra Pradesh',
  'Sri Shirdi Sai Junior College, Andhra Pradesh',
  'SRI SIDDARTHA RESIDENTIAL JUNIOR COLLEGE, Andhra Pradesh',
  'Sri Somanatha Green Fields Public School, Andhra Pradesh',
  'Sri Sri Chaitanya College , Andhra Pradesh',
  'SRI SRINIVASA JR COLLEGE , Andhra Pradesh',
  'SRI SURYA JUNIOR COLLEGE, Andhra Pradesh',
  'Sri Tirumala Junior College, Andhra Pradesh',
  'SRI VAISHNAVI ABHYAAS JUNIOR COLLEGE, Andhra Pradesh',
  'SRI VAISHNAVI MEDICAL ACADEMY, Andhra Pradesh',
  'SRI VAMSI KRISHNA JUNIOR COLLEGE, Andhra Pradesh',
  'SRI VASAVI JUNIOR COLLEGE, Andhra Pradesh',
  'SRI VEMA JR COLLEGE, Andhra Pradesh',
  'Sri Venkatewara College, Andhra Pradesh',
  'Sri Venketswara junior college, Andhra Pradesh',
  'SRI VIDYA J.R COLLEGE, Andhra Pradesh',
  'Sri Viswa Santhi Mahila junior college, Andhra Pradesh',
  'Sri viswasanthi educational institutions, Andhra Pradesh',
  'SRI VISWASANTHI JUNIOR COLLEGE, Andhra Pradesh',
  'SRI VISWASANTHI SENIOR SECONDARY SCHOOL , Andhra Pradesh',
  'Sri viswashanthi, Andhra Pradesh',
  'Sri Vivekananda Central School, Andhra Pradesh',
  'sri vivekananda junior college, Andhra Pradesh',
  'SRI VYSHNAVI ABHAYAS JUNIOR COLLEGE, Andhra Pradesh',
  'Sri Vyshnavi Bhavan,Narayana jr college, Andhra Pradesh',
  'srichaitanya educational institions, Andhra Pradesh',
  'Srichaitanya girls junior college(Sumedha bhavan), Andhra Pradesh',
  'srichaitanya high school, Andhra Pradesh',
  'SRICHAITANYA IIT ACADEMY , Andhra Pradesh',
  'SRICHAITANYA JUNIOR COLLEGE, Andhra Pradesh',
  'Srichaityana college , Andhra Pradesh',
  'srujana junior college , Andhra Pradesh',
  'St Anns English Medium School, Andhra Pradesh',
  'ST ANNS JUNIOR COLLEGE, Andhra Pradesh',
  "ST JOHN'S ENGLISH MEDIUM HIGH SCHOOL, Andhra Pradesh",
  'ST MARYS EM HIGH SCHOOL , Andhra Pradesh',
  'ST PAULS HIGH SCHOOL , Andhra Pradesh',
  "St. josep's girls high school, Andhra Pradesh",
  'St.Anns School, Andhra Pradesh',
  'ST.JOHNS EM HIGH SCHOOL , Andhra Pradesh',
  'st.josephs public school, Andhra Pradesh',
  'star college , Andhra Pradesh',
  'STAR JUNIOR COLLEGE, Andhra Pradesh',
  'State Board of secondary Education, Andhra Pradesh',
  'SUBHANIKETAN SCHOOL, Andhra Pradesh',
  'SUGNAN IC MEDICAL ACADEMY, Andhra Pradesh',
  'surya college, Andhra Pradesh',
  'Suvarna bharathi junior college, Andhra Pradesh',
  'SVK JUNIOR COLLEGE, Andhra Pradesh',
  'Svks jr college, Andhra Pradesh',
  'SVL KRANTHI JUNIOR COLLEGE, Andhra Pradesh',
  'swarnandhra international school, Andhra Pradesh',
  "T.A.R's SCHOOL, Andhra Pradesh",
  'T.M.R college, Andhra Pradesh',
  'THE ADITYA BIRLA PUBLIC SCHOOL, Andhra Pradesh',
  'THE BODHI EM HIGHSCHOOL, Andhra Pradesh',
  "THE FUTURE KID'S SCHOOL, Andhra Pradesh",
  'The nandyal public school, Andhra Pradesh',
  'THE NUCLEUS JUNIOR COLLEGE, Andhra Pradesh',
  'The Presidential School, Andhra Pradesh',
  'The Sun School, Andhra Pradesh',
  'Thejus residential em hs, Andhra Pradesh',
  'TIMPANY SENIOR SECONDARY SCHOOL, Andhra Pradesh',
  'TINY TOTS E.M HIGH SCHOOL, Andhra Pradesh',
  'Tirumala educational institutions, Andhra Pradesh',
  'TIRUMALA JUNIOR COLLEGE, Andhra Pradesh',
  'Tirumala Manila junior kalasala, Andhra Pradesh',
  'Triveni Junior College, Andhra Pradesh',
  'UMAMAHESWARA JUNIOR COLLEGE, Andhra Pradesh',
  'UNIVERSAL HIGH SCHOOL, Andhra Pradesh',
  'universal public school, Andhra Pradesh',
  'Usha kiran high school, Andhra Pradesh',
  'VAGDEVI JR COLLEGE , Andhra Pradesh',
  'VAISHNAVI TECHNO SCHOOL, Andhra Pradesh',
  'VAMSI JR COLLEGE, Andhra Pradesh',
  'vasavi ideal public school, Andhra Pradesh',
  'VASAVI JUNIOR COLLEGE, Andhra Pradesh',
  'Vbr residential school, Andhra Pradesh',
  'veeramachaneni paddayya siddhartha public school, Andhra Pradesh',
  'Venkatreddy Siddhartha junior college, Andhra Pradesh',
  'Viawasai Jr college, Andhra Pradesh',
  'Vidhyanidhi junior college, Andhra Pradesh',
  'VIDYA VIKAS, Andhra Pradesh',
  'Vidya Vikas Junior college, Andhra Pradesh',
  'Vignan Boys Junior College, Andhra Pradesh',
  'vignan co-operative junior college, Andhra Pradesh',
  'Vignan girls junior college, Andhra Pradesh',
  'Vignan Junior College, Andhra Pradesh',
  'VIGNAN STEEL CITY PUBLIC SCHOOl, Andhra Pradesh',
  'Vignan Vidyalayam school, Andhra Pradesh',
  'VIGNANA BHARATHI SCHOOL, Andhra Pradesh',
  'VIGNANABHARATHI JUNIOR COLLEGE, Andhra Pradesh',
  'Vignanasuma Junior College, Andhra Pradesh',
  'VIJAYA JUNIOR COLLEGE, Andhra Pradesh',
  'VIJAYA KRANTHI JR COLLEGE, Andhra Pradesh',
  'VIJAYAM PUBLIC SCHOOL, Andhra Pradesh',
  'vijayam school, Andhra Pradesh',
  'vijayawada nalanda junior college, Andhra Pradesh',
  'Vijnana vihara school, Andhra Pradesh',
  'VIKAS VIDYANIKETAN, Andhra Pradesh',
  'VIKRAM JUNIOR COLLEGE, Andhra Pradesh',
  'VISAKHA VALLEY SCHOOL, Andhra Pradesh',
  'Vishaka diffence acadamy, Andhra Pradesh',
  'Vishwanarayana college, Andhra Pradesh',
  'VISWA SAI JUNIOR COLLEGE, Andhra Pradesh',
  'VISWABHARATHI EM SCHOOL , Andhra Pradesh',
  'VISWASANTHI EM HIGH SCHOOL, Andhra Pradesh',
  'VIVEKA JUNIOR COLLEGE , Andhra Pradesh',
  'Vivekanand jr college, Andhra Pradesh',
  'VIVEKANANDA JUNIOR COLLEGE, Andhra Pradesh',
  'VIZAG DIFFENCE ACADAMY, Andhra Pradesh',
  'vowel techno School, Andhra Pradesh',
  'VRC Junior Collage, Andhra Pradesh',
  'Vuswasai jr college, Andhra Pradesh',
  'VYSHNAVI JUNIOR COLLEGE, Andhra Pradesh',
  'XPERTS JUNIOR COLLEGE, Andhra Pradesh',
  'Z.P.HIGH SCHOOL, Andhra Pradesh',
  'ZPHS, G.K.Palli, Andhra Pradesh',
  'Avichi Higher Secondary School, Arunachal Pradesh',
  'Delhi Public School, Arunachal Pradesh',
  'Donyi Polo Vidya Bhawan, Arunachal Pradesh',
  'Good Shepherd public school , Arunachal Pradesh',
  'GOVERNMENT HIGHER SECONDARY SCHOOL, Arunachal Pradesh',
  'HAPPY VALLEY SCHOOL, Arunachal Pradesh',
  'JAWAHAR NAVODAYA VIDYALAYA, Arunachal Pradesh',
  'Kendriya Vidyalaya , Arunachal Pradesh',
  'Lekhi Public School, Arunachal Pradesh',
  'Lotus high school, Arunachal Pradesh',
  'Ravindra Bharathi school, Arunachal Pradesh',
  'SASI JUNIOR COLLEGE , Arunachal Pradesh',
  'SRI CHAITANYA IIT JEE ACADEMY, Arunachal Pradesh',
  'SRI CHAITANYA JUNIOR COLLEGE , Arunachal Pradesh',
  'TIRUMALA JUNIOUR COLLEGE , Arunachal Pradesh',
  'VIVEKANANDA KENDRA VIDYALAYA, Arunachal Pradesh',
  'Ajmal college of arts commerce and science, Assam',
  'Anundoram borooah academy, Assam',
  'APS Narangi, Assam',
  'ARMY PUBLIC SCHOOL, Assam',
  'Aryan Junior College, Assam',
  'Assam Brilliant Academy, Assam',
  'ASSAM JATIYA VIDYALAYA, Assam',
  'Bajali College, Assam',
  'Concept Junior College, Assam',
  'Cotton university, Assam',
  'Dawson HS and MP SCHOOL, Assam',
  'DELHI PUBLIC SCHOOL , Assam',
  'Don Bosco school, Assam',
  'Dr. Radhakrishnan school of arts commerce and science , Assam',
  'Faculty Higher Secondary School, Assam',
  'Gauhati Commerce College, Assam',
  'Hindustani Kendriya Vidyalaya, Assam',
  'Holy Child School, Assam',
  'Jorhat Govt boys H.S and M.P School, Assam',
  'Kendriya Vidyalaya , Assam',
  'Maharishi Vidya Mandir, Assam',
  'MARIAS PUBLIC SCHOOL, Assam',
  'MILES BRONSON RESIDENTIAL SCHOOL, Assam',
  'NARAYANA JUIOR COLLEGE , Assam',
  'NPS International School, Assam',
  'Paragon junior college, Assam',
  'PRANABANANDA VIDYAMANDIR, Assam',
  'Ramanuj Gupta Junior College, Assam',
  'Resolution Academy, Assam',
  'Royal Global School, Assam',
  'SAI R.N.S ACADEMY, Assam',
  'Sai Vikash Junior College, Assam',
  'Saint Capitanio Senior Secondary school, Assam',
  "Saint Francis D'assisi High School , Assam",
  'Salt Brook Academy, Assam',
  'Shrimanta Shankar Academy, Assam',
  'ST FRANCIS D`ASSISI HIGH SCHOOL, Assam',
  'ST THERESAS SCHOOL , Assam',
  'SWADESHI ACADEMY, Assam',
  'THE ASSAM VALLEY SCHOOL, Assam',
  'THE LITTLE STARS SCHOOL , Assam',
  'TINSUKIA COLLEGE, Assam',
  'VIVEKANANDA KENDRA VIDYALAYA, Assam',
  'WIPS Junior College, Assam',
  'A N College, Bihar',
  'A.K.R.R. College , Bihar',
  'A.K.S. COLLEGE, Bihar',
  'aaj ke Vidyalay , Bihar',
  'ABADH BIHARI SINGH COLLEGE, Bihar',
  'Adarsh Gyanodaya Vidyalaya , Bihar',
  'Adarsh vikash vidyalaya, Bihar',
  'AL HAFEEZ COLLEGE , Bihar',
  'AMRITA VIDYALAYAM, Bihar',
  'Annie basent int school, Bihar',
  'ANUGRAH INTER SCHOOL , Bihar',
  'Army Public School , Bihar',
  'Arpa International school, Bihar',
  'ARYA UCCHTAR MADHMIK VIDYALAYA, Bihar',
  'Asi high school , Bihar',
  'B N collegiate , Bihar',
  'B.D,COLLEGE , Bihar',
  'B.N.M.V collage, Bihar',
  'B.R.N.K.S. COLLEGE , Bihar',
  'B.S.S COLLEGE, Bihar',
  'Bahadurganj collage, Bihar',
  'Baldwin Academy, Bihar',
  'BD PUBLIC SCHOOL, Bihar',
  'Bhawgat vidya peeth, Bihar',
  'BHRAMDEV CHANDRAKALA INTER COLLEGE, Bihar',
  'Bhupesh Gupt Mahavidyalay , Bihar',
  'Bihar Board Open Schooling, Bihar',
  'BIHAR PUBLIC SCJ, Bihar',
  'Blue bells academy, Bihar',
  'BPM INTERNATIONAL SCHOOL, Bihar',
  'BR DAV PUBLIC SCHOOL, Bihar',
  'BRIGHT CAREER SCHOOL, Bihar',
  'Brilliant Public School , Bihar',
  'BRISHN PATEL S.S. SCHOOL , Bihar',
  'BRITISH ENGLISH SCHOOL, Bihar',
  'BS COLLEGE SHAHKUND, Bihar',
  'C M D COLLEGE, Bihar',
  'C. high School, Bihar',
  'C.K HIGH SCHOOL , Bihar',
  'CAMBRIDGE PUBLIC SCHOOL, Bihar',
  'Cambridge School, Bihar',
  'Candirka school, Bihar',
  'CBI inter college, Bihar',
  'Central Public School, Bihar',
  'CHAUHAN PUBLIC SCHOOL, Bihar',
  'Chhapra Central school, Bihar',
  "Children's Garden, Bihar",
  'CMJ institute of education, Bihar',
  'Creane Memorial High school, Bihar',
  'CS COLLEGE BELHAR BANKA, Bihar',
  'D.M.P. Hollymission public school, Bihar',
  'D.S.S.V COLLEGE, Bihar',
  'DAV HIGH SCHOOL , Bihar',
  'DBKN COLLEGE , Bihar',
  'DELHI MODEL PUBLIC SCHOOL, Bihar',
  'DELHI PUBLIC SCHOOL, Bihar',
  'Devasthaliya vidyapeeth, Bihar',
  'Digdarshan secondary school, Bihar',
  'Diksha International School, Bihar',
  'DK Carmel residential high school, Bihar',
  'DMP HOLY MISSION SCHOOL, Bihar',
  'Dolphin Public School, Bihar',
  'DON BOSCO ACADEMY, Bihar',
  'Doon public school, Bihar',
  'DR B.P.YADAV S.S SCHOOL , Bihar',
  'DR G L D DAV PUB SCHOOL , Bihar',
  'Dr. Zakir Hussain High School, Bihar',
  'Dr.R.B.Roy College, Bihar',
  'EAST CENTRAL RAILWAY SR SEC SCH, Bihar',
  'Eklavya Educational complex, Bihar',
  'G C S S INTERCOLLAGE , Bihar',
  'G R S S Vidya Mandir, Bihar',
  'G.C.S.S.COLLEGE , Bihar',
  'G.D college , Bihar',
  'Goethals Public School, Bihar',
  'Gorakh singh college, Bihar',
  'Goverment inter college , Bihar',
  'Gulab memorial inter College , Bihar',
  'Gyan bharti global school, Bihar',
  'Gyan Bharti Residential Complex, Bihar',
  'GYAN BHARTI SR SEC SCHOOL, Bihar',
  'Gyanda Intnl School, Bihar',
  'Gyandeep high school, Bihar',
  'GYANSTHALI HIGH SCHOOL, Bihar',
  'HAPPY VALLEY SCHOOL, Bihar',
  'HIGH SCHOOL, Bihar',
  'Himalayan international school, Bihar',
  'Himalayan Public School, Bihar',
  'HIMALAYAN RESIDENTIAL SCHOOL, Bihar',
  'holy cross public school, Bihar',
  'HOLY KIDS INTL.SCHOOL, Bihar',
  'Holy Mission High School, Bihar',
  'HOLY MISSION SECONDARY SCHOOL , Bihar',
  'Indian Public School, Bihar',
  'INDRAPRASTH INTERNATIONAL SCHOOL BOCHAHAN, Bihar',
  'INFANT JESUS SCHOOL, Bihar',
  'Inter vidyalaya , Bihar',
  'International School , Bihar',
  'Ishan International Public School, Bihar',
  'J.J.S. College, Bihar',
  'J.K COLLEGE, Bihar',
  'J.L.N.M COLLEGE , Bihar',
  'Jagannath Sudama s.s school, Bihar',
  'JAGAT MISSION SR SEC SCHL, Bihar',
  'Jai Prakash college, Bihar',
  'JAWAHAR NAVODAYA VIDYALAYA, Bihar',
  'Jd public school, Bihar',
  'JEEVAN JYOTI PUBLIC SCHOOL, Bihar',
  'Jeewan Public school, Bihar',
  'JESUS AND MARY ACADEMY , Bihar',
  'JN COLLEGE, Bihar',
  'JNV VAISHALI, Bihar',
  'K N Memo Academy, Bihar',
  'K.N.S.S SCHOOL, Bihar',
  'KANTI DEVI INTER COLEGE, Bihar',
  'KEDAR PANDEY PROJECT GIRLS HIGH SCHOOL, Bihar',
  'KENDRIYA VIDYALAYA , Bihar',
  'KID-Z PUBLIC SCHOOL, Bihar',
  'KISAN INTER COLLEGE, Bihar',
  'KMD COLLEGE , Bihar',
  'koshi college , Bihar',
  'Krishna Memorial academy, Bihar',
  'Krishna Niketan, Bihar',
  'Krishna public school , Bihar',
  'Krishnanand Memorial Academy, Bihar',
  'Kushwaha Somari Triloki Collage, Bihar',
  'l..P shahi College, Bihar',
  'L.S College , Bihar',
  'Lawabar sikariya high school, Bihar',
  'LEEDS ASIAN SCHOOL, Bihar',
  'LITERA VALLEY ZEE, Bihar',
  'LND COLLEGE, Bihar',
  'Loyola High School, Bihar',
  'Lyceum international school, Bihar',
  'M G S plus two high school , Bihar',
  'M L T COLLEGE, Bihar',
  'M P COLLEGE, Bihar',
  'M R J D Inter college , Bihar',
  'M R JANATA COLLEGE, Bihar',
  'M. A. Y. INTER COLLEGE , Bihar',
  'M.L.S.M College , Bihar',
  'M.P COLLEGE , Bihar',
  'M.S COLLAGE , Bihar',
  'M.S. Memorial public school, Bihar',
  'MAA BAGESHWARI INTER COLLEGE , Bihar',
  'Madhuban Public School, Bihar',
  'MAHANT HANUMAN SHARAN SENIOR SECONDARY SCHOOL, Bihar',
  'Maharaja college, Bihar',
  'Maharishi Vidya Mandir, Bihar',
  'MAHATMA GANDHI SHIKSHAN SANSTHAN , Bihar',
  'Mahavir college , Bihar',
  'MANAS INTERNATIONAL PUBLIUC SCHOOL, Bihar',
  'MANASH BHARTI EDUCATIONAL COMPLEX , Bihar',
  'MANAV BHARTI NATIONAL SCHOOL , Bihar',
  'Marwari college , Bihar',
  'Model school , Bihar',
  'MODERN PUBLIC SCHOOL, Bihar',
  'Mother Teresa Vidyapeeth, Bihar',
  'N.N COLLEGE , Bihar',
  'Nardiganj college , Bihar',
  'National institute of open schooling, Bihar',
  'NEZAMIA PUBLIC SCHOOL, Bihar',
  'Notre Dame Academy , Bihar',
  'open minds birla school, Bihar',
  'ORIENTAL COLLEGE, Bihar',
  'P T J M S VIDYA MANDIR, Bihar',
  'p.c. high school, Bihar',
  'param gyan niketan, Bihar',
  'PARAMOUNT ACADEMY , Bihar',
  'PARK MOUNT PUBLIC SCHOOL, Bihar',
  'PATLIPUTRA CENTRAL SCHOOL, Bihar',
  'Phoolpati Devi singhldev Singh inter college, Bihar',
  'pr. B.P SINGH COLLEGE, Bihar',
  'Principal bp Singh College, Bihar',
  'Pristine childrens high school, Bihar',
  'PRMUS PUBLIC SCHOOL, Bihar',
  'Progressive Central High School, Bihar',
  'PROJECT GIRLS HIGH SCHOOL, Bihar',
  'PUBLIC SCHOOL , Bihar',
  'Purnea college , Bihar',
  'R K K COLLEGE , Bihar',
  'R. H S high school sah inter college , Bihar',
  'R. R. Ashok High School , Bihar',
  'R.K. HIGH SCHOOL, Bihar',
  'R.k.b inter college, Bihar',
  'R.L.S.Y.INTER COLLEGE, Bihar',
  'R.P.B.D INTER COLLEGE , Bihar',
  'R.P.S.PUBLIC SCHOOL, Bihar',
  'R.R Sah college , Bihar',
  'R.S.S. SCIENCE COLLEGE , Bihar',
  'Radiant international School, Bihar',
  'Raja ram sah college, Bihar',
  'Rajdeo singh college, Bihar',
  'RAJKIYAKRIT SR SECONDARY SCHOOL , Bihar',
  'Rajnandan Prasad s.s. school , Bihar',
  'RAJO SINGH COLLEGE, Bihar',
  'Raju singh college , Bihar',
  'RAM DAYAL SUBHNARAYAN SENIOR SECONDARY SCHOOL, Bihar',
  'Ram jagal shingh collage , Bihar',
  'RED CARPET HIGH SCHOOL, Bihar',
  'Regional secondary school, Bihar',
  'Resonance international school, Bihar',
  'Routa high school, Bihar',
  'RPS school, Bihar',
  'RSM PUBLIC SCHOOL , Bihar',
  'S .T .S.V SR. SEC. INTERNATIONAL SCHOOL , Bihar',
  'S G S M S M N N J COLLEGE , Bihar',
  'S K P VIDYA VIHAR, Bihar',
  'S N S COLLEGE, Bihar',
  'S R vidya mandir, Bihar',
  'S.A.E COLLEGE J, Bihar',
  'S.B.N COLLEGE, Bihar',
  'S.D Public school, Bihar',
  'S.K.P. VIDYA VIHAR, Bihar',
  'S.P.S.COLLEGE DESRI, Bihar',
  'S.P.V.INTER COLLEGE, Bihar',
  'S.R.PUBLIC SCHOOL, Bihar',
  'Saint joseph school , Bihar',
  'Saint Judes Vidyalaya, Bihar',
  'Sanghmita public school, Bihar',
  'SANSKAR BHARTI GLOBAL SCHOOL , Bihar',
  'Satyam International, Bihar',
  'School kesath, Bihar',
  'School of creative learning, Bihar',
  'SCOTTISH PUBLIC SCHOOL, Bihar',
  'sec delhi public school, Bihar',
  'Senior sec.school, Bihar',
  'Shanti M Academy , Bihar',
  'Shanti Mission Academy, Bihar',
  'Shantiniketan Jublee School, Bihar',
  'SHATABADI PUBLIC SCHOOL, Bihar',
  'SHIVAM CONVENT, Bihar',
  'Shivam residential public school, Bihar',
  'Shivam School , Bihar',
  'Shivam Vidyamandir, Bihar',
  'SHREE RAM RATAN SHAHI HIGH SCHOOL, Bihar',
  'SINHA MODEL HIGHER SECONDARY SCHOOL, Bihar',
  'skm plus2 high school, Bihar',
  'SKP VIDYA VIHAR, Bihar',
  'SMD INTER COLLEGE, Bihar',
  'Snatak mahavidyalay , Bihar',
  'South Point Public School, Bihar',
  'SPV INTER COLLEGE, Bihar',
  'SR public school, Bihar',
  'Sri guru govind singh college, Bihar',
  'SRI.Y.R.SR.SEC.SCHOOL, Bihar',
  'Srikant babu bindu singh sr. sec.sch, Bihar',
  'SRS VIDYA MANDIR , Bihar',
  'SS PUBLIC SCHOOL, Bihar',
  'ST ANNES HIGH SCHOOL, Bihar',
  "St John's Academy, Bihar",
  "St Joseph's Public School, Bihar",
  "St karen's high school, Bihar",
  "St Michael's High School, Bihar",
  'St Xavier JrSr school, Bihar',
  "St Xavier's High school , Bihar",
  "St. Joseph's public school, Bihar",
  "St. Michael's High School, Bihar",
  'St. Paul High School, Bihar',
  'ST. XAVIER SENIOR JUNIOR SCHOOL, Bihar',
  "ST. XAVIER'S HIGH SCHOOL, Bihar",
  'ST.PAUL SR. SEC. SCHOOL , Bihar',
  'St.xaviers english high school, Bihar',
  'Sushant public school, Bihar',
  'T.n.b collage, Bihar',
  'Techno Mission International School , Bihar',
  'Teresa International Academy, Bihar',
  'Thakur om Prakash Singh umv college, Bihar',
  'The Earth public school, Bihar',
  'The Indian Public School, Bihar',
  'The Tribhuvan School, Bihar',
  'Trident Public School, Bihar',
  'U C M COLLEGE, Bihar',
  'U T College, Bihar',
  'UCHH VIDYALAYA, Bihar',
  'V J International School, Bihar',
  'VANIJYA INTER COLLEGE, Bihar',
  'Vikash Vidyalaya, Bihar',
  'Vivekanand Mission School , Bihar',
  'VJ International School, Bihar',
  'Woodbine modern school, Bihar',
  'ZA Islamia College, Bihar',
  'ZILA SCHOOL , Bihar',
  'ADARSH VIDYALAYA , Chhattisgarh',
  'AMRESH SHARMA PUBLIC SCHOOL, Chhattisgarh',
  'AMRITA VIDYALAYAM , Chhattisgarh',
  'ASHOKA PUBLIC SCHOOL, Chhattisgarh',
  'B.S.P. Senior Secondary school, Chhattisgarh',
  'B.S.S. PRANVANANDA ACADEMY , Chhattisgarh',
  'Bal Bharti Public School, Chhattisgarh',
  'Brighton international School, Chhattisgarh',
  'Brilliant Public School, Chhattisgarh',
  'CARE public school, Chhattisgarh',
  'CAREER POINT WORLD SCHOOL, Chhattisgarh',
  'Career Public School, Chhattisgarh',
  'D. A. V. Public School , Chhattisgarh',
  'DELHI PUBLIC SCHOOL , Chhattisgarh',
  'disha school, Chhattisgarh',
  'DPS RISALI BHILAI, Chhattisgarh',
  'Dronacharya Public School, Chhattisgarh',
  'E.M.BHARAT MATA SCHOOL, Chhattisgarh',
  'GOVT.HIGHER SECONDARY SCHOOL, Chhattisgarh',
  'GREEN VALLEY CONVENT SCHOOL, Chhattisgarh',
  'GYAN GANGA EDUCATIONAL ACADEMY , Chhattisgarh',
  'Holy Cross Senior Secondary School , Chhattisgarh',
  'INDU INTERNATIONAL SCHOOL, Chhattisgarh',
  'Jawahar Navodaya Vidyalaya, Chhattisgarh',
  'K.D Public School, Chhattisgarh',
  'KAUTILYA SR SEC SCHOOL , Chhattisgarh',
  'KENDRIYA VIDYALAYA, Chhattisgarh',
  'KRISHNA PUBLIC SCHOOL , Chhattisgarh',
  'LITTLE FLOWER  HIGHER SECONDARY SCHOOL, Chhattisgarh',
  'Loyola School, Chhattisgarh',
  'M.G.M Senior Secondary School, Chhattisgarh',
  'MAHARISHI VIDYA MANDIR , Chhattisgarh',
  'milestone academy, Chhattisgarh',
  'MOTHERS PRIDE SCHOOL, Chhattisgarh',
  'NATIONAL CONVENT SCHOOL, Chhattisgarh',
  'NEW ERA PROGRESSIVE SCHOOL, Chhattisgarh',
  'Nirmal Higher Senior Secondry School, Chhattisgarh',
  'O. P. Jindal School, Chhattisgarh',
  'Prayas govt boys residential school , Chhattisgarh',
  'RYAN INTERNATIONAL SCHOOL, Chhattisgarh',
  'S.t Peter school, Chhattisgarh',
  'Saraswati Gyan Mandir High school , Chhattisgarh',
  'Shakuntala vidyalaya, Chhattisgarh',
  'SHRI SHANKARA VIDYALAYA , Chhattisgarh',
  'St Francis higher secondary school, Chhattisgarh',
  "ST. XAVIER'S HIGH SCHOOL, Chhattisgarh",
  'Swami Vivekanand Sr. Sec. School, Chhattisgarh',
  'Tata D.A.V. Public School, Chhattisgarh',
  'THAMARAI INTERNATIONAL SCHOOL, Chhattisgarh',
  'The Jain International School , Chhattisgarh',
  'ADARSH HIGHER SECONDARY SCHOOL, Goa',
  'DCT Vasantrao Dempo H.S.S of Arts, Science & Commerce, Goa',
  'Dempo Higher Secondary School, Goa',
  'GVMs ADARSH HIGHER SECONDARY SCHOOL, Goa',
  'KENDRIYA VIDYALAYA NO.1, VASCO DA GAMA , Goa',
  'KINGS SCHOOL , Goa',
  'Loyola Higher Secondary School , Goa',
  'MUSHTIFUND ARYAAN HIGHER SECONDARY SCHOOL, Goa',
  'NAVY CHILDREN SCHOOL, Goa',
  'Sharada Mandir School, Goa',
  'ShriShanta Durga Higher Secondary School, Goa',
  "St Xavier's High school , Goa",
  'Sunshine world wide, Goa',
  'Vasantrao Dempo Higher Sceondary School of Arts, Science & Commerce, Goa',
  'A B SCHOOL, Gujarat',
  'Agarwal Vidya Vihar, Gujarat',
  'AHMEDABAD PUBLIC  SCHOOL, Gujarat',
  'AIR FORCE SCHOOL , Gujarat',
  'AMBE SCHOOL, Gujarat',
  'Amrita Vidyalayam, Gujarat',
  'anand niketan , Gujarat',
  'ARMY PUBLIC SCHOOL, Gujarat',
  'Atmiya Vidya Mandir, Gujarat',
  'BAPS SWAMINARAYAN VIDHYA MANDIR, Gujarat',
  'Bharatiya Vidhaya Bhavan, Gujarat',
  'Bright Day School, Gujarat',
  'BRIGHT SCHOOL, Gujarat',
  'BRILIAMT SECONDARY AND HIGHER SECONDARY SCHOOL, Gujarat',
  'citizens school, Gujarat',
  'D.R. AMIN MEMORIAL SCHOOL, Gujarat',
  'DALMIA PUBLIC SCHOOL, Gujarat',
  'DELHI PUBLIC SCHOOL , Gujarat',
  'DHOLAKIYA SCHOOLS, Gujarat',
  'DIVINE CHILD HIGH SCHOOL, Gujarat',
  'Divine life international school, Gujarat',
  'Don Bosco High School, Gujarat',
  'EDUNOVA HIGHER SECONDARY SCHOOL, Gujarat',
  'ESSAR INTERNATIONAL SCHOOL, Gujarat',
  'G. D. Goenka International School, Gujarat',
  'GLOBAL INDIAN INTERNATIONAL SCHOOL, Gujarat',
  'GREEN VALLEY HIGH SCHOOL, Gujarat',
  'GUJARAT PUBLIC SCHOOL, Gujarat',
  'J H Ambani Saraswati Vidyamandir, Gujarat',
  'JAWAHAR NAVODAYA VIDYALAYA , Gujarat',
  'K D AMBANI VIDYAMANDIR, Gujarat',
  'K V EME BARODA, Gujarat',
  'Kaushal vidhyabhavan, Gujarat',
  'KD Ambani Vidyamandir Digvijaygram Jamnagar, Gujarat',
  'KENDRIYA VIDHYALAYA, Gujarat',
  'KOKILABEN DHIRUBHAI AMBANI VIDYAMANDIR, Gujarat',
  'KRISHNA INTERNATIONAL SCHOOL, Gujarat',
  'L.P.Savani Academy, Gujarat',
  'Lalji Mehrotra Lions School, Gujarat',
  'Lancers army school, Gujarat',
  'Little Flower High School, Gujarat',
  'lord krishna school of science, Gujarat',
  'Lourdes  Convent High School, Gujarat',
  'M.G.M Amin and V.N Savani Vallabh Ashram School, Gujarat',
  'M.K. SECONDARY AND HIGHER SECONDARY SCHOOL, Gujarat',
  'Mahapragya Vidya Niketan, Gujarat',
  'Mahdi Higher secondary school, Gujarat',
  'MAHESHWARI VIDYAPEETH, Gujarat',
  'MARY MATHA PUBLIC SCHOOL, Gujarat',
  'METAS ADVENTIST SCHOOL, Gujarat',
  'Mount Litera Zee High School, Gujarat',
  'Munishipal higher secondary school , Gujarat',
  'Nalanda international school, Gujarat',
  'Nand Vidya Niketan Jamnagar, Gujarat',
  'Navrachana International School , Gujarat',
  'New era english medium school, Gujarat',
  'New Era Public School, Gujarat',
  'NEW ERA SENIOR SECONDARY SCHOOL, Gujarat',
  'NIB SCHOOL OF SCIENCE, Gujarat',
  'Nirman High School, Gujarat',
  'OM SHANTI ENGLISH MEDIUM COLLEGE, Gujarat',
  'PODAR INTERNATIONAL SCHOOL , Gujarat',
  'PUBLIC SCHOOL , Gujarat',
  'PUNA  INTERNATIONAL SCHOOL, Gujarat',
  'RIVERDALE ACADEMY, Gujarat',
  'Ryan International School, Gujarat',
  'S D Jain Modern School, Gujarat',
  'S S DIVINE SCHOOL, Gujarat',
  'SADHUVASWANI INTERNATIONAL SCHOOL, Gujarat',
  'Sahjanand School of Achiever, Gujarat',
  'SAKAR ENGLISH SCHOOL, Gujarat',
  'Sanskartirth Gyanpeeth, Gujarat',
  'Sanskruti  Higher secondary school, Gujarat',
  'Sardba high school, Gujarat',
  'SARVODAY HIGH SCHOOL, Gujarat',
  'Satyamevajayate International School, Gujarat',
  'SCHOLARS ENGLISH ACADEMY, Gujarat',
  'Shakti Higher Secondary School, Gujarat',
  'Shannen Kids School, Gujarat',
  'Shanti Asiatic School, Gujarat',
  'SHIV ASHISH SCHOOL, Gujarat',
  'Shree Gayatri Vidyalay, Gujarat',
  'SHREE NARAYANA CETRAL SCHOOL, Gujarat',
  'Shree p k desai Vidhyalaya, Gujarat',
  'SHREE P.V. MODI HIGH SCHOOL, Gujarat',
  'Shree swaminarayan international school, Gujarat',
  'Shree swaminarayan mahavidhya kendra h.s.school, Gujarat',
  'SHRI H J GAJERA HIGHER SECONDARY ENGLISH SCHOOL, Gujarat',
  'SHRI O. P.  JINDAL VIDYA NIKETAN, Gujarat',
  'Shri Ramkrishna Harikrishna Academy, Gujarat',
  'SHRI SWAMINARAYAN SECONDARY AND HIGHER SECONDARY SCHOOL, Gujarat',
  'Shri swaminarayan vidyaveli gyan kendra , Gujarat',
  'SMT Sandraben Shroff Gnyan Dham School , Gujarat',
  'Smt.V.D.(Wadiwala) self Finance High Secondary School , Gujarat',
  'SORARIS PUBLIC SCHOOL, Gujarat',
  'SRS GNYANDHAM SCHOOL, Gujarat',
  'ST ANNS SCHOOL , Gujarat',
  'ST KABIR DRIVE IN, Gujarat',
  'St. Joseph Higher Secondary School , Gujarat',
  'St. Kabir School, Gujarat',
  "ST. XAVIER'S HIGH SCHOOL, Gujarat",
  'super english school, Gujarat',
  'SUV INTERNATIONAL , Gujarat',
  'Swastik school of science, Gujarat',
  'Tapasya Integrated School, Gujarat',
  'THE ADITYA BIRLA PUBLIC SCHOOL, Gujarat',
  'The J M DESAI HIGHSCHOOL , Gujarat',
  'The New Tulip International School, Gujarat',
  'TREE HOUSE HIGH SCHOOL, Gujarat',
  'UDGAM SCHOOL FOR CHILDREN, Gujarat',
  'URMI SCHOOL , Gujarat',
  'Utkarsh school of excellence, Gujarat',
  'Vedant International School, Gujarat',
  'ZYDUS SCHOOL FOR EXCELLENCE, Gujarat',
  'AAROHI MODEL SEN SEC SCHOOL , Haryana',
  'Air Force School , Haryana',
  'Ajanta public school, Haryana',
  'Amity International School, Haryana',
  'AMRAVATI VIDYALAYA, Haryana',
  'APEEJAY SCHOOL , Haryana',
  'APOLLO INTERNATIONAL SCHOOL, Haryana',
  'ARAVALI INTERNATIONAL SCHOOL, Haryana',
  'Army public school, Haryana',
  'Bal Bharati Public School, Haryana',
  'Bal Vikas Progressive School, Haryana',
  'Bansi Vidya Niketan Sr. Sec. School, Haryana',
  'Base Model Sr Sec School , Haryana',
  'BD Memorial Convent School, Haryana',
  'Bhiwani public school , Haryana',
  'BLUE BELLS MODEL SCHOOL, Haryana',
  'C R B V M SR SEC SCHOOL , Haryana',
  'Cambridge school , Haryana',
  'Carmel Convent School , Haryana',
  'CCA School, Haryana',
  "Colonel's Central Academy, Haryana",
  'DAV PUBLIC SCHOOL, Haryana',
  'DAYANAND SCHOOL , Haryana',
  'Delhi Public School, Haryana',
  'DYAL SINGH PUBLIC SCHOOL , Haryana',
  'dynasty international school, Haryana',
  'EICHER SCHOOL, Haryana',
  'Euro international school , Haryana',
  'G D Goenka public school, Haryana',
  'G.R. International school , Haryana',
  'Gaur Sr.sec School, Haryana',
  'Gav Public School , Haryana',
  'Government Senior Secondary School , Haryana',
  'Green Wood Public School, Haryana',
  'GURUKUL KURUKSHETRA, Haryana',
  'HANSRAJ PUBLIC SCHOOL, Haryana',
  'Heritage Xperiential Learning School , Haryana',
  'Holy Child Public School, Haryana',
  'Ishwar international school, Haryana',
  'Jawahar Navodaya Vidyalaya , Haryana',
  'JIVA PUBLIC SCHOOL, Haryana',
  'JP JAIN SR SEC SCHOOL , Haryana',
  'K.R. Mangalam World School, Haryana',
  'KCM WORLD SCHOOL, Haryana',
  'Kendriya Vidyalaya , Haryana',
  'LITTLE ANGELS SCHOOL, Haryana',
  'Lord Jesus Public School , Haryana',
  'Manav Rachna International School, Haryana',
  'MASD PUBLIC SCHOOL, Haryana',
  'Model School, Haryana',
  'MODERN  VIDYA NIKETAN, Haryana',
  'MODERN DELHI PUBLIC SCHOOL , Haryana',
  'Modern School, Haryana',
  'Mohania Devi Memorial Senior Secondary School, Haryana',
  'Mother teresa convent school, Haryana',
  'Nic public school, Haryana',
  'NISHAN PUBLIC SCHOOL , Haryana',
  'O.S.D.A.V.  public school , Haryana',
  'Pathania Public School, Haryana',
  'PDM Public School, Haryana',
  'Pratap Public School, Haryana',
  'R.E.D SCHOOL, Haryana',
  'RABINDRANATH WORLD SCHOOL, Haryana',
  'Raman Munjal Vidya Mandir, Haryana',
  'ROTARY PUBLIC SCHOOL, Haryana',
  'RPS senior Secondary School , Haryana',
  'Ryan International School, Haryana',
  'S D Model Sr Sec School, Haryana',
  'S D PUBLIC SCHOOL, Haryana',
  'Sacred Heart Convent School, Haryana',
  'Saint Haridas Senior Secondary school, Haryana',
  'SAINT THOMAS SCHOOL, Haryana',
  'Salwan Public School, Haryana',
  'Sanatam Dharam Vidya Mandir , Haryana',
  'Saraswati sr. Sec. School, Haryana',
  'SATYUG DARSHAN VIDHALAYA, Haryana',
  'Scottish High International , Haryana',
  'sd memorial Sr sec school, Haryana',
  'Sehwag international school, Haryana',
  'Sh. S.N. Sidheshwar Sr. Sec. Public School, Haryana',
  'Shalom Hills International School, Haryana',
  'Shambhu Dayal Modern School, Haryana',
  'Shiv senior secondary school, Haryana',
  'Shri bala ji sr sec school, Haryana',
  'SHRI KRISHAN SR. SEC. SCHOOL, Haryana',
  'Shri SN Sidheshwar Public School, Haryana',
  'St columbus school , Haryana',
  'St Thomas School, Haryana',
  'ST THOMAS SENIOR SECONDARY SCHOOL, Haryana',
  "St. Crispin's Sr. Sec. School, Haryana",
  "St. Joseph's Convent School , Haryana",
  "ST. MARY'S SENIOR SECONDARY SCHOOL, Haryana",
  "ST. THERESA'S CONVENT SR. SEC. CHOOL, Haryana",
  'STANFORD INTERNATIONAL SCHOOL, Haryana',
  'SUNCITY SCHOOL, Haryana',
  'Suraj School, Haryana',
  'Surajkund International School, Haryana',
  'The Gurukul, Haryana',
  'The heights senior secondary school, Haryana',
  'THE MILLENIUM SCHOOL, Haryana',
  'The Shri Ram School, Haryana',
  'VIDYA MANDIR PUBLIC SCHOOL, Haryana',
  'Vikas International School , Haryana',
  'Yaduvanshi shiksha niketan, Haryana',
  'ACME SR SEC PUBLIC SCHOOL , Himachal Pradesh',
  'Akal Academy , Himachal Pradesh',
  'Army Public school, Himachal Pradesh',
  'B.L. CENTRAL PUBLIC SCHOOL, Himachal Pradesh',
  'Bhartiya Public Senior Secondary School , Himachal Pradesh',
  'BISHOP COTTON SCHOOL, Himachal Pradesh',
  'bluestar school , Himachal Pradesh',
  'CAREER ACADEMY SENIOR SECONDARY SCHOOL, Himachal Pradesh',
  'Dav Centenary public school , Himachal Pradesh',
  'DAV Public School , Himachal Pradesh',
  'DELHI PUBLIC SCHOOL, Himachal Pradesh',
  'Geeta Adarsh Vidyalaya , Himachal Pradesh',
  'Guru Nanak Public School, Himachal Pradesh',
  'GURUKUL PUBLIC SENIOR SECONDARY SCHOOL , Himachal Pradesh',
  'HIMALYAN PUBLIC SCHOOL , Himachal Pradesh',
  'JAWAHAR NAVODAYA VIDYALAYA , Himachal Pradesh',
  'laksahy public school, Himachal Pradesh',
  'M.R.A.D.A.V. public school , Himachal Pradesh',
  'PINEGROVE SCHOOL, Himachal Pradesh',
  'rainbow international school , Himachal Pradesh',
  'Sacred Heart Senior Secondary School, Himachal Pradesh',
  'SAINT XAVIERS RESIDENTIAL SCHOOL , Himachal Pradesh',
  'St Thomas School , Himachal Pradesh',
  'Stokes memorial School, Himachal Pradesh',
  'Vardhman Mahavir public school , Himachal Pradesh',
  'Army Public School , Jammu and Kashmir',
  'B. N. Hr. Sec. School, Jammu and Kashmir',
  'Boys hr sec school , Jammu and Kashmir',
  'DELHI PUBLIC SCHOOL , Jammu and Kashmir',
  'G D Goenka public school , Jammu and Kashmir',
  'Government Girls Higher Secondary School , Jammu and Kashmir',
  'Government higher secondary School , Jammu and Kashmir',
  'Government model higher secondary school , Jammu and Kashmir',
  'Govt Boys Higher Secondary School, Jammu and Kashmir',
  'Govt. Higher secondary school, Jammu and Kashmir',
  'Jodhamal Public school, Jammu and Kashmir',
  'KC International School, Jammu and Kashmir',
  'KC Public School, Jammu and Kashmir',
  'Kendriya Vidyalaya, Jammu and Kashmir',
  'SAINIK SCHOOL , Jammu and Kashmir',
  'The Heritage School, Jammu and Kashmir',
  'ADARSH VIDYA MANDIR , Jharkhand',
  'AMANAT ALI INTER COLLEGE, Jharkhand',
  'ARMY PUBLIC SCHOOL, Jharkhand',
  'ATOMIC ENERGY CENTRAL SCHOOL, Jharkhand',
  'BARHI INTER COLLEGE , Jharkhand',
  'Bns dav public school , Jharkhand',
  'BOKARO PUB SCH , Jharkhand',
  'BRL DAV Public School , Jharkhand',
  'Cambrian public school, Jharkhand',
  'CARMEL JUNIOR COLLEGE, Jharkhand',
  'CENTRAL ACADEMY, Jharkhand',
  'CHINMAYA VIDYALAYA , Jharkhand',
  'CHURCH SCHOOL , Jharkhand',
  'CSD INTER COLLEGE, Jharkhand',
  'D.A.V Public School, Jharkhand',
  'D.B.M.S ENGLISH SCHOOL , Jharkhand',
  'DAV Centenary public school , Jharkhand',
  'DBMS.ENGLISH SCHOOL, Jharkhand',
  'DELHI PUBLIC SCHOOL, Jharkhand',
  'Denobili school, Jharkhand',
  'doon public school, Jharkhand',
  'Gulmohur High School , Jharkhand',
  'GURU GOBIND SINGH PUBLIC SCHOOL, Jharkhand',
  'Guru Nanak Higher Secondary School , Jharkhand',
  'HILL TOP SCHOOL , Jharkhand',
  'HOLY CROSS SCHOOL , Jharkhand',
  'International public school, Jharkhand',
  'JAMSHEDPUR PUBLIC SCHOOL, Jharkhand',
  'JASIDIH PUBLIC SCHOOL, Jharkhand',
  'Jawahar Vidya Mandir, Jharkhand',
  'Jawaharlal Nehru Senior Secondary Memorial School , Jharkhand',
  'JJ COLLEGE , Jharkhand',
  'JUSCO SCHOOL, Jharkhand',
  'Kairali school, Jharkhand',
  'KASIDIH HIGH SCHOOL, Jharkhand',
  'Kendriya vidyalaya , Jharkhand',
  'Kerala Public School, Jharkhand',
  'KOLHAN INTER COLLEGE, Jharkhand',
  'LA garden high school, Jharkhand',
  'Lala Lajpat Rai senior secondary high school, Jharkhand',
  'Little Flower School, Jharkhand',
  'Loyola Convent School, Jharkhand',
  'Motilal Nehru Public School, Jharkhand',
  'Munam public school, Jharkhand',
  'Narbheram Hansraj English School, Jharkhand',
  'National Institute of Open Schooling, Jharkhand',
  'OXFORD PUBLIC SCHOOL , Jharkhand',
  'Pitts modern school, Jharkhand',
  'RAJ KAMAL SARASWATI VIDYA MANDIR , Jharkhand',
  'RAJENDRA VIDYALAYA, Jharkhand',
  'Rajiv Gandhi intermediate College, Jharkhand',
  'Ram Lakhan Singh Yadav inter college, Jharkhand',
  'Ramakrishna mission vidyapith, Jharkhand',
  'RED ROSE PUBLIC SR. SEC. SCHOOL, Jharkhand',
  'Sacred Heart Convent School, Jharkhand',
  'Saint Augustine high school, Jharkhand',
  'SANT NANDLAL SMRITI VIDYA MANDIR, Jharkhand',
  'SANT XAVIERS INTER COLLEGE MAHARO DUMKA, Jharkhand',
  'SDSM SCHOOL FOR EXCELLENCE, Jharkhand',
  'sindri collage , Jharkhand',
  'Sree ayyappa public school , Jharkhand',
  'Sri krishna public school, Jharkhand',
  'St Francis School , Jharkhand',
  'St Jagat Gyan Public School, Jharkhand',
  'St Josephs convent high school, Jharkhand',
  'ST MARY SCHOOL, Jharkhand',
  "ST MARY'S ENGLISH HIGH SCHOOL , Jharkhand",
  'ST THOMAS HIGH SCHOOL, Jharkhand',
  'St. Xavier College, Jharkhand',
  'St.Francis school , Jharkhand',
  'St.thomas high school, Jharkhand',
  'Star International School, Jharkhand',
  'Subhash Public School, Jharkhand',
  'SURENDRANATH CENTENARY SCHOOL, Jharkhand',
  'Tarapore school agrico, Jharkhand',
  'TENDER HEART SCHOOL, Jharkhand',
  'TPS DAV PUBLIC SCHOOL , Jharkhand',
  'Valley View School, Jharkhand',
  'VIDYA BHARATI CHINMAYA VIDYALAYA, Jharkhand',
  'Vikas Vidyalaya , Jharkhand',
  'Vivekanand Vidya Mandir, Jharkhand',
  'AMBIKA PADAVI POORVA VIDYALAYA , Karnataka',
  'AMC Brooklyn PU College, Karnataka',
  'AMITY INTERNATIONAL SCHOOL, Karnataka',
  'AMRITA VIDYALAYAM, Karnataka',
  'Amrutha Varshini Vidyalaya, Karnataka',
  'Anmol science PU college, Karnataka',
  'ANWESHANA PU COLLAGE, Karnataka',
  'ARMY PUBLIC SCHOOL , Karnataka',
  'ARYABHATA COLLEGE OF SCIENCE , Karnataka',
  'Ashok composite pu college, Karnataka',
  'ATOMIC ENERGY CENTRAL SCHOOL, Karnataka',
  'B.E.L composite P.U college , Karnataka',
  'BANAJAWAD RESIDENCIAL P.U COLLEGE , Karnataka',
  'BANGALORE INTERNATIONAL ACADEMY, Karnataka',
  'BASE P U COLLEGE, Karnataka',
  'BEL PRE UNIVERSITY COLLEGE, Karnataka',
  'BELLARY INDEPENTEND PU COLLEGE, Karnataka',
  'bendre pu science college , Karnataka',
  'BET  pre-university, Karnataka',
  'BET PU college, Karnataka',
  'Bethany High School , Karnataka',
  'BGS INDEPENDENT PU COLLEGE, Karnataka',
  'BGS INTERNATIONAL RESIDENTIAL SCHOOL, Karnataka',
  'BGS NATIONAL PUBLIC SCHOOL, Karnataka',
  'BISHOP COTTON BOYS SCHOOL, Karnataka',
  "Bishop Cotton Girls' School, Karnataka",
  'BMSPU college for women, Karnataka',
  'BNM Public  School / Sri Kumarans PU College , Karnataka',
  'Brilliant Pre University, Karnataka',
  "Bunt's Sangha R.N.S composite pu college, Karnataka",
  'Cambridge PU College, Karnataka',
  'CANARA PU COLLEGE, Karnataka',
  'Carmel convent pu college, Karnataka',
  'carmel convent school, Karnataka',
  'Carmel English high school, Karnataka',
  'CARMEL PU COLLEGE, Karnataka',
  'Cathedral comp pu collage, Karnataka',
  'CHETAN PU SCIENCE COLLEGE , Karnataka',
  'Chetana P. U. College, Karnataka',
  'Chinmaya vidyalaya , Karnataka',
  'chirst academy junior college, Karnataka',
  'City college , Karnataka',
  'City PU College, Karnataka',
  'Clarence High School, Karnataka',
  'Cluny Convent High School, Karnataka',
  'CMR National PU College, Karnataka',
  'Coorg Pre-University college, Karnataka',
  'Daksha PU college, Karnataka',
  'DAV PUBLIC SCHOOL, Karnataka',
  'De Paul International ResidentialSchool, Karnataka',
  'Deccan International School, Karnataka',
  'DEEKSHA C F L PU COLLEGE, Karnataka',
  'Deeksha Center for Learning, Karnataka',
  'Deens Academy , Karnataka',
  'DELHI PUBLIC SCHOOL , Karnataka',
  'DON BOSCO HSS , Karnataka',
  'Dr. R. B. Patil Mahesh Pre - University College, Karnataka',
  'EBENEZER INTERNATIONAL RESIDENTIAL SCHOOL AND JUNIOR COLLEGE, Karnataka',
  'Ekya School ITPL, Karnataka',
  'Excel Public School, Karnataka',
  'EXCELLENT PU COLLEGE , Karnataka',
  'EXCELLENT SCIENCE AND COMMERCE PU COLLEGE, Karnataka',
  'EXPERT PRE UNIVERSITY, Karnataka',
  'Expert Pre University College , Karnataka',
  'EXPERT PU COLLEGE , Karnataka',
  'FREEDOM INTERNATIONAL SCHOOL, Karnataka',
  'G.R.V PU college, Karnataka',
  'GEAR Innovative Intl. School, Karnataka',
  'Geethanjali Vidyalaya, Karnataka',
  'GM HALAMMA PU COLLEGE, Karnataka',
  'Gnaaneshraj.S.B, Karnataka',
  'Golden city PU College , Karnataka',
  'GOPALAN PU COLLEGE, Karnataka',
  'GOPALASWAMY INDEPENDENT PU COLLEGE, Karnataka',
  'GOPALASWAMY SHISHUVIHARA INDEPENDENT PU COLLEGE, Karnataka',
  'Government PU college , Karnataka',
  'Govindram sekseria science pu college, Karnataka',
  'GOVT PU COLLEGE , Karnataka',
  'Govt.boys pucollege, Karnataka',
  'Greenwood High International School, Karnataka',
  'GRV pre university college , Karnataka',
  'GRV pu college, Karnataka',
  'Gurukul independent PU college , Karnataka',
  'HAL Gnanajyothi School, Karnataka',
  'HAL PUBLIC SCHOOL, Karnataka',
  'HARVEST INTERNATIONAL SCHOOL, Karnataka',
  'Himamshu Jyothi Kala Peetha Preuniversity College, Karnataka',
  'HMR International School, Karnataka',
  'HOLY SPIRIT SCHOOL & PU COLLEGE, Karnataka',
  'Hoysala Pre University College , Karnataka',
  'Hymamshu Jyothi Kala Peetha Composite Pre-University College, Karnataka',
  'Indiranagar composite pu college, Karnataka',
  'inventure Academy, Karnataka',
  'J S S Public School, Karnataka',
  'jain  pu college, Karnataka',
  'Jawahar navodaya vidyalaya , Karnataka',
  'Jindal girls pre-unversity college, Karnataka',
  'JINDAL VIDYA MANDIR, Karnataka',
  'JNANA SWEEKAR PU COLLEGE, Karnataka',
  'JNANADEEPA EXPERT COLLEGE, Karnataka',
  'Jnanadeepa Senior Secondary school, Karnataka',
  'JNANASAROVARA INTERNATIONAL RESIDENCE SCHOOL , Karnataka',
  'Jnanodaya PU College, Karnataka',
  'JSS PUBLIC SCHOOL, Karnataka',
  'JUBILEE COMPOSITE PU COLLEGE, Karnataka',
  'Jyoti Nivas PU College, Karnataka',
  'KENDRIYA VIDYALAYA, Karnataka',
  'Kensri School and College, Karnataka',
  'KESHAVA REDDY EM HS , Karnataka',
  'KH Patil PU Science College, Karnataka',
  'kle gi bagewadi college , Karnataka',
  'KLE independent PU college, Karnataka',
  "KLE Society's Prerana PU College, Karnataka",
  'knc innovative pu college, Karnataka',
  'krupanidhi college, Karnataka',
  'KVG Amarajyothi PU College, Karnataka',
  'LIS GOWRIBIDANUR, Karnataka',
  'Little Rock Indian School, Karnataka',
  'LOURDES CENTRAL SCHOOL, Karnataka',
  'M.E.S PU COLLEGE FOR SCIENCE COMMERCE &ARTS, Karnataka',
  'M.E.S.Kishora Kendra pre University college, Karnataka',
  'Madhava Kripa school , Karnataka',
  'MAHARANI LAKSHMI AMMANNI WOMEN PU COLLEGE, Karnataka',
  'MAHARISHI VIDYA MANDIR , Karnataka',
  "MAHATMA CHILDREN'S HOME , Karnataka",
  'Mahesh Pre - University College, Karnataka',
  'Manasarowar pushkarini vidyashrama pu college, Karnataka',
  'MANGALORE INDEPENDENT PU COLLEGE, Karnataka',
  'Masters PU college, Karnataka',
  'MES KISHORE KENDRA PU COLLEGE , Karnataka',
  'mes mpl sastry pu college, Karnataka',
  'MES Prof.B.R. SUBBA RAO PRE UNIVERSITY COLLEGE, Karnataka',
  'MES PU COLLEGE, Karnataka',
  'Miranda Composite PU college , Karnataka',
  'MORARJI DESAI RESIDENTIAL SCHOOL, Karnataka',
  'mother teresa pu college, Karnataka',
  'mount carmel college, Karnataka',
  'MOUNT CARMEL PU COLLEGE, Karnataka',
  'NAGACHETANA PU COLLEGE, Karnataka',
  'NAGARJUNA VIDYANIKETAN, Karnataka',
  'Nalanada international independent pu collage, Karnataka',
  'Nandi international collage, Karnataka',
  'narayana vidyaranipura, Karnataka',
  'Narayanan educational institutions , Karnataka',
  'Naryana e techno school, Karnataka',
  'Naryana PU College, Karnataka',
  'National Academy For Learning , Karnataka',
  'national center for excellence, Karnataka',
  'NATIONAL CENTRE FOR EXCELLENCE , Karnataka',
  'NATIONAL PUBLIC SCHOOL, Karnataka',
  'Navkis Educational center, Karnataka',
  'NCFE PRE-UNIVERSITY COLLEGE, Karnataka',
  'NEHARU SMARAKA VIDYALAYA, Karnataka',
  'Nehru Smaraka Vidyalaya, Karnataka',
  'nehuru smaraka vidyalaya  jayanagar  bangalour, Karnataka',
  'New  baldwin pu college, Karnataka',
  'New Horizon Public School, Karnataka',
  'NEW SRI CHAITANYA PU COLLEGE, Karnataka',
  'NITTE INTERNATIONAL SCHOOL, Karnataka',
  'NPS International School, Karnataka',
  'NPS Koramangala, Karnataka',
  'NTR PRE-UNIVERSITY COLLEGE, Karnataka',
  'NTR PU College, Karnataka',
  'P C jabin college, Karnataka',
  'PANA PU COLLEGE, Karnataka',
  'Pandits The Orchids PU College, Karnataka',
  'PARIVARTHANA PU COLLEGE, Karnataka',
  'Perana Pu College, Karnataka',
  'pes pre-university college, Karnataka',
  'PODAR INTERNATIONAL SCHOOL, Karnataka',
  'Poornaprajna PU College, Karnataka',
  'PRAMATI HILLVIEW ACADEMY, Karnataka',
  'Premier Science & Commerce PU College, Karnataka',
  'PRESIDENCY JR COLLEGE, Karnataka',
  'Primus Public School, Karnataka',
  'PRISM P.U. SCIENCE COLLEGE, Karnataka',
  'PSBB LEARNING LEADERSHIP ACADEMY, Karnataka',
  'R V Pre University College, Karnataka',
  'R V PU COLLEGE, Karnataka',
  'Rajajinagar  composite pu college, Karnataka',
  'RAMAIAH PU COLLEGE, Karnataka',
  'rambapuri pu college, Karnataka',
  'Red Bridge  International Academy , Karnataka',
  'Reva Independent PU College, Karnataka',
  'RNS PU College , Karnataka',
  'Royal composite Pu college, Karnataka',
  'ROYALE CONCORDE INTERNATIONAL SCHOOL, Karnataka',
  'ROYALE CONCORDE PU COLLEGE, Karnataka',
  'RV PU College, Karnataka',
  'Ryan International PU college , Karnataka',
  'S Basaveshwar Resi PU Collge , Karnataka',
  'S J E S COMPOSITE PU COLLEGE, Karnataka',
  's v pre university college, Karnataka',
  'S.D.M. RESIDENTIAL PREUNIVERSITY COLLEGE, Karnataka',
  'S.V.E.I.COMPOSITE P.U.COLLEGE, Karnataka',
  'SACHETAN PU SCIENCE COLLEGE , Karnataka',
  'Sacred Heart Girls pu college, Karnataka',
  'SADHANA P.U. COLLEGE, Karnataka',
  'sadvidya coposite pu college, Karnataka',
  'Sadvidya Semi Residential PU College, Karnataka',
  'SAMARTH INDIPENDENT RRE UNIVERSITY SCIENCE COLLEGE , Karnataka',
  'Samruddhi pu college, Karnataka',
  'Sanganabasava international residential pu college, Karnataka',
  'Sarala Birla Academy, Karnataka',
  'Sarswathi high school mundaje, Karnataka',
  'Sarvodaya pu college, Karnataka',
  'satyam PU College, Karnataka',
  'SBGNS RURAL COMPOSITE COLLEGE, Karnataka',
  'SBRR MAHAJANA PU COLLEGE, Karnataka',
  'SDM RESIDENTIAL PU COLLEGE, Karnataka',
  'secab pu college for boys, Karnataka',
  'SESHADRIPURAM PU COLLEGE , Karnataka',
  'Sevabharathi Composite PU College, Karnataka',
  'SFS PUBLIC SCHOOL, Karnataka',
  'SGPTA PU college, Karnataka',
  'Shakuntala Devi pu college, Karnataka',
  'shantiniketan trust school, Karnataka',
  'sharada indp  pu college , Karnataka',
  'SHARADA PRE UNIVERSITY COLLEGE , Karnataka',
  'Sharadha vidyamandir pu college, Karnataka',
  'SHARANBASVESHWAR RESIDENTIAL COMPOSITE PU COLLEGE, Karnataka',
  'Sharnbasveshwar comp pu college , Karnataka',
  'Shishya BEML Public School, Karnataka',
  'Shree swaminarayan gurukul international school , Karnataka',
  'Shree Swaminarayan Residential PU College, Karnataka',
  'Shree Vedanta PU College , Karnataka',
  'Shreeyam pu college of arts science and commerce, Karnataka',
  'SHRI CHAITANYA PU COLLEGE , Karnataka',
  'shri kumarans composite pu college, Karnataka',
  'Siddhartha PU college, Karnataka',
  'Sindhi high school, Karnataka',
  'Sindhi PU College, Karnataka',
  'SIR CHAITANYA PU COLLAGE, Karnataka',
  'sir m visvesvaraya pu college, Karnataka',
  'SMIORE VYASAPURI COMPOSITE PU COLLEGE, Karnataka',
  'smt danamma chanabasaviah independent pu college, Karnataka',
  'Smt Vidya P Hanchinmani PU science College, Karnataka',
  'SMT. Pushpamahalingappa independent science PU college, Karnataka',
  'Smt. Yalamanchili janata Rani pu college, Karnataka',
  'SMT.PUSHPA SHAMNUR MAHALINGAPPA PU SCIENCE COLLEGE, Karnataka',
  'Sophia High School, Karnataka',
  'Soundarya Pre university , Karnataka',
  'Spsm pu college, Karnataka',
  'SRDG put college , Karnataka',
  'SREE AYYAPPA EDUCATION CENTRE , Karnataka',
  'Sree Cauvery School, Karnataka',
  'Sri  Chaitanya  CBSE school, Karnataka',
  'sri  chaitanya  jr college, Karnataka',
  'Sri  kumaran childrens Home, Karnataka',
  'Sri  Vidyamandir Ind PU College , Karnataka',
  'SRI ADICHUNCHANAGIRI INDEPENDENT PU COLLEGE, Karnataka',
  'Sri AUROBINDO MEMORIAL SCHOOL, Karnataka',
  'Sri Aurobindo PU college, Karnataka',
  'Sri Bhagavan Mahaveer Jain College , Karnataka',
  'SRI CHAINTANYA CBSE SCHOOL, Karnataka',
  'sri chaitanaya college , Karnataka',
  'Sri jayachamarajendra pre university college , Karnataka',
  'Sri Krishna PU College, Karnataka',
  'SRI KSPL PU COLLEGE, Karnataka',
  "Sri Kumaran Children's Home composite Pre-University college, Karnataka",
  'SRI LAKSHMI HAYAGREEVI COLLEGE , Karnataka',
  'Sri Maruthi PU College, Karnataka',
  'Sri Sadguru PU college, Karnataka',
  'sri sadguru vidya mandira , Karnataka',
  'Sri Sai angles PU college, Karnataka',
  'SRI SARASWATHI Vidya Niketan English High school , Karnataka',
  'SRI SATHYA SAI PU COLLEGE , Karnataka',
  'Sri Siddaganga PU College, Karnataka',
  'SRI SRI RAVISHANKAR VIDYA MANDIR BANGALORE EAST, Karnataka',
  'Sri Vani Education Centre, Karnataka',
  'Sri Venkateshwara College, Karnataka',
  'Sri Venkateshwara Pre University collage, Karnataka',
  'Sri Vidya Kendra , Karnataka',
  'Sri vidya mandir Pre University College, Karnataka',
  'Sri vidyaniketan pu college, Karnataka',
  'Srichaitanya College, Karnataka',
  'SRICHAITANYA JR KALASHALA , Karnataka',
  'Srichaitanya pu college, Karnataka',
  "St  Joseph's Pre University College, Karnataka",
  'St . Francis pu college, Karnataka',
  'st aloysious pu college, Karnataka',
  'ST ANNES GIRLS PU COLLEGE, Karnataka',
  'St claret PU college, Karnataka',
  "ST JEROME'S HSS, Karnataka",
  'St Jeromes Pu and Degree College, Karnataka',
  'St joseph indian composite college, Karnataka',
  'ST PAULS ENGLISH SCHOOL, Karnataka',
  "St Philomena's pu collage, Karnataka",
  'St. Francis Composite P.U. College, Karnataka',
  "St. Germain's Institutions, Karnataka",
  "St. Jerome's PU College, Karnataka",
  "St. Patrick's Academy, Karnataka",
  'St. Vincent Pallotti School, Karnataka',
  "St.Anne's pu college for girls, Karnataka",
  'ST.CLARET PU COLLEGE, Karnataka',
  'ST.JOHNS SCIENCE PU COLLEGE, Karnataka',
  "St.Joseph's Boys' High School, Karnataka",
  'St.PHILOMENAS P U COLLEGE, Karnataka',
  'SUNDARI ANANDA ALVA MEMORIAL PU COLLEGE, Karnataka',
  'SUNRISE ENGLISH PRIVATE SCHOOL, Karnataka',
  'surabhi pu college, Karnataka',
  'Surana ind pu college , Karnataka',
  'SVG VISHWAPRAJNA COMPOSITE PU COLLEGE , Karnataka',
  'SVVN PU COLLEGE, Karnataka',
  'SWARGARANI SCHOOL, Karnataka',
  'Talent pu college, Karnataka',
  'THE AMAATRA ACADEMY, Karnataka',
  'The Brigade School, Karnataka',
  'The Frank Anthony Public School, Karnataka',
  'the kingdom colleges , Karnataka',
  'The national pu college, Karnataka',
  'The Oxford Senior Secondary School, Karnataka',
  'THE SAMHITA ACADEMY, Karnataka',
  'THE VALLEY SCHOOL , Karnataka',
  'Treamis world school, Karnataka',
  'TUNGAL PU COLLEGE VIJAYAPUR , Karnataka',
  'TUNGAL SCIENCE COMPOSITE PU COLLEGE, Karnataka',
  'Ujjwal vidyalaya, Karnataka',
  'Vaels Ravindra Bharathi Global School, Karnataka',
  'VAGDEVI VILAS SCHOOL, Karnataka',
  'VARAPRADA KADUR PU COLLEGE, Karnataka',
  'VBR PU COLLEGE, Karnataka',
  'Vedantha PU college, Karnataka',
  'VENKAT INTERNATIONAL PUBLIC SCHOOL, Karnataka',
  'Vidhya Jyothi PU College, Karnataka',
  'Vidyodaya public school, Karnataka',
  'Vijaya PU College, Karnataka',
  'Vikasa Pre University College, Karnataka',
  'VIKASANA PU COLLEGE, Karnataka',
  'VIP COLLEGE, Karnataka',
  'VISION PU COLLEGE, Karnataka',
  'Vivekananada Pre University College, Karnataka',
  'VSM g.i.bagewadi, Karnataka',
  'VVS GJ PU College, Karnataka',
  'VVS Sardar Patel High School, Karnataka',
  'VYASA INTERNATIONAL SCHOOL, Karnataka',
  'Vydehi school of Excellence, Karnataka',
  'VYSHNAVI CHETANA PU COLLEGE , Karnataka',
  'WHITEFIELD GLOBAL PRE UNIVERSITY COLLEGE, Karnataka',
  'A J John Memorial High School Chathangotnada, Kerala & Mahe',
  'A K A S G HSS PAYYANUR, Kerala & Mahe',
  'A K G S G H S S PERALASSERY, Kerala & Mahe',
  'A K J M H S S KANJIRAPPALLY  PIN 686507, Kerala & Mahe',
  'A K M H S S KOTTOOR, Kerala & Mahe',
  'A M H S S THIRUMALA, Kerala & Mahe',
  'A R R PUBLIC SCHOOL NETTAYAM THIRUVANANTHAPURAM KERALA, Kerala & Mahe',
  'A S H S S PARIPPALLY, Kerala & Mahe',
  'A V  HSS PONNANI, Kerala & Mahe',
  'A V S GOVT HIGHER SECONDARY SCHOOL KARIVELLUR , Kerala & Mahe',
  'A.K.M.H.S.S. Kottoor, Kerala & Mahe',
  'A.M.M.H.S.S,Edayaranmula, Kerala & Mahe',
  'ABDUL SALAM RAFI RES SCHOOL,TRIVANDRUM,KERALA, Kerala & Mahe',
  'Abu Dhabi Indian School , Kerala & Mahe',
  'ADARSHA VIDYA BHAVAN NORTH PARAVUR, Kerala & Mahe',
  'AEPMHSS Irumpanangadu, Kerala & Mahe',
  'AHALIA PUBLIC SCHOOL KOZHIPPARA, Kerala & Mahe',
  'AHSS Vllikunnam, Kerala & Mahe',
  'AIRPORT SENIOR SECONDARY SCHOOL, Kerala & Mahe',
  'Aiswarya public school, kollam, Kerala & Mahe',
  'AKASGVHSS PAYYANUR, Kerala & Mahe',
  'AKG MGHSS PINARAYI, Kerala & Mahe',
  'AKJM HSS, Kerala & Mahe',
  'AKNMMAM HSS KATTUKULAM, Kerala & Mahe',
  'AL AMEEN CENTRAL SCHOOL PULIYUR KARUNAGAPPALLY, Kerala & Mahe',
  'Al Ameen Public school Edappally, Kerala & Mahe',
  'AL FAROOK RESIDENTIAL SENIOR SECONDARY SCHOOL, Kerala & Mahe',
  'Al huda english school, Kerala & Mahe',
  'AL IHSAN ENGLISH HIGHER SECONDARY SCHOOL VENGARA, Kerala & Mahe',
  'Al Irshad English school, killimangalam, Kerala & Mahe',
  'Al manar hss, Kerala & Mahe',
  'Al-Ameen Public School, Kerala & Mahe',
  'Al-Azhar English Medium School, Kerala & Mahe',
  'ALAN FELDMAN PUBLIC SCHOOL, Kerala & Mahe',
  'Alfeen Public School, Kerala & Mahe',
  'ALL SAINTS PUBLIC SCHOOL ADOOR, Kerala & Mahe',
  'ALPHONSA SCHOOL THAMARASSERY, Kerala & Mahe',
  'AMALAMBIKA CONVENT ENGLISH SCHOOL, THEKKADY, IDUKKI, Kerala & Mahe',
  'AMMHSS EDAYARANMULA, Kerala & Mahe',
  'AMRIITA COLLEGE KANHANGAD, Kerala & Mahe',
  'AMRITA HSS, VALLIKUNNAM, Kerala & Mahe',
  'AMRITA SANSKRIT HSS PARIPPALLY, Kerala & Mahe',
  'AMRITA VIDYALAYA THALASSERY, Kerala & Mahe',
  'AMRITA VIDYALAYAM AMRITA NAGAR KAIMANAM TRIVANDRUM KERALA, Kerala & Mahe',
  'AMRITA VIDYALAYAM AMRITA NAGAR, KAKKAD, KANNUR, Kerala & Mahe',
  'AMRITA VIDYALAYAM CHAVAKKADU, Kerala & Mahe',
  'AMRITA VIDYALAYAM KODUNGALLUR, Kerala & Mahe',
  'AMRITA VIDYALAYAM KUTHUPARAMBA, Kerala & Mahe',
  'AMRITA VIDYALAYAM Mananthavady, Kerala & Mahe',
  'AMRITA VIDYALAYAM MANJERI, Kerala & Mahe',
  'AMRITA VIDYALAYAM PALAKKAD, Kerala & Mahe',
  'Amrita Vidyalayam Panchikal Ayyanthol Trichur, Kerala & Mahe',
  'AMRITA VIDYALAYAM PANDALAM, Kerala & Mahe',
  'Amrita vidyalayam perumbavoor , Kerala & Mahe',
  'AMRITA VIDYALAYAM POOKKODE KUTHUPARAMBA KANNUR, Kerala & Mahe',
  'AMRITA VIDYALAYAM PUTHIYAKAVU, Kerala & Mahe',
  'Amrita vidyalayam Thalassery , Kerala & Mahe',
  'AMRITA VIDYALAYAM THRISSUR, Kerala & Mahe',
  'AMRITA VIDYALAYAM, HARIPAD, ALAPPUZHA, Kerala & Mahe',
  'Amrita vidyalayam, kallarakadav, PTA, Kerala & Mahe',
  'Amrita Vidyalayam, Kottekkad Palakkad, Kerala & Mahe',
  'Amrita Vidyalayam, KOZHIKODE, Kerala & Mahe',
  'Amrita Vidyalayam,Kakkad,Kannur, Kerala & Mahe',
  'Amrita vidyalayam,Ottapalam, Kerala & Mahe',
  'AMRITA VIDYALAYAM,PALAKKAD, Kerala & Mahe',
  'Amrita Vidyalayam,pookode, Kerala & Mahe',
  'AMRITA VIDYALAYAMM,THUKALASSERY, Kerala & Mahe',
  'Amrita vishwa vidyapeetham,Amritapuri, Kerala & Mahe',
  'AMRITHA HSS,VALLIKUNNAM,ALAPPUZHA, Kerala & Mahe',
  'AMRITHA KAIRALI VIDYA BHAVAN, Kerala & Mahe',
  'AMRITHA VIDHYALAYAM HARIPAD, Kerala & Mahe',
  'ANGELS ARC ENGLISH MEDIUM SCHOOL KAYAMKULAM , Kerala & Mahe',
  'ARAVIND VIDYAMANDIRAM ANICAD KOTTAYAM, Kerala & Mahe',
  'Army Public School, Cannanore, Kerala & Mahe',
  'ASHSS PARIPPALLY, Kerala & Mahe',
  'ASM Higher Secondary School,Velliyanchery, Kerala & Mahe',
  'ASMMHSS ALATHUR, Kerala & Mahe',
  'ASRAM HSS PERUMBAVOOR, Kerala & Mahe',
  'Assabah Residential Higher Secondary School for Girls, Kerala & Mahe',
  'B H S S KARUNAGAPPALLY KOLLAM, Kerala & Mahe',
  'B J S M MADATHIL H S S THAZHAVA NORTH , Kerala & Mahe',
  'B M M ENGLISH MEDIUM SENIOR SECONDARY SCHOOL, Kerala & Mahe',
  'B P M MODEL SCHOOL  MOONGODU  P O VARKALA TVM KERALA , Kerala & Mahe',
  'B V B VIDYA MANDIR , Kerala & Mahe',
  'B.E.S B.T.V KALEKKAD PALAKKAD, Kerala & Mahe',
  'B.J.S.M MADATHIL V&HSS, Kerala & Mahe',
  'Baker memorial ghss kottayam, Kerala & Mahe',
  'BALIKAMADOM HSS, THIRUMOOLAPURAM, Kerala & Mahe',
  'Bappuji senior secondary school, Kerala & Mahe',
  'Believers Church English Medium School, Kerala & Mahe',
  'BELIEVERS CHURCH RES SCHOOL, KUTTAPUZHA, Kerala & Mahe',
  'Believers Church Residential School, Tiruvalla, Kerala & Mahe',
  'BENHILL ENGLISH SCHOOL IRITTY, Kerala & Mahe',
  'BETHANY ACADEMY THIRUVALLA, Kerala & Mahe',
  'BHARATH VIDYA MANDIR ,VALAPAD, Kerala & Mahe',
  'Bharathamatha Higher Secondary School , Kerala & Mahe',
  'BHARATHAMATHA HIGHER SECONDARY SCHOOL ,PALAKKAD , Kerala & Mahe',
  "Bharathiya Vidya Bhavan's Vidya Mandir ,Kodungalore, Kerala & Mahe",
  "BHARATHIYA VIDYA BHAVAN'S VIDYA MANDIR,POOCHATTY, Kerala & Mahe",
  'Bharathiya Vidya Bhavan, Kodunganoor , Thiruvananthapuram, Kerala & Mahe',
  'BHARATHIYA VIDYA BHAVAN,CHEVAYUR,CALICUT, Kerala & Mahe',
  'Bharathiya vidya bhavan,Thirunavaya,Malappuram, Kerala & Mahe',
  'Bharathiya Vidya Bhavanâ€™s Newsprint Vidyalaya Vellor, Kerala & Mahe',
  'BHARATHMATHA H S S PALAKKAD, Kerala & Mahe',
  'BHARATIYA VIDHYABHAVAN, Kerala & Mahe',
  'Bharatiya vidya bhavan , eravimangalam, Kerala & Mahe',
  'BHARATIYA VIDYA BHAVAN ,ELAMAKKARA, Kerala & Mahe',
  'Bharatiya Vidya Bhavan Chevayur, Kerala & Mahe',
  'Bharatiya Vidya Bhavan Eroor, Kerala & Mahe',
  'BHARATIYA VIDYA BHAVAN PERUMTHIRUTHI, Kerala & Mahe',
  'bharatiya vidya bhavan poochatty P.O eravimangalam thrissur kerala 680751, Kerala & Mahe',
  'Bharatiya Vidya Bhavan School, Kerala & Mahe',
  "Bhavan's Varuna Vidyalaya, Trikkakara, Kerala & Mahe",
  'Bhavana Adarsha Vidyalaya, Kerala & Mahe',
  'Bishop Moore Higher Secondary School, Akkanattukara, Kerala & Mahe',
  'Brahmanandhodayam Higher Secondary School, Kerala & Mahe',
  'BRIGHT PUBLIC SCHOOL, Kerala & Mahe',
  'BVB pottor Thrissur , Kerala & Mahe',
  'BVB Vidya Mandir , Poochatty, Kerala & Mahe',
  'BVB VIVEKANANDA VIDYA MANDIR, MANVILA, PANGAPARA, THIRUVANANTHAPURAM -695581, Kerala & Mahe',
  'C H MOHAMMED KOYA MEM SENIOR SECONDARY SCHOOL, Kerala & Mahe',
  'C H S S CHATTANCHAL, Kerala & Mahe',
  'CARMEL H S S CHALAKUDY, Kerala & Mahe',
  'CATHOLICATE HIGHER SECONDARY SCHOOL, PATHANAMTHITTA, Kerala & Mahe',
  'CBM HSS NOORANAD, ALAPPUZHA, Kerala & Mahe',
  'CCM HSS KARIKKATTOOR, Kerala & Mahe',
  'CCPLM ANGLO INDIAN HSS PERMANOOR, Kerala & Mahe',
  'CE.Bharathan Higher Secondary School Mahe, Kerala & Mahe',
  'CGHSS VADAKKENCHERRY, Kerala & Mahe',
  'Chakkalakkal higher secondary school , Kerala & Mahe',
  'CHALAVARA HIGHER SECONDARY SCHOOL, Kerala & Mahe',
  'Chaldean Syrian Higher Secondary School, Kerala & Mahe',
  'CHATTANCHAL HIGHER SECONDARY SCHOOL, Kerala & Mahe',
  'CHAVAR PUBLIC SCHOOL,PALA, Kerala & Mahe',
  'Chavara CMI International School, Kerala & Mahe',
  'Chavara Public School Pala, Kottyam, Kerala & Mahe',
  'CHAVARA PUBLIC SCHOOL, PALA, Kerala & Mahe',
  'CHENTRAPPINI HSS CHENTRAPPINNI, Kerala & Mahe',
  'CHERUPUSHPA BETHANY SR SEC SCHOOL , Kerala & Mahe',
  'CHINMAYA VIDYALAYA  PALLAVUR PALAKKAD, Kerala & Mahe',
  'CHINMAYA VIDYALAYA , CHANDANATHOPE,KOLLAM, Kerala & Mahe',
  'Chinmaya vidyalaya , taliparamba , Kerala & Mahe',
  'CHINMAYA VIDYALAYA ,KANNANKULANGARA, Kerala & Mahe',
  'CHINMAYA VIDYALAYA ALAPPUZHA, Kerala & Mahe',
  'Chinmaya Vidyalaya Attukal, Kerala & Mahe',
  'Chinmaya vidyalaya Calicut , Kerala & Mahe',
  'CHINMAYA VIDYALAYA CHENGANNUR, Kerala & Mahe',
  'Chinmaya vidyalaya kannamaly, Kerala & Mahe',
  'CHINMAYA VIDYALAYA KATTIPARAMBU KANNAMLY KOCHI , Kerala & Mahe',
  'Chinmaya vidyalaya kolazhhy, Kerala & Mahe',
  'CHINMAYA VIDYALAYA KOLLENGODE, Kerala & Mahe',
  'CHINMAYA VIDYALAYA PAYYANUR, Kerala & Mahe',
  'CHINMAYA VIDYALAYA TALIPARAMBA KANNUR, Kerala & Mahe',
  'CHINMAYA VIDYALAYA, ATTUKAL, THIRUVANANTHAPURAM, Kerala & Mahe',
  'Chinmaya Vidyalaya, Kannankulamgara, Tripunthura, Kerala & Mahe',
  'CHINMAYA VIDYALAYA, KOCHI, Kerala & Mahe',
  'Chinmaya Vidyalaya, Kolazhy, Kerala & Mahe',
  'CHOTHAVOOR HSS CHAMPAD, Kerala & Mahe',
  'CHOVVA  HIGHER SECONDARY SCHOOL, Kerala & Mahe',
  'CHRIST CENTRAL SCHOOL MUTHOOR THIRUVALLA  PATHANAMTITTA, Kerala & Mahe',
  'CHRISTVA MAHILALAYAM PUBLIC SCHOOL, ALUVA, Kerala & Mahe',
  'CHSS CHATTANCHAL, Kerala & Mahe',
  'CITADEL RESIDENTIAL SCHOOL, Kerala & Mahe',
  'CJMAHSS VARANDARAPPILLY, Kerala & Mahe',
  'CO OPRATIVE PUBLIC SCHOOL, THODUPUZHA, Kerala & Mahe',
  'COCHIN REFINERIES SCCHOOL, Kerala & Mahe',
  'CRESCENT HIGHER SECONDARY SCHOOL ADAKKAKUNDU, Kerala & Mahe',
  'CVMHSS VANDAZHI PALAKKAD DISTRICT, Kerala & Mahe',
  'D V N S S H S S POOVATTOOR KIOLLAM, Kerala & Mahe',
  'Dawn International School, Kerala & Mahe',
  'DE PAUL PUBLIC SCHOOL ,THODUPUZHA, Kerala & Mahe',
  'Deepa College, Padma Junction, Pullepady, Ernakulam, Kerala, Kerala & Mahe',
  'DEEPA COLLEGE,THOPPUMPADY, Kerala & Mahe',
  'DEVAGIRI CMI PUBLIC SCHOOL DEVAGIRI, Kerala & Mahe',
  'DEVAMATHA CMI PUBLIC SCHOOL, Kerala & Mahe',
  'DEVASWOM BOARD HSS, CHERIYANAD, Kerala & Mahe',
  'DON BOSCO  CENTRAL SCHOOL, PUTHUPALLY, Kerala & Mahe',
  'DON BOSCO HS MANNUTHY, Kerala & Mahe',
  'Don Bosco Senior Secondary School Vaduthala, Kerala & Mahe',
  'DR G R Public School Neyyattinkara, Kerala & Mahe',
  'DR GR PUBLIC SCHOOL, NEYYATTINKARA TRIVANDRUM , Kerala & Mahe',
  'DR ZHM B VIDYA VIHAR, Kerala & Mahe',
  'Dr. K. B MENON MEMMORIAL HIGHER SECONDARY SCHOOL, TRITHALA(P.O) PALAKKAD(DIS) , Kerala & Mahe',
  'Dr.CT.Eapen Memorial Higher Secondary School, Kerala & Mahe',
  'Dr.ZHM Bharathiya Vidya Vihar Changanacherry-2, Kerala & Mahe',
  'DURGA HIGHER SECONDARY SCHOOL, Kerala & Mahe',
  'DV NSS HSS POOVATTOOR, Kerala & Mahe',
  'DVHHS Kandakary, Kerala & Mahe',
  'DVMNNMHSS Maranalloor, Kerala & Mahe',
  'DVVHSS THALAVOOR, KOLLAM DIST., Kerala & Mahe',
  'E V HSS NEDUVATHOOR  KOTTARAKKARA, Kerala & Mahe',
  'E.K.NAYANAR SMARAKA GOVT HIGHER SECONDARY SCHOOL VENGAD, Kerala & Mahe',
  'E.M.E.A.H.S.S. THURAKKAL ,KONDOTTY, Kerala & Mahe',
  'EASTERN PUBLIC SCHOOL ADIMALY, Kerala & Mahe',
  'EASWARAVILASOM HIGHER SECONDARY SCHOOL, Kerala & Mahe',
  'EBENEZER HIGHER SECONDARY SCHOOL VEETTOOR ERNAKULAM DISTRICT KERALA, Kerala & Mahe',
  'EDWARD MEMORIAL GOVT .HSS , VELI, FORTKOCHIN ,ERNAKULAM, Kerala & Mahe',
  'EK NAYANAR SMARAKA GHSS VENGAD, Kerala & Mahe',
  'EXCEL PUBLIC SCHOOL CHALAKKARA, Kerala & Mahe',
  'EXCEL PUBLIC SCHOOL MAHE, Kerala & Mahe',
  'EXCELSIOR ENGLISH SCHOOL,ILLICKAL,KOTTAYAM, Kerala & Mahe',
  'Farook higher secondory  school, Kerala & Mahe',
  'Father Joseph Memorial Higher Secondary School, Kerala & Mahe',
  'FR.GKM HSS KANIYARAM, Kerala & Mahe',
  'Fr.PHILIPS NELLIKADE KATAKADA THIRUVANANTHAPURAM, Kerala & Mahe',
  'G D V H S S KUDAVECHOOR, Kerala & Mahe',
  'G H S S ANCHALUMMOODU, Kerala & Mahe',
  'G H S S ANGADICAL SOUTH, Kerala & Mahe',
  'G H S S CHEEMENI, Kerala & Mahe',
  'G H S S CHERUTHURUTHY, Kerala & Mahe',
  'G H S S KARUKONE KARUKONE P O ANCHAL KOLLAM, Kerala & Mahe',
  'G H S S KIZHAKKENCHERRY, Kerala & Mahe',
  'G H S S KOKKALLUR, Kerala & Mahe',
  'G H S S KONNI , Kerala & Mahe',
  'G H S S KUNNAKKAVU, Kerala & Mahe',
  'G H S S NORT PARAVUR ERNAKULAM, Kerala & Mahe',
  'G H S S PERIYE, Kerala & Mahe',
  'G H S S PUTHOOR, Kerala & Mahe',
  'G H S S THRIKKAVU, Kerala & Mahe',
  'G H S S VALIYAZHEEKAL, Kerala & Mahe',
  'G V H S S FOR BOYS KOTTARAKKARA, Kerala & Mahe',
  'G V H S S KARAKKURISSI, Kerala & Mahe',
  'G V H S S KARAKULAM, Kerala & Mahe',
  'G V H S S KULAKKADA, Kerala & Mahe',
  'G V H S S MANANTHAVADY, Kerala & Mahe',
  'G V H S S PAYYOLI, Kerala & Mahe',
  'G V H S S VAKKOM, Kerala & Mahe',
  'G.H.S.S ANCHAL EAST, Kerala & Mahe',
  'G.H.S.S EASTHILL, KOZHIKODE, Kerala & Mahe',
  'G.H.S.S MEENANGADI, Kerala & Mahe',
  'G.H.S.S RAJAKKAD , Kerala & Mahe',
  'G.H.S.S,PARAVOOU,KOLLAM, Kerala & Mahe',
  'G.H.S.S.MATHIL, Kerala & Mahe',
  'G.H.S.S.THOTTAKKONAM, Kerala & Mahe',
  'G.M.M.G.H.S.S PALAKKAD, Kerala & Mahe',
  'GANGOTHRI ENGLISH MEDIUM SCHOOL NEMMARA, Kerala & Mahe',
  'GARDEN VALLEY EM HSS,KUTTIPPALA, Kerala & Mahe',
  'GAYATHRI CENTRAL SCHOOL PULIYANNOOR P O  PALA, Kerala & Mahe',
  'GBHSS CHAVARA, Kerala & Mahe',
  'GBHSS CHITTUR, Kerala & Mahe',
  'GBHSS Kayamkulam, Kerala & Mahe',
  'GBHSS MANJERI, Kerala & Mahe',
  'GBHSS Mavelikara , Kerala & Mahe',
  'GBHSS NEMMARA, Kerala & Mahe',
  'GBVHSS KOTTARAKARA, Kerala & Mahe',
  'GEMS NEMMARA, Kerala & Mahe',
  'GFHSS, KUZHITHURA, Kerala & Mahe',
  'GG HSS HARIPAD, Kerala & Mahe',
  'GGHSS  Madappally, Kerala & Mahe',
  'GGHSS alathur, Kerala & Mahe',
  'GGHSS BALUSSERY, Kerala & Mahe',
  'GGHSS FOR GIRLS B P ANGADI, TIRUR, Kerala & Mahe',
  'GGHSS HARIPAD, Kerala & Mahe',
  'GGHSS KOYILANDY, Kerala & Mahe',
  'GGHSS Malappuram, Kerala & Mahe',
  'GGHSS PAYYANUR, Kerala & Mahe',
  'GGHSS WANDOOR, Kerala & Mahe',
  'GGHSS, Kayamkulam , Kerala & Mahe',
  'GGVHSS Feroke, Kerala & Mahe',
  'GGVHSS NEMMARA PALAKKAD, Kerala & Mahe',
  'GGVHSS PERINTHALMANNA, Kerala & Mahe',
  'GGVHSS, feroke, Kerala & Mahe',
  'GHS THIRUVALY, Kerala & Mahe',
  'GHSS  THUVVR, Kerala & Mahe',
  'GHSS AGALI, Kerala & Mahe',
  'GHSS ALANALLUR, Kerala & Mahe',
  'GHSS ANCHALUMOODU  KOLLAM, Kerala & Mahe',
  'GHSS AND VHSS KOTTARAKKARA, Kerala & Mahe',
  'GHSS AREACODE, Kerala & Mahe',
  'GHSS AVALA KUTTOTH, Kerala & Mahe',
  'GHSS AYYANKOICKAL, Kerala & Mahe',
  'GHSS AZHIYUR, Kerala & Mahe',
  'GHSS Bhoothakulam, Kerala & Mahe',
  'GHSS Big Bazaar School, Kerala & Mahe',
  'GHSS Chala, Kerala & Mahe',
  'GHSS CHALISSERY, Kerala & Mahe',
  'GHSS Chathanoor, Kerala & Mahe',
  'GHSS CHAYOTH, Kerala & Mahe',
  'GHSS CHEEMENI, Kerala & Mahe',
  'GHSS Chelora, Kerala & Mahe',
  'GHSS CHEMBUCHIRA, Kerala & Mahe',
  'GHSS CHENGAMANAD, Kerala & Mahe',
  'Ghss cherpulasseery, Kerala & Mahe',
  'GHSS CHERPULASSERY, Kerala & Mahe',
  'GHSS CHERUKUNNU, Kerala & Mahe',
  'GHSS CHITHARA, Kerala & Mahe',
  'GHSS CHORODE, Kerala & Mahe',
  'Ghss chuzhali, Kerala & Mahe',
  'GHSS EASTHILL KOZHIKODE, Kerala & Mahe',
  'GHSS EDAPPAL, Kerala & Mahe',
  'GHSS HOSDURG, Kerala & Mahe',
  'GHSS KADANNAPPALLY, Kerala & Mahe',
  'GHSS KADAVALLUR, Kerala & Mahe',
  'GHSS Kadayiruppu, Kerala & Mahe',
  'GHSS KAKKAVAYAL, Kerala & Mahe',
  'GHSS KALANJOOR, Kerala & Mahe',
  'GHSS Kallachi, Kerala & Mahe',
  'GHSS KARAKURUSSI, Kerala & Mahe',
  'GHSS KARUKONE, Kerala & Mahe',
  'GHSS KARUPADANNA , Kerala & Mahe',
  'Ghss karuvarakundu, Kerala & Mahe',
  'GHSS KAVANUR, Kerala & Mahe',
  'GHSS KIZHAKKENCHERRY, Kerala & Mahe',
  'GHSS kodakara, Kerala & Mahe',
  'GHSS KODUVAYUR, Kerala & Mahe',
  'GHSS KOICKAL, Kerala & Mahe',
  'GHSS KOKKALLUR, Kerala & Mahe',
  'GHSS KONGORPILLY , Kerala & Mahe',
  'GHSS KOOTHUPARAMBA, Kerala & Mahe',
  'GHSS KOROM, Kerala & Mahe',
  'GHSS KOTTARAKKARA, Kerala & Mahe',
  'GHSS KOTTILA, Kerala & Mahe',
  'GHSS Kundamkuzhy, Kerala & Mahe',
  'GHSS KUNHIMANGALAM, Kerala & Mahe',
  'Ghss Kuttamath , Kerala & Mahe',
  'GHSS KUTTIKKATTOOR, Kerala & Mahe',
  'ghss kuttiyadi, Kerala & Mahe',
  'GHSS Madikai, Kerala & Mahe',
  'GHSS MAMALASSERY, Kerala & Mahe',
  'GHSS MATTUMMAL ATHVANAD, Kerala & Mahe',
  'GHSS Meenangadi, Kerala & Mahe',
  'GHSS MORAZHA, Kerala & Mahe',
  'GHSS MUTTARA, Kerala & Mahe',
  'GHSS NAIRKUZHI, Kerala & Mahe',
  'GHSS NEDUNGOLAM, Kerala & Mahe',
  'GHSS NEDUNGOME, Kerala & Mahe',
  'GHSS NEELESWARAM, Kerala & Mahe',
  'GHSS NEMMARA, Kerala & Mahe',
  'GHSS NORTH PARAVUR, Kerala & Mahe',
  'GHSS OTTAPALAM, Kerala & Mahe',
  'Ghss Pandikkad , Kerala & Mahe',
  'GHSS PANNUR, Kerala & Mahe',
  'Ghss perinad, Kerala & Mahe',
  'GHSS PERINGARA, Kerala & Mahe',
  'GHSS PERIYE, Kerala & Mahe',
  'GHSS PILICODE, Kerala & Mahe',
  'GHSS POTTASSERY, Kerala & Mahe',
  'GHSS PULAMANTHOLE, Kerala & Mahe',
  'GHSS PUTHOOR KOLLAM KERALA, Kerala & Mahe',
  'Ghss puthupparamba, Kerala & Mahe',
  'GHSS PUTHUR, Kerala & Mahe',
  'GHSS RAMANTHALI, Kerala & Mahe',
  'Ghss Ramapuram, Kerala & Mahe',
  'GHSS SADANANDAPURAM , Kerala & Mahe',
  'GHSS THOPRAMKUDY, Kerala & Mahe',
  'GHSS THOTTAKONAM, Kerala & Mahe',
  'GHSS THRIKKAVU, Kerala & Mahe',
  'GHSS THUMPAMON NORTH, Kerala & Mahe',
  'GHSS TIRURANGADI, Kerala & Mahe',
  'GHSS TRIKKAVU, Kerala & Mahe',
  'GHSS UDINUR, Kerala & Mahe',
  'GHSS Vadanamkurussi, Kerala & Mahe',
  'GHSS Vaduvanchal, Kerala & Mahe',
  'GHSS VALATHUNGAL, Kerala & Mahe',
  'GHSS VALAYAM, Kerala & Mahe',
  'Ghss vallikkeezhu kollam, Kerala & Mahe',
  'GHSS VANIYAMBALAM, Kerala & Mahe',
  'GHSS Varkala, Kerala & Mahe',
  'GHSS VATAKARA, Kerala & Mahe',
  'GHSS VATAKARA PUTHUR, Kerala & Mahe',
  'GHSS VAYALA, KOLLAM, Kerala & Mahe',
  'GHSS VELLUR, Kerala & Mahe',
  'GHSS VENJARAMOODU, Kerala & Mahe',
  'GHSS WADAKKANCHERY, Kerala & Mahe',
  'GHSS West kollam , Kerala & Mahe',
  'GHSS YEROOR, Kerala & Mahe',
  'Girideepam Bethany Central School, Kerala & Mahe',
  'GJHSS NADUVATTAM, Kerala & Mahe',
  'GLOBAL PUBLIC SCHOOL,KOCHI, Kerala & Mahe',
  'GMBHSS HARIPPAD, Kerala & Mahe',
  'GMGHSS KUNNAMKULAM, Kerala & Mahe',
  'GMHSS CALICUT, Kerala & Mahe',
  'GMHSS CALICUT UNIVERSITY CAMPUS, Kerala & Mahe',
  'GMHSS PERINTHALMANNA MALAPPURAM, Kerala & Mahe',
  'GmHSS PUNNAMOODU TVPM, Kerala & Mahe',
  'GMHSS, VARKALA, Kerala & Mahe',
  'GMMGHSS Palakkad, Kerala & Mahe',
  'GMRHSS Kuzhalmanam, Kerala & Mahe',
  'GMVHSS KOYILANDI , Kerala & Mahe',
  'GMVHSS, Nilambur, Kerala & Mahe',
  'GMVHSS,KOYILANDI, Kerala & Mahe',
  'GOOD SHEPHERD PUBLIC SCHOOL,CHANGANACHERRY, Kerala & Mahe',
  'GOVERNMENT  HSS KULASEKHARAPURAM,KOLLAM, Kerala & Mahe',
  'GOVERNMENT HIGHER SECONDARY SCHOOL, KULATHOOR, Kerala & Mahe',
  'GOVERNMENT HSS CHERANALLOOR, Kerala & Mahe',
  'GOVERNMENT HSS KADAYIRUPPU, Kerala & Mahe',
  'GOVERNMENT HSS KULASEKHARAPURAM, Kerala & Mahe',
  'GOVERNMENT HSS THEVANNOOR, Kerala & Mahe',
  'Government Model Boys Higher Secondary School, Kerala & Mahe',
  'GOVERNMENT MODEL GIRLS HSS ,PATTOM, Kerala & Mahe',
  'GOVERNMENT MODEL HIGHER SECONDARY SCHOOL KULESHEKARAPURAM, Kerala & Mahe',
  'GOVERNMENT MODEL HIGHER SECONDARY SCHOOL MANATHANA, Kerala & Mahe',
  'GOVERNMENT MODEL HIGHER SECONDARY SCHOOL THRISSUR , Kerala & Mahe',
  'GOVERNMENT MODEL HSS KOZHIKODE, Kerala & Mahe',
  'Government Model Senior Secondary School, Port Blair, Kerala & Mahe',
  'GOVT  VHSS PATTAZHI, Kerala & Mahe',
  'GOVT BHSS KOTTARAKKARA KOLLAM, Kerala & Mahe',
  'GOVT BOYS HIGHER SECONDARY  SCHOOL ATTINGAL, Kerala & Mahe',
  'Govt boys higher secondary koyilandy, Kerala & Mahe',
  'GOVT BOYS HIGHER SECONDARY SCHOOL  ALUVA, Kerala & Mahe',
  'GOVT BOYS HIGHER SECONDARY SCHOOL MANJERI, Kerala & Mahe',
  'GOVT BOYS HSS KAYAMKULAM , Kerala & Mahe',
  'GOVT BOYS HSS THYKAVU PATHANAMTHITTA, Kerala & Mahe',
  'GOVT GHSS KAYAMKULAM, Kerala & Mahe',
  'Govt GIRLS HIGHER SECONDARY SCHOOL KAYAMKULAM , Kerala & Mahe',
  'GOVT GIRLS HSS KAYAMKULAM, Kerala & Mahe',
  'Govt Girls Hss Kodungallur,Thrissur, Kerala & Mahe',
  'GOVT GIRLS HSS NADAKKAVU, Kerala & Mahe',
  'GOVT GIRLS HSS NORTH PARAVUR, Kerala & Mahe',
  'GOVT H S S CHAVARA, Kerala & Mahe',
  'Govt H S S KARAKULAM, Kerala & Mahe',
  'Govt H.S.S Konni, Kerala & Mahe',
  'GOVT HIGHER SECONDARY SCHOOL MEDICAL COLLEGE CAMPUS, Kerala & Mahe',
  'GOVT HIGHER SECONDARY SCHOOL RAMANTHALI, Kerala & Mahe',
  'Govt higher secondary school thazhava, Kerala & Mahe',
  'Govt Higher Secondary School, Sooranad, Kollam, Kerala & Mahe',
  'GOVT HSS AVALA KUTTOTH, Kerala & Mahe',
  'GOVT HSS FOR GIRLS NEYYATTINKARA, Kerala & Mahe',
  'GOVT HSS KADAKKAL, Kerala & Mahe',
  'GOVT HSS KARUNAGAPPALLY, Kerala & Mahe',
  'GOVT HSS KOTTARAKKARA, Kerala & Mahe',
  'Govt HSS KS Puram, Kerala & Mahe',
  'GOVT HSS MEDICAL COLLEGE CAMPUS, KOZHIKODE, Kerala & Mahe',
  'GOVT HSS MORAZHA, Kerala & Mahe',
  'GOVT HSS MULANTHURUTHY, Kerala & Mahe',
  'GOVT HSS NEELESWARAM, Kerala & Mahe',
  'GOVT HSS NGO QRTS KOZHIKODE, Kerala & Mahe',
  'Govt HSS PALLICKAL, Kerala & Mahe',
  'GOVT HSS PALLIMON , Kerala & Mahe',
  'GOVT HSS PANAMKANDY, Kerala & Mahe',
  'GOVT HSS PATTAZHY, Kerala & Mahe',
  'Govt HSS PUTHOOR, Kerala & Mahe',
  'GOVT HSS SADANANADAPURAM, Kerala & Mahe',
  'Govt HSS SASTHAMKOTTA, Kerala & Mahe',
  'Govt HSS Thamarassery, Kerala & Mahe',
  'Govt HSS Thazhathuvadakara, Kerala & Mahe',
  'Govt HSS THIRURANGADI, Kerala & Mahe',
  'GOVT HSS THODIYOOR, Kerala & Mahe',
  'Govt hss vallikeezhu, Kerala & Mahe',
  'Govt hss yeroor, Kerala & Mahe',
  'GOVT HSS, KULASEKHARAPURAM, KOLLAM, Kerala & Mahe',
  'Govt HSS, Punalur, Kerala & Mahe',
  'GOVT HSS, VILLEDOM, THRISSUR, Kerala & Mahe',
  'GOVT HSS,EDAPPAL,MALAPPURAM, Kerala & Mahe',
  'Govt Jawahar HSS ayoor, Kerala & Mahe',
  'GOVT MANAVEDAN HIGHER SECONDARY SCHOOL NILAMBUR, Kerala & Mahe',
  'GOVT MEDICAL COLLEGE CAMPUS HSS KOZHIKODE , Kerala & Mahe',
  'GOVT MGHSS CHADAYAMANGALAM, Kerala & Mahe',
  'GOVT MODEL BOYS HSS HARIPAD, Kerala & Mahe',
  'GOVT MODEL BOYS HSS THEVALLY, KOLLAM, Kerala & Mahe',
  'GOVT MODEL BOYS HSS THYCAUD, Kerala & Mahe',
  'GOVT MODEL BOYS KUNNAMKULAM, Kerala & Mahe',
  'Govt model girls higher secondary school,Pattom,TVM, Kerala & Mahe',
  'GOVT MODEL HSS KOLLAM, Kerala & Mahe',
  'GOVT MODEL HSS KOZHIKODE, Kerala & Mahe',
  'GOVT MODEL HSS KULASEKHARAPURAM, Kerala & Mahe',
  'GOVT MODEL HSS VETTIKAVALA, Kerala & Mahe',
  'GOVT MOYAN MODEL GIRLS HIGHER SECONDARY SCHOOL PALAKKAD, Kerala & Mahe',
  'Govt of Kerala Board of Higher Secondary Examinations, Kerala & Mahe',
  'GOVT S V H S S KUDASSANAD, Kerala & Mahe',
  'GOVT TOWN HIGHER SECONDARY SCHOOL KANNUR, Kerala & Mahe',
  'GOVT VOCATIONAL HIGHER SECONDARY SCHOOL, VALATHUNGAL, KOLLAM, Kerala & Mahe',
  'GOVT. BOYS HIGHER SECONDARY SCHOOL,KOTTARAKKARA, Kerala & Mahe',
  'GOVT. BOYS HSS ALUVA, Kerala & Mahe',
  'GOVT. BOYS HSS KAYAMKULAM, Kerala & Mahe',
  'GOVT. BOYS HSS NEYYATTINKARA, Kerala & Mahe',
  'GOVT. BOYS HSS PATHANAMTHITTA , Kerala & Mahe',
  'Govt. HSS AVALA, KUTTOTH, Kerala & Mahe',
  'Govt. LVHSS Arayoor, Kerala & Mahe',
  'GOVT. MOPLA HIGHER SECONDARY SCHOOL,KOYILANDI, Kerala & Mahe',
  'Govt. Moyan Model Girls Higher Secondary School, Kerala & Mahe',
  'GOVT. VHSS Pathiripala, Kerala & Mahe',
  'Govt. VHSS, VATTIYOORKAVU, THIRUVANANTHAPURAM, Kerala & Mahe',
  'Govt. Vocational Higher Secondary School, Marutharoad, Kerala & Mahe',
  'Govt. Vocational Higher Secondary School, Mulakuzha, Kerala & Mahe',
  'GOVT.BOYS H S S KAYAMKULAM, Kerala & Mahe',
  'Govt.Brennan higher secondary school, Thalassery, Kerala & Mahe',
  'GOVT.GANAPAT VOCATIONAL HIGHER SECONDARY SCHOOL, Kerala & Mahe',
  'GOVT.GIRLS H.S.S MALAPPURAM, Kerala & Mahe',
  'GOVT.GIRLS HSS BALUSSERY, Kerala & Mahe',
  'GOVT.GIRLS HSS HARIPAD, Kerala & Mahe',
  'Govt.Girls HSS Mavelikkara, Kerala & Mahe',
  'Govt.Girls Hss, Payyanur, Kerala & Mahe',
  'GOVT.GIRLS VHSS,VENGARA, Kerala & Mahe',
  'Govt.Girls.Highersecondary.School.Manjeri, Kerala & Mahe',
  'Govt.H.S.S Ayyankoical,koivila, Kerala & Mahe',
  'Govt.H.S.S Quilon West, Kerala & Mahe',
  'GOVT.HIGHER SECONDARY SCHOOL, RAMAPURAM, KEERIKADU, Kerala & Mahe',
  "Govt.Moyan Model Girl's Higher Secondary School, Kerala & Mahe",
  'GOVT.SANSKRIT HSS MEPPAYIL VATAKARA, Kerala & Mahe',
  'Govt.VHSS ARYAD, Kerala & Mahe',
  'GOVT.VHSS-THS,PAMPADY, Kerala & Mahe',
  'GOVT:HIGHER SECONDARY SCHOOL  CHERPULASSERY, Kerala & Mahe',
  'GOVTVT BOYS HIGHER SECONDARY SCHOOL KAYAMKULAM, Kerala & Mahe',
  'GREEN HILL PUBLIC SCHOOL SULTHAN BATHERY, Kerala & Mahe',
  'GREGORIAN PUBLIC SCHOOL, Kerala & Mahe',
  'GRFTHSS TANUR, Kerala & Mahe',
  'GRHSS KOTTAKKAL, Kerala & Mahe',
  'Gshss meppayil vatakara, Kerala & Mahe',
  'GUARDIAN ANGEL EMHSS MANNOOR, Kerala & Mahe',
  'Guardian Public School, Kerala & Mahe',
  'GUHANANDAPURAM HSS, Kerala & Mahe',
  'Gulf Indian School, Kerala & Mahe',
  'GVHSS AVALA KUTTOTH, Kerala & Mahe',
  'GVHSS AYYANTHOLE, Kerala & Mahe',
  'Gvhss chathannoor ,kollam, Kerala & Mahe',
  'GVHSS CHELARI, Kerala & Mahe',
  'GVHSS Cheruvannur, Kerala & Mahe',
  'GVHSS Chittur, Kerala & Mahe',
  'GVHSS Chunakkara , Kerala & Mahe',
  'GVHSS EDAYANNUR, Kerala & Mahe',
  'GVHSS KARAKULAM, Kerala & Mahe',
  'GVHSS KARTHIKAPURAM, Kerala & Mahe',
  'GVHSS KOROM,PAYYANNUR, Kerala & Mahe',
  'GVHSS KURUMATHUR, Kerala & Mahe',
  'GVHSS KUTTICHIRA KOZHIKODE, Kerala & Mahe',
  'GVHSS MADAPALLY, Kerala & Mahe',
  'GVHSS MAKKARAPARAMBA, Kerala & Mahe',
  'GVHSS Malampuzha, Kerala & Mahe',
  'GVHSS MANANTHAVADY, Kerala & Mahe',
  'GVHSS MANGAYIL MARADU, Kerala & Mahe',
  'GVHSS MARUTHA ROAD, Kerala & Mahe',
  'GVHSS MEPPAYUR , Kerala & Mahe',
  'GVHSS NADAKKAVU, Kerala & Mahe',
  'GVHSS PATHIRIPPALA, Kerala & Mahe',
  'GVHSS PAYYOLI, Kerala & Mahe',
  'GVHSS PONKUNNAM, Kerala & Mahe',
  'GVHSS THALIKULAM, Kerala & Mahe',
  'GVHSS THIRUMARADY, KOOTHATTUKULAM (ERNAKULAM), KERALA, Kerala & Mahe',
  'GVHSS THS NERUVAMBRAM, Kerala & Mahe',
  'GVHSS THS SULTHAN BATHERY, Kerala & Mahe',
  'GVHSS VAKKOM, Kerala & Mahe',
  'GVHSS VENGARA, Kerala & Mahe',
  'GVHSS WADAKANCHERY, Kerala & Mahe',
  'H I H S S EDAVANAKAD ERNAKULAM, Kerala & Mahe',
  'H S S  PERINGODE, Kerala & Mahe',
  'HARI SRI VIDYA NUDHI SCHOOL THRISSUR, Kerala & Mahe',
  "HHSIB SWAMIJI'S HSS,EDNEER,CHERKALA,KASARAGOD, Kerala & Mahe",
  'HIGHER SECONDARY SCHOOL CHETTIKULANGARA, Kerala & Mahe',
  'Higher Secondary School for Boys Punalur, Kerala & Mahe',
  'HIGHER SECONDARY SCHOOL, KUTHANUR, Kerala & Mahe',
  'HIHSS Edavanakkad, Kerala & Mahe',
  'Hill Blooms School, Kerala & Mahe',
  'HIRA ENGLISH SCHOOL KAIPAMANGALAM, Kerala & Mahe',
  'Holitrinity Thevalakkara, Kerala & Mahe',
  "Holy Angel's English medium higher secondary school Adoor, Kerala & Mahe",
  'Holy Angels Convent Higher Secondary School,Vanjiyoor,Thiruvanandhapur, Kerala & Mahe',
  'Holy Angels EMHSS, ADOOR, Kerala & Mahe',
  'HOLY ANGELS H S S OLLUR THRISSUR, Kerala & Mahe',
  'HOLY ANGELS ISC SCHOOL THIRUVANANTHAPURAM, Kerala & Mahe',
  'Holy cross hss,  cherpunkal, Kerala & Mahe',
  'HOLY FAMILY BHSSS CHERTHALA, Kerala & Mahe',
  'Holy Family Higher Secondary School Cherthala, Kerala & Mahe',
  'HOLY GRACE ACADEMY CBSE RESIDENTIAL SCHOOL MALA, Kerala & Mahe',
  'HOLY TRINITY ANGLO INDIAN SCHOOL KOLLAM, Kerala & Mahe',
  'Holyangels HSS adoor, Kerala & Mahe',
  'Holyfamily covent girls high school , chempukavu,  thrissur , Kerala & Mahe',
  'HSS CHENTRAPPINI, Kerala & Mahe',
  'IES CHITTILAPILLY, Kerala & Mahe',
  'IES PUBLIC SCHOOL, Kerala & Mahe',
  'IKTHSS CHERUKULAMBA, Kerala & Mahe',
  'ILAHIA PUBLIC SCHOOL, Kerala & Mahe',
  'Indian excelLent private school, Kerala & Mahe',
  'INDIAN SCHOOL DARSAIT MUSCAT, Kerala & Mahe',
  'INFANNT JESUS ANGLO INDIAN H S S, Kerala & Mahe',
  'Infant Jesus Anglo Indian Higher Secondary School, Kerala & Mahe',
  'INFANT JESUS ISC SCHOOL, MAVELIKKARA, Kerala & Mahe',
  'International Indian School Jeddah, KSA, Kerala & Mahe',
  'IQBAL HIGHER SECONDARY SCHOOL KANHANGAD, Kerala & Mahe',
  'IRINGANNUR HIGHER SECONDARY SCHOOL, Kerala & Mahe',
  'J.N.M GOVT HIGHER SECONDARY SCHOOL PUTHUPPANAM, Kerala & Mahe',
  'JAVAHAR NAVODAYA VIDYALAYA MAHE, Kerala & Mahe',
  'JAWAHAR NAVODAYA VIDYALAYA, NERIAMANGALAM, Kerala & Mahe',
  'JNM GHSS PUDUPPANAM, Kerala & Mahe',
  'Jyothis Central School , KAZHAKUTTOM, Kerala & Mahe',
  'JYOTI NILAYAM HSS, Kerala & Mahe',
  'K E CARMEL CETRAL SCHOOL MUHAMMA ALAPPUZHA, Kerala & Mahe',
  'K E H S S MANNANA,, Kerala & Mahe',
  'K E SCHOOL MANNANAM, Kerala & Mahe',
  'K H S S KANNADI PALAKKAD, Kerala & Mahe',
  'K K M G V H S S ELIPPAKULAM, Kerala & Mahe',
  'K M B VIDYA MANDIR , POTTORE, Kerala & Mahe',
  'K N S MEM S N CEN SCH , Kerala & Mahe',
  'K P M H S S POOTHOTTA, Kerala & Mahe',
  'K P R P H S S, KONGAD, PALAKKAD, Kerala & Mahe',
  'K V  KANNUR, Kerala & Mahe',
  'K V EDAT, PAYYANNUR, Kerala & Mahe',
  'KENDRIYA VIDYALAYA ADOOR, Kerala & Mahe',
  'KENDRIYA VIDYALAYA AFS AKKULAM, Kerala & Mahe',
  'KENDRIYA VIDYALAYA EZHIMALA, Kerala & Mahe',
  'KENDRIYA VIDYALAYA INS DRONACHARYA, Kerala & Mahe',
  'KENDRIYA VIDYALAYA INS ZAMORIN KANNUR KERALA, Kerala & Mahe',
  'KENDRIYA VIDYALAYA KADAVANTHRA ERNAKULAM , Kerala & Mahe',
  'KENDRIYA VIDYALAYA KALPETTA, Kerala & Mahe',
  'KENDRIYA VIDYALAYA MALAPPURAM, Kerala & Mahe',
  'KENDRIYA VIDYALAYA NO.2 KATARI BAGH NAVAL BASE , Kerala & Mahe',
  'KENDRIYA VIDYALAYA NO3 COCHINPORT TRUST, Kerala & Mahe',
  'KENDRIYA VIDYALAYA NTPC KAYAMKULAM, Kerala & Mahe',
  'KENDRIYA VIDYALAYA OTTAPALAM, Kerala & Mahe',
  'KENDRIYA VIDYALAYA PAYYANIR, Kerala & Mahe',
  'KENDRIYA VIDYALAYA SAP CAMP PEROORKADA  TRIVANDRUM, Kerala & Mahe',
  'KENDRIYA VIDYALAYA THIRUMULLAVARAM , Kerala & Mahe',
  'KENDRIYA VIDYALAYA, CRPF, PALLIPPURAM, THIRUVANANTHAPURAM, Kerala & Mahe',
  'KENDRIYA VIDYALAYA, EDAT, PAYYANUR, Kerala & Mahe',
  'KENDRIYA VIDYALAYA, KANJIKODE, PALAKKAD, Kerala & Mahe',
  'KENDRIYA VIDYALAYA, PURANATTUKARA TRICHUR, Kerala & Mahe',
  'KENDRIYA VIDYALAYA, RUBBER BOARD PO,KOTTAYAM, Kerala & Mahe',
  'KENDRIYA VIDYALAYA, THRISSUR, Kerala & Mahe',
  'KENDRIYA VIDYALAYA,EZHIMALA, Kerala & Mahe',
  'KENDRYA VIDYALAYA, PATTOM, TRIVANDRUM, Kerala & Mahe',
  'KKKVMHSS POTHAPPALLY, Kerala & Mahe',
  'KNSMSNC SCHOOL KADAIKODU, Kerala & Mahe',
  'KOOTHALI V H S S, Kerala & Mahe',
  'KPCHSS PATTANNUR, Kerala & Mahe',
  'KPM HSS POOTHOTTA, Kerala & Mahe',
  'KPRP HSS KONGAD,PALAKKAD, Kerala & Mahe',
  'KRGPM HSS ODNAVATTOOM, Kerala & Mahe',
  'KRISHNAVILASAM SANSKRIT HIGHER SECONDARY SCHOOL, Kerala & Mahe',
  'KRIST RAJ HSS KOLLAM, Kerala & Mahe',
  'KV DRONACHARYA,MUNDAMVELI, KOCHI, Kerala & Mahe',
  'Labour India Public School, Kerala & Mahe',
  'Lajanathul Muhammadiya Higher Secondary School Alappuzha, Kerala & Mahe',
  'LBSM HSS AVITTATHUR, Kerala & Mahe',
  'Lecole  Chempaka, Kerala & Mahe',
  'LFCGHSS MAMMIYOOR, Kerala & Mahe',
  'LMS BOYS HIGHER SECONDARY SCHOOL AMARAVILA, Kerala & Mahe',
  'LOURDES   PUBLIC SCHOOL  KOTTAYAM, Kerala & Mahe',
  'LOYOLA SCHOOL SREEKARIAM THIRUVANANTHAPURAM, Kerala & Mahe',
  'M A M H S S CHENGAMANDU, Kerala & Mahe',
  'M G M MODEL SCHOOL,AYIROOR,VARKALA, Kerala & Mahe',
  'M.E.T.E.M.H.S.S, Kerala & Mahe',
  'M.T.H.S.S Venmony, Kerala & Mahe',
  'M.V. GOVT. HSS, PEROOR, Kerala & Mahe',
  'Madhava Vilassom Higher secondary school, Kerala & Mahe',
  'MAHARAJAS COLLAGE, Kerala & Mahe',
  'MARTHOMA HIGHER SECONDARY SCHOOL,VENMONY ,P.O ,CHENGANNUR, Kerala & Mahe',
  'Marthoma Senior Secondary School Kozhencherry, Kerala & Mahe',
  'Mary Rani Public School,  changanacherry,  Kottayam, Kerala & Mahe',
  'Marygiri cmi public school, Kerala & Mahe',
  'MARYGIRI ENGLISH MEDIUM SCHOOL, Kerala & Mahe',
  'Matha senior secondary school , Kerala & Mahe',
  'MATTANNUR HIGHER SECONDARY SCHOOL, Kerala & Mahe',
  'MESHSS MANNARKKAD, Kerala & Mahe',
  'METROPOLITAN H S S,PUTHENCAVU, Kerala & Mahe',
  'MGGHSS PALA KOTTAYAM KERALA  , Kerala & Mahe',
  'MGM  HSS KURUPPAMPADY, Kerala & Mahe',
  'MGM Central Public School,Kuzhivila,Karimanal P O, Thiruvananthapuram, Kerala & Mahe',
  'MGM KORRARAKKARA,KOLLAM, Kerala & Mahe',
  'MHSS FOR BOYS, CHENNITHALA , Kerala & Mahe',
  'MILADE SHERIF HSS, MYNAGAPPALLY, KOLLAM, Kerala & Mahe',
  'MKHMMOHSS MANASSERY, Kerala & Mahe',
  'MKNMHSS Kumaramangalam, Kerala & Mahe',
  'MNKMHSS CHITTILAMCHERY, Kerala & Mahe',
  'MODEL TECHNICAL HIGHER SECONDARY SCHOOL, Kerala & Mahe',
  'MOOTHEDATH HIGHER SECONDARY SCHOOL TALIPARAMBA, Kerala & Mahe',
  'MOTHER THERESA HSS MUHAMMA, Kerala & Mahe',
  'MOUNT BETHANY CBSE SCHOOL KUMBAZHA, Kerala & Mahe',
  'MOUNT CARMEL CONVENT ANGLO INDIAN GIRLS HIGH SCHOOL, Kerala & Mahe',
  'MR Raman Memorial High School, Kerala & Mahe',
  'MSM HSS CHATHINAMKULAM, Kerala & Mahe',
  'MSM HSS KALLINGALPARAMBA, Kerala & Mahe',
  'MSM HSS Kayamkulam, Kerala & Mahe',
  'MSNSSHSSCHAKKALAKUTH,NILAMBUR, Kerala & Mahe',
  'MSPHSS MALAPPURAM, Kerala & Mahe',
  'MUNDUR HIGHER SECONDARY SCHOOL,PALAKKAD, Kerala & Mahe',
  'Municipal Higher Secondary School Kandangali, Kerala & Mahe',
  'MUTHUKULAM HIGH SCHOOL, Kerala & Mahe',
  'MV higher secondary school, Kerala & Mahe',
  'MVGHSS, PEROOR KOLLAM, Kerala & Mahe',
  'MVHS ARUMANOOR, Kerala & Mahe',
  'N H S S IRINJALAKUDA, Kerala & Mahe',
  'N R P M H S S, PULLUKULANGARA, KAYAMKULAM, Kerala & Mahe',
  'N S S  H S S THATTAYIL P O, Kerala & Mahe',
  'N S S H S S ALAKODE, Kerala & Mahe',
  'N S S H S S KURATHIKAD, Kerala & Mahe',
  'N S S H S S PRAKKULAM, Kerala & Mahe',
  'N S S H S S THATTAYIL, Kerala & Mahe',
  'N S S H S S,KAVIYOOR, Kerala & Mahe',
  'N.S.B.H.S.MANNAR, Kerala & Mahe',
  'N.S.S H.S.S Karukachal, Kerala & Mahe',
  'N.S.S H.S.S Thattayil, Kerala & Mahe',
  'N.S.S V.H.S.S MUNDATHICODE, Kerala & Mahe',
  'Naduvannur HSS Vakayad, Kerala & Mahe',
  'NAIPUNNYA PUBLIC SCHOOL, EDAKKUNNU, Kerala & Mahe',
  'NAIR SAMAJAM BOYS HIGHER SECONDARY SCHOOL , Kerala & Mahe',
  'Nair Samajam Boys Higher Secondary School , Mannar, Kerala & Mahe',
  'NALANDA PUBLIC SCHOOL, Kerala & Mahe',
  'NAM HIGHER SECONDARY SCHOOL PERINGATHUR , Kerala & Mahe',
  'National Higher secondary school  ENGANDIYUR, Kerala & Mahe',
  'NAVABHARATH HIGHER SECONDARY SCHOOL,ATTINGAL, Kerala & Mahe',
  'Navadeep Public School, Kerala & Mahe',
  'NAVAJEEVAN BETHANY VIDYALAYA, NALANCHIRA, Kerala & Mahe',
  'NAVAL CHILDREN SCHOOL, KOCHI, Kerala & Mahe',
  'NAVAMUKUNDA HSS THIRUNAVAYA, Kerala & Mahe',
  'NAVDEEP PUBLIC SCHOOL, MUKHATHALA P.O, KOLLAM, Kerala & Mahe',
  'NAVY CHILDREN SCHOOL,KOCHI, Kerala & Mahe',
  'NETAJI HIGHER SECONDARY SCHOOL,PRAMADOM, Kerala & Mahe',
  'NGPM HIGHER SECONDARY SCHOOL, Kerala & Mahe',
  'NHSS ENGANDIYOOR, Kerala & Mahe',
  'NHSS IRINJALAKUDA, Kerala & Mahe',
  'NHSS KOLATHUR, Kerala & Mahe',
  'NHSS NANMINDA, Kerala & Mahe',
  'NHSS VAKAYAD, Kerala & Mahe',
  'NIRAMALAMATHA CENTRAL SCHOOL  , Kerala & Mahe',
  'NIRMALA HSS MUVATTUPUZHA, Kerala & Mahe',
  'NIRMALA MATHA CENTRAL SCHOOL,EAST FORT THRISSUR, Kerala & Mahe',
  'NITHYANANDA BHAVAN ENGLISH MEDIUM SCHOOL KANNUR KERALA, Kerala & Mahe',
  'NKMGHSS DHANUVACHAPURAM, Kerala & Mahe',
  'NOCHAD HSS NOCHAT, Kerala & Mahe',
  'NOORUL ISLAMIYA HIGHER SECONDARY VENGOOR, Kerala & Mahe',
  'NSS  KARUVATTA, Kerala & Mahe',
  'NSS Boys HS School pandalam, Kerala & Mahe',
  'NSS EHSS Kalpetta, Kerala & Mahe',
  'NSS EM HSS MANJERI, Kerala & Mahe',
  'NSS HSS  PERUNNA, Kerala & Mahe',
  'NSS HSS ADOOR, Kerala & Mahe',
  'NSS HSS KALPETTA, Kerala & Mahe',
  'NSS HSS KARUKACHAL , Kerala & Mahe',
  'NSS HSS KARUVATTA, Kerala & Mahe',
  'NSS HSS Kaviyoor, Kerala & Mahe',
  'NSS HSS KIDANGOOR, Kerala & Mahe',
  'NSS HSS THATTAYIL, Kerala & Mahe',
  'NSSHSS VARAPPETTY, Kerala & Mahe',
  'NSSHSS, KARUVATTA, Kerala & Mahe',
  'NSSHSS, PRAKKULAM, Kerala & Mahe',
  'NSSKHSS KOTTAKKAL, Kerala & Mahe',
  'Oxford Senior Secondary School, Kerala & Mahe',
  'P C N G H S S MOOKKUTHALA, Kerala & Mahe',
  'P.T.M.HIGHER SECONDRY SCHOOL VELLILA, Kerala & Mahe',
  'PADANILAM H S S, Kerala & Mahe',
  'PADMASREE CENTRAL SCHOOL ENATHU, Kerala & Mahe',
  'PALGHAT LIONS SCHOOL, KOPPAM,PALAKKAD, Kerala & Mahe',
  'Pallikkunnu higher secondary school , Kerala & Mahe',
  'PALORA HSS ULLIYERI, Kerala & Mahe',
  'Pandallur Higher Secondary School, Kerala & Mahe',
  'PANDIT  MOTHILAL GOVERNMENT MODEL HIGHER SECONDARY SCHOOL, Kerala & Mahe',
  'PANTHEERANKAVE H S S, Kerala & Mahe',
  'Parama Bhattara Kendriya Vidyalayam, Kerala & Mahe',
  'PEEVEES PUBLIC SCHOOL NILAMBUR MALAPPURAM DISTRICT, Kerala & Mahe',
  'Peralassery AKG Smaraka Government Higher Secondary School, Kerala & Mahe',
  'PERAMBRA HIGH SCHOOL, Kerala & Mahe',
  'PHSS Pandallur, Kerala & Mahe',
  'PJMSGHSS KANDASSANKADAVU, Kerala & Mahe',
  'PKMMHSS Edarikode, Kerala & Mahe',
  'PMG HSS PALAKKAD, Kerala & Mahe',
  'PMSA HSS ELANKOOR, Kerala & Mahe',
  'POILKAVU HIGHER SECONDARY HIGH SCHOOL, Kerala & Mahe',
  'PPMHSS KOTTUKKARA, Kerala & Mahe',
  'PPTMYHSS CHERUR, Kerala & Mahe',
  'PR MEMORIAL HIGHER SECONDARY SCHOOL PANOOR panoor, Kerala & Mahe',
  'PSVPM Higher Secondary School, Iravon, Kerala & Mahe',
  'PSVPM HSS KONNI, Kerala & Mahe',
  'PTM HSS THRIKKATIRI, Kerala & Mahe',
  'PTM HSS VELLILA, Kerala & Mahe',
  'PTMHSS KODIYATHUR, KOZHIKODE, Kerala & Mahe',
  'PTMHSS VELLILA, Kerala & Mahe',
  'R V S M H S S PRAYAR, Kerala & Mahe',
  'RACHSS KATAMERY, Kerala & Mahe',
  'RAFAGIRI PUBLIC SCHOOL, Kerala & Mahe',
  'RAGIVGANDHI MEMMORIAL HSS MOKERI, Kerala & Mahe',
  'RAHMANI HSS FOR HANDICAPPED, MEDICAL COLLEGE, KOZHIKODE, Kerala & Mahe',
  'Raja Kesava Das NSS HSS Sasthamangalam, Kerala & Mahe',
  'RAJAGIRI CHRISTU JAYANTHI PUBLIC SCHOOL, Kerala & Mahe',
  'Rajagiri Public School Ernakulam kerala, Kerala & Mahe',
  'RAJASHREE SMM SENIOR SECONDARY SCHOOL, Kerala & Mahe',
  'Ramakrishna Mission HSS, Kozhikode, Kerala & Mahe',
  'RAMANATTUKARA HIGHER SECONDARY SCHOOL, Kerala & Mahe',
  'RAMAVILASAM H S S CHOKLI, Kerala & Mahe',
  'RMHSS MELATTUR, Kerala & Mahe',
  'RMHSS VADAVUCODE, Kerala & Mahe',
  'RMVHSS PERINJANAM, Kerala & Mahe',
  'S B V G H S S PANMANA MANAYIL, Kerala & Mahe',
  'S C S HSS TIRUVALLA, Kerala & Mahe',
  'S C U G V H S S Pattanakad, Kerala & Mahe',
  'S D V ENGLISH MED HR SEC SCHOOL, Kerala & Mahe',
  'S D V English Medium Higher Secondary School Alappuzha, Kerala, Kerala & Mahe',
  'S F A H S S ARTHUNKAL, Kerala & Mahe',
  'S M H S S KOTTARA KOLLAM, Kerala & Mahe',
  'S M HSS KOTTARA, Kerala & Mahe',
  'S M V HSS POONJAR, Kerala & Mahe',
  'S N  TRUST  HSS PALLIPAD, Kerala & Mahe',
  'S N  Trust HSS Aickarakonam,Kakkodu P.O,Punalur,Kollam, Kerala & Mahe',
  'S N CENTRAL ALUMKADAVU KARUNAGAPPALLY, Kerala & Mahe',
  'S N CENTRAL SCHOOL NEDUNGOLAM , PARAVOOR KOLLAM KERALA, Kerala & Mahe',
  'S N G CENTRAL SCHOOL, Kerala & Mahe',
  'S N GURU CEN SCH KOTTARAKKARA, EZHUKONE ,KOLLAM, Kerala & Mahe',
  'S N H S S NORTH PARAVUR ERNAKULAM KERLAA, Kerala & Mahe',
  'S N TRUST CHERIYANAD  , Kerala & Mahe',
  'S N TRUST H S S CHERTHALA, Kerala & Mahe',
  'S N TRUST HSS CHATHANNOOR, Kerala & Mahe',
  'S N TRUST HSS CHERTHALA, Kerala & Mahe',
  'S N TRUSTS CENTRAL SCHOOL SHORANUR, Kerala & Mahe',
  'S N V H S S PANAYARA, Kerala & Mahe',
  'S N V SANSKRIT H S S NORTH PARAVUR, Kerala & Mahe',
  'S N VIDYA BHAVAN CHENTRAPPINNI, Kerala & Mahe',
  'S S M H S S THEYYALINGAL, Kerala & Mahe',
  'S T THERESE HSS SHORANUR, Kerala & Mahe',
  'S V G V H S S KIDANGANNUR, Kerala & Mahe',
  'S V H S S CLAPPANA , Kerala & Mahe',
  'S V H S S PANDANAD, Kerala & Mahe',
  'S V R V N S S H S S,VAZHOOR, Kerala & Mahe',
  'S V V BHAVAN ERAVIMANGALAM, Kerala & Mahe',
  'S.N. VIDYA BHAVAN CHENTRAPPINNI, Kerala & Mahe',
  'S.N.CENTRAL SCHOOL MAVALIKARA, Kerala & Mahe',
  'S.N.D.P.HSS PALISSERY, Kerala & Mahe',
  'S.N.D.P.Y.H.S.School Neeravil, Kerala & Mahe',
  'S.N.H.S.S, IRINJALAKUDA, Kerala & Mahe',
  "S.T MARY'S HSS MANARCADU, Kerala & Mahe",
  'S.V.H.S.S CLAPPANA, Kerala & Mahe',
  'SABARI CENTRAL SCHOOL CHERPULASSERY, Kerala & Mahe',
  'SABARI GIRI ENGLISH SCHOOL, ANCHAL, Kerala & Mahe',
  'SABARI HSS PALLIKURUP, PALAKKAD DISTRICT, Kerala & Mahe',
  'SABARIGIRI ENGLISH SCHOOL ANCHAL P.O KOLLAM KERALA, Kerala & Mahe',
  'SABARIGIRI SENIOR SECONDARY SCHOOL THOLICODE PUNALUR, Kerala & Mahe',
  'Sacred Heart Public School,Kottayam, Kerala & Mahe',
  'Sahodaran Memorial Higher secondary school Cherai Ernakulam, Kerala & Mahe',
  'SAINIK SCHOOL KAZHAKOOTAM, Kerala & Mahe',
  'SAINT ANTONYS PUBLIC SCHOOL KANJIRAPPALLY, Kerala & Mahe',
  'SALSABEEL CENTRAL SCHOOL AVANOOR MUNDOOR THRISSUR, Kerala & Mahe',
  'Samajam HSS Muthukulam, Kerala & Mahe',
  'SANATHANA DHARMA VIDYASALA ENGLISH MEDIUM HIGHER SECONDARY SCHOOL, Kerala & Mahe',
  'SANDEEPANI VIDYA NIKETHAN, THRISSUR, Kerala & Mahe',
  'SANJOS ENGLISH MEDIUM SCHOOL TALIPARAMBA KANNUR, Kerala & Mahe',
  'Santhinikethan Public school Irinjalakuda, Kerala & Mahe',
  'Saraswathi Vidhyalaya Vattiyoorkavu Thiruvananthapuram, Kerala & Mahe',
  'SARASWATHI VIDYANIKETAN PUBLIC SCHOOL ELAMAKKARA KOCHI, Kerala & Mahe',
  'SARVODAYA CENTRAL VIDYALAYA, THIRUVANANTHAPURAM, Kerala & Mahe',
  'Savio Higher Secondary School, Devagiri, Calicut, Kerala & Mahe',
  'Sboa Public (sr .sec) school, Kerala & Mahe',
  'SBOA SR SEC SCHOOL, SOUTH CHITTOOR, Kerala & Mahe',
  'SBVS GHSS PANMANA MANAYIL, Kerala & Mahe',
  'SC Higher secondary school, Kerala & Mahe',
  'School Of Good Shepherd , Trivandrum, Kerala & Mahe',
  'SCOLE KERALA, Kerala & Mahe',
  'SDPY CENTRAL SCHOOL KALATHARA KANNAMALI, Kerala & Mahe',
  'Seethi sahib memorial higher secondary school, Kerala & Mahe',
  'SEVA MANDIR POST BASIC HIGHER SECONDARY SCHOOL RAMANTTUKARA, Kerala & Mahe',
  'SEVA SADAN CENTRAL SCHOOL, Kerala & Mahe',
  'SFS PUBLIC SCHOOL AND JUNIOR COLLEGE ETTUMANOOR, Kerala & Mahe',
  'SH CMI PUBLIC SCHOOL, THEVARA, Kerala & Mahe',
  'SH Higher Secondary School changanacherry, Kerala & Mahe',
  'Shanmukha Vilasam Higher Secondary School, Clappana , Kerala & Mahe',
  'SHANTHINIKETAN SCHOOL, KUNNUMPURAM, TRIVANDRUM, Kerala & Mahe',
  'SHANTINIKETHAN PUBLIC SCHOOL IRINJALAKUDA, Kerala & Mahe',
  'SHCGHSS CHALAKUDY, Kerala & Mahe',
  'SHHSS mylapra, Kerala & Mahe',
  'SHMGVHSS EDAVANNA, Kerala & Mahe',
  'Shree Vidhyadiraja Vidya Mandir, Vazhuthakaud, Trivandrum, Kerala & Mahe',
  'Siddhartha Central School Pallimon,Kollam, Kerala & Mahe',
  'SILVER HILLS HIGHER SECONDARY SCHOOL, Kerala & Mahe',
  'SIVAGIRI H S S VARKALA, Kerala & Mahe',
  'Sivagiri Sree Narayana Senior Secondary School, Sreenivasapuram, Kerala & Mahe',
  'SIVARAM NSS HSS, KARICODE , Kerala & Mahe',
  'SJ HSS KALLODY, Kerala & Mahe',
  'SJHSS Vellayamkudy, Kerala & Mahe',
  'SKM HSS kumarakom, Kerala & Mahe',
  'SKMJ Higher secondary school Kalpetta, Kerala & Mahe',
  'SKMJ kalpetta , Kerala & Mahe',
  'SKPHSS sreekrishnapuram, Kerala & Mahe',
  'SKPS KADUTHURUTHY, Kerala & Mahe',
  'SMD public school,peroor, kollam, Kerala & Mahe',
  'SMHSS cherai, Kerala & Mahe',
  'SMHSS MARYKULAM, Kerala & Mahe',
  'SMHSS PATHARAM, Kerala & Mahe',
  'SMM HSS PAZHAMBALACODE, Kerala & Mahe',
  'SMSN HSS VAIKOM, KOTTAYAM, Kerala & Mahe',
  'SMV HSS POONJAR, Kerala & Mahe',
  'SMV NSS HSS, KALLARA, KOTTAYAM, Kerala & Mahe',
  'SN CEN SCH ALUMKADAVU KARUNAGAPPALLY, Kerala & Mahe',
  'SN CENTRAL SCHOOL KAYAMKULAM, Kerala & Mahe',
  'SN EMHSS VALIYAKULANGARA OACHIRA KOLLAM, Kerala & Mahe',
  'SN GURU CENTRAL SCHOOL EZHUKONE KOLLAM, Kerala & Mahe',
  'SN TRUST HSS CHATHANNOOR, Kerala & Mahe',
  'SN TRUST HSS KOLLAM, Kerala & Mahe',
  'SN TRUST HSS, NANGIARKULANGARA, Kerala & Mahe',
  'SN TRUST HSS,CHERIYANAD ,ALAPPUZHA, Kerala & Mahe',
  'SN VIDYA BHAVAN,CHENTRAPPINNI, Kerala & Mahe',
  'SNDP HSS CHENNERKARA, Kerala & Mahe',
  'SNEM HS OACHIRA, Kerala & Mahe',
  'SNEM HSS, Valiyakulangara, Kerala & Mahe',
  'Snhss chithara, Kerala & Mahe',
  'SNHSS Irinjalakuda, Kerala & Mahe',
  'SNHSS Sreekandeswaram, Kerala & Mahe',
  'SNHSS SREEKANDESWARAM POOCHAKKAL, Kerala & Mahe',
  'SNM HSS Moothakunnam, Kerala & Mahe',
  'SNMHSS PARAPPANANGADI, Kerala & Mahe',
  'SNMHSS purakkad, Kerala & Mahe',
  'SNSM HSS ELAMPALLOOR, Kerala & Mahe',
  'SNV HSS  Angadical South, Kerala & Mahe',
  'SNV SKT HSS , NORTH PARAVOOR, Kerala & Mahe',
  'SNVHSS ANAD, Kerala & Mahe',
  'SNVHSS NR CITY, Kerala & Mahe',
  'SPRING VALLEY SCHOOL, NIT CALICUT CAMPUS, Kerala & Mahe',
  'SR ELIZABETH JOEL CSI EMHSS ATTINGAL, Kerala & Mahe',
  'SREE BUDDHA CENTRAL SCHOOL KARUNAGAPPLLY, Kerala & Mahe',
  'SREE GOKULAM PUBLIC SCHOOL  CHAVAKKAD , Kerala & Mahe',
  'SREE GOKULAM PUBLIC SCHOOL VATAKARA, Kerala & Mahe',
  'SREE GOKULAM PUBLIC SCHOOL, ATTINGAL, Kerala & Mahe',
  'SREE GURUDEVA CENTRAL SCHOOL NEDUMONCAVU KOLLAM, Kerala & Mahe',
  'SREE NARAYANA CENTRAL SCHOOL CHERUKUNNAM, Kerala & Mahe',
  'SREE NARAYANA CENTRAL SCHOOL KARUNAGAPPALLY, Kerala & Mahe',
  'SREE NARAYANA CENTRAL SCHOOL KAYAMKULAM, Kerala & Mahe',
  'sree narayana central school mavelikara, Kerala & Mahe',
  'Sree Narayana Central school,Karunagappally, Kerala & Mahe',
  'sree Narayana college kollam, Kerala & Mahe',
  'Sree Narayana English Medium Higher Secondary School, Kerala & Mahe',
  'Sree Narayana Higher Secondary School Okkal, Kerala & Mahe',
  'Sree Narayana Higher Secondary School Poothadi, Kerala & Mahe',
  'Sree Narayana Higher secondary School thrikkanarvattom, Kerala & Mahe',
  'SREE NARAYANA HIGHER SECONDARY SCHOOL, VATAKARA, Kerala & Mahe',
  'SREE NARAYANA HSS VADAKARA, Kerala & Mahe',
  'SREE NARAYANA MEMORIAL HIGHER SECONDARY SCHOOL, Kerala & Mahe',
  'Sree Narayana Public School Konni, Kerala & Mahe',
  'SREE NARAYANA PUBLIC SCHOOL PAVUMBA, Kerala & Mahe',
  'SREE NARAYANA PUBLIC SCHOOL, GURUDEVAPURAM, CBSE, 2020, Kerala & Mahe',
  'SREE NARAYANA PUBLIC SCHOOL, KUNNACHY, ELAPPULLY, PALAKKAD, Kerala & Mahe',
  'SREE NARAYANA TRUST CENTRAL SCHOOL, HARIPAD, Kerala & Mahe',
  'SREE NARAYANA VIDYA MANDIR SENIOR SECONDARY SCHOOL, Kerala & Mahe',
  'SREE NARAYAYANA PUBLIC SCHOOL,KOLLAM, Kerala & Mahe',
  'Sree Sabari Central School Paneyil, Nooranad, Kerala & Mahe',
  'SREE SANKARA VIDYAPEETAM SENIOR SECONDARY SCHOOL, Kerala & Mahe',
  'SREE SARADA VIDYALAYA, Kerala & Mahe',
  'SREE VALUVANAD VIDHYA BHAVAN PERLMANNA, Kerala & Mahe',
  'SREE VIVEKANANDA HSS, PALEMAD, Kerala & Mahe',
  'Sree Vivekananda Memorial Public School, Kerala & Mahe',
  'Sreebuddha Central School, Kerala & Mahe',
  'SREEKRISHNA HIGHER SECONDARY SCHOOL GURUVAYUR, Kerala & Mahe',
  'SREENARAYANA CENTRAL SCHOOL KAYAMKULAM, Kerala & Mahe',
  'SREENARAYANA CENTRAL SCHOOL,THEKKEKARA,MAVELIKKARA, Kerala & Mahe',
  'Sreenarayana hss vadakara , Kerala & Mahe',
  'SREENARAYANA PUBLIC SCHOOL, VADAKKKEVILA, KOLLAM, Kerala & Mahe',
  'SREENIKETAN CENTRAL SCHOOL CHATHANNOOR KOLLAM, Kerala & Mahe',
  'Sreepuram English medium high school and junior college, Kerala & Mahe',
  'Sri Adwayananda Public School, Kerala & Mahe',
  'sri athmananda public school, Kerala & Mahe',
  'SRI SATHYA SAI VIDYAPEETH, Kerala & Mahe',
  'SRI SRI RAVI SHANKAR VIDYA MANDIR ERNAKULAM, Kerala & Mahe',
  'Sri Sri Ravishankar Vidya Mandir , Maruthamkuzhy, Kerala & Mahe',
  'Sri Vidyadhiraja Memorial Model HSS,Vendar,Kottarakara, Kerala & Mahe',
  'SSGHSS KANDANGALI , KANDANGALI PO ,  PAYYANUR, Kerala & Mahe',
  'SSVM WORLD SCHOOL, VELLALORE, COIMBATORE, Kerala & Mahe',
  'ST .JOHN T B ENG SCH NEDUMKUNNAM PO KOTTAYAM KL, Kerala & Mahe',
  'ST ALOSIOUS HSS ELTHURUTH, Kerala & Mahe',
  'St Aloysius elthuruth, Kerala & Mahe',
  'ST ALOYSIUS H S S ATHIRAMPUZHA, Kerala & Mahe',
  'St Aloysius HSS Elthuruth , Kerala & Mahe',
  'St Alphonsas, Kerala & Mahe',
  "St Anne's HSS Kurianad, Kerala & Mahe",
  'St Anns English Medium School, Kerala & Mahe',
  "St Anthony's HSS, Kerala & Mahe",
  'ST ANTHONYS PUBLIC SCHOOL, Kerala & Mahe',
  "ST ANTONY'S H S S MALA, Kerala & Mahe",
  "St Antony's HSS mala, Kerala & Mahe",
  "ST CLARE'S CGHSS THRISSUR, Kerala & Mahe",
  "St Gemma's Girls HSS, Malappuram, Kerala & Mahe",
  'ST GEMMAS GHSS MALAPPURAM, Kerala & Mahe',
  'ST GEORGE HIGHER SECONDARY SCHOOL KOTHAMANGALAM, Kerala & Mahe',
  'ST GEORGE HSS KATTAPPANA, Kerala & Mahe',
  'ST GORETTI HSS PUNALUR KOLLAM, Kerala & Mahe',
  'ST GREGORIOS CENTRAL SCH KARUNAGAPPALLY KOLLAM, Kerala & Mahe',
  'ST GREGORIOS ENG MED SCHOOL PATHANAMTHITTA , Kerala & Mahe',
  'ST GREGORIOS HSS, KOTTARAKARA, Kerala & Mahe',
  'ST IGNATIUS HSS KANJIRAMATTOM, Kerala & Mahe',
  "ST JEROME'S HSS, VELLAYAMKUDY, Kerala & Mahe",
  'ST JOHN BAPTIST EMHSS KADATHUMKADAVU, Kerala & Mahe',
  "St John's Model HSS, Nalanchira, Thiruvananthapuram, Kerala & Mahe",
  "ST JOHN'S RESIDENTIAL SCHOOL KUNDARA, Kerala & Mahe",
  'ST JOHNS HSS MATTOM, MAVELIKARA, Kerala & Mahe',
  'ST JOHNS KEERUKUZHY, Kerala & Mahe',
  'ST JOHNS SCHOOL ANCHAL, Kerala & Mahe',
  'ST JOHNS SCHOOL KEERUKUZHY, Kerala & Mahe',
  'ST JOSEPH ANGLO INDIAN HIGHER SECONDARY SCHOOL ,KOZHIKODE KERALA, Kerala & Mahe',
  'ST JOSEPH HSS PAVARATTY THRISSUR, Kerala & Mahe',
  'St joseph hss peruvanthanam, Kerala & Mahe',
  'st joseph hss sulthan bathery, Kerala & Mahe',
  'ST JOSEPH HSS TVM, Kerala & Mahe',
  'ST JOSEPH PUBLIC SCHOOL PATTANAKAD, Kerala & Mahe',
  "ST JOSEPH'S BOYS' HIGHER SECONDARY SCHOOL KOZHIKODE, Kerala & Mahe",
  "ST JOSEPH'S GHSS ALAPPUZHA, Kerala & Mahe",
  "ST JOSEPH'S GHSS CHANGANASSERY, Kerala & Mahe",
  "St Joseph's has,  Vayatuparamba, Kerala & Mahe",
  "St Joseph's Higher secondary school, Thirithuvapuram, Kerala & Mahe",
  "ST JOSEPH'S HSS PERUVANTHANAM, Kerala & Mahe",
  'ST JOSEPHS CONVENT GHSS KOLLAM, Kerala & Mahe',
  'ST JOSEPHS H S S CHENGAL, Kerala & Mahe',
  'ST JOSEPHS H S S MATHILAKAM, Kerala & Mahe',
  'ST JOSEPHS HSS THALASSERY, Kerala & Mahe',
  'ST JOSEPHS MODEL HSS KURIACHIRA, Kerala & Mahe',
  'ST JUDE HSS MUKHATHALA KOLLAM, Kerala & Mahe',
  'ST JUDE PUBLIC SCHOOL, KUTTANELLOR, THRISSUR, Kerala & Mahe',
  'ST KURIAKOSE SENIOR SECONDARY SCHOOL, Kerala & Mahe',
  "ST MARY'S  school poojapura, Kerala & Mahe",
  "ST MARY'S GHHS KAYAMKULAM, Kerala & Mahe",
  "ST MARY'S GHSS KUZHIKATTUSSERY , Kerala & Mahe",
  "ST MARY'S HSS,PATTOM, Kerala & Mahe",
  "ST MARY'S RESIDENTIAL SCHOOL PATHANAPURAM, Kerala & Mahe",
  "ST MARY'S RESIDENTIAL SCHOOL ULIYACOVIL,KOLLAM, Kerala & Mahe",
  'St marys covent school Alakode, Kerala & Mahe',
  'ST MARYS GHSS KANJIRAPPALLY, Kerala & Mahe',
  'St Marys HSS Kizhakkekara, Kottarakkara, Kerala & Mahe',
  'St Marys HSS Koodathai, Kerala & Mahe',
  'ST MARYS HSS PARIYAPURAM, Kerala & Mahe',
  'ST MARYS RESIDENTIAL CENTRAL SCHOOL ALAPPUZHA, Kerala & Mahe',
  'ST MARYS RESIDENTIAL PUBLIC SCHOOL THIRUVALLA, Kerala & Mahe',
  'ST MARYS RESIDENTIAL SCHOOL RAMANKULANGARA KOLLAM, Kerala & Mahe',
  'ST MERY RESIDENTIAL SCHOOL THIRUVELLA, Kerala & Mahe',
  "ST MICHAEL'S ANGLO INDIAN HIGHER SECONDARY SCHOOL, KANNUR, Kerala & Mahe",
  'ST MICHAELS A I H S S KANNUR, Kerala & Mahe',
  "ST PATRICK'S ACADEMY ANGAMALY, Kerala & Mahe",
  "St Paui'sEMHSS,Thenhippalam, Kerala & Mahe",
  "ST PAUL'S INTERNATIONAL SCHOOL, KALAMASSERY, Kerala & Mahe",
  'ST PAULS HSS VALIYAKUMARAMANGALAM, Kerala & Mahe',
  'ST PETER S H S S KURUMPANADOM, Kerala & Mahe',
  "St peter's senior secondary school,kolenchery,Ernakulam, Kerala & Mahe",
  "ST RAPHAEL'S CATHEDRAL SCHOOL , Kerala & Mahe",
  "ST SEBASTIAN'S HSS GOTHURUTHY, N.PARAVUR , ERNAKULAM, Kerala & Mahe",
  'ST STEPHENS HSS PATHANAPURAM, Kerala & Mahe',
  "ST TERESA'S C.G.H.S.S EKM, Kerala & Mahe",
  'ST TERESAS A I H S S KANNUR, Kerala & Mahe',
  "ST THERESA'S HSS CHALAKKARA MAHE, Kerala & Mahe",
  'ST THERESE HSS SHORANUR , Kerala & Mahe',
  'ST Thomas Cental School Mukkolackal Thiruvananthapuram, Kerala & Mahe',
  'ST THOMAS HSS PUNALUR, Kerala & Mahe',
  'ST Thomas HSS, MUKKOLAKKAL, TRIVANDRUM, Kerala & Mahe',
  'ST THOMAS PUBLIC SCHOOL MUVATTUPUZHA, Kerala & Mahe',
  'St Thomas Residential School, Thiruvananthapuram, Kerala & Mahe',
  'ST THOMAS SENIOR SECONDARY SCHOOL, Kerala & Mahe',
  'St Thomas senior secondary school punalur, Kerala & Mahe',
  'ST VINCENT PALLOTTI CENTRAL SCHOOL,THRISSUR , Kerala & Mahe',
  "St'Gorettis higher secondary school, Kerala & Mahe",
  "ST, ANTONY'S H.S.S, MUTHOLY, Kerala & Mahe",
  'St. Aloysius Convent I.S.C School, Kerala & Mahe',
  'St. Antonys H S S Kanjiracode, Kerala & Mahe',
  'St. Antonys HSS Kacheripady, Kerala & Mahe',
  "St. Augustine's Higher Secondary School, Kerala & Mahe",
  'St. Chrysostom GHS, Nettimoodu, Kerala & Mahe',
  "ST. DOMINIC'S CONVENT ENGLISH MEDIUM SCHOOL, Kerala & Mahe",
  "ST. EPHREM'S HSS, MANNANAM,KOTTAYAM, Kerala & Mahe",
  'St. Francis GHSS, Aluva, Kerala & Mahe',
  'St. George H S S muttar, Kerala & Mahe',
  'ST. GORETTI HSS PUNALUR, Kerala & Mahe',
  'St. Gregorios Higher Secondary School Kottarakara, Kerala & Mahe',
  'St. Ignatius Vocational & Higher Secondary School, Kerala & Mahe',
  "ST. JEROME'S HSS , VELLAYAMKUDY , Kerala & Mahe",
  'St. Johns model HSS Nalanchira, TVM, Kerala & Mahe',
  'St. JOSEPH CONVENT GHSS KOLLAM, Kerala & Mahe',
  'St. JOSEPH PUBLIC SCHOOL PATTANAKKAD, Kerala & Mahe',
  "St. Joseph's Anglo-Indian Girl's Higher Secondary School, Kerala & Mahe",
  "ST. JOSEPH'S BOYS HIGHER SECONDARY SCHOOL, Kerala & Mahe",
  "St. Joseph's Central School and Junior College, Kerala & Mahe",
  "St. Joseph's Convent GHSS, Kollam, Kerala & Mahe",
  "St. joseph's HSS  Trivandrum, Kerala & Mahe",
  "ST. JOSEPH'S HSS THALASSERY, Kerala & Mahe",
  "St. Joseph's public school, Kerala & Mahe",
  'St. Josephs HSS Chengal , Kerala & Mahe',
  'St. Josseph,s HSS Koonamavu, Kerala & Mahe',
  'St. Jude Public School, Kuttanellor, Kerala & Mahe',
  'ST. KURIAKOSE SENIOR SECONDARY SCHOOL, Kerala & Mahe',
  "St. Mary's Higher Secondary School Irinjalakuda , Kerala & Mahe",
  'ST. MARYS CENTRAL SCHOOL RANNI, PATHANAMTHITTA, Kerala & Mahe',
  "ST. SEBASTIAN'S H.S.S VAZHITHALA, Kerala & Mahe",
  "ST. TERESA'S CONVENT GIRLS HIGHER SECONDARY SCHOOL, Kerala & Mahe",
  'ST. VINCENT HSS PALA, Kerala & Mahe',
  'St.Aloysius HSS Elthuruth, Kerala & Mahe',
  'St.Aloysius Senior Secondary School, Kerala & Mahe',
  'St.Alphonsa English Medium School Thamarassery , Kerala & Mahe',
  'St.Anns Higher Secondary School, Eloor, Kerala & Mahe',
  "ST.ANTONY'S HSS KANJIRACODE, Kerala & Mahe",
  "St.Antony's public school , anakkal, Kerala & Mahe",
  'ST.AUGUSTINE H.S.S Aroor, Kerala & Mahe',
  'ST.BEHANANS HSS VENNIKULAM, Kerala & Mahe',
  'St.Dominic Convent English Medium School, Sreekrishnapuram, Kerala & Mahe',
  'ST.FRANCIS EM SCHOOL THOTTADA KANNUR, Kerala & Mahe',
  'St.gemmaÂ´s GHSS malappuram, Kerala & Mahe',
  'ST.GEORGE H S S KULATHUVAYAL, Kerala & Mahe',
  'St.Goretti , Punalur, Kerala & Mahe',
  'St.Gregorios Central School, Kerala & Mahe',
  'St.johns RHS School karuvelil kundara, Kerala & Mahe',
  'St.Joseph Anglo Indian Girls Higher Secondary School, Kerala & Mahe',
  'ST.JOSEPH CONVENT G.H.S.S KOLLAM, Kerala & Mahe',
  'ST.JOSEPH PUBLIC SCHOOL , PATTANAKKAD , CHERTHALA, Kerala & Mahe',
  "ST.JOSEPH'S ANGLO-INDIAN GIRLS HIGHER SECONDARY SCHOOL, Kerala & Mahe",
  "ST.JOSEPH'S BOYS' HIGHER SECONDARY SCHOOL, Kerala & Mahe",
  "St.Joseph's EMHSS Thrikkakara, Kerala & Mahe",
  "ST.JOSEPH'S H.S.S VAYATTUPARAMBA, Kerala & Mahe",
  "ST.JOSEPH'S HIGHER SECONDARY SCHOOL PERAVOOR, Kerala & Mahe",
  'ST.JOSEPH;S HSS,Trivandrum, Kerala & Mahe',
  'ST.JUDES HIGHER SECONDARY SCHOOL VELLARIKUND, Kerala & Mahe',
  'St.Judes Public School , KUTTANALLUR, THRISSUR, Kerala & Mahe',
  'ST.KURIAKOSE PUBLIC SCHOOL,  KADUTHURUTHY , Kerala & Mahe',
  "St.Mary's Higher Secondary School, pattom, Kerala & Mahe",
  "ST.MARY'S HIGHER SECONDARY SCHOOL,THALACODE, Kerala & Mahe",
  "St.Mary's HSS Bhrananganam, Kerala & Mahe",
  'ST.MARY,S RES CEN SCH POOJAPPURA, Kerala & Mahe',
  'st.marys college has,sulthan bathery, Kerala & Mahe',
  'ST.MARYS RESIDENTIAL CENTRAL SCHOOL, ALAPPUZHA , Kerala & Mahe',
  'St.Mary`s H.S.S Kizhakkekara, Kerala & Mahe',
  'ST.Michaels AIHSS school kannur, Kerala & Mahe',
  "St.Paul's International School  , Kerala & Mahe",
  'St.Raphaels cathedral school, Kerala & Mahe',
  "St.sebastian's h.s.s kadanad, Kerala & Mahe",
  "ST.THERESA'S CONVENT GHSS NEYYATTINKARA ,TRIVANDRUM, Kerala & Mahe",
  'St.Thomas College H.S.S,Thrissur, Kerala & Mahe',
  'ST.THOMAS ENG MED SCHOOL PALLICKAL, Kerala & Mahe',
  'ST.Thomas Higher Secondary School, Mukkolakkal, Kerala & Mahe',
  'ST.VINCENT HSS PALA, Kerala & Mahe',
  "St: Raphael's cathedral school, Palakkad, Kerala & Mahe",
  'STANTONYS PUBLIC SCHOOL ANAKKAL KANJIRAPPALLY, Kerala & Mahe',
  'STRAT FORD PUBLIC SCHOOL AND JUNIOR COLLEGE THEVALAKKARA, Kerala & Mahe',
  'SVHSS Palemad, Kerala & Mahe',
  'SVMMHSS,VENDAR,KOLLAM, Kerala & Mahe',
  'SWAMY VIVEKANANDA HIGHER SECONDARY SCHOOL, PANDANAD, Kerala & Mahe',
  'Syrian Jacobite Public School, Varikkad, Thiruvalla, Kerala & Mahe',
  'T D higher secondary school , Kerala & Mahe',
  'T I M GIRLS HSS NADAPURAM, Kerala & Mahe',
  'T K M CENTENARY PUBLIC SCHOOL KARIKODE KOLLAM, Kerala & Mahe',
  'TAGORE VIDYANIKETHAN GOVT.HSS ,THALIPARAMBA, Kerala & Mahe',
  'TALENT PUBLIC SCHOOL, Kerala & Mahe',
  'TECHICAL HIGHER SECONDARY SCHOOL MUTTADA, Kerala & Mahe',
  'TECHNCHNICAL HIGHER SECONDARY SCHOOL ADOOR, Kerala & Mahe',
  'TECHNICAL HIGHER SECONDARY SCHOOL ALUVA, Kerala & Mahe',
  'THADICADU HSS, Kerala & Mahe',
  'Thanbeehul Islam Higher Secondary School , Kerala & Mahe',
  'THAPOVAN PUBLIC SCHOOL,MANAKKALA, Kerala & Mahe',
  'The Baker Vidyapeedh Senior Secondary School, Kerala & Mahe',
  'The Choice School Tripunithura, Kerala & Mahe',
  'THE DAWN PUBLIC SCHOOL, Kerala & Mahe',
  'The Delta Study, Kerala & Mahe',
  'THE GREENHILLS PUBLIC SCHOOL MOOLANKAVE WAYANAD , Kerala & Mahe',
  'The High Range School Mattupatti, Kerala & Mahe',
  'The Model School, Kerala & Mahe',
  'The Oxford School, Kollam, Kerala & Mahe',
  'THE SCHOOL OF THE GOOD SHEPHERD, AKKULAM, THIRUVANANTHAPURAM, Kerala & Mahe',
  'The settlement Higher Secondary school, Kerala & Mahe',
  'The Village International School , Thodupuzha, Kerala & Mahe',
  'THE WARWIN SCHOOL VAIKOM, Kerala & Mahe',
  'THSS Mallappally, Kerala & Mahe',
  'THSS pallipuram, Kerala & Mahe',
  'THSS PERINTHALMANNA, Kerala & Mahe',
  'THSS PUTHUPALLY, Kerala & Mahe',
  'THSS VADAKKANGARA, Kerala & Mahe',
  'TKM CENTENARY PUBLIC SCHOOL, Kerala & Mahe',
  'TM HSS VELLORA, Kerala & Mahe',
  'TOC H PUBLIC SCHOOL, Kerala & Mahe',
  'TOCH SCHOOL, VYTILLA, ERNAKULAM, Kerala & Mahe',
  'Tolins world school , Kerala & Mahe',
  'TRINITY LYCEUM FATIMA ROAD KOLLAM, Kerala & Mahe',
  'Trivandrum International School, Kerala & Mahe',
  'TRK, HSS, Vaniyamkulam, Ottapalam, Kerala & Mahe',
  'UNION HSS MAMBRA, Kerala & Mahe',
  'Ursuline Senior Secondary School , Payyambalam, Kerala & Mahe',
  'V K S HSS VARAKKAD, Kerala & Mahe',
  'V V H S S POREDOM, Kerala & Mahe',
  'V V H S S Thamarakulam, Kerala & Mahe',
  'V.P.M.S.N.P.H.S.S KAZHIMBRAM, Kerala & Mahe',
  'V.S.S.C Central school, Kerala & Mahe',
  'V.V.Higher Secondary School,THAMARAKULAM, Kerala & Mahe',
  'VADUTHALA JAMA-ATH HIGHER SECONDARY SCHOOL , Kerala & Mahe',
  'VASAVI VIDYALAYA, THANNEER PANTHAL, KINASSERY POST, Kerala & Mahe',
  'VAYALAR RAMAVARMA GOV. HSS, Kerala & Mahe',
  'VCSHSS PUTHENVELIKARA, Kerala & Mahe',
  'Veda Vyasa Vidyalayam Senior Secondary School, Kerala & Mahe',
  'Velayudan Memorial HSS, Kerala & Mahe',
  'Venerini higher secondary school,karinkallai,kozhikode, Kerala & Mahe',
  'VGSS AMBIKODAYAM HSS, Kerala & Mahe',
  'VHM HIGHER SECONDARY SCHOOL MORAYUR, Kerala & Mahe',
  'VHSS VALANCHERY, Kerala & Mahe',
  'VIDHYADHI RAJA ATTINGAL, Kerala & Mahe',
  'VIDHYADHIRAJA VIDHYABHAVAN HSS ALUVA, Kerala & Mahe',
  'VIDYODAYA SCHOOL THEVAKKAL, Kerala & Mahe',
  'VIJAYAGIRI PUBLIC SCHOOL ANNALLUR, ASHTAMICHIRA, THRISSUR, KERALA, Kerala & Mahe',
  'VIM HSS PALLASANA, Kerala & Mahe',
  'VIMALA HRIDAYA GHSS PATTATHANAM KOLLAM, Kerala & Mahe',
  'VIMALA PUBLIC SCHOOL THODUPUZHA, Kerala & Mahe',
  'VINCENT DE PAUL HSS PALA, Kerala & Mahe',
  'VISWABHARATHI MODEL H S S,KRISHNAPURAM PO,KAYAMKULAM, Kerala & Mahe',
  'VISWABHARATHY PUB SCHOOL NEYYATTINKARA, Kerala & Mahe',
  'Viswabharathy SN HSS,neezhoor, Kerala & Mahe',
  'Viswadeepthi english medium school, KattakadA, Kerala & Mahe',
  'VISWADEEPTHI PUBLIC SCHOOL,ADIMALY, Kerala & Mahe',
  'VISWAJYOTHI C.M.I PUBLIC SCHOOL, Kerala & Mahe',
  'Viswaprakash Central School, Thirumala, Kerala & Mahe',
  'VISWHABHARATHI S N H S S , Kerala & Mahe',
  'VIVEKANANDA H S S CHANGANKULANGARA, Kerala & Mahe',
  'VIVEKODAYAM BHSS THRISSUR, Kerala & Mahe',
  'VMC GHSS, Wandoor, Malappuram, Kerala, India, Kerala & Mahe',
  'VMC Higher Secondary School Wandoor, Kerala & Mahe',
  'VMCGHSS WANDOOR, Kerala & Mahe',
  'VNSS SN TRUSTS CEN SCH, Kerala & Mahe',
  'VPMSNDP HSS KAZHIMBRAM, Kerala & Mahe',
  'VRVM vayalar , Kerala & Mahe',
  'VSSC CENTRAL SCHOOL,ISRO STAFF QUARTERS, Kerala & Mahe',
  'VVHSS THAMARAKULAM, Kerala & Mahe',
  'VYASA VIDHYA PEETHOM, Kerala & Mahe',
  'Vyasa Vidya Peethom,kallekkad,palakkad, Kerala & Mahe',
  'WOHSS PINANGODE, Kerala & Mahe',
  "ZAMORIN'S HIGHER SECONDARY SCHOOL KOZHIKODE, Kerala & Mahe",
  'A P NARMADA H S SCHOOL, Madhya Pradesh',
  'A.B.M convent H.S. school, Madhya Pradesh',
  'A.M.I. SHISHU MANDIR H.S. SCHOOL , Madhya Pradesh',
  'A.P.N.higher secondary school, Madhya Pradesh',
  'Abhyuday public school, Madhya Pradesh',
  'ADARSH HIGHER SECONDARY SCHOOL, Madhya Pradesh',
  'ADITYA VIDYA MANDIR HIGH SECONDARY SCHOOL, Madhya Pradesh',
  'Advanced Academy, Madhya Pradesh',
  'Agarwal academy, Madhya Pradesh',
  'ajmera mukesh nemi chand bhai school, Madhya Pradesh',
  'Anupama Higher Secondary School, Madhya Pradesh',
  'ARMY PUBLIC SCHOOL , Madhya Pradesh',
  'Asian Public Higher Secondary School, Madhya Pradesh',
  'ATOMIC ENERGY CENTRAL SCHOOL, Madhya Pradesh',
  'Bal Bharati Public school, Madhya Pradesh',
  'Balaghat public school, Madhya Pradesh',
  'BCG PUBLIC SCHOOL, Madhya Pradesh',
  'Bens Public School, Madhya Pradesh',
  'Bhartiya Vidya Mandir, Madhya Pradesh',
  'board of secondary education madhya pradesh, Madhya Pradesh',
  'Bonanza convent higher secondary school , Madhya Pradesh',
  'Bonnie foi school , Madhya Pradesh',
  'Bright career academy , Madhya Pradesh',
  'Bright model hr. Sec. School, Madhya Pradesh',
  'CAMPION  SCHOOL, Madhya Pradesh',
  'Carmel Convent SR Secondary School , Madhya Pradesh',
  'cbse private candidate, Madhya Pradesh',
  'CENTRAL ACADEMY ENG MED SCHOOL , Madhya Pradesh',
  'Chavara CMI Public School, Madhya Pradesh',
  'CHHATRAPATI SHIVAJI PUBLIC SCHOOL, Madhya Pradesh',
  'Children Public High School, Madhya Pradesh',
  'Chitransh Public School , Madhya Pradesh',
  'CHRIST CHURCH BOYS S S SCHOOL, Madhya Pradesh',
  "CHRIST CHURCH Girls' S S SCHOOL, Madhya Pradesh",
  "Colonel's Academy, Madhya Pradesh",
  'columbia conv school, Madhya Pradesh',
  'D C MEMORIAL H S SCHOOL, Madhya Pradesh',
  'D.A.V.SENIOR SECONDARY SCHOOL, Madhya Pradesh',
  'DAV ACC PUBLIC SCHOOL, Madhya Pradesh',
  'DAV public school, Madhya Pradesh',
  'DELHI PUBLIC SCHOOL , Madhya Pradesh',
  'DON BOSCO HS MANNUTHY, Madhya Pradesh',
  'G V N THE GLOBAL SCHOOL, Madhya Pradesh',
  'Good Shepherd Convent Senior Secondary School, Madhya Pradesh',
  'Government higher secondary school, Madhya Pradesh',
  'Govt. modal HS School , Madhya Pradesh',
  'Govt.school for excellence, Madhya Pradesh',
  'Greenwood Public School Adityapuram, Madhya Pradesh',
  'GVN GLOBAL SCHOOL , Madhya Pradesh',
  'GWALIOR GLORY HIGH SCHOOL, Madhya Pradesh',
  'Gyan Ganga International Academy, Madhya Pradesh',
  'GYAN GANGA PUBLIC SCHOOL, Madhya Pradesh',
  'Gyan sagar vidhya niketan., Madhya Pradesh',
  'H D JAIN H S SCHOOL, Madhya Pradesh',
  'Himalaya International School, Madhya Pradesh',
  'Holy Family Convent Sr. Sec. School, Madhya Pradesh',
  'IBS GLOBAL ACADEMY, Madhya Pradesh',
  'INDORE PUBLIC SCHOOL, Madhya Pradesh',
  'INTERNATIONAL PUBLIC SCHOOL, Madhya Pradesh',
  'Jai gurudev international school , Madhya Pradesh',
  'JAWAHAR NAVODAYA VIDYALAYA , Madhya Pradesh',
  'JAWAHARLAL NEHRU BHEL BHOPAL, Madhya Pradesh',
  'JOY SENIOR SECONDARY SCHOOL, Madhya Pradesh',
  'JPV DAV PUBLIC SCHOOL, Madhya Pradesh',
  'Kala Bal Vidhya Mandir, Madhya Pradesh',
  'kautilya education academy, Madhya Pradesh',
  'kempfort public school, Madhya Pradesh',
  'KENDRIYA VIDYALAYA, Madhya Pradesh',
  'Kiddies Corner Hr Sec School, Madhya Pradesh',
  'Kiddys Corner Public School, Madhya Pradesh',
  'LITTLE  WORLD  SCHOOL, Madhya Pradesh',
  'Little kingdom Senior Secondary School, Madhya Pradesh',
  'Maa Mangla Devi Vidya Peeth, Madhya Pradesh',
  'Macro Vision Academy Burhanpur (M.P.), Madhya Pradesh',
  'MAHARISHI VIDYA MANDIR , Madhya Pradesh',
  'Master Mind H S School, Madhya Pradesh',
  'MASTERMIND PUBLIC SCHOOL, Madhya Pradesh',
  'MOTHER TERESA HIGHER SECONDARY SCHOOL, Madhya Pradesh',
  'N.S SINGHVI SCHOOL, Madhya Pradesh',
  'NACHIKETA SENIOR SECONDARY SCHOOL, Madhya Pradesh',
  'New Digamber Public School, Madhya Pradesh',
  'NEW GREEN FIELD ACADEMY, Madhya Pradesh',
  'Nutan Hr. Sec School, Madhya Pradesh',
  'Padmaja Higher Secondary school, Madhya Pradesh',
  'Patel higher secondary school , Madhya Pradesh',
  'PIONEEER CONVENT SCHOOL , Madhya Pradesh',
  'Pioneer Convent School, Madhya Pradesh',
  'PITHAMPUR PUBLIC H.S. SCHOOL, Madhya Pradesh',
  'PRAGATI VIDHYA PEETH, Madhya Pradesh',
  'Pragya Girls School, Madhya Pradesh',
  'Prestige Public School, Madhya Pradesh',
  'Rajeev Gandhi Higher Secondary School , Madhya Pradesh',
  'Rajeshwar Higher Secondary school , Madhya Pradesh',
  'Ramakrishna Vidhya Mandir, Madhya Pradesh',
  'Reema vidya mandir, Madhya Pradesh',
  'RISHI GALAV PUBLIC SCHOOL, Madhya Pradesh',
  'Rishikul Vidya Niketan, Madhya Pradesh',
  'rose mary higher sec. school, Madhya Pradesh',
  'Royal heights higher secondary school, Madhya Pradesh',
  'Ryan international school , Madhya Pradesh',
  'SACRED HEART HIGHER SECONDARY SCHOOL , Madhya Pradesh',
  'Sacred heart school , Madhya Pradesh',
  'Saflta H.Sc. School, Madhya Pradesh',
  'Sagar Public School, Madhya Pradesh',
  'samaritans senior secondary school, Madhya Pradesh',
  'San Marino Public School Indore, Madhya Pradesh',
  'Sanskar Academy Pachore, Madhya Pradesh',
  'Sanskar Public School, Madhya Pradesh',
  'SANT KANWAR RAM HIGHER SECONDARY SCHOOL , Madhya Pradesh',
  'Sarafa Vidya Niketan , Madhya Pradesh',
  'SARDAR PATEL PUBLIC SCHOOL, Madhya Pradesh',
  'Sardar patel sr. Sec school , Madhya Pradesh',
  'Scholars Home Higher Secondary School , Madhya Pradesh',
  'SGS AMNEM SCHOOL, Madhya Pradesh',
  'Shamgarh Public school , Madhya Pradesh',
  'Shiv Jyoti Sr. Sec. School, Madhya Pradesh',
  'Shivpuri public school , Madhya Pradesh',
  'Shree  nav niketan H. S school, Madhya Pradesh',
  'Shree Kasera Bazar Vidya Niketan, Madhya Pradesh',
  'Shri Durge Higher Secondary School, Madhya Pradesh',
  'Sita Devi Higher Secondary School , Madhya Pradesh',
  'Sitamau Public School, Madhya Pradesh',
  'SMALL WONDERS, Madhya Pradesh',
  'Smt kamala saklecha gyan mandir , Madhya Pradesh',
  'ST ARNOLD`S SCHOOL , Madhya Pradesh',
  'ST JOSEPH COED SCHOOL , Madhya Pradesh',
  'St Josephs Convent Sr. Sec. School, Madhya Pradesh',
  "St Mary's co-ed school, Madhya Pradesh",
  "St Michael's Senior Secondary School, Madhya Pradesh",
  'ST PAUL HIGHER SECONDARY SCHOOL, Madhya Pradesh',
  'ST PAUL SR SEC CO ED SCHOOL , Madhya Pradesh',
  'ST. JOSEPH CONVENT SR SEC SCHOOL, Madhya Pradesh',
  "st. joseph's co-ed school , Madhya Pradesh",
  "St. Mary's Convent Senior Seccondary School, Madhya Pradesh",
  'ST. NORBERT SENIOR SECONDARY SCHOOL, Madhya Pradesh',
  'ST. PAUL HIGHER SECONDARY SCHOOL, Madhya Pradesh',
  "St. Raphael's  School , Madhya Pradesh",
  "St.Benedict's School , Madhya Pradesh",
  "St.JOSEPH'S CO ED SCHOOL, Madhya Pradesh",
  'St.Norbert Senior Secondary School, Madhya Pradesh',
  "ST.XAVIER'S SCHOOL, Madhya Pradesh",
  'Standard Public School, Madhya Pradesh',
  'Subhash Excellence govt. hr. sec. school, Madhya Pradesh',
  'Sunrisers Hr Sec School, Madhya Pradesh',
  'THE BHAVANS PROMINENT INTERNATIONAL SCHOOL, Madhya Pradesh',
  'The Daly College, Madhya Pradesh',
  'THE EMERALD HEIGHTS INTERNATIONAL SCHOOL, Madhya Pradesh',
  'THE NEW GREEN FIELD PUBLIC ACADEMY , Madhya Pradesh',
  'The Omni School , Madhya Pradesh',
  'The Sanskaar Valley School, Madhya Pradesh',
  'The Shishukunj International School, Madhya Pradesh',
  'The variety public high secondary school, Madhya Pradesh',
  'UNIQUE ACADEMY, Madhya Pradesh',
  'Vanasthali M. H. S. School, Madhya Pradesh',
  'Vidya Bhawan, Madhya Pradesh',
  'Vidya niketan higher secondary school, Madhya Pradesh',
  'Vidya vijay bal mandir, Madhya Pradesh',
  'VINDHYACHAL ACADEMY, Madhya Pradesh',
  'World way international school , Madhya Pradesh',
  'Yashwant Public school, Madhya Pradesh',
  'A.D.JOSHI JR COLLEGE , Maharashtra',
  'Abasaheb Chinchwade Junior College, Maharashtra',
  'Abhishekh Arts , Commerce&Science Junior College , Maharashtra',
  'Adv. Lalchand junior science and arts college , Maharashtra',
  'AIR FORCE SCHOOL, Maharashtra',
  'Akshara International School, Maharashtra',
  'Al Irfan Secondary And Senior School, Maharashtra',
  'Allan swami Jr. college science, Maharashtra',
  'Allen Swami Vivekanand Junior College of Science, Maharashtra',
  'Alpha Junior college , Maharashtra',
  'amrita vidyalaya, Maharashtra',
  'Amrutvahini International School , Maharashtra',
  'ANGLO URDU BOYS HIGH SCHOOL AND JR COLLEGE, Maharashtra',
  'Annasaheb Magar college, Maharashtra',
  'Apeejay School , Maharashtra',
  'APTE PRASHALA, Maharashtra',
  'arham junior college, Maharashtra',
  'Arihant Arts,Commerce & Science Junior College, Maharashtra',
  'Army public school kamptee, Maharashtra',
  'Arya Gurukul International Junior College of Commerce and Science, Maharashtra',
  'ASHOK VIDYALAYA AND JR COLLEGE, Maharashtra',
  'ASHOKA UNIVERSAL SCHOOL, Maharashtra',
  'ASM Geetamata acs junior College, Maharashtra',
  'ATMA MALIK INTERNATIONAL SCHOOL , Maharashtra',
  'ATOMIC ENERGY CENTRAL SCHOOL , Maharashtra',
  'Avalon Heights International School , Maharashtra',
  'B K Birla college Kalyan, Maharashtra',
  'B.E.S JR COLLOGE OF COMMERCE AND SCIENCE, Maharashtra',
  "B.G.P.S.' Mumbai College of Arts, Commerce & Science, Maharashtra",
  'B.K. Birla Public School Kalyan, Maharashtra',
  'Bajaj College of Science, Maharashtra',
  'Bal Bharati Public School, Maharashtra',
  'Balkrishna junior college , Maharashtra',
  'BHAGWANBABA COLLEGE , Maharashtra',
  'Bhagyashree junior college, Maharashtra',
  'BHARTI KRISHNA VIDHYA VIHAR, Maharashtra',
  "BHAVAN'S BP VIDYA MANDIR, Maharashtra",
  'Bhavani Shankar College, Maharashtra',
  'BHAVANI SHANKAR ROAD JUNIOR COLLEGE OF SCIENCE, Maharashtra',
  'Bhavans College, Maharashtra',
  'BHONSALA MILITARY SCHOOL, Maharashtra',
  'BILLABONG HIGH INTERNATIONAL SCHOOL, Maharashtra',
  'BJM Carmel Academy , Maharashtra',
  'BK BIRLA PUBLIC SCHOOL, Maharashtra',
  'Blossom Public School, Maharashtra',
  'BLUE BELLS JUNIOR COLLEGE, Maharashtra',
  'BOMBAY SCOTTISH SCHOOL, Maharashtra',
  'BRIGHT RIDERS SCHOOL , Maharashtra',
  'CAMBRIDGE ARTS,COMMERCE AND SCIENCE JUNIOR COLLEGE, Maharashtra',
  'Cambridge Junior College, Maharashtra',
  'CENTRE POINT SCHOOL , Maharashtra',
  'Chandrabhan Sharma Junior College of Science and Commerce, Maharashtra',
  'Chatrabhuj Narsee Memorial School, Maharashtra',
  'Chhatrapati Shahu vidyala and jr college, Maharashtra',
  'City International School, Maharashtra',
  'CMS ENGLISH MEDIUM HIGHER SECONDARY SCHOOL, Maharashtra',
  'D A V International School, Maharashtra',
  'D.A.V Public School, Maharashtra',
  'D.Y Patil International School, Maharashtra',
  'Dayanand science college , Maharashtra',
  'DELHI PUBLIC SCHOOL, Maharashtra',
  'Deogiri college , Maharashtra',
  'Deshmukh college , Maharashtra',
  'Dharampeth m.p.deo memorial science college, Maharashtra',
  'Disha Public School And Junior College, Maharashtra',
  'Divine Child Junior College, Maharashtra',
  'DIXIT ROAD JUNIOR COLLEGE OF SCIENCE, Maharashtra',
  "Dnyan Ganga Education Trust's, Maharashtra",
  'Dnyanbhakti junior college , Maharashtra',
  'Dodamarg English School and  Junior College , Maharashtra',
  'DON BOSCO SENIOR SECONDARY SCHOOL , Maharashtra',
  'Dr Ambedkar College, Maharashtra',
  'Dr shamarao kalmadi junior college, Maharashtra',
  'Dr. D.Y.patil A.C.S. jr college , Maharashtra',
  'Dr. Kalmadi Shamarao High School and Jr. College, Maharashtra',
  'Dr. M.K umathe college, Maharashtra',
  'DR.RP NATH JR COLLEGE OF ARTS AND SCIENCE, Maharashtra',
  'DY Patil ACS COLLEGE, Maharashtra',
  'dy patil international school , Maharashtra',
  'FERGUSSON COLLEGE, Maharashtra',
  'FORT INTERNATIONAL ACADEMY, Maharashtra',
  'Fr Agnel Multipurpose School and Jr College, Maharashtra',
  'Fravashi International Academy , Maharashtra',
  'FRIENDS JUNIOR COLLEGE, Maharashtra',
  'G H RAISONI VIDYANIKETAN, Maharashtra',
  'G.A. Highschool & Late Sou. Tanubai Arwade Jr. College, Maharashtra',
  'Gandhi city public school & Jr. College, Maharashtra',
  'GARGI JUNIOR COLLEGE , Maharashtra',
  'Gaurav High school and junior college, Maharashtra',
  'Gayatri English Medium School & Junior College, Maharashtra',
  'GG International School, Maharashtra',
  'GLOBAL INDIAN INTERNATIONAL SCHOOL, Maharashtra',
  'global public school and junior college, Maharashtra',
  'GOLDEN KIDS ENGLISH HIGH SCHOOL AND JUNIOR COLLEGE, Maharashtra',
  'Gondia public school, Maharashtra',
  'Gopi Birla Memorial School, Maharashtra',
  'Govt.residential school , Maharashtra',
  'GREENFINGERS GLOBAL SCHOOL, Maharashtra',
  'gundecha education academy , Maharashtra',
  'harmony Jr college , Maharashtra',
  'High vision International school & Jr. college., Maharashtra',
  'Hill spring International Jr.College of Science &commerce, Maharashtra',
  'Hindi Madhyamik Ucch Madhyamik Vidyalaya, Maharashtra',
  'HIRANANDANI FOUNDATION SCHOOL, Maharashtra',
  'Hislop college, Maharashtra',
  "ICLES' Motilal Jhunjhunwala College, Maharashtra",
  'IDEAL ENGLISH SCHOOL AND JUNIOR COLLEGE, Maharashtra',
  'IDUBS COLLEGE, Maharashtra',
  'Indira National School, Maharashtra',
  "ITCI's Cambridge Junior College, Maharashtra",
  'Jagadambha junior vollege, Maharashtra',
  'Jai Bharat College of Science, Maharashtra',
  'JAI HIND COLLEGE, Maharashtra',
  'JAI HIND HIGH SCHOOL AND JUNIOR COLLEGE, Maharashtra',
  'JAIN INTERNATIONAL SCHOOL, Maharashtra',
  'jaipuriar school, Maharashtra',
  'Jamnabai Narsee School, Maharashtra',
  'JAWAHAR NAVODAYA VIDYALAYA, Maharashtra',
  'Jawaharlal Nehru sec and high sec junior college, Maharashtra',
  'JUNIOR COLLEGE OF ART COMMERCE AND SCIENCE , Maharashtra',
  'Junior science college, Maharashtra',
  'K P C Junior College of Science and Commerce, Maharashtra',
  'K.E.S Junior College of Science, Maharashtra',
  'K.M AGRAWAL COLLEGE OF ARTS,COMMERCE & SCIENCE, Maharashtra',
  'K.M. Agrawal college, Maharashtra',
  'K.P.C JR College of Science & Commerce, Maharashtra',
  'Karnataka Lingayat Education Society(KLE), Maharashtra',
  'KENDRIYA  VIDYALAYA , Maharashtra',
  'KES COLLAGE OF JUNIOR SCIENCE, Maharashtra',
  'Keshavraj JUNIOR college , Maharashtra',
  'khar education society, Maharashtra',
  'KISHINCHAND CHELLARAM COLLEGE, Maharashtra',
  'KJ SOMAIYA JUNIOR COLLEGE OF SCIENCE AND COMMERCE, Maharashtra',
  'Kolhapur Public School, Maharashtra',
  'KPC College of Sciences and Commerce, Maharashtra',
  'KPC Jr College of Sciences and Commerce, Maharashtra',
  'Kusumtai JR College, Maharashtra',
  'LATE P B JOG JUNIOR COLLEGE OF SCIENCE AND COMMERCE, Maharashtra',
  'Lokhandwala Foundation School, Maharashtra',
  'LOKNETE VYANKATRAO HIRAY COLLEGE , Maharashtra',
  'LOKSEVA e SCHOOL AND JR. COLLEGE, Maharashtra',
  'M J Junior College of Science, Maharashtra',
  'M t balwadkar jr college , Maharashtra',
  'M.J. junior college of science , Maharashtra',
  'M.P. INTERNATIONAL SCHOOL AND JUNIOR COLLEGE, Maharashtra',
  'M.T.Balwadkar jr. College , Maharashtra',
  'Maharashtra junior college , Maharashtra',
  'Maharashtra Vidyalaya Junior College, Maharashtra',
  'MAHATMA bashwashwar jr college , Maharashtra',
  'Mahatma college of arts science and commerce , Maharashtra',
  'Mahatma Phule junior College, Maharashtra',
  'MAHATMA PHULE VASAHTIGRUH M P INTERNATIONAL SCHOOL AND JUNIOR COLLEGE, Maharashtra',
  'MAHATMA SCHOOL(CBSE), Maharashtra',
  'MAJOR HEMANT JAKATE INSTITUTE OF SCIENCE AND COMMERCE, Maharashtra',
  'MANSUKHBHAI KOTHARI NATIONAL SCHOOL, Maharashtra',
  'Mathoshree Prabodhini Junior College of Science , Maharashtra',
  'Matoshri junior college of engineering , Maharashtra',
  'Matosri Gangama Shivji Kothari Gujrathi Vidyalaya and Junior College Science, Maharashtra',
  'MATRIX ACADEMY SHOOL, Maharashtra',
  'MNR SCHOOL OF EXCELLENCE , Maharashtra',
  'MODEL COLLEGE , Maharashtra',
  'Modern High school and Junior College, Maharashtra',
  'MODERN JUNIOR COLLEGE, Maharashtra',
  'Modern School, Maharashtra',
  'MOHANLAL RAMAUTAR MANDHANA ARTS,COMMERCE AND SCIENCE JR.COLLEGE, Maharashtra',
  'Montfort school , Maharashtra',
  'MOUNT ST ANN HIGH SCHOOL, Maharashtra',
  'MP International School and Junior College, Maharashtra',
  "MSP Mandal's Deogiri college, Maharashtra",
  'Muljibhai Mehta International School, Maharashtra',
  'MULUND VIDYA MANDIR, Maharashtra',
  'MUMBAI JR COLLEGE OF ARTS, COMMERCE AND SCIENCE, Maharashtra',
  'N. H. Ranka jr. College, Maharashtra',
  'N.E.S.Science college, Maharashtra',
  'N.K.T.T. Jr College , Maharashtra',
  'Namo Rims International School And Junior College, Maharashtra',
  'NARAYANA JUIOR COLLEGE, Maharashtra',
  'Narayana Vidyalayam, Maharashtra',
  'NASHIK CAMBRIDGE, Maharashtra',
  'National Institute of Open Schooling, Maharashtra',
  'NAVY CHILDREN SCHOOL, Maharashtra',
  'Nes junior college of science and commerce , Maharashtra',
  'NEW ENGLISH HIGH SCHOOL AND JUNIOR COLLEGE, Maharashtra',
  'New Horizon Public School, Maharashtra',
  'New India Secondary and Higher Secondary  College, Maharashtra',
  'Nirala Junior College, Maharashtra',
  'Nirmal college , Maharashtra',
  'Nirmal Junior college, Maharashtra',
  'NIRMALA MEMORIAL FOUNDATION JR COLLEGE OF COMMERCE & SCIENCE, Maharashtra',
  'North Point School, Maharashtra',
  'Nowrosjee Wadia College, Maharashtra',
  'Nutan Bharat Jr. College, Maharashtra',
  'Nutan vidalaya , Maharashtra',
  'Nutan Vidya Mandir, Maharashtra',
  'NUTAN VIDYAMANDIR, Maharashtra',
  'Orchid Juior College, Maharashtra',
  'PACE  Junior Science College, Maharashtra',
  'PARLE TILAK VIDYALAYA, Maharashtra',
  'PODAR INTERNATIONAL SCHOOL, Maharashtra',
  'PRAGNYA JUNIOR COLLEGE, Maharashtra',
  'R N PODAR HIGH SCHOOL, Maharashtra',
  'R.K.TALREJA, Maharashtra',
  'R.r international junior college of science and commerce, Maharashtra',
  'R.R. International Jr and Sr College, Maharashtra',
  'Rahul gandhi madhamic vidhyalay , Maharashtra',
  'Rahul International school, Maharashtra',
  'Rajaram College , Maharashtra',
  'Rajarshi Shahu Jr college , Maharashtra',
  'RAJIV GANDHI ACADEMY OF ELEARNING SCHOOL AND JUNIOR COLLEGE OF SCIENCE, Maharashtra',
  'Ramniranjan Jhunjhunwala college , Maharashtra',
  'ramsheth thakur public school, Maharashtra',
  'RAO JUNIOR COLLEGE OF SCIENCE, Maharashtra',
  'RATANBAI WALBAI JUNIOR COLLEGE OF SCIENCE, Maharashtra',
  'Ravindra bharti jr college of science and commerce, Maharashtra',
  'Ravindranath Tagore High School, Maharashtra',
  'Rd national college, Maharashtra',
  'RELIANCE FOUNDATION SCHOOL, Maharashtra',
  'RN PODAR SCHOOL, Maharashtra',
  'ROYAL COLLEGE OF ARTS, SCIENCE AND COMMERCE, Maharashtra',
  'ROYAL JUNIOR COLLEGE, Maharashtra',
  'RP junior college of arts, science and commerce , Maharashtra',
  'Rustomjee international school and Junior college, Maharashtra',
  'Ryan International School, Maharashtra',
  'S v b d college niwana, Maharashtra',
  'S.B.J Junior college of science and commerce, Maharashtra',
  'S.E.S Jr. College of Arts and Science, Maharashtra',
  'S.M Shetty High school and junior college, Maharashtra',
  'S.M. Choksey High School and Junior College, Maharashtra',
  'S.M.T. SBJ JUNIOR COLLEGE OF SCIENCE, Maharashtra',
  'S.N.B.P INTERNATIONAL SCHOOL, Maharashtra',
  'S.S.M.M. COLLEGE OF ARTS , COMMERCE AND SCIENCE , Maharashtra',
  'S.V. SCHOOL & JUNIOR COLLEGE, Maharashtra',
  'SAINIK SCHOOL SATARA , Maharashtra',
  'SAINT MONICA ENGLISH SCHOOL, Maharashtra',
  'Sandipani School, Maharashtra',
  'Sangmeshwar college , Maharashtra',
  'Sanjay Gandhi Junior College , Maharashtra',
  'Sanjay Ghodawat International School, Maharashtra',
  'Sanjay Ghodawat Junior College, Maharashtra',
  'SANT TUKARAM NATIONAL MODEL SCHOOL, Maharashtra',
  'Sanyoogita patil cambridge school & jr.college , Maharashtra',
  'Saraswathi Vidyalaya, Maharashtra',
  'SAROSH JUNIOR COLLEGE, Maharashtra',
  'School of scholars, Maharashtra',
  'Seth hirchand mutha college , Maharashtra',
  'SETH KANJIBHAI VITHALDAS PAREKH ANAND JUNIOR COLLEGE OF ARTS, SCIENCE AND COMMERCE, Maharashtra',
  'Seva sadan high school, Maharashtra',
  'Seven square academy, Maharashtra',
  'SEVENTH DAY ADVENTIST HIGHER SECONDARY SCHOOL, Maharashtra',
  'Shanti Junior College of Science, Maharashtra',
  'Sheth vidya mandir junior college of science and commerce , Maharashtra',
  'Shiv Chhatrapati College , Maharashtra',
  'SHREE H.B. GIRME JUNIOR COLLEGE, Maharashtra',
  'Shri ankhiridevi junior college, Maharashtra',
  'Shri chhatrapati Shivaji junior science college, Maharashtra',
  'SHRI DAWALE JR. COLLEGE, Maharashtra',
  'SHRI MAHARSHI VIDYAMANDIR , Maharashtra',
  'Shri Mhalsakant Vidyalaya , Maharashtra',
  'Shri Shivaji Junior collage , Maharashtra',
  'Shri Shivaji science College , Maharashtra',
  'Shri Sushil Kumar Thirani Junior College of Commerce and Science , Maharashtra',
  'Shri T P Bhatia College of Science, Maharashtra',
  'Shri tripura junior science college , Maharashtra',
  'Shriram junior college kada, Maharashtra',
  'Shubham Raje Jr College, Maharashtra',
  'Sies college of arts science and commerce, Maharashtra',
  'sir parshurambhau college, Maharashtra',
  'SMT LILAVATIBAI PODAR HIGH SCHOOL, Maharashtra',
  'SMT SBJ JUNIOR COLLEGE OF SCIENCE AND COMMERCE, Maharashtra',
  'Smt. Sulochanadevi Singhania School, Maharashtra',
  'SNBP International School, Maharashtra',
  'SOU Vijayadevi Yadav English Medium School, Maharashtra',
  'Springdale junior college, Maharashtra',
  'Sri Chaitanya College , Maharashtra',
  'SRI CHAITANYA JUNIOR COLLEGE, Maharashtra',
  'Sri Ma Vidyalaya, Maharashtra',
  'SS And LS Patkar Varde College, Maharashtra',
  'SS HIGH SCHOOL & JR. COLLEGE, Maharashtra',
  "SSVPS'S L. K Dr. P. R Ghogrey Jr. College of science, Maharashtra",
  "St Andrew's College of Arts, Science and Commerce, Maharashtra",
  'st anthony convent high school, Maharashtra',
  'St Joseph high school, Maharashtra',
  "St Mary's School and Junior College, Maharashtra",
  'st Marys multipurpose high school , Maharashtra',
  'st paul high school, Maharashtra',
  'St Paul jr college hudkwshwar , Maharashtra',
  'ST VINCENT SCHOOL AND JUNIOR COLLEGE, Maharashtra',
  'St Xaviers High Schol, Maharashtra',
  "ST. GEORGE'S COLLEGE JUNIOR , Maharashtra",
  'ST. MARYS SCHOOL, Maharashtra',
  'ST. PAUL JUNIOR COLLEGE, Maharashtra',
  "St. Peter's Junior College, Maharashtra",
  'ST. WILFRED SCHOOL, Maharashtra',
  'Star English medium school and jr. College, Maharashtra',
  'STEPPING STONES HIGH SCHOOL, Maharashtra',
  'Subham Raje Junior College, Maharashtra',
  'Sudarshan College of arts xommerce and sceince, Maharashtra',
  'Suryadatta National School, Maharashtra',
  'Suryadatta Public School, Maharashtra',
  'swami ramkrishna paramhans junior college, Maharashtra',
  'Swami vivekanad junior college , Maharashtra',
  'Swami Vivekanand High School and Junior College, Maharashtra',
  'TATYASAHEB MUSALE VIDYALAYA & JR. COLLEGE , Maharashtra',
  'Taywade college , Maharashtra',
  'Taywade Junior College, Maharashtra',
  'Terna Vidyalaya , Maharashtra',
  'THAKUR COLLEGE OF SCIENCE AND COMMERCE, Maharashtra',
  'Thakur Vidya Mandir High School And Junior College, Maharashtra',
  'THE AIR FORCE SCHOOL, Maharashtra',
  'The Bishops Co-Ed School, Maharashtra',
  'The Bishops School, Maharashtra',
  'The Jain International School, Maharashtra',
  'The Lexicon International School, Maharashtra',
  'THE NEW COLLEGE , Maharashtra',
  'THE NEW HIGH SCHOOL AND JUNIOR COLLEGE , Maharashtra',
  'THE ORBIS SCHOOL, Maharashtra',
  'THE ORCHID SCHOOL, Maharashtra',
  'The Royal Gondwana Public School, Maharashtra',
  'TILAK PUBLIC SCHOOL, Maharashtra',
  'UNIVERSAL HIGH SCHOOL, Maharashtra',
  'Universal Junior college , Maharashtra',
  'Unnatti Junior College, Maharashtra',
  'UTKARSHA MADHYAMIK VIDYALAYA & JUNIOR COLLEGE, Maharashtra',
  'V.G.Vaze College Of Arts Science And Commerce, Maharashtra',
  'Vardhaman junior college, Maharashtra',
  'Vasantrao Naik college, Maharashtra',
  'Vidhyanidhi Junior College OF Science, Maharashtra',
  'VIDYADHAM SCIENCE COLLEGE, Maharashtra',
  'VIDYANIKETAN JR.COLLEGE OF COMMERCE & SCIENCE, Maharashtra',
  'VIKHE PATIL MEMORIAL SCHOOL, Maharashtra',
  'Vinod shivaji bhate and junior college of sangli, Maharashtra',
  'Vitthalrao dhumal junior college, Maharashtra',
  'VIVEKANAND COLLEGE, Maharashtra',
  'walchand college of arts and science, Maharashtra',
  'Warna Vidyaniketan And Aabasaheb Patil Junior College Of Science, Maharashtra',
  'Yak public school, Maharashtra',
  'Yashoda Public Higher Secondary School, Maharashtra',
  'YC military school , Maharashtra',
  'ZB Patil High School, Maharashtra',
  'Delhi Public School, Manipur',
  'HERBERT SCHOOL , Manipur',
  'Kendriya Vidyalaya , Manipur',
  'little angles Englsh school, Manipur',
  'ARMY PUBLIC SCHOOL , Meghalaya',
  'B.K. Bajoria School, Meghalaya',
  'BSF Senior Secondary School, Meghalaya',
  'Kendriya Vidyalaya , Meghalaya',
  'Pine mount school, Meghalaya',
  'G Rio School, Nagaland',
  'Pranab Vidyapith Higher Secondary School, Nagaland',
  'Adyant higher secondary school, Odisha',
  'CARMEL ENGLISH SCHOOL, Odisha',
  'Chandrasekhar Academy, Odisha',
  'COLLEGE OF BASIC SCIENCE AND HUMANITIES, Odisha',
  'COUNCIL OF HIGHER SECONDARY EDUCATION, Odisha',
  'D.A.V Public School, Odisha',
  'Dalmia Vidya Mandir, Odisha',
  'DEEPIKA ENGLISH MEDIUM SCHOOL , Odisha',
  'DELHI PUBLIC SCHOOL, Odisha',
  'DR. ANK DAV PUBLIC SCHOOL, Odisha',
  'FIITJEE Higher Secondary School, Odisha',
  'GANDHI PUBLIC SCHOOL, Odisha',
  'GUIDANCE ENGLISH MEDIUM SCHOOL, Odisha',
  'INSTITUTE OF HIGHER SECONDARY EDUCATION, Odisha',
  'JAWAHAR NAVODAYA VIDYALAYA , Odisha',
  'Jupiter higher secondary school, Odisha',
  'KENDRIYA VIDYALAYA, Odisha',
  'Keneddy high school , Odisha',
  'KIIT INTERNATIONAL SCHOOL , Odisha',
  'LOYOLA SCHOOL , Odisha',
  'LR DAV PUBLIC SCHOOL, Odisha',
  'MBS PUBLIC SCHOOL , Odisha',
  "Mother's Public School, Odisha",
  'NALANDA PUBLIC SCHOOL, Odisha',
  'Nalanda vidya mandir, Odisha',
  'O.P. Jindal School, Odisha',
  'ODM PUBLIC SCHOOL, Odisha',
  'Prabhujee English Medium School, Odisha',
  'Rabindra Vidya Niketan, Odisha',
  'Rajdhani college, Odisha',
  'Ramadevi Junior College, Odisha',
  'SAI International School, Odisha',
  'Sainik School Bhubaneswar, Odisha',
  'Saraswati Junior Vidya Mandir, Odisha',
  'Shakti Higher Secondary School, Odisha',
  'Shree Krishna International School, Odisha',
  'SRI SATYA SAI VIDYA VIHAR, Odisha',
  "St Paul's School, Odisha",
  'ST VINCENTS CONVENT SCHOOL, Odisha',
  'Stewart School, Odisha',
  'SUDHANANDA VIDYALAYA, Odisha',
  'talent college of science and technology, Odisha',
  'The Daly College, Odisha',
  'V.S.Vidyalaya, Odisha',
  'VIKASH RESIDENTIAL SCHOOL, Odisha',
  'APEEJAY SCHOOL, Punjab',
  'ARMY PUBLIC SCHOOL, Punjab',
  'BBMB DAV Public School, Punjab',
  'BCM Arya Model Sr. Sec. School, Punjab',
  'BCM School, Punjab',
  'Cambridge International Foundation School, Punjab',
  'Captain amol kalia school Jindwari , Punjab',
  'DAV Public School , Punjab',
  'DC MODEL SR SEC SCHOOL, Punjab',
  'Delhi Public School , Punjab',
  'DPS Jalandhar, Punjab',
  'Government Senior Secondary School (Multipurpose), Punjab',
  'GTB Int. Sr. Sec School, Punjab',
  'Guru nanak public school, Punjab',
  'innocent hearts school, Punjab',
  'Jai vatika public school, Punjab',
  'KB DAV Senior Secondary Public School, Punjab',
  'kendriya vidyalaya , Punjab',
  'Lord mahavira jain public school, Punjab',
  'LRS DAV SR SEC MODEL SCHOOL , Punjab',
  'Mahindra Public School, Punjab',
  'Montgomery Guru Nanak Public School, Punjab',
  'Mukat Public School, Punjab',
  'PINE GROVE PUBLIC SCHOOL, Punjab',
  'R.S.model sr. Sec. School , Punjab',
  'Rosemary Convent School, Punjab',
  'S B Senior Secondary School , Punjab',
  'S.d. model senior secondary school, Punjab',
  'Sacred heart sen sec school, Punjab',
  'SAFFRON CITY SCHOOL, Punjab',
  'Sanawar an institute for children, Punjab',
  'Sd senior secondary school, Punjab',
  'Sidana International School, Punjab',
  'Snt. Joseph School, Punjab',
  'Springdale Senior  Secondary School, Punjab',
  'SSD KRISHNA VATIKA SEN SEC SCHOOL, Punjab',
  'ST SOLDIER DIVINE PUBLIC SCHOOL, Punjab',
  "ST. JOSEPH'S BOYS SCHOOL, Punjab",
  'St. Kabir Public School, Punjab',
  "ST. Mary's School , Punjab",
  'ST. SOLDIER DIVINE PUBLIC SCHOOL, Punjab',
  'STAR PLUS CONVENT SCHOOL , Punjab',
  'sudesh vatika convent school, Punjab',
  'SUMMER HILL CONVENT SCHOOL, Punjab',
  'TAGORE MODEL SENIOR SECONDARY SCHOOL , Punjab',
  'The  senior scholar school, Punjab',
  'THE MILLENIUM SCHOOL, Punjab',
  'THE SENIOR STUDY II, Punjab',
  'A.V.M convent school, Rajasthan',
  'AASTHA ACADEMY SR SEC SCHOOL, Rajasthan',
  'ABHIGYAn sr. Sec school, Rajasthan',
  'accent international  sr sec school, Rajasthan',
  'ADARSH INDIAN PUBLIC SEC SCHOOL, Rajasthan',
  'Adinath Public School, Rajasthan',
  'ADITYA PUBLIC SENIOR SECONDARY SCHOOL BANSWARA, Rajasthan',
  'Akank public school kota, Rajasthan',
  'Aklank DayBoarding Cum Residential School, Rajasthan',
  'AKLANK PUBLIC SCHOOL , Rajasthan',
  'ALOK PUBLIC SCHOOL, Rajasthan',
  'Anand Vidya Mandir , Rajasthan',
  'Ankur Senior Secondary School , Rajasthan',
  'Apex academy se sec school, Rajasthan',
  'Apex acedemy senior secondary school, Rajasthan',
  'Arcadia academy , Rajasthan',
  'Arihant Public School, Rajasthan',
  'ARMY PUBLIC SCHOOL, Rajasthan',
  'Asian children academy senior secondary school, Rajasthan',
  'Asteward Morris senior secondary school, Rajasthan',
  'ATOMIC ENERGY CENTRAL SCHOOL, Rajasthan',
  'AVS public sr sec school, Rajasthan',
  'B V B VIDYASHRAM PRATAP NAGAR JAIPUR, Rajasthan',
  'BAKSHIS SPRINGDALES SR SEC SCHOOL, Rajasthan',
  'Bala Vidya Mandir Senior Secondary School, Rajasthan',
  'Banasthali Vidhyapith, Rajasthan',
  'Bansal Public School, Rajasthan',
  'BANSAL PUBLIC SCHOOL KOTA RAJASTHAN, Rajasthan',
  'BHAGAT PUBLIC SCHOOL, Rajasthan',
  'Bhagat Public Sr. Sec. School , Rajasthan',
  'Bharatiya vidya bhavan vidyasharam , Rajasthan',
  'BHARTIYA  SR SEC SCH , Rajasthan',
  'Bhuvnesh Bal Vidyalaya, Rajasthan',
  'BIRLA BALIKA VIDYAPEETH , Rajasthan',
  'BIRLA PUBLIC SCHOOL, Rajasthan',
  'Birla school pilani , Rajasthan',
  'BLUE HEAVEN VIDYALAYA, Rajasthan',
  'Blue star public sr. Sec. School, Rajasthan',
  'Bright future sr sec school , Rajasthan',
  'Bright India public school, Rajasthan',
  'BRIGHT India PUBLIC school AJMER , Rajasthan',
  'BRIGHT MOON PUBLIC SCHOOL, Rajasthan',
  'Bright Senior Secondary School , Rajasthan',
  'BSN ACADEMY , Rajasthan',
  'BUHANA CHILDREN ACADEMY SE. SEC. SCHOOL, Rajasthan',
  'BVB Vidyashram, Rajasthan',
  'BVB VIVEKANANDA , Rajasthan',
  'Cambridge Court High School, Rajasthan',
  'Career Point Gurukul, Rajasthan',
  'carrer point gurukul school, Rajasthan',
  'CENTRAL ACADEMY, Rajasthan',
  "Children's Academy Convent School, Rajasthan",
  'CP GURUKUL SCHOOL , Rajasthan',
  'D.A.V. PUBLIC SCHOOL, Rajasthan',
  'D.S. Science Academy, Rajasthan',
  'DAV Centenary Public School, Rajasthan',
  'DAV HZL SR SEC SCHOOL , Rajasthan',
  'DAV Pubic School, Rajasthan',
  'Dayanand Bal Niketan Sr. Sec. School, Rajasthan',
  'Dayanand Vidya Vihar , Rajasthan',
  'DEFENCE PUBLIC SCHOOL, Rajasthan',
  'Delhi Public School, Rajasthan',
  'Disha Delphi global Sr sec school, Rajasthan',
  'Disha Delphi public school , Rajasthan',
  'dundlod public school , Rajasthan',
  'Dushyant vidhya mandir, Rajasthan',
  'Eden International School, Rajasthan',
  'EMMANUEL MISSION SCHOOL, Rajasthan',
  'G R Global academy, Rajasthan',
  'G S JANGID MEMORIAL SCHOOL, Rajasthan',
  'Gems Public School, Rajasthan',
  'GLOBAL PUBLIC SCHOOL , Rajasthan',
  'Gov. Se. Sec. School , Rajasthan',
  'govt senior secondary School , Rajasthan',
  'GOVT SR SEC SCHOOL , Rajasthan',
  'gudha international school , Rajasthan',
  'gurunank public school, Rajasthan',
  'HAPPY HOME PUBLIC SENIOR SECONDARY SCHOOL, Rajasthan',
  'India international school , Rajasthan',
  'Indian convent school, Rajasthan',
  'Indian Public Academy Sr. Sec. School, Rajasthan',
  'Jaipur School, Rajasthan',
  'Jaipuriya Vidyalaya, Rajasthan',
  'JAISHREE PERIWAL HIGH SCHOOL, Rajasthan',
  'Jalore Public School, Rajasthan',
  'JAWAHAR NAVODAYA VIDYALAYA, Rajasthan',
  'jawahar navodya vidyalaya jaswantpura, Rajasthan',
  'Jayshree Periwal High School, Rajasthan',
  'JESUS AND MARY SEC SCHOOL, Rajasthan',
  'JINDAL PUBLIC SCHOOL , Rajasthan',
  'JYOTI BAL VIDHYA MANDIR SR SEC SCHOOL, Rajasthan',
  'KAUTILYA SR SEC SCHOOL , Rajasthan',
  'Ken Bridge School, Rajasthan',
  'KENDRA VIAYALAYA, Rajasthan',
  'KMD public senior secondary school , Rajasthan',
  'Krishna vidhya mandir sr sec , Rajasthan',
  'kuchaman school, Rajasthan',
  'KVM Public Senior Secondary School , Rajasthan',
  'L K Singhania , Rajasthan',
  'L.B.S Senior Secondary school, Rajasthan',
  'l.b.s.m senior secoundry, Rajasthan',
  'Lady Anusuiya Singania Educational Academy, Rajasthan',
  'Lal Bahadur Shastri Convent School, Rajasthan',
  'LAL BAHADUR SHASTRI SR.SEC SCHOOL, Rajasthan',
  'LAV KUSH MODEL SCHOOL, Rajasthan',
  'LBS CONVENT SCHOOL, Rajasthan',
  'LBS PUBLIC SCHOOL, Rajasthan',
  'LBS SR SECONDARY SCHOOL, Rajasthan',
  'Lord Buddha Public School , Rajasthan',
  'M B International  Sr. Sec. School, Rajasthan',
  'M B INTERNATIONAL SCHOOL, Rajasthan',
  'M D S PUBLIC SVHOOL, Rajasthan',
  'M.B. PUBLIC SCHOOL, Rajasthan',
  'MA BHARTI, Rajasthan',
  'MAA BHARTI SR. SEC. SCHOOL, Rajasthan',
  'MAHARAJA SAWAI BHAWANI SINGH SCHOOL, Rajasthan',
  'MAHARAJA SAWAI MAN SINGH VIDHYLAYA, Rajasthan',
  "Maharani gayatri devi girls' school, Rajasthan",
  'mahesh puabic school, Rajasthan',
  'Maheshwari Girls Public School, Rajasthan',
  'MAHESHWARI PUB. SCHOOL, Rajasthan',
  'MAHILA ASHRAM PUBLIC SCHOOL , Rajasthan',
  'Mahrishi Dayanand Public School, Rajasthan',
  'MALVIYA CONVENT SCHOOL, Rajasthan',
  'mangalam dav public school, Rajasthan',
  'Manu public school, Rajasthan',
  'Matrix HIgh School, Rajasthan',
  'Mayoor School , Rajasthan',
  'Mayur noble acadmey , Rajasthan',
  'MB International School, Rajasthan',
  'MB Public Sr Secondary School, Rajasthan',
  'MDS PUBLIC  SCHOOL , Rajasthan',
  'Modern public senior secondary school, Rajasthan',
  'Modi public school , Rajasthan',
  'MOHTA MOOLCHAND GOVT SR SEC SCHOOL, Rajasthan',
  'Mount Litera Zee  School, Rajasthan',
  'Mount Litera Zee High School, Rajasthan',
  'MPs international school, Rajasthan',
  'Naeevn sikhsa niketan, Rajasthan',
  'Nalanda Academy, Rajasthan',
  'NAVJEEVAN ACA SR SEC SCH, Rajasthan',
  'Neerja Modi School, Rajasthan',
  'New look central school, Rajasthan',
  'NEW LOOK UCHTAR MADHYAMIK VIDYALAYA, Rajasthan',
  'Nimawat public school, Rajasthan',
  'Noble International School, Rajasthan',
  'Nosegay Public School, Rajasthan',
  'oxford international academy, Rajasthan',
  'POlKAJI SIKSHA SANSTHAN SIKAR, Rajasthan',
  'PRAGATI PUBLIC SENIOR SECONDRY SCHOOL   , Rajasthan',
  'PRERNA PUB SR. SEC SCHOOL , Rajasthan',
  'RAJAT VIDHYAPEETH SR SEC SCHOOL , Rajasthan',
  'RAJMATA KRISHNA KUMARI GIRLS PUBLIC SCHOOL, Rajasthan',
  'RAWAT PUBLIC SCHOOL, Rajasthan',
  'RED ROSE PUBLIC SR. SEC. SCHOOL, Rajasthan',
  'Rockwoods High School, Rajasthan',
  'Royal academy senior secondary school, Rajasthan',
  'RSM International School , Rajasthan',
  'RUKMANI BIRLA MODERN HIGH SCHOOL, Rajasthan',
  'Ryan International School, Rajasthan',
  'RYAN INTERNATIONAL SCHOOL JAIPUR, Rajasthan',
  'S D Memorial Sr Sec School, Rajasthan',
  'S R Global School, Rajasthan',
  'S R PUBLIC SCHOOL, Rajasthan',
  'S.V public school, Rajasthan',
  'sahara public school, Rajasthan',
  'SAINT ANSELM SENIOR SECONDARY SCHOOL, Rajasthan',
  'SAINT JOHNS SENIOR SECONDARY SCHOOL, Rajasthan',
  "Saint Mary's Convent Senior Secondary School , Rajasthan",
  "Saint Paul's school , Rajasthan",
  'Saint soldier public school, Rajasthan',
  'SAINT VIVEKANAND SR. SEC. SCHOOL, Rajasthan',
  'Saint Xavier Senior Secondary school , Rajasthan',
  'SAMRAT PUBLIC SCHOOL, Rajasthan',
  'SANKALP ACADEMY SR. SEC. SCHOOL, Rajasthan',
  'Sanskar Bharti Senior Secondary School, Rajasthan',
  'Sanskar School, Rajasthan',
  'Sanskriti the school, Rajasthan',
  'Saraswati sr. Sec. School, Rajasthan',
  'SARVODAYA SENIOR SECONDARY HIGH SCHOOL, Rajasthan',
  'SATYAM S S SR SS, Rajasthan',
  'Seedling Modern High School, Rajasthan',
  'Seedling Public School, Rajasthan',
  'SHAHEED MAJ DS SUMAL  GOVT SR SEC SCHOOL , Rajasthan',
  'Sharda Vidhya mandir , Rajasthan',
  'Shastri BN Sr SS, Rajasthan',
  'SHEKHAWATI INT ACADEMY , Rajasthan',
  'Shekhawati International Academy , Rajasthan',
  'Shiksha High School, Rajasthan',
  'SHIV JOYI SR. SEC. SCHOOL , Rajasthan',
  'Shiv Jyoti Convent school, Rajasthan',
  'Shiv Jyoti International School, Rajasthan',
  'Shiv Jyoti Sr. Sec. School, Rajasthan',
  'SHIVJYOTI SENIOR SECONDARY SCHOOL, Rajasthan',
  'shri ganesh sr sec school , Rajasthan',
  'Siant Angila Sophia Sr. Secondary School, Rajasthan',
  'Siddharth public school, Rajasthan',
  'Sir Padampat Singhania Sr Sec School , Rajasthan',
  'Skdb govt senior secondary School, Rajasthan',
  'SMT N D MORDEN SR SEC SS, Rajasthan',
  'soni academy sr sec school , Rajasthan',
  'Sony Academy Sr Sec School, Rajasthan',
  'Sophia Girls Senior Secondary School, Rajasthan',
  'SR PUBLIC SR SEC SCHOOL , Rajasthan',
  'Sri Guru Harkrishan Public School, Rajasthan',
  'SRN INTERNATIONAL SCHOOL, Rajasthan',
  'ST ANSELMS PINK CITY SR SEC SCHOOL, Rajasthan',
  'ST ANTHONYS SENIOR SECONDARY SCHOOL, Rajasthan',
  'ST JOHNS SR SEC SCHOOL , Rajasthan',
  'St joseph international school , Rajasthan',
  'ST JOSEPH PUBLIC SCHOOL , Rajasthan',
  "St Joseph's International School, Rajasthan",
  "St Mary's convent Sr. Sec school, Rajasthan",
  'ST PAUL SENIOR SECONDARY SCHOOL, Rajasthan',
  "St Paul's Sr Secondary School, Rajasthan",
  "St Paul's Sr. Sec. School, Rajasthan",
  'st pauls school , Rajasthan',
  'ST PAULS SR SEC SCHOOL , Rajasthan',
  "ST SOLDIER'S PUBLIC SCHOOL, Rajasthan",
  "ST TERESA'S SENIOR SECONDARY SCHOOL, Rajasthan",
  "St Xavier's senior secondary school, Rajasthan",
  "ST. ANNE'S Sr. Sec. SCHOOL, Rajasthan",
  "St. Anselm's North City School, Rajasthan",
  "St. Anselm's Sr. Sec. School, Rajasthan",
  "St. John's School, Rajasthan",
  'St. Joseph International School, Rajasthan',
  "St. Joseph's public school, Rajasthan",
  "St. Teresa's Sen. Sec. School , Rajasthan",
  "ST.PAUL'S SEN.SEC.SCHOOL, Rajasthan",
  'St.Wilfred School, Rajasthan',
  "ST.XAVIER'S SCHOOL, Rajasthan",
  'Step By Step Public School, Rajasthan',
  'Stepwise International , Rajasthan',
  'Subodh Public School, Rajasthan',
  'Sunrise public school, Rajasthan',
  'SWAMI V V M SR SEC SCHOOL , Rajasthan',
  'SWAMI VIVEKANAND GOVT. MODEL SCHOOL, Rajasthan',
  'SWARAJAYA SENIOR SECONDARY SCHOOL, Rajasthan',
  'TAGORE BAL NIKTEN SS SCHOOL , Rajasthan',
  'Tagore International School, Rajasthan',
  'TAGORE PUBLIC SCHOOL, Rajasthan',
  'Tagore Shikshan Sansthan Sr Sec School, Rajasthan',
  'THE ADITYA BIRLA PUBLIC SCHOOL, Rajasthan',
  'THE SAGAR SCHOOL, Rajasthan',
  'The scholars arena senior secondary English medium school, Rajasthan',
  'TILAK PUBLIC SCHOOL, Rajasthan',
  'tilak senior secondary school, Rajasthan',
  'TIMES PUBLIC SCHOOL, Rajasthan',
  'Tiny tots Senior Secondary School , Rajasthan',
  'Turning Point Public School, Rajasthan',
  'universe public school , Rajasthan',
  'V.M. Public school, Rajasthan',
  'Vandana public sr sec school, Rajasthan',
  'Vidha sagar senior secondary school , Rajasthan',
  'Vidhya sagar senior secondary school , Rajasthan',
  'VIDHYANJALI ACADEMY, Rajasthan',
  'Vidiya Mandir Sec Sr. School , Rajasthan',
  'Vidya Bharti Public School , Rajasthan',
  'Vidya Niketan birla public school , Rajasthan',
  'vidyashram public school , Rajasthan',
  'Vrindavan Public School, Rajasthan',
  'Warren academy school, Rajasthan',
  'Yogiraj Public School , Rajasthan',
  'KV NHPC, Sikkim',
  'Little Flower , Sikkim',
  '21st CENTURY INTERNATIONAL HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'A M M MATRICULATION HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'A R CHINNIMALAI GOUNDER HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'A R L M MATRICULATION HR SEC SCHOOL , Tamil Nadu & Puducherry (UT)',
  'A V M S MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'A V MEIYAPPAN MATRICULATION HIGHER SECONDARY SCHOOL , Tamil Nadu & Puducherry (UT)',
  'A V RM V MATRICULATION HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'A.E.T. MHSS, Tamil Nadu & Puducherry (UT)',
  'A.G.N.MATRIC. HR.SEC. SCHOOL, Tamil Nadu & Puducherry (UT)',
  'A.M.M. Matriculation Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'A.R.B.INTERNATIONAL SCHOOL, Tamil Nadu & Puducherry (UT)',
  'A.R.L.M Matriculation Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'A.U.P.E.T CHINMAYA VIDYALAYA MHSS, Tamil Nadu & Puducherry (UT)',
  'A.V.P Trust National Matric Higher secondary school, Tamil Nadu & Puducherry (UT)',
  'A.V.Rm.V. Matriculation Higher Secondary School , Tamil Nadu & Puducherry (UT)',
  'AAAndal alagar school, Tamil Nadu & Puducherry (UT)',
  'AALPHA WISDOM VIDYASHRAM SENIOR SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Aamaarpvam her sec school, Tamil Nadu & Puducherry (UT)',
  'ABACUS INTERNATIONAL MONTESSORI SCHOOL, Tamil Nadu & Puducherry (UT)',
  'ABS VIDHYA MANDHIR, Tamil Nadu & Puducherry (UT)',
  'ACHARIYA  SIKSHA MANDIR VILLIANUR PUDUCHERRY, Tamil Nadu & Puducherry (UT)',
  'ACHARIYA BALA SIKSHA MANDIR, Tamil Nadu & Puducherry (UT)',
  'ACHARIYA SIKSHA MANDIR HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Acharya Bala Siksha Mandir TT, Tamil Nadu & Puducherry (UT)',
  'Achivers Academy Matriculation Higher Secoundary School, Tamil Nadu & Puducherry (UT)',
  'Achyuta Academy Matriculation Hr Sec school, Tamil Nadu & Puducherry (UT)',
  'ADHARSH VIDHYALAYA MATRIC HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'ADHAYAPANA CBSE SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Adhiparasakthi matriculation hr sec school, Tamil Nadu & Puducherry (UT)',
  'Adhiyaman Matric Hr Sec School, Tamil Nadu & Puducherry (UT)',
  'ADHYAPANA SCHOOL , Tamil Nadu & Puducherry (UT)',
  'ADR matriculation and hiigher secondary school, Tamil Nadu & Puducherry (UT)',
  'Adrash vidya Kendra, Tamil Nadu & Puducherry (UT)',
  'AECS Anupuram, Tamil Nadu & Puducherry (UT)',
  'AET MATRIC.HR.SEC.SCHOOL, Tamil Nadu & Puducherry (UT)',
  'AGARAM PUBLIC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'AKKV  AARUNADU MATRIC HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Aklavya International school, Tamil Nadu & Puducherry (UT)',
  'AKR ACADEMY  CBSE SCHOOL, Tamil Nadu & Puducherry (UT)',
  'AKSHAYA ACADEMY CBSE SENIOR SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'AKT ACADEMY MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Alagu Jothi Academy, Tamil Nadu & Puducherry (UT)',
  'Alpha plus matric hr sec school, Tamil Nadu & Puducherry (UT)',
  'Alphonsa Matriculation Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'Alvernia Matriculation Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'alwin memorial public school, Tamil Nadu & Puducherry (UT)',
  'Amala convent girls higher secondary school , Tamil Nadu & Puducherry (UT)',
  'AMALORPAVAM HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Amrita vidyalayam , Tamil Nadu & Puducherry (UT)',
  'Amudham matric higer secondary school, Tamil Nadu & Puducherry (UT)',
  'ANAN KIDS ACADEMY, Tamil Nadu & Puducherry (UT)',
  'ananda vidyalaya matric he sec school , Tamil Nadu & Puducherry (UT)',
  'Angel. Mat. Hr. Sec. School, Tamil Nadu & Puducherry (UT)',
  'Anitha kumaran matric hr sec school, Tamil Nadu & Puducherry (UT)',
  'Anna Adarsh Mat.Hr.Sec.School, Tamil Nadu & Puducherry (UT)',
  'ANNA ARIVAGAM HR.SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Anna Gem Science Park Matriculation Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  "annai veilankanni's matriculation higher secondary school, Tamil Nadu & Puducherry (UT)",
  'ANNAI VELANKANNI MATRIC HR SEC SCHOOL , Tamil Nadu & Puducherry (UT)',
  "ANNAMALAIYAR MILLS GIRL'S HIGHER SECONDARY SCHOOL , Tamil Nadu & Puducherry (UT)",
  'ARB International School, Tamil Nadu & Puducherry (UT)',
  'ARC Matri Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'ARJ Public School, Tamil Nadu & Puducherry (UT)',
  'ARLM Matric. Hr Sec School, Tamil Nadu & Puducherry (UT)',
  'Arokia matha matriculation higher secondary school , Tamil Nadu & Puducherry (UT)',
  'ARR MATRIC.HR.SEC.SCHOOL, Tamil Nadu & Puducherry (UT)',
  'ARRS Academy, Tamil Nadu & Puducherry (UT)',
  'Arsha Vidya Mandir, Tamil Nadu & Puducherry (UT)',
  'Arumugham Palaniguru Modern School, Tamil Nadu & Puducherry (UT)',
  'ASAN Memorial Matriculation School, Tamil Nadu & Puducherry (UT)',
  'At joseph matric hr sec school, Tamil Nadu & Puducherry (UT)',
  'ATHENA GLOBAL SCHOOL, Tamil Nadu & Puducherry (UT)',
  'ATOMIC ENERGY CEN SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Atomic Energy Central Higher Secondary school-2, Tamil Nadu & Puducherry (UT)',
  'AUPET CHIMAYA VIDYALAYA MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Auxilium English MEdium School, Tamil Nadu & Puducherry (UT)',
  'AV Meiyappan Matriculation Hr. Sec. School, Tamil Nadu & Puducherry (UT)',
  'AVK INTERNATIONAL RESIDENTIAL SCHOOL, Tamil Nadu & Puducherry (UT)',
  'AVM MARIMUTHU NADAR HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'AVP TRUST NATIONAL MATRIC HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'B.V.B. HIGH SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'BABAJI VIDHYASHRAM, Tamil Nadu & Puducherry (UT)',
  'Bala Barathi Matriculation Higher Sec School, Tamil Nadu & Puducherry (UT)',
  'BALA MATRIC HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Bala Vidya Mandir, Tamil Nadu & Puducherry (UT)',
  'Balalok matriculation higher secondary school, Tamil Nadu & Puducherry (UT)',
  'Bannari Amman Public School, Tamil Nadu & Puducherry (UT)',
  'Barathi Kids School, Tamil Nadu & Puducherry (UT)',
  'barathi vidya bhavan, Tamil Nadu & Puducherry (UT)',
  'Bell Matric Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'BEST MAT HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'BHAARATH VIDHYA BHAVAN MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Bhagavan higher secondary school, Tamil Nadu & Puducherry (UT)',
  'BHAKTAVATSALAM VIDYASHRAM, Tamil Nadu & Puducherry (UT)',
  'BHARANI PARK MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'BHARAT INTERNATIONAL SENIOR SECONDARY SCHOOL- CBSE, Tamil Nadu & Puducherry (UT)',
  'bharat senior secondary school , Tamil Nadu & Puducherry (UT)',
  'Bharath Matric Hr Sec School Krishnagiri, Tamil Nadu & Puducherry (UT)',
  'BHARATH MONTESSORI MATRIC HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Bharath Vidhya Nikketan , Tamil Nadu & Puducherry (UT)',
  'Bharath Vidya Mandir, Tamil Nadu & Puducherry (UT)',
  'BHARATHI  VIDYA BHAVAN ERODE, Tamil Nadu & Puducherry (UT)',
  'Bharathi .Mat .Hr.Sec.school., Tamil Nadu & Puducherry (UT)',
  'bharathi dasanar matric higher secondary school, Tamil Nadu & Puducherry (UT)',
  'BHARATHI KIDS KSHETHRALAYA MATRIC HR SEC SCHOOL TIRUPUR, Tamil Nadu & Puducherry (UT)',
  'Bharathi matric hier secondary school , Tamil Nadu & Puducherry (UT)',
  'Bharathi Matriculation Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'Bharathi vidya bhavan, Tamil Nadu & Puducherry (UT)',
  'BHARATHI VIDYA BHAVAN MATRIC HR SEC SCHOOL , Tamil Nadu & Puducherry (UT)',
  'Bharathiyar Hi-tech International senior secondary school, Tamil Nadu & Puducherry (UT)',
  'Bharthidasan Matriculation Higher sec school, Tamil Nadu & Puducherry (UT)',
  "Bhavan's Rajaji Vidyashram, Tamil Nadu & Puducherry (UT)",
  'Blossom Public School, Tamil Nadu & Puducherry (UT)',
  'BLUE BIRD MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'BMC MATRIC HR SEC SCHOOL TUTICORIN, Tamil Nadu & Puducherry (UT)',
  'BOAZ PUBLIC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'BREEKS MEMORIAL SCHOOL OOTY, Tamil Nadu & Puducherry (UT)',
  'Brindhavan Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'BRINDISI MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'BS Mootha senior higher secondary school, Tamil Nadu & Puducherry (UT)',
  'BVB HR SEC SCHOOL THINDAL, Tamil Nadu & Puducherry (UT)',
  'BVB VIVEKANANDA VIDYA MANDIR, Tamil Nadu & Puducherry (UT)',
  'BVM GLOBAL @ KARPAGAM CAMPUS SINGANALLUR CBE TN, Tamil Nadu & Puducherry (UT)',
  'C K School Of Practical Knowledge Matriculation Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'C S ACADEMY, Tamil Nadu & Puducherry (UT)',
  'C.E.O.A Matriculation Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'C.R.R Matric HR Sec School, Tamil Nadu & Puducherry (UT)',
  'C.S. Academy, Tamil Nadu & Puducherry (UT)',
  'Cambridge Public e School, Tamil Nadu & Puducherry (UT)',
  'CAMPION A.I. HR. SEC. SCHOOL TRICHY - 17, Tamil Nadu & Puducherry (UT)',
  'CARE International School, Tamil Nadu & Puducherry (UT)',
  'Carmel Garden Matric. Higher Secondary school , Tamil Nadu & Puducherry (UT)',
  'Cauvery Global senior secondary school, Tamil Nadu & Puducherry (UT)',
  'CAUVERY PUBLIC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'CENTURY FOUNDATION HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Chanakya Hi-tech Matric HR Sec school , Tamil Nadu & Puducherry (UT)',
  'Chandra Matriculation Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'Chatram Bharathi Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'CHAVARA VIDYA BHAVAN MATRICULATION HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'CHEETINAD VIDYASHRAM, Tamil Nadu & Puducherry (UT)',
  'Chelammal matric higher secondary school, Tamil Nadu & Puducherry (UT)',
  'CHERAN MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Child Jesus Girls Higher Secondary school, Tamil Nadu & Puducherry (UT)',
  'CHINMAYA HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Chinmaya International Residential School, Tamil Nadu & Puducherry (UT)',
  'CHINMAYA VIDHYALAYA P.A.C.R Hr.sec school, Tamil Nadu & Puducherry (UT)',
  'CHINMAYA VIDYALAYA , Tamil Nadu & Puducherry (UT)',
  "Christ king girl's hr.sec.school, Tamil Nadu & Puducherry (UT)",
  'CHRIST KING MHSS, Tamil Nadu & Puducherry (UT)',
  'CHRIST THE KING BOYS MATRIC HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'CHRIST THE KING GIRLS HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Christuraja Matric Higher secondary school, Tamil Nadu & Puducherry (UT)',
  'Christus Rex Senior Secondary school, Tamil Nadu & Puducherry (UT)',
  'CK SCHOOL OF PARTICAL KNOWLEDGE, Tamil Nadu & Puducherry (UT)',
  'CKT MATRICULATION HR SEC SCHOOL , Tamil Nadu & Puducherry (UT)',
  'CLUNY MATRICULATION HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'CMS MATRIC HR SEC SCHOOL  GANAPATHY, Tamil Nadu & Puducherry (UT)',
  'COIMBATORE PUBLIC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'COMORIN INTERNATIONAL SCHOOL, Tamil Nadu & Puducherry (UT)',
  'CORDITE FACTORY HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'CORPUS CHRISTI SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Counian Matriculation Hr. Sec. School, Tamil Nadu & Puducherry (UT)',
  'CP Ramaswami Aiyer Foundation, Tamil Nadu & Puducherry (UT)',
  'CP VIDHYA MANDIR MATRICULATION HIR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'CPS GLOBAL SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Crescent castle matric higher secondary school, Tamil Nadu & Puducherry (UT)',
  'CS ACADEMY, Tamil Nadu & Puducherry (UT)',
  'CSI BAIN SCHOOL hr sec school, Tamil Nadu & Puducherry (UT)',
  'CSI JESSIE MOSES MATRIC HR SEC SCHOOL CHENNAI, Tamil Nadu & Puducherry (UT)',
  'Csma higher secondary School, Tamil Nadu & Puducherry (UT)',
  'D.A.V. MATRIC. HR.SEC.SCHOOL, Tamil Nadu & Puducherry (UT)',
  'D.A.V. PUBLIC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'D.M.N.S  DR SIVANTHI ADITHANAR MATRIC.HR.SEC.SCHOOL, Tamil Nadu & Puducherry (UT)',
  'D.V.S. REDDY HR. SEC. SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Dalmia Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'Daniel matriculation higher secondary school, Tamil Nadu & Puducherry (UT)',
  'DAV BHEL SCHOOL RANIPET NORTH ARCOT TAMILNADU, Tamil Nadu & Puducherry (UT)',
  'DAV BOYS SENIOR SECONDARY SCHOOL MOGAPPAIR, Tamil Nadu & Puducherry (UT)',
  'DAV SR SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Dawn matric higher secondary school, Tamil Nadu & Puducherry (UT)',
  'DE BRITTO HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'DELHI PUBLIC SCHOOL , Tamil Nadu & Puducherry (UT)',
  'Delhi Public School Coimbatore, Tamil Nadu & Puducherry (UT)',
  'DESIA MATRIC HR. SEC. SCHOOL, Tamil Nadu & Puducherry (UT)',
  'DEVANGAR GIRLS HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Devangar higher secondary school, Tamil Nadu & Puducherry (UT)',
  'DEVANGAR HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Devasakayam Matriculation Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'Devi Academy Senior Secondary School, Tamil Nadu & Puducherry (UT)',
  'dhanalakshmi sirinivasan matriculation higher secondarry school, Tamil Nadu & Puducherry (UT)',
  'dhanish mat hr sec school, Tamil Nadu & Puducherry (UT)',
  'Dharani International School, Tamil Nadu & Puducherry (UT)',
  'Dharani Matriculation Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'DHAVA AMUDHAM MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'DHEERAN CHINNAMALAI INTERNATIONAL RESIDENTLAL SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Dolphin Matric hr sec school, Tamil Nadu & Puducherry (UT)',
  'Don bosco higher secondary school, Tamil Nadu & Puducherry (UT)',
  'DOVETON BOYS HR SEC SCHOOL , Tamil Nadu & Puducherry (UT)',
  'Dr DASARATHAN INTERNATIONAL SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Dr G.S.Kalayansundaram Memorial school, Tamil Nadu & Puducherry (UT)',
  'DR GS KALAYANASUNDARAM MATRIC HR SEC SCHOOL , Tamil Nadu & Puducherry (UT)',
  'Dr v genguswamy matriculation higher secondary School, Tamil Nadu & Puducherry (UT)',
  'DR VGN MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Dr. Dasarathan International school, Tamil Nadu & Puducherry (UT)',
  'Dr. Rkv matric higher secondary school , Tamil Nadu & Puducherry (UT)',
  'Dr. V. Genguswamy Naidu Matriculation Hr. Sec. School, Tamil Nadu & Puducherry (UT)',
  'Dr. Vgn matriculation higher secondary school , Tamil Nadu & Puducherry (UT)',
  'Dr.C.L.Metha Sishya OMR, Tamil Nadu & Puducherry (UT)',
  'Dr.Dasarathan International School, Tamil Nadu & Puducherry (UT)',
  'Dr.G.S.KALYANASUNDARAM MATRIC.HR.SEC.SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Dr.G.S.KALYANASUNDARAM MEMORIAL SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Dr.V Genguswamy Naidu matriculation higher secondary school, Tamil Nadu & Puducherry (UT)',
  'DVD Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'E S MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'EBENEZER MARCUS INTERNATIONAL SCHOOL AND JUNIOR COLLEGE, Tamil Nadu & Puducherry (UT)',
  'EBENEZER MARCUS MATRIC.HR.SEC.SCHOOL, Tamil Nadu & Puducherry (UT)',
  'EDUSTAR INTERNATIONAL SCHOOL , Tamil Nadu & Puducherry (UT)',
  'ELGI MATRIC HR.SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'EMERALD VALLEY PUBLIC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'EQUITAS GURUKUL MATRIC HR SEC SCHOOL , Tamil Nadu & Puducherry (UT)',
  'ERODE HINDU KALVI NILAYAM MATRICULATION HR.SEC.SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Esaki Vidyaashram (CBSE) School, Tamil Nadu & Puducherry (UT)',
  'EVANGELINE MATRICULATION HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Evans Matriculation Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'EVEREST KENBRIDGE SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Everest KenBridge Senior Secondary School, Tamil Nadu & Puducherry (UT)',
  'EVERWIN MATRIC HR.SEC.SCHOOL , Tamil Nadu & Puducherry (UT)',
  'EVERWIN MATRICULATION HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Excel Central School, Tamil Nadu & Puducherry (UT)',
  'Excel Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'Fathima Central Senior Secondary School, Tamil Nadu & Puducherry (UT)',
  'Fiitjee coimbatore, Tamil Nadu & Puducherry (UT)',
  'Five star matric higher secondary school, Tamil Nadu & Puducherry (UT)',
  'Francis Xavier School, Tamil Nadu & Puducherry (UT)',
  'FRONTLINE MATRIC SENIOR SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'FRONTLINE MILLENNIUM SENIOR SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  "FUSCO'S MATRIC HR SEC SCHOOL VANDIYUR, Tamil Nadu & Puducherry (UT)",
  'G B Public school, Tamil Nadu & Puducherry (UT)',
  'G D Matriculation Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'G K SHETTY VIVEKANANDA VIDYALAYA, Tamil Nadu & Puducherry (UT)',
  'G R Damodaran Matriculation Hr.Sec. School, Tamil Nadu & Puducherry (UT)',
  'G R T Mahalakshmi Vidyalaya, Tamil Nadu & Puducherry (UT)',
  'G Ramasamy naidu matriculation higher secondary school , Tamil Nadu & Puducherry (UT)',
  'G. D Matriculation Higher secondary school , Tamil Nadu & Puducherry (UT)',
  'G. K. D Matriculation higher secondary school, Tamil Nadu & Puducherry (UT)',
  'G. K. Shetty Vivekananda Vidyalaya, Tamil Nadu & Puducherry (UT)',
  'G. S. R.Matric.Hr.Sec.School, Tamil Nadu & Puducherry (UT)',
  'G.D MATRICULATION HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'G.K.D Matric hr sec school, Tamil Nadu & Puducherry (UT)',
  'G.K.SHETTY VIVEKANANDA VIDYALAYA JUNIOR COLLEGE, Tamil Nadu & Puducherry (UT)',
  'G.R.DAMODARAN MATRICULATION HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'G.R.G.MATRIC.HIGHER.SECONDARY.SCHOOL, Tamil Nadu & Puducherry (UT)',
  'G.R.THANGAMALIGAI MAHALAKSHMI VIVEKANANDA VIDYALAYA Sr. Sec. School, Tamil Nadu & Puducherry (UT)',
  'G.Ramaswamy matric hr sec school, Tamil Nadu & Puducherry (UT)',
  'GANDHI MATRIC HIGHER SEC SCHOOL VIKRAMAM, Tamil Nadu & Puducherry (UT)',
  'Gandhi vidyalaya matriculation higher secondary school, Tamil Nadu & Puducherry (UT)',
  'Gateway International School, Tamil Nadu & Puducherry (UT)',
  'GD MATRIC HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'GD MATRICULATION HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'GEDEE PUBLIC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Geethaanjali all India senior secondary school, Tamil Nadu & Puducherry (UT)',
  'GK Matric Hr Sec School , Tamil Nadu & Puducherry (UT)',
  'GK Shetty Vivekananda Vidyalaya Sr.Sec.School, Tamil Nadu & Puducherry (UT)',
  'GKD Matric Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'GLAZE BROOKE MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'GLAZEBROOKE PUBLIC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'gnana vidya mandir CBSE school, Tamil Nadu & Puducherry (UT)',
  'Gnanasambandar matric higher secondary school, Tamil Nadu & Puducherry (UT)',
  'GNS ADWAITH MATRICULATION SCHOOL, Tamil Nadu & Puducherry (UT)',
  'GOLDEN GATES MATRICULATION HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'GOOD EARTH SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Good luck matric higher secondary School, Tamil Nadu & Puducherry (UT)',
  'GOOD SAMARITAN MATRIC HR. SEC. SCHOOL, Tamil Nadu & Puducherry (UT)',
  'GOOD SHEPHERD ENGLISH HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'GOODSAM MATRICULATION SCHOOL, Tamil Nadu & Puducherry (UT)',
  'GOV.HR.SEC.SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Government boys high secontry school t.godu, Tamil Nadu & Puducherry (UT)',
  'Government boys higher secondary school , Tamil Nadu & Puducherry (UT)',
  'GOVERNMENT BOYS HSS, Tamil Nadu & Puducherry (UT)',
  'GOVERNMENT HIGHER SECONDARY SCHOOL , Tamil Nadu & Puducherry (UT)',
  'Grace matric hr sec school, Tamil Nadu & Puducherry (UT)',
  'GRD CPF MATRICULATION HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'GRD Matriculation Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'GRDCPF MATRIC HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Green Garden Matric Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'GREEN PARK  INTERNATIONAL  SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Green park  International Senior Secondary School, Tamil Nadu & Puducherry (UT)',
  'Green Park Girls Matric. Hr. Sec. School, Tamil Nadu & Puducherry (UT)',
  'GREEN PARK INT SCHOOL , Tamil Nadu & Puducherry (UT)',
  'GREEN VALLEY MARIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'GRN MATRICULATION HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'GRT MAHALAKSHMI MATRICULATION HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'GT ALOHA VIDHYA MANDIR SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Gurugna Sambandar Mission Matriculation higher secondary school, Tamil Nadu & Puducherry (UT)',
  'GV Higher secondary school, Tamil Nadu & Puducherry (UT)',
  'GVC Higher Sec School, Tamil Nadu & Puducherry (UT)',
  'Harish metric higher secondary school, Tamil Nadu & Puducherry (UT)',
  'HAYAGRIVAS INTERNATIONAL SCHOOL, Tamil Nadu & Puducherry (UT)',
  'HEBRON MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'HEMFORD FUTURISTIC SCHOOL , Tamil Nadu & Puducherry (UT)',
  'HILLFORT MATRICULATION HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'hillock international school, Tamil Nadu & Puducherry (UT)',
  'Hilton Matriculation Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'Hindu higher secondary  school , Tamil Nadu & Puducherry (UT)',
  'HINDU VIDYALAYA MATRIC HR SEC SCHOOL , Tamil Nadu & Puducherry (UT)',
  'Hindustan matriculation higher secondary school, Tamil Nadu & Puducherry (UT)',
  'Hindustan matriculation school, Tamil Nadu & Puducherry (UT)',
  'Hiranandani Upscale School, Tamil Nadu & Puducherry (UT)',
  'HLC  International , Tamil Nadu & Puducherry (UT)',
  'HNUC HR SEC SCHOOL TN PUTHUKUDI PULIANGUDI, Tamil Nadu & Puducherry (UT)',
  'holy angels anglo indian higher secondary school, Tamil Nadu & Puducherry (UT)',
  'Holy Angels Girls Matric Hr Sec School, Tamil Nadu & Puducherry (UT)',
  'HOLY ANGELS HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  "HOLY ANGELS' GIRLS MATRIC HR SEC SCHOOL , Tamil Nadu & Puducherry (UT)",
  'HOLY CROSS MATRICULATION HIGHER SECONDARY SCHOOL , Tamil Nadu & Puducherry (UT)',
  'Hussain Memorial Matric Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'I.V.L MARIC HR. SEC. SCHOOL, Tamil Nadu & Puducherry (UT)',
  'IDA SCUDDER SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Ideal matriculation higher secondary school, Tamil Nadu & Puducherry (UT)',
  'Idhayam Rajendran Residential School, Tamil Nadu & Puducherry (UT)',
  'IIPE LAXMI RAMAN MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'IISD Dammam Saudi arabia, Tamil Nadu & Puducherry (UT)',
  'IMMACULATE ENGLISH HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'INDIAN MATRIC HR.SEC.SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Indian Public School , Tamil Nadu & Puducherry (UT)',
  'INDU INTERNATIONAL SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Infant Jesus Higher Secondary Matriculation School, Tamil Nadu & Puducherry (UT)',
  'Isha vidhya Higher Secondary Matriculation school, Tamil Nadu & Puducherry (UT)',
  'Islamiah Higher secondary school, Tamil Nadu & Puducherry (UT)',
  'IVL Matriculation Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'Jagannath Vidyalaya Senior Secondary School, Tamil Nadu & Puducherry (UT)',
  'JAI GOPAL GARODIA MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Jai Saradha Matriculation Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'jaigopal  garodia vivekananda vidyalaya, Tamil Nadu & Puducherry (UT)',
  'JAIRAM HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Jairam Matriculation school, Tamil Nadu & Puducherry (UT)',
  'JAIVABAI MUNICIPAL GIRLS HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'JAIVINS ACADEMY, Tamil Nadu & Puducherry (UT)',
  'JAWAHAR CBSE HR. SEC. SCHOOL, Tamil Nadu & Puducherry (UT)',
  'JAWAHAR HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'JAY TECH INTNL SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Jaya Matriculation Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'Jaya Priya Vidyalaya Senior Seconday School, Tamil Nadu & Puducherry (UT)',
  'Jaya vasavi matric higher secondary School, Tamil Nadu & Puducherry (UT)',
  'Jayam foundation, Tamil Nadu & Puducherry (UT)',
  'JAYAM MATRIC HR SEC SCHOOL , Tamil Nadu & Puducherry (UT)',
  'JAYAPRIYA VIDYALAYA, Tamil Nadu & Puducherry (UT)',
  'Jayarani Matriculation Hr. Sec. School, Tamil Nadu & Puducherry (UT)',
  'Jaycee higher secondary School, Tamil Nadu & Puducherry (UT)',
  'Jaycee.matric.higher.secondary.school, Tamil Nadu & Puducherry (UT)',
  'Jayendra saraswathi vidhyalaya matriculation hr.sec school , Tamil Nadu & Puducherry (UT)',
  'jeeva montessori matriculation hr. sec. school, Tamil Nadu & Puducherry (UT)',
  'Jeeva velu international school, Tamil Nadu & Puducherry (UT)',
  'JEEVANA SCHOOL, Tamil Nadu & Puducherry (UT)',
  'JEGAN MATHA MATRICULATION HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'JEI VIKAS MATRIC HR SEC SCHOOL , Tamil Nadu & Puducherry (UT)',
  'JEPPIAAR MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'JKK Nattaraja MHSS, Tamil Nadu & Puducherry (UT)',
  'jkk nattraja matric. hr. sec . school, Tamil Nadu & Puducherry (UT)',
  'John Bosco Matriculation Hr Sec School, Tamil Nadu & Puducherry (UT)',
  'John Britto Matric Hr. Sec School, Tamil Nadu & Puducherry (UT)',
  'John Dewey Matric Hr. Sec. School, Tamil Nadu & Puducherry (UT)',
  'JOTHI VIDHYALAYA MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Joy matric hr sec school vadakarai, Tamil Nadu & Puducherry (UT)',
  'JRK MATRICULATION HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'JSS international school Ooty , Tamil Nadu & Puducherry (UT)',
  'JSS PUBLIC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'K C A D MATRIC HR SEC SCHOOL SATTUR, Tamil Nadu & Puducherry (UT)',
  'K KAMARAJAR MATRIC HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'K R A VIDHYASHRAM HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'K V OCF SCHOOL, Tamil Nadu & Puducherry (UT)',
  'K.C.A.D.Chidambaram.Gnanagiri.Mat.Hr.Sec.School, Tamil Nadu & Puducherry (UT)',
  'K.KAMARAJAR MAT.HR.SEC.SCHOOL, Tamil Nadu & Puducherry (UT)',
  'K.R.A vidhyashrum matric HR sec , Tamil Nadu & Puducherry (UT)',
  'K.R.P MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'K.S.R AKSHARA ACADEMY, Tamil Nadu & Puducherry (UT)',
  'K.V.S.English Medium School (CBSE), Tamil Nadu & Puducherry (UT)',
  'KADHIR VIDYAA MANDHIR, Tamil Nadu & Puducherry (UT)',
  'Kailash Maansarovar School, Tamil Nadu & Puducherry (UT)',
  'Kalaikovil vidhya mandir matric HR.sec school , Tamil Nadu & Puducherry (UT)',
  'Kalaimagal kalalaya Matric High sec school, Tamil Nadu & Puducherry (UT)',
  'kalaimagal kalvi nilayam girls higher sec school, Tamil Nadu & Puducherry (UT)',
  'KALAIMAGAL MATRIC. HR. SEC. SCHOOL, Tamil Nadu & Puducherry (UT)',
  'kalashetra matric. hr. sec. school, Tamil Nadu & Puducherry (UT)',
  'kamakoti vidyalaya, Tamil Nadu & Puducherry (UT)',
  'KAMALA NIKETAN MONTESSORI SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Kamala Subramaniam Higher secondary school , Tamil Nadu & Puducherry (UT)',
  'Kamalam International School, Tamil Nadu & Puducherry (UT)',
  'KAMALAMMAL MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Kamalavathi Senior Secondary School, Tamil Nadu & Puducherry (UT)',
  'KAMARAJ MATRIC HIGHER SECONDARY SPECIAL SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Kamban Karpagam Matric Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'KANCHI SRI SANKARAA MATRIC. HR. SEC. SCHOOL, Tamil Nadu & Puducherry (UT)',
  'KAPV VISWANATHAM HR Sec School, Tamil Nadu & Puducherry (UT)',
  'KARAIKUDI MAHARISHI VIDYA MANDIR MATRIC HR SEC SCHOOL , Tamil Nadu & Puducherry (UT)',
  'Karapaga vinayaga matric hr.sec school , Tamil Nadu & Puducherry (UT)',
  'karthekeyan higher secondary school, Tamil Nadu & Puducherry (UT)',
  'Karur Saraswathi Vidhyala Hr Sec School, Tamil Nadu & Puducherry (UT)',
  'KARUR VETRI VINAYAGA MATRIC.HR.SEC.SCHOOL, Tamil Nadu & Puducherry (UT)',
  'KATHIR VIDHYAA MANDHIR, Tamil Nadu & Puducherry (UT)',
  'Kathiravan matric higher secondary school, Tamil Nadu & Puducherry (UT)',
  'Kaumaram Sushila International Residential School, Tamil Nadu & Puducherry (UT)',
  'kCAD chidamabaram gnanagiri matric. higher sec. school , Tamil Nadu & Puducherry (UT)',
  'KCM MATRICULATION HR.SEC. SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Keartiman matric higher secondary school, Tamil Nadu & Puducherry (UT)',
  'Keins matric hr sec school, Tamil Nadu & Puducherry (UT)',
  'KenBridge School, Tamil Nadu & Puducherry (UT)',
  'KENDIRIYA VIDYALAYA , Tamil Nadu & Puducherry (UT)',
  'Kerala HSE, Tamil Nadu & Puducherry (UT)',
  'KEREN MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'KG MATRIC HR SEC SCHOOL , Tamil Nadu & Puducherry (UT)',
  'KIDS CLUB CBSE SCHOOL, Tamil Nadu & Puducherry (UT)',
  'KIKANI VIDHYA MANDIR, Tamil Nadu & Puducherry (UT)',
  'KINGS SCHOOL , Tamil Nadu & Puducherry (UT)',
  'KMC PUBLIC SCHOOL , Tamil Nadu & Puducherry (UT)',
  'KMC public senior secondary school, Tamil Nadu & Puducherry (UT)',
  'Kodaikanal International School, Tamil Nadu & Puducherry (UT)',
  'KOLA SARASWATHI VAISHNAV SENIOR SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'KOLA SARASWATHI VAISHNAV SR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Kola Saraswathi Vaishnav Sr. Sec. School, Tamil Nadu & Puducherry (UT)',
  'Kolaperumal Chetty Vaishnav Senior Secondary School, Tamil Nadu & Puducherry (UT)',
  'KONGU MATRIC HR. SEC. SCHOOL, Tamil Nadu & Puducherry (UT)',
  'KOTAGIRI PUBLIC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'KPATDRS JAYCEES MATRIC HR SEC SCHOOL SIVAKASI, Tamil Nadu & Puducherry (UT)',
  'KRISHNAMAL RAMASUBBAIYER SCHOOL, Tamil Nadu & Puducherry (UT)',
  'KRISHNASAMY  MEMORIAL MATRIC HR SEC SCHOOL , Tamil Nadu & Puducherry (UT)',
  'Krishnasamy Matric Higher Secondary School of excellence, Tamil Nadu & Puducherry (UT)',
  'KRISHNASAMY MEM MAT HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'KSR AKSHARA ACADEMY, Tamil Nadu & Puducherry (UT)',
  'KSR HI TECH SCHOOL, Tamil Nadu & Puducherry (UT)',
  'KSR Matriculation Higher Secondary School , Tamil Nadu & Puducherry (UT)',
  'Kumararani Meena Muthaiah Mat.High.Scl, Tamil Nadu & Puducherry (UT)',
  'KUMARI MATRICULATION HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'KURINJI HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'KURINJI MAT HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'L K HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'l..k.hr sec school, Tamil Nadu & Puducherry (UT)',
  'L.M.Dadha senior secondary school, Tamil Nadu & Puducherry (UT)',
  'La Chatelaine Junior College, Tamil Nadu & Puducherry (UT)',
  'LA CHATELAINE RES JR COLLEGE, Tamil Nadu & Puducherry (UT)',
  'Lady Andal VenkataSubba Rao Matriculation School, Tamil Nadu & Puducherry (UT)',
  'LAKSHMI CHORDIA MEMORIAL MATRICULATION HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'LAKSHMI GARADEN MATRIC HR.SEC.SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Lalaji Memorial Omega International School, Tamil Nadu & Puducherry (UT)',
  'LALAJI OMEGA INT SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Lathangi vidhya mandir , Tamil Nadu & Puducherry (UT)',
  'LAUREL HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'LE CHATELIER MATRICULATION HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'LIONS MATRIC HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Lisieux matriculation higher secondary school , Tamil Nadu & Puducherry (UT)',
  'LITERA VALLEY ZEE, Tamil Nadu & Puducherry (UT)',
  'Little  Flower Matriculation higher secondary school , Tamil Nadu & Puducherry (UT)',
  'Little  rose matric high sec school, Tamil Nadu & Puducherry (UT)',
  'LITTLE ANGELS ENGLISH HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Little kingdom senior secondary school , Tamil Nadu & Puducherry (UT)',
  'Little Oxford matriculation higher secondary school, Tamil Nadu & Puducherry (UT)',
  'LITTLE SCHOLARS MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Little Scholars Matriculation Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'LONDON KRISHNAMOORTHI MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Lord Venkateswara Matric Hr.sec School, Tamil Nadu & Puducherry (UT)',
  'LOURDE MATHA CONVENT MATRIC HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Lourde Matha Convent Matriculation Hr sec school, Tamil Nadu & Puducherry (UT)',
  'Lourdes Matha Convent Matriculation Hr. Sec. school , Tamil Nadu & Puducherry (UT)',
  'M A M MATRIC HR SEC SCHOOL , Tamil Nadu & Puducherry (UT)',
  'M L M MAMALLAN MAT HSS , Tamil Nadu & Puducherry (UT)',
  'M. A. M HITECH , Tamil Nadu & Puducherry (UT)',
  'M. A. M matric HR sec school , Tamil Nadu & Puducherry (UT)',
  'M.A.Krishnaswami MHSS, Tamil Nadu & Puducherry (UT)',
  'M.A.M exel matric hr sec school, Tamil Nadu & Puducherry (UT)',
  'M.CT.M Chidambaram chettyar mat hr sec school, Tamil Nadu & Puducherry (UT)',
  'M.N.R.D Girls Hr.sec.school, Tamil Nadu & Puducherry (UT)',
  'M.S.R.Matric.Higher.Secondary.School, Tamil Nadu & Puducherry (UT)',
  'M.S.VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Madras Christian College Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'MAHAATMA KK NAGAR, Tamil Nadu & Puducherry (UT)',
  'Mahabharathi International School, Tamil Nadu & Puducherry (UT)',
  'Maharashi Vidya Mandir Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'MAHARISHI HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'MAHARISHI INT RES SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Maharishi internarional residental school, Tamil Nadu & Puducherry (UT)',
  'MAHATMA MONTESSORI MATRI H S S, Tamil Nadu & Puducherry (UT)',
  'Mahindra World School, Tamil Nadu & Puducherry (UT)',
  'Mahirishi International Residential School, Tamil Nadu & Puducherry (UT)',
  'MAI SKOOL - CBSE, Tamil Nadu & Puducherry (UT)',
  'Malar Matric Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'MALAR MATRICULATION HR. SEC. SCHOOL, Tamil Nadu & Puducherry (UT)',
  'MALCO VIDYALAYA MATRIC HR SEC SCHOOL , Tamil Nadu & Puducherry (UT)',
  'MAM Maticulation higher secondary school, Tamil Nadu & Puducherry (UT)',
  'MAM.hi tech.matric.hr.sec.school, Tamil Nadu & Puducherry (UT)',
  'Manchester International School, Tamil Nadu & Puducherry (UT)',
  'MANGALAM HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Maria Rafols matriculation highert secondary school, Tamil Nadu & Puducherry (UT)',
  'MaruthuRukmani.matric.hr.sec.school, Tamil Nadu & Puducherry (UT)',
  'Mary Ann Matric HR SEC School, Tamil Nadu & Puducherry (UT)',
  'Mary Matha Matric Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'Mary Matha Matriculation Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'Marymatha matriculation. Hr. Sec. School, Tamil Nadu & Puducherry (UT)',
  'mata, Tamil Nadu & Puducherry (UT)',
  'MATHAGONDAPALLI MODEL SCHOOL, Tamil Nadu & Puducherry (UT)',
  'mathakondapalli model school , Tamil Nadu & Puducherry (UT)',
  'mathakondapalli model school hosur, Tamil Nadu & Puducherry (UT)',
  'MATHATMA MONTESSORI SCHOOL MADURAI, Tamil Nadu & Puducherry (UT)',
  'MAXWELL MATRIC HIGHER SECONDARY SCHOOL THANJAVUR, Tamil Nadu & Puducherry (UT)',
  'MCC CAMPUS MAT.HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'MCC Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'Measi matriculation higher secondary school, Tamil Nadu & Puducherry (UT)',
  'Mepco Schlenk Matric Hr Sec School, Tamil Nadu & Puducherry (UT)',
  'Merit matirc hr., Tamil Nadu & Puducherry (UT)',
  'Metro Matriculation Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'Migros K.I.D.S  School, Tamil Nadu & Puducherry (UT)',
  'MJ VINCENT MATRIC HR SEC SCHOOL COIMBATORE, Tamil Nadu & Puducherry (UT)',
  'MKVK Matric HR Sec School, Tamil Nadu & Puducherry (UT)',
  'MLM Mamallan matriculation higher secondary school kanchipuram, Tamil Nadu & Puducherry (UT)',
  'Mncvv kundrathur , Tamil Nadu & Puducherry (UT)',
  'Monfort School, Tamil Nadu & Puducherry (UT)',
  'MONT FORT SCHOOL KATTUR TRICHY, Tamil Nadu & Puducherry (UT)',
  'Montfort Academy M.H.S.School, Tamil Nadu & Puducherry (UT)',
  'MONTFORT ANGLO INDIAN HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Morning Star Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'mount litera zee school, Tamil Nadu & Puducherry (UT)',
  'Mount park.Hr.Second.School, Tamil Nadu & Puducherry (UT)',
  'MOUNT SAINT JOSEPH MATRICULATION HIGHER SECONDARY SCHOOL , Tamil Nadu & Puducherry (UT)',
  'Mount Zion International School, Tamil Nadu & Puducherry (UT)',
  'MOUNT ZION MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'MOUNTAIN HOME SCHOOL & JUNIOR COLLEGE, Tamil Nadu & Puducherry (UT)',
  'MR matriculation school, Tamil Nadu & Puducherry (UT)',
  'msp solai nadar memorial higher secondary school, Tamil Nadu & Puducherry (UT)',
  'MSR MATRICULATION HR SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Mullai higher secondary School, Tamil Nadu & Puducherry (UT)',
  'MUNICIPAL GIRLS HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Muthur Vivekananda vidyalaya, Tamil Nadu & Puducherry (UT)',
  'Muthusamy Gounder Matric HR Secondary School , Tamil Nadu & Puducherry (UT)',
  'MUVENDAR MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'MVM SCHOOL, Tamil Nadu & Puducherry (UT)',
  'MVM SENIOR SECONDARY SCHOOL , Tamil Nadu & Puducherry (UT)',
  'N K S Vivekananda vidyalaya school, Tamil Nadu & Puducherry (UT)',
  'N.A.Annapparaja Memorial Higher secondary school , Tamil Nadu & Puducherry (UT)',
  'N.K.M.HR.SEC.SCHOOL, Tamil Nadu & Puducherry (UT)',
  'N.S.N Matriculation Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'N.S.N MEMORIAL SENIOR SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'N.S.V.V Boys Hr Sec School, Tamil Nadu & Puducherry (UT)',
  'NACHIAR VIDYALAYAM MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Nadar higher secondary school, Tamil Nadu & Puducherry (UT)',
  'NADAR SARASHWATHI BOYS HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'NAG VIDHYASHRAM SENIOR SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'nagamani ammal matriculation hr sec school, Tamil Nadu & Puducherry (UT)',
  'NAGAMANIAMMAL MEMORIAL MATRICULATION SCHOOL, Tamil Nadu & Puducherry (UT)',
  'NALANDA INTERNATIOANL PUBLIC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'NAMAKKAL KONGU MATRIC HR SEC SCHOOL KEERAMBUR NAMAKKAL, Tamil Nadu & Puducherry (UT)',
  'nandha central school, Tamil Nadu & Puducherry (UT)',
  'Nandha Matriculation Higher secondary school, Tamil Nadu & Puducherry (UT)',
  'naraya e techno school velappanchadi, Tamil Nadu & Puducherry (UT)',
  'Narayan  e-techno school, Tamil Nadu & Puducherry (UT)',
  'NARAYAN JUNIOR COLLEGE , Tamil Nadu & Puducherry (UT)',
  'Narayana  e-tecno school , Tamil Nadu & Puducherry (UT)',
  'Narayana  matriculation higher secondary school, Tamil Nadu & Puducherry (UT)',
  'Narayana Co Kaveri Bhavan, Tamil Nadu & Puducherry (UT)',
  'Narayana E Techno School , Tamil Nadu & Puducherry (UT)',
  'NARAYANA e-TECHNO CHOOL, Tamil Nadu & Puducherry (UT)',
  'Narayana group of schools , Tamil Nadu & Puducherry (UT)',
  'narayana higher secondary school, Tamil Nadu & Puducherry (UT)',
  'NARAYANA JUIOR COLLEGE, Tamil Nadu & Puducherry (UT)',
  'NARAYANA MAT HR SEC SCHOOL , Tamil Nadu & Puducherry (UT)',
  'NATIONAL ACADEMY MATRIC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'NATIONAL ACADEMY MONTESSORI MATRIC. HR. SEC. SCHOOL, Tamil Nadu & Puducherry (UT)',
  'National Academy School, Tamil Nadu & Puducherry (UT)',
  'National Higher secondary school , Tamil Nadu & Puducherry (UT)',
  'National Institute of Open Schooling, Tamil Nadu & Puducherry (UT)',
  'National Matriculation Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'NATIONAL PUBLIC SCHOOL , Tamil Nadu & Puducherry (UT)',
  'NAVA BHARATH NATIONAL SCHOOL, Tamil Nadu & Puducherry (UT)',
  'NEW BHARATH MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Nirmala matha convent matric higher secondary school, Tamil Nadu & Puducherry (UT)',
  'Nivedha metric higher secondary school porayar, Tamil Nadu & Puducherry (UT)',
  'NKM HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'NKS Vivekananda Vidyalaya Junior College, Tamil Nadu & Puducherry (UT)',
  'NOBLE MATRIC HR SEC SCHOOL VIRUDHUNAGAR, Tamil Nadu & Puducherry (UT)',
  'Notre Dame of Holy Cross, Tamil Nadu & Puducherry (UT)',
  'NOTRE DAME OF HOLY CROSS SCHOOL, Tamil Nadu & Puducherry (UT)',
  'NSN MEMORIAL SENIOR SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'NSVV MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'NV. Matrix. HR. Sec. School, Tamil Nadu & Puducherry (UT)',
  'NVKS HIGHER SECONDARY SCHOOL ATTOOR, Tamil Nadu & Puducherry (UT)',
  'Nyruthi Vidhya Bhavan Matriculation Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'OM SADHANA CENTRAL SCHOOL, Tamil Nadu & Puducherry (UT)',
  'OM SAKTHI NARAYANI MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'ONGC Public School   , Tamil Nadu & Puducherry (UT)',
  'ORANGE INTERNATIONAL MATRICULATION HIGHER SECONDARY SCHOOL  ARNI, Tamil Nadu & Puducherry (UT)',
  'ORCHARD SCHOOLS, Tamil Nadu & Puducherry (UT)',
  'OUR ANGEL MATRICULATION HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'OUR LADY OF LOURDES MATRICULATION HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'OXALISS  INTERNATIONAL SCHOOL CBSE , Tamil Nadu & Puducherry (UT)',
  'OXALISS INTERNATIONAL SENIOR SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Oxford English School , Tamil Nadu & Puducherry (UT)',
  'OXFORD MATRIC HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'OXFORD MATRIC HR SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'P .K .P SWAMY MATRIC HIGHER SECONDARY SCHOOL , Tamil Nadu & Puducherry (UT)',
  'P A K PALANISAMY HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'P A VIDYA BHAVAN HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'P K D MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'P K P SWAMY MHSS, Tamil Nadu & Puducherry (UT)',
  'P S B B Sr Sec School K K Nagar, Tamil Nadu & Puducherry (UT)',
  'p s l narayana vidyalaya cbse school madurai, Tamil Nadu & Puducherry (UT)',
  'P S MATRICULATION HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'P S SENIOR SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'P. A .C.M.HR.SEC.SCHOOL, Tamil Nadu & Puducherry (UT)',
  'P. S. SENIOR SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'P.A.VIDYA BHAVAN, Tamil Nadu & Puducherry (UT)',
  'P.K.D MATRICULATION HIGHER SECONDARY SCHOOL., Tamil Nadu & Puducherry (UT)',
  'P.K.P SWAMY MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'P.L.W.A Hr. Sec. School, Tamil Nadu & Puducherry (UT)',
  'P.R.G VELAPPA NAIDU MATRIC HR SEC SCHOOL PK.PUDUR PALANI T.K, Tamil Nadu & Puducherry (UT)',
  'P.S. SENIOR SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'P.S.B.B.S.S.SCHOOL, Tamil Nadu & Puducherry (UT)',
  'P.S.G matric. hr, Tamil Nadu & Puducherry (UT)',
  'P.S.SENIOR SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Paavai vidhyaashram, Tamil Nadu & Puducherry (UT)',
  'PAAVAI VIDHYASHRAM, Tamil Nadu & Puducherry (UT)',
  'PAAVAI VIDHYASHRAM GLOBAL SCHOOL, Tamil Nadu & Puducherry (UT)',
  'PAAVENDHAR HR SEC SCHOOL MV SOUTH ATTUR, Tamil Nadu & Puducherry (UT)',
  'PACM HR SEC SCHOOL RAJAPALAYAM, Tamil Nadu & Puducherry (UT)',
  'PADMA RAMASAMY MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Palaniappa Memorial higher secondary school , Tamil Nadu & Puducherry (UT)',
  "Parvathy's Anugrahaa International School , Tamil Nadu & Puducherry (UT)",
  'Pasi matric higher secondary school, Tamil Nadu & Puducherry (UT)',
  'Pearls Public School, Tamil Nadu & Puducherry (UT)',
  'PEM SCHOOL OF EXCELLENCE , Tamil Nadu & Puducherry (UT)',
  'Pen school of excellence , Tamil Nadu & Puducherry (UT)',
  'PERIYAR MATRICULATION HR.SEC.SCHOLL, Tamil Nadu & Puducherry (UT)',
  'PERKS MATRICULATION HIGHER SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'PETIT SEMINAIRE HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'PKP SWAMY MATRICULATION SCHOOL, Tamil Nadu & Puducherry (UT)',
  'PLATOS ACADEMY, Tamil Nadu & Puducherry (UT)',
  'PMG World school, Tamil Nadu & Puducherry (UT)',
  'Point calimere International school, Tamil Nadu & Puducherry (UT)',
  'PON JESLY MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'pon vidhyashram, Tamil Nadu & Puducherry (UT)',
  'Pon Vidya Mandir senior secoundary school, Tamil Nadu & Puducherry (UT)',
  'PONJESLY MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Ponjesly Public Matric Higher secondary school, Tamil Nadu & Puducherry (UT)',
  'ponjesly public matric.hr.sec.school, Tamil Nadu & Puducherry (UT)',
  'Ponjesly Public Matriculation Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'ponnaiya ramajayam public hr sec school , Tamil Nadu & Puducherry (UT)',
  'Ponnaiyah ramajayam public school , Tamil Nadu & Puducherry (UT)',
  'PONNU MATRIC HR SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Ponvidyashram , Tamil Nadu & Puducherry (UT)',
  'Pradakshina , Tamil Nadu & Puducherry (UT)',
  "Pravathy's Anugrahaa International School, Tamil Nadu & Puducherry (UT)",
  'Premier Vidhyaa Vikash Higher Secondary Matriculation School, Tamil Nadu & Puducherry (UT)',
  'PREMIER VIDYAA VIKASH, Tamil Nadu & Puducherry (UT)',
  'Presidency higher secondary school, Tamil Nadu & Puducherry (UT)',
  'PRG V.N. Matric High Sec School, Tamil Nadu & Puducherry (UT)',
  'Prince Srivari Senior secondary, Tamil Nadu & Puducherry (UT)',
  'PRINCE SRIVARI SENIOR SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  "Prof K.K.Anand's Smart Minds Academy, Tamil Nadu & Puducherry (UT)",
  "PROF KK ANAND'S SMART MINDS ACADEMY, Tamil Nadu & Puducherry (UT)",
  'PS Senior Secondary School, Tamil Nadu & Puducherry (UT)',
  'PSBB MILLENNIUM SCHOOL , Tamil Nadu & Puducherry (UT)',
  'PSBB Senior Secondary School, Tamil Nadu & Puducherry (UT)',
  'PSG Matric Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'PSG PUBLIC SCHOOLS, Tamil Nadu & Puducherry (UT)',
  'PSG SARVAJANA HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'PSGR KRISHNAMMAL HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'PSSB MILLENNIUM SCHOOL , Tamil Nadu & Puducherry (UT)',
  'PSSbb millennium school, Tamil Nadu & Puducherry (UT)',
  'PURNA  VIDHYA BHAVAN, Tamil Nadu & Puducherry (UT)',
  'PUSHPALATA MATRICULATION HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'PUSHPALATHA VIDYA MANDIR, Tamil Nadu & Puducherry (UT)',
  'puspalata vidya mandir, Tamil Nadu & Puducherry (UT)',
  'QUEEN MIRA INTERNATIONAL SCHOOL , Tamil Nadu & Puducherry (UT)',
  'R C  HR SCE SCHOOL, Tamil Nadu & Puducherry (UT)',
  'R D INTERNATIONAL SCHOOL, Tamil Nadu & Puducherry (UT)',
  'R G MATRIC HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'R J Mantra English School, Tamil Nadu & Puducherry (UT)',
  'R J MANTRA SCHOOL, Tamil Nadu & Puducherry (UT)',
  'R K Sree rangammal Kalvi nilayam, Tamil Nadu & Puducherry (UT)',
  'R M K RESIDENTIAL SCHOOL KAVARAIPETTAI TIRUVALLUR, Tamil Nadu & Puducherry (UT)',
  'R S K HIGHER SECONDARY SCHOOL -TRICHY, Tamil Nadu & Puducherry (UT)',
  'R S KRISHNAN HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'R V HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'R.C.HR.SCE.SCHOOL.PUDUVAYAL, Tamil Nadu & Puducherry (UT)',
  'R.G Matric Higher Secondary School , Tamil Nadu & Puducherry (UT)',
  'R.J. MATRICULATION AND HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'R.M.K PATASHAALA , Tamil Nadu & Puducherry (UT)',
  'R.S.K Higher Sec School, Tamil Nadu & Puducherry (UT)',
  'R.S.Krishnan Higher Sec School, Tamil Nadu & Puducherry (UT)',
  'Raj Matriculation Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'Rajavignesh Higher secondary school, Tamil Nadu & Puducherry (UT)',
  'RAJENDRAN MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Rajiv gandhi national matric hr sec school, Tamil Nadu & Puducherry (UT)',
  'Rajkumar Sulochana Matriculation Higher Sec School, Tamil Nadu & Puducherry (UT)',
  'Ram jayam vidyaa mandir matriculation school, Tamil Nadu & Puducherry (UT)',
  'RAMAKRISHNA MISSION VIDYAALAYA MATRIC HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Ramana Vidyalaya, Tamil Nadu & Puducherry (UT)',
  'RAMCO VIDYALAYA    , Tamil Nadu & Puducherry (UT)',
  'Ramnagar Suburban Matriculation higher secondary school, Tamil Nadu & Puducherry (UT)',
  "Ramsun's matriculation higher secondary school, Tamil Nadu & Puducherry (UT)",
  'RANK INTERNATIONAL SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Rasi international school , Tamil Nadu & Puducherry (UT)',
  'RAYAR KALVI NILAYAM, Tamil Nadu & Puducherry (UT)',
  'RD International school, Tamil Nadu & Puducherry (UT)',
  'RELIANCE MATRICULATION HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Reliance Matriculation Hr. Sec. School, Tamil Nadu & Puducherry (UT)',
  'Revilla KRA VIDYASHRAM, Tamil Nadu & Puducherry (UT)',
  'Revoor padmanabha chettys matriculation higher secondary school, Tamil Nadu & Puducherry (UT)',
  'RGM MATRIC HR SEC SCHOOL UDUMALPET, Tamil Nadu & Puducherry (UT)',
  'RGR matric Hr. Sec. School, Tamil Nadu & Puducherry (UT)',
  'Rice City matric.Hr.sec.school, Tamil Nadu & Puducherry (UT)',
  'RIVERSIDE PUBLIC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'RJ MANTRA ENGLISH SCHOOL, Tamil Nadu & Puducherry (UT)',
  'RJ MATRICULATION HIGHERSECONDARY SCHOOL , Tamil Nadu & Puducherry (UT)',
  'RKM  matriculation higher sec school, Tamil Nadu & Puducherry (UT)',
  'RMK SENIOR SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Rosary matriculation higher secondary school , Tamil Nadu & Puducherry (UT)',
  'ROSE MARY MATRIC HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Rotary High School , Tamil Nadu & Puducherry (UT)',
  'ROYAL INTERNATIONAL SCHOOL, Tamil Nadu & Puducherry (UT)',
  'RSK HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'RVB MATRIC HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'RVSM HSS SULUR, Tamil Nadu & Puducherry (UT)',
  'S B O A MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'S M B M N P MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'S N M HINDU VIDYALAYA MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'S N S Academy, Tamil Nadu & Puducherry (UT)',
  'S R  V Boys Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'S R D F VIVEKANANDA VIDYALAYA, Tamil Nadu & Puducherry (UT)',
  'S R D K Vivekananda Vidyalaya Junior College, Tamil Nadu & Puducherry (UT)',
  'S R V GIRLS HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'S R V MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'S S K V MATRICULATION SCHOOL KANCHIPURAM, Tamil Nadu & Puducherry (UT)',
  'S S M Lakshmiammal Matriculation Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'S V G V HSS KARAMADAI, Tamil Nadu & Puducherry (UT)',
  'S.B.I.O.A.Model.Mat.Hr.Sec.School, Tamil Nadu & Puducherry (UT)',
  'S.B.O.A Matric and Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'S.D.A.V. Higher secondary school, Tamil Nadu & Puducherry (UT)',
  'S.D.H Jain Vidyala Matriculation Hr  Sec School, Tamil Nadu & Puducherry (UT)',
  'S.D.sion Matric Hr Sec school , Tamil Nadu & Puducherry (UT)',
  'S.E.T. VIDHYADEVI METRIC. HR. SEC. SCHOOL, Tamil Nadu & Puducherry (UT)',
  'S.H.N.ethel Harvey school, Tamil Nadu & Puducherry (UT)',
  'S.K.V Matriculation & Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'S.K.V VIDHYAASHRAM, Tamil Nadu & Puducherry (UT)',
  'S.M.B.M Matric Hr Secondary School, Tamil Nadu & Puducherry (UT)',
  'S.N.M. Hindu Vidyalaya Matric. Hr. Sec. School Krishnancoil, Tamil Nadu & Puducherry (UT)',
  'S.N.R. HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'S.R.K MATRIC HR.SEC.SCHOOL, Tamil Nadu & Puducherry (UT)',
  'S.R.K.B.V. MATRICULATION HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'S.R.S.I Matriculation Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'S.R.V Matric Hr Sec School, Tamil Nadu & Puducherry (UT)',
  'S.R.V.BOYS Hr.Sec.School, Tamil Nadu & Puducherry (UT)',
  'S.S.GOVERNMENT.HR.SEC. SCHOOL, Tamil Nadu & Puducherry (UT)',
  'S.S.M LAKSHMIAMMAL MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'S.S.R.M Higher secondary school, Tamil Nadu & Puducherry (UT)',
  'S.V.N matric HR. sec. School, Tamil Nadu & Puducherry (UT)',
  'Sacred Heart  Church Park , Tamil Nadu & Puducherry (UT)',
  'SAGAR INTERNATIONAL SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Sahayamatha matric hr sec school, Tamil Nadu & Puducherry (UT)',
  'Sai matric hr secondary school, Tamil Nadu & Puducherry (UT)',
  'SAI RAM VIDYALAYA, Tamil Nadu & Puducherry (UT)',
  'Sai Vidhya Nikethan Matric Hr Sec School, Tamil Nadu & Puducherry (UT)',
  'SAINIK SCHOOL AMARAVATHI NAGAR, Tamil Nadu & Puducherry (UT)',
  'sainik school amaravathinagar, Tamil Nadu & Puducherry (UT)',
  'SAIRAM MATRIC HR SEC SCHOOL , Tamil Nadu & Puducherry (UT)',
  'SAKTHI MAT HR SEC SCHOOL KANIYAMOOR CHINNASALEM , Tamil Nadu & Puducherry (UT)',
  'Sakthi Matric Hr. Sec. School, Tamil Nadu & Puducherry (UT)',
  'Sakthi vigneswara kalvi nilayam hr. sec.school, Tamil Nadu & Puducherry (UT)',
  'SAKTHI VINAYAGAR HINDU VIDYALAYA SENIOR SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Samadh senior secondary school, Tamil Nadu & Puducherry (UT)',
  'SAMBAVIGA HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'SAMY SCHOOL PORUR CHENNAI, Tamil Nadu & Puducherry (UT)',
  'SANKARA VIDHYALAYAA SCHOOL, Tamil Nadu & Puducherry (UT)',
  'SANTA MARIA MATRICULATION HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'SANTHINIKETAN MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Santhivijay girls higher secondary school, Tamil Nadu & Puducherry (UT)',
  'Santhosha Vidhyalaya, Tamil Nadu & Puducherry (UT)',
  'SANTINIKETAN MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'SARAH TUCKER HR SEC SCHOOL FOR GIRLS PALAYAMKOTTAI, Tamil Nadu & Puducherry (UT)',
  'Saraswathi vidyalaya senior secondary school, Tamil Nadu & Puducherry (UT)',
  'Saraswati Kendra learning centre , Tamil Nadu & Puducherry (UT)',
  'SARATHA INTERNATIONAL SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Sathya saai matriculation hr sec school , Tamil Nadu & Puducherry (UT)',
  'sathya sai bala gurukulam, Tamil Nadu & Puducherry (UT)',
  'Sathya Vidyalaya CBSE, Tamil Nadu & Puducherry (UT)',
  'Sathyam matric higherseccondary school, Tamil Nadu & Puducherry (UT)',
  'SAV BALAKRISHNA cbse School, Tamil Nadu & Puducherry (UT)',
  'SAV BALAKRISHNA matriculation School, Tamil Nadu & Puducherry (UT)',
  'SBA matric hr sec school, Tamil Nadu & Puducherry (UT)',
  'SBIOA MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'sbk boys higher secondary school, Tamil Nadu & Puducherry (UT)',
  'SBK BOYS HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'SBK GIRLS HR SEC SCHOOL ARUPPUKOTTAI, Tamil Nadu & Puducherry (UT)',
  'SBK HR SEC SCHOOL Aruppukottai, Tamil Nadu & Puducherry (UT)',
  'SBM JAY TECH INTNL SR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Scism.matric. hr.sec.school, Tamil Nadu & Puducherry (UT)',
  'Scs matriculation higher secondary school, Tamil Nadu & Puducherry (UT)',
  'SDA MATRIC HR SEC SCHOOL PERIYAKULAM, Tamil Nadu & Puducherry (UT)',
  'SDAV HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'SDR school, Tamil Nadu & Puducherry (UT)',
  'Search Results Web results  Chandra Matriculation Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'Selvam.Mat.Hr.Sec.School, Tamil Nadu & Puducherry (UT)',
  'Sengunthar Matric Hr. Sec. School , Tamil Nadu & Puducherry (UT)',
  'senthil  matric higher secondary school, Tamil Nadu & Puducherry (UT)',
  'SENTHIL MATRIC HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'SETUPATI .HR .SEC .SCHOOL, Tamil Nadu & Puducherry (UT)',
  'SEVA SANGAM GIRLS HSS, Tamil Nadu & Puducherry (UT)',
  'SEVAI SHANTHI MR HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'SEVENTH DAY ADVENTIST HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'seventh day adventist matric hr secondary school, Tamil Nadu & Puducherry (UT)',
  'SFS. Mat. Hr. Sec. School   , Tamil Nadu & Puducherry (UT)',
  'sfs.mat.hr.sec.school, Tamil Nadu & Puducherry (UT)',
  'SH SARASSWATHI VIDH MANDHEER SCHOOL, Tamil Nadu & Puducherry (UT)',
  'SHA DARGAJEE HEERAJEE JAIN VIDYALAYA , Tamil Nadu & Puducherry (UT)',
  'SHAANTHI MATRICULATION HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'SHANTHIVIJAY GIRLS HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Sharaddha Childrens Academy, Tamil Nadu & Puducherry (UT)',
  'SHEMFORD FUTURESTIC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Sherwood Hall Senior Secondary School, Tamil Nadu & Puducherry (UT)',
  'SHIFAN NOOR GLOBAL ACADEMY, Tamil Nadu & Puducherry (UT)',
  'SHIKSHAA MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Shiri vidhya giri matriculation higher secondary school, Tamil Nadu & Puducherry (UT)',
  'Shiristi Vidiyashram Katpadi Vellore, Tamil Nadu & Puducherry (UT)',
  'Shiva Niketan, Tamil Nadu & Puducherry (UT)',
  'SHNV HR SEC SCHOOL , Tamil Nadu & Puducherry (UT)',
  'Shraddha Childrens Academy, Tamil Nadu & Puducherry (UT)',
  'shree baldevdas kikani higher secondary school, Tamil Nadu & Puducherry (UT)',
  'SHREE BALDEVDAS KIKANI VIDYAMANDIR MATRICULATION HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Shree saraaswathi vidhyaah mandheer, Tamil Nadu & Puducherry (UT)',
  'Shri  A.Ganesan Mat. and Higher Sec. school , Tamil Nadu & Puducherry (UT)',
  'SHRI A GANESAN MATRICULATION HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Shri Amrita Hr sec School , Tamil Nadu & Puducherry (UT)',
  'Shri Anand Jain Vidhyalaya, Tamil Nadu & Puducherry (UT)',
  'Shri B. S. Mootha girls senior secondary school, Tamil Nadu & Puducherry (UT)',
  'Shri B. S. Mootha senior secondary school, Tamil Nadu & Puducherry (UT)',
  'SHRI BALA VIDHYA MANDIR MATRIC HR SEC SCHOOL , Tamil Nadu & Puducherry (UT)',
  'Shri Ganga, Tamil Nadu & Puducherry (UT)',
  'shri ganga higher secondary school , Tamil Nadu & Puducherry (UT)',
  'Shri Kishnaswamy.Matric.Hr.Sec.School, Tamil Nadu & Puducherry (UT)',
  'SHRI NADMAL SINGHVI JADAVBAI SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Shri Natesan Vidhyasala Mat.Hr.Sec.School, Tamil Nadu & Puducherry (UT)',
  'Shri Nehru vidyalaya, Tamil Nadu & Puducherry (UT)',
  'SHRI NIKETAN MATRICULATION HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Shri renga vidhyalaya higher secondary school, Tamil Nadu & Puducherry (UT)',
  'SHRI RENUGA VID MATRIC HR SEC SCHOOL LAKSHMIPURAM, Tamil Nadu & Puducherry (UT)',
  'Shri renuga vidhyalaya mat hr sec school , Tamil Nadu & Puducherry (UT)',
  'SHRI RENUGA VIDHYALAYAM MAT HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Shri Saraswati Vidhya mandeer, Tamil Nadu & Puducherry (UT)',
  'SHRI SWAMY MATRIC HR SEC SCHOOL MASINAICKENPATTY SALEM, Tamil Nadu & Puducherry (UT)',
  'shri vidhyaa giri matriculation higher secondary school, Tamil Nadu & Puducherry (UT)',
  'Shri vidhyaBharathi Matric Hr sec School du, Tamil Nadu & Puducherry (UT)',
  'SHRI VIDHYABHARATHI MATRIC HR SEC SCHOOL SAKKARAMPALAYAM, Tamil Nadu & Puducherry (UT)',
  'shri vijay vidyalaya(Krishnagiri), Tamil Nadu & Puducherry (UT)',
  'SHRI VINAYAGA HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'SHRIKARRA VIDHYA MANDHIR, Tamil Nadu & Puducherry (UT)',
  'Shrine Vailankanni senior secondary school , Tamil Nadu & Puducherry (UT)',
  'Shrishti vidhyashram senior secondary school, Tamil Nadu & Puducherry (UT)',
  'SHSREE SARASWATHI VIDHYAAH  MANDHEER, Tamil Nadu & Puducherry (UT)',
  'SIGARAM MATRIC.HR.SEC.SCHOOL, Tamil Nadu & Puducherry (UT)',
  'signature pipes school, Tamil Nadu & Puducherry (UT)',
  'Silver jubilee matriculation higher secondary school, Tamil Nadu & Puducherry (UT)',
  'Sindhi model matric , Tamil Nadu & Puducherry (UT)',
  'Sindhi Model Senior Secondary School, Tamil Nadu & Puducherry (UT)',
  'SINDHI VIDHYALAYA MATRIC HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Sir Sivaswami Kalalaya Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'Sir Vijay vidhayala metric Hsec School, Tamil Nadu & Puducherry (UT)',
  'Sishya matric hr sec school, Tamil Nadu & Puducherry (UT)',
  'SITADEVI GARODIA HINDU VIDYALAYA MAT, Tamil Nadu & Puducherry (UT)',
  'Sivakasi hindu nadar matriculation higher secondary school, Tamil Nadu & Puducherry (UT)',
  'Sivakasi Nadars Matriculation Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'SJN INTERNATIONAL SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Skl public matric. Hr. Sec. School, Tamil Nadu & Puducherry (UT)',
  'SKNS PMC VIVEKANANDA VIDYALAYA JUNIOR COLLEGE, Tamil Nadu & Puducherry (UT)',
  'SKV  Vidhyashram CBSE School, Tamil Nadu & Puducherry (UT)',
  'SKV HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'SKV MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'SKV VIDHAASHRAM M.Kandampalayam Tiruchencode, Tamil Nadu & Puducherry (UT)',
  'SLB GOVERNMENT HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'smart minds academy, Tamil Nadu & Puducherry (UT)',
  'Smart modern school, Tamil Nadu & Puducherry (UT)',
  'SMART MODERN SENIOR SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'SMB Matriculation School DINDIGUL, Tamil Nadu & Puducherry (UT)',
  'SMT K N SHAH PMC V VIDYALAYA PERAMBUR CHENNAI TN, Tamil Nadu & Puducherry (UT)',
  'Smt NDJAVV junior college , Tamil Nadu & Puducherry (UT)',
  'SMT R K D FOMRA V VIDYALAYA CHROMEPET CHENNAI TN, Tamil Nadu & Puducherry (UT)',
  'SMT SUNDARAVALLI MEM SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Smt.  R. K. D FOMRA V VIDYALAYA, Tamil Nadu & Puducherry (UT)',
  'Smt. Ramkuwar Devi Fomra Vivekananda Vidyalaya, Tamil Nadu & Puducherry (UT)',
  'smt.R.P.Rajalakshmi Hindu Vidyalaya Matric Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'SNEHA DEEPAM MATRIC HR SEC SCHOOL ALAMELUMANGAOURAM, Tamil Nadu & Puducherry (UT)',
  'SNM Hindu Vidyalaya, Tamil Nadu & Puducherry (UT)',
  'SNM HR SEC SCHOOL SIVAKASI, Tamil Nadu & Puducherry (UT)',
  'Soundararaja Vidyalaya, Tamil Nadu & Puducherry (UT)',
  'Sourashtra Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'Southside Matric Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'SOWDAAMBIKAA MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Sowdambika Matric Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'Sowdeshwari matric school, Tamil Nadu & Puducherry (UT)',
  'SPARK MATRIC HR SEC SCHOOL THIRUMALAIKODI VELLORE, Tamil Nadu & Puducherry (UT)',
  'SPIC Nagar Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'SPK Matriculation higher secondary school, Tamil Nadu & Puducherry (UT)',
  'SPRING MOUNT PUBLIC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Springfield Matriculation Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'SR Matriculation Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'SRC MEMORIAL MATRICULATION HIGHER SECONDARY SCHOOL , Tamil Nadu & Puducherry (UT)',
  'SRD Khemka Vivekananda vidyalaya, Tamil Nadu & Puducherry (UT)',
  'SRDF VIVEKANANDA VIDAYALAYA, Tamil Nadu & Puducherry (UT)',
  'Sree Adharsh Matric Higher seconday school , Tamil Nadu & Puducherry (UT)',
  'Sree Dharmasastha  Matric.hr.Sec.School , Tamil Nadu & Puducherry (UT)',
  'SREE GOKULAM PUBLIC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'SREE KRISHNA HINDU VIDYALAYA MATRIC HR SEC SCHOOL KANCHINAGAR, Tamil Nadu & Puducherry (UT)',
  'SREE NARAYANA GURU MATRIC.HR.SEC.SCHOOL, Tamil Nadu & Puducherry (UT)',
  'SREE NARAYANA MISSION SENIOR SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Sree Narayanaguru Matriculation Hr. Sec. School, Tamil Nadu & Puducherry (UT)',
  'SREE SANKARA BALA VIDYALAYA GOLDEN JUBILEE SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Sree saravana niketan matric hr. Secondary school, Tamil Nadu & Puducherry (UT)',
  'SREE SHANTHI ANAND VIDYALAYA THIRUMUDIVAKKAM CHENNAI, Tamil Nadu & Puducherry (UT)',
  'Sree Venkatesvara Vidyalaya, Tamil Nadu & Puducherry (UT)',
  'Sreevatsa viswanathan Vivekananda vidyalaya senior secondary college, Tamil Nadu & Puducherry (UT)',
  'SRGDS MATRIC HR SEC SCHOOL TIRUVANNAMALAI, Tamil Nadu & Puducherry (UT)',
  'SRGDS SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Sri  Lathangi Vidhya Mandir HR.Sec School, Tamil Nadu & Puducherry (UT)',
  'Sri  Ragavendra Matriculation school, Tamil Nadu & Puducherry (UT)',
  'SRI  SANKARA MATRIC HIGHER SECONDRY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'SRI  VIJAY VIDYALAYA, Tamil Nadu & Puducherry (UT)',
  'SRI AANOOR VIDYALAYA MATRIC. HR. SEC. SCHOOL, Tamil Nadu & Puducherry (UT)',
  'SRI AHOBILA MATH ORIENTAL HIGHER SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'SRI AMBAL PUBLIC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'SRI ANNAI RAANI CONVENT HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'SRI ARAVINDAR BALAR MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'SRI AUROBINDO INTERNATIONAL SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Sri Aurobindo mira matric.hr.sec school, Tamil Nadu & Puducherry (UT)',
  'sri ayyan vidyashram higher secondary school., Tamil Nadu & Puducherry (UT)',
  'Sri baldavas Vidya mandir kikani matriculation higher secondary school , Tamil Nadu & Puducherry (UT)',
  'sri baldevdas kikani vidyamandir, Tamil Nadu & Puducherry (UT)',
  'Sri Bharathi Vidhyalaya academy mat.hr.sec.school, Tamil Nadu & Puducherry (UT)',
  'SRI BHARATHIDASAN MATRIC HIGHER SECONDARY SCHOOL MANNARGUDI, Tamil Nadu & Puducherry (UT)',
  'Sri bharathidasan matric hr sec school , Tamil Nadu & Puducherry (UT)',
  'SRI CHAITANYA JUNIOR KALASALA, Tamil Nadu & Puducherry (UT)',
  'SRI CHAITANYA P U COLLEGE, Tamil Nadu & Puducherry (UT)',
  'Sri Chaitanya School, Tamil Nadu & Puducherry (UT)',
  'SRI CHAITANYA TECHNO SCHOOL, Tamil Nadu & Puducherry (UT)',
  'SRI G V G VISALAKSHI MATRICULATION HR SEC SCHOOL , Tamil Nadu & Puducherry (UT)',
  'Sri G.V.G Vishalakshi matriculation higher secondary School, Tamil Nadu & Puducherry (UT)',
  'SRI GANESAR HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'SRI GNANANANDA MAT HR SEC SCHOOL TIRUKOILUR, Tamil Nadu & Puducherry (UT)',
  'Sri Gnanananda matric higher secondary school, Tamil Nadu & Puducherry (UT)',
  'Sri Gopal Naidu He Sec School, Tamil Nadu & Puducherry (UT)',
  'SRI GOPALNAIDU HR SEC SCHOOL PEELAMEDU, Tamil Nadu & Puducherry (UT)',
  'Sri GRM Girls Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'SRI JAYAM NAMAKKAL MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Sri Jayendra Matriculation HSS, Tamil Nadu & Puducherry (UT)',
  'Sri Kalaivani matric he sec school, Tamil Nadu & Puducherry (UT)',
  'Sri Kamakshi Vidyalaya Matric Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'SRI KANNA MATRIC HR SEC SCHOOL CHINTHAMANI PULIANGUDI, Tamil Nadu & Puducherry (UT)',
  'SRI KRISH INTERNATIONAL SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Sri Krishna matric hr sec school oddanchatram , Tamil Nadu & Puducherry (UT)',
  'SRI KUMARAN MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Sri kumaran public school, Tamil Nadu & Puducherry (UT)',
  'Sri kumaran public senior secondary school, Tamil Nadu & Puducherry (UT)',
  'Sri lakshmi vidyalaya matric hr sec school, Tamil Nadu & Puducherry (UT)',
  'SRI LALITHAAMBIGA, Tamil Nadu & Puducherry (UT)',
  'SRI LATHANGI VIDHYA MANDHIR, Tamil Nadu & Puducherry (UT)',
  'Sri Lathangi Vidhya Mandir, Tamil Nadu & Puducherry (UT)',
  'sri mahadeva guruji matriculation  higher secondary school, Tamil Nadu & Puducherry (UT)',
  'Sri Matha Matric. higher sec. School, Tamil Nadu & Puducherry (UT)',
  'Sri Nachammal Vidyavani, Tamil Nadu & Puducherry (UT)',
  'Sri Nathella Sampathu Chetty Vivekananda Vidyalya Junior college, Tamil Nadu & Puducherry (UT)',
  'Sri P.Mallaiyan Matric HR.Sec.School, Tamil Nadu & Puducherry (UT)',
  'SRI R M JAIN VIDHYASHRAM SENIOR SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Sri R.M.jain Vidhyasharam, Tamil Nadu & Puducherry (UT)',
  'SRI RAGAVENDRA MATRIC HIGHER SECONDARY SCHOOL , Tamil Nadu & Puducherry (UT)',
  'Sri raghavendra vidyalya, Tamil Nadu & Puducherry (UT)',
  'Sri ram Dayal Khemka Vivekananda Vidyalaya Junior College, Tamil Nadu & Puducherry (UT)',
  'Sri Ramakrishna Matric.Hr.Sec.School, Tamil Nadu & Puducherry (UT)',
  'SRI RAMAKRISHNA MATRICHULATION HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Sri ramakrishna vidyalaya, Tamil Nadu & Puducherry (UT)',
  'Sri ramakrishna vidyalaya gurukulam matric higher secondary school, Tamil Nadu & Puducherry (UT)',
  'Sri ramana vidyalaya, Tamil Nadu & Puducherry (UT)',
  'SRI RAMANA VIDYALAYA MONTESSORI MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Sri Ramasamy Matric Hr. Sec. School, Tamil Nadu & Puducherry (UT)',
  'Sri Ramji Matric Hr sec school , Tamil Nadu & Puducherry (UT)',
  'sri ranganathar matric higher sec school, Tamil Nadu & Puducherry (UT)',
  'sri ranganathar matric school, Tamil Nadu & Puducherry (UT)',
  'SRI RAO BAHADHUR A.K.D.DHARMARAJA GIRLS HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Sri Renuga devi Hr Sec school, Tamil Nadu & Puducherry (UT)',
  'SRI RM JAIN VIDHYASHRAM SR SEC SCHOOL V M NAGAR THIRUVALLUR, Tamil Nadu & Puducherry (UT)',
  'Sri sai matriculation higher secondary school, Tamil Nadu & Puducherry (UT)',
  'SRI SANKARA VIDYASHRAMAM MAT. HR. SEC. SCHOOL , Tamil Nadu & Puducherry (UT)',
  "Sri Saradha balamandir boy's matriculation higher secondary school, Tamil Nadu & Puducherry (UT)",
  'SRI SARASWATHI VIDHYA MANDHIR, Tamil Nadu & Puducherry (UT)',
  'Sri Saraswathi Vidhya Mandhir school, Tamil Nadu & Puducherry (UT)',
  'Sri Saravana Nikethan , Tamil Nadu & Puducherry (UT)',
  'SRI SATHY SAI INSTITUTE OF EDUCARE, Tamil Nadu & Puducherry (UT)',
  'Sri Sathya sai martic hr sec school, Tamil Nadu & Puducherry (UT)',
  "SRI SESHAA'S INT PUBLIC SCHOOL YERCARD SALEM TN, Tamil Nadu & Puducherry (UT)",
  'sri seshaas international public school, Tamil Nadu & Puducherry (UT)',
  'SRI SHAKTHI INTERNATIONAL SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Sri shanmuga matric Higher secondary school, Tamil Nadu & Puducherry (UT)',
  'sri sharadha vidyalaya metric.hr.sec.school, Tamil Nadu & Puducherry (UT)',
  'SRI SITARAM VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Sri sowdambika convent matric higher secondary school, Tamil Nadu & Puducherry (UT)',
  'SRI SOWDAMBIKA CONVENT MATRICULATION HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Sri Sowdeswari Vidhyalaya, Tamil Nadu & Puducherry (UT)',
  'Sri Sowdeswari Vidhyalaya Matric Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'Sri SPK Public Senior Secondar School, Tamil Nadu & Puducherry (UT)',
  'sri suka brahma maharishi vidhyalayam matriculation higher secondary school, Tamil Nadu & Puducherry (UT)',
  'Sri SV Sala, Tamil Nadu & Puducherry (UT)',
  'SRI SWAMY INTERNATIONAL SCHOOL SALEM, Tamil Nadu & Puducherry (UT)',
  'Sri Vageesha Vidhyashram, Tamil Nadu & Puducherry (UT)',
  'SRI VAIYAPURI VIDYALAYA MATRIC HR SEC SCHOOL SANKARANKOVIL, Tamil Nadu & Puducherry (UT)',
  'Sri vasavi matriculation higher secondary school, Tamil Nadu & Puducherry (UT)',
  'SRI VENKATESHWAEA VIDHIYALAYA MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Sri Venkateshwara International School, Tamil Nadu & Puducherry (UT)',
  'sri venkateshwara international senior secondary school, Tamil Nadu & Puducherry (UT)',
  'SRI VENKATESHWARA MATRICLATION HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Sri Venkateswara International SchoolCBSE), Tamil Nadu & Puducherry (UT)',
  'SRI VENKATESWARA MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Sri Venkateswara vidyalaya higher secondary school, Tamil Nadu & Puducherry (UT)',
  'sri vidhya bharathi matric Hr sec school, Tamil Nadu & Puducherry (UT)',
  'Sri vidhya Bharati, Tamil Nadu & Puducherry (UT)',
  'Sri vidhya nikethan, Tamil Nadu & Puducherry (UT)',
  'Sri Vidhyaa Mandir higher secondary school, Tamil Nadu & Puducherry (UT)',
  'Sri Vidhyalakshmi Matriculation Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'Sri Vidya mandir higher secondry school , Tamil Nadu & Puducherry (UT)',
  'SRI VIDYA MANDIR HR SE SCH RASIPURAM, Tamil Nadu & Puducherry (UT)',
  'Sri Vidya Mandir Matriculation Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'sri vidya mandir senior secondary school , Tamil Nadu & Puducherry (UT)',
  'SRI VIDYA MANDIR SR SEC SCHOOL SHIVAJINAGAR SALEM, Tamil Nadu & Puducherry (UT)',
  'SRI VIDYA MATRIC. HR. SEC. SCHOOL , Tamil Nadu & Puducherry (UT)',
  'Sri vignesh vidhyalaya, Tamil Nadu & Puducherry (UT)',
  'SRI VIGNESH VIDYALAYA SENIOR SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'SRI VIGNESWARA MATRIC HIGHER SECONDRY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'SRI VIJAY VID GIRLS MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Sri Vijay vidhyala matric higher school, Tamil Nadu & Puducherry (UT)',
  'sri vijay vidyashram, Tamil Nadu & Puducherry (UT)',
  'SRI VIJAY VIDYASHRAM SR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'SRI VIJAY VIDYASHRAM SSS, Tamil Nadu & Puducherry (UT)',
  'Sri Vijaya Vidhayala HSS School, Tamil Nadu & Puducherry (UT)',
  'SRI VINAYAGA MATRIC HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'sri vinayaga vidhalaya school, Tamil Nadu & Puducherry (UT)',
  'Sri Vinayaga Vidhyalaya school , Tamil Nadu & Puducherry (UT)',
  'Sri Visweswara Vidyalaya Matriculation Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'sri vivekananda higher secondary school , Tamil Nadu & Puducherry (UT)',
  'Sri VIVEKANANDA HR SEC school , Tamil Nadu & Puducherry (UT)',
  'SRI. A. RAMASAMY MATRICULATION HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'SRI. P. MALLAIYAN MATRICULATION HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Sri.vijay vidhyalaya .mat.hr.sec.school, Tamil Nadu & Puducherry (UT)',
  'SRIBALA VIDYA MANDIR MATRIC HR SCHOOL, Tamil Nadu & Puducherry (UT)',
  'SRII JOTHI HIGER SECONDARU SCHOOL , Tamil Nadu & Puducherry (UT)',
  'SRII SUNDARESWARA VIDYA SALA MATRIC HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'SRIMATHI LAKSHMIAMMAL MEMORIAL MAT.HR.SEC.SCHOOL, Tamil Nadu & Puducherry (UT)',
  'srimathi sundaravalli memorial school Perungalathur, Tamil Nadu & Puducherry (UT)',
  'Srinivasa  vidhyalaya matriculation higer srcondary scho, Tamil Nadu & Puducherry (UT)',
  'SRINIVASA VIDHYALAYA MAT HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Srinivasa vidhyalaya matric Hr secondary school, Tamil Nadu & Puducherry (UT)',
  'Srinivasa vidhyalaya matriculation higher secondary school , Tamil Nadu & Puducherry (UT)',
  'Srivi lions matric higher Secondary school, Tamil Nadu & Puducherry (UT)',
  'SRIVI LIONS MATRIC HR. SEC. SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Srividhyalaya MHSSchool, Tamil Nadu & Puducherry (UT)',
  'SRIVIJAYVIDYALAYA MAT.HR.SEC.SCHOOL, Tamil Nadu & Puducherry (UT)',
  'SRK Matric Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'SRK MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'SRK Matric. Boys Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'SRKBV matric higersecondary school, Tamil Nadu & Puducherry (UT)',
  'SRKV GURUKULAM MATRIC HR SEC SCHOOL ULUNDURPET, Tamil Nadu & Puducherry (UT)',
  'SRM PUBLIC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Srnv  higher secondary school, Tamil Nadu & Puducherry (UT)',
  'SRRI SPK PUBLIC SENIOR SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Srt universal  higher secondary school sathyamangalam, Tamil Nadu & Puducherry (UT)',
  'SRT Universal matric Hr Sec School, Tamil Nadu & Puducherry (UT)',
  'SRT UNIVERSAL MATRICULATION HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'SRV BOYS HIGHER SECONDARY SCHOOL rasipuram, Tamil Nadu & Puducherry (UT)',
  'SRV GIRLS HIGHER SECONDARY SCHOOL , Tamil Nadu & Puducherry (UT)',
  'SRV Hi TECH MATRIC HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'SRV Matric Hr Sec School, Tamil Nadu & Puducherry (UT)',
  'SRV Matric Hr Sec School Samayapuram Trichy, Tamil Nadu & Puducherry (UT)',
  'SRV matriculation higher secondary school, Tamil Nadu & Puducherry (UT)',
  'SRV MATRICULATION HR. SEC. SCHOOL- SAMAYAPURAM, Tamil Nadu & Puducherry (UT)',
  'SRV.MATRIC.HIGHER.SEC.SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Srvs nation higher secondary school, Tamil Nadu & Puducherry (UT)',
  'SRVS National Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'ss, Tamil Nadu & Puducherry (UT)',
  'SSM  matric higher secondary school, Tamil Nadu & Puducherry (UT)',
  'SSM ACADEMY CBSE, Tamil Nadu & Puducherry (UT)',
  'SSM CENTRAL SCHOOL, Tamil Nadu & Puducherry (UT)',
  'SSM LAKSHMI AMMAL MATRIC HR SEC SCHOOL , Tamil Nadu & Puducherry (UT)',
  'SSN COLLEGE OF ENGINEERING, Tamil Nadu & Puducherry (UT)',
  'SSV CBSE AND MATRIC SCHOOL , Tamil Nadu & Puducherry (UT)',
  "St .Mary's girl's higher secondary school , Tamil Nadu & Puducherry (UT)",
  "ST ANNE'S MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)",
  "ST ANTONY'S MATRICULATION HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)",
  'St Brittos Academy, Tamil Nadu & Puducherry (UT)',
  'ST IGNATIUS CONVENT HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'ST JAMES MATRICULATION HIGHER SECONDARY SCHOOL TRICHY 620 001, Tamil Nadu & Puducherry (UT)',
  "ST JOHN'S ENGLISH SCHOOL, Tamil Nadu & Puducherry (UT)",
  'St Johns English School and Junior College, Tamil Nadu & Puducherry (UT)',
  'ST JOSEPHS RESIDENTIAL SCHOOL SRIPERUMBUDUR CHENNAI, Tamil Nadu & Puducherry (UT)',
  'ST JUDES PUBLIC SCHOOL AND JUNIOR COLLEGE, Tamil Nadu & Puducherry (UT)',
  'St Marcinas Matric Hr Sec School, Tamil Nadu & Puducherry (UT)',
  "ST MARK'S MHSS, Tamil Nadu & Puducherry (UT)",
  'ST MARY OF LEUCA MATRIC. HR. SEC. SCHOOL, Tamil Nadu & Puducherry (UT)',
  "ST MARY'S ANGLO-INDIAN BOYS  HR SEC SCHOOL CHENNAI, Tamil Nadu & Puducherry (UT)",
  "ST Mary's Higher secondary school , Tamil Nadu & Puducherry (UT)",
  "St mary's matric hr sec school, Tamil Nadu & Puducherry (UT)",
  'ST MARYS MATRIC HR SEC SCHOOL CUDDALORE, Tamil Nadu & Puducherry (UT)',
  'ST MICHAEL MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  "ST michael's accademy, Tamil Nadu & Puducherry (UT)",
  'St Michaels Academy, Tamil Nadu & Puducherry (UT)',
  'ST PATRICK MATRIC HSS, Tamil Nadu & Puducherry (UT)',
  "St Patrick's Anglo Indian Higher Secondary School, Tamil Nadu & Puducherry (UT)",
  'ST PAULS MAT HR SEC SCHOOL NEYVELI, Tamil Nadu & Puducherry (UT)',
  "ST PETER'S MATRICULATION HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)",
  'ST Thomas  MHSS Ayyankolly, Tamil Nadu & Puducherry (UT)',
  'ST THOMAS HIGHER  SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'ST THOMAS MATRIC HR SEC SCHOOL MMC CHENNAI, Tamil Nadu & Puducherry (UT)',
  'St Vincent matriculation higher secondary school , Tamil Nadu & Puducherry (UT)',
  "St. Anne's higher secondary school , Tamil Nadu & Puducherry (UT)",
  "ST. ANTONY'S MATRICULATION HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)",
  "ST. BEDE'S ANGLO INDIAN BOYS HIGHER SECONDARY SCHOOL SANTHOME, Tamil Nadu & Puducherry (UT)",
  'ST. FRANCIS MATRIC.HR.SEC. SCHOOL, Tamil Nadu & Puducherry (UT)',
  'St. Francis xaviers hr. Sec school, Tamil Nadu & Puducherry (UT)',
  'ST. JAMES MATRICULATION HR. SEC. SCHOOL, Tamil Nadu & Puducherry (UT)',
  "St. Jude's Public School & Junior College, Tamil Nadu & Puducherry (UT)",
  'St. Kevins anglo indian higher secondary school, Tamil Nadu & Puducherry (UT)',
  "St. Mary's higher secondary school Melpala, Tamil Nadu & Puducherry (UT)",
  "St. Mary's Matriculation Higher Secondary School, Tamil Nadu & Puducherry (UT)",
  "St. Michael's Academy, Tamil Nadu & Puducherry (UT)",
  'St. Micheal Matric Hr.Sec School, Tamil Nadu & Puducherry (UT)',
  'st. Patrick higher secondary school, Tamil Nadu & Puducherry (UT)',
  'St. Paul High School, Tamil Nadu & Puducherry (UT)',
  'St. Paul Matriculation Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'St. Thomas matriculation higher secondary school, Tamil Nadu & Puducherry (UT)',
  'St. tresas matric hr sec school, Tamil Nadu & Puducherry (UT)',
  'St.Alphonsa Matriculation Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'St.Anns Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'St.Anns matriculation Hr.Sec.Schoo, Tamil Nadu & Puducherry (UT)',
  "St.Antony's  Matric Higher secondary school, Tamil Nadu & Puducherry (UT)",
  "ST.ANTONY'S MATRIC HR.SEC. SCHOOL, Tamil Nadu & Puducherry (UT)",
  "St.Bede's A.I.Hr.sec school, Tamil Nadu & Puducherry (UT)",
  "St.Brito's Academy, Tamil Nadu & Puducherry (UT)",
  "ST.BRITTO'S MATRICULATION HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)",
  'St.Francis Matriculation Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'ST.FRANCIS XAVIER A.I.H.S.SCHOOL, Tamil Nadu & Puducherry (UT)',
  'St.Ignatius Convent H S S, Tamil Nadu & Puducherry (UT)',
  'St.JAMES MARTICULATION HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  "ST.JOHN'S ENGLISH SCHOOL AND JUNIOR COLLEGE, Tamil Nadu & Puducherry (UT)",
  'St.johns.Mat.Hr.Sec.school, Tamil Nadu & Puducherry (UT)',
  'St.joseph academy matriculation higher secondary school, Tamil Nadu & Puducherry (UT)',
  "St.Jude's Public School & Junior College, Tamil Nadu & Puducherry (UT)",
  'St.Marcinas school, Tamil Nadu & Puducherry (UT)',
  "St.Mark's MHSS, Tamil Nadu & Puducherry (UT)",
  'ST.Mary Goretty Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  "St.Mary's Matriculation Higher Secondary School, Tamil Nadu & Puducherry (UT)",
  'St.Marys.Hr.Sec.School, Tamil Nadu & Puducherry (UT)',
  'St.Michael matric hr sec school, Tamil Nadu & Puducherry (UT)',
  'ST.MICHAELS ACADEMY, Tamil Nadu & Puducherry (UT)',
  'ST.PATRICK MAT HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  "St.Paul's matriculation higher secondary school, Tamil Nadu & Puducherry (UT)",
  "ST.PAUL'S.MATRICULATION.HIGHER.SECONDARY.SCHOOL, Tamil Nadu & Puducherry (UT)",
  'St.peters , Tamil Nadu & Puducherry (UT)',
  'St.Thomas matriculation , Tamil Nadu & Puducherry (UT)',
  'ST.THOMAS MATRICULATION HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'St.Thomas matriculation Hr Sec School, Tamil Nadu & Puducherry (UT)',
  'St.tresas matric hr sec school, Tamil Nadu & Puducherry (UT)',
  'Stands Anglo-Indian School, Tamil Nadu & Puducherry (UT)',
  'Stanes AI Higher secondary school , Tamil Nadu & Puducherry (UT)',
  'STATE BOARD OF INTERMEDIATE EDUCATION, Tamil Nadu & Puducherry (UT)',
  'Subbiah Matric.Hr.Sec School, Tamil Nadu & Puducherry (UT)',
  'SUBBIAH VIDAYALAYAM GIRLS HR.SEC.SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Subramania bhaarathi higher secondary school, Tamil Nadu & Puducherry (UT)',
  'SUDANDIRA MATRIC HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'SUDHARSANAM VIDYAASHRAM, Tamil Nadu & Puducherry (UT)',
  'SUGUNA PIP SCHOOL , Tamil Nadu & Puducherry (UT)',
  'SUNBEEM CBSE SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Sunshine Chennai SS School, Tamil Nadu & Puducherry (UT)',
  'Suraj Matriculation Higher secondary school, Tamil Nadu & Puducherry (UT)',
  'Sushil Hari International Residential School, Tamil Nadu & Puducherry (UT)',
  'SVB Matric Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'SVGV Matric Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'SVGV MATRICULATION HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Svgv matriculation hrs sec school, Tamil Nadu & Puducherry (UT)',
  'SVN Matric Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'SWAMYS MATRICULATION SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Swathanthra HSS, Tamil Nadu & Puducherry (UT)',
  'SYED AMMAL HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'T I MATRICULATION HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'T K SANGAM  MATRIC HR SEC SCHOOL THENI, Tamil Nadu & Puducherry (UT)',
  'T.M.H.N.U MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'TAGORE MATRIC HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'TAGORE PUBLIC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Tagore vidyalayam matriculation higher secondary school, Tamil Nadu & Puducherry (UT)',
  'TAMILNADU STATE BOARD, Tamil Nadu & Puducherry (UT)',
  'TAN ACADEMY Matric HR Sec school, Tamil Nadu & Puducherry (UT)',
  'Test School, Tamil Nadu & Puducherry (UT)',
  'THAAI SCHOOL CBSE, Tamil Nadu & Puducherry (UT)',
  'Thamarai International School Thanjavur, Tamil Nadu & Puducherry (UT)',
  'THANTHAI HANS ROEVER HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'THE  JOHN DEWEY MATRIC Hr SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'The  Laidlaw Memorial school and junior college ketti, Tamil Nadu & Puducherry (UT)',
  'THE  VIKASA HIGHER. SECONDARY  SCHOOL  , Tamil Nadu & Puducherry (UT)',
  'THE ASHOK LEYLAND SCHOOL, Tamil Nadu & Puducherry (UT)',
  'THE BVB SCHOOL , Tamil Nadu & Puducherry (UT)',
  'THE C.P. RAMASWAMI AIYAR FOUNDATION, Tamil Nadu & Puducherry (UT)',
  'The C.P.Ramaswami Aiyar Foundation Alwarpet, Tamil Nadu & Puducherry (UT)',
  'The camford International School, Tamil Nadu & Puducherry (UT)',
  'THE CP RAMASWAMI AIYAR SARASWATI, Tamil Nadu & Puducherry (UT)',
  'THE DAWN PUBLIC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'The Frontline Academy Matric. Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'The GEEKAY World School, Tamil Nadu & Puducherry (UT)',
  'THE GROVE SCHOOL, Tamil Nadu & Puducherry (UT)',
  'The Higher Secondary School for Boys, Tamil Nadu & Puducherry (UT)',
  'THE HINDU COLONY CHELLAMAL VIDYALAYA SENIOR SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'The Hindu Colony Chellammal Vidyalaya Senior Secondary School, Tamil Nadu & Puducherry (UT)',
  'The Indian Public School , Tamil Nadu & Puducherry (UT)',
  'THE LAIDLAW MEMORIAL SCHOOL & JR COLLEGE, Tamil Nadu & Puducherry (UT)',
  'THE LAKSHMI MILLS HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'The Lawrence School, Tamil Nadu & Puducherry (UT)',
  'The Lawrence School Lovedale, Tamil Nadu & Puducherry (UT)',
  'The Little kingdom senior school, Tamil Nadu & Puducherry (UT)',
  "The Lords' International School, Tamil Nadu & Puducherry (UT)",
  'THE MERIT HR SEC SCHOOL SENTHAMANGALAM, Tamil Nadu & Puducherry (UT)',
  'THE MODERN ACADEMY MATRIC HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'The Navodaya Academy - Senior Secondary CBSE School, Tamil Nadu & Puducherry (UT)',
  'THE NAVODAYA ACADEMY SENIOR SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'THE NGP School, Tamil Nadu & Puducherry (UT)',
  'The Optimus Public School, Tamil Nadu & Puducherry (UT)',
  'THE OPTIUS PUBLIC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'The Oxford Senior Secondary School SVG, Tamil Nadu & Puducherry (UT)',
  'THE PATH GP SCHOOL , Tamil Nadu & Puducherry (UT)',
  'THE PIONEER SCHOOL VETTURNIMADAM NAGERCOIL K K DIST, Tamil Nadu & Puducherry (UT)',
  'The presidency Higher secondary school , Tamil Nadu & Puducherry (UT)',
  'THE PSBB MILLENNIUM SCHOOL , Tamil Nadu & Puducherry (UT)',
  'The psbb millennium school gerugambakkam , Tamil Nadu & Puducherry (UT)',
  'The Pupil Saveetha Eco School , Tamil Nadu & Puducherry (UT)',
  'The Rajas international school  nagercoil, Tamil Nadu & Puducherry (UT)',
  'The School KFI, Tamil Nadu & Puducherry (UT)',
  'THE SCHRAM ACADEMY, Tamil Nadu & Puducherry (UT)',
  'The Schramm Academy, Tamil Nadu & Puducherry (UT)',
  'The Shrishti Vidyashram Senior Secondary School, Tamil Nadu & Puducherry (UT)',
  'The sivakasi lions matic Higer sec school, Tamil Nadu & Puducherry (UT)',
  'THE STANES ANGLO INDIAN HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'The Study lecole international, Tamil Nadu & Puducherry (UT)',
  'THE STUDY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'THE SUGUNA PIP SCHOOL, Tamil Nadu & Puducherry (UT)',
  'THE T V S SCHOOL, Tamil Nadu & Puducherry (UT)',
  'THE TRINITY ACADEMY , Tamil Nadu & Puducherry (UT)',
  'THE TVS SCHOOL, Tamil Nadu & Puducherry (UT)',
  'THE UNIQUE ACADEMY, Tamil Nadu & Puducherry (UT)',
  'The Vijay Millennium Senior Secondary school, Tamil Nadu & Puducherry (UT)',
  'The Vikasa School, Tamil Nadu & Puducherry (UT)',
  'Theni Melapettai Hindu Nadar Uravinmurai Matriculation Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'THENMALAR HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Thiagarajar model higher secondary school, Tamil Nadu & Puducherry (UT)',
  'THIAGARAJAR.MODEL.HIGHER.SECONDARY.SCHOOL, Tamil Nadu & Puducherry (UT)',
  'THIRUMURUGAN MATRICULATION HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Thiruvalluvar Matric Hr.Sec.School, Tamil Nadu & Puducherry (UT)',
  'Thiyagi N G Ramaswamy Mem HR Sec School, Tamil Nadu & Puducherry (UT)',
  'TI Matriculation Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'TMHNU Mat Hr sec School, Tamil Nadu & Puducherry (UT)',
  'TNPL PUBLIC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'To appear in Class XII EXAM in March 2020, Tamil Nadu & Puducherry (UT)',
  'Town higher secondary school, Tamil Nadu & Puducherry (UT)',
  'Trinity academy mat. Hr.sec.school, Tamil Nadu & Puducherry (UT)',
  'TRINITY ACADEMY MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'TRINITY INTERNATIONAL SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Trinity Matriculation Higher Secondary School , Tamil Nadu & Puducherry (UT)',
  'TVIS Velammal Bodhi Campus, Tamil Nadu & Puducherry (UT)',
  'union christian matriculation school, Tamil Nadu & Puducherry (UT)',
  'UNITED  PUBLIC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Unity public school, Tamil Nadu & Puducherry (UT)',
  'UNIVERSAL MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'UPDATE Knowledge result Kathir Vidyaa Mandhir, Tamil Nadu & Puducherry (UT)',
  'URC PALANIAMMAL MATRICULATION HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'V S BOYS HR SEC SCHOOL , Tamil Nadu & Puducherry (UT)',
  'V S VIDYODAYA SCHOOL , Tamil Nadu & Puducherry (UT)',
  'V V N K M ENGLISH MEDIUM SCHOOL, Tamil Nadu & Puducherry (UT)',
  'V.M.J SCHOOL, Tamil Nadu & Puducherry (UT)',
  'V.V.N.K.M.SR.SEC.SCHOOL, Tamil Nadu & Puducherry (UT)',
  'vaagai vidhyalaya matric hr.sec school, Tamil Nadu & Puducherry (UT)',
  'Vaels International School, Tamil Nadu & Puducherry (UT)',
  'VAGEESWARI VIDHYALAYA, Tamil Nadu & Puducherry (UT)',
  'Vagisvari vidhya Mandir, Tamil Nadu & Puducherry (UT)',
  'VAILAKANNI MATRIC HR SEC SCHOOL , Tamil Nadu & Puducherry (UT)',
  'Vailankanni matha matriculation hr secondary school, Tamil Nadu & Puducherry (UT)',
  'Vailankanni matriculation Hr. Sec School, Tamil Nadu & Puducherry (UT)',
  'Vailankanni Public School (CBSE), Tamil Nadu & Puducherry (UT)',
  'VAIRAMS MATRIC HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'valaikanni matha matric higher secondary school, Tamil Nadu & Puducherry (UT)',
  'Valemmal Matric Higer Secondary School Mogappair west, Tamil Nadu & Puducherry (UT)',
  'VANA VANI MATRICULATION HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Vanaprastha International School, Tamil Nadu & Puducherry (UT)',
  'vanathirayanpattanam, Tamil Nadu & Puducherry (UT)',
  'VANAVANI MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Vani Vidhayalaya, Tamil Nadu & Puducherry (UT)',
  'Vani Vidhayalaya Matric Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'Vani Vidyalaya Senior Secondary & Junior College, Tamil Nadu & Puducherry (UT)',
  'Vanshi Manthan Vidyashram, Tamil Nadu & Puducherry (UT)',
  'VARIYAR higher secondary school , Tamil Nadu & Puducherry (UT)',
  'vasavi vidyalaya mhss , Tamil Nadu & Puducherry (UT)',
  'VAV International School, Tamil Nadu & Puducherry (UT)',
  'VCV SHISHU VIDYODAYA HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'VEDANTA ACADEMY, Tamil Nadu & Puducherry (UT)',
  'vedavalii hr.sec.school, Tamil Nadu & Puducherry (UT)',
  'Vedavalli Vidyalaya, Tamil Nadu & Puducherry (UT)',
  'VEDAVALLI VIDYALAYA HIGHER SECONDARY SCHOOL WALAJAPET, Tamil Nadu & Puducherry (UT)',
  'Vedha Vikas Matriculation School, Tamil Nadu & Puducherry (UT)',
  'Vedic vidhyashram senior secondary school, Tamil Nadu & Puducherry (UT)',
  'VEDIC VIDYASHRAM CBSE SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Vellammal vidhyashram, Tamil Nadu & Puducherry (UT)',
  'Vels vidyashram Senior secondary school, Tamil Nadu & Puducherry (UT)',
  'Velumanickam Matriculation Higher Secondary school, Tamil Nadu & Puducherry (UT)',
  'Venganar vajram matri Hr sec school, Tamil Nadu & Puducherry (UT)',
  'VENKATALAKSHMI MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Venkatalakshmi.matric.Hr.Sec.School, Tamil Nadu & Puducherry (UT)',
  'Venkateshwara vidhyalaya matric higher secondary school, Tamil Nadu & Puducherry (UT)',
  'Venkateshwara vidyalaya , Tamil Nadu & Puducherry (UT)',
  'venkateswara vidyalaya matriculation hr.sec.school, Tamil Nadu & Puducherry (UT)',
  'Venus Matric HR. Sec. School, Tamil Nadu & Puducherry (UT)',
  'VENUS MATRICULATION HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Vetri vikas boys higher secondary school, Tamil Nadu & Puducherry (UT)',
  'Vetrivikaas boys hr school, Tamil Nadu & Puducherry (UT)',
  'Veveaham Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'VEVEAHAM HIGHER SECONDARY SCHOOL DHARAPURAM, Tamil Nadu & Puducherry (UT)',
  'Veveaham Matriculation Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'VEVEGAM MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'VHN higher secondary school , Tamil Nadu & Puducherry (UT)',
  'victoria matric hr sec school, Tamil Nadu & Puducherry (UT)',
  'Victory vidhyalaya matriculation higher secondary school, Tamil Nadu & Puducherry (UT)',
  'Vidhya matric HR sec school , Tamil Nadu & Puducherry (UT)',
  'VIDHYA NETHRRA MATRIC HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'VIDYAMANDIR ESTANCIA, Tamil Nadu & Puducherry (UT)',
  'VIDYANIKETAN MATRIC HR SEC SCHOOL -KATPADI, Tamil Nadu & Puducherry (UT)',
  'VIDYAPEETAM SENIOR SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'VIDYASAGAR INTERNATIONAL PUBLIC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'VIDYAVIKAS INTERNATIONAL SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Vidyodaya Matriculation Hr. Sec. Academy, Tamil Nadu & Puducherry (UT)',
  'Vidys vikas international school, Tamil Nadu & Puducherry (UT)',
  'Vignesh international school, Tamil Nadu & Puducherry (UT)',
  'Vignesh Sri Renga Matriculation Hr Sec School, Tamil Nadu & Puducherry (UT)',
  'Vigneshwar Vidhya Manthir Matric higher secondary school , Tamil Nadu & Puducherry (UT)',
  'VIIVEKANANDA VIDYALAYA , Tamil Nadu & Puducherry (UT)',
  'Vijajayanta model higher secondary school, Tamil Nadu & Puducherry (UT)',
  'VIJAY VIDYASHARAM SENIOR SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Vijayalakshmi Matric Higher secondary school, Tamil Nadu & Puducherry (UT)',
  'VIJAYANTA MODEL HR SEC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Vikash Vidhyalaya metric hr sec school, Tamil Nadu & Puducherry (UT)',
  'VIMAL JYOTHI CONVENT MATRIC. HR.SEC.SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Vimala matric higher secondary school thuraiyur, Tamil Nadu & Puducherry (UT)',
  'Virudhunagar T.S.M. Manickam Nadar â€“ Janaki Ammal Higher Secondary school, Tamil Nadu & Puducherry (UT)',
  'Vivekam Senior Secondary School (CBSE) Coimbatore, Tamil Nadu & Puducherry (UT)',
  'Vivekanadha Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'VIVEKANANDA MATRICULATION HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Vivekananda Vidya Kala Ashram, Tamil Nadu & Puducherry (UT)',
  'Vivekananda Vidyalaya Hr.Sec.School, Tamil Nadu & Puducherry (UT)',
  'VMJ HIGHER SECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'VNUAPT mat hr sec school VILAMPATTI , Tamil Nadu & Puducherry (UT)',
  'VVCR.M.Senguthar girls HR sec school, Tamil Nadu & Puducherry (UT)',
  'Vyasa Vidhyalaya, Tamil Nadu & Puducherry (UT)',
  'Wisdom Hr sec school, Tamil Nadu & Puducherry (UT)',
  'Woodside School, Tamil Nadu & Puducherry (UT)',
  'YAGAPPA HR SEC MATRICULATION SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Yettacode Higher Secondary School, Tamil Nadu & Puducherry (UT)',
  'YOGI VEMANA MATRICULATION HIGHERSECONDARY SCHOOL, Tamil Nadu & Puducherry (UT)',
  'YRTV MAT HSS SIVAKASI , Tamil Nadu & Puducherry (UT)',
  'YUVABHARATHI PUBLIC SCHOOL, Tamil Nadu & Puducherry (UT)',
  'Zion matric higher secondary school, Tamil Nadu & Puducherry (UT)',
  'ZKM Hr Sec School, Tamil Nadu & Puducherry (UT)',
  'AADITYA JUNIOR COLLEGE, Telangana',
  'Aakash junior college , Telangana',
  'ABHYAS JUNIOR COLLEGE, Telangana',
  'ABHYAS TECHNO SCHOOL, Telangana',
  'Abhyasa international residential school, Telangana',
  'AKSHARA JUNIOR COLLEGE, Telangana',
  'Alpha high school, Telangana',
  'ALPHORES JUNIOR COLLEGE KARIMNAGAR, Telangana',
  'alsafa junior college , Telangana',
  'AMS. P. Obul Reddy Public School, Telangana',
  'ANNUS JUNIOR COLLEGE, Telangana',
  'APS Bolarum, Telangana',
  'Army Public School , Telangana',
  'ARYABHATTA CONCEPT SCHOOL, Telangana',
  'Ascent Junior College, Telangana',
  'Ashrama High school Nirmal, Telangana',
  'Aspirants IIT Academy, Telangana',
  'Atomic energy central school hyderabad, Telangana',
  'AURORA JUNIOR COLLEGE, Telangana',
  'Auxilium high school, Telangana',
  'Avinash College of commerce, Telangana',
  'BALAJI JUNIOR COLLEGE, Telangana',
  'BHADRACHALAM PUBLIC SCHOOL & JUNIOR COLLEGE, Telangana',
  "Bharatiya Vidya Bhavan's Vidyasharam Public School, Telangana",
  'BHASHAYAM HIGH SCHOOL, Telangana',
  'Bhashyam junior college , Telangana',
  'BHASYAM IIT COLLEGE, Telangana',
  'BHAVANS SHRI RAMAKRISHNA VIDYALAYA, Telangana',
  'bhavans sri ramakrishna vidyalaya, Telangana',
  'BHAVITHA JUNIOR COLLEGE, Telangana',
  'Blooming Buds High School, Telangana',
  'BRILLIENT JR COLLEGE, Telangana',
  'CANDOR SHRINE I SR SEC SCHOOL, Telangana',
  'Centre of excellence algunuoor karimnagar, Telangana',
  'Chaitanya junior college, Telangana',
  'chaitanya kalashala, Telangana',
  'CHINMAYA VIDYALAYA, Telangana',
  'Chirec International School, Telangana',
  'chirec public school, Telangana',
  'Chitanya jr. College  khammam, Telangana',
  'CITY CENTRAL JUNIOR COLLEGE, Telangana',
  'CV RAMAN JUNIOR COLLEGE, Telangana',
  'D A V PUBLIC SCHOOL SAFILGUDA HYDERABAD, Telangana',
  'D R S INTERNATIONAL SCHOOL HYDERABAD , Telangana',
  'DAV PUBLIC SCHOOL, Telangana',
  'DAV School Kanchanbagh Hyderabad, Telangana',
  'DDMS(AMS)P.Obul Reddy Public School, Telangana',
  'Deeksha Junior College, Telangana',
  'Delhi Public School, Telangana',
  'DELTA JUNIOR COLLEGE, Telangana',
  'Dhruva junior college for girls , Telangana',
  'DIKSUKHNAGAR HIGH SCHOOL, Telangana',
  'dnyanmata high school, Telangana',
  'Dps warangal, Telangana',
  'DR KKR GOWTHAM SCHOOL, Telangana',
  'Dr RLR Junior College, Telangana',
  'DR.KKR GOWTHAM SCHOOL , Telangana',
  'Drs international school , Telangana',
  'ekalavya junior college, Telangana',
  'EXCEL JUNIOR COLLEGE, Telangana',
  'Excellentia Junior College, Telangana',
  'FIIJEE COLLEGE, Telangana',
  'FIIT JEE JUNIOR COLLEGE, Telangana',
  'FIRDAUS AMRUT CENTRE, Telangana',
  'Forum for Indian Institute of Technology junior college, Telangana',
  'FR. AGNEL SCHOOL, Telangana',
  'Ganges Valley School, Telangana',
  'Gayatri group of institutions, Telangana',
  'Geetanjali High School, Telangana',
  'Gitam Junior college, Telangana',
  'Glendale Academy, Telangana',
  'GLOBAL INDIAN INTERNATIONAL SCHOOL, Telangana',
  'Gnanavapi Junior College, Telangana',
  'GOLDENBELLS E M SCHOOL, Telangana',
  'Gorkey public school, Telangana',
  'Goutam model school, Telangana',
  'Goutham junior college, Telangana',
  'GOVERNMENT POLYTECHNIC SANGAREDDY, Telangana',
  'Govt junior college garla, Telangana',
  'Govt polytechnic College gomaram Narsapur, Telangana',
  'Gowtham Junior College, Telangana',
  'GOWTHAM MODEL SCHOOL , Telangana',
  'GOWTHAMI academy junior college, Telangana',
  'GREENWOOD HIGH SCHOOL, Telangana',
  'GTA VIDHYA MANDIR, Telangana',
  'GURU JUNIOR COLLEGE, Telangana',
  'Gyanavapi Junior College, Telangana',
  'HARWEST JUNIOR COLLEGE, Telangana',
  'Heritage Valley the Indian School, Telangana',
  'Hindu public school, Telangana',
  'hombley, Telangana',
  'HYDERABAD INSTITUTE OF EXCELLENCE JUNIOR COLLEGE, Telangana',
  'I CLASS CDR JUNIOR COLLEGE, Telangana',
  'IDEAL JUNIOR COLLEGE , Telangana',
  'Impulse junior college, Telangana',
  'IMPULSE JUNIOR COLLEGE KUKATPALLY, Telangana',
  'indian blossom high school, Telangana',
  'Indrani High school, Telangana',
  'INDUS UNIVERSAL SCHOOL, Telangana',
  'IRA JUNIOR COLLEGE, Telangana',
  'Jain heritage a Cambridge school , Telangana',
  'jaya high school, Telangana',
  'JNV KHAMMAM, Telangana',
  'Johnson Grammar School, Telangana',
  'JOUNIOR COLLEGE CHAITANYA TANDUR, Telangana',
  'k.k reddy school, Telangana',
  'Kakatiya High School, Telangana',
  'KAKATIYA TECHNO SCHOOL, Telangana',
  'KASTURBHA GANDHI, Telangana',
  'KENDRIYA VIDYALAYA, Telangana',
  'KENNEDY HIGH - THE GLOBAL SCHOOL, Telangana',
  'KESAVAREDDY EM HIGH SCHOOL, Telangana',
  'KESHAV SMARAK JUNIOR COLLEGE, Telangana',
  'KESHAVA REDDY CONCEPT SCHOOL, Telangana',
  'krishna murty junior college, Telangana',
  'Krishna veni co operative jr college, Telangana',
  'KRISHNAMURTHY JUNIOR COLLEGE, Telangana',
  'KRISHNAVENI JR COLLEGE KHAMMAM, Telangana',
  'Krishnaveni Junior college, Telangana',
  'Krishnaveni talent school, Telangana',
  'LITTLE ANGELS SCHOOL, Telangana',
  'Little Flower Junior College, Telangana',
  'Little Genius High school, Telangana',
  'littleflower, Telangana',
  'Loyola Academy junior college, Telangana',
  'MADHU JUNIOR COLLEGE, Telangana',
  'MAHARISHI INT RES SCHOOL, Telangana',
  'Maharishi Vidya Madir, Telangana',
  'MANTHAN INTERNATIONAL SCHOOL Hyderabad, Telangana',
  'MATHRU SRI JUNIOR COLLEGE, Telangana',
  'MELUHA INTERNATIONAL SCHOOL, Telangana',
  'Meluha Junior College, Telangana',
  'meridian school madapur, Telangana',
  'MILLENNIUM HIGH SCHOOL , Telangana',
  'Modern Senior Secondary School, Telangana',
  'Montessori school , Telangana',
  'MOTHER JUNIOR COLLEGE, Telangana',
  'Mount Litera Zee High School, Telangana',
  'ms junior college, Telangana',
  'N t r Junior College Hyderabad, Telangana',
  'Nalandha junior college , Telangana',
  'nano junior college Nallakunta Hyderabad, Telangana',
  'NARAYANA  CONCEPT SCHOOL, Telangana',
  'NARAYANA  IIT ACADAMY , Telangana',
  'NARAYANA COLLEGE SARASWATHI BHAVAN, Telangana',
  'NARAYANA e-co  SCHOOL , Telangana',
  'NARAYANA E-TECHNO SCHOOL, Telangana',
  'NARAYANA EDUCATOINAL INSTITUTIONS , Telangana',
  'Narayana EM school, Telangana',
  'NARAYANA IAS ACADEMY  HYDERABAD, Telangana',
  'NARAYANA JUNIOR COLLEGE, Telangana',
  'NARAYANA NARMADA JUNIOR COLLEGE, Telangana',
  'NARAYANA OLYMPIAD SCHOOL , Telangana',
  'Narayana PU College Hulimavu Branch, Telangana',
  'NARAYANA SCHOOL E M NANDIGAMA, Telangana',
  'Nava bharth public school, Telangana',
  'New Chaitanya Junior College, Telangana',
  'NRI JUNIOR KALASALA, Telangana',
  'Nri juniour kalasala nizampet, Telangana',
  'Oakwood International School, Telangana',
  'Omega Junior college, Telangana',
  'PAGE JUNIOR COLLEGE, Telangana',
  'Rosary convent school, Telangana',
  'ROYAL JUNIOR COLLEGE, Telangana',
  'S R Junior College For Boys, Telangana',
  'S V CHILDRENS HIGH SCHOOL, Telangana',
  'S.R.JUNIOR COLLEGE , Telangana',
  'Sadhana Junior College, Telangana',
  'Sadhu Vaswani International School, Telangana',
  'Sai Chaitanya junior, Telangana',
  'SAI VEDA JUNIOR COLLEGE, Telangana',
  'sainikpuri high school, Telangana',
  'Saint Peters Senior Secondary School, Telangana',
  'Samaritans public School, Telangana',
  'SCHOLARS JUNIOR COLLEGE, Telangana',
  'seeaakashjuniorcollege, Telangana',
  'Sr Chaitanya Junior kalasala, Telangana',
  'sr digi school kompally, Telangana',
  'SR Educational Academy, Telangana',
  'SR JR COLLEGE HANAMKONDA, Telangana',
  'SR JUNIOR COLLEGE KARIMNAGAR, Telangana',
  'sree chaitnya jr.college, Telangana',
  'SREE NIDHI JR COLLEGE., Telangana',
  'Sree Vardhan Junior College, Telangana',
  'SREE visveswaraya Polytechnic, Telangana',
  'Sri   Chaitanya Jr Kalasala Narayanaguda, Telangana',
  'sri  akash junior college, Telangana',
  'Sri  Chaitanya Techno School, Telangana',
  'Sri  Gayatri Educational Institutions, Telangana',
  'Sri Aadarshajunior collage, Telangana',
  'Sri Aakash Junior College, Telangana',
  'sri abidha junior college, Telangana',
  'SRI CHAITANYA EDUCATIONAL INSTITUTIONS, Telangana',
  'Sri Chaitanya Girls Residential College, Telangana',
  'SRI CHAITANYA HIGH SCHOOL, Telangana',
  'Sri chaitanya jr clg rkpuram, Telangana',
  'SRI CHAITANYA JR COLLEGE FOR WOMEN KONDAPUR HYDERABAD, Telangana',
  'SRI CHAITANYA JUNIOR KALASALA, Telangana',
  'SRI CHAITNYA ARTS AND SCIENCE JUNIOR COLLEGE, Telangana',
  'Sri Chaitnya Techno School, Telangana',
  'SRI GAYATHRI EDUCATIONAL INSTITUTIONS, Telangana',
  'SRI GAYATRI JR. COLLEGE, Telangana',
  'SRI MEDHA V JUNIOR COLLEGE, Telangana',
  'SRI RAM JUNIOR COLLEGE , Telangana',
  'sri sai vidya vikas junior college, Telangana',
  'SRI SANJEEVANI JUNIOR COLLEGE, Telangana',
  'SRI SATYA SAI VIDYA VIHAR HIGH SCHOOL, Telangana',
  'SRI VAGDEVI JR COLLEGE, Telangana',
  'SRI VAISHNAVI ABHYAAS JUNIOR COLLEGE, Telangana',
  "St Joseph's Public School, Telangana",
  "ST PATRICK'S JUNIOR COLLEGE, Telangana",
  'st pious junior college, Telangana',
  'ST THOMAS HIGH SCHOOL, Telangana',
  'St. Andrews School, Telangana',
  "St. Ann's junior college, Telangana",
  "ST. JOSEPH'S SCHOOL, Telangana",
  'ST. Martins High school, Telangana',
  'ST.ALPHONUS HIGH SCHOOL NALGONDA, Telangana',
  "St.Ann's High School , Telangana",
  'ST.JOSEPH PUBLIC SCHOOL, Telangana',
  'st.marys junior college , Telangana',
  'St.Patricks Junior College, Telangana',
  'Suchitra Academy International School, Telangana',
  'SUMATHI REDDY JUNIOR COLLEGE FOR GIRLS, Telangana',
  'SUSHEELA JUNIOR COLLEGE MADHIRA, Telangana',
  'suvidyaa junior college, Telangana',
  'SVS SRI CHAITANYA JUNIOR COLLEGE, Telangana',
  'swaminarayan gurukul hyderabad , Telangana',
  'SYNAPSE JUNIOR COLLEGE, Telangana',
  'T.s.w.r.e.I.s.(BOYS) lingala, Telangana',
  'TAPASYA JUNIOR COLLEGE, Telangana',
  'TEJASWI HIGH  SCHOOL, Telangana',
  'TELANGANA MODEL SCHOOL, Telangana',
  'Telangana state of model school , Telangana',
  'THE HYDERABAD PUBLIC SCHOOL RAMANTHAPUR , Telangana',
  'THE NALGONDA PUBLIC SCHOOL, Telangana',
  'The Secunderabad Public School, Telangana',
  'Topper Junior College, Telangana',
  'TRINITY JR COLLEGE KARIMNAGAR, Telangana',
  'Trivdyaa Jr Collage Hyderabad, Telangana',
  'TRIVENI IIT IMPACT TALENT SCHOOL, Telangana',
  'Trividya Junior College, Telangana',
  'Ts Model school , Telangana',
  'TSRS & JR COLLEGE, Telangana',
  'TSSWRESI(G) H. SCHOOL, Telangana',
  'Tswr(iit-medical) academy Gowlidoddi, Telangana',
  'TSWRS/JC(BOS) CHILKUR, Telangana',
  'ttwemrcoe(boys)gandhari, Telangana',
  'TTWR JR. COLLEGE FOR BOYS, Telangana',
  'Urbane Junior College, Telangana',
  'VAGDEVI JUNIOR COLLEGE, Telangana',
  'VARDHAN JUNIOR COLLEGE , Telangana',
  'VASAVI PUBLIC SCHOOL HYDERABAD, Telangana',
  'vectra junior college, Telangana',
  'Vejatha junior college, Telangana',
  'VELOCIITy Junior college, Telangana',
  'vidhya bhavan narsingi hyderabad, Telangana',
  'VIDYA VIKAS JUNIOR COLLEGE BODHAN, Telangana',
  'vidyaranya model school indiranagar ramanthapur hyderabad 500013, Telangana',
  'Vignan Boys Junior College, Telangana',
  'VIGNAN JUNIOR COLLEGE HYDERABAD, Telangana',
  'VIGNAN Vidyalaya, Telangana',
  'vignana bo tree school, Telangana',
  'VIJAYA RATNA JUNIOR COLLEGE , Telangana',
  'Vijayratna junior college, Telangana',
  'VIJETHA JUNIOR COLLEGE, Telangana',
  'Vikas The  Concept School, Telangana',
  'VILLA MARIE JUNIOR COLLEGE FOR GIRLS, Telangana',
  'Vingan Junior College, Telangana',
  'VISHRA JUNIOR KALASALA, Telangana',
  'Vishwashanthi high school, Telangana',
  'Vision 40 Gyanavapi Junior College, Telangana',
  'visra junior kalasala, Telangana',
  'VISWABHARATHI EM SCHOOL , Telangana',
  'vivekananda residential school, Telangana',
  'VVC CENTRAL PUBLIC SCHOOL, Telangana',
  'WPS Warangal, Telangana',
  'Yadadri junior college, Telangana',
  'zee high school, Telangana',
  'CLUNY MAT.HR SEC SCHOOL, Tripura',
  'DON BOSCO SCHOOL , Tripura',
  'Jawahar Navodaya Vidyalaya , Tripura',
  'KENDRIYA AIR FORCE STATION  HAKIMPET, Tripura',
  'Kendriya vidyalaya , Tripura',
  'RAMAKRISHNA MISSION VIDYALAYA, Tripura',
  'Sri Sri Ravishankar Vidya Mandir, Tripura',
  'Academic Global international school, Uttar Pradesh',
  'Adarsh intercollege bhadeora konch, Uttar Pradesh',
  'Adarsh Public School, Uttar Pradesh',
  'Adharsheela Global School, Uttar Pradesh',
  'Agra vanesthali vidyalaya , Uttar Pradesh',
  'AIR FORCE SCHOOL , Uttar Pradesh',
  'ALIGANJ MONTESSORI SCHOOL, Uttar Pradesh',
  'ALIGARH MUSLIM UNIVERSITY SAIYYID HAMID SENIOR SECONDARY SCHOOL (BOYS) , Uttar Pradesh',
  'Aligarh Public School, Uttar Pradesh',
  'ALIGHARH MUSLIM UNIVERSITY, Uttar Pradesh',
  'ALL SAINTS SCHOOL, Uttar Pradesh',
  'Allahabad public school , Uttar Pradesh',
  'Amarnath vidya ashram senior secondary school, Uttar Pradesh',
  'AMBITION CONVENT UJAIR KURMIDIH BALLIA UP, Uttar Pradesh',
  'Amity International School, Uttar Pradesh',
  'Apeejay School Noida, Uttar Pradesh',
  'Archies Higher Secondary School, Uttar Pradesh',
  'ARMY PUBLIC SCHOOL, Uttar Pradesh',
  'Army Public School ,Bareilly, Uttar Pradesh',
  'Awagarh Public School, Uttar Pradesh',
  'AZAD PUBLIC SCHOOL, Uttar Pradesh',
  'Bal Bharati Public School, Uttar Pradesh',
  'Bal Bharti School, Uttar Pradesh',
  'Bal Jyoti senior secondary  School, Uttar Pradesh',
  'Bal niketan balika inter college , Uttar Pradesh',
  'Bal Vidya Mandir Sr sec school, Uttar Pradesh',
  'Baldev vidya pith inter collage, Uttar Pradesh',
  'Bethany Convent School, Uttar Pradesh',
  'BHEL SHIKSHA NIKETAN, Uttar Pradesh',
  'Bight Angels Intermediate College, Uttar Pradesh',
  'Bishop Johnson school and college, Uttar Pradesh',
  'Blue Bird Senior Secondary  School, Uttar Pradesh',
  'BOYS HIGH SCHOOL AND COLLEGE, Uttar Pradesh',
  'BRIGHTLAND SCHOOL, Uttar Pradesh',
  'Brij Bhushan Lal public school, Uttar Pradesh',
  'Buddha inter collage kushinagar, Uttar Pradesh',
  'C.M.S ALIGANJ-1 LUCKNOW, Uttar Pradesh',
  'Cambridge Convent Senior Secondary school, Uttar Pradesh',
  'CAMBRIDGE SCHOOL INDIRAPURAM, Uttar Pradesh',
  'CARMEL SCHOOL, Uttar Pradesh',
  'Cathedral Sr. Sec. School, Uttar Pradesh',
  'CENTRAL ACADEMY, Uttar Pradesh',
  'CENTRAL HINDU BOYS SCHOOL , Uttar Pradesh',
  'CENTRAL PUBLIC SCHOOL, Uttar Pradesh',
  'Chaudhary Charan Singh Memorial Public School, Uttar Pradesh',
  'Children Amry Public School , Uttar Pradesh',
  'Children senior secondary school, Uttar Pradesh',
  "Children's Academy senior secondary school, Uttar Pradesh",
  'CHINMAYA VIDYALAYA, Uttar Pradesh',
  'Chiranjiv bharati school, Uttar Pradesh',
  'Christ the king inter college, Uttar Pradesh',
  'Christu jyoti convent schiol, Uttar Pradesh',
  'CITY CHILDRENS ACADEMY, Uttar Pradesh',
  'City Convent School , Uttar Pradesh',
  'CITY MONTESSORI SCHOOL, Uttar Pradesh',
  "Colonel's Brightland public school, Uttar Pradesh",
  'D C LEWIS MEMORIAL SCHOOL, Uttar Pradesh',
  'D R Public Inter College , Uttar Pradesh',
  'D.P. Public School , Uttar Pradesh',
  'DAV Centenary Public School , Uttar Pradesh',
  'DAYAWATI MODI ACADEMY, Uttar Pradesh',
  'Dayawati Modi public School, Uttar Pradesh',
  'Deep memorial public school, Uttar Pradesh',
  'Deep Public School, Uttar Pradesh',
  'Delhi  Public School , Uttar Pradesh',
  'DIVYABHA I C SHRIPALPUR LALGANJ,BALLIA, Uttar Pradesh',
  'DLF public school , Uttar Pradesh',
  'DR A I MEMO SUNBEAM SCHOOL , Uttar Pradesh',
  'DR BRIJ KISHORI DUBEY MEMORIAL SCHOOL, Uttar Pradesh',
  'DR V S E CENTRE PANKI KANPUR, Uttar Pradesh',
  'Dr Virendra Swarup Public school, Uttar Pradesh',
  'Dr.virendra swaroop education centre, Uttar Pradesh',
  'Ds public school muzaffarnagar, Uttar Pradesh',
  'Emma thompson school, Uttar Pradesh',
  'Escorts world school , Uttar Pradesh',
  'Ess Ess Convent Sr. Sec.School, Uttar Pradesh',
  'Ethel Higginbottom School, Uttar Pradesh',
  'EWNG CHRISTIAN PUBLIC SCHOOL, Uttar Pradesh',
  'FATHER AGNEL SCHOOL, NOIDA, Uttar Pradesh',
  'Fatima sr. Sec. School gonda, Uttar Pradesh',
  'FR. AGNEL SCHOOL, NOIDA, Uttar Pradesh',
  'G. N. NATIONAL PUBLIC SCHOOL, Uttar Pradesh',
  'G.B ISHLAMIYAN INTER COLLAGE KULPAHAR MAHOBA, Uttar Pradesh',
  'Ganga das inter collage, Uttar Pradesh',
  'Gaurav memorial international school, Uttar Pradesh',
  'Gauri Pathshala Inter College, Uttar Pradesh',
  'GD Goenka Public School , Lucknow, Uttar Pradesh',
  'Geeta Sanjay Memorial Public School, Lohia Nagar ,GZB, Uttar Pradesh',
  'Genesis Global School, Uttar Pradesh',
  'Girlâ€™s High School & College , Uttar Pradesh',
  'GIRLS HIGH SCHOOL AND COLLEGE  ALLAHABAD, Uttar Pradesh',
  "Girls' High School and College, Allahabad, Uttar Pradesh",
  'Gn national public school, Uttar Pradesh',
  'GORAKHPUR PUBLIC SCHOOL, Uttar Pradesh',
  'Govt inter college, Uttar Pradesh',
  'Govt Jubilee Inter College, Uttar Pradesh',
  'green field academy, Uttar Pradesh',
  'Green Valley English School, Uttar Pradesh',
  'Greenfields school, Uttar Pradesh',
  'GURU TEG BAHADUR PUBLIC SCHOOL, Uttar Pradesh',
  'Gurukul the school, Uttar Pradesh',
  'Gyan International school, Uttar Pradesh',
  'gyan sthali residential school , Uttar Pradesh',
  'GYANDEEP SHIKSHA BHARATI, Uttar Pradesh',
  'HAPPY HOME ENGLISH SCHOOL, Uttar Pradesh',
  'HAPPY MODEL SCHOOL, Uttar Pradesh',
  'HARIBANSH MALL MEMORIAL PUBLIC SCHOOL, Uttar Pradesh',
  'Harihar Singh Public School, Uttar Pradesh',
  'Harmilap Mission School , Uttar Pradesh',
  'Heera singh public school mathura, Uttar Pradesh',
  'HERITAGE INTERNATIONAL SCHOOL, Uttar Pradesh',
  'Hindu college moradabad, Uttar Pradesh',
  'Holy angels sr. Sec. School , Uttar Pradesh',
  'Holy Cross School Ballia, Uttar Pradesh',
  'HOLY PUBLIC SCHOOL, Uttar Pradesh',
  'HP Inteernational School, Uttar Pradesh',
  'INDIRAPURAM PUBLIC SCHOOL, Uttar Pradesh',
  'Indraprastha global school , Uttar Pradesh',
  'INDUS VALLEY PUBLIC SCHOOL, SECTOR-62, NOIDA, UP, Uttar Pradesh',
  'IPEM International school and college , Uttar Pradesh',
  'J A S INT COLL KHURJA BULANDSHAHR UP, Uttar Pradesh',
  'J.K.G International School, Uttar Pradesh',
  'Jagran Public School, Uttar Pradesh',
  'JAI ACADEMY, Uttar Pradesh',
  'Jamia Urdu Aligarh, Uttar Pradesh',
  'Janta inter college ,Roza-yakubpur,Gautam budh nagar, Uttar Pradesh',
  'JANTA INTER COLLEGE LACHHERA MUZAFFARNAGAR, Uttar Pradesh',
  'JAWAHAR NAVODAYA VIDYALAYA , Uttar Pradesh',
  'JEEVAN JYOTI PUBLIC SCHOOL, Uttar Pradesh',
  'JIM CORBETT PUBLIC SCHOOL, Uttar Pradesh',
  'John Milton Public School, Uttar Pradesh',
  'JP International School, Greater Noida, Uttar Pradesh',
  'Jugal Devi Saraswati Vidhya Mandir, Kanpur, Uttar Pradesh',
  'K B INTER COLLEGE PAYAGPUR BAHRAICH, Uttar Pradesh',
  'K D PUBLIC INTERMEDIATE COLLEGE, Uttar Pradesh',
  'K.L. INTERNATIONAL SCHOOL, Uttar Pradesh',
  'Kanha Makhan Public School, Uttar Pradesh',
  'Karan public school, Uttar Pradesh',
  'karl huber school, Uttar Pradesh',
  'KAUSHALYA WORLD SCHOOL, Uttar Pradesh',
  'KD PUB SCH NEEM GAON RD AYRA KHER MATHURA UP, Uttar Pradesh',
  'KENDRIYA VIDAYALAYA , Uttar Pradesh',
  'Leelawati senior secondary School Pratap vihar, Uttar Pradesh',
  'Lions School NTPC Shaktinagar, Uttar Pradesh',
  'Little flower House kakarmata Dlw, Uttar Pradesh',
  'Little Flower School, Uttar Pradesh',
  'Loreto Convent Inter College, Uttar Pradesh',
  'LOTUS VALLEY INT SCHOOL, Uttar Pradesh',
  'LTR PUBLIC SCHOOL KURALI MEERUT, Uttar Pradesh',
  'Lucknow Public School, Uttar Pradesh',
  'M G Convent School, Uttar Pradesh',
  'Madhavrao Scindia Public School, Uttar Pradesh',
  'Madhu Vachaspati Inter College, Uttar Pradesh',
  'MAHA RANI LAXMI BAI MEMORIAL INTER COLLEGE, Uttar Pradesh',
  'MAHAMAYA VR PUBLIC SCH LALPUR ROAD BLY UP, Uttar Pradesh',
  'Maharaji devi smarak inter college, Uttar Pradesh',
  'MAHARANA PRATAP EDUCATION CENTRE, Uttar Pradesh',
  'Maharishi Vidya Mandir, Uttar Pradesh',
  'Maharshi Patanjali Vidya Mandir, Uttar Pradesh',
  'Mahatma Gandhi Senior Secondary School , Uttar Pradesh',
  'Mahatma Hansraj Modern School, Uttar Pradesh',
  'Manav Rachna International School, noida, Uttar Pradesh',
  "MARY GARDINER'S CONVENT SCHOOL, Uttar Pradesh",
  'MEERUT PUBLIC GIRLS SCHOOL, Uttar Pradesh',
  'METHODIST HIGH SCHOOL, Uttar Pradesh',
  'Metropolitan School, Uttar Pradesh',
  'Miet public school , Uttar Pradesh',
  'MILTON PUBLIC SCHOOL, Uttar Pradesh',
  'MODERN ACADEMY INTER COLLEGE, Uttar Pradesh',
  'MODERN PUBLIC SCHOOL MEERUT, Uttar Pradesh',
  'Modern School, Uttar Pradesh',
  'Montfort Inter College, Uttar Pradesh',
  'Mother Teresa Mission Higher Secondary School, Uttar Pradesh',
  'MTS public school lalapur jigna Mirzapur, Uttar Pradesh',
  'mumtaz modern sr src school, Uttar Pradesh',
  'MV CONV INTER COLLEGE ALLAHABAD UP, Uttar Pradesh',
  'MVM PUBLIC SCHOOL, Uttar Pradesh',
  'Naga ji S V M Inter college jeerabasti ballia , Uttar Pradesh',
  'National Institute of Open Schooling, Uttar Pradesh',
  'NEHRU WORLD SCHOOL, Uttar Pradesh',
  'New Era Public School, Uttar Pradesh',
  'New Lancers convent senior secondary school, Uttar Pradesh',
  'Nirmala Convent Sr. Sec. school, Uttar Pradesh',
  'Oxford Model Senior Secondary school, Uttar Pradesh',
  'Oxford Public School , Uttar Pradesh',
  'P KHOOBI RAM PUBLIC SCHOOL, Uttar Pradesh',
  'P.G Senior Secondary School, Uttar Pradesh',
  'Parvati Radhakishan Fomra School, Uttar Pradesh',
  'PAUL INTERNATIONAL SCHOOL, Uttar Pradesh',
  'Police Modern School, Uttar Pradesh',
  'Prabhat Manohar Lal Vimla Devi laharpur Sitapur, Uttar Pradesh',
  'Pragyan School , Uttar Pradesh',
  'Praxis Vidya Peeth shiva colony basti, Uttar Pradesh',
  'PRELUDE PUBLIC SCHOOL, Uttar Pradesh',
  'Presidium School Indirapuram, Uttar Pradesh',
  'Priyanka Modern senior secondary School , Uttar Pradesh',
  'PT H S R L INDIAN PUB SCH, Uttar Pradesh',
  'Pt Jawahar Lal Nehru inter collage helepara, Uttar Pradesh',
  'PT M M M INTER COLLEGE SIKHARI GHAZIPUR, Uttar Pradesh',
  'R M S G PUBLIC SCHOOL, Uttar Pradesh',
  'R N INTER COLLEGE ATRAMPUR ALLAHABAD, Uttar Pradesh',
  'R P M ACADEMY CIVIL LINES GORAKHPUR, Uttar Pradesh',
  'R S K inter college, Uttar Pradesh',
  'R.k modern se. SEc. School, Uttar Pradesh',
  'R.N.S. World School, Uttar Pradesh',
  'R.P.M Academy, Uttar Pradesh',
  'Radiant Academy School, Uttar Pradesh',
  'RAGENDRA SWARUP PUBLIC SCHOOL , Uttar Pradesh',
  'Raj English School Varanasi, Uttar Pradesh',
  'Rajghat besant school , Uttar Pradesh',
  'Ralli International School, Uttar Pradesh',
  'RAM KISHAN INSTITUTE , Uttar Pradesh',
  'Ram Lakhan NK Inter college , Uttar Pradesh',
  'Ramagya School, Uttar Pradesh',
  'Rani  avanti bai inter college awagarh rod eta, Uttar Pradesh',
  'Rani laxmi bai memorial school, Uttar Pradesh',
  'Rapid Global School, Uttar Pradesh',
  'Ratna Memorial Public School, Uttar Pradesh',
  'Regional College Bareilly, Uttar Pradesh',
  'Renaissance Academy, Uttar Pradesh',
  'RNS world school ,jhansi, Uttar Pradesh',
  'Royal Highs Public School , Uttar Pradesh',
  'RS Convent Sainik School, Uttar Pradesh',
  'RYAN INTERNATIONAL SCHOOL, Uttar Pradesh',
  'Sacred Heart Academy, Uttar Pradesh',
  'SAINT ANGELS PUBLIC SCHOOL, Uttar Pradesh',
  'Saint Ann College , Uttar Pradesh',
  'Saint Dominic Savio English Medium School, Uttar Pradesh',
  'Saint Johns Academy, Uttar Pradesh',
  'Saint Joseph college , Uttar Pradesh',
  'Saint vivekanand sr sec public school , Uttar Pradesh',
  'SANJAY GANDHI INTER COLLEGE , Uttar Pradesh',
  'Sanskaar International School, Uttar Pradesh',
  'Sant atulanand convent school, Uttar Pradesh',
  'Sant Keenaram Public School , Uttar Pradesh',
  'SARALA CHOPRA DAV CEN PUB SCH , Uttar Pradesh',
  'Saraswati Bal Mandir sr. sec. School, Uttar Pradesh',
  'Saraswati vidya mandir, Uttar Pradesh',
  'Sardar Patel Inter College Kolna, Uttar Pradesh',
  'SARLA CHOPRA DAV CEN PUB SCH NOIDA, Uttar Pradesh',
  'SETH ANANDRAM JAIPURIA SCHOOL, Uttar Pradesh',
  'Seth M. R. Jaipuria School, Uttar Pradesh',
  'Seventh Day Adventist inter college bahraich, Uttar Pradesh',
  'SHAH FAIZ PUBLIC SCHOOL , Uttar Pradesh',
  'Shaiffali Public School, Uttar Pradesh',
  'Shambhu Dayal public school, Uttar Pradesh',
  'Shanti niketan public school, Uttar Pradesh',
  'SHARDEIN SCHOOL, Uttar Pradesh',
  'SHEILING HOUSE SCHOOL, Uttar Pradesh',
  'SHIRDI SAI PUBLIC SCHOOL, Uttar Pradesh',
  'Shiv Nadar School, Uttar Pradesh',
  'SHIVOY SCHOOL, Uttar Pradesh',
  'Shree Sanatan Dharam Education Centre, Uttar Pradesh',
  'SHRI BAJRANG INTER COLLEGE NAGLA SALEM HATHRAS , Uttar Pradesh',
  'Shri chandrabhanu I c MASURIYAPUR NAINIJOR AZAMGARH, Uttar Pradesh',
  'SHRI GAYATRI MAHASHAKTI SHIKSHAN SANSTHAN INTER COLLEGE BANDA SHAHJAHANPUR, Uttar Pradesh',
  'Shri Gulab Rai Montessori Sr sec school bareilly, Uttar Pradesh',
  'Shri R.S.Public School,Bainpur road, Sikandra,Agra, Uttar Pradesh',
  'Shri Ram Public School, Uttar Pradesh',
  'Shri Shankar Inter College, Nanpara, Bahraich, U.P., Uttar Pradesh',
  'Shri Varni jain Inter college, Uttar Pradesh',
  'SHRI VINAYAK ACAD INT SCH ORAI JALAUN UP, Uttar Pradesh',
  'Shri VirVikram Singh IC Nawada Param Fir , Uttar Pradesh',
  'shriji baba saraswati vidya mandir, Uttar Pradesh',
  'Shrimati sridevi awasiya vidyapeeth, Uttar Pradesh',
  'SIC umari begamganj gonda, Uttar Pradesh',
  'Siddharth public school, Uttar Pradesh',
  'SILVER BELLS PUBLIC SCHOOL, Uttar Pradesh',
  'Sir Syed Hamid Sr. Sec School Boys., Uttar Pradesh',
  'SJS PUBLIC SCHOOL GAURIGANJ, Uttar Pradesh',
  'SKD Academy, Uttar Pradesh',
  'Skd academy ISC Board, Uttar Pradesh',
  'Somerville School Noida, Uttar Pradesh',
  'SOPHIA GIRLS SCHOOL, Uttar Pradesh',
  'Spring Dale College , Uttar Pradesh',
  'SPRINGDALES CHILDREN SCHOOL, Uttar Pradesh',
  'SR International School Bareilly, Uttar Pradesh',
  'SRI RAGHUKUL VIDYA PEETH, Uttar Pradesh',
  "ST BASIL'S SCHOOL, Uttar Pradesh",
  'St Dominic Savio Lalitpur, Uttar Pradesh',
  'ST FIDELIS COLLEGE, Uttar Pradesh',
  'St Francis Convent schoo , Uttar Pradesh',
  'St johns school varanasi, Uttar Pradesh',
  'ST JOSEPH ENG MED SR SEC SCHOOL , Uttar Pradesh',
  'St Joseph school, Uttar Pradesh',
  "ST JUDE'S COLLEGE, Uttar Pradesh",
  'St Pauls school, Uttar Pradesh',
  'st tulsi global school ,azamgarh, Uttar Pradesh',
  "St Xavier's High school , Uttar Pradesh",
  "St Xavier's sr sec school, Uttar Pradesh",
  'St.  Andrews public School, Uttar Pradesh',
  'St. Conrads Inter College, Uttar Pradesh',
  'ST. FRANCIS INTER COLLEGE, Uttar Pradesh',
  'St. Johns school, Uttar Pradesh',
  "St. Mary's Convent Inter College , Uttar Pradesh",
  'St. Marys Academy, Uttar Pradesh',
  "ST. MICHAEL'S CONVENT SCHOOL, Uttar Pradesh",
  "St. Paul's College, Uttar Pradesh",
  'St.Clares Senior Sec. School, Uttar Pradesh',
  "St.Conrad's inter college, Uttar Pradesh",
  "St.Francis' College , Lucknow., Uttar Pradesh",
  "St.Joesph's School,Khorabar,Gorakhpur, Uttar Pradesh",
  "St.John's Higher Secondary School, Mattom, Mavelikkara, Uttar Pradesh",
  'ST.JOHNS SR SEC SCH GOLA GOKHARANNATH KHERI UP, Uttar Pradesh',
  'Stella maris convent school, Uttar Pradesh',
  'STELLA MARIS INTER COLLEGE, Uttar Pradesh',
  'Stepping Stones Intermediate College, Uttar Pradesh',
  'Study hall, Uttar Pradesh',
  'Study hall school, Uttar Pradesh',
  'suditi global academy mainpuri, Uttar Pradesh',
  'Sumeet Rahul goel memorial senior secondary School Agra, Uttar Pradesh',
  'Sumitra Modern School Sitapur, Uttar Pradesh',
  'Sun International School, Uttar Pradesh',
  'Sun Valley Public School, Varanasi, Uttar Pradesh',
  'SUNBAM ACADEMY, Uttar Pradesh',
  'SUNMEAM ENGLISH SCHOOL BHAGWANPUR  VARANASI, Uttar Pradesh',
  'SUNWAY SCHOOL, Uttar Pradesh',
  'SURAJ GYAN MODERN PUBLIC SCHOOL KONCH UP, Uttar Pradesh',
  'Surmount international school, Uttar Pradesh',
  'SVM SULTANPUR, Uttar Pradesh',
  'Swami Harsewanand Public School, Uttar Pradesh',
  'Swami vivekanand vidyapeeth, Uttar Pradesh',
  'Swaraj india public school, Uttar Pradesh',
  'Syadwad jain academy, Uttar Pradesh',
  'The Adhyyan School, Uttar Pradesh',
  'The Avadh School, Uttar Pradesh',
  'The Chintels School, Uttar Pradesh',
  'THE INDIAN PUBLIC GOMTI NAGAR LUCKNOW, Uttar Pradesh',
  'The institute of open school, Uttar Pradesh',
  'The Jain International School, Uttar Pradesh',
  'The Khaitan School, Noida, Uttar Pradesh',
  'THE MILLENNIUM SCHOOL LUCKNOW, Uttar Pradesh',
  'The Modern School, Uttar Pradesh',
  'The skyland school, Uttar Pradesh',
  'Tilak Dhari Singh intermediate college, Uttar Pradesh',
  'Tomar children school, Uttar Pradesh',
  'Tulsi Vidya Niketan, Uttar Pradesh',
  'U. P. Kirana Seva Samiti Vidyalaya, Uttar Pradesh',
  'Udaya Public School, Uttar Pradesh',
  'Umanath singh higher secondary school,maharupur,shankarganj,jaunpur, Uttar Pradesh',
  'UNITED PUBLIC SCHOOL, Uttar Pradesh',
  'universal public school, Uttar Pradesh',
  'up public school deoria, Uttar Pradesh',
  'V ST Joseph Sr Sec School, Uttar Pradesh',
  'VANASTHALI public school, Uttar Pradesh',
  'Vanasthali public school  baraut, Uttar Pradesh',
  'Varanasi Public School, Uttar Pradesh',
  'Vedantic international school, Uttar Pradesh',
  'Vidya bhavan public school, Uttar Pradesh',
  'Vidya sagar school, Uttar Pradesh',
  'VidyaGyan School, Uttar Pradesh',
  'Vidyawati nigam memorial public school, Uttar Pradesh',
  'Vigyan sanjivani central college, Uttar Pradesh',
  'VIKAS INTERMEDIATE COLLEGE PARAMANANDPUR VARANASI, Uttar Pradesh',
  'vimla singh smarak inter college, Uttar Pradesh',
  'Vishnu Bhagwan Public School, Uttar Pradesh',
  'Vishwa Bharati Public School NOIDA, Uttar Pradesh',
  'WISDOM PUBLIC SCHOOL KWARSI RAM GHAT ROAD ALIGARH UTTAR PRADESH, Uttar Pradesh',
  'WOODLAND ACADEMY, Uttar Pradesh',
  'YMCA Centenary School and College, Uttar Pradesh',
  'youhe public school, Uttar Pradesh',
  'AMENITY PUBLIC SCHOOL , Uttarakhand',
  'ANN MARY SCHOOL, Uttarakhand',
  'ARMY PUBLIC SCHOOL , Uttarakhand',
  'ARYAMAN VIKRAM BIRLA INSTITUTE OF LEARNING, Uttarakhand',
  'B. R. New Modern School, Uttarakhand',
  'BRIGHTLANDS, Uttarakhand',
  'CAMPUS SCHOOL , Uttarakhand',
  'CANTERBURRY BELLS SCHOOL, Uttarakhand',
  'CITY CONVENT SCH , Uttarakhand',
  'D.S.B INT PUB SCHOOL, Uttarakhand',
  'DAV Centenary Public School , Uttarakhand',
  'DAV PUBLIC SCHOOL, Uttarakhand',
  'DELHI PUBLIC SCHOOL, Uttarakhand',
  'DOON INTERNATIONAL SCHOOL , Uttarakhand',
  'doon public school, Uttarakhand',
  'DSB INTERNATIONAL PUBLIC SCHOOL, Uttarakhand',
  'gayatri vidhyapeeth, Uttarakhand',
  'Goverment inter college , Uttarakhand',
  'Himalayan progressive school, Uttarakhand',
  'Inspiration public school, Uttarakhand',
  'JAMDAGNI PUBLIC SCHOOL, Uttarakhand',
  'Jawahar Navodaya Vidyalaya, Uttarakhand',
  'Jaycees Public School, Uttarakhand',
  'Kendriya Vidhyalaya, Uttarakhand',
  'MARIA ASSUMPTA CONVENT SCHOOL, Uttarakhand',
  'Montfort Senior Secondary School , Uttarakhand',
  'Priyanka Modern senior secondary School  , Uttarakhand',
  'R A N PUBLIC SCHOOL, Uttarakhand',
  'Raja rammohan roy academy, Uttarakhand',
  'RASHTRIYA INDIAN MILITARY COLLEGE, Uttarakhand',
  'S.S.Public School, Uttarakhand',
  'Sai public school, Uttarakhand',
  'SCHOLARS HOME SR SEC SCHOOL, Uttarakhand',
  'SelaQui International School, Uttarakhand',
  'Sharda Public School, Uttarakhand',
  'Shemford Futuristic School, Uttarakhand',
  'Shivedale school, Uttarakhand',
  'Shri Ram Centennial School, Uttarakhand',
  'St Joseph Academy, Uttarakhand',
  'St Joseph college, Uttarakhand',
  "St MARK'S ACADEMY , Uttarakhand",
  'ST THERESAS SCHOOL , Uttarakhand',
  'St. Francis School, Uttarakhand',
  'St. Joseph college, Uttarakhand',
  "St. Mary's school, Uttarakhand",
  "ST. MARY'S SENIOR SECONDARY SCHOOL, Uttarakhand",
  'Templeton Academy International School , Uttarakhand',
  'The Asian School, Uttarakhand',
  'The Doon Global School, Uttarakhand',
  'Time World School, Uttarakhand',
  "Tula's International School, Uttarakhand",
  'Tularam Rajaram Saraswati vidya mandir Inter college, Uttarakhand',
  'VENDY PUBLIC SCHOOL, Uttarakhand',
  "welham boy's school, Uttarakhand",
  'Abhinav bharati high school , West Bengal',
  'ADITYA ACADEMY SENIOR SECONDARY, West Bengal',
  'Amrita Vidyalayam, West Bengal',
  'ANDHARIA RAJ BALLAV HIGH SCHOOL , West Bengal',
  'Apeejay School, West Bengal',
  'ARIADAHA KALACHAND HIGH SCHOOL, West Bengal',
  'ARMY PUBLIC school , West Bengal',
  'Ashok Hall Girls Higher Secondary School , West Bengal',
  'ASIAN INTERNATIONAL SCHOOL, West Bengal',
  'ASSEMBLY OF GOD CHURCH SCHOOL, West Bengal',
  'Auxilium Convent School, West Bengal',
  'Barasat Indira Gandhi Memorial Highschool, West Bengal',
  'barasat pcs govt high school, West Bengal',
  'Barrackpore Debiprasad High School , West Bengal',
  'BARRACKPORE GOVT. HIGH SCHOOL, West Bengal',
  'BHARATIYA VIDYA BHAVAN , West Bengal',
  "Bhavan's Gangabux Kanoria Vidyamandir, West Bengal",
  "BHAVAN'S N S C B VIDYA NIKETAN, West Bengal",
  'BIDHAN CHANDRA INSTITUTION, West Bengal',
  'BIRLA BHARTI SCHOOL, West Bengal',
  'Bodhicariya Sr. Sc. school, West Bengal',
  'Burdwan Municipal High School, West Bengal',
  'Burdwan Town school, West Bengal',
  'BURNPUR RIVERSIDE SCHOOL, West Bengal',
  'CALCUTTA PUBLIC SCHOOL , West Bengal',
  'CENTRAL MODEL SCHOOL, West Bengal',
  'CHAKDWIPA HIGH SCHOL, West Bengal',
  'CHANDERNAGORE KANAILAL , West Bengal',
  'college for higher education, West Bengal',
  'Contains Public School, West Bengal',
  'DAHIJURI MAHATMA VIDYAPITH, West Bengal',
  'DANTON H.S MULTIPURPOSE  SCHOOL, West Bengal',
  'DAV School, West Bengal',
  'Dayerbazar vidyamandir, West Bengal',
  'Debijhora high school , West Bengal',
  'DELHI PUBLIC SCHOOL , West Bengal',
  'DISARI PUBLIC SCHOOL, West Bengal',
  'Don Bosco School, West Bengal',
  'Douglas Memorial Higher Secondary School, West Bengal',
  'DPS Megacity , West Bengal',
  'DPS Ruby Park, West Bengal',
  'East West Model School, West Bengal',
  'Future Campus School, West Bengal',
  'G D Birla Centre For Education, West Bengal',
  'G d Goenka public school, West Bengal',
  'G.B. Memorial Institution, West Bengal',
  'Garden High School, West Bengal',
  'Green Point Academy, West Bengal',
  'GURU TEG BAHADUR PUBLIC SCHOOL, West Bengal',
  'Hariyana Vidya Mandir, West Bengal',
  'HEM SHEELA MODEL SCHOOL, West Bengal',
  'Himalayan International Residential School, West Bengal',
  'Holy Child English Academy , West Bengal',
  'holy garden model school , West Bengal',
  'IDEAL PUBLIC SCHOOL, West Bengal',
  'India International School , West Bengal',
  'INDIRA GANDHI MEMORIAL HIGH SCHOOL, West Bengal',
  'Indus Valley World School, West Bengal',
  'Jahalda High School, West Bengal',
  'Jawahar navodaya vidyalaya, West Bengal',
  'Kalyani Central Model School, West Bengal',
  'Kalyani Public School, West Bengal',
  'kamal jyoti vidhyapeeth, West Bengal',
  'KENDRIYA VIDYALAYA , West Bengal',
  'khantura ritilata shiksha niketan (boys), West Bengal',
  'Kharagpur Silver Jubilee High School, West Bengal',
  'KRISHNAGANJ KRISHI SILPA VIDYALAYA, West Bengal',
  'KRISHNAGAR COLLEGIATE SCHOOL, West Bengal',
  'LAKE SCHOOL FOR GIRLS, West Bengal',
  'LMET INTERNATIONAL SCHOOL, West Bengal',
  'M.P. Birla Foundation Higher Secondary School, West Bengal',
  'MAHADEVI BIRLA WORLD ACADEMY, West Bengal',
  'Mahesh Sri Ramkrishna ashram Vidyalaya, West Bengal',
  'Mahisadal Raj High School, West Bengal',
  'MALDA RAILWAY HIGH SCHOOL, West Bengal',
  'Manisha International School, West Bengal',
  'MDB DAV PUBLIC SCHOOL, West Bengal',
  'Memari Crystal Model School, West Bengal',
  'Methodist School, West Bengal',
  'Modella Caretaker Centre & School, West Bengal',
  'Mordern High School For Girls, West Bengal',
  'Nati onal Gems Higher Secondary School , West Bengal',
  'National English school , West Bengal',
  'Nava Nalanda High School, West Bengal',
  'Nayaput Sudhir Kumar High School, West Bengal',
  'New Barrackpur Colony Boys High School, West Bengal',
  'Nimta High School, West Bengal',
  'PAILAN WORLD SCHOOL, West Bengal',
  'Panchberia Ramchandra Smriti Siksha Mandir, West Bengal',
  'PATHFINDER HIGHER SECONDARY PUBLIC SCHOOL, West Bengal',
  'POlKAJI SIKSHA SANSTHAN SIKAR, West Bengal',
  'R.B.B HIGH (H.S) SCHOOL, West Bengal',
  'RABINDRANATH TAGORE GIRLS HIGH SCHOOL, West Bengal',
  'Railway Higher Secondary School, West Bengal',
  'Rajballavpur High School, West Bengal',
  "Rajkumari Santonamoyee Girls' School, West Bengal",
  'Rathipur Barda Banipith H.S, West Bengal',
  'Rishi Aurobindoh Memorial Academy, West Bengal',
  'Rockvale Academy, West Bengal',
  'Sacred heart school, West Bengal',
  'Salt Lake Point School, West Bengal',
  'Salt lake school, West Bengal',
  'SE RLY MIXED SR. SECONDARY SCHOOL, West Bengal',
  'seventh day adventist senior secondary school, West Bengal',
  'Shaw public school, West Bengal',
  'shree jain vidyalaya, West Bengal',
  'SHRI SHIKSHAYATAN SCHOOL, West Bengal',
  'SHYAMNAGAR KANTI CHANDRA HIGH SCHOOL, West Bengal',
  'Siliguri Baradakanta Vidyapith, West Bengal',
  'SOUTH POINT HIGH SCHOOL, West Bengal',
  'Springdale high school, West Bengal',
  'Sri Aurobindo Institute of Education, West Bengal',
  'SRI AUROVINDO INSTITUTION OF EDUCATION, West Bengal',
  'SRI SRI ACADEMY, West Bengal',
  'SS PUBLIC SCHOOL, West Bengal',
  'St Augustine Day School, West Bengal',
  "St joan's school, West Bengal",
  "St Joseph & Mary's School, West Bengal",
  "St Joseph's college, West Bengal",
  "ST JOSEPH'S CONVENT, West Bengal",
  'ST PAULS ACADEMY, West Bengal',
  'St Xaviers Institution, West Bengal',
  "St. Augustine's Day School, West Bengal",
  'ST. CLARET SCHOOL, West Bengal',
  'St. Francis Xavier School, West Bengal',
  "St. Patrick's Higher Secondary School, West Bengal",
  "ST. XAVIER'S COLLEGIATE SCHOOL, West Bengal",
  "St.augustine's School, West Bengal",
  'SUDHIR MEMORIAL INSTITUTE, West Bengal',
  "Sushila Birla Girls' School, West Bengal",
  'Tarakeswar High School, West Bengal',
  'TECHNO INDIA GROUP PUBLIC SCHOOL, West Bengal',
  'THE ARYANS SCHOOL, West Bengal',
  'THE ASSEMBLY OF GOD CHURCH SCHOOL, West Bengal',
  'The Heritage School, West Bengal',
  'U.P.PUBLIC SCHOOL , West Bengal',
  'Uchalan High School, West Bengal',
  'Vidyasagar Vidyapith, West Bengal',
  'welland gouldsmith school, West Bengal',
  'Zilla public school, West Bengal',
  'Alpha Wisdom Vidyashram, Andaman and Nicobar Islands',
  'CARMEL SENIOR SECONDARY SCHOOL, Andaman and Nicobar Islands',
  'CITADEL RESIDENTIAL SCHOOL, Andaman and Nicobar Islands',
  'Delhi Public School , Andaman and Nicobar Islands',
  'Government Model Senior Secondary School, Andaman and Nicobar Islands',
  'Govt Sr Sec School , Andaman and Nicobar Islands',
  'Govt. senior secondary school school line, Andaman and Nicobar Islands',
  'Kamaraj English Medium School, Andaman and Nicobar Islands',
  'KENDRIYA VIDYALAYA , Andaman and Nicobar Islands',
  'NAVY CHILDREN SCHOOL, Andaman and Nicobar Islands',
  'RVSM HSS PRAYAR, Andaman and Nicobar Islands',
  'Senior secondary school, Andaman and Nicobar Islands',
  'SRI CHAITANYA JR COLLEGE, Andaman and Nicobar Islands',
  'St.mary school, Andaman and Nicobar Islands',
  'Vivekananda Kendra Vidyalaya , Andaman and Nicobar Islands',
  'Bal Niketan Model Sr. Secondary School, Chandigarh',
  'Banyan Tree School, Chandigarh',
  'Bharatiya Vidya Bhavan , Chandigarh',
  'DELHI PUBLIC SCHOOL, Chandigarh',
  'Government Model Senior Secondary School , Chandigarh',
  'Guru Nanak Public School, Chandigarh',
  'kaliachak high school , Chandigarh',
  'KENDRIYA VIAYALAYA , Chandigarh',
  'Mount Carmel School, Chandigarh',
  'Ryan International School, Chandigarh',
  'Sacred HEarts Senior Secondary School, Chandigarh',
  'SGGS Collegiate Public School , Chandigarh',
  'SHISHU NIKETAN, Chandigarh',
  'SRI GURU GOBIND SINGH SN. SEC. SCHOOL, Chandigarh',
  'Sri Guru Harkrishan School, Chandigarh',
  'St. Johns High School, Chandigarh',
  "St. Peter's Senior Secondary School, Chandigarh",
  'Stepping Stones Sr Secondary High School, Chandigarh',
  'THE BRITISH SCHOOL, Chandigarh',
  'THE NEW PUBLIC SCHOOL, Chandigarh',
  'VIVEK HIGH SCHOOL, Chandigarh',
  'Prabhat Scholars Academy, Dadra and Nagar Haveli',
  'shivprakash memorial school, Dadra and Nagar Haveli',
  'AAKASH PUBLIC SCHOOL, Delhi',
  'aaryan international school, Delhi',
  'ABHINAV PUBLIC SCHOOL, Delhi',
  'Abu Dhabi Indian School , Delhi',
  'ADARSH JAIN DHARMIC SHIKSHA SADAN, Delhi',
  'Adarsh Vidya Bhawan Public school , Delhi',
  'AGDAV, Centanary Public School, Delhi',
  'AHLCON INTERNATIONAL SCHOOL, Delhi',
  'AIR FORCE GOLDEN JUBILEE INSTITUTE, Delhi',
  'AIRPORT SENIOR SECONDARY SCHOOL , Delhi',
  'AL AMEER ENGLISH SCHOOL , Delhi',
  'Al-Aliya International Indian School, Delhi',
  'Amity International School, Delhi',
  'AMRITA VIDYALAYAM , Delhi',
  'Apeejay School , Delhi',
  'ARMY PUBLIC SCHOOL, Delhi',
  'Arwachin Bharti bhawan sr sec school, Delhi',
  'ARWACHIN INTERNATIONAL SCHOOL, Delhi',
  'Aryan international school delhi, Delhi',
  'Asian Christian High School, Delhi',
  'ASIAN INTERNATIONAL PVT SCHOOL, Delhi',
  'ASN SENIOR SECONDARY SCHOOL, Delhi',
  'B.G.S INTERNATIONAL PUBLIC SCHOOL, Delhi',
  'BAL BHARATI PUB SCHOOL, Delhi',
  'BAL BHAWAN PUBLIC SCHOOL , Delhi',
  'BAL MANDIR,SR.SEC. SCHOOL, Delhi',
  'BANASTHALI PUBLIC SCHOOL, Delhi',
  'Basava International School, Delhi',
  'Bhai Parmanand Vidya Mandir, Delhi',
  'Bharti Public School, Delhi',
  "Bhartiya Vidya Bhawan's Mehta Vidyalaya, Delhi",
  'BHATNAGAR INTERNATIONAL SCHOOL =, Delhi',
  'Bhrama shakti, Delhi',
  'BIRLA PUBLIC SCHOOL, Delhi',
  'Birla vidya niketan, Delhi',
  'Bloom Public School, Delhi',
  'Bluebells School International, Delhi',
  'Brahma Shakti Public School, Delhi',
  'BSM PUBLIC SCHOOL, Delhi',
  'C R Oasis Convent Senior Secondary School, Delhi',
  'CAMBRIDGE SCHOOL, Delhi',
  'CENTRAL SCHOOL AGCR COLONY DELHI, Delhi',
  'chandra shekher azad govt boys sr sec school, Delhi',
  'CHINTHALAYA VIDYALAYAM, Delhi',
  'CONVENT OF GAGAN BHARTI, Delhi',
  'Convent Of Jesus and Mary , Delhi',
  'CRESCENT PUBLIC SCHOOL, Delhi',
  'CRPF PUBLIC SCHOOL , Delhi',
  'D A V PUBLIC SCHOOL , Delhi',
  'Darshan Academy, Delhi',
  'DAVID MODEL SENIOR SECONDARY SCHOOL, Delhi',
  'DEEN BANDHU PUBLIC SCHOOL, Delhi',
  'Delhi International School, Delhi',
  'DELHI JAIN PUBLIC SCHOOL, Delhi',
  'DELHI PUBLIC SCHOOL, Delhi',
  'Dhruva Public School, Delhi',
  'Don Bosco School, Delhi',
  'Doon public school, Delhi',
  'DPS VASANT KUNJ, Delhi',
  'DTEA SR SEC SCHOOL, Delhi',
  'EAST POINT SCHOOL , Delhi',
  'Emirates Future International Academy, Delhi',
  'FAHAHEEL AL WATANIEH INDIAN PRIVATE SCHOOL, Delhi',
  'FAITH ACADEMY, Delhi',
  'FATHER AGNEL SCHOOL, Delhi',
  'G d Goenka public school, Delhi',
  'G.L.T Saraswati Bal Mandir Sr.Secondary School, Delhi',
  'Ganga international school, Delhi',
  'GD Goenka  School, Delhi',
  'GD GOENKA PUBLIC SCHOOL, Delhi',
  'GD SALWAN PUBLIC SCHOOL, Delhi',
  'GEMS Our Own English High School, Delhi',
  'GLT saraswati bal mandir , Delhi',
  'Good Shepherd Public school and junior college, Delhi',
  'Government Boys Senior Secondary School, Delhi',
  'Govt Sarvodaya , Delhi',
  'Green View Public School, Delhi',
  'GREENFIELDS PUBLIC SCHOOL, Delhi',
  'Greenway Modern School, Delhi',
  'Guru Harkrishan Public School, Delhi',
  'Gurugram Public School, Delhi',
  'Gyan Bharati School , Delhi',
  'GYAN DEVI SALWAN PUBLIC SCHOOL, Delhi',
  'Hans Raj Model School , Delhi',
  'Heeralal Public School , Delhi',
  'HOLY CHILD AUXILIUM SCHOOL, Delhi',
  'Hope Hall Foundation School, Delhi',
  'INDIAN CENTRAL SCHOOL, Delhi',
  'INDIAN SCHOOL , Delhi',
  'Indian School Ajman, Delhi',
  'Indraprastha International School, Delhi',
  'International Indian School , Delhi',
  'JASPAL KAUR PUBLIC SCHOOL, Delhi',
  'K R MANGALAM WORLD SCHOOL, Delhi',
  'KENDRIYA VIDAYALAY , Delhi',
  'KERALA EDUCATION SOCIETY SEC SCHOOL, Delhi',
  'KIIT WORLD SCHOOL, Delhi',
  'Krishna International school, Delhi',
  'Kulachi Hansraj Model School , Delhi',
  'LAL BAHADUR SHASTRI SR.SEC SCHOOL, Delhi',
  "Lancer's Convent, Delhi",
  'Laxman Public school , Delhi',
  'LB Shastri School, Delhi',
  'Little Flower public senior secondary school, Delhi',
  'Lt. Col. Mehar Little Angels Sr. Sec. School, Delhi',
  'M M Public School, Delhi',
  'Maharaja aggarsain Adarsh public school , Delhi',
  'Mamta Modern Sr. Sec. School, Delhi',
  'Manav Sthali School, Delhi',
  'Manava Bharati India International School, Delhi',
  'Marigold Public school, Delhi',
  'MATA JAI KAUR PUBLIC SCHOOL, Delhi',
  'maxfort school , Delhi',
  'Mayo International School, Delhi',
  'Mayoor Private School , Delhi',
  'Midfield sr sec school, Delhi',
  'Modern Convent School , Delhi',
  'Modern School, Delhi',
  'MOTHER DIVINE PUBLIC SCHOOL, Delhi',
  'MOTHER KHAZANI CONVENT SCHOOL, Delhi',
  "MOTHER MARY'S SCHOOL, Delhi",
  "Mother's Global School, Delhi",
  'Mount Carmel School, Delhi',
  "Mount St. Mary's School, Delhi",
  'N C JINDAL PUBLIC SCHOOL, Delhi',
  'Naipunnya Public School , Delhi',
  'National Public School, Delhi',
  'National victor public school, Delhi',
  'NAVY CHILDREN SCHOOL, Delhi',
  'Navyug Convent Senior Secondary School, Delhi',
  'New Delhi Public School, Delhi',
  'New Era Public School, Delhi',
  'NEW HORIZON SCHOOL, Delhi',
  'Nk Bagrodia public school, Delhi',
  'NP CO EDU SR SEC SCHOOL, Delhi',
  'Nutan Vidya Mandir, Delhi',
  'OPG WORLD SCHOOL, Delhi',
  'Our Own English High School , Delhi',
  'Paramount International School , Delhi',
  'Patrachar Vidyalaya, Delhi',
  'PATRACHAR VIDYALYA, Delhi',
  'PES VIDYALAYA , Delhi',
  'prabhu dayal public school, Delhi',
  'PRAGATI PUBLIC SCHOOL, Delhi',
  'PRESENTATION CONVENT SR. SEC. SCHOOL, Delhi',
  'Presidium School , Delhi',
  'PRUDENCE SCHOOL, Delhi',
  "Queen Mary's school , Delhi",
  'Queens Valley School, Delhi',
  'Raghubir Singh modern school, Delhi',
  'RAISINA BENGALI SCHOOL, Delhi',
  'Rajagiri Public School, Delhi',
  'RAJKIYA PRATIBHA VIKAS VIDHYALAYA , Delhi',
  'RAMJAS SCHOOL, Delhi',
  'Rishabh public school, Delhi',
  'Rising star academy , Delhi',
  'rosary senior secondary school, Delhi',
  'Rukmani Devi Public School, Delhi',
  'Ryan International School, Delhi',
  'S.M. Arya Public School, Delhi',
  'Sachdeva Global School, Delhi',
  'Sadhu Vaswani International School For GIRLS, Delhi',
  'SALWAN PUBLIC SCHOOL, Delhi',
  'Sandhya Senior Secondary Public School, Delhi',
  'Sanskriti School , Delhi',
  'SANT GYANESHWAR MODEL SCHOOL, Delhi',
  'Sant Hari Dass Senior Secondary Public School, Delhi',
  'santgyaneshwar model school, Delhi',
  'Sardar Patel Vidyalaya, Delhi',
  'sarvoday Bal Vidyalay , Delhi',
  'SCHOLARS INDIAN SCHOOL, Delhi',
  'Seth Bhagwan Dass Senior Secondary School, Delhi',
  'Shaheed Rajpal DAV Public School, Delhi',
  'Sharjah indian school, Delhi',
  'shiksha bharati global school, Delhi',
  'Shri Shyam Mandir Shahu College, Delhi',
  'Shyama Prasad Vidyalaya, Delhi',
  'Siddharth public school, Delhi',
  'SNEH INTERNATIONAL SCHOOL, Delhi',
  'SOMERVILLE SCHOOL, Delhi',
  'South Delhi public school , Delhi',
  'Springdales School , Delhi',
  'SR DAV PUBLIC SCHOOL , Delhi',
  'SREE NARAYANA PUBLIC SCHOOL , Delhi',
  'Sri Venkateshwar International School, Delhi',
  'ST ANDREWS SCOTS SR. SEC SCHOOL, Delhi',
  "St Columba's School, Delhi",
  'st francis de sales school, Delhi',
  'St George public school , Delhi',
  'St marks sr sec public school , Delhi',
  "St Mary's School, Delhi",
  'ST PAULS SCHOOL, Delhi',
  'ST THOMAS GIRLS SENIOR SECONDARY SCHOOL, Delhi',
  'ST THOMAS SENIOR SECONDARY SCHOOL, Delhi',
  "St Xavier's Senior Secondary School, Delhi",
  'ST. CECILIAS PUBLIC SCHOOL, Delhi',
  "St. Columba's School, Delhi",
  'st. francis de sales school, Delhi',
  'St. Francis Senior Secondary School, Delhi',
  "St. Georeg's School, Delhi",
  'St. Johns academy, Delhi',
  "St. Mark's Sr. Sec. Public School, Delhi",
  'St. Martins diocesan school , Delhi',
  "St. Mary's School, Delhi",
  "ST. MARY'S SENIOR SECONDARY SCHOOL, Delhi",
  "St. Paul's School, Delhi",
  'St. Thomas School, Delhi',
  'ST. XAVIER SR. SEC. SCHOOL, Delhi',
  'St.Andrews Scots Sr. Secondary School, Delhi',
  "St.Cecilia's Public School, Delhi",
  "ST.GEORGE'S SCHOOL , Delhi",
  'St.Kabir Modern School, Delhi',
  'STRATFORD PUBLIC SCHOOL , Delhi',
  'SUMERMAL JAIN PUBLIC SCHOOL, Delhi',
  'SWISS COTTAGE SCHOOL, Delhi',
  'Tagore International School, Delhi',
  'THE AIR FORCE SCHOOL, Delhi',
  'The Dev public school , Delhi',
  'The Frank Anthony Public School, Delhi',
  'THE GREEN HILLS PUBLIC SCHOOL, Delhi',
  'The Heritage School, Delhi',
  'The Indian Community School, Delhi',
  'The Indian High School , Delhi',
  'THE MOTHERS INTERNATIONAL SCHOOL, Delhi',
  'U.S.M. Public School, Delhi',
  'UNITED INDIAN SCHOOL, Delhi',
  'universal public school, Delhi',
  'VANASTHALI PUBLIC SCHOOL , Delhi',
  'Venkateshwar International School, Delhi',
  'Vishwa Bharati Public School, Delhi',
  'Vivekanand International School, Delhi',
  'White leaf public school , Delhi',
  'FIITJEE JR.COLLEGE , Lakshadweep',
  'ST MARYS CATHEDRAL PUBLIC SCHOOL, Lakshadweep',
]

const stateValues = [
  'Andhra Pradesh',
  'Arunachal Pradesh',
  'Assam',
  'Bihar',
  'Chhattisgarh',
  'Goa',
  'Gujarat',
  'Haryana',
  'Himachal Pradesh',
  'Jammu and Kashmir',
  'Jharkhand',
  'Karnataka',
  'Kerala & Mahe',
  'Madhya Pradesh',
  'Maharashtra',
  'Manipur',
  'Meghalaya',
  'Mizoram',
  'Nagaland',
  'Odisha',
  'Punjab',
  'Rajasthan',
  'Sikkim',
  'Tamil Nadu & Puducherry (UT)',
  'Telangana',
  'Tripura',
  'Uttar Pradesh',
  'Uttarakhand',
  'West Bengal',
  'Andaman and Nicobar Islands',
  'Chandigarh',
  'Dadra and Nagar Haveli',
  'Daman and Diu',
  'Delhi',
  'Lakshadweep',
]
const countryValues = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'America',
  'Andorra',
  'Angola',
  'Antigua',
  'Argentina',
  'Armenia',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bhutan',
  'Bissau',
  'Bolivia',
  'Bosnia',
  'Botswana',
  'Brazil',
  'British',
  'Brunei',
  'Bulgaria',
  'Burkina',
  'Burma',
  'Burundi',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cape Verde',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Colombia',
  'Comoros',
  'Congo',
  'Costa Rica',
  'country debt',
  'Croatia',
  'Cuba',
  'Cyprus',
  'Czech',
  'Denmark',
  'Djibouti',
  'Dominica',
  'East Timor',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Emirate',
  'England',
  'Eritrea',
  'Estonia',
  'Ethiopia',
  'Fiji',
  'Finland',
  'France',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Great Britain',
  'Greece',
  'Grenada',
  'Grenadines',
  'Guatemala',
  'Guinea',
  'Guyana',
  'Haiti',
  'Herzegovina',
  'Honduras',
  'Hungary',
  'Iceland',
  'in usa',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Israel',
  'Italy',
  'Ivory Coast',
  'Jamaica',
  'Japan',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Korea',
  'Kosovo',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macedonia',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall',
  'Mauritania',
  'Mauritius',
  'Mexico',
  'Micronesia',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Panama',
  'Papua',
  'Paraguay',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Qatar',
  'Romania',
  'Russia',
  'Rwanda',
  'Samoa',
  'San Marino',
  'Sao Tome',
  'Saudi Arabia',
  'scotland',
  'scottish',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'Solomon',
  'Somalia',
  'South Africa',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'St. Kitts',
  'St. Lucia',
  'St Kitts',
  'St Lucia',
  'Saint Kitts',
  'Santa Lucia',
  'State of Palestine',
  'Sudan',
  'Suriname',
  'Swaziland',
  'Sweden',
  'Switzerland',
  'Syria',
  'Taiwan',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'Tobago',
  'Togo',
  'Tonga',
  'Trinidad',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Kingdom',
  'United States',
  'Uruguay',
  'USA',
  'Uzbekistan',
  'Vanuatu',
  'Vatican',
  'Venezuela',
  'Vietnam',
  'wales',
  'welsh',
  'Yemen',
  'Zambia',
  'Zimbabwe',
  'Other',
]
const cityValues = [
  'Visakhapatnam[a]',
  'Vijayawada',
  'Guntur[6]',
  'Nellore[7][8]',
  'Kurnool',
  'Rajahmundry',
  'Kadapa',
  'Kakinada',
  'Tirupati',
  'Eluru',
  'Anantapur',
  'Vizianagaram',
  'Proddatur',
  'Nandyal',
  'Ongole',
  'Adoni',
  'Madanapalle',
  'Machilipatnam ',
  'Tenali',
  'Chittoor',
  'Hindupur',
  'Bhimavaram',
  'Srikakulam',
  'Guntakal',
  'Dharmavaram',
  'Gudivada',
  'Narasaraopet',
  'Tadipatri',
  'Tadepalligudem',
  'Chilakaluripet',
  'Amaravati',
  'Kavali',
  'Thiruvananthapuram',
  'Kochi',
  'Kollam',
  'Thrissur',
  'Alappuzha',
  'Malappuram',
  'Manjeri',
  'Thrippunithura',
  'Ponnani',
  'Thrikkakara',
  'Vatakara',
  'Kanhangad',
  'Taliparamba',
  'Payyanur',
  'Koyilandy',
  'Kalamassery',
  'Beypore',
  'Kayamkulam',
  'Nileshwaram',
  'Tiruvalla',
  'Thodupuzha',
  'Perinthalmanna',
  'Chalakkudy',
  'karunagappally',
  'Thirurangadi',
  'Kottarakara',
  'Maradu',
  'Shornur',
  'Kottakkal',
  'Varkala',
  'Manjeshwaram',
  'Uppala',
  'Mattanur',
  'Chavakkad',
  'Kattappana',
  'Adoor',
  'Pandalam',
  'Anchal',
  'Edava',
  'Kalpetta',
  'Sultan Bathery',
  'Mananthavady',
  'Aalo (Along)',
  'Anini',
  'Basar',
  'Boleng',
  'Bomdila',
  'Changlang',
  'Daporijo',
  'Deomali',
  'Dirang',
  'Hawai',
  'Itanagar',
  'Jairampur',
  'Khonsa',
  'Koloriang',
  'Longding',
  'Miao',
  'Naharlagun',
  'Namsai',
  'Pasighat',
  'Roing',
  'Rupa',
  'Sagalee',
  'Seppa',
  'Tawang',
  'Tezu',
  'Yingkiong',
  'Ziro',
  'Abhayapuri',
  'Ambikapur Part-X',
  'Amguri',
  'Anand Nagar',
  'Badarpur',
  'Badarpur Rly Town',
  'Bahbari Gaon',
  'Bamun Sualkuchi',
  'Barbari (AMC Area)',
  'Barpathar',
  'Barpeta',
  'Barpeta Road',
  'Basugaon',
  'Bihpuria',
  'Bijni',
  'Bilasipara',
  'Biswanath Chariali',
  'Bohari',
  'Bokajan',
  'Bokakhat',
  'Bongaigaon',
  'Bongaigaon, Refinery & Petro-chemical Ltd. Township',
  'Borgolai Grant No.11',
  'Chabua',
  'Chandrapur Bagicha',
  'Chapar',
  'Chekonidhara',
  'Choto Haibor',
  'Dergaon',
  'Dharapur',
  'Dhekiajuli',
  'Dhemaji',
  'Dhing',
  'Dhubri',
  'Dibrugarh',
  'Digboi',
  'Digboi Oil Town',
  'Dimaruguri',
  'Diphu',
  'Doboka',
  'Dokmoka',
  'Donkamokam',
  'Doom Dooma',
  'Duliajan No.1',
  'Duliajan Oil Town',
  'Durga Nagar Part-V',
  'Gauripur',
  'Goalpara',
  'Gohpur',
  'Golaghat',
  'Golokganj',
  'Gossaigaon',
  'Guwahati',
  'Haflong',
  'Hailakandi',
  'Hamren',
  'Hindustan Paper Corporation Ltd. Township Area Panchgram',
  'Hojai',
  'Howli',
  'Howraghat',
  'Jagiroad',
  'Jonai Bazar',
  'Jorhat',
  'Kampur Town',
  'Kanakpur Part-II',
  'Karimganj',
  'Kharijapikon',
  'Kharupatia',
  'Kochpara',
  'Kokrajhar',
  'Kumar Kaibarta Gaon',
  'Lakhipur',
  'Lakhipur',
  'Lala',
  'Lanka',
  'Lido Tikok',
  'Lido Town',
  'Lumding',
  'Lumding Rly Colony',
  'Mahur',
  'Maibong',
  'Majgaon',
  'Makum',
  'Mangaldoi',
  'Mankachar',
  'Margherita',
  'Mariani',
  'Marigaon',
  'Moran Town',
  'Moranhat',
  'Nagaon',
  'Naharkatiya',
  'Nalbari',
  'Namrup',
  'Naubaisa Gaon',
  'Nazira',
  'New Bongaigaon Rly. Colony',
  'North Guwahati',
  'North Lakhimpur',
  'Numaligarh Refinery Township',
  'Palasbari',
  'Pathsala',
  'Rangapara',
  'Rangia',
  'Salakati',
  'Sapatgram',
  'Sarbhog',
  'Sarthebari',
  'Sarupathar',
  'Sarupathar Bengali',
  'Senchoa Gaon',
  'Sibsagar',
  'Silapathar',
  'Silchar',
  'Silchar Part-X',
  'Sonari',
  'Sualkuchi',
  'Tangla',
  'Tezpur',
  'Tihu',
  'Tinsukia',
  'Titabor Town',
  'Udalguri',
  'Umrangso',
  'Uttar Krishnapur Part-I',
  'Amarpur',
  'Araria',
  'Areraj',
  'Arrah',
  'Asarganj',
  'Aurangabad',
  'Bagaha',
  'Bahadurganj',
  'Bairgania',
  'Bakhtiarpur',
  'Banka',
  'Banmankhi Bazar',
  'Barahiya',
  'Barauli',
  'Barauni IOC Township',
  'Barbigha',
  'Barh',
  'Begusarai',
  'Behea',
  'Belsand',
  'Bettiah',
  'Bhabua',
  'Bhagalpur (M.Corp)',
  'Bihar',
  'Bikramganj',
  'Birpur',
  'Bodh Gaya',
  'Buxar',
  'Chakia',
  'Chanpatia',
  'Chapra',
  'Colgong',
  'Dalsinghsarai',
  'Darbhanga',
  'Daudnagar',
  'Dehri',
  'Dhaka',
  'Dighwara',
  'Dinapur Cantt.',
  'Dinapur Nizamat',
  'Dumra',
  'Dumraon',
  'Fatwah',
  'Forbesganj',
  'Gaya',
  'Ghoghardiha',
  'Gogri Jamalpur',
  'Gopalganj',
  'Habibpur',
  'Hajipur',
  'Hilsa',
  'Hisua',
  'Islampur',
  'Jagdishpur',
  'Jainagar',
  'Jamalpur',
  'Jamhaur',
  'Jamui',
  'Janakpur Road',
  'Jehanabad',
  'Jhajha',
  'Jhanjharpur',
  'Jogbani',
  'Kanti',
  'Kasba',
  'Kataiya',
  'Katihar',
  'Khagaria',
  'Khagaul',
  'Kharagpur',
  'Khusrupur',
  'Kishanganj',
  'Koath',
  'Koilwar',
  'Lakhisarai',
  'Lalganj',
  'Lauthaha',
  'Madhepura',
  'Madhubani',
  'Maharajganj',
  'Mahnar Bazar',
  'Mairwa',
  'Makhdumpur',
  'Maner',
  'Manihari',
  'Marhaura',
  'Masaurhi',
  'Mirganj',
  'Mohiuddinagar',
  'Mokameh',
  'Motihari',
  'Motipur',
  'Munger',
  'Murliganj',
  'Muzaffarpur',
  'Nabinagar',
  'Narkatiaganj',
  'Naugachhia',
  'Nawada',
  'Nirmali',
  'Nokha',
  'Paharpur',
  'Patna',
  'Phulwari Sharif',
  'Piro',
  'Purnia',
  'Rafiganj',
  'Raghunathpur',
  'Rajgir',
  'Ramnagar',
  'Raxaul Bazar',
  'Revelganj',
  'Rosera',
  'Saharsa',
  'Samastipur',
  'Sasaram',
  'Shahpur',
  'Sheikhpura',
  'Sheohar',
  'Sherghati',
  'Silao',
  'Sitamarhi',
  'Siwan',
  'Sonepur',
  'Sugauli',
  'Sultanganj',
  'Supaul',
  'Tekari',
  'Thakurganj',
  'Warisaliganj',
  'Ahiwara',
  'Akaltara',
  'Ambagarh Chowki',
  'Ambikapur',
  'Arang',
  'Bade Bacheli',
  'Bagbahara',
  'Baikunthpur',
  'Balod',
  'Baloda',
  'Baloda Bazar',
  'Banarsi',
  'Basna',
  'Bemetra',
  'Bhanpuri',
  'Bhatapara',
  'Bhatgaon',
  'Bhilai Charoda',
  'Bhilai Nagar',
  'Bilaspur',
  'Bilha',
  'Birgaon',
  'Bodri',
  'Champa',
  'Chharchha',
  'Chhuikhadan',
  'Chirmiri',
  'Dalli-Rajhara',
  'Dantewada',
  'Deori',
  'Dhamdha',
  'Dhamtari',
  'Dharamjaigarh',
  'Dipka',
  'Dongargaon',
  'Dongragarh',
  'Durg',
  'Frezarpur',
  'Gandai',
  'Gaurella',
  'Geedam',
  'Gharghoda',
  'Gobranawapara',
  'Gogaon',
  'Hatkachora',
  'Jagdalpur',
  'Jamul',
  'Jashpur nagar',
  'Jhagrakhand',
  'Kanker',
  'Katghora',
  'Kawardha',
  'Khairagarh',
  'Khamhria',
  'Kharod',
  'Kharsia',
  'Khongapani',
  'Kirandul',
  'Kondagaon',
  'Korba',
  'Kota',
  'Kumhari',
  'Kurud',
  'Lingiyadih',
  'Lormi',
  'Mahasamund',
  'Manendragarh',
  'Mehmand',
  'Mowa',
  'Mungeli',
  'Naila Janjgir',
  'Namna Kalan',
  'Naya Baradwar',
  'Pandariya',
  'Patan',
  'Pathalgaon',
  'Pendra',
  'Phunderdihari',
  'Pithora',
  'Raigarh',
  'Raipur',
  'Rajgamar',
  'Rajnandgaon',
  'Ramanujganj',
  'Ratanpur',
  'Sakti',
  'Saraipali',
  'Sarangarh',
  'Shivrinarayan',
  'Simga',
  'Sirgiti',
  'Surajpur',
  'Takhatpur',
  'Telgaon',
  'Tilda Newra',
  'Urla',
  'Vishrampur',
  'Amli',
  'Silvassa',
  'Daman',
  'Diu',
  'Ali Pur',
  'Asola',
  'Babar Pur',
  'Bankner',
  'Bawana',
  'Begum Pur',
  'Bhalswa Jahangir Pur',
  'Bhati',
  'Burari',
  'Chhawla',
  'Chilla Saroda Bangar',
  'Dallo Pura',
  'Dayal Pur',
  'Delhi Cantt.',
  'Deoli',
  'Gharoli',
  'Gharonda Neemka Bangar alias Patpar Ganj',
  'Gheora',
  'Ghitorni',
  'Ghoga',
  'Gokal Pur',
  'Hastsal',
  'Jaffrabad',
  'Jharoda Majra Burari',
  'Jiwan Pur alias Johri Pur',
  'Jona Pur',
  'Kanjhawala',
  'Kapas Hera',
  'Karawal Nagar',
  'Khajoori Khas',
  'Khera Khurd',
  'Kirari Suleman Nagar',
  'Kondli',
  'Libas Pur',
  'Mirpur Turk',
  'Mithe Pur',
  'Molar Band',
  'Mundka',
  'Mustafabad',
  'Nangal Dewat',
  'Nangal Thakran',
  'Nangloi Jat',
  'Pehlad Pur Bangar',
  'Pooth Kalan',
  'Pooth Khurd',
  'Pul Pehlad',
  'Quammruddin Nagar',
  'Rajokri',
  'Roshan Pura alias Dichaon Khurd',
  'Sadat Pur Gujran',
  'Sahibabad Daulat Pur',
  'Saidul Ajaib',
  'Sambhalka',
  'Sanoth',
  'Siras Pur',
  'Sultan Pur',
  'Sultan Pur Majra',
  'Taj Pul',
  'Tigri',
  'Ziauddin Pur',
  'Aldona',
  'Aquem',
  'Bambolim',
  'Bandora',
  'Benaulim',
  'Bicholim',
  'Calangute',
  'Calapor',
  'Canacona',
  'Candolim',
  'Carapur',
  'Chicalim',
  'Chimbel',
  'Chinchinim',
  'Colvale',
  'Cuncolim',
  'Curchorem Cacora',
  'Curti',
  'Davorlim',
  'Goa Velha',
  'Guirim',
  'Mapusa',
  'Margao',
  'Mormugao',
  'Navelim',
  'Pale',
  'Panaji',
  'Parcem',
  'Penha-de-Franca',
  'Pernem',
  'Ponda',
  'Quepem',
  'Queula',
  'Reis Magos',
  'Saligao',
  'Sancoale',
  'Sanguem',
  'Sanquelim',
  'Sanvordem',
  'Sao Jose-de-Areal',
  'Siolim',
  'Socorro (Serula)',
  'Valpoi',
  'Varca',
  'Aadityana',
  'Aambaliyasan',
  'Aantaliya',
  'Aarambhada',
  'Abrama',
  'Adalaj',
  'Ahmedabad',
  'Ahmedabad Cantonment',
  'Alang',
  'Ambaji',
  'Amreli',
  'Anand',
  'Andada',
  'Anjar',
  'Anklav',
  'Anklesvar',
  'Anklesvar INA',
  'Atul',
  'Bagasara',
  'Bajva',
  'Balasinor',
  'Bantwa',
  'Bardoli',
  'Bavla',
  'Bedi',
  'Bhachau',
  'Bhanvad',
  'Bharuch',
  'Bharuch INA',
  'Bhavnagar',
  'Bhayavadar',
  'Bhuj',
  'Bilimora',
  'Bodeli',
  'Bopal',
  'Boriavi',
  'Borsad',
  'Botad',
  'Chaklasi',
  'Chala',
  'Chalala',
  'Chalthan',
  'Chanasma',
  'Chandkheda',
  'Chandlodiya',
  'Chanod',
  'Chhaprabhatha',
  'Chhatral INA',
  'Chhaya',
  'Chhota Udaipur',
  'Chikhli',
  'Chiloda(Naroda)',
  'Chorvad',
  'Dabhoi',
  'Dahegam',
  'Dakor',
  'Damnagar',
  'Deesa',
  'Devgadbaria',
  'Devsar',
  'Dhandhuka',
  'Dhanera',
  'Dharampur',
  'Dhola',
  'Dholka',
  'Dhoraji',
  'Dhrangadhra',
  'Dhrol',
  'Digvijaygram',
  'Dohad',
  'Dungra',
  'Dwarka',
  'Freelandgunj',
  'Gadhada',
  'Gandevi',
  'Gandhidham',
  'Gandhinagar',
  'Gariadhar',
  'Ghatlodiya',
  'Ghogha',
  'Godhra',
  'Gondal',
  'Gota',
  'GSFC Complex INA',
  'Hajira INA',
  'Halol',
  'Halvad',
  'Harij',
  'Himatnagar',
  'Ichchhapor',
  'Idar',
  'Jafrabad',
  'Jalalpore',
  'Jam Jodhpur',
  'Jambusar',
  'Jamnagar',
  'Jasdan',
  'Jawaharnagar (Gujarat Refinery)',
  'Jetpur Navagadh',
  'Jodhpur',
  'Joshipura',
  'Junagadh',
  'Kadi',
  'Kadodara',
  'Kalavad',
  'Kali',
  'Kalol',
  'Kalol',
  'Kalol INA',
  'Kalol INA',
  'Kandla',
  'Kanodar',
  'Kapadvanj',
  'Karachiya',
  'Karamsad',
  'Karjan',
  'Katpar',
  'Keshod',
  'Kevadiya',
  'Khambhalia',
  'Khambhat',
  'Kharaghoda',
  'Kheda',
  'Khedbrahma',
  'Kheralu',
  'Kodinar',
  'Kosamba',
  'Kutiyana',
  'Lambha',
  'Lathi',
  'Limbdi',
  'Limla',
  'Lunawada',
  'Mahemdavad',
  'Mahesana',
  'Mahudha',
  'Mahuva',
  'Mahuvar',
  'Makarba',
  'Maktampur',
  'Malpur',
  'Manavadar',
  'Mandvi',
  'Mangrol',
  'Mansa',
  'Meghraj',
  'Memnagar',
  'Mithapur',
  'Modasa',
  'Mogravadi',
  'Morvi',
  'Motera',
  'Mundra',
  'Nadiad',
  'Nanakvada',
  'Nandej',
  'Nandesari',
  'Nandesari INA',
  'NavagamGhed',
  'Navsari',
  'Ode',
  'Okha port',
  'Paddhari',
  'Padra',
  'Palanpur',
  'Palej',
  'Palitana',
  'Pardi',
  'Parnera',
  'Parvat',
  'Patan',
  'Petlad',
  'Petro Chemical Complex INA',
  'Porbandar',
  'Prantij',
  'Radhanpur',
  'Rajkot',
  'Rajpipla',
  'Rajula',
  'Ramol',
  'Ranavav',
  'Ranip',
  'Ranoli',
  'Rapar',
  'Sachin',
  'Sachin INA',
  'Salaya',
  'Sanand',
  'Santrampur',
  'Sarigam INA',
  'Sarkhej-Okaf',
  'Savarkundla',
  'Sayan',
  'Sidhpur',
  'Sihor',
  'Sikka',
  'Singarva',
  'Songadh',
  'Surajkaradi',
  'Surat',
  'Surendranagar Dudhrej',
  'Talaja',
  'Talod',
  'Tarsali',
  'Thaltej',
  'Thangadh',
  'Tharad',
  'Ukai',
  'Umbergaon',
  'Umbergaon INA',
  'Umreth',
  'Un',
  'Una',
  'Unjha',
  'Upleta',
  'Utran',
  'Vadia',
  'Vadnagar',
  'Vadodara',
  'Vaghodia INA',
  'Vallabh Vidhyanagar',
  'Valsad',
  'Valsad INA',
  'Vanthali',
  'Vapi',
  'Vapi INA',
  'Vartej',
  'Vasna Borsad INA',
  'Vastral',
  'Vastrapur',
  'Vejalpur',
  'Veraval',
  'Vijalpor',
  'Vijapur',
  'Viramgam',
  'Visavadar',
  'Visnagar',
  'Vitthal Udyognagar INA',
  'Vyara',
  'Wadhwan',
  'Wankaner',
  'Zalod',
  'Ambala',
  'Ambala Cantt.',
  'Ambala Sadar',
  'Asan  Khurd',
  'Assandh',
  'Ateli',
  'Babiyal',
  'Bahadurgarh',
  'Barwala',
  'Bawal',
  'Bawani Khera',
  'Beri',
  'Bhiwani',
  'Bilaspur',
  'Buria',
  'Charkhi Dadri',
  'Cheeka',
  'Chhachhrauli',
  'Dharuhera',
  'Dundahera',
  'Ellenabad',
  'Farakhpur',
  'Faridabad',
  'Farrukhnagar',
  'Fatehabad',
  'Ferozepur Jhirka',
  'Ganaur',
  'Gharaunda',
  'Gohana',
  'Gurgaon',
  'Gurgaon Rural',
  'Haileymandi',
  'Hansi',
  'Hassanpur',
  'Hathin',
  'Hisar',
  'Hodal',
  'Indri',
  'Jagadhri',
  'Jakhal Mandi',
  'Jhajjar',
  'Jind',
  'Julana',
  'Kaithal',
  'Kalan Wali',
  'Kalanaur',
  'Kalayat',
  'Kalka',
  'Kanina',
  'Kansepur',
  'Kardhan',
  'Karnal',
  'Kharkhoda',
  'Ladrawan',
  'Ladwa',
  'Loharu',
  'Maham',
  'Mahendragarh',
  'Mandi Dabwali',
  'Mustafabad',
  'Nagai Chaudhry',
  'Naraingarh',
  'Narnaul',
  'Narnaund',
  'Narwana',
  'Nilokheri',
  'Nuh',
  'Palwal',
  'Panchkula Urban Estate',
  'Panipat',
  'Panipat Taraf Ansar',
  'Panipat Taraf Rajputan',
  'Panipt Taraf Makhdum Zadgan',
  'Pataudi',
  'Pehowa',
  'Pinjore',
  'Punahana',
  'Pundri',
  'Radaur',
  'Raipur Rani',
  'Rania',
  'Ratia',
  'Rewari',
  'Rewari (Rural)',
  'Rohtak',
  'Sadaura',
  'Safidon',
  'Samalkha',
  'Sankhol',
  'Sasauli',
  'Shahbad',
  'Sirsa',
  'Siwani',
  'Sohna',
  'Sonipat',
  'Sukhrali',
  'Taoru',
  'Taraori',
  'Thanesar',
  'Tilpat',
  'Tohana',
  'Tosham',
  'Uchana',
  'Uklanamandi',
  'Uncha Siwana',
  'Yamunanagar',
  'Arki',
  'Baddi',
  'Bakloh',
  'Banjar',
  'Bhota',
  'Bhuntar',
  'Bilaspur',
  'Chamba',
  'Chaupal',
  'Chuari Khas',
  'Dagshai',
  'Dalhousie',
  'Dalhousie',
  'Daulatpur',
  'Dera Gopipur',
  'Dharmsala',
  'Gagret',
  'Ghumarwin',
  'Hamirpur',
  'Jawalamukhi',
  'Jogindarnagar',
  'Jubbal',
  'Jutogh',
  'Kangra',
  'Kasauli',
  'Kotkhai',
  'Kullu',
  'Manali',
  'Mandi',
  'Mant Khas',
  'Mehatpur Basdehra',
  'Nadaun',
  'Nagrota Bagwan',
  'Nahan',
  'Naina Devi',
  'Nalagarh',
  'Narkanda',
  'Nurpur',
  'Palampur',
  'Paonta Sahib',
  'Parwanoo',
  'Rajgarh',
  'Rampur',
  'Rawalsar',
  'Rohru',
  'Sabathu',
  'Santokhgarh',
  'Sarkaghat',
  'Seoni',
  'Shimla',
  'Solan',
  'Sundarnagar',
  'Talai',
  'Theog',
  'Tira Sujanpur',
  'Una',
  'Yol',
  'Achabal',
  'Akhnoor',
  'Anantnag',
  'Arnia',
  'Awantipora',
  'Badami Bagh',
  'Badgam',
  'Bandipore',
  'Banihal',
  'Baramula',
  'Bari Brahmana',
  'Bashohli',
  'Batote',
  'Beerwah',
  'Bhaderwah',
  'Bijbehara',
  'Billawar',
  'Bishna',
  'Charari Sharief',
  'Chenani',
  'Doda',
  'Duru-Verinag',
  'Ganderbal',
  'Gho Manhasan',
  'Gorah Salathian',
  'Gulmarg',
  'Hajan',
  'Handwara',
  'Hiranagar',
  'Jammu',
  'Jammu Cantonment',
  'Jourian',
  'Kargil',
  'Kathua',
  'Katra',
  'Khan Sahib',
  'Khour',
  'Khrew',
  'Kishtwar',
  'Kud',
  'Kukernag',
  'Kulgam',
  'Kunzer',
  'Kupwara',
  'Lakhenpur',
  'Leh',
  'Magam',
  'Mattan',
  'Nowshehra',
  'Pahalgam',
  'Pampore',
  'Parole',
  'Pattan',
  'Pulwama',
  'Punch',
  'Qazigund',
  'Rajauri',
  'Ram Garh',
  'Ramban',
  'Ramnagar',
  'Ranbir Singh Pora',
  'Reasi',
  'Rehambal',
  'Samba',
  'Shupiyan',
  'Sopore',
  'Srinagar',
  'Sumbal',
  'Sunderbani',
  'Talwara',
  'Thanamandi',
  'Tral',
  'Udhampur',
  'Uri',
  'Vijay Pur',
  'Adityapur',
  'Amlabad',
  'Angarpathar',
  'Ara',
  'Babua Kalan',
  'Bagbera',
  'Baliari',
  'Balkundra',
  'Bandhgora',
  'Barajamda',
  'Barhi',
  'Barkakana',
  'Barughutu',
  'Barwadih',
  'Basaria',
  'Basukinath',
  'Bermo',
  'Bhagatdih',
  'Bhojudih',
  'Bhowrah',
  'Bhuli',
  'Bokaro',
  'Bokaro Steel City',
  'Bundu',
  'Chaibasa',
  'Chakradharpur',
  'Chakulia',
  'Chandaur',
  'Chandil',
  'Chandrapura',
  'Chas',
  'Chatra',
  'Chhatatanr',
  'Chhota Gobindpur',
  'Chhotaputki',
  'Chiria',
  'Chirkunda',
  'Churi',
  'Daltonganj',
  'Danguwapasi',
  'Dari',
  'Deoghar',
  'Deorikalan',
  'Dhanbad',
  'Dhanwar',
  'Dhaunsar',
  'Dugda',
  'Dumarkunda',
  'Dumka',
  'Egarkunr',
  'Gadhra',
  'Garhwa',
  'Ghatshila',
  'Ghorabandha',
  'Gidi',
  'Giridih',
  'Gobindpur',
  'Godda',
  'Godhar',
  'Gomoh',
  'Gua',
  'Gumia',
  'Gumla',
  'Haludbani',
  'Hazaribag',
  'Hesla',
  'Hussainabad',
  'Isri',
  'Jadugora',
  'Jamadoba',
  'Jamshedpur',
  'Jamtara',
  'Jaridih Bazar',
  'Jasidih',
  'Jena',
  'Jharia',
  'Jharia Khas',
  'Jhinkpani',
  'Jhumri Tilaiya',
  'Jorapokhar',
  'Jugsalai',
  'Kailudih',
  'Kalikapur',
  'Kandra',
  'Kanke',
  'Katras',
  'Kedla',
  'Kenduadih',
  'Kharkhari',
  'Kharsawan',
  'Khelari',
  'Khunti',
  'Kiriburu',
  'Kodarma',
  'Kuju',
  'Kurpania',
  'Kustai',
  'Lakarka',
  'Lapanga',
  'Latehar',
  'Lohardaga',
  'Loyabad',
  'Madhupur',
  'Maithon',
  'Malkera',
  'Mango',
  'Marma',
  'Meghahatuburu Forest village',
  'Mera',
  'Meru',
  'Mihijam',
  'Mugma',
  'Muri',
  'Musabani',
  'Nagri Kalan',
  'Nirsa',
  'Noamundi',
  'Okni NO.II',
  'Orla',
  'Pakaur',
  'Palawa',
  'Panchet',
  'Paratdih',
  'Pathardih',
  'Patratu',
  'Phusro',
  'Pondar Kanali',
  'Rajmahal',
  'Ramgarh Cantonment',
  'Ranchi',
  'Religara alias Pachhiari',
  'Rohraband',
  'Sahibganj',
  'Sahnidih',
  'Saraidhela',
  'Sarjamda',
  'Saunda',
  'Seraikela',
  'Sewai',
  'Sijhua',
  'Sijua',
  'Simdega',
  'Sindri',
  'Sinduria',
  'Sini',
  'Sirka',
  'Siuliban',
  'Tati',
  'Tenu Dam-cum- Kathhara',
  'Tisra',
  'Topa',
  'Topchanchi',
  'Adityapatna',
  'Adyar',
  'Afzalpur',
  'Aland',
  'Alnavar',
  'Alur',
  'Ambikanagara',
  'Anekal',
  'Ankola',
  'Annigeri',
  'Arkalgud',
  'Arsikere',
  'Athni',
  'Aurad',
  'Badami',
  'Bagalkot',
  'Bagepalli',
  'Bail Hongal',
  'Bajala',
  'Bajpe',
  'Bangalore',
  'Bangarapet',
  'Bankapura',
  'Bannur',
  'Bantwal',
  'Basavakalyan',
  'Basavana Bagevadi',
  'Belgaum',
  'Belgaum Cantonment',
  'Bellary',
  'Beltangadi',
  'Belur',
  'Belvata',
  'Bhadravati',
  'Bhalki',
  'Bhatkal',
  'Bhimarayanagudi',
  'Bhogadi',
  'Bidar',
  'Bijapur',
  'Bilgi',
  'Birur',
  'Bommanahalli',
  'Bommasandra',
  'Byadgi',
  'Byatarayanapura',
  'Challakere',
  'Chamrajnagar',
  'Channagiri',
  'Channapatna',
  'Channarayapattana',
  'Chik Ballapur',
  'Chikmagalur',
  'Chiknayakanhalli',
  'Chikodi',
  'Chincholi',
  'Chintamani',
  'Chitapur',
  'Chitgoppa',
  'Chitradurga',
  'Dandeli',
  'Dargajogihalli',
  'Dasarahalli',
  'Davanagere',
  'Devadurga',
  'Devanhalli',
  'Dod Ballapur',
  'Donimalai Township',
  'Gadag-Betigeri',
  'Gajendragarh',
  'Gangawati',
  'Gauribidanur',
  'Gokak',
  'Gokak Falls',
  'Gonikoppal',
  'Gottikere',
  'Gubbi',
  'Gudibanda',
  'Gulbarga',
  'Guledgudda',
  'Gundlupet',
  'Gurmatkal',
  'Haliyal',
  'Hangal',
  'Harihar',
  'Harpanahalli',
  'Hassan',
  'Hatti',
  'Hatti Gold Mines',
  'Haveri',
  'Hebbagodi',
  'Hebbalu',
  'Heggadadevanakote',
  'Herohalli',
  'Hindalgi',
  'Hirekerur',
  'Hiriyur',
  'Holalkere',
  'Holenarsipur',
  'Homnabad',
  'Honavar',
  'Honnali',
  'Hoovina Hadagalli',
  'Hosanagara',
  'Hosdurga',
  'Hoskote',
  'Hospet',
  'Hubli-Dharwad',
  'Hukeri',
  'Hunasamaranahalli',
  'Hungund',
  'Hunsur',
  'Ilkal',
  'Indi',
  'Jagalur',
  'Jamkhandi',
  'Jevargi',
  'Jog Falls',
  'Kadur',
  'Kalghatgi',
  'Kamalapuram',
  'Kampli',
  'Kanakapura',
  'Kangrali (BK)',
  'Kangrali (KH)',
  'Kannur',
  'Karkal',
  'Karwar',
  'Kengeri',
  'Kerur',
  'Khanapur',
  'Kodigenahalli',
  'Kodiyal',
  'Kolar',
  'Kollegal',
  'Konanakunte',
  'Konnur',
  'Koppa',
  'Koppal',
  'Koratagere',
  'Kotekara',
  'Kothnur',
  'Kotturu',
  'Krishnarajanagar',
  'Krishnarajapura',
  'Krishnarajasagara',
  'Krishnarajpet',
  'Kudchi',
  'Kudligi',
  'Kudremukh',
  'Kumta',
  'Kundapura',
  'Kundgol',
  'Kunigal',
  'Kurgunta',
  'Kushalnagar',
  'Kushtagi',
  'Lakshmeshwar',
  'Lingsugur',
  'Londa',
  'Maddur',
  'Madhugiri',
  'Madikeri',
  'Magadi',
  'Mahadevapura',
  'Mahalingpur',
  'Malavalli',
  'Mallar',
  'Malur',
  'Mandya',
  'Mangalore',
  'Manvi',
  'Molakalmuru',
  'Mudalgi',
  'Mudbidri',
  'Muddebihal',
  'Mudgal',
  'Mudhol',
  'Mudigere',
  'Mudushedde',
  'Mulbagal',
  'Mulgund',
  'Mulki',
  'Mulur',
  'Mundargi',
  'Mundgod',
  'Munirabad Project Area',
  'Munnur',
  'Mysore',
  'Nagamangala',
  'Nanjangud',
  'Narasimharajapura',
  'Naregal',
  'Nargund',
  'Navalgund',
  'Nelmangala',
  'Nipani',
  'Pandavapura',
  'Pattanagere',
  'Pavagada',
  'Piriyapatna',
  'Pudu',
  'Puttur',
  'Rabkavi Banhatti',
  'Raichur',
  'Ramanagaram',
  'Ramdurg',
  'Ranibennur',
  'Raybag',
  'Robertson Pet',
  'Ron',
  'Sadalgi',
  'Sagar',
  'Sakleshpur',
  'Saligram',
  'Sandur',
  'Sankeshwar',
  'Sathyamangala',
  'Saundatti-Yellamma',
  'Savanur',
  'Sedam',
  'Shahabad',
  'Shahabad ACC',
  'Shahpur',
  'Shaktinagar',
  'Shiggaon',
  'Shikarpur',
  'Shimoga',
  'Shirhatti',
  'Shorapur',
  'Shrirangapattana',
  'Siddapur',
  'Sidlaghatta',
  'Sindgi',
  'Sindhnur',
  'Sira',
  'Siralkoppa',
  'Sirsi',
  'Siruguppa',
  'Someshwar',
  'Somvarpet',
  'Sorab',
  'Sringeri',
  'Srinivaspur',
  'Sulya',
  'Talikota',
  'Tarikere',
  'Tekkalakota',
  'Terdal',
  'Thokur-62',
  'Thumbe',
  'Tiptur',
  'Tirthahalli',
  'Tirumakudal-Narsipur',
  'Tumkur',
  'Turuvekere',
  'Udupi',
  'Ullal',
  'Uttarahalli',
  'Venkatapura',
  'Vijayapura',
  'Virajpet',
  'Wadi',
  'Wadi ACC',
  'Yadgir',
  'Yelahanka',
  'Yelandur',
  'Yelbarga',
  'Yellapur',
  'Yenagudde',
  'Adoor',
  'Akathiyoor',
  'Alappuzha',
  'Aluva',
  'Ancharakandy',
  'Angamaly',
  'Arookutty',
  'Aroor',
  'Attingal',
  'Avinissery',
  'Azhikode North',
  'Azhikode South',
  'Bangramanjeshwar',
  'Beypore',
  'Brahmakulam',
  'Chala',
  'Chalakudy',
  'Changanassery',
  'Chavakkad',
  'Chelora',
  'Chendamangalam',
  'Chengamanad',
  'Chengannur',
  'Cheranallur',
  'Cherthala',
  'Cheruthazham',
  'Cheruvannur',
  'Chevvoor',
  'Chirakkal',
  'Chittur-Thathamangalam',
  'Chockli',
  'Choornikkara',
  'Chowwara',
  'Dharmadom',
  'Edathala',
  'Elayavoor',
  'Eloor',
  'Eranholi',
  'Erattupetta',
  'Feroke',
  'Guruvayoor',
  'Hosabettu',
  'Idukki Township',
  'Iringaprom',
  'Irinjalakuda',
  'Iriveri',
  'Kadachira',
  'Kadamakkudy',
  'Kadirur',
  'Kadungalloor',
  'Kalamassery',
  'Kalliasseri',
  'Kalpetta',
  'Kanhangad',
  'Kanhirode',
  'Kanjikkuzhi',
  'Kannadiparamba',
  'Kannapuram',
  'Kannur',
  'Kannur Cantonment',
  'Karuvanthuruthy',
  'Kasaragod',
  'Kayamkulam',
  'Kochi',
  'Kodungallur',
  'Kokkothamangalam',
  'Kolazhy',
  'Kollam',
  'Komalapuram',
  'Koothuparamba',
  'Koratty',
  'Kothamangalam',
  'Kottayam',
  'Kottayam-Malabar',
  'Kottuvally',
  'Kozhikode',
  'Kudlu',
  'Kunnamkulam',
  'Kureekkad',
  'Malappuram',
  'Manjeri',
  'Manjeshwar',
  'Maradu',
  'Marathakkara',
  'Mattannur',
  'Mavelikkara',
  'Mavilayi',
  'Mavoor',
  'Methala',
  'Muhamma',
  'Mulavukad',
  'Munderi',
  'Muvattupuzha',
  'Muzhappilangad',
  'Nadathara',
  'Narath',
  'Nedumangad',
  'Nenmenikkara',
  'New Mahe',
  'Neyyattinkara',
  'Olavanna',
  'Ottappalam',
  'Paduvilayi',
  'Palai',
  'Palakkad',
  'Palayad',
  'Palissery',
  'Pallikkunnu',
  'Paluvai',
  'Panniyannur',
  'Panoor',
  'Pantheeramkavu',
  'Pappinisseri',
  'Paravoor',
  'Paravur',
  'Pathanamthitta',
  'Pathiriyad',
  'Pattiom',
  'Pavaratty',
  'Payyannur',
  'Perakam',
  'Peralasseri',
  'Peringathur',
  'Perinthalmanna',
  'Perumbaikad',
  'Perumbavoor',
  'Pinarayi',
  'Ponnani',
  'Pottore',
  'Punalur',
  'Puranattukara',
  'Puthukkad',
  'Puthunagaram',
  'Puzhathi',
  'Quilandy',
  'Ramanattukara',
  'Shoranur',
  'Taliparamba',
  'Thaikkad',
  'Thalassery',
  'Thiruvalla',
  'Thiruvankulam',
  'Thodupuzha',
  'Thottada',
  'Thrippunithura',
  'Thrissur',
  'Tirur',
  'Trivandrum',
  'Udma',
  'Vadakara',
  'Vaikom',
  'Valapattanam',
  'Vallachira',
  'Varam',
  'Varappuzha',
  'Varkala',
  'Vazhakkala',
  'Venmanad',
  'Villiappally',
  'Amini',
  'Kavaratti',
  'Minicoy',
  'Agar',
  'Ajaigarh',
  'Akoda',
  'Akodia',
  'Alampur',
  'Alirajpur',
  'Alot',
  'Amanganj',
  'Amarkantak',
  'Amarpatan',
  'Amarwara',
  'Ambada',
  'Ambah',
  'Amla',
  'Amlai',
  'Anjad',
  'Antari',
  'Anuppur',
  'Aron',
  'Ashok Nagar',
  'Ashta',
  'Babai',
  'Bada Malhera',
  'Badagaon',
  'Badagoan',
  'Badarwas',
  'Badawada',
  'Badi',
  'Badkuhi',
  'Badnagar',
  'Badnawar',
  'Badod',
  'Badoda',
  'Badra',
  'Bagh',
  'Bagli',
  'Baihar',
  'Baikunthpur',
  'Balaghat',
  'Baldeogarh',
  'Bamhani',
  'Bamor',
  'Bamora',
  'Banda',
  'Bangawan',
  'Bansatar Kheda',
  'Baraily',
  'Barela',
  'Barghat',
  'Barhi',
  'Barigarh',
  'Barwaha',
  'Barwani',
  'Basoda',
  'Begamganj',
  'Beohari',
  'Berasia',
  'Betma',
  'Betul',
  'Betul-Bazar',
  'Bhainsdehi',
  'Bhamodi',
  'Bhander',
  'Bhanpura',
  'Bharveli',
  'Bhaurasa',
  'Bhavra',
  'Bhedaghat',
  'Bhikangaon',
  'Bhilakhedi',
  'Bhind',
  'Bhitarwar',
  'Bhopal',
  'Biaora',
  'Bijawar',
  'Bijeypur',
  'Bijuri',
  'Bilaua',
  'Bilpura',
  'Bina Etawa',
  'Bina Rly Colony',
  'Birsinghpur',
  'Boda',
  'Budhni',
  'Burhanpur',
  'Burhar',
  'Buxwaha',
  'Chachaura-Binaganj',
  'Chakghat',
  'Chandameta-Butaria',
  'Chanderi',
  'Chandia',
  'Chandla',
  'Chaurai Khas',
  'Chhatarpur',
  'Chhindwara',
  'Chhota Chhindwara (Gotegaon)',
  'Chichli',
  'Chitrakoot',
  'Churhat',
  'Daboh',
  'Dabra',
  'Damoh',
  'Damua',
  'Datia',
  'Deodara',
  'Deori',
  'Deori',
  'Depalpur',
  'Devendranagar',
  'Devhara',
  'Dewas',
  'Dhamnod',
  'Dhana',
  'Dhanpuri',
  'Dhar',
  'Dharampuri',
  'Dighawani',
  'Diken',
  'Dindori',
  'Dola',
  'Dongar Parasia',
  'Dumar Kachhar',
  'G.C.F Jabalpur',
  'Gadarwara',
  'Gairatganj',
  'Garhakota',
  'Garhi-Malhara',
  'Garoth',
  'Ghansor',
  'Ghuwara',
  'Gogapur',
  'Gohad',
  'Gormi',
  'Govindgarh',
  'Guna',
  'Gurh',
  'Gwalior',
  'Hanumana',
  'Harda',
  'Harpalpur',
  'Harrai',
  'Harsud',
  'Hatod',
  'Hatpipalya',
  'Hatta',
  'Hindoria',
  'Hirapur',
  'Hoshangabad',
  'Ichhawar',
  'Iklehra',
  'Indergarh',
  'Indore',
  'Isagarh',
  'Itarsi',
  'Jabalpur',
  'Jabalpur Cantt.',
  'Jaisinghnagar',
  'Jaithari',
  'Jaitwara',
  'Jamai',
  'Jaora',
  'Jata Chhapar',
  'Jatara',
  'Jawad',
  'Jawar',
  'Jeron Khalsa',
  'Jhabua',
  'Jhundpura',
  'Jiran',
  'Jirapur',
  'Jobat',
  'Joura',
  'Kailaras',
  'Kakarhati',
  'Kali Chhapar',
  'Kanad',
  'Kannod',
  'Kantaphod',
  'Kareli',
  'Karera',
  'Kari',
  'Karnawad',
  'Karrapur',
  'Kasrawad',
  'Katangi',
  'Katangi',
  'Kelhauri(chachai)',
  'Khachrod',
  'Khajuraho',
  'Khand(Bansagar)',
  'Khandwa',
  'Khaniyadhana',
  'Khargapur',
  'Khargone',
  'Khategaon',
  'Khetia',
  'Khilchipur',
  'Khirkiya',
  'Khujner',
  'Khurai',
  'Kolaras',
  'Kotar',
  'Kothi',
  'Kotma',
  'Kukshi',
  'Kumbhraj',
  'Kurwai',
  'Kymore',
  'Lahar',
  'Lakhnadon',
  'Lateri',
  'Laundi',
  'Lidhorakhas',
  'Lodhikheda',
  'Loharda',
  'Machalpur',
  'Maharajpur',
  'Maheshwar',
  'Mahidpur',
  'Maihar',
  'Majholi',
  'Makronia',
  'Maksi',
  'Malaj Khand',
  'Malhargarh',
  'Manasa',
  'Manawar',
  'Mandav',
  'Mandideep',
  'Mandla',
  'Mandleshwar',
  'Mandsaur',
  'Manegaon',
  'Mangawan',
  'Manglaya Sadak',
  'Manpur',
  'Mau',
  'Mauganj',
  'Meghnagar',
  'Mehara Gaon',
  'Mehgaon',
  'Mhow Cantt.',
  'Mhowgaon',
  'Mihona',
  'Mohgaon',
  'Morar Cantt.',
  'Morena',
  'Multai',
  'Mundi',
  'Mungaoli',
  'Murwara (Katni)',
  'Nagda',
  'Nagod',
  'Nagri',
  'Nai Garhi',
  'Nainpur',
  'Nalkheda',
  'Namli',
  'Narayangarh',
  'Narsimhapur',
  'Narsinghgarh',
  'Narwar',
  'Nasrullaganj',
  'Naudhia',
  'Neemuch',
  'Nepanagar',
  'Neuton Chikhli Kalan',
  'Niwari',
  'Nowgaon',
  'Nowrozabad(Khodargama)',
  'O.F.Khamaria',
  'Obedullaganj',
  'Omkareshwar',
  'Orachha',
  'Ordinance Factory Itarsi',
  'Pachmarhi Cantt',
  'Pachore',
  'Pal Chourai',
  'Palda',
  'Palera',
  'Pali',
  'Panagar',
  'Panara',
  'Pandhana',
  'Pandhurna',
  'Panna',
  'Pansemal',
  'Pasan',
  'Patan',
  'Patharia',
  'Pawai',
  'Petlawad',
  'Phuphkalan',
  'Pichhore',
  'Pichhore',
  'Pipariya',
  'Pipariya',
  'Piploda',
  'Piplya Mandi',
  'Pithampur',
  'Polay Kalan',
  'Porsa',
  'Prithvipur',
  'Raghogarh -Vijaypur',
  'Rahatgarh',
  'Raisen',
  'Rajakhedi',
  'Rajgarh',
  'Rajgarh',
  'Rajnagar',
  'Rajpur',
  'Rampur Baghelan',
  'Rampur Naikin',
  'Rampura',
  'Ranapur',
  'Ratangarh',
  'Ratlam',
  'Ratlam Rly. Colony (Ratlam Kasba)',
  'Rau',
  'Rehli',
  'Rehti',
  'Rewa',
  'Runji Gautampura',
  'Sabalgarh',
  'Sagar',
  'Sagar Cantt.',
  'Sailana',
  'Sanawad',
  'Sanchi',
  'Sarangpur',
  'Sardarpur',
  'Sarni',
  'Satai',
  'Satna',
  'Satwas',
  'Sausar',
  'Sawer',
  'Sehore',
  'Semaria',
  'Sendhwa',
  'Seondha',
  'Seoni',
  'Seoni Malwa',
  'Sethia',
  'Shahdol',
  'Shahgarh',
  'Shahpur',
  'Shahpur',
  'Shahpur',
  'Shahpura',
  'Shahpura',
  'Shajapur',
  'Shamgarh',
  'Sheopur',
  'Shivpuri',
  'Shujalpur',
  'Sidhi',
  'Sihora',
  'Singoli',
  'Singrauli',
  'Sinhasa',
  'Sirgora',
  'Sirmaur',
  'Sironj',
  'Sitamau',
  'Sohagpur',
  'Sonkatch',
  'Soyatkalan',
  'Suhagi',
  'Sultanpur',
  'Susner',
  'Suthaliya',
  'Tal',
  'Talen',
  'Tarana',
  'Taricharkalan',
  'Tekanpur',
  'Tendu Kheda',
  'Teonthar',
  'Thandla',
  'Tikamgarh',
  'Timarni',
  'Tirodi',
  'Udaipura',
  'Ujjain',
  'Ukwa',
  'Umaria',
  'Unchehara',
  'Unhel',
  'Vehicle Fac. Jabalpur',
  'Vidisha',
  'Vijayraghavgarh',
  'Wara Seoni',
  'Achalpur',
  'Ahmadnagar',
  'Ahmadnagar',
  'Ahmadpur',
  'Ajra',
  'Akkalkot',
  'Akola',
  'Akot',
  'Alandi',
  'Alibag',
  'Amalner',
  'Ambad',
  'Ambarnath',
  'Ambejogai',
  'Ambivali Tarf Wankhal',
  'Amravati',
  'Anjangaon',
  'Arvi',
  'Ashta',
  'Aurangabad',
  'Aurangabad',
  'Ausa',
  'Babhulgaon',
  'Badlapur',
  'Balapur',
  'Ballarpur',
  'Baramati',
  'Barshi',
  'Basmath',
  'Bhadravati',
  'Bhagur',
  'Bhandara',
  'Bhingar',
  'Bhiwandi',
  'Bhokardan',
  'Bhor',
  'Bhum',
  'Bhusawal',
  'Bid',
  'Biloli',
  'Birwadi',
  'Boisar',
  'Brahmapuri',
  'Budhgaon',
  'Buldana',
  'Chakan',
  'Chalisgaon',
  'Chandrapur',
  'Chandur',
  'Chandur',
  'Chandurbazar',
  'Chicholi',
  'Chikhaldara',
  'Chikhli',
  'Chinchani',
  'Chiplun',
  'Chopda',
  'Dahanu',
  'Dapoli Camp',
  'Darwha',
  'Daryapur Banosa',
  'Dattapur Dhamangaon',
  'Daund',
  'Davlameti',
  'Deglur',
  'Dehu',
  'Dehu Road',
  'Deolali',
  'Deolali Pravara',
  'Deoli',
  'Desaiganj',
  'Deulgaon Raja',
  'Dewhadi',
  'Dharangaon',
  'Dharmabad',
  'Dharur',
  'Dhatau',
  'Dhule',
  'Digdoh',
  'Digras',
  'Dondaicha-Warwade',
  'Dudhani',
  'Durgapur',
  'Dyane',
  'Eklahare',
  'Erandol',
  'Faizpur',
  'Gadchiroli',
  'Gadhinglaj',
  'Gandhinagar',
  'Ganeshpur',
  'Gangakhed',
  'Gangapur',
  'Georai',
  'Ghatanji',
  'Ghoti Budruk',
  'Ghugus',
  'Ghulewadi',
  'Godoli',
  'Gokhivare',
  'Gondiya',
  'Goregaon',
  'Greater Mumbai',
  'Guhagar',
  'Hadgaon',
  'Hinganghat',
  'Hingoli',
  'Hupari',
  'Ichalkaranji',
  'Igatpuri',
  'Indapur',
  'Jalgaon',
  'Jalgaon',
  'Jalgaon (Jamod)',
  'Jalna',
  'Jamkhed',
  'Jawhar',
  'Jaysingpur',
  'Jejuri',
  'Jintur',
  'Junnar',
  'Kabnur',
  'Kagal',
  'Kalamb',
  'Kalambe Turf Thane',
  'Kalameshwar',
  'Kalamnuri',
  'Kalundre',
  'Kalyan-Dombivali',
  'Kamptee',
  'Kamptee',
  'Kandari',
  'Kandhar',
  'Kandri',
  'Kandri',
  'Kanhan (Pipri)',
  'Kankavli',
  'Kannad',
  'Karad',
  'Karanja',
  'Karanje Turf Satara',
  'Karivali',
  'Karjat',
  'Karmala',
  'Kasara Budruk',
  'Katai',
  'Katkar',
  'Katol',
  'Kegaon',
  'Khadkale',
  'Khamgaon',
  'Khapa',
  'Khed',
  'Khed',
  'Kherdi',
  'Khoni',
  'Khopoli',
  'Khuldabad',
  'Kinwat',
  'Kirkee',
  'Kodoli',
  'Kolhapur',
  'Kon',
  'Kondumal',
  'Kopargaon',
  'Kopharad',
  'Korochi',
  'Kudal',
  'Kundalwadi',
  'Kurduvadi',
  'Kurundvad',
  'Kusgaon Budruk',
  'Lanja',
  'Lasalgaon',
  'Latur',
  'Loha',
  'Lonar',
  'Lonavala',
  'Madhavnagar',
  'Mahabaleshwar',
  'Mahad',
  'Mahadula',
  'Maindargi',
  'Malegaon',
  'Malkapur',
  'Malkapur',
  'Malkapur',
  'Malwan',
  'Manadur',
  'Manchar',
  'Mangalvedhe',
  'Mangrulpir',
  'Manjlegaon',
  'Manmad',
  'Manor',
  'Mansar',
  'Manwath',
  'Matheran',
  'Mehkar',
  'Mhasla',
  'Mhaswad',
  'Mira-Bhayandar',
  'Mohpa',
  'Mohpada Alias Wasambe',
  'Morshi',
  'Mowad',
  'Mudkhed',
  'Mukhed',
  'Mul',
  'Murbad',
  'Murgud',
  'Murtijapur',
  'Murud',
  'Murum',
  'Nachane',
  'Nagapur',
  'Nagardeole',
  'Nagothana',
  'Nagpur',
  'Nakoda',
  'Nala Sopara',
  'Naldurg',
  'Nanded-Waghala',
  'Nandgaon',
  'Nandura',
  'Nandurbar',
  'Narkhed',
  'Nashik',
  'Navghar-Manikpur',
  'Navi Mumbai',
  'Navi Mumbai (Panvel, Raigarh)',
  'Nawapur',
  'Neral',
  'Nilanga',
  'Nildoh',
  'Nimbhore Budruk',
  'Osmanabad',
  'Ozar',
  'Pachgaon',
  'Pachora',
  'Padagha',
  'Paithan',
  'Palghar',
  'Pali',
  'Panchgani',
  'Pandharkaoda',
  'Pandharpur',
  'Pandharpur',
  'Panhala',
  'Panvel',
  'Paranda',
  'Parbhani',
  'Parli',
  'Parola',
  'Partur',
  'Pasthal',
  'Patan',
  'Pathardi',
  'Pathri',
  'Patur',
  'Pauni',
  'Pen',
  'Peth Umri',
  'Phaltan',
  'Pimpri Chinchwad',
  'Poladpur',
  'Pulgaon',
  'Pune',
  'Pune',
  'Purna',
  'Purushottamnagar',
  'Pusad',
  'Rahimatpur',
  'Rahta Pimplas',
  'Rahuri',
  'Rajapur',
  'Rajgurunagar (Khed)',
  'Rajur',
  'Rajura',
  'Ramtek',
  'Ratnagiri',
  'Raver',
  'Risod',
  'Roha Ashtami',
  'Sailu',
  'Sandor',
  'Sangamner',
  'Sangli-Miraj & Kupwad',
  'Sangole',
  'Sasti',
  'Sasvad',
  'Satana',
  'Satara',
  'Savda',
  'Savner',
  'Sawantwadi',
  'Sawari Jawharnagar',
  'Shahade',
  'Shahapur',
  'Shegaon',
  'Shelar',
  'Shendurjana',
  'Shirdi',
  'Shirpur-Warwade',
  'Shirur',
  'Shirwal',
  'Shivajinagar',
  'Shivatkar (Nira)',
  'Shrigonda',
  'Shrirampur',
  'Shrirampur(Rural)',
  'Shrivardhan',
  'Sillewada',
  'Sillod',
  'Sindi',
  'Sindi Turf Hindnagar',
  'Sindkhed Raja',
  'Singnapur',
  'Sinnar',
  'Solapur',
  'Sonegaon (Nipani)',
  'Sonpeth',
  'Soyagaon',
  'Surgana',
  'Talegaon Dabhade',
  'Talode',
  'Taloje Panchnad',
  'Tarapur',
  'Tasgaon',
  'Tathavade',
  'Tekadi',
  'Telhara',
  'Thane',
  'Tirora',
  'Totaladoh',
  'Trimbak',
  'Tuljapur',
  'Tumsar',
  'Uchgaon',
  'Udgir',
  'Ulhasnagar',
  'Umarga',
  'Umarkhed',
  'Umarsara',
  'Umbar Pada Nandade',
  'Umred',
  'Umri Pragane Balapur',
  'Uran',
  'Uran Islampur',
  'Utekhol',
  'Vada',
  'Vadgaon',
  'Vadgaon Kasba',
  'Vaijapur',
  'Vanvadi (Sadashivgad)',
  'Vasai',
  'Vasantnagar',
  'Vashind',
  'Vengurla',
  'Virar',
  'Vita',
  'Wadgaon Road',
  'Wadi',
  'Waghapur',
  'Wai',
  'Wajegaon',
  'Walani',
  'Waliv',
  'Wanadongri',
  'Wani',
  'Wardha',
  'Warora',
  'Warud',
  'Washim',
  'Yavatmal',
  'Yawal',
  'Yerkheda',
  'Yevla',
  'Andro',
  'Bishnupur',
  'Heriok',
  'Imphal',
  'Jiribam',
  'Kakching',
  'Kakching Khunou',
  'Khongman',
  'Kumbi',
  'Kwakta',
  'Lamai',
  'Lamjaotongba',
  'Lamshang',
  'Lilong (Imphal West)',
  'Lilong (Thoubal)',
  'Mayang Imphal',
  'Moirang',
  'Moreh',
  'Nambol',
  'Naoriya Pakhanglakpa',
  'Ningthoukhong',
  'Oinam',
  'Porompat',
  'Samurou',
  'Sekmai Bazar',
  'Sikhong Sekmai',
  'Sugnu',
  'Thongkhong Laxmi Bazar',
  'Thoubal',
  'Torban (Khetri Leikai)',
  'Wangjing',
  'Wangoi',
  'Yairipok',
  'Baghmara',
  'Cherapunjee',
  'Jawai',
  'Madanrting',
  'Mairang',
  'Mawlai',
  'Nongmynsong',
  'Nongpoh',
  'Nongstoin',
  'Nongthymmai',
  'Pynthorumkhrah',
  'Resubelpara',
  'Shillong',
  'Shillong Cantt.',
  'Tura',
  'Williamnagar',
  'Aizawl',
  'Bairabi',
  'Biate',
  'Champhai',
  'Darlawn',
  'Hnahthial',
  'Khawhai',
  'Khawzawl',
  'Kolasib',
  'Lengpui',
  'Lunglei',
  'Mamit',
  'N. Vanlaiphai',
  'N.Kawnpui',
  'Saiha',
  'Sairang',
  'Saitual',
  'Serchhip',
  'Thenzawl',
  'Tlabung',
  'Vairengte',
  'Zawlnuam',
  'Chumukedima',
  'Dimapur',
  'Kohima',
  'Mokokchung',
  'Mon',
  'Phek',
  'Tuensang',
  'Wokha',
  'Zunheboto',
  'Anandapur',
  'Anugul',
  'Asika',
  'Athagad',
  'Athmallik',
  'Balagoda (Bolani)',
  'Balangir',
  'Baleshwar',
  'Balimela',
  'Balugaon',
  'Banapur',
  'Bangura',
  'Banki',
  'Barapali',
  'Barbil',
  'Bargarh',
  'Baripada',
  'Basudebpur',
  'Baudhgarh',
  'Belagachhia',
  'Bellaguntha',
  'Belpahar',
  'Bhadrak',
  'Bhanjanagar',
  'Bhawanipatna',
  'Bhuban',
  'Bhubaneswar',
  'Binika',
  'Biramitrapur',
  'Bishama Katek',
  'Brahmapur',
  'Brajarajnagar',
  'Buguda',
  'Burla',
  'Byasanagar',
  'Champua',
  'Chandapur',
  'Chandili',
  'Charibatia',
  'Chhatrapur',
  'Chikiti',
  'Choudwar',
  'Cuttack',
  'Dadhapatna',
  'Daitari',
  'Damanjodi',
  'Debagarh',
  'Deracolliery Township',
  'Dhamanagar',
  'Dhenkanal',
  'Digapahandi',
  'Dungamal',
  'Fertilzer Corporation of IndiaTownship',
  'G. Udayagiri',
  'Ganjam',
  'Ghantapada',
  'Gopalpur',
  'Gudari',
  'Gunupur',
  'Hatibandha',
  'Hinjilicut',
  'Hirakud',
  'Jagatsinghapur',
  'Jajapur',
  'Jalda',
  'Jaleswar',
  'Jatani',
  'Jeypur',
  'Jharsuguda',
  'Jhumpura',
  'Joda',
  'Junagarh',
  'Kamakshyanagar',
  'Kantabanji',
  'Kantilo',
  'Karanjia',
  'Kashinagara',
  'Kavisuryanagar',
  'Kendrapara',
  'Kendujhar',
  'Kesinga',
  'Khaliapali',
  'Khalikote',
  'Khandapada',
  'Khariar',
  'Khariar Road',
  'Khatiguda',
  'Khordha',
  'Kochinda',
  'Kodala',
  'Konark',
  'Koraput',
  'Kotpad',
  'Lattikata',
  'Makundapur',
  'Malkangiri',
  'Mukhiguda',
  'Nabarangapur',
  'NALCO',
  'Nayagarh',
  'Nilagiri',
  'Nimapada',
  'Nuapatna',
  'OCL Industrialship',
  'Padmapur',
  'Panposh',
  'Paradip',
  'Parlakhemundi',
  'Patnagarh',
  'Pattamundai',
  'Phulabani',
  'Pipili',
  'Polasara',
  'Pratapsasan',
  'Puri',
  'Purusottampur',
  'Rairangpur',
  'Rajagangapur',
  'Rambha',
  'Raurkela',
  'Raurkela Industrialship',
  'Rayagada',
  'Redhakhol',
  'Remuna',
  'Rengali Dam Projectship',
  'Sambalpur',
  'Sonapur',
  'Soro',
  'Sunabeda',
  'Sundargarh',
  'Surada',
  'Talcher',
  'Talcher Thermal Power Station Township',
  'Tarbha',
  'Tensa',
  'Titlagarh',
  'Udala',
  'Umarkote',
  'Karaikal',
  'Kurumbapet',
  'Mahe',
  'Ozhukarai',
  'Pondicherry',
  'Yanam',
  'A.Thirumuruganpoondi',
  'A.Vellalapatti',
  'Abiramam',
  'Achampudur',
  'Acharapakkam',
  'Acharipallam',
  'Achipatti',
  'Adikaratti',
  'Adiramapattinam',
  'Aduthurai alias Maruthuvakudi',
  'Agaram',
  'Agastheeswaram',
  'Alagappapuram',
  'Alampalayam',
  'Alandur',
  'Alanganallur',
  'Alangayam',
  'Alangudi',
  'Alangulam',
  'Alangulam',
  'Alanthurai',
  'Alapakkam',
  'Allapuram',
  'Alur',
  'Alwarkurichi',
  'Alwarthirunagiri',
  'Ambasamudram',
  'Ambattur',
  'Ambur',
  'Ammainaickanur',
  'Ammapettai',
  'Ammapettai',
  'Ammavarikuppam',
  'Ammoor',
  'Anaimalai',
  'Anaiyur',
  'Anaiyur',
  'Anakaputhur',
  'Ananthapuram',
  'Andipalayam',
  'Andipatti Jakkampatti',
  'Anjugramam',
  'Annamalai Nagar',
  'Annavasal',
  'Annur',
  'Anthiyur',
  'Appakudal',
  'Arachalur',
  'Arakandanallur',
  'Arakonam',
  'Aralvaimozhi',
  'Arani',
  'Arani',
  'Aranthangi',
  'Arasiramani',
  'Aravakurichi',
  'Aravankad',
  'Arcot',
  'Arimalam',
  'Ariyalur',
  'Ariyappampalayam',
  'Ariyur',
  'Arumanai',
  'Arumbavur',
  'Arumuganeri',
  'Aruppukkottai',
  'Ashokapuram',
  'Athani',
  'Athanur',
  'Athimarapatti',
  'Athipattu',
  'Athur',
  'Athur',
  'Attayampatti',
  'Attur',
  'Avadattur',
  'Avadi',
  'Avalpoondurai',
  'Avanashi',
  'Avaniapuram',
  'Ayakudi',
  'Aygudi',
  'Ayothiapattinam',
  'Ayyalur',
  'Ayyampalayam',
  'Ayyampettai',
  'Ayyampettai',
  'Azhagiapandiapuram',
  'B. Mallapuram',
  'B. Meenakshipuram',
  'Balakrishnampatti',
  'Balakrishnapuram',
  'Balapallam',
  'Balasamudram',
  'Bargur',
  'Batlagundu',
  'Belur',
  'Bhavani',
  'Bhavanisagar',
  'Bhuvanagiri',
  'Bikketti',
  'Bodinayakkanur',
  'Boothapandi',
  'Boothipuram',
  'Brahmana Periya-Agraharam',
  'Chengalpattu',
  'Chengam',
  'Chennai',
  'Chennasamudram',
  'Chennimalai',
  'Cheranmadevi',
  'Chetpet',
  'Chettiarpatti',
  'Chettipalayam',
  'Chettipalayam',
  'Chettithangal',
  'Chidambaram',
  'Chinna Anuppanadi',
  'Chinnakkampalayam',
  'Chinnalapatti',
  'Chinnamanur',
  'Chinnampalayam',
  'Chinnasalem',
  'Chinnasekkadu',
  'Chinnavedampatti',
  'Chithode',
  'Chitlapakkam',
  'Cholapuram',
  'Coimbatore',
  'Coonoor',
  'Courtalam',
  'Cuddalore',
  'Dalavaipatti',
  'Denkanikottai',
  'Desur',
  'Devadanapatti',
  'Devakottai',
  'Devanangurichi',
  'Devarshola',
  'Dhalavoipuram',
  'Dhali',
  'Dhaliyur',
  'Dharapadavedu',
  'Dharapuram',
  'Dharasuram',
  'Dharmapuri',
  'Dindigul',
  'Dusi',
  'Edaganasalai',
  'Edaikodu',
  'Edakalinadu',
  'Edappadi',
  'Elathur',
  'Elayirampannai',
  'Elumalai',
  'Eral',
  'Eraniel',
  'Eriodu',
  'Erode',
  'Erumaipatti',
  'Eruvadi',
  'Ethapur',
  'Ettayapuram',
  'Ettimadai',
  'Ezhudesam',
  'Ganapathipuram',
  'Gandhi Nagar(Katpadi Ext.)',
  'Gangaikondan',
  'Gangavalli',
  'Ganguvarpatti',
  'Gingee',
  'Gobichettipalayam',
  'Gopalasamudram',
  'Goundampalayam',
  'Gudalur',
  'Gudalur',
  'Gudalur',
  'Gudiyatham',
  'Gummidipoondi',
  'Hanumanthampatti',
  'Harur',
  'Harveypatti',
  'Highways',
  'Hosur',
  'Hubbathala',
  'Huligal',
  'Idikarai',
  'Ilampillai',
  'Ilanji',
  'Iluppaiyurani',
  'Iluppur',
  'Inam Karur',
  'Injambakkam',
  'Irugur',
  'Jaffrabad',
  'Jagathala',
  'Jalakandapuram',
  'Jalladiampet',
  'Jambai',
  'Jayankondam',
  'Jolarpet',
  'Kadambur',
  'Kadathur',
  'Kadayal',
  'Kadayampatti',
  'Kadayanallur',
  'Kalakkad',
  'Kalambur',
  'Kalapatti',
  'Kalappanaickenpatti',
  'Kalavai',
  'Kalinjur',
  'Kaliyakkavilai',
  'Kalladaikurichi',
  'Kallakkurichi',
  'Kallakudi',
  'Kallukuttam',
  'Kalugumalai',
  'Kamayagoundanpatti',
  'Kambainallur',
  'Kambam',
  'Kamuthi',
  'Kanadukathan',
  'Kanakkampalayam',
  'Kanam',
  'Kancheepuram',
  'Kandanur',
  'Kangayampalayam',
  'Kangeyam',
  'Kangeyanallur',
  'Kaniyur',
  'Kanjikoil',
  'Kannadendal',
  'Kannamangalam',
  'Kannampalayam',
  'Kannankurichi',
  'Kannivadi',
  'Kannivadi',
  'Kanniyakumari',
  'Kappiyarai',
  'Karaikkudi',
  'Karamadai',
  'Karambakkam',
  'Karambakkudi',
  'Kariamangalam',
  'Kariapatti',
  'Karugampattur',
  'Karumandi Chellipalayam',
  'Karumathampatti',
  'Karungal',
  'Karunguzhi',
  'Karuppur',
  'Karur',
  'Kasipalayam (E)',
  'Kasipalayam (G)',
  'Kathujuganapalli',
  'Katpadi',
  'Kattivakkam',
  'Kattumannarkoil',
  'Kattuputhur',
  'Kaveripakkam',
  'Kaveripattinam',
  'Kayalpattinam',
  'Kayatharu',
  'Keelakarai',
  'Keeramangalam',
  'Keeranur',
  'Keeranur',
  'Keeripatti',
  'Keezhapavur',
  'Kelamangalam',
  'Kembainaickenpalayam',
  'Kethi',
  'Kilampadi',
  'Kilkulam',
  'Kilkunda',
  'Killiyur',
  'Killlai',
  'Kilpennathur',
  'Kilvelur',
  'Kinathukadavu',
  'Kodaikanal',
  'Kodavasal',
  'Kodumudi',
  'Kolachal',
  'Kolappalur',
  'Kolathupalayam',
  'Kolathur',
  'Kollankodu',
  'Kollankoil',
  'Komaralingam',
  'Kombai',
  'Konavattam',
  'Kondalampatti',
  'Konganapuram',
  'Kooraikundu',
  'Koothappar',
  'Koradacheri',
  'Kotagiri',
  'Kothinallur',
  'Kottaiyur',
  'Kottakuppam',
  'Kottaram',
  'Kottivakkam',
  'Kottur',
  'Kouthanallur',
  'Kovilpatti',
  'Krishnagiri',
  'Krishnarayapuram',
  'Krishnasamudram',
  'Kuchanur',
  'Kuhalur',
  'Kulasekarapuram',
  'Kulithalai',
  'Kumarapalayam',
  'Kumarapuram',
  'Kumbakonam',
  'Kundrathur',
  'Kuniyamuthur',
  'Kunnathur',
  'Kurichi',
  'Kurinjipadi',
  'Kurudampalayam',
  'Kurumbalur',
  'Kuthalam',
  'Kuzhithurai',
  'Labbaikudikadu',
  'Lakkampatti',
  'Lalgudi',
  'Lalpet',
  'Llayangudi',
  'Madambakkam',
  'Madathukulam',
  'Madavaram',
  'Madippakkam',
  'Madukkarai',
  'Madukkur',
  'Madurai',
  'Maduranthakam',
  'Maduravoyal',
  'Mallamooppampatti',
  'Mallankinaru',
  'Mallasamudram',
  'Mallur',
  'Mamallapuram',
  'Mamsapuram',
  'Manachanallur',
  'Manali',
  'Manalmedu',
  'Manalurpet',
  'Manamadurai',
  'Manapakkam',
  'Manapparai',
  'Manavalakurichi',
  'Mandaikadu',
  'Mandapam',
  'Mangadu',
  'Mangalam',
  'Mangalampet',
  'Manimutharu',
  'Mannargudi',
  'Mappilaiurani',
  'Maraimalainagar',
  'Marakkanam',
  'Maramangalathupatti',
  'Marandahalli',
  'Markayankottai',
  'Marudur',
  'Marungur',
  'Mathigiri',
  'Mayiladuthurai',
  'Mecheri',
  'Meenambakkam',
  'Melacheval',
  'Melachokkanathapuram',
  'Melagaram',
  'Melamadai',
  'Melamaiyur',
  'Melathiruppanthuruthi',
  'Melattur',
  'Melpattampakkam',
  'Melur',
  'Melvisharam',
  'Mettupalayam',
  'Mettupalayam',
  'Mettur',
  'Minjur',
  'Modakurichi',
  'Mohanur',
  'Moolakaraipatti',
  'Moovarasampettai',
  'Mopperipalayam',
  'Mudukulathur',
  'Mukasipidariyur',
  'Mukkudal',
  'Mulagumudu',
  'Mulanur',
  'Muruganpalayam',
  'Musiri',
  'Muthupet',
  'Muthur',
  'Muttayyapuram',
  'Myladi',
  'Naduvattam',
  'Nagapattinam',
  'Nagavakulam',
  'Nagercoil',
  'Nagojanahalli',
  'Nallampatti',
  'Nallur',
  'Namagiripettai',
  'Namakkal',
  'Nambiyur',
  'Nandambakkam',
  'Nandivaram-Guduvancheri',
  'Nangavalli',
  'Nangavaram',
  'Nanguneri',
  'Nanjikottai',
  'Nannilam',
  'Naranammalpuram',
  'Naranapuram',
  'Narasimhanaickenpalayam',
  'Narasingapuram',
  'Narasingapuram',
  'Naravarikuppam',
  'Nasiyanur',
  'Natham',
  'Nathampannai',
  'Natrampalli',
  'Nattapettai',
  'Nattarasankottai',
  'Navalpattu',
  'Nazerath',
  'Needamangalam',
  'Neelagiri',
  'Neelankarai',
  'Neikkarapatti',
  'Neiyyur',
  'Nellikuppam',
  'Nelliyalam',
  'Nemili',
  'Neripperichal',
  'Nerkunram',
  'Nerkuppai',
  'Nerunjipettai',
  'Neykkarappatti',
  'Neyveli',
  'Nilakkottai',
  "O' Valley",
  'Odaipatti',
  'Odaiyakulam',
  'Oddanchatram',
  'Odugathur',
  'Oggiyamduraipakkam',
  'Olagadam',
  'Omalur',
  'Orathanadu (Mukthambalpuram)',
  'Othakadai',
  'Othakalmandapam',
  'Ottapparai',
  'P. J. Cholapuram',
  'P.Mettupalayam',
  'P.N.Patti',
  'Pacode',
  'Padaiveedu',
  'Padianallur',
  'Padirikuppam',
  'Padmanabhapuram',
  'Palaganangudy',
  'Palakkodu',
  'Palamedu',
  'Palani',
  'Palani Chettipatti',
  'Palavakkam',
  'Palavansathu',
  'Palayam',
  'Palladam',
  'Pallapalayam',
  'Pallapalayam',
  'Pallapatti',
  'Pallapatti',
  'Pallapatti',
  'Pallathur',
  'Pallavaram',
  'Pallikaranai',
  'Pallikonda',
  'Pallipalayam',
  'Pallipalayam Agraharam',
  'Pallipattu',
  'Pammal',
  'Panagudi',
  'Panaimarathupatti',
  'Panapakkam',
  'Panboli',
  'Pandamangalam',
  'Pannaikadu',
  'Pannaipuram',
  'Panruti',
  'Papanasam',
  'Pappankurichi',
  'Papparapatti',
  'Papparapatti',
  'Pappireddipatti',
  'Paramakudi',
  'Paramathi',
  'Parangipettai',
  'Paravai',
  'Pasur',
  'Pathamadai',
  'Pattinam',
  'Pattiveeranpatti',
  'Pattukkottai',
  'Pazhugal',
  'Peerkankaranai',
  'Pennadam',
  'Pennagaram',
  'Pennathur',
  'Peraiyur',
  'Peralam',
  'Perambalur',
  'Peranamallur',
  'Peravurani',
  'Periya Negamam',
  'Periyakodiveri',
  'Periyakulam',
  'Periyanaickenpalayam',
  'Periyapatti',
  'Periyasemur',
  'Pernampattu',
  'Perumagalur',
  'Perumandi',
  'Perumuchi',
  'Perundurai',
  'Perungalathur',
  'Perungudi',
  'Perungulam',
  'Perur',
  'Pethampalayam',
  'Pethanaickenpalayam',
  'Pillanallur',
  'Polichalur',
  'Pollachi',
  'Polur',
  'Ponmani',
  'Ponnamaravathi',
  'Ponnampatti',
  'Ponneri',
  'Poolambadi',
  'Poolampatti',
  'Pooluvapatti',
  'Poonamallee',
  'Porur',
  'Pothanur',
  'Pothatturpettai',
  'Pudukadai',
  'Pudukkottai',
  'Pudukkottai',
  'Pudupalaiyam Aghraharam',
  'Pudupalayam',
  'Pudupatti',
  'Pudupatti',
  'Pudupattinam',
  'Pudur (S)',
  'Puduvayal',
  'Puliyankudi',
  'Puliyur',
  'Pullampadi',
  'Punjai Thottakurichi',
  'Punjaipugalur',
  'Punjaipuliampatti',
  'Puthalam',
  'Puvalur',
  'Puzhal',
  'Puzhithivakkam (Ullagaram)',
  'R.Pudupatti',
  'R.S.Mangalam',
  'Rajapalayam',
  'Ramanathapuram',
  'Ramapuram',
  'Rameswaram',
  'Ranipettai',
  'Rasipuram',
  'Rayagiri',
  'Reethapuram',
  'Rosalpatti',
  'Rudravathi',
  'S. Kannanur',
  'S.Kodikulam',
  'S.Nallur',
  'Salangapalayam',
  'Salem',
  'Samalapuram',
  'Samathur',
  'Sambavar Vadagarai',
  'Sankaramanallur',
  'Sankarankoil',
  'Sankarapuram',
  'Sankari',
  'Sankarnagar',
  'Saravanampatti',
  'Sarcarsamakulam',
  'Sathankulam',
  'Sathiyavijayanagaram',
  'Sathuvachari',
  'Sathyamangalam',
  'Sattur',
  'Sayalgudi',
  'Sayapuram',
  'Seerapalli',
  'Seevur',
  'Seithur',
  'Sembakkam',
  'Semmipalayam',
  'Senthamangalam',
  'Sentharapatti',
  'Senur',
  'Sethiathoppu',
  'Sevilimedu',
  'Sevugampatti',
  'Shenbakkam',
  'Shenkottai',
  'Sholavandan',
  'Sholinganallur',
  'Sholingur',
  'Sholur',
  'Sikkarayapuram',
  'Singampuneri',
  'Singaperumalkoil',
  'Sirkali',
  'Sirugamani',
  'Sirumugai',
  'Sithayankottai',
  'Sithurajapuram',
  'Sivaganga',
  'Sivagiri',
  'Sivagiri',
  'Sivakasi',
  'Sivanthipuram',
  'Srimushnam',
  'Sriperumbudur',
  'Sriramapuram',
  'Srivaikuntam',
  'Srivilliputhur',
  'St.Thomas Mount-cum-Pallavaram',
  'Suchindram',
  'Suleeswaranpatti',
  'Sulur',
  'Sundarapandiam',
  'Sundarapandiapuram',
  'Surampatti',
  'Surandai',
  'Suriyampalayam',
  'Swamimalai',
  'T.Kallupatti',
  'Tambaram',
  'Tayilupatti',
  'Tenkasi',
  'Thadikombu',
  'Thakkolam',
  'Thalainayar',
  'Thalakudi',
  'Thamaraikulam',
  'Thammampatti',
  'Thanjavur',
  'Thanthoni',
  'Tharamangalam',
  'Tharangambadi',
  'Thathaiyangarpet',
  'Thedavur',
  'Thenambakkam',
  'Thengampudur',
  'Theni Allinagaram',
  'Thenkarai',
  'Thenkarai',
  'Thenthamaraikulam',
  'Thenthiruperai',
  'Thesur',
  'Thevaram',
  'Thevur',
  'Thiagadurgam',
  'Thingalnagar',
  'Thirukarungudi',
  'Thirukattupalli',
  'Thirumalayampalayam',
  'Thirumangalam',
  'Thirumazhisai',
  'Thirunagar',
  'Thirunageswaram',
  'Thiruneermalai',
  'Thirunindravur',
  'Thiruparankundram',
  'Thiruparappu',
  'Thiruporur',
  'Thiruppanandal',
  'Thirupuvanam',
  'Thirupuvanam',
  'Thiruthangal',
  'Thiruthuraipoondi',
  'Thiruvaiyaru',
  'Thiruvalam',
  'Thiruvallur',
  'Thiruvarur',
  'Thiruvattaru',
  'Thiruvenkatam',
  'Thiruvennainallur',
  'Thiruverumbur',
  'Thiruvidaimarudur',
  'Thiruvithankodu',
  'Thisayanvilai',
  'Thittacheri',
  'Thondamuthur',
  'Thondi',
  'Thoothukkudi',
  'Thorapadi',
  'Thorapadi',
  'Thottipalayam',
  'Thottiyam',
  'Thudiyalur',
  'Thuraiyur',
  'Thuthipattu',
  'Thuvakudi',
  'Timiri',
  'Tindivanam',
  'Tiruchendur',
  'Tiruchengode',
  'Tiruchirappalli',
  'Tirukalukundram',
  'Tirukkoyilur',
  'Tirunelveli',
  'Tirupathur',
  'Tirupathur',
  'Tiruppur',
  'Tirusulam',
  'Tiruttani',
  'Tiruvannamalai',
  'Tiruverkadu',
  'Tiruvethipuram',
  'Tiruvottiyur',
  'Tittakudi',
  'TNPL Pugalur',
  'Udangudi',
  'Udayarpalayam',
  'Udhagamandalam',
  'Udumalaipettai',
  'Ullur',
  'Ulundurpettai',
  'Unjalaur',
  'Unnamalaikadai',
  'Uppidamangalam',
  'Uppiliapuram',
  'Urapakkam',
  'Usilampatti',
  'Uthamapalayam',
  'Uthangarai',
  'Uthayendram',
  'Uthiramerur',
  'Uthukkottai',
  'Uthukuli',
  'V. Pudur',
  'Vadakarai Keezhpadugai',
  'Vadakkanandal',
  'Vadakkuvalliyur',
  'Vadalur',
  'Vadamadurai',
  'Vadavalli',
  'Vadipatti',
  'Vadugapatti',
  'Vadugapatti',
  'Vaitheeswarankoil',
  'Valangaiman',
  'Valasaravakkam',
  'Valavanur',
  'Vallam',
  'Valparai',
  'Valvaithankoshtam',
  'Vanavasi',
  'Vandalur',
  'Vandavasi',
  'Vandiyur',
  'Vaniputhur',
  'Vaniyambadi',
  'Varadarajanpettai',
  'Vasudevanallur',
  'Vathirairuppu',
  'Vazhapadi',
  'Vedapatti',
  'Vedaranyam',
  'Vedasandur',
  'Veeraganur',
  'Veerakeralam',
  'Veerakkalpudur',
  'Veerapandi',
  'Veerapandi',
  'Veerapandi',
  'Veerappanchatram',
  'Veeravanallur',
  'Velampalayam',
  'Velankanni',
  'Vellakinar',
  'Vellakoil',
  'Vellalur',
  'Vellimalai',
  'Vellore',
  'Vellottamparappu',
  'Velur',
  'Vengampudur',
  'Vengathur',
  'Venkarai',
  'Vennanthur',
  'Veppathur',
  'Verkilambi',
  'Vettaikaranpudur',
  'Vettavalam',
  'Vijayapuri',
  'Vikramasingapuram',
  'Vikravandi',
  'Vilangudi',
  'Vilankurichi',
  'Vilapakkam',
  'Vilathikulam',
  'Vilavur',
  'Villukuri',
  'Viluppuram',
  'Virudhachalam',
  'Virudhunagar',
  'Virupakshipuram',
  'Viswanatham',
  'Walajabad',
  'Walajapet',
  'Wellington',
  'Zamin Uthukuli',
  'Abohar',
  'Adampur',
  'Ahmedgarh',
  'Ajnala',
  'Akalgarh',
  'Alawalpur',
  'Amloh',
  'Amritsar',
  'Amritsar Cantt.',
  'Anandpur Sahib',
  'Badhni Kalan',
  'Bagha Purana',
  'Balachaur',
  'Banaur',
  'Banga',
  'Baretta',
  'Bariwala',
  'Barnala',
  'Bassi Pathana',
  'Batala',
  'Bathinda',
  'Begowal',
  'Bhabat',
  'Bhadaur',
  'Bhankharpur',
  'Bharoli Kalan',
  'Bhawanigarh',
  'Bhikhi',
  'Bhikhiwind',
  'Bhisiana',
  'Bhogpur',
  'Bhucho Mandi',
  'Bhulath',
  'Budha Theh',
  'Budhlada',
  'Cheema',
  'Chohal',
  'Daroha',
  'Dasua',
  'Daulatpur',
  'Dera Baba Nanak',
  'Dera Bassi',
  'Dhanaula',
  'Dharamkot',
  'Dhariwal',
  'Dhilwan',
  'Dhuri',
  'Dina Nagar',
  'Dirba',
  'Faridkot',
  'Fatehgarh Churian',
  'Fazilka',
  'Fiozpur',
  'Firozpur Cantt.',
  'Gardhiwala',
  'Garhshanker',
  'Ghagga',
  'Ghanaur',
  'Gidderbaha',
  'Gobindgarh',
  'Goniana',
  'Goraya',
  'Gurdaspur',
  'Guru Har Sahai',
  'Hajipur',
  'Handiaya',
  'Hariana',
  'Hoshiarpur',
  'Hussainpur',
  'Jagraon',
  'Jaitu',
  'Jalalabad',
  'Jalandhar',
  'Jalandhar Cantt.',
  'Jandiala',
  'Jandiala',
  'Jugial',
  'Kalanaur',
  'Kapurthala',
  'Karoran',
  'Kartarpur',
  'Khamanon',
  'Khanauri',
  'Khanna',
  'Kharar',
  'Khem Karan',
  'Kot Fatta',
  'Kot Kapura',
  'Kurali',
  'Lehragaga',
  'Lodhian Khas',
  'Longowal',
  'Ludhiana',
  'Machhiwara',
  'Mahilpur',
  'Majitha',
  'Makhu',
  'Malerkotla',
  'Maloud',
  'Malout',
  'Mansa',
  'Maur',
  'Moga',
  'Moonak',
  'Morinda',
  'Mukerian',
  'Muktsar',
  'Mullanpur Dakha',
  'Mullanpur- Garibdas',
  'Nabha',
  'Nakodar',
  'Nangal',
  'Nawanshahr',
  'Nehon',
  'Noor Mahal',
  'Pathankot',
  'Patiala',
  'Patti',
  'Pattran',
  'Payal',
  'Phagwara',
  'Phillaur',
  'Qadian',
  'Rahon',
  'Raikot',
  'Rajasansi',
  'Rajpura',
  'Raman',
  'Ramdass',
  'Rampura Phul',
  'Rayya',
  'Rupnagar',
  'Rurki Kasba',
  'S.A.S. Nagar (Mohali)',
  'Sahnewal',
  'Samana',
  'Samrala',
  'Sanaur',
  'Sangat',
  'Sangrur',
  'Sansarpur',
  'Sardulgarh',
  'Shahkot',
  'Shamchaurasi',
  'Shekhpura',
  'Sirhind -Fategarh',
  'Sri Hargobindpur',
  'Sujanpur',
  'Sultanpur Lodhi',
  'Sunam',
  'Talwandi Bhai',
  'Talwara',
  'Tappa',
  'Tarn Taran',
  'Urmar Tanda',
  'Zira',
  'Zirakpur',
  '1 SGM',
  '3 STR',
  'Abu Road',
  'Ajmer',
  'Aklera',
  'Alwar',
  'Amet',
  'Antah',
  'Anupgarh',
  'Asind',
  'Baggar',
  'Bagru',
  'Bakani',
  'Bali',
  'Balotra',
  'Bandikui',
  'Banswara',
  'Baran',
  'Bari',
  'Bari Sadri',
  'Barmer',
  'Basni Belima',
  'Bayana',
  'Beawar',
  'Beejoliya Kalan',
  'Begun',
  'Behror',
  'Bhadra',
  'Bhalariya',
  'Bharatpur',
  'Bhawani Mandi',
  'Bhilwara',
  'Bhinder',
  'Bhinmal',
  'Bhiwadi',
  'Bhusawar',
  'Bidasar',
  'Bikaner',
  'Bilara',
  'Bissau',
  'Budhpura',
  'Bundi',
  'Chaksu',
  'Chechat',
  'Chhabra',
  'Chhapar',
  'Chhipabarod',
  'Chhoti Sadri',
  'Chirawa',
  'Chittaurgarh',
  'Chomu',
  'Churu',
  'Dariba',
  'Dausa',
  'Deeg',
  'Deoli',
  'Deshnoke',
  'Devgarh',
  'Dhariawad',
  'Dhaulpur',
  'Didwana',
  'Dungargarh',
  'Dungarpur',
  'Fatehnagar',
  'Fatehpur',
  'Gajsinghpur',
  'Galiakot',
  'Ganganagar',
  'Gangapur',
  'Gangapur City',
  'Goredi Chancha',
  'Gothra',
  'Govindgarh',
  'Gulabpura',
  'Hanumangarh',
  'Hindaun',
  'Indragarh',
  'Jahazpur',
  'Jaipur',
  'Jaisalmer',
  'Jaitaran',
  'Jalor',
  'Jhalawar',
  'Jhalrapatan',
  'Jhunjhunun',
  'Jobner',
  'Jodhpur',
  'Kaithoon',
  'Kaman',
  'Kanor',
  'Kapasan',
  'Kaprain',
  'Karanpur',
  'Karauli',
  'Kekri',
  'Keshoraipatan',
  'Kesrisinghpur',
  'Khairthal',
  'Khandela',
  'Kherli',
  'Kherliganj',
  'Kherwara Chhaoni',
  'Khetri',
  'Kiranipura',
  'Kishangarh',
  'Kishangarh',
  'Kishangarh Renwal',
  'Kolvi Mandi Rajendra pura',
  'Kota',
  'Kotputli',
  'Kuchaman City',
  'Kuchera',
  'Kumbhkot',
  'Kumher',
  'Kushalgarh',
  'Lachhmangarh',
  'Ladnu',
  'Lakheri',
  'Lalsot',
  'Losal',
  'Mahu Kalan',
  'Mahwa',
  'Makrana',
  'Malpura',
  'Mandalgarh',
  'Mandawa',
  'Mandawar',
  'Mangrol',
  'Manohar Thana',
  'Marwar Junction',
  'Merta City',
  'Modak',
  'Mount Abu',
  'Mukandgarh',
  'Mundwa',
  'Nadbai',
  'Nagar',
  'Nagaur',
  'Nainwa',
  'Nasirabad',
  'Nathdwara',
  'Nawa',
  'Nawalgarh',
  'Neem-Ka-Thana',
  'Newa Talai',
  'Nimbahera',
  'Niwai',
  'Nohar',
  'Nokha',
  'Padampur',
  'Pali',
  'Parbatsar',
  'Partapur',
  'Phalna',
  'Phalodi',
  'Phulera',
  'Pilani',
  'Pilibanga',
  'Pindwara',
  'Piparcity',
  'Pirawa',
  'Pokaran',
  'Pratapgarh',
  'Pushkar',
  'Raisinghnagar',
  'Rajakhera',
  'Rajaldesar',
  'Rajgarh',
  'Rajgarh',
  'Rajsamand',
  'Ramganj Mandi',
  'Ramgarh',
  'Rani',
  'Ratangarh',
  'Ratannagar',
  'Rawatbhata',
  'Rawatsar',
  'Reengus',
  'Rikhabdeo',
  'Sadri',
  'Sadulshahar',
  'Sagwara',
  'Salumbar',
  'Sambhar',
  'Sanchore',
  'Sangaria',
  'Sangod',
  'Sardarshahar',
  'Sarwar',
  'Satalkheri',
  'Sawai Madhopur',
  'Shahpura',
  'Shahpura',
  'Sheoganj',
  'Sikar',
  'Sirohi',
  'Sogariya',
  'Sojat',
  'Sojat Road',
  'Sri Madhopur',
  'Sujangarh',
  'Suket',
  'Sumerpur',
  'Surajgarh',
  'Suratgarh',
  'Takhatgarh',
  'Taranagar',
  'Tijara',
  'Todabhim',
  'Todaraisingh',
  'Todra',
  'Tonk',
  'Udaipur',
  'Udaipurwati',
  'Udpura',
  'Uniara',
  'Vanasthali',
  'Vidyavihar',
  'Vijainagar',
  'Vijainagar',
  'Viratnagar',
  'Weir',
  'Gangtok',
  'Gyalshing',
  'Jorethang',
  'Mangan',
  'Namchi',
  'Nayabazar',
  'Rangpo',
  'Singtam',
  'Upper Tadong',
  'Agartala',
  'Amarpur',
  'Ambassa',
  'Badharghat',
  'Belonia',
  'Dharmanagar',
  'Gakulnagar',
  'Gandhigram',
  'Indranagar',
  'Jogendranagar',
  'Kailasahar',
  'Kamalpur',
  'Kanchanpur',
  'Khowai',
  'Kumarghat',
  'Kunjaban',
  'Narsingarh',
  'Pratapgarh',
  'Ranirbazar',
  'Sabroom',
  'Sonamura',
  'Teliamura',
  'Udaipur',
  'Achhalda',
  'Achhnera',
  'Adari',
  'Afzalgarh',
  'Agarwal Mandi',
  'Agra',
  'Agra',
  'Ahraura',
  'Ailum',
  'Air Force Area',
  'Ajhuwa',
  'Akbarpur',
  'Aliganj',
  'Aligarh',
  'Allahabad',
  'Allahabad',
  'Allahganj',
  'Allapur',
  'Amanpur',
  'Ambehta',
  'Amethi',
  'Amethi',
  'Amila',
  'Amilo',
  'Aminagar Sarai',
  'Aminagar Urf Bhurbaral',
  'Amraudha',
  'Anandnagar',
  'Anpara',
  'Antu',
  'Anupshahr',
  'Aonla',
  'Armapur Estate',
  'Ashrafpur Kichhauchha',
  'Atarra',
  'Atasu',
  'Atrauli',
  'Atraulia',
  'Auraiya',
  'Aurangabad',
  'Aurangabad Bangar',
  'Auras',
  'Awagarh',
  'Ayodhya',
  'Azamgarh',
  'Azizpur',
  'Azmatgarh',
  'Babarpur Ajitmal',
  'Baberu',
  'Babina',
  'Babrala',
  'Babugarh',
  'Bachhraon',
  'Bachhrawan',
  'Bad',
  'Baghpat',
  'Bah',
  'Bahadurganj',
  'Baheri',
  'Bahjoi',
  'Bahraich',
  'Bahsuma',
  'Bahuwa',
  'Bajna',
  'Bakewar',
  'Bakiabad',
  'Baldeo',
  'Ballia',
  'Balrampur',
  'Banat',
  'Banda',
  'Bangarmau',
  'Banki',
  'Bansdih',
  'Bansgaon',
  'Baragaon',
  'Baragaon',
  'Baraut',
  'Bareilly',
  'Bareilly',
  'Barhalganj',
  'Barkhera',
  'Barsana',
  'Barua Sagar',
  'Barwar',
  'Basti',
  'Begumabad Budhana',
  'Behta Hajipur',
  'Bela Pratapgarh',
  'Belthara Road',
  'Beniganj',
  'Beswan',
  'Bewar',
  'Bhadarsa',
  'Bhagwant Nagar',
  'Bharatganj',
  'Bhargain',
  'Bharthana',
  'Bharuhana',
  'Bharwari',
  'Bhatni Bazar',
  'Bhatpar Rani',
  'Bhawan Bahadur Nagar',
  'Bhinga',
  'Bhogaon',
  'Bhojpur Dharampur',
  'Bhokarhedi',
  'Bidhuna',
  'Bighapur',
  'Bijnor',
  'Bijpur',
  'Bikapur',
  'Bilari',
  'Bilariaganj',
  'Bilaspur',
  'Bilaspur',
  'Bilgram',
  'Bilhaur',
  'Bilram',
  'Bilsanda',
  'Bilsi',
  'Bindki',
  'Bisalpur',
  'Bisanda Buzurg',
  'Bisauli',
  'Bisharatganj',
  'Bisokhar',
  'Biswan',
  'Bithoor',
  'Budaun',
  'Budhana',
  'Bugrasi',
  'Bulandshahr',
  'Chail',
  'Chak Imam Ali',
  'Chakeri',
  'Chakia',
  'Chandauli',
  'Chandausi',
  'Chandpur',
  'Charkhari',
  'Charthaval',
  'Chaumuhan',
  'Chhaprauli',
  'Chharra Rafatpur',
  'Chhata',
  'Chhatari',
  'Chhibramau',
  'Chhutmalpur',
  'Chilkana Sultanpur',
  'Chirgaon',
  'Chitbara Gaon',
  'Chitrakoot Dham (Karwi)',
  'Chopan',
  'Choubepur Kalan',
  'Chunar',
  'Churk Ghurma',
  'Colonelganj',
  'Dadri',
  'Dalmau',
  'Dankaur',
  'Dariyabad',
  'Dasna',
  'Dataganj',
  'Daurala',
  'Dayalbagh',
  'Deoband',
  'Deoranian',
  'Deoria',
  'Dewa',
  'Dhampur',
  'Dhanauha',
  'Dhanauli',
  'Dhanaura',
  'Dharoti Khurd',
  'Dhaura Tanda',
  'Dhaurehra',
  'Dibai',
  'Dibiyapur',
  'Dildarnagar Fatehpur Bazar',
  'Doghat',
  'Dohrighat',
  'Dostpur',
  'Dudhi',
  'Dulhipur',
  'Ekdil',
  'Erich',
  'Etah',
  'Etawah',
  'Etmadpur',
  'Faizabad',
  'Faizabad',
  'Faizganj',
  'Farah',
  'Faridnagar',
  'Faridpur',
  'Faridpur',
  'Fariha',
  'Farrukhabad-cum-Fatehgarh',
  'Fatehabad',
  'Fatehganj Pashchimi',
  'Fatehganj Purvi',
  'Fatehgarh',
  'Fatehpur',
  'Fatehpur',
  'Fatehpur Chaurasi',
  'Fatehpur Sikri',
  'Firozabad',
  'Gangaghat',
  'Gangapur',
  'Gangoh',
  'Ganj Dundawara',
  'Ganj Muradabad',
  'Garautha',
  'Garhi Pukhta',
  'Garhmukteshwar',
  'Gaura Barhaj',
  'Gauri Bazar',
  'Gausganj',
  'Gawan',
  'Ghatampur',
  'Ghaziabad',
  'Ghazipur',
  'Ghiraur',
  'Ghorawal',
  'Ghosi',
  'Ghosia Bazar',
  'Ghughuli',
  'Gohand',
  'Gokul',
  'Gola Bazar',
  'Gola Gokarannath',
  'Gonda',
  'Gopamau',
  'Gopiganj',
  'Gorakhpur',
  'Gosainganj',
  'Gosainganj',
  'Govardhan',
  'Gulaothi',
  'Gularia Bhindara',
  'Gulariya',
  'Gunnaur',
  'Gursahaiganj',
  'Gursarai',
  'Hafizpur',
  'Haidergarh',
  'Haldaur',
  'Hamirpur',
  'Handia',
  'Hapur',
  'Hardoi',
  'Harduaganj',
  'Hargaon',
  'Hariharpur',
  'Harraiya',
  'Hasayan',
  'Hastinapur',
  'Hathras',
  'Hyderabad',
  'Ibrahimpur',
  'Iglas',
  'Ikauna',
  'Indian Telephone Industry, Mankapur (Sp. Village)',
  'Islamnagar',
  'Itaunja',
  'Jafarabad',
  'Jagner',
  'Jahanabad',
  'Jahangirabad',
  'Jahangirpur',
  'Jais',
  'Jaithara',
  'Jalalabad',
  'Jalalabad',
  'Jalalabad',
  'Jalali',
  'Jalaun',
  'Jalesar',
  'Jamshila',
  'Jangipur',
  'Jansath',
  'Jarwal',
  'Jasrana',
  'Jaswantnagar',
  'Jatari',
  'Jaunpur',
  'Jewar',
  'Jhalu',
  'Jhansi',
  'Jhansi',
  'Jhansi Rly. Settlement',
  'Jhinjhak',
  'Jhinjhana',
  'Jhusi',
  'Jhusi Kohna',
  'Jiyanpur',
  'Jyoti Khuria',
  'Kabrai',
  'Kachhauna Patseni',
  'Kachhla',
  'Kachhwa',
  'Kadaura',
  'Kadipur',
  'Kailashpur',
  'Kaimganj',
  'Kairana',
  'Kakgaina',
  'Kakod',
  'Kakori',
  'Kakrala',
  'Kalinagar',
  'Kalpi',
  'Kamalganj',
  'Kampil',
  'Kandhla',
  'Kandwa',
  'Kannauj',
  'Kanpur',
  'Kanpur',
  'Kanth',
  'Kanth',
  'Kaptanganj',
  'Karari',
  'Karhal',
  'Karnawal',
  'Kasganj',
  'Katghar Lalganj',
  'Kathera',
  'Katra',
  'Katra',
  'Katra Medniganj',
  'Kauriaganj',
  'Kemri',
  'Kerakat',
  'Khaga',
  'Khailar',
  'Khair',
  'Khairabad',
  'Khairabad',
  'Khalilabad',
  'Khanpur',
  'Kharela',
  'Khargupur',
  'Khariya',
  'Kharkhoda',
  'Khatauli',
  'Khatauli Rural',
  'Khekada',
  'Kheragarh',
  'Kheri',
  'Kheta Sarai',
  'Khudaganj',
  'Khurja',
  'Khutar',
  'Kiraoli',
  'Kiratpur',
  'Kishni',
  'Kishunpur',
  'Kithaur',
  'Koeripur',
  'Konch',
  'Kopaganj',
  'Kora Jahanabad',
  'Koraon',
  'Korwa',
  'Kosi Kalan',
  'Kota',
  'Kotra',
  'Kotwa',
  'Kul Pahar',
  'Kunda',
  'Kundarki',
  'Kunwargaon',
  'Kuraoli',
  'Kurara',
  'Kursath',
  'Kursath',
  'Kurthi Jafarpur',
  'Kusmara',
  'Laharpur',
  'Lakhimpur',
  'Lakhna',
  'Lal Gopalganj Nindaura',
  'Lalganj',
  'Lalitpur',
  'Lar',
  'Lawar NP',
  'Lohta',
  'Loni',
  'Lucknow',
  'Lucknow',
  'Machhlishahr',
  'Madhoganj',
  'Madhogarh',
  'Mahaban',
  'Mahmudabad',
  'Mahoba',
  'Maholi',
  'Mahona',
  'Mahrajganj',
  'Mahrajganj',
  'Mahrajganj',
  'Mahroni',
  'Mailani',
  'Mainpuri',
  'Majhara Pipar Ehatmali',
  'Majhauli Raj',
  'Malihabad',
  'Mallawan',
  'Mandawar',
  'Manikpur',
  'Manikpur Sarhat',
  'Maniyar',
  'Manjhanpur',
  'Mankapur',
  'Marehra',
  'Mariahu',
  'Maruadih Rly. Settlement',
  'Maswasi',
  'Mataundh',
  'Mathura',
  'Mathura',
  'Mau Aima',
  'Maudaha',
  'Maunath Bhanjan',
  'Mauranipur',
  'Maurawan',
  'Mawana',
  'Meerut',
  'Meerut',
  'Mehnagar',
  'Mendu',
  'Milak',
  'Miranpur',
  'Mirganj',
  'Mirzapur-cum-Vindhyachal',
  'Misrikh-cum-Neemsar',
  'Modinagar',
  'Mogra Badshahpur',
  'Mohammadabad',
  'Mohammadabad',
  'Mohammadi',
  'Mohan',
  'Mohanpur',
  'Mohiuddinpur',
  'Moradabad',
  'Moth',
  'Mubarakpur',
  'Mughalsarai',
  'Mughalsarai Rly. Settlement',
  'Muhammadabad',
  'Mukrampur Khema',
  'Mundera Bazar',
  'Mundia',
  'Muradnagar',
  'Mursan',
  'Musafirkhana',
  'Muzaffarnagar',
  'Nadigaon',
  'Nagina',
  'Nagram',
  'Nainana Jat',
  'Najibabad',
  'Nakur',
  'Nanauta',
  'Nandgaon',
  'Nanpara',
  'Naraini',
  'Narauli',
  'Naraura',
  'Nautanwa',
  'Nawabganj',
  'Nawabganj',
  'Nawabganj',
  'Nawabganj',
  'Nehtaur',
  'Nichlaul',
  'Nidhauli Kalan',
  'Niwari',
  'Nizamabad',
  'Noida',
  'Noorpur',
  'Northern Rly. Colony',
  'Nyoria Husainpur',
  'Nyotini',
  'Obra',
  'Oel Dhakwa',
  'Orai',
  'Oran',
  'Ordnance Factory Muradnagar',
  'Pachperwa',
  'Pahasu',
  'Paintepur',
  'Pali',
  'Pali',
  'Palia Kalan',
  'Parasi',
  'Parichha',
  'Parikshitgarh',
  'Parsadepur',
  'Patala',
  'Patiyali',
  'Patti',
  'Phalauda',
  'Phaphund',
  'Phulpur',
  'Phulpur',
  'Phulwaria',
  'Pihani',
  'Pilibhit',
  'Pilkhana',
  'Pilkhuwa',
  'Pinahat',
  'Pipalsana Chaudhari',
  'Pipiganj',
  'Pipraich',
  'Pipri',
  'Powayan',
  'Pratapgarh City',
  'Pukhrayan',
  'Puranpur',
  'Purdilnagar',
  'Purquazi',
  'Purwa',
  'Qasimpur Power House Colony',
  'Rabupura',
  'Radhakund',
  'Rae Bareli',
  'Raja Ka Rampur',
  'Rajapur',
  'Ramnagar',
  'Ramnagar',
  'Rampur',
  'Rampur Bhawanipur',
  'Rampur Karkhana',
  'Rampur Maniharan',
  'Rampura',
  'Ranipur',
  'Rashidpur Garhi',
  'Rasra',
  'Rasulabad',
  'Rath',
  'Raya',
  'Renukoot',
  'Reoti',
  'Richha',
  'Risia Bazar',
  'Rithora',
  'Rly. Settlement Roza',
  'Robertsganj',
  'Rudauli',
  'Rudayan',
  'Rudrapur',
  'Rura',
  'Rustamnagar Sahaspur',
  'Sadabad',
  'Sadat',
  'Safipur',
  'Sahanpur',
  'Saharanpur',
  'Sahaspur',
  'Sahaswan',
  'Sahatwar',
  'Sahawar',
  'Sahjanwa',
  'Sahpau NP',
  'Saidpur',
  'Saidpur',
  'Sainthal',
  'Saiyad Raja',
  'Sakhanu',
  'Sakit',
  'Salarpur Khadar',
  'Salempur',
  'Salon',
  'Sambhal',
  'Samdhan',
  'Samthar',
  'Sandi',
  'Sandila',
  'Sarai Aquil',
  'Sarai Mir',
  'Sardhana',
  'Sarila',
  'Sarsawan',
  'Sasni',
  'Satrikh',
  'Saunkh',
  'Saurikh',
  'Seohara',
  'Sewalkhas',
  'Shahabad',
  'Shahabad',
  'Shahganj',
  'Shahi',
  'Shahjahanpur',
  'Shahjahanpur',
  'Shahpur',
  'Shamli',
  'Shamsabad',
  'Shamsabad',
  'Shankargarh',
  'Shergarh',
  'Sherkot',
  'Shikarpur',
  'Shikohabad',
  'Shishgarh',
  'Shivdaspur',
  'Shivli',
  'Shivrajpur',
  'Siana',
  'Siddhaur',
  'Sidhauli',
  'Sidhpura',
  'Sikanderpur',
  'Sikanderpur',
  'Sikandra',
  'Sikandra Rao',
  'Sikandrabad',
  'Singahi Bhiraura',
  'Sirathu',
  'Sirauli',
  'Sirsa',
  'Sirsaganj',
  'Sirsi',
  'Sisauli',
  'Siswa Bazar',
  'Sitapur',
  'Som',
  'Soron',
  'Suar',
  'Sukhmalpur Nizamabad',
  'Sultanpur',
  'Sumerpur',
  'Swamibagh',
  'Talbehat',
  'Talgram',
  'Tambaur-cum-Ahmadabad',
  'Tanda',
  'Tatarpur Lallu',
  'Thakurdwara',
  'Thana Bhawan',
  'Thiriya Nizamat Khan',
  'Tikait Nagar',
  'Tikri',
  'Tilhar',
  'Tindwari',
  'Tirwaganj',
  'Titron',
  'Tondi Fatehpur',
  'Tulsipur',
  'Tundla',
  'Tundla Kham',
  'Tundla Rly. Colony',
  'Ugu',
  'Ujhani',
  'Umri',
  'Umri Kalan',
  'Un',
  'Unchahar',
  'Unnao',
  'Usawan',
  'Usehat',
  'Utraula',
  'Varanasi',
  'Varanasi',
  'Vijaigarh',
  'Vrindavan',
  'Warhapur',
  'Wazirganj',
  'Zaidpur',
  'Zamania',
  'Almora',
  'Badrinathpuri',
  'Bageshwar',
  'Banbasa',
  'Barkot',
  'Bhimtal',
  'Bhowali',
  'Chakrata',
  'Chamba',
  'Chamoli Gopeshwar',
  'Champawat',
  'Clement Town',
  'Dehradun',
  'Dehradun',
  'Dhaluwala',
  'Dharchula',
  'Dharchula Dehat',
  'Didihat',
  'Doiwala',
  'Dwarahat',
  'FRI and College Area',
  'Gochar',
  'Haldwani-cum-Kathgodam',
  'Haridwar',
  'Herbertpur',
  'Jaspur',
  'Joshimath',
  'Kaladungi',
  'Karn Prayag',
  'Kashipur',
  'Kirtinagar',
  'Lalkuan',
  'Landour',
  'Lohaghat',
  'Muni Ki Reti',
  'Mussoori',
  'Nainital',
  'Nainital',
  'Nand Prayag',
  'Narendra Nagar',
  'Pithoragarh',
  'Pratitnagar',
  'Raipur',
  'Ramnagar',
  'Ranikhet',
  'Rishikesh',
  'Roorkee',
  'Tanakpur',
  'Tehri',
  'Veerbhadra',
  'Vikasnagar',
  'Ahmadpur',
  'Aiho',
  'Aistala',
  'Alipurduar',
  'Alipurduar Rly.Jnc.',
  'Amodghata',
  'Andul',
  'Anup Nagar',
  'Aurangabad',
  'Bablari Dewanganj',
  'Baharampur',
  'Bairatisal',
  'Balarampur',
  'Bally',
  'Banarhat Tea Garden',
  'Bankura',
  'Barjora',
  'Baruihuda',
  'Baruipur',
  'Beldanga',
  'Beliatore',
  'Bholar Dabri',
  'Birnagar',
  'Bishnupur',
  'Bolpur',
  'Chachanda',
  'Chakdaha',
  'Char Brahmanagar',
  'Char Maijdia',
  'Charka',
  'Chechakhata',
  "Cooper's Camp",
  'Dafahat',
  'Darappur',
  'Dhulian',
  'Dhupguri',
  'Dhusaripara',
  'Dubrajpur',
  'English Bazar',
  'Falakata',
  'Farrakka Barrage Township',
  'Fatellapur',
  'Gairkata',
  'Gayespur',
  'Ghorsala',
  'Goaljan',
  'Gopalpur',
  'Gora Bazar',
  'Harharia Chak',
  'Jagadanandapur',
  'Jagtaj',
  'Jalpaiguri',
  'Jangipur',
  'Jaygaon',
  'Jiaganj Azimganj',
  'Jot Kamal',
  'Kachu Pukur',
  'Kalyani',
  'Kandi',
  'Kankuria',
  'Karimpur',
  'Kasim Bazar',
  'Kendua',
  'Khodarampur',
  'Kolkata',
  'Krishnanagar',
  'Kshidirpur',
  'Madanpur',
  'Mainaguri',
  'Mal',
  'Murshidabad',
  'Nabadwip',
  'Nasra',
  'Old Maldah',
  'Parbbatipur',
  'Paschim  Jitpur',
  'Paschim Punropara',
  'Phulia',
  'Rampurhat',
  'Ranaghat',
  'Rishra',
  'Sahajadpur',
  'Sainthia',
  'Santipur',
  'Satigachha',
  'Serpur',
  'Sobhaganj',
  'Sonamukhi',
  'Srikantabati',
  'Suri',
  'Taherpur',
  'Uttar Kamakhyaguri',
  'Uttar Latabari',
  'Uttar Mahammadpur',
  'Jamin',
  'Palin',
  'Longphong',
  'Nianu',
  'Niausa',
  'Senua',
  'Noksa Zedua',
  'Nginu',
  'Mintong',
  'Chanu',
  'Longchan',
  'Chubam',
  'Russa',
  'Rangluwa',
  'Namsai Town',
  'Tuting',
  'Yingkiong',
  'Goreswar',
  'Mushalpur',
  'Basugaon',
  'Bijni',
  'Chirang district',
  'Chandrapur',
  'Guwahati',
  'Odalguri',
  'Harisinga',
  'Kalaigaon',
  'Tangla',
  'Kaler',
  'Bihar',
  'Balod',
  'Dalli Rajhara',
  'Baloda Bazar',
  'Bhatapara',
  'Gainsari',
  'Pachperwa',
  'Tulsipur',
  'Utraula',
  'Nawagarh',
  'Basavan Bagevadi',
  'Gariaband',
  'Keskal',
  'Pharasgaon',
  'Lormi',
  'Mungeli',
  'Narayanpur',
  'Sukma',
  'Surajpur',
  'Malpur',
  'Meghraj',
  'Modasa',
  'Botad',
  'Gadhada',
  'Ranpur',
  'Bodeli',
  'Chhota Udaipur',
  'Nasvadi',
  'Sankheda',
  'Subir',
  'Waghai',
  'Bhanvad',
  'Dwarka',
  'Jamkhambhaliya',
  'Okha Port',
  'Salaya',
  'Kodinar',
  'Sutrapada',
  'Talala',
  'Una',
  'Khanpur',
  'Lunavada',
  'Santrampur',
  'Halvad',
  'Morbi',
  'Tankara',
  'Ukai',
  'Valod',
  'Vyara',
  'Badhra',
  'Charkhi Dadri',
  'Taoru',
  'Hassanpur',
  'Hathin',
  'Hodal',
  'Palwal',
  'Nichar',
  'Poo',
  'Wangtu',
  'Sissu',
  'Kalusa',
  'Ganderbal',
  'Kangan',
  'Lar',
  'Kishtwar',
  'Kulgam',
  'Pahloo',
  'Banihal',
  'Batote',
  'Jyotipuram',
  'Reasi',
  'Bari Brahmana',
  'Vijay Pur',
  'Shopian',
  'Jamtara',
  'Mihijam',
  'Govindpur Road',
  'Kurkura',
  'Latehar',
  'Netarhat',
  'Bhurkunda',
  'Ramgarh Cantonment',
  'Saunda',
  'Chandil',
  'Simdega',
  'Kaivara',
  'Bidadi',
  'Channapatna',
  'Kanakapura',
  'Bhimarayanagudi',
  'Shahapur',
  'Shorapur',
  'Yadagiri',
  'Badod',
  'Nalkheda',
  'Soyatkalan',
  'Susner',
  'Alirajpur',
  'Jobat',
  'Amarkantak',
  'Jaithari',
  'Kotma',
  'Ashoknagar',
  'Isagarh',
  'Mungaoli',
  'Burhanpur',
  'Shahpur',
  'Deosar',
  'Singrauli',
  'Waidhan',
  'Mumbai',
  'South Mumbai',
  'Jawhar',
  'Manniang Veng',
  'New Lamka',
  'Zomi Colony',
  'Khamsom',
  'Nungba',
  'Tamenglong',
  'Ukhrul',
  'Talui',
  'Lad Rymbai',
  'Resubelpara',
  'Tura',
  'Mawkyrwat',
  'Hmawngbuchhuah',
  'Kiphire',
  'Longleng',
  'Jalukie',
  'Peren',
  'Bhadaur',
  'Fazilka',
  'Jalalabad',
  'Madhopur',
  'Kurali',
  'Bhikhiwind',
  'Arnod',
  'Chhoti Sadri',
  'Dhariawad',
  'Peepal Khoont',
  'Pratapgarh',
  'Kelamangalam',
  'Thalli',
  'Anchetti',
  'Krishnagiri',
  'Shoolagiri',
  'Vepanapalli',
  'Hosur',
  'Kaveripattinam',
  'Pochampalli',
  'Mathur',
  'Uthangarai',
  'Muthur',
  'Mannarai',
  'Rudravathi',
  'Kannivadi',
  'Mulanur',
  'Kolathupalayam',
  'Chinnakkampalayam',
  'Avinashi (also spelled as Avanashi)',
  'Dhali',
  'Madathukulam',
  'Kaniyur',
  'Komaralingam',
  'Samalapuram',
  'Sankaramanallur',
  'Thirumuruganpoondi',
  'Kunnathur',
  'Uthukuli',
  'Kamanaicken palayam',
  'Alampur',
  'Waddepalle',
  'Dharur',
  'Manopad',
  'Alampur',
  'Rajoli',
  'Waddepalle',
  'Upperu',
  'Dharur',
  'Kuchinerla',
  'Chintharevula',
  'Ghattu',
  'Undavelli',
  'Ramapuram',
  'Kamareddy',
  'Dharmaram (P.B)',
  'Jagtial',
  'Jallaram',
  'Karimnagar',
  'Koratla',
  'Metpalle',
  'Palakurthy',
  'Peddapalle',
  'Ramagundam',
  'Ratnapur',
  'Rekurthi',
  'Sircilla',
  'Vemulawada R',
  'Chimmapudi',
  'Dhamsalapuram',
  'Erlapudi',
  'Koya Chalka',
  'Mallepalle Chinthagurthi',
  'Manchugonda',
  'Papadapalle',
  'Raghunadha Palem',
  'Regula Chalka',
  'V. Venkatayapalem',
  'Velugumatla',
  'Vepakuntla',
  'Bejjur',
  'Lingapur',
  'Jainoor',
  'Tiryani',
  'Komaram Bheem',
  'Kerameri',
  'Sirpur (U)',
  'Rebbena',
  'Wankidi',
  'Maripeda',
  'Kesamudram',
  'Gudur',
  'Gumudur',
  'Inugurthy',
  'Narsimhulapet',
  'Nellikudur',
  'Kuravi',
  'Gundrathimadugu',
  'Peddamupparam',
  'Achampet',
  'Atmakur',
  'Badepalle',
  'Boyapalle',
  'Chinnachintakunta',
  'Farooqnagar',
  'Gadwal',
  'Jadcherla',
  'Kalwakurthy',
  'Kothakota',
  'Singapur',
  'Teegalpahad',
  'Thallapalle',
  'Allipur',
  'Ameenapur',
  'Annaram',
  'Bhanur',
  'Bollaram (Bolarum)',
  'Bonthapalle',
  'Chegunta',
  'Chitkul',
  'Eddumailaram',
  'Gajwel',
  'Isnapur',
  'Alwal',
  'Bachupally',
  'Balanagar',
  'Dundigal Gandimaisamma',
  'Kukatpally',
  'Malkajgiri',
  'Quthbullapur',
  'Ghatkesar',
  'Kapra',
  'Keesara',
  'Medchal',
  'Medipally',
  'Shamirpet',
  'Uppal',
  'Achampet',
  'Kollapur',
  'Kalwakurthy',
  'Bhongir',
  'Bibinagar',
  'Chandur',
  'Chityala',
  'Choutuppal',
  'Devarakonda',
  'Kodad',
  'Kondamallapalle',
  'Miryalaguda',
  'Nakrekal',
  'Nalgonda',
  'Pochampalle (Bhoodan Pochampally)',
  'Raghunathpur',
  'Ramannapeta',
  'Suryapet',
  'Vijayapuri North',
  'Yadagirigutta',
  'Mudhole',
  'Khanapur',
  'Dilawarpur',
  'Kubeer',
  'Jam',
  'Basar',
  'Narsapur',
  'Ponkal',
  'Waddyal',
  'Laxmanchanda',
  'Armur (Armoor)',
  'Banswada',
  'Bodhan',
  'Ghanpur',
  'Kamareddy',
  'Nizamabad',
  'Soanpet',
  'Yellareddy',
  'Manthani',
  'Sultanabad',
  'Bommareddipalle',
  'Pannur',
  'Kannala',
  'Kamanpur',
  'Kolanoor',
  'Myadaram',
  'Odela',
  'Garrepalle',
  'Gambhiraopet',
  'Rudrangi',
  'Mustabad',
  'Yellareddipeta',
  'Vilasagar',
  'Thangallapalle',
  'Cheerlavancha',
  'Thadur',
  'Repaka',
  'Kodurupaka',
  'Bachpalle',
  'Badangpet',
  'Bandlaguda (Jagir)',
  'Boduppal',
  'Dundigal',
  'Ghatkesar',
  'Ibrahimpatnam (Bagath)',
  'Jawaharnagar',
  'Jillalguda (Jillelguda)',
  'Kismatpur',
  'Kompalle',
  'Kothapet',
  'Medchal',
  'Medipalle',
  'Meerpet',
  'Nagaram',
  'Narsingi',
  'Navandgi',
  'Omerkhan Daira',
  'Peerzadguda',
  'Shamshabad',
  'Tandur',
  'Turkayamjal',
  'Vicarabad (Vikarabad)',
  'Kohir',
  'Mogdampalle',
  'Gummadidala',
  'Kandi',
  'Ismailkhanpet',
  'Nizampet',
  'Hothi [B]',
  'Hathnoora',
  'Ranjole',
  'Rudraram',
  'Husnabad',
  'Siddipet',
  'Huzurnagar',
  'Chilkur',
  'Mella Cheruvu',
  'Bethavole',
  'Neredcherla',
  'Anantha Giri',
  'Thirumalgiri',
  'Revuru',
  'Garidepalle',
  'Jajireddi Gudem',
  'Huzurnagar',
  'Chilkur',
  'Mella Cheruvu',
  'Bethavole',
  'Neredcherla',
  'Anantha Giri',
  'Thirumalgiri',
  'Revuru',
  'Garidepalle',
  'Jajireddi Gudem',
  'Pebbair',
  'Amarchinta',
  'Srirangapur',
  'Peddamandadi',
  'Ghanpur',
  'Gopalpeta',
  'Buddharam',
  'Manajipet',
  'Yedula',
  'Peddagudem',
  'Bachannapet',
  'Narmetta',
  'Tharigoppula',
  'Zafargadh',
  'Chilpur',
  'Station Ghanpur',
  'Devaruppula',
  'Palakurthy',
  'Kodakandla',
  'Lingala Ghanpur',
  'Gundala',
  'Warangal Urban',
  'Alair',
  'Mothkur',
  'Kolanupaka',
  'Rayagiri (R)',
  'Sarvail',
  'Narayanapur',
  'Chada',
  'Valigonda',
  'Maryala',
  'Tangad Palle',
  'Amarpur',
  'Udaipur',
  'Padmabil Block',
  'Bishalgarh',
  'Sonamura',
  'Kailashahar',
  'Kumarghat',
  'Akbarpur',
  'Ashrafpur Kichhauchha',
  'Gauriganj',
  'Jagdishpur',
  'Bachhraon',
  'Dhanaura',
  'Ghosia Bazar',
  'Gopiganj',
  'Pilkhuwa',
  'Kasganj',
  'Dudahi Bazar',
  'Kaptanganj',
  'Babrala',
  'Chandausi',
  'Hariharpur',
  'Khalilabad',
  'Ailum',
  'Banat',
  'Bhinga',
  'Ikauna',
  'Hallaur',
  'Itwa',
  'Hardwar',
  'Roorkee',
  'Banghat',
  'Dugadda',
  'Sumerpur',
  'Kashipur',
  'Jaspur',
  'Barkot',
  'Bhaironghati',
  ' Barasat',
  'Baruipur',
  'Uttar Latabari',
  'Paschim Jitpur',
  'Paschim Jitpur',
  'Durgapur',
  'Changrabandha',
  'Andaranfulbari',
  'Bagdogra',
  'Bairatisal',
  'Balurghat',
  'Gangarampur',
  'Chopra',
  'Goalpokhar',
  'Alikhoja',
  'Amodghata',
  'Amta',
  'Andul',
  'Panskura',
  'Tamluk',
  'Kharagpur',
  'Jhargram',
  'Belda',
  'Raghunathpur-Adra',
  'Jhalida',
  'Balarampur',
  'Bakultala',
  'Aves Island (Andaman and Nicobar Islands)',
  'Curlew Island (Andaman and Nicobar Islands)',
  'Diglipur',
  'Bambooflat',
  'Prothrapur',
  'Garacharma',
  'Atawa',
  'Badheri',
  'Bahlana',
  'Karol Bagh',
  'Kirari Suleman Nagar',
  'Sultan Pur Majra',
  'Bhalswa Jahangir Pur',
  'Jaffrabad Census Town',
  'Babar Pur Census Town',
  'Deoli (Census Town)',
  'Osmania University',
  'Secunderabad',
  'Adilabad',
  'Asifabad',
  'Punalur',
  'Anchal',
  'Dasnapur',
  'Kaghaznagar',
  'Kyathampalle',
  'Madaram',
  'Vallickavu',
  'Azheekkal',
  'Naspur',
  'test',
  'Bhuvaneswar',
  'Rajgiri',
  'Teegalapahad',
  'Hyderabad M.Cor',
  'Kattilkadavu',
  'Puthiyackavu',
  'Azheekkal',
  'HARIPPAD',
  'Cheroor',
  'CHENNAI',
  'MELATTUR',
  'BENGALURU',
  'HYDERABAD',
  'PEERZADIGUDA',
  'SEHARA',
  'Clappana',
  'GHATOTAND',
  'Kolkata',
  'hebbal',
  'thruvallur',
  'TIRUPUR',
  'Dwarka',
  'Udumalpet',
  'thrithala',
  'Siliguri',
  'New delhi',
  'HYDERABAD',
  'MULAPADU',
  'Kangayam',
  'WARANGAL RURAL',
  'ATMAKUR',
  'mangapet',
  'ravulapalem',
  'NANMINDA',
  'sullurpeta',
  'NIZAMPET',
  'WANAPARTHY',
  'MANAVASI',
  'YELLAREDDY',
  'TANUKU',
  'KHAMMAM',
  'WHITEFIELD',
  'DELHI',
  'Mangalam road',
  'NEW DELHI',
  'Kundara',
  'NUZVID',
  'PALVANCHA',
  'KORATTUR',
  'new delhi',
  'PIDUGURALLA',
  'JAGTIAL',
  'AMALAPURAM',
  'KALPAKKAM',
  'PITHAPURAM',
  'Vasco Da Gama',
  'Kukanoor',
  'Kesariya',
  'TANDUR',
  'hyderabad',
  'DEBRA',
  'KALAVOOR',
  'Moolapalayam',
  'Navi Mumbai',
  'Dhone',
  'MIDNAPUR',
  'Koodarangi',
  'ERNAKULAM',
  'PEROOR',
  'KODUVAYUR',
  'THENHIPALAM',
  'AMBALAPUZHA',
  'Bellampalli',
  'Nilambur',
  'ETTUMANOOR',
  'Vallikkunnu',
  'MANNARKKAD',
  'CHENNAI',
  'Pargi',
  'ITEMPUDI',
  'MAHABUBABAD',
  'KATARAM',
  'Mudunuru',
  'HALIYA',
  'Thullur',
  'PORT BLAIR',
  'GUDUR',
  'srikalahasthi',
  'C V RAMAN NAGAR',
  'Kuttippuram',
  'VELUGODE',
  'EDAMUTTAM',
  'Mahabubnagar',
  'SURYAPET',
  'HYDERABAD',
  'TANGEDUMALLI',
  'MULANTHURUTHY',
  'Srimukhalingam',
  'ARINALLOOR',
  'West Hill',
  'SOORANAD NORTH',
  'MACHERLA',
  'Greater Noida',
  'Sompeta',
  'VADAKKENCHERY',
  'RAGHUNATHPALLY',
  'peruva',
  'Aginaparru',
  'Wazirganj',
  'Addanki',
  'SHADNAGAR',
  'Deola',
  'Delhi',
  'sasthamcotta',
  'NAIDUPET',
  'ALLAGADDA',
  'Rajampeta',
  'Salar',
  'Ranni',
  'Near ULUNDURPET',
  'BUCHIREDDIPALEM',
  'VASAI',
  'Aswapuram',
  'PALAMANER',
  'HANAMKONDA',
  'Lakkireddypalli',
  'GODAVARIKHANI',
  'Erumad',
  'Melmaruvathur',
  'Bargur',
  'KUNNATHOOR',
  'Gumla',
  'test11143',
  'NANDIKOTKUR',
  '212666',
  'yercaud foot hills',
  'TADIKONDA',
  'SADASIVPET',
  'Vidyaranyapura',
  'Narsampet',
  'Mukkom',
  'VINDHYANAGAR',
  'BANGALORE',
  'PALLIKKATHODU',
  'AYOOR',
  'sattenapalli',
  'hyderabad',
  'Ramanattukara',
  'BHADRACHALAM',
  'Lenavilakku',
  'KOTHAGUDEM',
  'GAJWEL',
  'GANNAVARAM',
  'Dharapuram',
  'RANGAREDDY',
  'Kothavalasa',
  'BALUSSERY',
  'PANTNAGAR',
  'MANGALAGIRI',
  'MANNANAM,KOTTAYAM',
  'Kasdol',
  'PALAKOL',
  'Sukkampatti',
  'Bangalore',
  'NANDAVARAM',
  'Puduchatram',
  'GUDAVALLI',
  'KAIKALURU',
  'JANGAON',
  'YEMMIGANAUR',
  'Kovaipudur',
  'Kanjirapally',
  'chathannoor',
  'KUKKATPALLY',
  'GANDI MISAMMA',
  'Valanchery',
  'CHALLAPALLI',
  'BAPATLA',
  'Achanta',
  'Vasant Kunj',
  'BANAGANAPALLI MANDAL',
  'HULIMAVU',
  'SUNGUVARCHATHIRAM',
  'VUYYURU',
  'PATANCHERU',
  'Kuwait',
  'KODMUR',
  'Iritty',
  'Mangalore',
  'paderu',
  'Kulumani',
  'Paramathi vellore',
  'Paramathi velur',
  'vatakara',
  'Kulasekharapuram',
  'PEDDAPURAM',
  'SAMARLAKOTA',
  'KOLKATA',
  'Kushwar Asthan',
  'Giddalur',
  'PONNUR',
  'GANAPAVARAM',
  'Rehla',
  'BISHRAMPUR',
  'Secunderabad',
  'Pamukunta',
  'SODAM',
  'JAYANAGER',
  'KALIKKADAVU',
  'Veeraghattam',
  'Madhapur',
  'Peelamedu',
  'Darsi',
  'ATHAVANAD',
  'Atigre',
  'Near v.kota',
  'Zaheerabad',
  'TORANAGALLU',
  'MANCHERIAL',
  'Visakhapatnam',
  'NAGARAM',
  'RAYACHOTI',
  'TALAPPILLY TALUK',
  'PODALAKUR',
  'GARLA',
  'BATTUPALLY',
  'Chandi',
  'CHIRALA',
  'Palluruthy',
  'Srirengam',
  'TADEPALLI',
  'ZIRAKPUR',
  'CHANDIGARH',
  'turputallu',
  'CHIKKABALLAPUR',
  'Kaduthuruthy',
  'Manpur',
  'ALATHUR',
  'Perumanna',
  'UTHANGAL',
  'AMBALAMUGAL',
  'KADIRI',
  'Howrah',
  'ELAMAKKARA',
  'THANAGAZI',
  'BHUPALAPALLY',
  'MARTUR',
  'GOOTY',
  'PERINGOTTUKARA',
  'Sherlingampally',
  'Cheruvathur',
  'Gopalapuram',
  'AMBASAMUTHIRAM',
  'NAGALAPURAM',
  'Preet Vihar',
  'NEW RAJDHANI ENCLAVE',
  'MULUND',
  'Yerraguntla',
  'KOSAKULAM',
  'chandragiri',
  'RAJAM',
  'Pandua',
  'KOZHENCHERRY',
  'RANNY',
  'TIRUCHANOOR',
  'Parvathipuram',
  'PAROPADY',
  'Delhi',
  'peddamberpet',
  'POLLACHI',
  'Pileru',
  'MATASAR',
  'JAMMALAMADUGU',
  'VENKATAGIRIKOTA',
  'Vadakankulam',
  'PAYAKARAOPETA',
  'NEMMARA',
  'Cujira',
  'Gudlavalleru',
  'AGIRIPALLI',
  'SANGAREDDY',
  'MULAMTHURUTHY',
  'REPALLE',
  'Medharametla',
  'GUNTUR',
  'Vallikunam',
  'Shekhopur sarai',
  'Kakkanad',
  'PATHANAPURAM',
  'KONDOTTY',
  'Kondotty',
  'NELLORE',
  'ASPARI',
  'Najafgarh',
  'Mundur',
  'new delhi',
  'Muzaffarpur',
  'Saripalli',
  'JANGAREDDY GUDEM',
  'VADLAMUDI',
  'Eachanari',
  'PODILI',
  'Koli Bharthana',
  'vikas puri',
  'Koli Bharthana',
  'MANNUTHY',
  'IMPHAL',
  'dammalapadu',
  'KOLATHUR',
  'KOLATHUR',
  'IRIKKUR',
  'SREEKANDAPURAM',
  'NEWTOWN, RAJARHAT, KOLKATA',
  'Vinukonda',
  'komarapalayam',
  'Irala',
  'VANASTHALIPURAM',
  'STATION GHANPUR',
  'Satlasana',
  'HYDERABAD',
  'narnoor',
  'VENKATAGIRI',
  'Vissannapeta',
  'KONDAPURAM',
  'Palakurthy',
  'ambattur',
  'Cherpu',
  'GANDARVARKOTTAI',
  'PATTAMBI',
  'KANCHIPURAM',
  'KHARGHAR',
  'MADNOOR',
  'Ondipudur',
  'Ondipudur',
  'OMALLOOR',
  'boisar',
  'PERUMBAKKAM',
  'Duddukuru',
  'Musafirkhana',
  'NARSINGI',
  'nirmal',
  'Attiguppe',
  'Attiguppe',
  'Uttam nagar',
  'Perambra',
  'POONJAR',
  'KOILAKUNTLA',
  'attibele',
  'REDDIPATTI',
  'BODUPPAL',
  'marathalli',
  'Chengalpet',
  'Mala',
  'Shankerpally',
  'Velacherry',
  'Chityala',
  'NARSIPATNAM',
  'JHARODA KALAN',
  'JHARODA KALAN',
  'Tangassery',
  'Lakkavarapukota',
  'Bajkul',
  'Neoria husnpur',
  'SIRCILLA',
  'Haldia',
  'HOSUR',
  'PAYANGADI',
  'Allur',
  'Bahrain',
  'Bahrain',
  'CHENNUR',
  'P.L.Puram',
  'Nedumkandam',
  'TELKAPALLY',
  'Kanjurmarg',
  'PERIYAKURICHI',
  'ATMAKUR',
  'PULIVENDULA',
  'KANDUKUR',
  'NEYVELI TS',
  'CHOWDEPALLE',
  'Punganur',
  'PAMARRU',
  'CUMBUM',
  'kanchikacherla',
  'Koppam',
  'New Rajinder Nagar',
  'aravalli',
  'Erode',
  'ARAGONDA',
  'Dantan',
  'MARKAPUR',
  'MADHIRA',
  'Halanayakanahalli',
  'RAZOLE',
  'YELLAMANCHILI',
  'Pusuluru,Guntur',
  'New Delhi',
  'Kazhakuttom',
  'Mahua',
  'KONNI',
  'KALANJOOR',
  'Panchveer',
  'Ghatkopar',
  'Chentrapinni',
  'KUTTANELLUR',
  'Mangalapurum',
  'Vasant Vihar',
  'TAMILNADU',
  'Powai',
  'Koduvilarpatti',
  'B KOTHA KOTA',
  'Bommasandra',
  'Kakkavayal',
  'Munnar',
  'PATHANKOT',
  'Vaishali',
  'Dyanand Vihar',
  'karveti nagaram',
  'NEW DELHI',
  'Kodad',
  'VIKARABAD',
  'MIRYLAGUDA',
  'BHUVANGIRI',
  'TRIKARIPUR',
  'Khowai',
  'ALIGANJ',
  'Gandod',
  'VENGARA',
  'G.T.B Enclave',
  'Devarkadra',
  'Karamcodu',
  'Rajajinagar',
  'WADAKANCHERY',
  'benipatti',
  'MUTHUTHEVANPATTI',
  'GADWAL',
  'BIJWASAN',
  'Bobbili',
  'porumamilla',
  'OACHIRA',
  'NANDIKKARA',
  'NOORANAD',
  'kotla vihar phase 1',
  'ANUSHAKTI NAGAR',
  'CHADAYAMANGALAM',
  'Nagarkurnool',
  'Jaggaiahpet',
  'PANGULURU',
  'GACHIBOWLI',
  'Thumpamon',
  'INDIRAPURAM',
  'Mattipadu',
  'Nidamanuru',
  'POOTHOTTA',
  'Kadanad',
  'BODHUPATTI',
  'NEKKALLU',
  'Koduru',
  'hanumanjunction',
  'KALLURU',
  'Anakapalli',
  'Gauribidanur',
  'Cheemasandra',
  'Vazhakulam',
  'Veraval',
  'TIRUVURU',
  'Toopran',
  'HANUMAKONDA',
  'HANMAKONDA',
  'Doddakannalli',
  'Enekapadu',
  'lalganj',
  'lalganj',
  'MAHINDRA WORLD CITY',
  'MAHINDRA WORLD CITY',
  'Kondapur',
  'Anaparthy',
  'Madhapur',
  'Vellakovil',
  'Bhaptiyahi',
  'Gudipatipalli',
  'Malda',
  'Vidya Nagar',
  'Manubolu',
  'MAHE',
  'MAHE',
  'Kasibugga',
  'r tallavalasa',
  'AMEERPET',
  'MULUGU',
  'totharamudi',
  'Kothacheruvu',
  'ANDIMADAM',
  'PETTAVAITHALAI',
  'Prathipadu',
  'Balapala',
  'Mumbai',
  'najafgarh',
  'kochi',
  'KAKKUR',
  'CHEVAYUR',
  'Chapadu',
  'Thevalakkara',
  'Doha, Qatar',
  'KAMALAPURAM',
  'Kurmannapalem',
  'BHAINSA',
  'UPPERPALLY',
  'PAMPADY',
  'CHINTALAPUDI',
  'ANNAVADAPPADU',
  'MANIMALA',
  'Pichatur',
  'cox town',
  'indiranagar',
  'NARAYANPET',
  'VEMPALLI',
  'Daggubadu',
  'SATHUPALLY',
  'MANNAR',
  'Chintamani',
  'Chinathadagam',
  'KUPPAM',
  'nandigama',
  'MEDIKONDURU',
  'vinjamur',
  'PuttaParthi',
  'MUPPAVARAM',
  'Barauni',
  'BADVEL',
  'kulappuly',
  'NERIAMANGALAM',
  'LAKSHMIPURAM',
  'PEKERU',
  'ALLUR MANDAL',
  'Mohali',
  'GOLLAPROLU',
  'Chandarlapadu',
  'Chrompet',
  'Pulla',
  'AMEENPUR',
  'NARASIMAIAH KONDA',
  'Gurramkonda',
  'LAKKAVARAM',
  'Thasra',
  'ASHTAMICHIRA',
  'Uravakonda',
  'sathyavedu',
  'Vellila',
  'Ahmednagar',
  'JAMMIKUNTA',
  'Palarivattom',
  'RAILWAY KODUR',
  'kozhinjampara',
  'ROMPICHERLA MANDAL',
  'Rompicherla',
  'ANJANAPURA',
  'BIDARE AGRAHARA',
  'Kusumanchi',
  'SOMANDEPALLI',
  'RENIGUNTA',
  'Balha',
  'MANARCAD',
  'Vennamalai',
  'PODANUR',
  'Makkaraparamba',
  'KUMBLA',
  'PULLURU',
  'thindal',
  'Tamluk hub',
  'CHALISSERY',
  'SR Nagar',
  'Kalikiri',
  'Bhubaneswar',
  'Kazipet',
  'malayalapuzha',
  'GUNUPUR',
  'NOLAMBUR',
  'MADHAVARAM',
  'MANDAMARRI',
  'HAYATHNAGAR',
  'nadapuram',
  'Kalyandurg',
  'Garividi',
  'Nagalapuram',
  'DENKANIKOTTAI',
  'SRIHARIKOTA',
  'KANURU',
  'Dilshad Colony',
  'Dilshad Colony',
  'Dilshad Garden',
  'CHITVEL',
  'Patel Nagar',
  'Areekode',
  'DAMMAPETA',
  'KOMARAPALYAM',
  'ROHINI',
  'Madhurawada',
  'THAZHE CHOVVA',
  'Gatepudur',
  'NAGARAM',
  'NARSINGI',
  'NARSINGI',
  'NARSINGI',
  'EDAKKAD',
  'trichy',
  'MYLAVARAM',
  'ARATTUPUZHA',
  'KONDAPUR',
  'NIGAHI',
  'Dilahukh nagar',
  'GORANTLA',
  'ADIPUR',
  'DOMMASANDRA',
  'yeshwanthpur',
  'Sarapaka',
  'Peapully',
  'BHIMUNIPATNAM',
  'Narikkuni',
  'Kuttamboor',
  'HUZURABAD',
  'KADAKKAL',
  'gudur mandal',
  'PUTTUR',
  'Sidhrawali',
  'Parappanangadi',
  'Shankarampet A',
  'ICHCHAPURAM',
  'Marthandam',
  'Ramayampet',
  'PAYYOLI',
  'Wanaparthy',
  'Monippally',
  'Rayadurgam',
  'KOTIKESAVARAM',
  'Pandikkad',
  'PATTIKONDA',
  'RAMACHANDRAPURAM',
  'NARSAPUR',
  'Vemulavada',
  'Denduluru mandal',
  'KOVVUR',
  'MAHABUBABAD',
  'BUKKAPATNAM',
  'Bestawaripeta',
  'chagallu',
  'hanuman  junction',
  'PALLIKARANAI',
  'Andheri',
  'KOLLAMPALAYAM',
  'KANDIANKOIL',
  'KANDAGHAT',
  'Mehsi',
  'VELLARIKUNDU',
  'CHAGALAMARRI',
  'Mundlamuru Mandal',
  'Vasundara',
  'NIDADAVOLE',
  'NADIGUDEM',
  'Arakunnam',
  'Bardez',
  'Singarayakonda',
  'KALIGIRI',
  'BITRAGUNTA',
  'KPHB',
  'PARIPPALLY',
  'Whitefield',
  'TUNI',
  'ANUPURAM',
  'VAYALPAD',
  'VELPOOR',
  'Gajuwaka',
  'KANIGIRI',
  'Thoraipakkam',
  'MYPA',
  'GARAGAPARRU',
  'PALAKODERU MANDAL',
  'Narsapur',
  'DUBBAK',
  'saluru',
  'BALLABGARH',
  'TATANAGARA',
  'CHOTTANIKKARA',
  'KODUVALLY',
  'Nallepilly',
  'PAYAKARAOPETA',
  'Kanchiracode P.O',
  'MANUGURU',
  'parchur',
  'Palladam',
  'Rayakottai',
  'SIRPUR KAGHAZNAGAR',
  'R K Puram',
  'Doddakal Sandra',
  'PENUBALLY',
  'CHINTHAKANI',
  'KONIJERLA',
  'THALLADA',
  'MUTHALAMADA',
  'Shakurpur',
  'Sitalpur',
  'YELLANDU',
  'Cherukuru',
  'Barnala',
  'ASWARAOPET',
  'Chittarikkal',
  'Baleru',
  'TALLADA',
  'CHERUKUPALLI',
  'Pakala',
  'KALOOR',
  'Warangal urban',
  'Koottanad',
  'KADAMPANAD',
  'peraiyur',
  'bhatrojkhan',
  'SINGHANA',
  'Kuzhalmannam',
  'NAGAMALAI PUDUKOTTAI',
  'Karukachal',
  'Maharajapuram',
  'Vengames',
  'INDUKURUPETA',
  'VENMONY',
  'Ayarkunnam',
  'Gopavaram',
  'ANAKKARA',
  'saibaba colony',
  'PANYAM',
  'Puthoor',
  'AMBALLUR',
  'Tangutur',
  'Kankipadu',
  'Nawanagar',
  'Moradabad',
  'GAZOLE',
  'VANAPALLI',
  'nellore',
  'Kottayam',
  'PUTHENCRUZ',
  'Trichy',
  'Rajahmundry Rural',
  'kruthivennu',
  'CHINNAGANJAM',
  'Vyalikaval',
  'Us',
  'LEPAKSHI',
  'Avanigadda',
  'BARPETA',
  'Gajapathinagaram',
  'KOVUR',
  'Benipur',
  'OYOOR',
  'AKKIVARAM',
  'Y KOTHAPALLE',
  'MOGHALPUR',
  'VIZAG',
  'KHAMMAM RURAL',
  'Vemuru',
  'KULANADA',
  'Rudrangi',
  'Kompally',
  'PEDANA',
  'HAGARIBOMMANAHALLI',
  'KRISHNA NAGAR',
  'MALAD',
  'Pathapatnam',
  'AMETHI',
  'KAIPATTOOR',
  'Vikhroli',
  'Tekkali',
  'Datti',
  'PALLAVUR',
  'BHATTIPROLU',
  'Mahadevpur',
  'Biharijung',
  'PONKUNNAM',
  'KANJIRAPPALLY',
  'Alakode',
  'SAMBEPALLI',
  'MANDAPETA',
  'DOMLUR',
  'Attibele',
  'MANGURU',
  'OCHIRA',
  'Kudankulam',
  'KALUVOYA',
  'Ghansavangi',
  'PEDDAPALLY',
  'BOATH',
  'VAZHOOR',
  'VEERAVASARAM',
  'A GOKAVARAM',
  'Valukkupparai',
  'MAREGAON',
  'Pendurthi',
  'BISHRAMPUR',
  'KOLIMIGUNDLA MANDAL',
  'prathipadu',
  'BANTUMILLI',
  'PAMIDIPADU',
  'Kurudikkad',
  'VOLETIVARI PALEM',
  'ROLLA',
  'MALIKIPURAM',
  'Khajipet',
  'PADANNA',
  'SAHIBABAD',
  'THENNAMPALAYM',
  'AKIVIDU',
  'YERPEDU',
  'Penukonda',
  'Chinthaladevi',
  'SANGAM',
  'Yarravari palem',
  'Vetapalem',
  'ANANTHASAGARAM',
  'YERRUPALEM',
  'VELLAVOOR',
  'KAMEPALLI',
  'SAVALYAPURAM',
  'Pangode',
  'Nookampalayam',
  'Galiveedu',
  'Durgi',
  'gopalapuram',
  'KOLACHERI',
  'RT NAGAR',
  'KOTTAPPADY',
  'Panigapalli',
  'KORUTLA',
  'UDAYAGIRI',
  'PEDAPUDI',
  'PEDAKALLEPALLI',
  'Thorrur',
  'MELMALAYANUR',
  'Thamarakulam',
  'Rajapuram',
  'VEMBAYAM',
  'Manipal',
  'Akkayapalem',
  'ONGALLUR',
  'ATCHAMPET',
  'JALADANKI',
  'NIZAMABAD',
  'MAYUR VIHAR',
  'CHHATARPUR',
  'BHEL',
  'Noida',
  'KAMAVARAPUKOTA',
  'Gabjore',
  'URAKAM',
  'CHENNUR',
  'MIYAPUR',
  'CHENGANNUR (VIA)',
  'Kothakota',
  'Vellaturu',
  'Medak',
  'Ponmalai',
  'BAGEPALLI',
  'NARASENAPETA',
  'Ponda',
  'ALAMURU',
  'Konakanchi',
  'CHANDOLE',
  'Palasa',
  'KOTTAPALLI',
  'PEDAVENKANNA PALEM',
  'VENJARAMOODU',
  'MELVANAKKAMBADI',
  'payyanur',
  'Brahmamgari mattam',
  'Vill Satoh',
  'VEERAPUNAYUNI PALLI',
  'KUKKALAPALLI',
  'Saidapur',
  'santirbazar',
  'Parli',
  'Chankyapuri',
  'SRIKAKULAM',
  'Mydukur',
  'TALUPULA',
  'PERAVALI',
  'Ponnaluru',
  'Devarapalli Mandal',
  'amangal',
  'MACHAVARAM',
  'koyyalagudem',
  'Kavalkinaru',
  'G LAKSHMIPURAM',
  'Sagar ring road.',
  'KOTHAPETA',
  'Dwaraka Tirumala',
  'NANNURAPADU',
  'Gopalapatnam',
  'Palayamkottai',
  'KORAMANGALA',
  'palam',
  'Vellanaipatty',
  'Koduvai',
  'RAMAGUNDAM',
  'Narayankhed',
  'Rayavaram',
  'ABHANPUR',
  'Kavalam',
  'CHANGORABHATA',
  'Amritapuri',
  'Baina',
  'kolenchery',
  'Yadiki',
  'Kurichedu',
  'SIDDHARTH NAGAR',
  'Vazhuthacaud',
  'PENUGONDA',
  'Nandipulam',
  'Bibinagar',
  'Pappanamcode',
  'OLAVAKKOD',
  'Marripadu',
  'Chejerla',
  'Ramagiri',
  'AGALI',
  'Ashok Nagar',
  'Charampa',
  'KORUKONDA',
  'MARTERU',
  'Karsog',
  'KANJIKODE',
  'Chukkapur',
  'Rajanagaram',
  'EGRA',
  'Kodumon',
  'METPALLI',
  'Karikode',
  'PULLAMPETA',
  'VADAKKUTHU',
  'Vedurukuppam',
  'Asifabad',
  'Chapara',
  'Enkoor',
  'Rajampalem',
  'Nad',
  'Mahagama',
  'SOWRIPALAYAM',
  'CHERPULASSERY',
  'Shambhuganj',
  'PALEM',
  'KADIYAM',
  'Mudigubba',
  'Pitampura',
  'K.K.nagar',
  'L.K.T. NAGAR',
  'CHALODE',
  'G D NELLORE',
  'KUSHAIGUDA',
  'Sreekariyam',
  'Piramcheruvu',
  'Kakdwip',
  'Kotauratla',
  'ESANATHAM',
  'Adambakkam',
  'Bauram',
  'Mehdipatnam',
  'Koothattukulam',
  'ARAPALAYAM',
  'Bandra',
  'Gachibowli',
  'Garla',
  'Vasant Kunj',
  'Nallajerla',
  'Padi',
  'vengeri',
  'Piravom',
  'Kumar nagar',
  'Kuttanad',
  'Wyra',
  'Kollengode',
  'KUDALLUR',
  'PENUMUR',
  'Adimali',
  'Keunta',
  'r.g pudur',
  'Kodangal',
  'peermade',
  'KAIPARAMBU',
  'EDAKKARA',
  'Chelakkara',
  'Saroornagar',
  'Nithiravilai',
  'Bhandup West',
  'KARTHIKAPALLY',
  'Thirumanur',
  'vandanmedu',
  'PUDUR',
  'KARUMANDAPAM',
  'PERINGOTTUKURISSI',
  'VADRAI, PALGHAR',
  'KATTAKADA',
  'Kalakada',
  'Near kavithal',
  'Chettipalayam.k',
  'Hari Nagar',
  'Ammanabrolu',
  'SINGANALLUR',
  'EDAPPAL',
  'MADARAM TOWN SHIP',
  'Suriyanallur',
  'Pattikad',
  'Old washermenpet',
  'Cheemasandra',
  'VIRAR',
  'annavaram',
  'teekli',
  'Gandhipuram',
  'REGONDA',
  'Shanthinagar',
  'Keeleperambalur',
  'Bhakarapet',
  'Somgaon Kalan',
  'Payyavoor',
  'Sharadara',
  'Mankada',
  'PANDANALLUR',
  'Suchitra',
  'Tiruppur',
  'Vattiyoorkavu',
  'Thakazhy',
  'Chandragiri',
  'Theni',
  'Andankovil',
  'Madhampatti',
  'secunderabad',
  'Nalanchira',
  'MANGALAM',
  'POTHENCODE',
  'KARUPPAYURANI',
  'Kondappanaickenpatti',
  'THEKKUMBHAGOM',
  'CHERUTHONY',
  'Janakpuri',
  'Kalayarkoil',
  'OLD BOWNPALLY',
  'JAMMULADINNE',
  'Mummidivaram',
  'BHIMADOLE',
  'Katravullapalli',
  'Ryali',
  'HIRAMANDALAM',
  'Sreekrishnapuram',
  'IRAGAVARAM',
  'INKOLLU',
  'Madduru',
  'PALANGI',
  'BURUGUPALLI',
  'chatrai',
  'Anandhapuram',
  'Pasarlapudi',
  'TALLAPUDI',
  'Nagarbhavi',
  'Cheran Managar',
  'AL puram',
  'Tuni',
  'kondepi',
  'NAMBURU',
  'Pandithavilluru',
  'Jaggampeta',
  'Eleswaram',
  'Kapileswarapuram',
  'CHADALADA',
  'PAMURU',
  'Ck palli',
  'Ananthavaram',
  'Pitlam',
  'Chinnadharapuram',
  'Karempudi',
  'Edamulackal',
  'Gopalapuram',
  'avadi',
  'Gollala mamidada',
  'Garladinne',
  'Ebbanad',
  'Mori',
  'Velivennu',
  'Jangareddygudem',
  'Atreyapuram',
  'POLAVARAM',
  'pulpally',
  'Makavarapalem',
  'THIRUVANNAMALAI',
  'Sessali',
  'udumbanchola',
  'Dwarapudi',
  'Moozhikkal',
  'Maruthancode',
  'Chittilappilly',
  'pallimukku',
  'Chintapalli',
  'Peramangalam',
  'parawada',
  'Therlam',
  'Kilimanoor',
  'SAKET',
  'Sankhavaram',
  'NEKARIKALLU',
  'Thenkurissi',
  'KOOTHATTUKULAM',
  'Bethamcherla',
  'Others',
  'velandipalayam post',
  'Sidhantham',
  'Annavaram',
  'Sakthikulangara',
  'THRIKKOVILVATTOM',
  'Salem',
  'Tatipaka',
  'Mudenapalli',
  'Tagarapuvalasa',
  'Uppal',
  'Santamaguluru mandal',
  'Kalgachia',
  'Tanakallu',
  'Mankurissi',
  'kakumanu',
  'Vyttila',
  'Niranam',
  'Maroor8955',
  'KHAJAULI',
  'VELAGAPUDI',
  'LaxmiNagar',
  'Plan Bandipora',
  'KALASAPADU',
  'Ramathirdham',
  'Choraut',
  'MEENANGADI',
  'PATHIYOOR',
  'Kanduru',
  'Palakonda',
  'Kumbazha',
  'Vellayani',
  'K. Paramathi',
  'UDAYAPATTY',
  'MUNDAKKAYAM',
  'ELAPPULLY',
  'Tiwarta',
  'tripuranthakam',
  'kUMILY',
  'NAGAYALANKA',
  'Srirangam',
  'Coimbatore',
  'Miyapur',
  'Parthibanur',
  'KURAVILANGAD',
  'Nadavayal',
  'ANNANUR',
  'vadanappally',
  'Ukkdam',
  'Jawa',
  'Kunnamangalam',
  'Mutluru village',
  'THOLANUR',
  'Tallur',
  'Devapur',
  'Palakunnu',
  'Tribeni',
  'Madhavaram',
  'Srisailam',
  'Ippagunta village',
  'Ghantasala',
  'kodambakkam',
  'Komarole',
  'KAYALODE',
  'V. V reddy naga',
  'Pallikathodu',
  'Thachottukavu',
  'Saravakota',
  'Kunnicode',
  'Niali',
  'ATTILI',
  'UZHAVOOR',
  'Triprayar, Valapad',
  'KODIGENAHALLI',
  'YERRAGONDA PALEM',
  'Jagadevpur',
  'Others',
  'Yallanur mandal',
  'Kodigehalli',
  'Chikkadpally',
  'Parassala',
  'Govindaraopet',
  'Marthandam',
  'Omassery',
  'kamalpur',
  'KONGAD',
  'Vaviletipadu village',
  'Kuchipudi',
  'Utnoor',
  'Yanam',
  'Dornakal',
  'Kochuveli',
  'Charummoodu',
  'DEEPSHIKHA',
  'PUDUSSERY',
  'SIPAT',
  'Pammamcode',
  'MADAVOOR',
  'PERUVAYAL',
  'Dhewdha',
  'THIRUVAMBADY',
  'Choutuppal',
  'Contai',
  'GOKAVARAM',
  'SODEPUR',
  'kattur',
  'Tadipudi',
  'BODDAVARAM',
  'Tambaram',
  'Sikkavalam West',
  'Neerattupuram',
  'Mirganj',
  'Rangampeta',
  'Pedanandipadu',
  'Mahadevapatnam',
  'PEDAPALLA',
  'Kotturu',
  'Singavaram',
  'Ballipadu',
  'Yerravaram',
  'Voolapalli',
  'Dalavalasa',
  'Bhogapuram',
  'BAIRIA',
  'Kajuluru',
  'TANUR',
  'Tapeswaram',
  'VALATHUNGAL',
  'PENUGANCHI PROLU.',
  'ELAVUMTHITTA',
  'Ramabhadrapuram',
  'Cherial',
  'Cheepurapalli',
  'Rohini',
  'Padikeel (Kodakkad)',
  'GUNDUGOLANU',
  'Nai Bazar',
  'Venkatagiri',
  'HEBBAL KEMPAPURA',
  'danapur cantt',
  'Uthiyur',
  'Kumaradevam',
  'Arumbakkam',
  'Upper sileru',
  'Narpala',
  'KALLUVATHUKKAL',
  'TANA',
  'Thamarassery',
  'Gollaprolli',
  'MUNDAKKAL',
  'Sarjapura',
  'Nand nagri',
  'Latheri',
  'Lalganj',
  'Sendurai',
  'Ellanthakunta',
  'PEDAPUDI',
  'Kamepalli',
  'Ealkathurthy',
  'Valasaravakkam',
  'Vamanapuram',
  'JP nagar',
  'Veeravallipalem',
  'Gurazala',
  'Pilicode',
  'BORRAMPALEM',
  'P.N.Pudur',
  'nagari',
  'Sanath nagar',
  'Lakhpura',
  'ananthapalli',
  'Visannapeta',
  'Amadalavalasa',
  'Edapally',
  'Viralimalai',
  'VETLAPALEM',
  'Rajamundry',
  'Sasthmnada',
  'pallamkurru',
  'RUNNI SAIDPUR',
  'Malampuzha',
  'Kukatpally',
  'Narasannapeta',
  'Murickassery',
  'SUNDAKKAMUTHUR',
  'Mopidevi',
  'RAVIVALASA',
  'Butibori',
  'Palakollu',
  'CHEMMAD',
  'Malayattoor',
  'Muthukulam',
  'CHANDIKHOL',
  'Sornavur',
  'Balaramapuram',
  'Near srikalahasthi',
  'Atmakur',
  'Owk Mandal',
  'Owk',
  'JUKKAL',
  'MASINAGUDI',
  'Kharsang',
  'Pauri',
  'Sri Rangarajapuram',
  'Draksharama',
  'Pedda vaduguru',
  'VADASSERIKARA',
  'THALAP',
  'Chodavaram',
  'Badangi',
  'GANTYATA',
  'Mallapally',
  'Desamangalam',
  'KODAKARA',
  'Kuttiady',
  'Maliyampathu',
  'Neemrana',
  'T SUNDUPALLI',
  'VELANTHAVALAM',
  'PANDITHITTA',
  'Chempu',
  'KARAKULAM',
  'Pattom',
  'Anchallummodu',
  'ENGANDIYUR',
  'RAJAKKAD',
  'Terakanambi',
  'PRESSCOLONY',
  'Malali',
  'Manali',
  'Pottamal',
  'KAIPAMANGALAM',
  'friday market',
  'MALAKONDARAYUNIPALEM',
  'ALIPURDUAR',
  'KADAVANTHRA',
  'tuting',
  'THIRUPPALAI',
  'Narketpally',
  'NADUVANNOOR',
  'Srungavarapukota',
  'Delhi',
  'Raiwala',
  'Mouda',
  'NAGERCOIL',
  'Chromepet',
  'Kaikolapalayam',
  'KAIKARAM',
  'Diyodar',
  'Krishnapuram',
  'NETTAYAM',
  'Kulathupuzha',
  'Rasoolabad',
  'Ulloor',
  'MARAYOOR',
  'VIDYANAGAR',
  'MEZHUVELI',
  'Chirayinkeezhu',
  'LALPANIA',
  'Maniyathahalli',
  'PTPS Patratu',
  'Sector 11',
  'Vanagaram',
  'PEDAVEGI,',
  'Ziyamvalasa',
  'K. Kotapadu',
  'Mugappair',
  'Yatapaka',
  'Vatsavai',
  'Porali',
  'Kodom Khullen',
  'LANKAM',
  'Jayant',
  'Vaduj',
  'Vedurapalli',
  'Kombakadu',
  'Thane',
  'Moobakhon',
  'Karlapalem',
  'West godavari',
  'Palatipadu',
  'PETTAH',
  'Khochi',
  'Vilayur',
  'Sangli',
  'YALAVARU',
  'Santhabommali',
  'Aganampudi',
  'KEELAPALUR',
  'NATHNAGAR',
  'Eppothum vendran',
  'MUDDANUR',
  'Ekma',
  'Dichpally',
  'Sankepalli',
  'Annamanada',
  'UNDRAJAVARAM',
  'Jamshedpur',
  'Arwal',
  'diskal',
  'Kodur',
  'AMARAPURAM',
  'Pangal',
  'Viyyur',
  'Panchkula',
  'Padalur',
  'Khandwa',
  'THEYYALINGAL',
  'RAJIM',
  'Crawford',
  'Phirangipuram',
  'Warangal',
  'KUSUMASAMUDRAM',
  'THOTAPALLI',
  'Kallur',
  'Konathukunnnu',
  'Malkhed Road',
  'KOLLIVALSA',
  'Idukki',
  'BIHARSHARIF',
  'Annavaram',
  'Kechery',
  'CHANDGAD',
  'Undi',
  'KGF',
  'Ranasthalam',
  'ROHINI',
  'THANDARAMPET',
  'PALA',
  'Marutha road',
  'venganoor',
  'choppadandi',
  'TADA',
  'Alampuram',
  'Goregaon',
  'Silli',
  'Alur',
  'VIJAYANAGAR',
  'seri lingampally',
  'Kavanad',
  'TEEPARRU',
  'WANDOOR',
  'Sathupalli',
  'THELLAKOM',
  'Anekal',
  'Amlai',
  'Marlava',
  'CHILLAKUR',
  'Habsiguda',
  'Vidavalur',
  'Chittapur',
  'Nellore',
  'Narakkal',
  'Nanded',
  'Hapur',
  'Mayabunder',
  'Others',
  'Karmasi',
  'Sawa',
  'Cherkala',
  'Panama godams',
  'Mahe',
  'Kothakota',
  'Khetri Nagar',
  'VILLIVAKKAM',
  'BHAJERA',
  'Kalavalapalli',
  'Abohar',
  'Jahalda',
  'Parbatta',
  'Minapur',
  'Uttam Nagar',
  'VISHNU GARDEN',
  'puraini',
  'Mandalaparru',
  'mohania',
  'Kondazhy',
  'Varusanadu',
  'Rajoun',
  'Vadapalani',
  'RAMAPURAM BAZAR',
  'RAMAPURAM',
  'mirzapur',
  'Raggsbsm',
  'Mahishadal',
  'Bharanikavu south',
  'Kattanam',
  'Jinnuru',
  'Kolathur',
  'Gunnampalli',
  'PIRPAINTI',
  'ladugaon',
  'Chabala',
  'Karmatar',
  'Velgatoor',
  'Others',
  'Sakra',
  'Shahdara',
  'Kadayam',
  'Mansarovar park',
  'Siliguri',
  'Corlim',
  'Jayyaram',
  'Thanisandra',
  'Basuki Bihari',
  'Ramgarhwa',
  'Phulparas',
  'Rly kodur',
  'New Delhi',
  'ASHTAMUDY',
  'Jhotwara',
  'Chempazhanthy',
  'Munshiganj',
  'Umrain',
  'Mukkolakkal',
  'Kamtaul',
  'Simultala',
  'Mullugu',
  'Rajendragram',
  'ANGAADIPPURAM',
  'Ambalavayal',
  'Sabbavaram',
  'Kairana',
  'Trivandrum',
  'SUNEL',
  'CHIMAKURTHY',
  'hasanparthy',
  'Hosur',
  'Moti nagar',
  'Nellimarla',
  'Mupkal',
  'Vangoor mandal',
  'Patiyali',
  'Pundi',
  'Nilamel',
  'Mamoodu',
  'Annavarappadu',
  'TANDA',
  'NEENDAKARA',
  'Vellanad',
  'Srungavruksham',
  'Sriniwaspuri',
  'CHALAD',
  'Nagarjuna Sagar',
  'Bijapur',
  'KALLAMBALAM',
  'MENDORA',
  'Arakatavemula',
  'Ganapavaram',
  'Ollaipadi',
  'Itarhi',
  'Anuvijay Township',
  'Tenkasi District',
  'SHARJAH',
  'Singheshwar',
  'katoria',
  'MUZAFFARNAGAR',
  'VELLANKI',
  'Thoguta',
  'Pratappur',
  'KALIABOR',
  'Velampalayam',
  'bhadradi-kothagudem',
  'ADAT',
  'VIRAGANUR',
  'KUNDALAHALLI',
  'Kadammanitta',
  'VELLANKI',
]

const genderValues = ['Female', 'Male', 'Other', 'Prefer not to say']

const schoolGradeValues = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  'Other',
]

const collegeYearValues = [
  'Year 1',
  'Year 2',
  'Year 3',
  'Year 4',
  'Year 5',
  'Year 6',
  'Other',
]

const coachDesignationValues = [
  'Teacher',
  'Principal',
  'Parent',
  'Sibling',
  'Other',
]

const institutionTypeValues = ['School', 'College']

const sourceValues = [
  'Facebook',
  'Instagram',
  'WhatsApp',
  'LinkedIn',
  'Friend',
  'Teacher',
  'Parent',
  'Edu Ace',
  'Mindler',
  'Edu Devs',
  'India Today',
  'CodeDrills',
  'Others',
]

const amritaCampusValues = [
  'Amrita School of Engineering, Amritapuri',
  'Amrita School of Engineering, Bengaluru',
  'Amrita School of Engineering, Coimbatore',
  'Amrita School of Engineering, Chennai',
  'Amrita School of Computing, Amritapuri',
  'Amrita School of Computing, Amaravati',
  'Amrita School of Computing, Bengaluru',
  'Amrita School of Artificial Intelligence, Amritapuri',
  'Amrita School of Artificial Intelligence, Coimbatore',
  'Amrita School of Artificial Intelligence, Bengaluru',
  'Amrita School of Arts & Sciences, Kochi',
  'Amrita School of Arts & Sciences, Mysuru',
]

const yesNoValues = ['Yes', 'No']

const autocompleteValues = {
  city: cityValues,
  coachDesignation: coachDesignationValues,
  collegeYear: collegeYearValues,
  country: countryValues,
  gender: genderValues,
  institutionType: institutionTypeValues,
  school: schoolValues,
  schoolGrade: schoolGradeValues,
  state: stateValues,
  source: sourceValues,
  yesNo: yesNoValues,
  amritaCampus: amritaCampusValues,
}

export default autocompleteValues
