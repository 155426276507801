var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ma-2 text-subtitle-1 black--text",
    attrs: {
      "color": "white"
    }
  }, [!_vm.wide ? _c('v-icon', {
    attrs: {
      "large": "",
      "left": "",
      "color": _vm.iconColor
    }
  }, [_vm._v(" " + _vm._s(_vm.iconName) + " ")]) : _c('v-icon', {
    staticClass: "pr-2",
    attrs: {
      "color": _vm.iconColor
    }
  }, [_vm._v(" " + _vm._s(_vm.cancelPres) + " ")]), _c('span', {
    staticClass: "black--text font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.labelText) + " ")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }