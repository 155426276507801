<template>
  <span>
    <v-row>
      <v-col>
        Continue where you left off, track your progress on all the active
        drills you are practicing
      </v-col>
    </v-row>

    <v-row v-if="activeDrills">
      <v-col
        :xs="12"
        :sm="6"
        :md="3"
        v-for="drill in dashboardView.getInProgressDrillPreviewsList()"
        v-bind:key="drill.getContentPreview().getId()"
      >
        <v-card
          id="prev"
          outlined
          width="270"
          height="100"
          :to="'/drills/' + drill.getContentPreview().getUrl()"
        >
          <div class="d-flex flex-no-wrap">
            <v-avatar class="mt-3 ml-3" size="imageWidth" tile>
              <v-img
                :src="drill.getContentPreview().getMetaData().getImageUrl()"
              ></v-img>
            </v-avatar>
            <div>
              <v-card-title class="text-caption-1">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <span
                      v-bind:style="{ width: titleWidth + 'px' }"
                      class="d-inline-block text-truncate"
                      v-on="on"
                    >
                      {{ drill.getContentPreview().getTitle() }}
                    </span>
                  </template>
                  {{ drill.getContentPreview().getTitle() }}
                </v-tooltip>
              </v-card-title>
              <v-card-subtitle>
                {{
                  drill.getUserActivity() &&
                  drill
                    .getUserActivity()
                    .getData()
                    .getDrillReport()
                    .getCompletedContentCount()
                }}/{{ totalContent(drill) }} Completed <br />
              </v-card-subtitle>
            </div>
          </div>
          <v-progress-linear
            bottom
            absolute
            color="accent"
            class="mt-5"
            :value="progress(drill)"
            height="10"
          ></v-progress-linear>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col>
        <status-card
          startNow="/drills"
          :height="100"
          :width="119"
          title="Drills"
          cardText="Start a drill to track progress here"
          imageName="Practice"
        ></status-card>
      </v-col>
    </v-row>
  </span>
</template>
<script>
import StatusCard from '../components/StatusCard'

export default {
  props: {
    dashboardView: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      titleWidth: 0,
      imageWidth: 0,
    }
  },
  computed: {
    activeDrills() {
      if (this.dashboardView) {
        return (
          this.dashboardView &&
          this.dashboardView.getInProgressDrillPreviewsList().length > 0
        )
      }
    },
  },
  methods: {
    isCompanyDrill(drill) {
      var tmp =
        drill
          .getContentPreview()
          .getMetaData()
          .getTagsList()
          .indexOf('drills/company') != -1
      console.log('tag...', drill.getContentPreview().getTitle(), tmp)
      return tmp
    },
    progress(drill) {
      if (drill && drill.getUserActivity()) {
        var sol =
          drill
            .getUserActivity()
            .getData()
            .getDrillReport()
            .getCompletedContentCount() / this.totalContent(drill)
        return sol * 100
      } else {
        return 0
      }
    },
    totalContent(drill) {
      if (drill && drill.getContentPreview().getMetaData().getDrillMeta()) {
        return drill
          .getContentPreview()
          .getMetaData()
          .getDrillMeta()
          .getTotalContent()
      } else {
        return 1
      }
    },
    getCardWidth() {
      if (this.activeDrills) {
        this.titleWidth = (document.getElementById('prev').clientWidth * 3) / 5
        this.imageWidth = (document.getElementById('prev').clientWidth * 1) / 5
      }
    },
  },
  mounted() {
    this.getCardWidth()
  },
  components: {
    StatusCard,
  },
}
</script>
