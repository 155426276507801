<template>
  <carousel3d width="800" height="370" autoplay :autoplayTimeout="7000" autoplayHoverPause controlsVisible>
    <slide v-for="(item, index) in items" class = "d-flex pa-5 slide" :class="{'slide-black': index % 2 === 0, 'slide-white': index % 2 ===1}" :index="index">
      <div class="d-flex order-first">
        <div class="d-flex flex-column justify-center">
          <div class="font-weight-bold">{{ item.name }}</div>
          <div>{{ item.subtitle }}</div>
          <v-rating :value="item.rating" class="mt-2" size="32" color="primary" dense readonly></v-rating>
          <div class="mt-7">{{ item.review }}</div>
        </div>
      </div>
      <div class="d-flex justify-end align-end" style="width: 200px">
        <v-img contain :src="item.src" class="avatar-image"/>
      </div>
    </slide>
  </carousel3d>
</template>
<script>
import { Carousel3d, Slide } from 'vue-carousel-3d';

export default {
  data() {
    return {
      items: [
        {
          src: require('../../../assets/images/reviews/maheshwara.png'),
          name: 'Maheshwara Chaitanya',
          subtitle: 'Contest Director, Amrita Vishwa Vidyapeetham',
          review: 'It has been a really awesome journey with CodeDrills. We used this platform for the world\'s largest regional contest hosted by Amrita University. While there were glitches faced in other platforms, CodeDrills has been a game changer. With more than 15000 users participating in the online round in 2021, CodeDrills had zero glitches. Wishing all the best to CodeDrills, the co-founder Deepa and her team, let it fly and reach the heights! It is because of their dedication that the platform has been very successful.',
          rating: 5,
        },
        {
          src: require('../../../assets/images/reviews/pritam.png'),
          name: 'Pritam Thakur',
          subtitle: 'Co-Founder, Ivy Homes',
          review:
            "Using CodeDrills, we set up our team of data scientists quickly. We used the customised tests to assess the candidate's skills accurately and save time",
          rating: 5,
        },
        {
          src: require('../../../assets/images/reviews/devayani.png'),
          name: 'Devayani Joshi',
          subtitle: 'CEO at TalkingMart',
          review:
            'Great tool for assessing Software Developers. CodeDrills provided a very easy test creation process and high quality test taking experience for candidates. It was convenient to be able to choose from pre-designed assessments which are of varying complexity and of high quality. It saved a lot of our time by making the screening process efficient. CodeDrills customer obession is amazing and they helped us at every step.',
          rating: 5,
        },
        {
          src: require('../../../assets/images/reviews/alice_wearex-2.png'),
          name: 'Alice Ferrero',
          subtitle: 'Co-founder of wearex',
          review:
            'Working with CodeDrills was very nice for your availability and responsiveness but also the quality of the time you dedicated on helping us. We needed help to compare different tech candidatures and without hesitation CodeDrills sent us a review of each profile which supported us in our decision.',
          rating: 5,
        },
        {
          src: require('../../../assets/images/reviews/vaishnavi.png'),
          name: 'Vaishnavi Reddy',
          subtitle: 'Co-founder of Unsweetened Beauty',
          review: 
           'Deepa is extremely responsive and very helpful. She was really nice to speak to and helped us connect with a good tech candidate. We would be using CodeDrills going forward to hire for other tech positions in our company.',
          rating: 5,
        },
      ],
    }
  },
  components: {
    Carousel3d,
    Slide,
  },
}
</script>
<style scoped>

.slide-black {
  background-color: black;
  background-image: url('../../../assets/images/review_black_bg.png');
  background-size: 120% 170%;
  background-position: center;
  color: white;
}

.slide-white {
  background-color: white;
  background-image: url('../../../assets/images/review_white_bg.png') !important;
  background-size: 120% 170%;
  background-position: center;
  color: black;
}

.slide {
  border-radius: 2%;
}

.avatar-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
</style>

