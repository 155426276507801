var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-card', {
    staticClass: "px-2",
    attrs: {
      "outlined": "",
      "height": 75
    }
  }, [_c('v-row', {
    staticClass: "pt-2",
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_vm.autopick ? _c('v-col', {
    attrs: {
      "cols": 4,
      "md": 2
    }
  }, [_c('a', {
    staticClass: "link_btn font-weight-bold",
    attrs: {
      "color": "accent"
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.pickAProblem.apply(null, arguments);
      }
    }
  }, [_vm._v(" Auto pick a problem ")])]) : _vm._e(), _c('v-spacer'), _vm.showOption ? _c('v-col', {
    staticClass: "hidden-sm-and-down",
    attrs: {
      "cols": 3,
      "lg": 2
    }
  }, [_c('v-checkbox', {
    attrs: {
      "label": "Show tags"
    },
    model: {
      value: _vm.showTags,
      callback: function ($$v) {
        _vm.showTags = $$v;
      },
      expression: "showTags"
    }
  })], 1) : _vm._e(), _vm.showOption ? _c('v-col', {
    staticClass: "hidden-sm-and-down",
    attrs: {
      "cols": 4,
      "lg": 3
    }
  }, [_c('v-checkbox', {
    attrs: {
      "label": "Hide solved problems"
    },
    model: {
      value: _vm.hideSolved,
      callback: function ($$v) {
        _vm.hideSolved = $$v;
      },
      expression: "hideSolved"
    }
  })], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": 12,
      "lg": 3,
      "sm": 8,
      "md": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "append-icon": _vm.mdiMagnify,
      "label": _vm.live ? 'Search' : 'Search (title, tags)',
      "clearable": "",
      "single-line": "",
      "outlined": ""
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1)], 1)], 1), _c('v-data-table', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.problemsObject,
      "footer-props": _vm.footerOptions,
      "hide-default-footer": _vm.hideFooter,
      "search": _vm.search,
      "custom-sort": _vm.customSort
    },
    on: {
      "update:footerProps": function ($event) {
        _vm.footerOptions = $event;
      },
      "update:footer-props": function ($event) {
        _vm.footerOptions = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('tr', [_vm.showIds ? _c('td', [_c('div', {
          staticClass: "status_dot",
          class: _vm.getStatusColor(item)
        }), _vm._v(" " + _vm._s(item.contentPreview.orderId) + " ")]) : _vm._e(), _c('td', [!_vm.showIds ? _c('div', {
          staticClass: "status_dot",
          class: _vm.getStatusColor(item)
        }) : _vm._e(), _c('router-link', {
          staticClass: "ml-1 problem_link",
          attrs: {
            "to": _vm.urlPrefix + '/problems/' + item.contentPreview.url
          }
        }, [_vm._v(" " + _vm._s(item.contentPreview.title) + " ")])], 1), _vm.$vuetify.breakpoint.lgAndUp && _vm.showSolved ? _c('td', [_vm._v(" " + _vm._s(_vm.solved(item)) + " ")]) : _vm._e(), _vm.$vuetify.breakpoint.lgAndUp ? _c('td', [_vm._v(" " + _vm._s(item.contentPreview.metaData.difficulty) + " ")]) : _vm._e(), !_vm.live && _vm.$vuetify.breakpoint.lgAndUp ? _c('td', {
          attrs: {
            "colspan": 3
          }
        }, _vm._l(item.contentPreview.metaData.tagsList, function (t) {
          return _c('v-chip', {
            key: t,
            staticClass: "mr-2",
            attrs: {
              "outlined": "",
              "color": "grey lighten-2",
              "text-color": "grey",
              "label": ""
            },
            on: {
              "click": _vm.searchTag
            }
          }, [_vm._v(" " + _vm._s(t) + " ")]);
        }), 1) : _vm._e()])];
      }
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }