<template>
  <span>
    <v-row class="ml-4" v-if="registrationContest">
      <v-col class="text-caption-1 font-weight-light">
        <v-icon>{{ mdiCalendar }}</v-icon>
        {{ formatDate(contestMeta.getStartAt()) }}
      </v-col>
    </v-row>
    <v-row class="ml-4" v-else>
      <v-col class="text-caption-1 font-weight-light">
        <v-icon>{{ mdiCalendar }}</v-icon>
        {{ formatDate(contestMeta.getStartAt()) }} -
        {{ formatDate(contestMeta.getEndAt()) }}
        <v-icon class="ml-2">{{ mdiClock }}</v-icon>
        {{ contestDuration(contestMeta.getStartAt(), contestMeta.getEndAt()) }}
      </v-col>
    </v-row>
    <v-row v-if="!upcomingContest && !isScoreboard && !registrationContest">
      <!-- <v-col :cols="3" :lg="2" class="mr-4">
				<v-btn color="accent">Share</v-btn>
			</v-col> -->
      <v-col
        :cols="12"
        :sm="3"
        :lg="2"
        v-bind:class="{ 'text-center': $vuetify.breakpoint.mobile }"
      >
        <v-btn
          color="accent"
          router-link
          :to="'/contests/' + this.contestUrl + '/scoreboard'"
        >
          Scoreboard
        </v-btn>
      </v-col>
    </v-row>
  </span>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import moment from 'moment'
import { mdiCalendar, mdiClock } from '@mdi/js'

export default {
  data() {
    return {
      mdiCalendar: mdiCalendar,
      mdiClock: mdiClock,
    }
  },
  props: {
    contentMeta: {
      type: Object,
      required: true,
    },
    contestUrl: {
      type: String,
      required: true,
    },
    isScoreboard: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapState('timesync', ['serverTime']),
    upcomingContest() {
      return (
        !!this.contestMeta && this.contestMeta.getStartAt() > this.serverTime
      )
    },
    registrationContest() {
      return this.contentMeta?.getTagsList()?.includes('types/registration')
    },
    contestMeta() {
      return this.contentMeta.getContestMeta()
    },
  },
  methods: {
    formatDate(value) {
      if (value) {
        return moment.unix(value).format('D MMM YYYY, ddd HH:mm z')
      }
    },
    contestDuration(start, end) {
      var diff = end - start
      return (
        Math.floor(diff / (60 * 60)) +
        ' hr ' +
        Math.floor((diff % (60 * 60)) / 60) +
        ' mins'
      )
    },
  },
  mounted() {
    // console.log("Data ...", this.contestMeta, this.contestUrl);
  },
  components: {},
}
</script>
<style scoped></style>
