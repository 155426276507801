var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": 6,
      "sm": 4,
      "md": 2
    }
  }, [_c('v-list-item', {
    attrs: {
      "two-line": ""
    }
  }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" To-do ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.todo) + " ")])], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": 6,
      "sm": 4,
      "md": 2
    }
  }, [_c('v-list-item', {
    attrs: {
      "two-line": ""
    }
  }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" Completed ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.completed) + " ")])], 1)], 1)], 1)], 1), _c('v-row', [_c('v-col', [_c('v-card', {
    staticClass: "pl-5 small"
  }, [_c('pie-chart', {
    attrs: {
      "data": _vm.submissionStats,
      "options": _vm.submissionChartOptions
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }