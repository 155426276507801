var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('carousel3d', {
    attrs: {
      "width": "800",
      "height": "370",
      "autoplay": "",
      "autoplayTimeout": 7000,
      "autoplayHoverPause": "",
      "controlsVisible": ""
    }
  }, _vm._l(_vm.items, function (item, index) {
    return _c('slide', {
      staticClass: "d-flex pa-5 slide",
      class: {
        'slide-black': index % 2 === 0,
        'slide-white': index % 2 === 1
      },
      attrs: {
        "index": index
      }
    }, [_c('div', {
      staticClass: "d-flex order-first"
    }, [_c('div', {
      staticClass: "d-flex flex-column justify-center"
    }, [_c('div', {
      staticClass: "font-weight-bold"
    }, [_vm._v(_vm._s(item.name))]), _c('div', [_vm._v(_vm._s(item.subtitle))]), _c('v-rating', {
      staticClass: "mt-2",
      attrs: {
        "value": item.rating,
        "size": "32",
        "color": "primary",
        "dense": "",
        "readonly": ""
      }
    }), _c('div', {
      staticClass: "mt-7"
    }, [_vm._v(_vm._s(item.review))])], 1)]), _c('div', {
      staticClass: "d-flex justify-end align-end",
      staticStyle: {
        "width": "200px"
      }
    }, [_c('v-img', {
      staticClass: "avatar-image",
      attrs: {
        "contain": "",
        "src": item.src
      }
    })], 1)]);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }