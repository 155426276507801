var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }), _c('v-col', {
    attrs: {
      "cols": "1"
    }
  }, [_vm.showLoader ? _c('v-progress-circular', {
    attrs: {
      "color": "accent",
      "indeterminate": "",
      "size": "70",
      "width": "10"
    }
  }) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }