// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/review_black_bg.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/images/review_white_bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".slide-black[data-v-4968afe6]{background-color:#000;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");color:#fff}.slide-black[data-v-4968afe6],.slide-white[data-v-4968afe6]{background-size:120% 170%;background-position:50%}.slide-white[data-v-4968afe6]{background-color:#fff;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")!important;color:#000}.slide[data-v-4968afe6]{border-radius:2%}.avatar-image[data-v-4968afe6]{width:100%;height:200px;-o-object-fit:cover;object-fit:cover}", ""]);
// Exports
module.exports = exports;
