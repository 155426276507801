<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col>
          <span v-if="user">
            <v-btn
              color="#BCECE0"
              size="35"
              class="text-capitalize"
              v-if="
                userActivity &&
                userActivity.getData() &&
                userActivity.getData().getDrillReport().getTracking()
              "
              @click="updateActivityStatus(false)"
            >
              Stop tracking
            </v-btn>
            <v-btn
              color="#BCECE0"
              size="35"
              class="text-capitalize"
              v-else
              @click="updateActivityStatus(true)"
            >
              Start tracking
            </v-btn>
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col> About </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card outlined>
            <v-card-text>
              <html-view
                :html="
                  contentView.getDataView().getDrillView().getDescriptionHtml()
                "
              ></html-view>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-expansion-panels
            v-model="panel"
            outlined
            accordion
            focusable
            multiple
          >
            <v-expansion-panel
              class="mt-5"
              v-for="section in contentView
                .getDataView()
                .getDrillView()
                .getSectionsList()"
              :key="section.getTitle()"
            >
              <v-expansion-panel-header
                ><html-view :html="section.getTitle()"></html-view
              ></v-expansion-panel-header>
              <v-expansion-panel-content class="mt-5">
                <html-view :html="section.getDescriptionHtml()"></html-view>
                <problem-list
                  :problems="problemList(section.getContentViewsList())"
                  :urlPrefix="contentView.getUrl()"
                ></problem-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import Page from '../components/Page'
import HtmlView from '../components/HtmlView'
import ProblemList from '../components/ProblemList'
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'

export default {
  props: {
    contentView: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapState('user', ['userActivity']),
  },
  data: function () {
    return {
      panel: [0],
    }
  },
  methods: {
    ...mapActions('user', ['setTrackingStatus', 'fetchUserActivity']),
    problemList(drillContentViewList) {
      if (!drillContentViewList) return []
      return drillContentViewList.map((d) => d.getUserContentPreview())
    },
    updateActivityStatus(isTracking) {
      console.log('tacking', isTracking)
      this.setTrackingStatus({
        id: this.contentView.getId(),
        isTracking: isTracking,
      })
    },
  },
  mounted() {
    this.fetchUserActivity({ id: this.contentView.getId() })
  },
  components: {
    Page,
    HtmlView,
    ProblemList,
  },
}
</script>
