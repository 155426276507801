<template>
  <div>
    <div class="py-8 text-h5 font-weight-bold text-center">Pricing</div>
    <v-tabs v-model="pricingTab" class="text-body-1">
      <v-tab> Free </v-tab>
      <v-tab> Premium </v-tab>
    </v-tabs>
    <v-tabs-items v-model="pricingTab" class="pa-5">
      <v-tab-item class="mb-5">
        <v-row class="mb-5">
          <div class="text-h5 font-weight-medium py-3">
            Test Our Platform with our Free Tier
          </div>
        </v-row>
        <v-row>
          <span class="font-italic font-weight-medium">
            To activate your free plan mail us at
            hello@codedrills.io
          </span>
        </v-row>
        <v-row class="text-body-2">
          <v-col cols="6">
            <ul>
              <li v-for="f in freeFeatures" :key="f" class="py-2">
                {{ f }}
              </li>
            </ul>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <!-- premium from here -->
        <v-row class="mb-5">
          <div class="text-h5 font-weight-medium py-3">
            Start Hiring Today
            <!-- (from
            <span class="primary--text">
              $99
              <span class="text-caption discounted red--text"> $199 </span>
            </span>
            ) -->
          </div>
        </v-row>
        <v-row>
          <span class="font-italic font-weight-medium">
            For Pricing details send an email to hello@codedrills.io
          </span>
        </v-row>
        <v-row align="center" class="text-h6">
          <!-- <v-col cols="auto" class="py-3 mx-1 full__border text-center">
            Monthly (
            <span class="primary--text">
              ${{ totalPrice }}
              <span class="text-caption discounted red--text">
                ${{ nonDiscountedPrice }}
              </span>
            </span>
            )
          </v-col> -->

          <!-- <v-col cols="auto" class="py-3 mx-1 full__border text-center">
            Annually (
            <span class="primary--text">
              ${{ annualTotalPrice }}
              <span class="text-caption discounted red--text">
                ${{ annualNonDiscountedPrice }}
              </span>
            </span>
            )
          </v-col> -->
        </v-row>
        <v-row class="mb-5 text-body-2">
          <v-col cols="6">
            <ul>
              <li v-for="f in features" :key="f" class="py-2">
                {{ f }}
              </li>
            </ul>
          </v-col>

          <!-- <v-col cols="6">
            <ul style="list-style: none">
              <li class="text-body-1 font-weight-medium pb-3">Addons</li>
              <li
                v-for="(addon, idx) in addons"
                :key="addon.label"
                class="pa-0 ma-0 text-body-2"
              >
                <v-checkbox
                  v-model="selectedAddons"
                  :value="idx"
                  :label="addon.label"
                  class="pa-0 ma-0"
                ></v-checkbox>
              </li>
            </ul>
          </v-col> -->
        </v-row>
        <!-- <v-row class="pa-5 mt-5 d-flex justify-space-around">
          <v-btn
            class="text-none text-body-1 px-5 py-3"
            color="primary"
            elevation="0"
          >
            Select Plan
          </v-btn>
          <v-spacer></v-spacer>
        </v-row> -->
      </v-tab-item>
      <hr />
    </v-tabs-items>
  </div>
</template>
<script>
export default {
  data() {
    return {
      pricingTab: 1,
      // 0 for monthly, 1 for annually
      basePrice: 199,
      addonTotal: 0,
      freeFeatures: [
        '5 candidates',
        '1 assessments',
        '3 admins',
        'Sample skills',
        'Multiple question types - MCQ, coding',
      ],
      features: [
        'Custom number of candidates',
        'Custom number of tests',
        'Custom number admins',
        'Standard/custom Skills library',
        'Candidate Pipeline',
        'Proctoring',
        'Detailed Test Report',
        'Limited Live Code Pairing with Audio',
        'Account Roles and Permission',
        'Centralized Billing',
        'Analytics Dashboard',
        'Priority Support via email and chat',
      ],
      selectedAddons: [],
      addons: [
        // { price: 39, label: 'Unlimited Admins' },
        // { price: 399, label: 'Exclusive Skills Library' },
        // { price: 199, label: 'Advanced Proctoring' },
        // { price: 99, label: 'Bulk operations' },
        // { price: 13, label: "Custom Branding" },
        // { price: 14, label: "ATS Integration" },
        // { price: 15, label: "SAML Single Sign-On" },
        // { price: 16, label: "On Call Customer Support" },
        // { price: 17, label: "Domain Authorization" },
        // {
        //   price: 18,
        //   label: "Unlimited Live code pairing with audio and video",
        // },
        // { price: 19, label: "Export and import data & Bulk user management" },
        // { price: 20, label: "Dedicated customer success manager" },
      ],
    }
  },
  computed: {
    annually() {
      return this.pricingTab === 1
    },
    totalPrice() {
      return this.basePrice + this.addonTotal
    },
    annualTotalPrice() {
      return (
        (this.basePrice + 1 + this.addonTotal + this.selectedAddons.length) /
          2 -
        1 -
        this.selectedAddons.length
      )
    },
    nonDiscountedPrice() {
      return (
        this.totalPrice + 100 + this.addonTotal + this.selectedAddons.length
      )
    },
    annualNonDiscountedPrice() {
      return (
        this.annualTotalPrice +
        100 +
        this.addonTotal +
        this.selectedAddons.length
      )
    },
  },
  watch: {
    selectedAddons() {
      this.addonTotal = 0
      this.selectedAddons.forEach((idx) => {
        this.addonTotal += this.addons[idx].price
      })
    },
  },
}
</script>
<style scoped>
.full__border {
  border: 2px solid #c4c4c4;
  border-radius: 1rem;
}
.discounted {
  text-decoration: line-through;
}
</style>
