<template>
  <span>
    <page
      class="ml-4"
      :title="title"
      :apiStatusList="[]"
      :loading="initialLoading"
      :breadcrumbs="breadcrumbs"
    >
      <template v-slot:subtitle v-if="hasSubtitle">
        <contest-subtitle
          v-if="scoreboard.getContestPreview().getMetaData()"
          :contentMeta="scoreboard.getContestPreview().getMetaData()"
          :contestUrl="scoreboard.getContestPreview().getUrl()"
          :isScoreboard="true"
        ></contest-subtitle>
      </template>
      <template v-slot:appendBanner v-if="hasBanner">
        <mascot :preposition="preposition" :time="remainingTime"></mascot>
      </template>
      <error-message :ex="ex"></error-message>
      <v-row v-if="isPrivateScoreboard(scoreboard)">
        <v-col>
          <v-alert type="info">
            The scoreboard is private. You can only view your own score and not
            of others.
          </v-alert>
        </v-col>
      </v-row>
      <v-row
        class="mt-5"
        v-if="userOrTeam.id && !isPrivateScoreboard(scoreboard)"
      >
        <v-col :cols="12" sm:8 :lg="6">
          <v-card>
            <v-data-table
              :hide-default-footer="true"
              :headers="userOrTeamHeaders"
              :items="userOrTeamScores"
            >
              <template v-slot:item="{ item }">
                <tr class="text-center">
                  <td>
                    {{ item.rank }}
                  </td>
                  <td>
                    <span>
                      <span class="font-weight-bold">{{
                        prettyScore(item.score)
                      }}</span>
                      <br />
                      <span class="grey--text">{{
                        prettyPenalty(item.penalty)
                      }}</span>
                    </span>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="mt-5">
        <v-col>
          <v-card>
            <v-card outlined :height="75" class="px-2">
              <v-row no-gutters class="pt-2">
                <v-col class="mt-2 ml-2" v-if="$vuetify.breakpoint.mdAndUp">
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon color="accent" large v-bind="attrs" v-on="on">
                        <v-icon
                          x-large
                          class="text-right"
                          @click="fetchScoreboard"
                          >{{ mdiCached }}</v-icon
                        >
                      </v-btn>
                    </template>
                    <span color="red">Refresh Scoreboard</span>
                  </v-tooltip>
                </v-col>
                <v-col
                  :cols="12"
                  :lg="4"
                  :sm="8"
                  :md="5"
                  class="mt-3"
                  v-if="$vuetify.breakpoint.mdAndUp"
                >
                  <span class="grey--text">Refreshed {{ lastRefreshed }} </span>
                </v-col>
                <v-spacer></v-spacer>
                <v-col :cols="12" :lg="3" :sm="8" :md="4">
                  <v-text-field
                    v-if="!isPrivateScoreboard(scoreboard)"
                    v-model="pagination.search"
                    :append-icon="mdiMagnify"
                    label="Full name/org not partial"
                    clearable
                    single-line
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card>
            <v-data-table
              v-bind:class="{
                teamDataTable: isTeamContest,
                userDataTable: !isTeamContest,
              }"
              :page="pagination.pageIndex"
              :pageCount="pageCount"
              :server-items-length="pagination.total"
              :headers="headers"
              :items="userScores"
              :footer-props.sync="footerOptions"
              :search="pagination.search"
              :loading="pageLoading"
              :options.sync="tableOptions"
            >
              <template v-slot:header.links="{ header }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-on="on" v-bind="attrs">
                      <router-link
                        v-if="scoreboard.getContestPreview()"
                        class="problem_link d-flex justify-center mt-4 text-center"
                        :to="
                          '/contests/' +
                          scoreboard.getContestPreview().getUrl() +
                          '/problems/' +
                          header.url
                        "
                      >
                        {{ header.text }}
                      </router-link>
                    </span>
                  </template>
                  <span>{{ header.title }}</span>
                </v-tooltip>
              </template>

              <template v-slot:item="{ item }">
                <tr>
                  <td
                    v-bind:class="{
                      userScoreHighlighting: isUserScoreHighlighting(item),
                    }"
                  >
                    {{ isPrivateScoreboard(scoreboard) ? '-' : item.rank }}
                  </td>
                  <td
                    v-bind:class="{
                      userScoreHighlighting: isUserScoreHighlighting(item),
                    }"
                  >
                    <router-link
                      v-if="isTeamContest"
                      :to="teamUrl(item)"
                      class="text-decoration-none router_link"
                    >
                      {{ getName(item) }}
                    </router-link>
                    <router-link
                      v-else-if="item.userPreview.handle"
                      :to="`/profiles/${item.userPreview.handle}`"
                      class="text-decoration-none router_link"
                    >
                      {{ item.userPreview.handle }}
                    </router-link>

                    <span v-else>
                      {{ getName(item) }}
                    </span>
                  </td>
                  <td
                    v-if="isTeamContest"
                    v-bind:class="{
                      userScoreHighlighting: isUserScoreHighlighting(item),
                    }"
                  >
                    {{ teamOrganization(item) }}
                  </td>
                  <td
                    v-bind:class="{
                      userScoreHighlighting: isUserScoreHighlighting(item),
                    }"
                    class="text-center"
                  >
                    <span>
                      <span class="font-weight-bold">
                        {{ prettyScore(item.score) }}
                      </span>
                      <br />
                      <span class="grey--text">{{
                        prettyPenalty(item.penalty)
                      }}</span>
                    </span>
                  </td>
                  <td
                    v-for="ps in problemScoresList"
                    :key="ps.getProblemId()"
                    class="text-center"
                    v-bind:class="{
                      userScoreHighlighting: isUserScoreHighlighting(item),
                    }"
                  >
                    <span v-if="item.userProblemScoresMap[ps.getProblemId()]">
                      <router-link
                        class="text-decoration-none"
                        v-if="
                          isShowUrl(
                            item.userProblemScoresMap[ps.getProblemId()],
                          )
                        "
                        :to="
                          submissionUrl(
                            item.userProblemScoresMap[ps.getProblemId()],
                          )
                        "
                      >
                        <span
                          v-if="
                            item.userProblemScoresMap[ps.getProblemId()]
                              .score === 0
                          "
                          class="font-weight-bold red--text"
                        >
                          {{
                            prettyScore(
                              item.userProblemScoresMap[ps.getProblemId()]
                                .score,
                            )
                          }}
                          ({{
                            item.userProblemScoresMap[ps.getProblemId()]
                              .totalSubmissions
                          }})
                        </span>
                        <span
                          v-else-if="
                            item.userProblemScoresMap[ps.getProblemId()].score >
                              0 &&
                            item.userProblemScoresMap[ps.getProblemId()]
                              .totalSubmissions === 1
                          "
                          class="font-weight-bold green--text"
                        >
                          {{
                            prettyScore(
                              item.userProblemScoresMap[ps.getProblemId()]
                                .score,
                            )
                          }}
                        </span>
                        <span v-else class="font-weight-bold green--text">
                          {{
                            prettyScore(
                              item.userProblemScoresMap[ps.getProblemId()]
                                .score,
                            )
                          }}
                          ({{
                            item.userProblemScoresMap[ps.getProblemId()]
                              .totalSubmissions - 1
                          }})
                        </span>
                        <br />
                        <span class="grey--text">
                          {{
                            prettyPenalty(
                              item.userProblemScoresMap[ps.getProblemId()]
                                .solvedAt,
                            )
                          }}
                        </span>
                      </router-link>
                      <span v-else>
                        <span
                          v-if="
                            item.userProblemScoresMap[ps.getProblemId()]
                              .score === 0
                          "
                          class="font-weight-bold red--text"
                        >
                          {{
                            prettyScore(
                              item.userProblemScoresMap[ps.getProblemId()]
                                .score,
                            )
                          }}
                          ({{
                            item.userProblemScoresMap[ps.getProblemId()]
                              .totalSubmissions
                          }})
                        </span>
                        <span
                          v-else-if="
                            item.userProblemScoresMap[ps.getProblemId()].score >
                              0 &&
                            item.userProblemScoresMap[ps.getProblemId()]
                              .totalSubmissions === 1
                          "
                          class="font-weight-bold green--text"
                        >
                          {{
                            prettyScore(
                              item.userProblemScoresMap[ps.getProblemId()]
                                .score,
                            )
                          }}
                        </span>
                        <span v-else class="font-weight-bold green--text">
                          {{
                            prettyScore(
                              item.userProblemScoresMap[ps.getProblemId()]
                                .score,
                            )
                          }}
                          ({{
                            item.userProblemScoresMap[ps.getProblemId()]
                              .totalSubmissions - 1
                          }})
                        </span>
                        <br />
                        <span class="grey--text">
                          {{
                            prettyPenalty(
                              item.userProblemScoresMap[ps.getProblemId()]
                                .solvedAt,
                            )
                          }}
                        </span>
                      </span>
                    </span>
                    <span v-else> - </span>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </page>
  </span>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import Page from '@/components/Page.vue'
import ContestSubtitle from '@/components/ContestSubtitle'
import Mascot from '../components/Mascot'
import ErrorMessage from '../components/ErrorMessage.vue'
import { prettytime, prettyday, prettyScore } from '../utils/helper.js'
import { mdiMagnify } from '@mdi/js'
import { mdiCached } from '@mdi/js'
import moment from 'moment'

import 'codedrills_proto/io/codedrills/proto/site/content_view_service_grpc_web_pb'

const content_proto = proto.io.codedrills.proto.content

export default {
  props: {
    url: {
      value: String,
      required: true,
    },
  },
  data: function () {
    return {
      mdiCached: mdiCached,
      mdiMagnify: mdiMagnify,
      lastFetchedAt: Date.now(),
      userOrTeam: {
        id: null,
        rank: null,
        score: null,
        penalty: null,
      },
      footerOptions: {
        itemsPerPageOptions: [25, 50, 100],
      },
      ex: null,
      pagination: {
        pageIndex: 1,
        itemsPerPage: 25,
        total: 0,
        search: null,
      },
      tableOptions: {},
      pageLoading: false,
      initialLoading: true,
      staticHeaders: [
        {
          text: 'Rank',
          align: 'left',
          value: 'rank',
          sortable: false,
        },
        {
          text: 'User',
          align: 'left',
          value: 'userPreview.name',
          sortable: false,
        },
        {
          text: 'Score',
          align: 'center',
          value: 'score',
          class: 'table-header',
          sortable: false,
        },
      ],
      userOrTeamHeaders: [
        {
          text: 'My Rank',
          value: 'rank',
          align: 'center',
          sortable: false,
        },
        {
          text: 'My Score',
          value: 'score',
          align: 'center',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapState('content', ['scoreboard', 'fetchContestScoreboardStatus']),
    ...mapState('timesync', ['serverTime']),
    title() {
      if (this.scoreboard)
        return this.scoreboard.getContestPreview()
          ? this.scoreboard.getContestPreview().getTitle()
          : ''
      else return ''
    },
    isTeamContest() {
      if (this.scoreboard)
        return (
          this.scoreboard
            .getContestPreview()
            .getMetaData()
            .getContestMeta()
            .getContestType() == 1
        )
      else return false
    },
    userScores() {
      if (!this.scoreboard) return []
      var scores =
        this.scoreboard &&
        this.scoreboard.getUserScoresList().map((s) => {
          var us = s.toObject()
          us.userProblemScoresMap = {}
          us.userProblemScoresList.forEach((ps) => {
            us.userProblemScoresMap[ps.problemId] = ps
          })
          return us
        })
      console.log('userScores', scores)
      return scores
    },
    userOrTeamScores() {
      var arr = []
      arr.push(this.userOrTeam)
      return arr
    },
    headers() {
      var headers = [...this.staticHeaders]
      if (!this.scoreboard) return headers
      if (this.isTeamContest) {
        headers.splice(1, 1, {
          text: this.isSingleMemberTeam ? 'Contestant' : 'Team',
          value: 'teamPreview.name',
          sortable: false,
        })
        headers.splice(2, 0, {
          text: 'Organization',
          value: 'teamPreview.organizationsList[0]',
          sortable: false,
        })
      }
      this.problemScoresList.forEach((item, idx) => {
        headers.push({
          text: String.fromCharCode('A'.charCodeAt(0) + idx),
          value: 'links',
          url: item.getProblemPreview().getUrl(),
          title: item.getProblemPreview().getTitle(),
          class: idx % 2 == 1 ? 'table-header' : undefined,
          sortable: false,
        })
      })
      console.log('headers', headers)
      return headers
    },
    problemScoresList() {
      return (
        this.scoreboard &&
        this.scoreboard.getScoreboardStats() &&
        this.scoreboard.getScoreboardStats().getProblemScoresList()
      )
    },
    hasSubtitle() {
      if (this.scoreboard)
        return (
          this.scoreboard.getContestPreview() &&
          this.scoreboard.getContestPreview().getMetaData().getContestMeta()
        )
    },
    breadcrumbs() {
      if (this.scoreboard && !this.scoreboard.getContestPreview()) return null //todo: misleading condition , @Balaji please check this
      return [
        {
          text: 'Contests',
          href: '/contests',
        },
        {
          text:
            this.scoreboard && this.scoreboard.getContestPreview().getTitle(),
          href: this.contestUrl,
        },
        {
          text: 'Scoreboard',
          disabled: false,
        },
      ]
    },
    contestUrl() {
      if (this.scoreboard)
        return '/contests/' + this.scoreboard.getContestPreview().getUrl()
    },
    liveContest() {
      return (
        this.scoreboard &&
        !!this.scoreboard.getContestPreview() &&
        !!this.scoreboard.getContestPreview().getMetaData().getContestMeta() &&
        this.scoreboard
          .getContestPreview()
          .getMetaData()
          .getContestMeta()
          .getStartAt() <= this.serverTime &&
        this.scoreboard
          .getContestPreview()
          .getMetaData()
          .getContestMeta()
          .getEndAt() >= this.serverTime
      )
    },
    liveOrUpcomingContest() {
      return (
        this.scoreboard &&
        !!this.scoreboard.getContestPreview() &&
        !!this.scoreboard.getContestPreview().getMetaData().getContestMeta() &&
        this.scoreboard
          .getContestPreview()
          .getMetaData()
          .getContestMeta()
          .getEndAt() >= this.serverTime
      )
    },
    hasBanner() {
      return this.liveContest || this.upcomingContest
    },
    upcomingContest() {
      return (
        this.scoreboard &&
        !!this.scoreboard.getContestPreview() &&
        !!this.scoreboard.getContestPreview().getMetaData().getContestMeta() &&
        this.scoreboard
          .getContestPreview()
          .getMetaData()
          .getContestMeta()
          .getStartAt() > this.serverTime
      )
    },
    preposition() {
      if (this.liveContest) return 'Ends in'
      if (this.UpcominContest) return 'Starts in'
      else return ''
    },
    remainingTime() {
      if (
        this.scoreboard &&
        this.scoreboard.getContestPreview() &&
        this.scoreboard.getContestPreview().getMetaData() &&
        this.scoreboard.getContestPreview().getMetaData().getContestMeta()
      ) {
        var diff = this.serverTime
        if (this.liveContest)
          diff =
            this.scoreboard
              .getContestPreview()
              .getMetaData()
              .getContestMeta()
              .getEndAt() - this.serverTime
        if (this.upcomingContest)
          diff =
            this.scoreboard
              .getContestPreview()
              .getMetaData()
              .getContestMeta()
              .getStartAt() - this.serverTime
        return prettyday(diff)
      }
    },
    pageCount() {
      if (this.pagination.itemsPerPage == -1) return 1
      return (
        (this.pagination.total + this.pagination.itemsPerPage - 1) /
        this.pagination.itemsPerPage
      )
    },
    lastRefreshed() {
      var last = new moment(this.lastFetchedAt * 1000).from(
        this.serverTime * 1000,
      )
      return last
    },
    isSingleMemberTeam() {
      return (
        this.isTeamContest &&
        this.scoreboard
          .getContestPreview()
          .getMetaData()
          .getContestMeta()
          .getTeamContestConfig()
          .getMaxTeamSize() === 1
      )
    },
  },
  methods: {
    ...mapActions('content', ['fetchContestScoreboard']),
    prettyScore,
    submissionUrl(item) {
      var link = '/submissions/' + item.successfulSubmissionPreview.id
      return link
    },
    prettyPenalty(sec) {
      if (sec == 0) return '00:00:00'
      return prettytime(sec)
    },

    startTimer() {
      if (!this.liveOrUpcomingContest) return
      this.timer = setInterval(() => {
        this.fetchScoreboard().catch((ex) => (this.ex = ex))
        if (!this.liveContest) {
          clearInterval(this.timer)
        }
      }, 10 * 60 * 1000)
    },
    getName(item) {
      var name
      if (this.isTeamContest) name = item.teamPreview && item.teamPreview.name
      else name = item.userPreview && item.userPreview.name
      console.log('getName', item)
      return name || 'Unnamed'
    },
    teamUrl(item) {
      if (
        this.isTeamContest &&
        this.scoreboard.getContestPreview() &&
        item.teamPreview
      ) {
        return (
          '/contests/' +
          this.scoreboard.getContestPreview().getUrl() +
          '/teams/' +
          item.teamPreview.id
        )
      }
    },
    teamOrganization(item) {
      if (this.isTeamContest) {
        var org = item.teamPreview.organizationsList[0] || ''
        if (org.length > 0) {
          org = org.replace(/&\bamp\b;/g, '')
          org = org.replace(/\bamp\b;/g, '')
        }
        return org
      }
    },
    isShowUrl(item) {
      return !this.liveOrUpcomingContest && item.successfulSubmissionPreview
    },
    isUserScoreHighlighting(item) {
      if (
        this.userOrTeam.id &&
        (item.userId === this.userOrTeam.id ||
          item.teamId === this.userOrTeam.id)
      )
        return true
      return false
    },
    isPrivateScoreboard(scoreboard) {
      return (
        scoreboard
          ?.getContestPreview()
          ?.getMetaData()
          ?.getContestMeta()
          ?.getScoreboardVisibility() ===
        content_proto.ScoreboardVisibility.SCOREBOARD_VISIBILITY_PRIVATE
      )
    },
    async fetchScoreboard() {
      console.log('Pagination...', this.pagination)
      this.pageLoading = true
      return this.fetchContestScoreboard({
        url: this.url,
        paginationQuery: this.pagination,
      }).then((res) => {
        if (res.getUserScore()) {
          if (res.getUserScore().getTeamId())
            this.userOrTeam.id = res.getUserScore().getTeamId()
          else if (res.getUserScore().getUserId())
            this.userOrTeam.id = res.getUserScore().getUserId()
          this.userOrTeam.rank = res.getUserScore().getRank()
          this.userOrTeam.penalty = res.getUserScore().getPenalty()
          this.userOrTeam.score = res.getUserScore().getScore()
        }
        this.pagination.total =
          res && res.getPaginationResponse()
            ? res.getPaginationResponse().getTotal()
            : 0
        this.pageLoading = false
        this.lastFetchedAt = this.serverTime
      })
    },
  },
  async mounted() {
    this.initialLoading = true
    this.fetchScoreboard().then((__) => {
      this.initialLoading = false
      this.startTimer()
    })
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  watch: {
    pagination: {
      handler() {
        if (this.initialLoading) {
          this.initialLoading = false
          return
        }
        console.log('Fetching')
        this.fetchScoreboard()
      },
      deep: true,
    },
    tableOptions(to) {
      console.log('options.sync', to)
      this.pagination.pageIndex = to.page
      this.pagination.itemsPerPage = to.itemsPerPage
    },
    scoreboard(s) {
      document.title = 'Scoreboard - ' + s.getContestPreview().getTitle()
    },
  },
  components: {
    Page,
    ContestSubtitle,
    Mascot,
    ErrorMessage,
  },
}
</script>
<style scoped>
.problem_link {
  text-decoration: none;
  color: black;
}

.router_link {
  color: inherit;
}
.userScoreHighlighting {
  background-color: #e0e0e0 !important;
}
.userDataTable td:nth-of-type(2n + 3) {
  background-color: #fafafa;
}

.teamDataTable td:nth-of-type(2n + 4) {
  background-color: #fafafa;
}

.userDataTable /deep/ .table-header {
  background-color: #fafafa;
}

.teamDataTable /deep/ .table-header {
  background-color: #fafafa;
}

.userDataTable tr:hover:not(.v-table__expanded__content) td {
  background: inherit;
}

.teamDataTable tr:hover:not(.v-table__expanded__content) td {
  background: inherit;
}
</style>
