<template>
  <span>
    <v-row>
      <v-col>
        See insights into your code and tutorials to beat the errors
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="3"
        v-for="analysis in dashboardView.getCodeAnalysisList()"
        v-bind:key="analysis"
      >
        <v-card outlined :width="270" :height="100">
          <div class="d-flex flex-no-wrap">
            <v-avatar size="50" tile>
              <v-img
                :src="
                  require('./../assets/images/drill/' +
                    analysis.getDrillPreview().getContentPreview().getTitle() +
                    '.svg')
                "
              ></v-img>
            </v-avatar>
            <div>
              <v-card-title class="text-caption-1">
                {{
                  analysis.getDrillPreview().getContentPreview().getTitle()
                }}</v-card-title
              >
              <v-card-subtitle>
                {{ analysis.getUserLevel() }}
              </v-card-subtitle>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </span>
</template>
<script>
export default {
  props: {
    dashboardView: {
      type: Object,
      required: true,
    },
  },
}
</script>
