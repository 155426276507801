var render = function render(){
  var _vm$contentView$getMe, _vm$contentView$getMe2, _vm$contentView$getMe3, _vm$contentView$getMe4, _vm$contentView$getDa;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('v-tabs', {
    attrs: {
      "vertical": _vm.$vuetify.breakpoint.smAndDown,
      "color": "accent"
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab', [_vm._v("Description")]), ((_vm$contentView$getMe = _vm.contentView.getMetaData()) === null || _vm$contentView$getMe === void 0 ? void 0 : (_vm$contentView$getMe2 = _vm$contentView$getMe.getProblemMeta()) === null || _vm$contentView$getMe2 === void 0 ? void 0 : _vm$contentView$getMe2.getEvaluationType()) === 1 ? _c('v-tab', [_vm._v("SQL Schema")]) : _vm._e(), _c('v-tab', [_vm._v("Submissions")]), _c('v-tab', [_vm._v("Editorial")])], 1), _c('v-tabs-items', {
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab-item', [_c('problem-description', {
    attrs: {
      "parentPreview": _vm.parentPreview,
      "contentView": _vm.contentView
    }
  })], 1), ((_vm$contentView$getMe3 = _vm.contentView.getMetaData()) === null || _vm$contentView$getMe3 === void 0 ? void 0 : (_vm$contentView$getMe4 = _vm$contentView$getMe3.getProblemMeta()) === null || _vm$contentView$getMe4 === void 0 ? void 0 : _vm$contentView$getMe4.getEvaluationType()) === 1 ? _c('v-tab-item', [_c('code-view', {
    attrs: {
      "code": (_vm$contentView$getDa = _vm.contentView.getDataView()) === null || _vm$contentView$getDa === void 0 ? void 0 : _vm$contentView$getDa.getProblemView().getSqlInitView(),
      "lang": "MY_SQL_8",
      "availableLanguages": _vm.availableLanguages
    }
  })], 1) : _vm._e(), _c('v-tab-item', [_c('submission-history', {
    attrs: {
      "parentPreview": _vm.parentPreview && _vm.parentPreview,
      "id": _vm.contentView.getId(),
      "userId": _vm.userId
    }
  })], 1), _c('v-tab-item', [_c('v-card', {
    staticClass: "pl-5 pr-2 py-5",
    attrs: {
      "outlined": ""
    }
  }, [_c('html-view', {
    attrs: {
      "html": _vm.view.getEditorialView()
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }