var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_vm.completed >= 1 ? _c('v-row', [_c('v-col', [_c('status-chart', {
    attrs: {
      "dashboardView": _vm.dashboardView
    }
  })], 1)], 1) : _c('v-row', [_c('v-col', [_c('status-card', {
    attrs: {
      "startNow": "/problems",
      "height": 83,
      "width": 131,
      "title": "Progress",
      "cardText": "You need to solve atleast 1 problem to start seeing status here",
      "imageName": "Analyze"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }