<template>
  <!-- <div> -->
  <v-row class="d-flex flex-sm-wrap">
    <v-col v-for="a in attributes" :key="a.name + ':' + a.value" :cols="12">
      <span v-if="evaluatePresence(a)">
        <v-combobox
          v-if="isAutoComplete(a) && a.customValueAllowed"
          dense
          :items="autoCompleteItems(a)"
          :label="addStarIfRequired(a)"
          @input="(v) => setValue(a.name, v)"
          :rules="rules(a)"
        ></v-combobox>
        <v-select
          v-else-if="isAutoComplete(a) && !a.customValueAllowed"
          dense
          :items="autoCompleteItems(a)"
          :label="addStarIfRequired(a)"
          @input="(v) => setValue(a.name, v)"
          :rules="rules(a)"
        ></v-select>
        <v-text-field
          v-else
          dense
          :label="addStarIfRequired(a)"
          @input="(v) => setValue(a.name, v)"
          :rules="rules(a)"
        ></v-text-field>
      </span>
    </v-col>
  </v-row>
  <!-- </div> -->
</template>
<script>
import { mapGetters } from 'vuex'
import autocompleteValues from '../utils/autocomplete'

export default {
  props: {
    customAttributes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      validationRules: {
        required: (value) => !!value || 'Required',
      },
      values: {},
    }
  },
  computed: {
    attributes() {
      return this.customAttributes
        .map((x) => x[1])
        .sort((a, b) => a.orderIndex - b.orderIndex)
    },
  },

  mounted() {
    console.log(this.customAttributes, this.getFieldPresenceList())
  },

  methods: {
    ...mapGetters('content', ['getFieldPresenceList']),
    setValue(n, v) {
      this.$set(this.values, n, v)
      this.$emit('change', { key: n, value: v })
    },
    addStarIfRequired(a) {
      var name = a.name
      if (name.toLowerCase() === 'country') {
        name = 'Country/Region'
      }
      if (this.required(a)) {
        return `${name}*`
      } else {
        return `${name}`
      }
    },
    evaluatePresence(a) {
      var termList = a.presenceCondition?.presenceTermList
      if (termList && termList.length > 0) {
        return this.values[termList[0].fieldName] === termList[0].fieldValue
      }
      return true
    },
    required(a) {
      return (
        this.getFieldPresenceList()[a.customAttributePresence] ===
        'FIELD_PRESENCE_REQUIRED'
      )
    },
    isAutoComplete(a) {
      var autocomplete =
        a.autoCompleteSetting && a.autoCompleteSetting.valueList !== ''
      console.log('auto', autocomplete, a)
      return autocomplete
    },
    autoCompleteItems(a) {
      var valueList = a.autoCompleteSetting.valueList
      console.log('aci ......', valueList, autocompleteValues)
      return autocompleteValues[valueList] || []
    },
    rules(a) {
      const ruleSet = []
      if (this.required(a)) {
        ruleSet.push(this.validationRules.required)
      }
      return ruleSet
    },
  },
}
</script>

<style></style>
