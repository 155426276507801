var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "d-flex flex-column"
  }, [_c('v-card-title', {
    staticClass: "text-h5 text-lg-h4 justify-center flex-column pa-8"
  }, [_c('v-row', {
    staticClass: "mx-auto text-center justify-center"
  }, [_c('span', {
    staticClass: "red--text pr-2"
  }, [_vm._v(" OH NO! ")]), _vm._v(" Test Time up.")]), _c('v-row', {
    staticClass: "justify-center"
  }, [_vm._v(" Press "), _c('div', {
    staticClass: "accent--text px-2"
  }, [_vm._v("Continue")]), _vm._v(" to end the test. ")])], 1), _c('v-card-text', [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "d-flex justify-center",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-img', {
    attrs: {
      "max-width": "400px",
      "height": "400px",
      "src": require('../../assets/images/TestTimeOver.png')
    }
  })], 1)], 1)], 1), _c('v-card-actions', {
    staticClass: "justify-center flex-end pb-12"
  }, [_c('v-btn', {
    staticClass: "text-none px-3 py-2 text-body-1",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.continueToTestEnd
    }
  }, [_vm._v(" Continue "), _c('v-icon', {
    attrs: {
      "right": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.mdiChevronRight) + " ")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }