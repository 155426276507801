<template>
  <span>
    <error v-if="!userId"
      >Please <router-link to="/community/login">login</router-link> to view
      submission history</error
    >
    <error
      v-else-if="
        fetchSubmissionsStatus !== undefined && fetchSubmissionsStatus == 3
      "
      >An error ocurred while loading this page. Please try again</error
    >
    <span v-else>
      <v-row class="py-5 px-2">
        <v-col>
          <v-card>
            <v-row>
              <v-col class="pl-10 mt-3">
                <span v-if="isTeamContest" class="subtitle-1"
                  >Team Submission History</span
                >
                <span v-else class="subtitle-1">Submission History </span>
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="accent" large v-bind="attrs" v-on="on">
                      <v-icon x-large class="text-right" @click="load">{{
                        mdiCached
                      }}</v-icon>
                    </v-btn>
                  </template>
                  <span color="red">Load submissions</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row v-if="submissionHistory">
              <v-col cols="12">
                <v-data-table
                  class="mx-6 mb-10 submission_history rounded-md"
                  :server-items-length="totalItems"
                  :headers="headers"
                  :items="submissionHistory"
                  :footer-props.sync="footerOptions"
                  :loading="pageLoading"
                  :options.sync="pagination"
                >
                  <template v-slot:item="{ item }">
                    <tr>
                      <td>{{ formatDate(item.getSubmittedOn()) }}</td>
                      <td
                        class="text-capitalize"
                        v-bind:class="statusClass(item.getStatus())"
                      >
                        {{ submissionStatusTitle(item) }}
                      </td>
                      <td>
                        {{ submissionScoreString(item) }}
                      </td>
                      <td>{{ item.getTimeTaken() }} ms</td>
                      <td>{{ item.getMemoryTaken() }} KiB</td>
                      <td v-if="isTeamContest">
                        {{ item.getUserPreview().getName() }}
                      </td>

                      <td>
                        <router-link
                          outlined
                          class="view_details_btn"
                          height="25"
                          :to="'/submissions/' + item.getId()"
                          target="_blank"
                        >
                          View Details
                        </router-link>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </span>
  </span>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import Loader from '@/components/Loader.vue'
import Error from '@/components/Error.vue'
import moment from 'moment'
import CodeDisplay from '../components/CodeDisplay'
import { mdiCached } from '@mdi/js'
import {
  statusClass,
  submissionScoreString,
  submissionStatusTitle,
} from '@/utils/status'

export default {
  props: {
    parentPreview: {
      type: Object,
      required: false,
    },
    id: {
      type: Number,
      required: true,
    },
    userId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      mdiCached: mdiCached,
      footerOptions: {
        itemsPerPageOptions: [10, 25, 50],
      },
      ex: null,
      totalItems: -1,
      pagination: {},
      pageLoading: false,
      staticHeaders: [
        {
          text: 'Submission Date',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Status',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Score',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Time',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Memory',
          align: 'left',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapState('judge', ['submissionHistory', 'fetchSubmissionsStatus']),
    ...mapGetters('judge', ['languageKeys']),

    isTeamContest() {
      if (
        this.parentPreview &&
        this.parentPreview.getMetaData().getContestMeta()
      ) {
        var tc =
          this.parentPreview &&
          this.parentPreview.getMetaData().getContestMeta().getContestType() ==
            1
        console.log('is tc ..', tc)
        return tc
      }
    },
    headers() {
      var headers = [...this.staticHeaders]
      if (this.isTeamContest) {
        headers.push({
          text: 'Submitted By',
          align: 'left',
          sortable: false,
        })
      }
      headers.push({
        text: '',
        align: 'left',
        sortable: false,
      })
      console.log('headers', headers)
      return headers
    },
  },
  watch: {
    id: () => this.clearHistory(),
    userId: () => this.clearHistory(),
    pagination: {
      handler() {
        this.load()
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions('judge', ['loadHistory', 'fetchSubmissionDetail']),
    ...mapMutations('judge', ['clearHistory']),
    statusClass,
    submissionScoreString,
    submissionStatusTitle,

    fetchDetail(id) {
      this.fetchSubmissionDetail({ submissionId: id }).then((__) =>
        this.$nextTick(() => {
          this.$vuetify.goTo(this.$refs.submissionDetails)
        }),
      )
    },
    formatDate(value) {
      if (value) {
        return new moment(new Date(value)).fromNow()
      }
    },

    async load() {
      if (!this.userId || !this.id) return Promise.resolve(null)
      this.pageLoading = true
      var parentID = null
      if (this.parentPreview) parentID = this.parentPreview.getId()
      return this.loadHistory({
        problemId: this.id,
        userId: this.userId,
        parentId: parentID,
        paginationQuery: this.pagination,
      }).then((res) => {
        console.log('res ...', res)
        this.totalItems =
          res && res.getPaginationResponse()
            ? res.getPaginationResponse().getTotal()
            : 0
        this.pageLoading = false
      })
    },
  },
  mounted() {
    this.clearHistory()
  },
  components: {
    CodeDisplay,
    Error,
    Loader,
  },
}
</script>
<style scoped>
.submission_history {
  border: 1px solid #cccccc;
}

@media only screen and (min-width: 1224px) {
  .submission_history {
    max-width: 75%;
  }
}

.submission_history tr:nth-of-type(odd) {
  background-color: #fbfbfb;
}

.submission_history tr td {
  border-bottom: none !important;
}

.view_details_btn {
  color: #33a79d;
  text-decoration: none;
}
</style>
