var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('page', {
    staticClass: "ml-4",
    attrs: {
      "title": _vm.title,
      "apiStatusList": [],
      "loading": _vm.initialLoading,
      "breadcrumbs": _vm.breadcrumbs
    },
    scopedSlots: _vm._u([_vm.hasSubtitle ? {
      key: "subtitle",
      fn: function () {
        return [_vm.scoreboard.getContestPreview().getMetaData() ? _c('contest-subtitle', {
          attrs: {
            "contentMeta": _vm.scoreboard.getContestPreview().getMetaData(),
            "contestUrl": _vm.scoreboard.getContestPreview().getUrl(),
            "isScoreboard": true
          }
        }) : _vm._e()];
      },
      proxy: true
    } : null, _vm.hasBanner ? {
      key: "appendBanner",
      fn: function () {
        return [_c('mascot', {
          attrs: {
            "preposition": _vm.preposition,
            "time": _vm.remainingTime
          }
        })];
      },
      proxy: true
    } : null], null, true)
  }, [_c('error-message', {
    attrs: {
      "ex": _vm.ex
    }
  }), _vm.isPrivateScoreboard(_vm.scoreboard) ? _c('v-row', [_c('v-col', [_c('v-alert', {
    attrs: {
      "type": "info"
    }
  }, [_vm._v(" The scoreboard is private. You can only view your own score and not of others. ")])], 1)], 1) : _vm._e(), _vm.userOrTeam.id && !_vm.isPrivateScoreboard(_vm.scoreboard) ? _c('v-row', {
    staticClass: "mt-5"
  }, [_c('v-col', {
    attrs: {
      "cols": 12,
      "sm:8": "",
      "lg": 6
    }
  }, [_c('v-card', [_c('v-data-table', {
    attrs: {
      "hide-default-footer": true,
      "headers": _vm.userOrTeamHeaders,
      "items": _vm.userOrTeamScores
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('tr', {
          staticClass: "text-center"
        }, [_c('td', [_vm._v(" " + _vm._s(item.rank) + " ")]), _c('td', [_c('span', [_c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(_vm._s(_vm.prettyScore(item.score)))]), _c('br'), _c('span', {
          staticClass: "grey--text"
        }, [_vm._v(_vm._s(_vm.prettyPenalty(item.penalty)))])])])])];
      }
    }], null, false, 112966499)
  })], 1)], 1)], 1) : _vm._e(), _c('v-row', {
    staticClass: "mt-5"
  }, [_c('v-col', [_c('v-card', [_c('v-card', {
    staticClass: "px-2",
    attrs: {
      "outlined": "",
      "height": 75
    }
  }, [_c('v-row', {
    staticClass: "pt-2",
    attrs: {
      "no-gutters": ""
    }
  }, [_vm.$vuetify.breakpoint.mdAndUp ? _c('v-col', {
    staticClass: "mt-2 ml-2"
  }, [_c('v-tooltip', {
    attrs: {
      "left": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref2) {
        var on = _ref2.on,
          attrs = _ref2.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": "",
            "color": "accent",
            "large": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          staticClass: "text-right",
          attrs: {
            "x-large": ""
          },
          on: {
            "click": _vm.fetchScoreboard
          }
        }, [_vm._v(_vm._s(_vm.mdiCached))])], 1)];
      }
    }], null, false, 2996613164)
  }, [_c('span', {
    attrs: {
      "color": "red"
    }
  }, [_vm._v("Refresh Scoreboard")])])], 1) : _vm._e(), _vm.$vuetify.breakpoint.mdAndUp ? _c('v-col', {
    staticClass: "mt-3",
    attrs: {
      "cols": 12,
      "lg": 4,
      "sm": 8,
      "md": 5
    }
  }, [_c('span', {
    staticClass: "grey--text"
  }, [_vm._v("Refreshed " + _vm._s(_vm.lastRefreshed) + " ")])]) : _vm._e(), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": 12,
      "lg": 3,
      "sm": 8,
      "md": 4
    }
  }, [!_vm.isPrivateScoreboard(_vm.scoreboard) ? _c('v-text-field', {
    attrs: {
      "append-icon": _vm.mdiMagnify,
      "label": "Full name/org not partial",
      "clearable": "",
      "single-line": "",
      "outlined": ""
    },
    model: {
      value: _vm.pagination.search,
      callback: function ($$v) {
        _vm.$set(_vm.pagination, "search", $$v);
      },
      expression: "pagination.search"
    }
  }) : _vm._e()], 1)], 1)], 1), _c('v-data-table', {
    class: {
      teamDataTable: _vm.isTeamContest,
      userDataTable: !_vm.isTeamContest
    },
    attrs: {
      "page": _vm.pagination.pageIndex,
      "pageCount": _vm.pageCount,
      "server-items-length": _vm.pagination.total,
      "headers": _vm.headers,
      "items": _vm.userScores,
      "footer-props": _vm.footerOptions,
      "search": _vm.pagination.search,
      "loading": _vm.pageLoading,
      "options": _vm.tableOptions
    },
    on: {
      "update:footerProps": function ($event) {
        _vm.footerOptions = $event;
      },
      "update:footer-props": function ($event) {
        _vm.footerOptions = $event;
      },
      "update:options": function ($event) {
        _vm.tableOptions = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "header.links",
      fn: function (_ref3) {
        var header = _ref3.header;
        return [_c('v-tooltip', {
          attrs: {
            "top": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref4) {
              var on = _ref4.on,
                attrs = _ref4.attrs;
              return [_c('span', _vm._g(_vm._b({}, 'span', attrs, false), on), [_vm.scoreboard.getContestPreview() ? _c('router-link', {
                staticClass: "problem_link d-flex justify-center mt-4 text-center",
                attrs: {
                  "to": '/contests/' + _vm.scoreboard.getContestPreview().getUrl() + '/problems/' + header.url
                }
              }, [_vm._v(" " + _vm._s(header.text) + " ")]) : _vm._e()], 1)];
            }
          }], null, true)
        }, [_c('span', [_vm._v(_vm._s(header.title))])])];
      }
    }, {
      key: "item",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('tr', [_c('td', {
          class: {
            userScoreHighlighting: _vm.isUserScoreHighlighting(item)
          }
        }, [_vm._v(" " + _vm._s(_vm.isPrivateScoreboard(_vm.scoreboard) ? '-' : item.rank) + " ")]), _c('td', {
          class: {
            userScoreHighlighting: _vm.isUserScoreHighlighting(item)
          }
        }, [_vm.isTeamContest ? _c('router-link', {
          staticClass: "text-decoration-none router_link",
          attrs: {
            "to": _vm.teamUrl(item)
          }
        }, [_vm._v(" " + _vm._s(_vm.getName(item)) + " ")]) : item.userPreview.handle ? _c('router-link', {
          staticClass: "text-decoration-none router_link",
          attrs: {
            "to": `/profiles/${item.userPreview.handle}`
          }
        }, [_vm._v(" " + _vm._s(item.userPreview.handle) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.getName(item)) + " ")])], 1), _vm.isTeamContest ? _c('td', {
          class: {
            userScoreHighlighting: _vm.isUserScoreHighlighting(item)
          }
        }, [_vm._v(" " + _vm._s(_vm.teamOrganization(item)) + " ")]) : _vm._e(), _c('td', {
          staticClass: "text-center",
          class: {
            userScoreHighlighting: _vm.isUserScoreHighlighting(item)
          }
        }, [_c('span', [_c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(" " + _vm._s(_vm.prettyScore(item.score)) + " ")]), _c('br'), _c('span', {
          staticClass: "grey--text"
        }, [_vm._v(_vm._s(_vm.prettyPenalty(item.penalty)))])])]), _vm._l(_vm.problemScoresList, function (ps) {
          return _c('td', {
            key: ps.getProblemId(),
            staticClass: "text-center",
            class: {
              userScoreHighlighting: _vm.isUserScoreHighlighting(item)
            }
          }, [item.userProblemScoresMap[ps.getProblemId()] ? _c('span', [_vm.isShowUrl(item.userProblemScoresMap[ps.getProblemId()]) ? _c('router-link', {
            staticClass: "text-decoration-none",
            attrs: {
              "to": _vm.submissionUrl(item.userProblemScoresMap[ps.getProblemId()])
            }
          }, [item.userProblemScoresMap[ps.getProblemId()].score === 0 ? _c('span', {
            staticClass: "font-weight-bold red--text"
          }, [_vm._v(" " + _vm._s(_vm.prettyScore(item.userProblemScoresMap[ps.getProblemId()].score)) + " (" + _vm._s(item.userProblemScoresMap[ps.getProblemId()].totalSubmissions) + ") ")]) : item.userProblemScoresMap[ps.getProblemId()].score > 0 && item.userProblemScoresMap[ps.getProblemId()].totalSubmissions === 1 ? _c('span', {
            staticClass: "font-weight-bold green--text"
          }, [_vm._v(" " + _vm._s(_vm.prettyScore(item.userProblemScoresMap[ps.getProblemId()].score)) + " ")]) : _c('span', {
            staticClass: "font-weight-bold green--text"
          }, [_vm._v(" " + _vm._s(_vm.prettyScore(item.userProblemScoresMap[ps.getProblemId()].score)) + " (" + _vm._s(item.userProblemScoresMap[ps.getProblemId()].totalSubmissions - 1) + ") ")]), _c('br'), _c('span', {
            staticClass: "grey--text"
          }, [_vm._v(" " + _vm._s(_vm.prettyPenalty(item.userProblemScoresMap[ps.getProblemId()].solvedAt)) + " ")])]) : _c('span', [item.userProblemScoresMap[ps.getProblemId()].score === 0 ? _c('span', {
            staticClass: "font-weight-bold red--text"
          }, [_vm._v(" " + _vm._s(_vm.prettyScore(item.userProblemScoresMap[ps.getProblemId()].score)) + " (" + _vm._s(item.userProblemScoresMap[ps.getProblemId()].totalSubmissions) + ") ")]) : item.userProblemScoresMap[ps.getProblemId()].score > 0 && item.userProblemScoresMap[ps.getProblemId()].totalSubmissions === 1 ? _c('span', {
            staticClass: "font-weight-bold green--text"
          }, [_vm._v(" " + _vm._s(_vm.prettyScore(item.userProblemScoresMap[ps.getProblemId()].score)) + " ")]) : _c('span', {
            staticClass: "font-weight-bold green--text"
          }, [_vm._v(" " + _vm._s(_vm.prettyScore(item.userProblemScoresMap[ps.getProblemId()].score)) + " (" + _vm._s(item.userProblemScoresMap[ps.getProblemId()].totalSubmissions - 1) + ") ")]), _c('br'), _c('span', {
            staticClass: "grey--text"
          }, [_vm._v(" " + _vm._s(_vm.prettyPenalty(item.userProblemScoresMap[ps.getProblemId()].solvedAt)) + " ")])])], 1) : _c('span', [_vm._v(" - ")])]);
        })], 2)];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }