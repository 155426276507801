var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "ma-3",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-btn', {
    staticClass: "ma-4",
    attrs: {
      "color": "accent",
      "dark": ""
    },
    on: {
      "click": _vm.openDialog
    }
  }, [_vm._v(" " + _vm._s(_vm.isSingleMemberTeam ? 'Register' : 'Create Team') + " ")]), _c('v-dialog', {
    model: {
      value: _vm.roleDialog,
      callback: function ($$v) {
        _vm.roleDialog = $$v;
      },
      expression: "roleDialog"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm.contestBannerUrl ? _c('img', {
    staticStyle: {
      "max-width": "100%"
    },
    attrs: {
      "src": _vm.contestBannerUrl,
      "alt": "contest banner"
    }
  }) : _c('div', [_vm._v(_vm._s(_vm.isSingleMemberTeam ? 'Register' : 'New Team'))])]), _c('v-card-text', [_c('v-container', [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": 4
    }
  }, [_c('v-btn', {
    attrs: {
      "outlined": "",
      "x-large": "",
      "width": "300"
    },
    on: {
      "click": function ($event) {
        return _vm.nextDetails(true);
      }
    }
  }, [_vm._v("I am a coach/mentor")])], 1)], 1), _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": 4
    }
  }, [_c('v-btn', {
    attrs: {
      "outlined": "",
      "x-large": "",
      "width": "300"
    },
    on: {
      "click": function ($event) {
        return _vm.nextDetails(false);
      }
    }
  }, [_vm._v("I am a contestant")])], 1)], 1)], 1)], 1)], 1)], 1), _c('v-dialog', {
    model: {
      value: _vm.detailsDialog,
      callback: function ($$v) {
        _vm.detailsDialog = $$v;
      },
      expression: "detailsDialog"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm.contestBannerUrl ? _c('img', {
    staticStyle: {
      "max-width": "100%"
    },
    attrs: {
      "src": _vm.contestBannerUrl,
      "alt": "contest banner"
    }
  }) : _c('div', [_vm._v(_vm._s(_vm.isSingleMemberTeam ? 'Register' : 'New Team'))])]), _c('v-card-text', [_c('v-form', {
    model: {
      value: _vm.valid,
      callback: function ($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [!_vm.isSingleMemberTeam ? _c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-subheader', [_vm._v("Team details")]), _c('v-divider'), _c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "color": "accent",
      "label": "Team name*",
      "required": "",
      "rules": [_vm.rules.required]
    },
    on: {
      "input": _vm.editTeamName
    },
    model: {
      value: _vm.teamName,
      callback: function ($$v) {
        _vm.teamName = $$v;
      },
      expression: "teamName"
    }
  })], 1)], 1), !_vm.hasSchoolAttribute ? _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "color": "accent",
      "label": "Organization"
    },
    model: {
      value: _vm.organization,
      callback: function ($$v) {
        _vm.organization = $$v;
      },
      expression: "organization"
    }
  })], 1)], 1) : _vm._e(), _c('custom-attributes', {
    attrs: {
      "customAttributes": _vm.teamContestConfig.toObject().teamCustomAttributeMap
    },
    on: {
      "change": function (attributeValue) {
        return _vm.addTeamValue(attributeValue);
      }
    }
  })], 1)], 1) : _vm._e(), _vm._l(_vm.teamContestConfig.getMaxTeamSize(), function (n) {
    return _c('v-card', {
      key: 'member-' + n,
      attrs: {
        "outlined": ""
      }
    }, [_c('v-subheader', {
      staticClass: "font-weight-bold green lighten-3"
    }, [_vm._v(_vm._s(_vm.memberLabel(n)))]), _c('v-divider'), _c('v-container', [_c('v-row', {}, [_c('v-col', [_c('v-text-field', {
      attrs: {
        "color": "accent",
        "label": 'Email' + (_vm.memberRequired(n) ? '*' : ''),
        "required": _vm.memberRequired(n),
        "rules": _vm.emailRules(_vm.memberRequired(n)),
        "readonly": n === 1 && !_vm.selfCoach,
        "value": _vm.selfEmail(false, n)
      },
      on: {
        "input": function (e) {
          return _vm.setMemberEmail(n, e);
        }
      }
    })], 1)], 1), _c('v-row', [_c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, [_vm.isSingleMemberTeam ? _c('v-text-field', {
      attrs: {
        "color": "accent",
        "label": "Name*",
        "required": "",
        "rules": [_vm.rules.required]
      },
      on: {
        "input": _vm.editTeamName
      },
      model: {
        value: _vm.teamName,
        callback: function ($$v) {
          _vm.teamName = $$v;
        },
        expression: "teamName"
      }
    }) : _vm._e()], 1)], 1), _vm.memberRequired(n) || _vm.memberPresent[n] ? _c('custom-attributes', {
      attrs: {
        "customAttributes": _vm.teamContestConfig.toObject().memberCustomAttributeMap
      },
      on: {
        "change": function (attributeValue) {
          return _vm.addMemberAttributes(n, attributeValue);
        }
      }
    }) : _vm._e()], 1)], 1);
  }), _c('v-container', [_c('v-row', [_c('v-col', [_vm.coachEnabled && !_vm.selfCoach && !_vm.coachFieldRequired ? _c('v-checkbox', {
    attrs: {
      "label": "I have a coach/mentor"
    },
    model: {
      value: _vm.haveCoach,
      callback: function ($$v) {
        _vm.haveCoach = $$v;
      },
      expression: "haveCoach"
    }
  }) : _vm._e()], 1)], 1)], 1), _vm.coachEnabled && _vm.haveCoach ? _c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-subheader', [_vm._v("Coach/mentor details")]), _c('v-divider'), _c('v-container', [_vm.coachEnabled ? _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "color": "accent",
      "label": `Coach email${_vm.coachFieldRequired ? '*' : ''} `,
      "rules": _vm.emailRules(_vm.coachFieldRequired),
      "required": _vm.coachFieldRequired,
      "readonly": _vm.selfCoach,
      "value": _vm.selfEmail(true)
    },
    on: {
      "input": function (e) {
        return _vm.setCoachEmail(e);
      }
    }
  }), _c('span', {
    staticClass: "text-caption mb-4 pb-4"
  }, [_vm._v(" A mentor can be anyone who can motivate and support the students(eg: Teacher, Counsellor) ")])], 1)], 1) : _vm._e(), _vm.coachEnabled && (_vm.coachFieldRequired || _vm.haveCoach) ? _c('custom-attributes', {
    attrs: {
      "customAttributes": _vm.teamContestConfig.toObject().coachSetting.coachCustomAttributeMap
    },
    on: {
      "change": function (attributeValue) {
        return _vm.addCoachAttributes(attributeValue);
      }
    }
  }) : _vm._e()], 1)], 1) : _vm._e(), _c('small', [_vm._v("*indicates required field")]), _c('v-row', [_c('v-col', [_c('small', {
    staticClass: "font-weight-black"
  }, [_vm._v(_vm._s(_vm.termAndConditions))])])], 1)], 2)], 1), _vm.isTeamNameEdited ? _c('v-card-actions', [_c('v-checkbox', {
    attrs: {
      "label": "I confirm that the details confirm to CodeDrills' Be Nice, Be Respectful standards and is not inappropriate. I understand that I may be banned from the platform if it is inappropriate"
    },
    model: {
      value: _vm.checked,
      callback: function ($$v) {
        _vm.checked = $$v;
      },
      expression: "checked"
    }
  })], 1) : _vm._e(), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "grey darken-1",
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.detailsDialog = false;
      }
    }
  }, [_vm._v(" Close ")]), _c('v-btn', {
    attrs: {
      "disabled": !_vm.checked,
      "color": "grey darken-1",
      "text": ""
    },
    on: {
      "click": _vm.createTeam
    }
  }, [_vm._v(" " + _vm._s(_vm.isSingleMemberTeam ? 'Register' : 'Create') + " ")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }