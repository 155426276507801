<template>
  <div>
    <error
      v-if="fetchSubmissionsStatus !== undefined && fetchSubmissionsStatus == 3"
    >
      An error ocurred while loading this page. Please try again
    </error>
    <div v-else>
      <v-card class="pl-2">
        <v-row>
          <v-col class="mt-3">
            <span v-if="isTeamContest" class="subtitle-1">
              Team Submission History
            </span>
            <span v-else class="subtitle-1">Submission History </span>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon color="accent" large v-bind="attrs" v-on="on">
                  <v-icon x-large class="text-right" @click="load">{{
                    mdiCached
                  }}</v-icon>
                </v-btn>
              </template>
              <span color="red">Load submissions</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row v-if="submissionHistory">
          <v-col cols="12">
            <v-data-table
              dense
              class="submission_history rounded-md"
              :server-items-length="totalItems"
              :headers="headers"
              :items="submissionHistory"
              :footer-props.sync="footerOptions"
              :loading="pageLoading"
              :options.sync="pagination"
            >
              <template v-slot:item="{ item, index }">
                <tr>
                  <td>{{ formatDate(item.getSubmittedOn()) }}</td>
                  <td
                    class="text-capitalize"
                    v-bind:class="{
                      'green--text': isCorrectSubmission(item),
                      'red--text': isIncorrectSubmission(item),
                    }"
                  >
                    {{ getStatus(item.getStatus()) }}
                  </td>
                  <!-- <td>{{ (100 * item.getScore()).toFixed(2).toString() }} %</td> -->
                  <td>{{ item.getTimeTaken() }} ms</td>
                  <td>{{ item.getMemoryTaken() }} KiB</td>
                  <td v-if="isTeamContest">
                    {{ item.getUserPreview().getName() }}
                  </td>
                  <td>
                    <v-dialog
                      v-model="submissionDialog[index]"
                      width="1000"
                      :key="item.getId()"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          text
                          small
                          outlined
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                          @click="fetchDetail(item.getId(), index)"
                        >
                          See Code
                        </v-btn>
                      </template>
                      <submission-modal
                        v-if="viewingSubmission"
                        :submission="viewingSubmission"
                        v-on:closesubmodal="closeSubmission(index)"
                      />
                    </v-dialog>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import Loader from '@/components/Loader.vue'
import Error from '@/components/Error.vue'
import moment from 'moment'
import CodeDisplay from '@/components/CodeDisplay'
import { mdiCached } from '@mdi/js'
import SubmissionModal from './SubmissionModal.vue'

export default {
  props: {
    parentPreview: {
      type: Object,
      required: false,
    },
    id: {
      type: Number,
      required: true,
    },
    userId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      mdiCached: mdiCached,
      footerOptions: {
        itemsPerPageOptions: [10],
      },
      ex: null,
      totalItems: -1,
      pagination: {},
      pageLoading: false,
      staticHeaders: [
        {
          text: 'Submission Date',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Status',
          align: 'left',
          sortable: false,
        },
        // {
        //   text: "Score",
        //   align: "left",
        //   sortable: false,
        // },
        {
          text: 'Time Limit',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Memory Limit',
          align: 'left',
          sortable: false,
        },
      ],
      viewingSubmission: null,
      submissionDialog: [],
    }
  },
  computed: {
    ...mapState('judge', ['submissionHistory', 'fetchSubmissionsStatus']),
    ...mapGetters('judge', ['languageKeys', 'statusKeys']),

    isTeamContest() {
      if (this.parentPreview?.getMetaData()?.getContestMeta()) {
        const tc =
          this.parentPreview
            ?.getMetaData()
            ?.getContestMeta()
            ?.getContestType() == 1
        return tc
      }
    },
    headers() {
      const headers = [...this.staticHeaders]
      if (this.isTeamContest) {
        headers.push({
          text: 'Submitted By',
          align: 'left',
          sortable: false,
        })
      }
      headers.push({
        text: '',
        align: 'left',
        sortable: false,
      })
      console.log('headers', headers)
      return headers
    },
  },
  watch: {
    id: () => this.clearHistory(),
    userId: () => this.clearHistory(),
    pagination: {
      handler() {
        this.load()
      },
      deep: true,
    },
    submissionHistory() {
      this.submissionDialog = this.submissionHistory.map((_) => false)
    },
  },
  methods: {
    ...mapActions('judge', ['loadHistory', 'fetchSubmissionDetail']),
    ...mapMutations('judge', ['clearHistory']),
    fetchDetail(id, x) {
      this.fetchSubmissionDetail({ submissionId: id }).then((r) =>
        this.$nextTick(() => {
          this.viewingSubmission = r.toObject()
          console.log(id, x, this.submissionDialog)
        }),
      )
    },
    formatDate(value) {
      if (value) {
        return new moment(new Date(value)).fromNow()
      }
    },
    status(statusNumber) {
      var retStatus = {}
      console.log('statusKeys', this.statusKeys)
      for (var key in this.statusKeys) {
        retStatus[this.statusKeys[key]] = key
      }
      return retStatus[statusNumber]
    },
    getStatus(status) {
      return this.statusKeys[status].split('_').join(' ').toLowerCase()
    },

    isCorrectSubmission(item) {
      return item.getStatus() === 13 ? true : false
    },

    isIncorrectSubmission(item) {
      return item.getStatus() >= 10 && item.getStatus() != 13 ? true : false
    },

    async load() {
      if (!this.userId || !this.id) return Promise.resolve(null)
      this.pageLoading = true
      var parentID = null
      if (this.parentPreview) parentID = this.parentPreview.getId()
      return this.loadHistory({
        problemId: this.id,
        userId: this.userId,
        parentId: parentID,
        paginationQuery: this.pagination,
      }).then((res) => {
        console.log('res ...', res)
        this.totalItems =
          res && res.getPaginationResponse()
            ? res.getPaginationResponse().getTotal()
            : 0
        this.pageLoading = false
      })
    },
    closeSubmission(index) {
      this.$set(this.submissionDialog, index, false)
    },
  },
  mounted() {
    this.clearHistory()
  },
  components: {
    CodeDisplay,
    Error,
    Loader,
    SubmissionModal,
  },
}
</script>
<style scoped>
.submission_history {
  border: 1px solid #cccccc;
}
.submission_history tr:nth-of-type(odd) {
  background-color: #fbfbfb;
}

.submission_history tr td {
  border-bottom: none !important;
}

.view_details_btn {
  color: #33a79d;
  text-decoration: none;
}
</style>
