<template>
  <render-html :html="html" :key="htmlHash"></render-html>
</template>
<script>
import RenderHtml from './RenderHtml.vue'
import { djb2Hash } from '@/utils/helper.js'

export default {
  props: {
    html: {
      type: String,
      default: '',
    },
  },
  components: {
    RenderHtml,
  },
  computed: {
    htmlHash() {
      if (this.html) {
        return djb2Hash(this.html)
      } else {
        return djb2Hash('')
      }
    },
  },
}
</script>

<style scoped></style>
