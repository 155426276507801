var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "d-flex flex-sm-wrap"
  }, _vm._l(_vm.attributes, function (a) {
    return _c('v-col', {
      key: a.name + ':' + a.value,
      attrs: {
        "cols": 12
      }
    }, [_vm.evaluatePresence(a) ? _c('span', [_vm.isAutoComplete(a) && a.customValueAllowed ? _c('v-combobox', {
      attrs: {
        "dense": "",
        "items": _vm.autoCompleteItems(a),
        "label": _vm.addStarIfRequired(a),
        "rules": _vm.rules(a)
      },
      on: {
        "input": function (v) {
          return _vm.setValue(a.name, v);
        }
      }
    }) : _vm.isAutoComplete(a) && !a.customValueAllowed ? _c('v-select', {
      attrs: {
        "dense": "",
        "items": _vm.autoCompleteItems(a),
        "label": _vm.addStarIfRequired(a),
        "rules": _vm.rules(a)
      },
      on: {
        "input": function (v) {
          return _vm.setValue(a.name, v);
        }
      }
    }) : _c('v-text-field', {
      attrs: {
        "dense": "",
        "label": _vm.addStarIfRequired(a),
        "rules": _vm.rules(a)
      },
      on: {
        "input": function (v) {
          return _vm.setValue(a.name, v);
        }
      }
    })], 1) : _vm._e()]);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }