<template>
  <v-card elevation="0" class="pt-2">
    <HiringCodeEditor
      v-model="problemSolCode"
      @setLanguage="setLanguage"
      @resetCode="removeOldCode"
      :codeAction="codeAction"
      :allowedLanguages="allowedLanguages"
      :evaluationType="evaluationType"
    >
      <template v-slot:actions>
        <div class="code-actions mt-2">
          <v-row class="code-action-buttons d-flex" text outlined>
            <!-- <v-tooltip top>
              <template v-slot:activator="{on, attrs}">
                <v-btn
                  text
                  outlined
                  @click="problemAction('CUSTOM_RUN')"
                  class="custom"
                  v-bind="attrs"
                  v-on="on"
                >
                  Custom
                </v-btn>
              </template>
              <span></span>
            </v-tooltip> -->
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="problemAction('TEST_RUN')"
                  class="ml-auto mr-2 test-run"
                  text
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  :loading="testRunSubmitting"
                  :disabled="fullRunSubmitting || testRunSubmitting"
                >
                  Run Code
                </v-btn>
              </template>
              <span>
                Runs only for sample test cases. This submission won't be
                considered for scoring.
              </span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="problemAction('FULL_RUN')"
                  class="full-run"
                  text
                  outlined
                  color="success"
                  v-bind="attrs"
                  v-on="on"
                  :loading="fullRunSubmitting"
                  :disabled="fullRunSubmitting"
                >
                  Submit
                </v-btn>
              </template>
              <span>
                Runs on all the test cases including hidden ones. On success, it
                shows the score based on how many test cases passed.
              </span>
            </v-tooltip>
            <v-btn
              v-if="codeAction !== 'HIDDEN'"
              @click="problemAction('HIDDEN')"
              icon
              outlined
              color="#C4C4C4"
            >
              <v-icon>
                {{ mdiChevronDown }}
              </v-icon>
            </v-btn>
            <v-btn
              v-else
              @click="problemAction('SHOW')"
              icon
              outlined
              color="#C4C4C4"
            >
              <v-icon>
                {{ mdiChevronUp }}
              </v-icon>
            </v-btn>
          </v-row>
          <div class="code-action-content" transition="slide-y-transition">
            <v-row v-if="codeAction === 'CUSTOM_RUN'">
              <v-col cols="8">
                <span
                  v-if="
                    hasWrappedIo ||
                    currentQuestion
                      .getMetaData()
                      ?.getProblemMeta()
                      ?.getEvaluationType() === 1
                  "
                >
                  Custom Input disabled for this problem.
                </span>
                <v-textarea
                  clearable
                  rows="3"
                  :disabled="
                    hasWrappedIo ||
                    currentQuestion
                      .getMetaData()
                      ?.getProblemMeta()
                      ?.getEvaluationType() === 1
                  "
                  outlined
                  v-model="userInput"
                >
                </v-textarea>
              </v-col>
              <v-col cols="4" class="d-flex flex-column justify-end">
                <v-btn
                  :disabled="
                    hasWrappedIo ||
                    currentQuestion
                      .getMetaData()
                      ?.getProblemMeta()
                      ?.getEvaluationType() === 1
                  "
                  @click="userInput = ''"
                >
                  Clear
                </v-btn>
                <v-btn
                  :disabled="
                    hasWrappedIo ||
                    currentQuestion
                      .getMetaData()
                      ?.getProblemMeta()
                      ?.getEvaluationType() === 1
                  "
                  color="success"
                  class="mt-2 mb-8"
                  @click="testRun()"
                >
                  Custom Run
                </v-btn>
              </v-col>
            </v-row>
            <div
              class="mt-5 px-2 submission-overflow"
              v-if="
                codeAction === 'SHOW' ||
                codeAction === 'FULL_RUN' ||
                codeAction === 'TEST_RUN'
              "
            >
              <TestProblemSubmissionResults :submitting="submitting" />
            </div>
            <error-message :ex="ex"></error-message>
          </div>
        </div>
      </template>
    </HiringCodeEditor>
  </v-card>
</template>
<script>
import HiringCodeEditor from '../editor/HiringCodeEditor.vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import TestProblemSubmissionResults from './TestProblemSubmissionResults.vue'
import CustomTestProblemSubmissionResults from './CustomTestProblemSubmissionResults.vue'
import { mdiChevronDown, mdiChevronUp } from '@mdi/js'
import ErrorMessage from '../../ErrorMessage.vue'
import 'codedrills_proto/io/codedrills/proto/content/content_pb'

const EvaluationType = proto.io.codedrills.proto.judge.EvaluationType

export default {
  components: {
    HiringCodeEditor,
    TestProblemSubmissionResults,
    CustomTestProblemSubmissionResults,
    ErrorMessage,
  },
  props: {
    currentQuestion: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mdiChevronDown,
      mdiChevronUp,
      problemSolCode: '',
      language: null,
      codeBar: null,
      userInput: '',
      judging: false,
      rateLimit: false,
      // CUSTOM_RUN, TEST_RUN, FULL_RUN, HIDDEN
      codeAction: 'HIDDEN',
      testId: null,
      hideStatus: true,
      submitting: false,
      testRunSubmitting: false,
      fullRunSubmitting: false,
      ex: null,
    }
  },
  created() {
    this.language =
      this.languageKeys[this.userPreference?.getEditorConfig()?.getLanguage()]
    this.testId = this.currentTestView?.getTestView().getTestPreview().getId()

    console.log('TEST VIEW', this.currentTestView)
    console.log('TEST ID', this.testId)
  },
  computed: {
    ...mapGetters('user', ['userId']),
    ...mapState('user', ['user', 'userPreference']),
    ...mapState('candidate', ['currentTestView']),
    ...mapGetters('judge', ['languageKeys']),
    ...mapState('judge', ['submissionId', 'submissionStatus', 'codeTemplate']),
    ...mapGetters('candidate', ['getContentTypes']),
    hasWrappedIo() {
      return (
        this.currentQuestion.getMetaData()?.getProblemMeta()?.getIoType() == 2
      )
    },
    isUserInput() {
      return !this.hasWrappedIo && this.userInput
    },
    allowedLanguages() {
      if (!this.currentTestView) return []
      return this.currentTestView
        .getTestView()
        ?.getLanguageRestriction()
        .getAllowLanguageList()
        .map((ln) => this.languageKeys[ln])
    },
    evaluationType() {
      if (this.currentTestView) {
        console.log('CQ.....', this.currentQuestion.toObject())
        return this.currentQuestion
          .getMetaData()
          ?.getProblemMeta()
          ?.getEvaluationType()
      }
    },
  },
  methods: {
    ...mapActions('judge', ['submit', 'fetchCodeTemplate']),
    ...mapMutations('judge', ['clearSubmission']),
    setLanguage(language) {
      this.language = language
      this.loadTemplateOrLocalCode()
    },
    problemAction(action) {
      this.codeAction = action
      console.log(action)
      switch (action) {
        case 'TEST_RUN':
          console.log('TEST_RUN')
          this.testRun()
          break
        case 'FULL_RUN':
          console.log('FULL_RUN')
          this.fullRun()
          break
        case 'CUSTOM_RUN':
          this.hideStatus = true
          console.log('CUSTOM_RUN')
          break
        case 'HIDDEN':
          console.log('HIDDEN')
          this.codeAction = 'HIDDEN'
          break
        default:
          console.log('DEFAULT')
          break
      }
    },
    fullRun() {
      this.fullRunSubmitting = true
      this.submitCode(true)
    },
    testRun() {
      this.testRunSubmitting = true
      this.submitCode(false)
      this.hideStatus = false
    },
    submitCode(isFull) {
      this.clearSubmission()
      this.submitting = true
      console.log('Submit Code ..', isFull, this.isUserInput, this.stdin)
      console.log(
        'Submit Code User Code ..',
        this.problemSolCode,
        this.language,
      )

      return this.submit({
        id: this.currentQuestion.getId(),
        version: this.currentQuestion.getVersion(),
        code: this.problemSolCode,
        language: this.language,
        isFull: isFull,
        parentId: this.currentTestView
          ?.getTestView()
          ?.getTestPreview()
          ?.getId(),
        isUserInput: this.isUserInput,
        stdin: this.isUserInput && !isFull ? this.userInput : '',
      })
        .then((res) => {
          console.log(`submitCode:component:`, res)
          console.log(`submitCode:submissionId:`, this.submissionId)
        })
        .catch((ex) => {
          this.clearSubmission()
          this.ex = ex
        })
        .finally(() => {
          this.clearSubmitting()
        })
    },
    getCodeTemplate() {
      let queriedLang = this.language
      console.log(`getCodeTemplate:`, this.language)
      console.log(`evaluation type`, this.evaluationType)
      if (
        this.language &&
        this.evaluationType !== EvaluationType.EVALUATION_TYPE_SQL
      ) {
        this.fetchCodeTemplate({
          problemId: this.currentQuestion?.getId(),
          version: this.currentQuestion?.getVersion(),
          language: this.language,
        })
          .then((res) => {
            if (this.language === queriedLang) {
              this.problemSolCode = res?.getCode()
            } else {
              this.loadTemplateOrLocalCode()
            }
          })
          .catch((ex) => {
            this.$store.dispatch('notifs/addNotif', {
              text: 'Error fetching code template. Press reset button on code editor to try again',
              type: 'error',
            })
          })
      } else {
        this.problemSolCode = ''
      }
    },
    loadTemplateOrLocalCode() {
      if (this.user) {
        const key =
          this.userId +
          '-' +
          this.language +
          '-' +
          this.currentQuestion.getId() +
          '-' +
          this.testId
        if (localStorage.getItem(key)) {
          const val = JSON.parse(localStorage.getItem(key))
          const now = Date.now()
          if (now > val.ttl) {
            localStorage.removeItem(key)
            this.getCodeTemplate()
          } else {
            console.log(`loadTemplateOrLocalCode`, val)
            this.problemSolCode = val.code
          }
        } else {
          this.getCodeTemplate()
        }
      } else {
        this.getCodeTemplate()
      }
    },
    removeOldCode() {
      if (this.user) {
        const key =
          this.user.uid +
          '-' +
          this.language +
          '-' +
          this.currentQuestion.getId() +
          '-' +
          this.testId
        if (localStorage.getItem(key)) {
          localStorage.removeItem(key)
        }
      }
      this.getCodeTemplate()
    },
    clearSubmitting() {
      this.submitting = false
      this.fullRunSubmitting = false
      this.testRunSubmitting = false
    },
  },
  watch: {
    problemSolCode: {
      handler() {
        const now = Date.now()
        const expiry = now + 14 * 24 * 60 * 60 * 1000
        if (this.user) {
          localStorage.setItem(
            this.userId +
              '-' +
              this.language +
              '-' +
              this.currentQuestion.getId() +
              '-' +
              this.testId,
            JSON.stringify({
              code: this.problemSolCode,
              language: this.language,
              ttl: expiry,
            }),
          )
        }
      },
      deep: true,
    },
    currentTestView: {
      handler() {
        this.clearSubmitting()
        const problemType =
          this.getContentTypes[
            this.currentTestView.getTestView().getQuestionView().getType()
          ]
        if (problemType === 'PROBLEM') {
          this.loadTemplateOrLocalCode()
        }
        this.codeAction = 'HIDDEN'
      },
    },
  },
}
</script>
<style scoped>
.custom-submission-overflow {
  height: 180px;
  overflow-y: auto;
  overflow-x: hidden;
}

.submission-overflow {
  height: 220px;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
