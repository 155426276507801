import store from '../store'

export function notifyError(ex) {
  var msg =
    (ex && ex.message) || 'An error ocurred while performing this operation'
  notify(msg, 'error')
}

export function notify(msg, type) {
  console.log('Notify', type, msg)
  store.dispatch('notifs/addNotif', {
    text: msg,
    type: type,
  })
}
