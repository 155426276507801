<template>
  <v-row class="ma-3" align="center" justify="center">
    <v-btn class="ma-4" color="accent" dark @click="openDialog">
      {{ isSingleMemberTeam ? 'Register' : 'Create Team' }}
    </v-btn>
    <v-dialog v-model="roleDialog">
      <v-card>
        <v-card-title>
          <img
            v-if="contestBannerUrl"
            style="max-width: 100%"
            :src="contestBannerUrl"
            alt="contest banner"
          />
          <div v-else>{{ isSingleMemberTeam ? 'Register' : 'New Team' }}</div>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row justify="center">
              <v-col :cols="4">
                <v-btn outlined x-large width="300" @click="nextDetails(true)"
                  >I am a coach/mentor</v-btn
                >
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col :cols="4">
                <v-btn outlined x-large width="300" @click="nextDetails(false)"
                  >I am a contestant</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="detailsDialog">
      <v-card>
        <v-card-title>
          <img
            v-if="contestBannerUrl"
            style="max-width: 100%"
            :src="contestBannerUrl"
            alt="contest banner"
          />
          <div v-else>{{ isSingleMemberTeam ? 'Register' : 'New Team' }}</div>
        </v-card-title>
        <v-card-text>
          <v-form v-model="valid">
            <v-card outlined v-if="!isSingleMemberTeam">
              <v-subheader>Team details</v-subheader>
              <v-divider></v-divider>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      color="accent"
                      label="Team name*"
                      required
                      :rules="[rules.required]"
                      v-model="teamName"
                      @input="editTeamName"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="!hasSchoolAttribute">
                  <v-col>
                    <v-text-field
                      color="accent"
                      label="Organization"
                      v-model="organization"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <custom-attributes
                  :customAttributes="
                    teamContestConfig.toObject().teamCustomAttributeMap
                  "
                  @change="(attributeValue) => addTeamValue(attributeValue)"
                ></custom-attributes>
              </v-container>
            </v-card>

            <v-card
              outlined
              v-for="n in teamContestConfig.getMaxTeamSize()"
              :key="'member-' + n"
            >
              <v-subheader class="font-weight-bold green lighten-3">{{
                memberLabel(n)
              }}</v-subheader>
              <v-divider></v-divider>
              <v-container>
                <v-row class="">
                  <v-col>
                    <v-text-field
                      color="accent"
                      :label="'Email' + (memberRequired(n) ? '*' : '')"
                      :required="memberRequired(n)"
                      :rules="emailRules(memberRequired(n))"
                      :readonly="n === 1 && !selfCoach"
                      :value="selfEmail(false, n)"
                      @input="(e) => setMemberEmail(n, e)"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-if="isSingleMemberTeam"
                      color="accent"
                      label="Name*"
                      required
                      :rules="[rules.required]"
                      v-model="teamName"
                      @input="editTeamName"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <custom-attributes
                  v-if="memberRequired(n) || memberPresent[n]"
                  :customAttributes="
                    teamContestConfig.toObject().memberCustomAttributeMap
                  "
                  @change="
                    (attributeValue) => addMemberAttributes(n, attributeValue)
                  "
                ></custom-attributes>
              </v-container>
            </v-card>
            <!-- Coach Details -->
            <v-container>
              <v-row
                ><v-col>
                  <v-checkbox
                    v-if="coachEnabled && !selfCoach && !coachFieldRequired"
                    v-model="haveCoach"
                    label="I have a coach/mentor"
                  ></v-checkbox> </v-col
              ></v-row>
            </v-container>
            <v-card outlined v-if="coachEnabled && haveCoach">
              <v-subheader>Coach/mentor details</v-subheader>
              <v-divider></v-divider>
              <v-container>
                <v-row v-if="coachEnabled">
                  <v-col>
                    <v-text-field
                      color="accent"
                      :label="`Coach email${coachFieldRequired ? '*' : ''} `"
                      :rules="emailRules(coachFieldRequired)"
                      :required="coachFieldRequired"
                      :readonly="selfCoach"
                      :value="selfEmail(true)"
                      @input="(e) => setCoachEmail(e)"
                    >
                    </v-text-field>
                    <span class="text-caption mb-4 pb-4">
                      A mentor can be anyone who can motivate and support the
                      students(eg: Teacher, Counsellor)
                    </span>
                  </v-col>
                </v-row>
                <custom-attributes
                  v-if="coachEnabled && (coachFieldRequired || haveCoach)"
                  :customAttributes="
                    teamContestConfig.toObject().coachSetting
                      .coachCustomAttributeMap
                  "
                  @change="
                    (attributeValue) => addCoachAttributes(attributeValue)
                  "
                ></custom-attributes>
              </v-container>
            </v-card>
            <small>*indicates required field</small>
            <v-row>
              <v-col>
                <small class="font-weight-black">{{ termAndConditions }}</small>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions v-if="isTeamNameEdited">
          <v-checkbox
            v-model="checked"
            label="I confirm that the details confirm to CodeDrills' Be Nice, Be Respectful standards and is not inappropriate. I understand that I may be banned from the platform if it is inappropriate"
          ></v-checkbox>
        </v-card-actions>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="detailsDialog = false">
            Close
          </v-btn>
          <v-btn
            :disabled="!checked"
            color="grey darken-1"
            text
            @click="createTeam"
          >
            {{ isSingleMemberTeam ? 'Register' : 'Create' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import Loader from '@/components/Loader'
import CustomAttributes from './CustomAttributes.vue'
import user from 'codedrills_proto/io/codedrills/proto/user/user_service_grpc_web_pb'
var user_proto = proto.io.codedrills.proto.user

export default {
  props: {
    error: {
      type: Boolean,
      required: true,
    },
    teamContestConfig: {
      type: Object,
      required: false,
    },
  },
  name: 'NewTeamDialog',
  data: function () {
    return {
      rules: {
        required: (v) => !!v || 'Required',
      },
      toggleloader: false,
      valid: true,
      roleDialog: false,
      detailsDialog: false,
      selfCoach: false,
      email: null,
      teamName: '',
      haveCoach: false,
      organization: '',
      teamDetails: new user_proto.TeamDetails(),
      membersList: [],
      memberPresent: [],
      // teamDetails: {
      //   teamName: null,
      //   emailIDs: [],
      //   contentID: null,
      // 	organization: null,
      // },
      isTeamNameEdited: false,
      checked: false,
      contestBannerUrl: null,
    }
  },

  computed: {
    ...mapState('content', ['contentView', 'userContentPreviews']),
    ...mapState('user', ['team', 'user', 'profile']),
    ...mapState('timesync', ['serverTime']),
    upcomingContestSelectItems() {
      return (
        this.userContentPreviews &&
        this.userContentPreviews
          .filter(
            (c) =>
              c.getContentPreview().getMetaData().getContestMeta() &&
              c
                .getContentPreview()
                .getMetaData()
                .getContestMeta()
                .getContestType() == 1 &&
              c
                .getContentPreview()
                .getMetaData()
                .getContestMeta()
                .getStartAt() > this.serverTime &&
              c.getContentPreview().getMetaData().getContestMeta().getEndAt() >
                this.serverTime,
          )
          .map((c) => c.getContentPreview())
          .map((e) => {
            return { text: e.getTitle(), value: e.getId() }
          })
      )
    },
    hasSchoolAttribute() {
      return (
        this.teamContestConfig &&
        (this.teamContestConfig
          .toObject()
          .memberCustomAttributeMap.findIndex(
            (ca) =>
              ca[0] === 'School' ||
              ca[0] === 'Institute name' ||
              ca[0] === 'School Name',
          ) >= 0 ||
          this.teamContestConfig
            .toObject()
            .teamCustomAttributeMap.findIndex(
              (ca) =>
                ca[0].toLowerCase().includes('school') ||
                ca[0].toLowerCase().includes('institute') ||
                ca[0].toLowerCase().includes('college'),
            ) >= 0)
      )
    },
    termAndConditions() {
      //var message = this.contentView.getMetaData().getContestMeta().getTermsAndConditions();
      var message =
        'By partcipating in ' +
        this.contentView?.getTitle() +
        ' Contest, I agree to share my contact information with the Organizer and Sponsor'
      console.log('message', message)
      return message
    },
    coachFieldRequired() {
      const presenceIndex = this.teamContestConfig
        ?.getCoachSetting()
        ?.getCoachPresence()
      return (
        this.getFieldPresenceList()[presenceIndex] === 'FIELD_PRESENCE_REQUIRED'
      )
    },
    isSingleMemberTeam() {
      return this.teamContestConfig.getMaxTeamSize() == 1
    },
    coachEnabled() {
      return this.teamContestConfig.getCoachSetting()?.getCoachEnabled()
    },
  },
  created() {
    this.membersList = []
    this.memberPresent = []
    if (this.coachEnabled) {
      this.membersList.push(new user_proto.Member().setMemberRole(2))
      this.memberPresent.push(false)
      if (this.coachFieldRequired) {
        this.haveCoach = true
      }
    }
    for (var i = 0; i < this.teamContestConfig.getMaxTeamSize(); ++i) {
      this.membersList.push(new user_proto.Member().setMemberRole(1))
      this.memberPresent.push(false)
    }
    this.contestBannerUrl =
      this.contentView.getMetaData()?.getContestMeta()?.getBannerUrl() || ''
  },
  components: {
    Loader,
    CustomAttributes,
  },
  methods: {
    ...mapGetters('content', ['getContentType', 'getFieldPresenceList']),
    ...mapActions('content', ['fetchUserContentPreviews']),
    openDialog() {
      if (this.coachEnabled) {
        this.roleDialog = true
      } else {
        this.nextDetails(false)
      }
    },
    nextDetails(isCoach) {
      this.roleDialog = false
      this.detailsDialog = true
      this.selfCoach = isCoach
      this.teamName = undefined
      if (this.selfCoach) {
        this.setCoachEmail(this.user.email)
        this.haveCoach = true
      } else {
        this.setMemberEmail(1, this.user.email)
        if (this.isSingleMemberTeam) {
          this.teamName = this.profile.getName()
          this.isTeamNameEdited = true
        }
        if (!this.coachFieldRequired) {
          this.haveCoach = false
        }
      }
    },
    selfEmail(isCoach, n) {
      console.log(
        'SelfEmail: ',
        n,
        isCoach,
        this.isCoachEnabled,
        this.membersList,
      )
      if (this.coachEnabled) {
        if (isCoach === true) {
          return this.membersList[0].getEmail()
        } else if (isCoach === false && n === 1) {
          return this.membersList[1].getEmail()
        }
      } else {
        if (isCoach === false && n === 1) {
          return this.membersList[0].getEmail()
        }
      }
      return ''
    },

    createTeam() {
      this.toggleloader = true
      this.isTeamNameEdited = false
      this.valid = true
      this.teamDetails.setName(this.teamName)
      var org = this.hasSchoolAttribute
        ? this.schoolAttributeValue()
        : this.organization
      this.teamDetails.setOrganizations(
        new user_proto.Organizations().addOrganizations(org),
      )
      this.teamDetails.setMembersList(
        this.membersList.filter((m) => m.getEmail() && m.getEmail() !== ''),
      )
      console.log('td sent', this.teamDetails.toObject())
      console.log('Orgs .. ', this.teamDetails.organization)
      this.$emit('callCreateTeam', this.teamDetails)
    },
    schoolAttributeValue() {
      console.log('schoolAttributeValue', this.membersList[0].toObject())
      var idx = this.coachEnabled ? 1 : 0
      if (!this.membersList[idx].toObject().customAttribute) {
        return ''
      }
      var fromMember = this.membersList[idx]
        .toObject()
        .customAttribute.customAttributeMap.find(
          (ca) =>
            ca[0] == 'School' ||
            ca[0] == 'Institute name' ||
            ca[0] == 'School Name',
        )
      if (fromMember) {
        return fromMember[1].stringValue
      }
      var fromTeam = this.teamDetails
        .toObject()
        .customAttribute.customAttributeMap.find(
          (ca) =>
            ca[0].toLowerCase().includes('school') ||
            ca[0].toLowerCase().includes('institute') ||
            ca[0].toLowerCase().includes('college'),
        )
      return fromTeam ? fromTeam[1].stringValue : ''
      /*
      return this.teamDetails
        .toObject()
        .customAttribute.customAttributeMap.find(
          (ca) => ca[0] == 'School' || ca[0] == 'Institute name' || ca[0] == 'School Name',
        )[1].stringValue
        */
    },
    emailRules(required) {
      return [
        (v) =>
          (!required && v == '') || /.+@.+/.test(v) || 'E-mail must be valid',
      ]
    },
    setInnerMemberEmail(index, email) {
      console.log('Setting inner mail at ..', index, email)
      this.membersList[index].setEmail(email)
      // make sure revalidation is done if email is deleted after entering
      // by checking for empty string
      this.$set(this.memberPresent, index, email !== '')
      console.log('member list after setting ..', this.membersList)
    },
    addInnerMemberAttributes(index, attribute) {
      console.log('Member at index ,', index, attribute)
      if (!this.membersList[index].getCustomAttribute()) {
        this.membersList[index].setCustomAttribute(
          new user_proto.CustomAttributeMap(),
        )
      }
      this.membersList[index]
        .getCustomAttribute()
        .getCustomAttributeMap()
        .set(
          attribute.key,
          new user_proto.CustomAttributeValue().setStringValue(attribute.value),
        )
      this.$set(this.membersList, index, this.membersList[index])
      console.log(
        'Member set at index ,',
        index,
        this.membersList[index].toObject(),
      )
    },
    //TODO:
    removeEmail(ID) {
      this.teamDetails.emailIDs.splice(this.teamDetails.emailIDs.indexOf(ID), 1)
      this.teamDetails.emailIDs = [...this.teamDetails.emailIDs]
    },
    editTeamName() {
      this.isTeamNameEdited = true
    },
    addTeamValue(attribute) {
      console.log('Values are ..', attribute, this.teamDetails.toObject())
      if (!this.teamDetails.getCustomAttribute()) {
        this.teamDetails.setCustomAttribute(new user_proto.CustomAttributeMap())
      }
      console.log('Team Attribute ..', this.teamDetails.toObject())
      this.teamDetails
        .getCustomAttribute()
        .getCustomAttributeMap()
        .set(
          attribute.key,
          new user_proto.CustomAttributeValue().setStringValue(attribute.value),
        )
    },
    memberLabel(n) {
      if (this.teamContestConfig.getMaxTeamSize() > 1) {
        return 'Member ' + n + ' details'
      } else {
        return 'Contestant details'
      }
    },
    memberRequired(n) {
      return (
        this.teamContestConfig.getMinTeamSize() <= n &&
        n <= this.teamContestConfig.getMaxTeamSize()
      )
    },

    addCoachAttributes(attributes) {
      console.log('Coach attributes ..', attributes)
      this.addInnerMemberAttributes(0, attributes)
    },
    setCoachEmail(email) {
      console.log('Coach Email outer ..', email)
      this.setInnerMemberEmail(0, email)
    },
    addMemberAttributes(idx, attributes) {
      console.log('member attributes ..', attributes)
      if (this.coachEnabled) {
        this.addInnerMemberAttributes(idx, attributes)
      } else {
        this.addInnerMemberAttributes(idx - 1, attributes)
      }
    },
    setMemberEmail(idx, email) {
      // console.log(`presidx`, this.teamContestConfig.getCoachSetting().toObject(), this.getFieldPresenceList()[2]);
      console.log('Email on idx is ', idx, email)
      if (this.coachEnabled) {
        console.log('Coach Email on idx is ', idx, email)
        this.setInnerMemberEmail(idx, email)
      } else {
        console.log('Member Email on idx is ', idx, email)
        this.setInnerMemberEmail(idx - 1, email)
      }
    },
  },
  watch: {
    error: function (n, o) {
      console.log('team created', n)
      this.toggleloader = !n
    },
    valid(v) {
      console.log('valid', v)
    },
    checked(c) {
      console.log('checked', c)
    },
  },
}
</script>
