<template>
  <span>
    <v-tabs
      :vertical="$vuetify.breakpoint.smAndDown"
      color="accent"
      v-model="tab"
    >
      <v-tab href="#overview">Overview</v-tab>
      <v-tab href="#myteam" v-if="teamContest">My Registration</v-tab>
      <v-tab href="#allteams" v-if="teamContest">All Registrants</v-tab>
    </v-tabs>
    <v-tabs-items :value="tab">
      <v-tab-item value="overview">
        <v-row v-if="!user && teamContest && editableContest">
          <v-col>
            Please <router-link to="/community/login"> login </router-link> to
            register
          </v-col>
        </v-row>
        <v-row
          v-else-if="
            editableContest && userContestTeams && userContestTeams.length == 0
          "
        >
          <v-col>
            <v-alert outlined border="top" color="orange" type="info">
              You are not registered for this contest
              <v-btn text color="success" @click="goToTeam"
                >Click to Register</v-btn
              >
            </v-alert>
          </v-col>
        </v-row>
        <v-row v-else-if="editableContest && userContestTeams && !acceptedTeam">
          <v-col>
            <v-alert outlined border="top" color="orange" type="info">
              No accepted invitation. Accept an invitation or register
              <v-btn text color="success" @click="goToTeam"
                >Check Invitation</v-btn
              >
            </v-alert>
          </v-col>
        </v-row>
        <v-row v-else-if="acceptedTeam">
          <v-col>
            <v-alert outlined border="top" color="green" type="info">
              You are registered for this contest, check details
              <v-btn text color="blue" @click="goToTeam">My Registration</v-btn>
            </v-alert>
          </v-col>
        </v-row>
        <v-row v-if="user && !noDisplayName">
          <v-col>
            <v-alert outlined border="top" color="red" type="info">
              Your name is missing, please set your name from
              <router-link text color="success" to="/profile/edit"
                >Profile</router-link
              >
            </v-alert>
          </v-col>
        </v-row>
        <v-row class="mt-8">
          <v-col>
            <div class="display-1">About</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="11">
            <v-card flat outlined class="pl-5 py-3">
              <div class="d-flex flex-no-wrap">
                <div class="d-flex align-center">
                  <html-view :html="view.getAboutHtml()"></html-view>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mt-8">
          <v-col>
            <div class="display-1">Rules</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="11">
            <v-card flat outlined class="pl-5 py-3">
              <div class="d-flex flex-no-wrap">
                <div class="d-flex align-center">
                  <html-view :html="view.getRulesHtml()"></html-view>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item value="myteam">
        <v-row v-if="!user">
          <v-col>
            Please <router-link to="/community/login"> login </router-link> to
            register or accept invitation
          </v-col>
        </v-row>
        <v-row v-else-if="userContestTeams && userContestTeams.length == 0">
          <v-col>
            <v-alert outlined border="top" color="orange" type="info">
              You are not registered for this contest
            </v-alert>
            <v-row v-if="teamContest && editableContest">
              <v-col>
                <new-team-dialog
                  :error="teamCreationFailed"
                  :teamContestConfig="teamContestConfig"
                  @callCreateTeam="handleCreateTeam"
                ></new-team-dialog>
                <error-message :ex="ex"></error-message>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-else-if="userContestTeams && !acceptedTeam">
          <v-col>
            <v-alert outlined border="top" color="orange" type="info">
              No accepted invitation. Accept an invitation or register
            </v-alert>
            <v-row v-if="teamContest && editableContest">
              <v-col>
                <new-team-dialog
                  :error="teamCreationFailed"
                  :teamContestConfig="teamContestConfig"
                  @callCreateTeam="handleCreateTeam"
                ></new-team-dialog>
              </v-col>
            </v-row>
            <div
              v-for="(team, index) of userContestTeams"
              :key="`member-${team.getId()} - ${index}`"
            >
              <team-detail :teamView="team"></team-detail>
            </div>
          </v-col>
        </v-row>
        <v-row v-else-if="ifCoach">
          <v-col>
            <v-row v-if="teamContest && editableContest">
              <v-col>
                <new-team-dialog
                  :error="teamCreationFailed"
                  :teamContestConfig="teamContestConfig"
                  @callCreateTeam="handleCreateTeam"
                ></new-team-dialog>
              </v-col>
            </v-row>
            <div
              v-for="(team, index) of userContestTeams"
              :key="`coach-${team.getId()} - ${index}`"
            >
              <team-detail :teamView="team"></team-detail>
            </div>
          </v-col>
        </v-row>
        <v-row v-else-if="!ifCoach && acceptedTeam">
          <v-col>
            <!-- delete only if team exists -->
            <team-detail
              :teamView="acceptedTeam"
              :editableContest="editableContest"
              :contestUrl="contentView.getUrl()"
            ></team-detail>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item value="allteams">
        <v-row>
          <v-col>
            <teams-list
              :contestId="contentView.getId()"
              :contestUrl="contentView.getUrl()"
              :contestMetadata="contentView.getMetaData().getContestMeta()"
            ></teams-list>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </span>
</template>

<script>
import HtmlView from '../components/HtmlView'
import { mapActions, mapState } from 'vuex'
import NewTeamDialog from '../components/NewTeamDialog'
import TeamsList from './TeamsList.vue'
import TeamDetail from '../components/TeamDetail'
import ErrorMessage from '../components/ErrorMessage'
import 'codedrills_proto/io/codedrills/proto/content/content_pb'

const MemberRole = proto.io.codedrills.proto.user.MemberRole
const MembershipState = proto.io.codedrills.proto.user.MembershipState
const ContestType = proto.io.codedrills.proto.content.ContestType

export default {
  props: {
    contentView: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      ex: null,
      teamCreationFailed: false,
    }
  },
  methods: {
    ...mapActions('content', ['fetchContentView']),
    ...mapActions('user', ['getProfile', 'createTeam', 'getUserContestTeams']),
    handleCreateTeam(details) {
      console.log('Team details ...', details.toObject())
      details.setContentId(this.contentView.getId())
      console.log('Team details ...?', details.toObject())
      this.createTeam({ teamDetails: details })
        .then(() => {
          this.$store.dispatch('notifs/addNotif', {
            text:
              'Congrats! You have successfully registered for the contest: ' +
              this.team.getTeamView().getContentPreview().getTitle() +
              '!',
            type: 'success',
          })
          this.$router.push(
            `/contests/${this.team
              .getTeamView()
              .getContentPreview()
              .getUrl()}/teams/${this.team.getTeamView().getId()}`,
          )
        })
        .catch((ex) => {
          this.teamCreationFailed = true
          this.ex = ex
        })
    },
    goToTeam() {
      this.$router.push({ path: this.$route.path, query: { tab: 'myteam' } })
    },
  },
  computed: {
    ...mapState('timesync', ['serverTime']),
    ...mapState('user', ['team', 'userContestTeams', 'user', 'profile']),
    tab: {
      set(tab) {
        console.log('TAB is ..', tab)
        this.$router.replace({ query: { ...this.$route.query, tab } })
      },
      get() {
        console.log('TAB returning ...', this.$route.query.tab)
        return this.$route.query.tab
      },
    },
    view() {
      return this.contentView.getDataView().getContestView()
    },
    teamContestConfig() {
      return this.contentView
        .getMetaData()
        .getContestMeta()
        .getTeamContestConfig()
    },
    teamContest() {
      console.log(
        'contest type ..',
        this.contentView.getMetaData().getContestMeta().getContestType(),
      )
      var isTeamContest =
        this.contentView.getMetaData().getContestMeta().getContestType() ===
        ContestType.TEAM_CONTEST
      console.log('isTeamContest ..', isTeamContest)
      return isTeamContest
    },
    editableContest() {
      var editableContest =
        this.contentView.getMetaData().getContestMeta() &&
        this.contentView
          .getMetaData()
          .getContestMeta()
          .getTeamContestConfig() &&
        this.contentView
          .getMetaData()
          .getContestMeta()
          .getTeamContestConfig()
          .getIsEditable()
      console.log('editableContest ....', editableContest)
      return editableContest
    },
    acceptedTeam() {
      console.info('User contest teams ...', this.userContestTeams)
      return (
        this.userContestTeams &&
        this.userContestTeams.find((t) => {
          var member = t
            .getMembersList()
            .find((u) => u.getEmail() === this.user.email)
          if (member) {
            var isAccepted =
              member.getMembershipState() ===
              MembershipState.MEMBERSHIP_ACCEPTED
            return isAccepted
          }
        })
      )
    },
    ifCoach() {
      return (
        this.userContestTeams &&
        !this.userContestTeams.find((t) => {
          var member = t
            .getMembersList()
            .find((u) => u.getEmail() === this.user.email)
          if (member) {
            console.log(
              'Member role ...',
              member.getMemberRole(),
              MemberRole.MEMBER_ROLE_COACH,
            )
            var isItCoach =
              member.getMemberRole() !== MemberRole.MEMBER_ROLE_COACH
            console.log('Is it coach ...', isItCoach)
            return isItCoach
          }
        })
      )
    },
    noDisplayName() {
      console.log('No display name ...', this.profile)
      return this.profile?.getHandle() || this.profile?.getName()
    },
    registrationContest() {
      return this.contentView
        ?.getMetaData()
        ?.getTagsList()
        ?.includes('types/registration')
    },
  },

  components: {
    HtmlView,
    NewTeamDialog,
    TeamsList,
    TeamDetail,
    ErrorMessage,
  },
}
</script>
<style scoped>
.v-tabs--vertical > .v-tabs-bar .v-tab {
  min-width: 100%;
}
</style>
