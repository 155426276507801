<template>
  <Page
    minimal
    title="Drills"
    :apiStatusList="[fetchUserContentPreviewsStatus]"
  >
    <v-row class="mt-8">
      <v-col>
        <span class="font-weight-bold text-truncate text-h5">
          Current Drills
        </span>
      </v-col>
    </v-row>
    <v-row v-if="isUserLoggedIn">
      <v-col v-if="!activeDrills || activeDrills.length == 0">
        Solve a problem in a drill to start tracking.
      </v-col>
      <v-col
        :xs="12"
        :sm="6"
        :md="3"
        v-for="drill in activeDrills"
        v-bind:key="drill.getContentPreview().getId()"
      >
        <drill-card :drill="drill"></drill-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col>
        Please
        <router-link
          class="ml-1 font-weight-bold dashboard_link"
          to="/community/login"
        >
          login
        </router-link>
        to see current Drills
      </v-col>
    </v-row>

    <v-row class="mt-8">
      <v-col>
        <span class="font-weight-bold text-truncate text-h5">
          Company Drills
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        :xs="12"
        :sm="6"
        :md="3"
        v-for="drill in companyDrills"
        v-bind:key="drill.getContentPreview().getId()"
      >
        <drill-card :drill="drill"></drill-card>
      </v-col>
    </v-row>

    <v-row class="mt-8">
      <v-col>
        <span class="font-weight-bold text-truncate text-h5">
          Topic Drills
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        :xs="12"
        :sm="6"
        :md="3"
        v-for="drill in topicDrills"
        v-bind:key="drill.getContentPreview().getId()"
      >
        <drill-card :drill="drill"></drill-card>
      </v-col>
    </v-row>
  </Page>
</template>
<script>
import { getters, mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import Page from '../components/Page'
import DrillCard from '../components/DrillCard'

var content_proto = proto.io.codedrills.proto.content

export default {
  components: {
    Page,
    DrillCard,
  },
  methods: {
    ...mapActions('content', ['fetchUserContentPreviews']),
  },
  computed: {
    ...mapState('content', [
      'userContentPreviews',
      'fetchUserContentPreviewsStatus',
    ]),
    ...mapState('user', ['user']),
    isUserLoggedIn() {
      return this.user && !this.user.isAnonymous
    },
    companyDrills() {
      if (this.userContentPreviews) {
        return this.userContentPreviews.filter(
          (c) =>
            c
              .getContentPreview()
              .getMetaData()
              .getTagsList()
              .indexOf('drills/company') != -1,
        )
      } else {
        return []
      }
    },
    topicDrills() {
      if (this.userContentPreviews) {
        return this.userContentPreviews.filter(
          (c) =>
            c
              .getContentPreview()
              .getMetaData()
              .getTagsList()
              .indexOf('drills/topic') != -1,
        )
      } else {
        return []
      }
    },
    activeDrills() {
      if (this.userContentPreviews) {
        return this.userContentPreviews.filter(
          (c) =>
            c.getUserActivity() &&
            c.getUserActivity().getData().getDrillReport() &&
            c.getUserActivity().getData().getDrillReport().getTracking(),
        )
      } else {
        return []
      }
    },
  },
  created() {
    this.fetchUserContentPreviews({
      tags: ['drills/topic', 'drills/company'],
      contentTypes: [content_proto.ContentType.DRILL],
    }).then((__) => console.log('list', this.userContentPreviews))
  },
}
</script>
<style scoped>
.dashboard_link {
  color: #33a79d;
  text-decoration: none;
}
</style>
