import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import CustomIcon from '../components/CustomIcon'
import AdBlocker from '../components/AdBlocker'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        //"accent": "#72DFD4",
        primary: '#2EAC68',
        accent: '#2EAC68',
        black: '#122333',
        danger: '#D30001',
      },
      dark: {
        primary: '#000000',
        background: '#000000',
      },
    },
  },
  icons: {
    iconfont: 'mdiSvg',
    values: {
      custom: {
        // name of our custom icon
        component: CustomIcon, // our custom component
      },
      adblocker: {
        component: AdBlocker,
      },
    },
  },
})
