<template>
  <span>
    <v-row>
      <v-col>
        <code-display
          :value="value"
          :availableLanguages="editorialLanguages"
          :language="language"
          @setLanguage="onLanguageChange"
        ></code-display>
      </v-col>
    </v-row>
  </span>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import CodeDisplay from '../components/CodeDisplay'

export default {
  props: {
    ids: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      submissions: {},
      language: 'CPP_17',
    }
  },
  computed: {
    ...mapGetters('judge', ['languages', 'languageKeys']),
    value() {
      var submission = this.submissions[this.language]
      return submission && submission.getCode()
    },
    editorialLanguages() {
      console.log('editorialLanguages ..', this.submissions)
      if (this.submissions) {
        return Object.keys(this.submissions)
      }
    },
  },
  methods: {
    ...mapActions('judge', ['fetchSubmissionDetail']),
    onLanguageChange(l) {
      console.log('new language is', l)
      this.language = l
    },
  },
  async mounted() {
    var submissionsMap = {}
    await Promise.all(
      this.ids.split(',').map((id) => {
        return this.fetchSubmissionDetail({ submissionId: id.trim() }).then(
          (sub) =>
            (submissionsMap[
              this.languageKeys[sub.getSubmission().getLanguage()]
            ] = sub.getSubmission()),
        )
      }),
    )
    this.submissions = submissionsMap
    this.language = this.editorialLanguages[0]
    console.log('setting lang...', this.language)
    console.log('languages ...', this.submissions)
  },

  components: {
    CodeDisplay,
  },
}
</script>
