<template>
  <div class="fill-height">
    <test-page :apiStatusList="[getHiringTestStatus]" :errMsg="errMsg">
      <template v-slot:top>
        <v-row
          class="align-center text-center"
          v-if="started & !testEndedLocally"
        >
          <v-col cols="3">
            <router-link to="/">
              <img
                alt="[C]"
                height="35"
                width="50"
                :src="require('../../assets/images/CodeDrillsLight.svg')"
              />
            </router-link>
          </v-col>
          <v-col cols="3">
            <v-icon
              :class="{ rotate: isTimeRunningOut }"
              :style="{ color: isTimeRunningOut ? 'red' : 'green' }"
            >
              {{ mdiTimerSand }}
            </v-icon>
            <b>Question Time:</b>
            {{ timeTillQuestionEnd }}/{{ questionDuration }}
          </v-col>

          <v-col cols="3">
            <b>Total Time:</b> {{ timeTillTestEnd }} / {{ testDuration }}
          </v-col>
          <v-col cols="3">
            <v-btn
              class="white--text text-none font-weight-medium text-body-1"
              color="danger"
              @click="endTestNavigation"
              elevation="0"
            >
              End Test
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <template v-slot:content>
        <v-row style="height: 100%">
          <v-col v-if="!loading" class="fill-height">
            <test-start-page
              v-if="!started"
              :testView="currentTestView"
            ></test-start-page>
            <test-end-page
              v-if="testEndedLocally"
              :testView="currentTestView"
            ></test-end-page>
            <test
              v-if="started && !testEndedLocally"
              :testView="currentTestView"
            >
            </test>
          </v-col>
        </v-row>
      </template>
    </test-page>
    <v-row v-if="yetToStart && !isTestEnded">
      <v-col>
        <v-alert type="warning" class="text-h5">
          <div v-html="waitMessage"></div>
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-if="isTestEnded">
      <v-col>
        <v-alert type="warning" class="text-h5">
          <div v-html="endMessage"></div>
        </v-alert>
      </v-col>
    </v-row>
    <BigConfirmation
      imageUrl="images/ConfirmTestEnd.png"
      ref="end"
    ></BigConfirmation>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import TestStartPage from '../../views/TestStartPage'
import TestEndPage from '../../views/TestEndPage'
import Test from '../../views/Test'
import TestPage from '../assessment/TestPage.vue'
import SmallConfirmation from '../assessment/SmallConfirmation.vue'
import BigConfirmation from '../assessment/BigConfirmation.vue'
import { mdiTimer, mdiTimerSand } from '@mdi/js'
import moment from 'moment'
import { prettyduration, formatDate } from '@/utils/helper.js'

export default {
  props: {},
  data() {
    return {
      loading: true,
      mdiTimer,
      mdiTimerSand,
      errMsg: '',
    }
  },
  methods: {
    ...mapActions('candidate', ['getHiringTest', 'fetchContentPreview']),
    ...mapActions('timesync', ['initTimeSync']),
    ...mapMutations('candidate', ['setTestEndedLocally']),
    ...mapActions('user', ['getUserContestTeams', 'fetchUserActivity']),
    async endTestNavigation() {
      console.log(`this.$refs:`, this.$refs)
      if (
        await this.$refs.end.open('Are you sure you want to STOP the test?', {
          color: 'primary',
        })
      ) {
        this.setTestEndedLocally(true)
      }
    },
    fetchTest() {
      if (!this.user) {
        return
      }
      this.getHiringTest({
        url: this.$route.params.url,
      })
        .then((_) => {
          console.log('Fetched hiringTest:', this.currentTestView)
          this.loading = false
        })
        .catch((err) => {
          this.fetchContentPreview({
            url: this.$route.params.url,
          }).then((_) => {
            this.loading = false
            if (this.yetToStart && !this.isTestEnded) {
              this.errMsg = ''
            } else if (this.isTestEnded) {
              this.errMsg = ''
            } else {
              this.errMsg =
                err?.message || 'Error loading test, please try again!'
            }
          })
        })
    },
  },
  computed: {
    ...mapState('timesync', ['serverTime']),
    ...mapState('user', ['userActivity']),
    ...mapState('candidate', [
      'currentTestView',
      'testEndedLocally',
      'getHiringTestStatus',
      'contentPreview',
      'fetchContentPreviewStatus',
    ]),
    ...mapState('user', ['user']),
    ...mapGetters('user', ['userId']),
    waitMessage() {
      return `
        Please wait for the test to begin. It will start in
        ${this.timeTillTestStart}.
      `
    },
    endMessage() {
      return `
        The Test has ended at ${this.testEndedAt}. \
        Please contact your recruiter for further details.
      `
    },
    testDuration() {
      return moment
        .utc(
          this.currentTestView
            ?.getTestView()
            ?.getTestTimeInfo()
            ?.getDuration() * 1000,
        )
        .format('HH:mm:ss')
    },
    started() {
      return (
        this.currentTestView?.getTestView()?.getTestTimeInfo()?.getStartedAt() >
        0
      )
    },
    yetToStart() {
      var startsAt = this.contentPreview
        ?.getMetaData()
        ?.getTestMeta()
        ?.getTestWindow()
        ?.getStartAt()
      console.log('startsAt:', startsAt)
      return startsAt > 0 && startsAt > this.serverTime
    },
    isTestEnded() {
      var endsAt = this.contentPreview
        ?.getMetaData()
        ?.getTestMeta()
        ?.getTestWindow()
        ?.getEndAt()
      console.log('endsAt:', endsAt)
      return endsAt > 0 && endsAt < this.serverTime
    },
    testEndedAt() {
      var endedAt = this.contentPreview
        ?.getMetaData()
        ?.getTestMeta()
        ?.getTestWindow()
        ?.getEndAt()
      return formatDate(endedAt)
    },
    timeTillTestStart() {
      var startsAt = this.contentPreview
        ?.getMetaData()
        ?.getTestMeta()
        ?.getTestWindow()
        ?.getStartAt()
      let timerem = startsAt - this.serverTime
      timerem = timerem > 0 ? timerem : 0
      return prettyduration(timerem)
    },

    timeTillQuestionEnd() {
      let timerem = this.questionEndEpoch - this.serverTime * 1000
      timerem = timerem > 0 ? timerem : 0
      return moment.utc(timerem).format('HH:mm:ss')
    },

    remainingTime() {
      const currentTimeInMilliseconds = this.serverTime * 1000
      const timeLeft = this.questionEndEpoch - currentTimeInMilliseconds
      return timeLeft > 0 ? Math.floor(timeLeft / 1000) : 0
    },

    isTimeRunningOut() {
      return this.remainingTime <= 30
    },

    testStartEpoch() {
      return this.currentTestView
        ?.getTestView()
        ?.getTestTimeInfo()
        ?.getStartedAt()
    },
    testDurationEpoch() {
      return (
        this.currentTestView?.getTestView()?.getTestTimeInfo()?.getDuration() *
        1000
      )
    },
    timeTillTestEnd() {
      let timerem =
        this.testStartEpoch + this.testDurationEpoch - this.serverTime * 1000
      timerem = timerem > 0 ? timerem : 0
      return moment.utc(timerem).format('HH:mm:ss')
    },
    questionStartEpoch() {
      return this.currentTestView
        ?.getTestView()
        ?.getQuestionTimeInfo()
        ?.getStartedAt()
    },
    questionEndEpoch() {
      // in ms
      return (
        this.questionStartEpoch +
        this.currentTestView
          ?.getTestView()
          ?.getQuestionTimeInfo()
          ?.getDuration() *
          1000
      )
    },
    questionDuration() {
      return moment
        .utc(
          this.currentTestView
            ?.getTestView()
            ?.getQuestionTimeInfo()
            ?.getDuration() * 1000,
        )
        .format('HH:mm:ss')
    },
  },
  components: {
    TestStartPage,
    TestEndPage,
    Test,
    TestPage,
    SmallConfirmation,
    BigConfirmation,
  },
  created() {
    this.initTimeSync()
  },
  mounted() {
    this.fetchTest()
  },
  watch: {
    user: function () {
      this.fetchTest()
    },
  },
}
</script>

<style>
html,
body,
canvas {
  margin: 0 !important;
  padding: 0 !important;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotate {
  animation: rotate 2s linear infinite;
}
</style>
