var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "fill-height",
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "8",
      "md": "4"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "body-1 accent white--text"
  }, [_vm._v(" Login / Signup ")]), _c('v-card-text', {
    staticClass: "pt-2"
  }, [_c('div', {
    attrs: {
      "id": "firebase-ui"
    }
  })])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }