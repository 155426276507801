<script>
// Cool way to render Vue components from HTML Strings
// https://medium.com/haiiro-io/compile-markdown-as-vue-template-on-nuxt-js-1c606c15731c
import VueWithCompiler from 'vue/dist/vue.esm'

import { VSimpleTable } from 'vuetify/lib'
import TextBlock from './TextBlock.vue'
import Solution from './Solution.vue'

export default {
  props: {
    html: {
      type: String,
      default: '',
    },
  },
  data() {
    return { templateRender: undefined }
  },
  watch: {
    html(to) {
      this.updateRender()
    },
  },
  created() {
    this.updateRender()
  },
  methods: {
    updateRender() {
      const compiled = VueWithCompiler.compile('<div>' + this.html + '</div>')
      this.templateRender = compiled.render
      this.$options.staticRenderFns = []
      for (const staticRenderFunction of compiled.staticRenderFns) {
        this.$options.staticRenderFns.push(staticRenderFunction)
      }
    },
  },
  render() {
    if (this.templateRender) {
      return this.templateRender()
    } else {
      return this.$slots.default
    }
  },
  components: {
    VSimpleTable,
    TextBlock,
    Solution,
  },
}
</script>
