import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/messaging'
import auth from './utils/auth'
import * as firebaseui from 'firebaseui'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import { getWhitelabelConfig } from './utils/whitelabel'
import './assets/css/main.css'

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: 'AIzaSyChiLDFW5EDgSCV4rEvR8MIEFVmFPvedfQ',
  authDomain: 'codedrills.io',
  databaseURL: 'https://codedrills.firebaseio.com',
  projectId: 'codedrills',
  storageBucket: 'codedrills.appspot.com',
  messagingSenderId: '864730259813',
  appId: '1:864730259813:web:7fe150e51205488a19418c',
}

var firebaseConfigLoad = {
  apiKey: 'AIzaSyDjixsj0Fhy3bMC0MjilfWcLf65sgs8rCw',
  authDomain: 'cdloadtest.firebaseapp.com',
  projectId: 'cdloadtest',
  storageBucket: 'cdloadtest.appspot.com',
  messagingSenderId: '891713579432',
  appId: '1:891713579432:web:b1bba9fc3ba8312e42159d',
}

var isProd = process.env.NODE_ENV === 'production'
var isStaging =
  process.env.NODE_ENV === 'development' &&
  process.env.VUE_APP_ENV !== 'loadtest'

// Initialize Firebase
if (isProd || isStaging) firebase.initializeApp(firebaseConfig)
else firebase.initializeApp(firebaseConfigLoad)

firebase.auth().onAuthStateChanged(auth.authChange)
Vue.prototype.$firebaseUI = new firebaseui.auth.AuthUI(firebase.auth())

var isProd = process.env.NODE_ENV === 'production'
var fullLogs = getWhitelabelConfig().fullLogs
// If query param has debug=true, enable all logs
if (new URLSearchParams(window.location.search).has('debug')) {
  fullLogs = true
}
if (isProd && !fullLogs) {
  console.log = function () {}
}

console.log('Env', process.env)
Vue.config.productionTip = false

if (firebase.messaging.isSupported()) {
  Vue.prototype.$messaging = firebase.messaging()
}
// TODO: Different integrations for different prod and staging
Sentry.init({
  Vue,
  dsn: 'https://bc1300de7db94973848333d4d2cd540b@o1359724.ingest.sentry.io/6647479',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [],
      logErrors: true,
    }),
    Sentry.metrics.metricsAggregatorIntegration(),
    Sentry.feedbackIntegration({
      colorScheme: 'system',
      showBranding: false,
      buttonLabel: 'Feedback',
      submitButtonLabel: 'Submit Feedback',
      formTitle: 'Feedback',
      messagePlaceholder:
        'What can we do better? Do NOT use this form for contest clarifications. This is not live monitored.',
      successMessageText: 'Thanks for your feedback!',
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
      maskAttributes: false,
      blockAllMedia: false,
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: isProd ? 0.1 : 0,
  replaysSessionSampleRate: isProd ? 0.001 : 0,
  replaysOnErrorSampleRate: isProd ? 0.1 : 0,
})

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')

if (firebase.messaging.isSupported()) {
  navigator.serviceWorker
    .register('/firebase-messaging-sw.js')
    .then((registration) => {
      Vue.prototype.$messaging.useServiceWorker(registration)
    })
    .catch((err) => {
      console.log(err)
    })
}
