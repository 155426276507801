var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "mt-2",
    attrs: {
      "id": "next",
      "width": 270,
      "height": 100,
      "to": '/drills/' + _vm.drill.getContentPreview().getUrl()
    }
  }, [_c('div', {
    staticClass: "d-flex flex-no-wrap"
  }, [_c('v-avatar', {
    staticClass: "mt-3 ml-3",
    attrs: {
      "size": _vm.imageWidth,
      "tile": ""
    }
  }, [_c('v-img', {
    attrs: {
      "src": _vm.drill.getContentPreview().getMetaData().getImageUrl()
    }
  })], 1), _c('div', [_c('v-card-title', [_c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('span', _vm._g({
          staticClass: "d-inline-block text-truncate",
          style: {
            width: _vm.titleWidth + 'px'
          }
        }, on), [_vm._v(" " + _vm._s(_vm.drill.getContentPreview().getTitle()) + " ")])];
      }
    }])
  }, [_vm._v(" " + _vm._s(_vm.drill.getContentPreview().getTitle()) + " ")])], 1), _c('v-card-subtitle', [_vm._v(_vm._s(_vm.solved) + "/" + _vm._s(_vm.totalContent) + " Problem Solved ")])], 1)], 1), _c('v-progress-linear', {
    staticClass: "mt-5",
    attrs: {
      "bottom": "",
      "absolute": "",
      "color": "accent",
      "value": _vm.progress,
      "height": 10
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }