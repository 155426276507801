var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt-n8"
  }, [_c('Navbar'), _c('v-row', {
    staticClass: "py-16",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "10",
      "lg": "8"
    }
  }, [_c('v-card', {
    staticClass: "px-4 px-lg-16 py-16"
  }, [_c('v-card-title', {
    staticClass: "text-h5 font-weight-medium black--text"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('v-card-subtitle', {
    staticClass: "text-h6 px-6 font-weight-medium black--text"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": 4
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "primary"
    }
  }, [_vm._v(_vm._s(_vm.mdiAlarm) + " ")]), _vm._v("Test duration: " + _vm._s(this.testDuration) + " ")], 1), _c('v-col', {
    attrs: {
      "cols": 4
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v(_vm._s(_vm.mdiInformation))]), _vm._v(" Total Questions: " + _vm._s(this.testQuestions) + " ")], 1)], 1), _c('v-row', [_c('v-col', [_vm._v(" Question Details "), _c('v-simple-table', [_c('thead', [_c('tr', [_c('th', [_vm._v("Order")]), _c('th', [_vm._v("Type")])])]), _c('tbody', _vm._l(_vm.questions, function (question, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_c('v-icon', [_vm._v(_vm._s(question.icon))]), _vm._v(" " + _vm._s(question.type) + " ")], 1)]);
  }), 0)])], 1)], 1), _c('v-row', [_c('v-col', [_vm._v(" Read the instructions carefully before you start the assessment! ")])], 1)], 1), _c('v-card-text', {
    staticClass: "px-8"
  }, [_c('v-row', {
    staticClass: "px-5"
  }, [_c('ul', {
    staticClass: "text-h6 black--text px-1 pb-1"
  }, [_c('li', {
    staticClass: "my-1"
  }, [_vm._v("Before you start")]), _c('ul', {
    staticClass: "text-subtitle-1 black--text px-1"
  }, [_c('li', [_vm._v(" Make sure you are using "), _c('b', [_vm._v("Google Chrome "), _c('v-icon', [_vm._v("$custom")])], 1)]), _c('li', [_vm._v(" Disable all "), _c('b', [_vm._v("ad-blockers")]), _c('v-icon', [_vm._v("$adblocker")]), _vm._v(" for the duration of the test ")], 1), _c('li', [_vm._v("We recommend completing the assessment in one go.")]), _c('li', [_vm._v("Make sure you have a stable internet connection")]), _c('li', [_vm._v(" We recommend you to attempt this "), _c('a', {
    attrs: {
      "href": "https://codedrills.io/signup/test-gbtf4z/testInvite"
    }
  }, [_vm._v("sample assessment")]), _vm._v(" before starting this assessment to familiarize yourself with the platform. ")])]), _c('li', {
    staticClass: "my-1"
  }, [_vm._v("About the assessment")]), _c('ul', {
    staticClass: "text-subtitle-1 black--text px-1"
  }, [_c('li', [_vm._v(" This assessment is "), _c('b', [_vm._v("timed")]), _vm._v(". A timer for overall assessment is shown at the top right. ")]), _c('li', [_c('b', [_vm._v("Each question")]), _vm._v(" is also "), _c('b', [_vm._v("timed")]), _vm._v(". A timer per question is shown as well. ")]), _c('li', [_vm._v(" Once you click "), _c('b', [_vm._v("Start Assessment")]), _vm._v(", the timer starts for the defined duration. ")]), _c('li', [_vm._v(" Once started, the timer "), _c('b', [_vm._v("can not be paused or restarted ")]), _vm._v(". ")]), _c('li', [_vm._v(" During the assessment, you can only move to the next question by pressing the "), _c('b', [_vm._v("Next")]), _vm._v(" button. Previous questions "), _c('b', [_vm._v("can not ")]), _vm._v(" be visited again. ")]), _c('li', [_vm._v(" The assessment will "), _c('b', [_vm._v("end automatically when the last question is submitted ")])])]), _c('li', {
    staticClass: "my-1"
  }, [_vm._v("Coding problem")]), _c('ul', {
    staticClass: "text-subtitle-1 black--text px-1"
  }, [_c('li', [_vm._v(" Your assessment may contain one or more coding problems. You can submit code "), _c('b', [_vm._v("multiple times")]), _vm._v(" using the "), _c('b', [_vm._v("Submit")]), _vm._v(" button, and only the best result will be considered. ")]), _c('li', [_vm._v(" The programming language might be "), _c('b', [_vm._v("restricted")]), _vm._v(", in case there is any restriction, you would be able to submit only in those languages. ")]), _c('li', [_c('b', [_vm._v("Run Code")]), _vm._v(" button is only for running your code against sample test cases and it is not considered for scoring. ")]), _c('li', [_c('b', [_vm._v("Submit")]), _vm._v(" button is for making a valid submission and it runs on hidden test cases; on success it shows score based on how many test cases passed. ")]), _c('li', [_vm._v(" The highest scoring submission out of all the submissions made with 'Submit' button is considered as your answer. ")]), _c('li', [_vm._v(" On clicking the "), _c('b', [_vm._v("Next")]), _vm._v(" button, all the submissions made with "), _c('b', [_vm._v("Submit")]), _vm._v(" button would "), _c('b', [_vm._v("automatically")]), _vm._v(" be considered for scoring. ")]), _vm._v(" If there is no submission made with "), _c('b', [_vm._v("Submit")]), _vm._v(" button, the current code in the code editor will not be auto-submitted and not considered for scoring. On clicking "), _c('b', [_vm._v("Next")]), _vm._v(" without any submissions, the problem would be considered as skipped. ")]), _c('li', {
    staticClass: "my-1"
  }, [_vm._v("MCQs (Multiple Choice Questions)")]), _c('ul', {
    staticClass: "text-subtitle-1 black--text px-1"
  }, [_c('li', [_vm._v("There is no negative marking for wrong answers")]), _c('li', [_vm._v("There maybe one or more than one correct options.")]), _c('li', [_vm._v(" On clicking the "), _c('b', [_vm._v("Next")]), _vm._v(" button, chosen answers for MCQ will be "), _c('b', [_vm._v("submitted automatically")]), _vm._v(". ")]), _c('li', [_vm._v(" If the "), _c('b', [_vm._v("Next")]), _vm._v(" button is clicked without selecting any options then the problem is considered as skipped. ")])])])]), _vm.isVideoEnabled ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "mb-4"
  }, [_c('strong', [_vm._v("Video and Audio Preview:")]), _vm._v(" Before you start the assessment, we will guide you through a quick video and audio preview to ensure that your camera and microphone are working properly. ")])]), _c('v-col', [_c('div', {
    staticClass: "mt-n8"
  }, [_c('v-container', {
    staticClass: "py-4"
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('v-card', {
    staticClass: "px-4 px-lg-8 py-8"
  }, [_c('Video', {
    ref: "videoComponent"
  })], 1)], 1)], 1)], 1)], 1)]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('strong', [_vm._v("Note:")]), _vm._v(" We will be recording both video and audio during this assessment for security and monitoring purposes. ")])], 1) : _vm._e(), _c('v-row', [_c('v-col', [_c('v-checkbox', {
    attrs: {
      "error-messages": _vm.TOSError,
      "rules": [function (v) {
        return !!v || 'You must agree to continue!';
      }]
    },
    on: {
      "change": _vm.validateTOS
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_vm.isVideoEnabled ? _c('div', [_vm._v(" I hereby give my consent to the recording, storage, and processing of my audio and video data for the purpose of conducting the assessment. ")]) : _vm._e(), _c('div', [_vm._v(" I have also read all the rules clearly and will follow them during the assessment. I will not use any means of cheating. If found cheating during the assessment, I understand that I will be banned from the CodeDrills platform. ")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.agreeToTOS,
      callback: function ($$v) {
        _vm.agreeToTOS = $$v;
      },
      expression: "agreeToTOS"
    }
  })], 1)], 1)], 1), _c('v-card-actions', {
    staticClass: "justify-center mt-8"
  }, [_c('v-btn', {
    staticClass: "white--text text-h6 pa-5 text-none",
    attrs: {
      "disabled": !_vm.agreeToTOS,
      "elevation": "0",
      "color": _vm.brandColor,
      "loading": _vm.startLoading
    },
    on: {
      "click": _vm.start
    }
  }, [_vm._v(" Start Assessment ")])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }