import moment from 'moment'
export function resolvePath(obj, path, separator = '.') {
  var properties = Array.isArray(path) ? path : path.split(separator)
  var value = properties.reduce((prev, curr) => prev && prev[curr], obj)
  return value
}

function prettyPart(part) {
  if (part < 10) return '0' + part
  else return part
}
export function prettyday(diff) {
  return diff > 72 * 60 * 60
    ? prettyPart(Math.floor(diff / (24 * 60 * 60)) + ' days')
    : prettyPart(
        Math.floor(diff / (60 * 60)) +
          ':' +
          prettyPart(Math.floor((diff % (60 * 60)) / 60)) +
          ':' +
          prettyPart(Math.floor(diff % 60)),
      )
}

export function prettytime(sec) {
  return (
    '' +
    prettyPart(Math.floor(sec / (60 * 60))) +
    ':' +
    prettyPart(Math.floor((sec % (60 * 60)) / 60)) +
    ':' +
    prettyPart(sec % 60)
  )
}

export function prettyduration(sec) {
  var duration = ''
  if (sec > 60 * 60 * 24) {
    duration += Math.floor(sec / (60 * 60 * 24)) + ' days '
    sec = sec % (60 * 60 * 24)
  }
  if (sec > 60 * 60) {
    duration += Math.floor(sec / (60 * 60)) + ' hrs '
    sec = sec % (60 * 60)
  }
  if (sec > 60) {
    duration += Math.floor(sec / 60) + ' mins '
    sec = sec % 60
  }
  if (sec > 0) {
    duration += sec + ' secs '
  }
  return duration.trim()
}

export function prettyPercent(num) {
  return (100 * num).toFixed(2).toString() + ' %'
}

export function isFullScreen() {
  return !(
    !document.fullscreenElement && // alternative standard method
    !document.mozFullScreenElement &&
    !document.webkitFullscreenElement &&
    !document.msFullscreenElement
  )
}

/* View in fullscreen */
export function openFullScreen() {
  var conf = confirm('Please enable fullscreen mode >_<')
  document.onclick = function (argument) {
    if (isFullScreen()) {
      return
    }
    var conf = confirm('Please enable fullscreen mode >_<')
    var docelem = document.documentElement

    if (conf == true) {
      if (docelem.requestFullscreen) {
        docelem.requestFullscreen()
      } else if (docelem.mozRequestFullScreen) {
        docelem.mozRequestFullScreen()
      } else if (docelem.webkitRequestFullScreen) {
        docelem.webkitRequestFullScreen()
      } else if (docelem.msRequestFullscreen) {
        docelem.msRequestFullscreen()
      }
    }
  }
}

export function closeFullscreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen()
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen()
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen()
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen()
  }
}

export function normalizeEmail(email) {
  return email.trim().toLowerCase()
}

export function buildKey(name, components) {
  const componentStrings = components.map((component) => String(component))
  const key = [name, ...componentStrings].join('-')
  console.log('key', key)
  return key
}

export function saveStringAsFile(content) {
  const uriContent = 'data:text/csv,' + encodeURIComponent(content)
  location.href = uriContent
}

export function formatDate(value) {
  if (value) {
    console.log('Date ...', value)
    return moment.unix(value).format('ddd HH:mm z,  D MMM YYYY')
  }
}

export function allowFutureDates(selection) {
  // yyyy-mm-dd is the internal rep of date in v-date-picker
  // allowed date should be greater than today's date
  const selectedDate = new Date(selection)
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  return selectedDate >= today
}

export function prettyScore(score) {
  console.log('Score', score)
  console.log('Score', parseFloat(score.toFixed(3)))
  return parseFloat(score.toFixed(3))
}

export function validateEmail(email) {
  var emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return emailRegex.test(email)
}

export function djb2Hash(str) {
  let hash = 5381
  for (let i = 0; i < str.length; i++) {
    hash = (hash * 33) ^ str.charCodeAt(i)
  }
  return hash
}

export function isPremium(skill) {
  console.log('Skill', skill.metaData?.tagsList)
  for (let tag of skill.metaData?.tagsList || [
    'skills/sample_assessment',
    'purpose/sample',
  ]) {
    if (tag === 'purpose/sample' || tag === 'skills/sample_assessment') {
      return false
    }
  }
  return true
}

export async function showPlanLimited(unlockComponent) {
  console.log('Unlocking component', unlockComponent)
  if (
    await unlockComponent.open(
      'To UNLOCK this feature, become a PREMIUM member today!',
    )
  ) {
    console.log('User has unlocked the feature')
  } else {
  }
}

const configToMonaco = {
  fontSize: {
    xs: 'small',
  },
  keyMap: {
    emacs: 'default',
    sublime: 'default',
  },
  theme: {
    idea: 'vs-light',
    dracula: 'vs-dark',
    'solarized light': 'vs-light',
    'solarized dark': 'vs-dark',
    default: 'vs-light',
  },
}

const configToCodeMirror = {
  theme: {
    'vs-dark': 'solarized dark',
    'vs-light': 'solarized light',
  },
}

export function mapEditorConfig(isMonacoEditor, editorConfig) {
  const mapper = isMonacoEditor ? configToMonaco : configToCodeMirror

  if (
    !editorConfig ||
    typeof editorConfig.getTheme !== 'function' ||
    typeof editorConfig.setTheme !== 'function' ||
    typeof editorConfig.getKeyMap !== 'function' ||
    typeof editorConfig.setKeyMap !== 'function' ||
    typeof editorConfig.getFontSize !== 'function' ||
    typeof editorConfig.setFontSize !== 'function' ||
    typeof editorConfig.getTabSize !== 'function' ||
    typeof editorConfig.setTabSize !== 'function' ||
    typeof editorConfig.getAutoComplete !== 'function' ||
    typeof editorConfig.setAutoComplete !== 'function'
  ) {
    return editorConfig
  }

  // Theme
  editorConfig.setTheme(
    (mapper.theme && mapper.theme[editorConfig.getTheme()]) ||
      editorConfig.getTheme(),
  )

  // Key Map
  editorConfig.setKeyMap(
    (mapper.keyMap && mapper.keyMap[editorConfig.getKeyMap()]) ||
      editorConfig.getKeyMap(),
  )

  // Font Size
  editorConfig.setFontSize(
    (mapper.fontSize && mapper.fontSize[editorConfig.getFontSize()]) ||
      editorConfig.getFontSize(),
  )

  // Tab Size
  editorConfig.setTabSize(
    (mapper.tabSize && mapper.tabSize[editorConfig.getTabSize()]) ||
      editorConfig.getTabSize(),
  )

  // Auto Complete
  editorConfig.setAutoComplete(
    (mapper.enableAutocomplete &&
      mapper.enableAutocomplete[editorConfig.getAutoComplete()]) ||
      editorConfig.getAutoComplete(),
  )

  return editorConfig
}
