;(function (mod) {
  mod(require('codemirror/lib/codemirror'))
})(function (CodeMirror) {
  'use strict'

  var WORD = /[\w$|#]+/,
    RANGE = 500

  CodeMirror.registerHelper('hint', 'cpp', function (editor, options) {
    var word = (options && options.word) || WORD
    var range = (options && options.range) || RANGE
    var cur = editor.getCursor(),
      curLine = editor.getLine(cur.line)
    var end = cur.ch,
      start = end
    while (start && word.test(curLine.charAt(start - 1))) --start
    var curWord = start != end && curLine.slice(start, end)
    var list = (options && options.list) || [],
      seen = {}
    var re = new RegExp(word.source, 'g')
    for (var dir = -1; dir <= 1; dir += 2) {
      var line = cur.line,
        endLine =
          Math.min(
            Math.max(line + dir * range, editor.firstLine()),
            editor.lastLine(),
          ) + dir
      for (; line != endLine; line += dir) {
        var text = editor.getLine(line),
          m
        while ((m = re.exec(text))) {
          if (line == cur.line && m[0] === curWord) continue
          if (
            (!curWord || m[0].lastIndexOf(curWord, 0) == 0) &&
            !Object.prototype.hasOwnProperty.call(seen, m[0])
          ) {
            seen[m[0]] = true
            list.push(m[0])
          }
        }
      }
    }
    var keywords = [
      'make_pair',
      'pair',
      'long long',
      'cin',
      'cout',
      'map',
      'set',
      'multiset',
      'vector',
      'string',
      'iostream',
      'int',
      'int32_t',
      'int64_t',
      'uint32_t',
      'uint64_t',
      'alignas',
      'decltype',
      'namespace',
      'struct',
      'alignof',
      'default',
      'new',
      'switch',
      'and',
      'delete',
      'noexcept',
      'template',
      'and_eq',
      'do',
      'not',
      'this',
      'asm',
      'double',
      'not_eq',
      'thread_local',
      'auto',
      'dynamic_cast',
      'nullptr',
      'throw',
      'bitand',
      'else',
      'operator',
      'true',
      'bitor',
      'enum',
      'or',
      'try',
      'bool',
      'explicit',
      'or_eq',
      'typedef',
      'break',
      'export',
      'private',
      'typeid',
      'case',
      'extern',
      'protected',
      'typename',
      'catch',
      'false',
      'public',
      'union',
      'char',
      'float',
      'register',
      'unsigned',
      'char16_t',
      'for',
      'reinterpret_cast',
      'using',
      'char32_t',
      'friend',
      'return',
      'virtual',
      'class',
      'goto',
      'short',
      'void',
      'compl',
      'if',
      'signed',
      'volatile',
      'const',
      'inline',
      'sizeof',
      'wchar_t',
      'constexpr',
      'int',
      'static',
      'while',
      'const_cast',
      'long',
      'static_assert',
      'xor',
      'continue',
      'mutable',
      'static_cast',
      'xor_eq',
      'asm',
      'bool',
      'catch',
      'class',
      'const_cast',
      'delete',
      'dynamic_cast',
      'explicit',
      'export',
      'false',
      'friend',
      'inline',
      'mutable',
      'namespace',
      'new',
      'operator',
      'private',
      'protected',
      'public',
      'reinterpret_cast',
      'static_cast',
      'template',
      'this',
      'throw',
      'true',
      'try',
      'typeid',
      'typename',
      'using',
      'virtual',
      'wchar_t',
      '#define',
      '#elif',
      '#else',
      '#endif',
      '#error',
      '#if',
      '#ifdef',
      '#ifndef',
      '#import',
      '#include',
      '#line',
      '#pragma',
      '#undef',
      '#using',
    ]
    for (let i = 0; i < keywords.length; i++) {
      if (!seen[keywords[i]] && keywords[i].startsWith(curWord)) {
        list.push(keywords[i])
        seen[keywords[i]] = true
      }
    }
    return {
      list: list,
      from: CodeMirror.Pos(cur.line, start),
      to: CodeMirror.Pos(cur.line, end),
    }
  })
})
