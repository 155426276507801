const WHITELABEL_CONFIG = {
  'icpc.codedrills.io': {
    // fallback
  },
  'kanpur.icpc.codedrills.io': {
    restrictLogin: true,
    enableRecruiter: false,
  },
  'amrita.icpc.codedrills.io': {
    restrictLogin: true,
    enableRecruiter: false,
  },
  'gwalior.icpc.codedrills.io': {
    restrictLogin: true,
    enableRecruiter: false,
  },
  'backup.icpc.codedrills.io': {
    restrictLogin: true,
    enableRecruiter: false,
  },
  default: {
    name: 'default',
    displayName: 'CodeDrills',
    communityLogo: require('@/assets/images/CodeDrillsLight.svg'),
    recruiterHomepageLogo: require('@/assets/images/recruiter/nav.png'),
    onlyLogo: require('@/assets/images/CodeDrills.svg'),
    dashboardLogo: require('@/assets/images/RecruiterDash.png'),
    profileLogo: require('@/assets/images/CodeDrillsLight.svg'),
    enableCommunity: true,
    enableRecruiter: true,
    enableContests: true,
    restrictLogin: false,
    fullLogs: false,
    showCalendar: true,
  },
}
export function getWhitelabelConfig() {
  var hostname = window.location.hostname
  // To test a particular config locally, use the following line
  //if (hostname === 'localhost') hostname = 'eval.curatal.com'
  var config = WHITELABEL_CONFIG[hostname]
  var defaultConfig = WHITELABEL_CONFIG['default']
  if (!config) {
    config = defaultConfig
  }
  // Merge default config with the config for the current domain
  for (var key in defaultConfig) {
    if (!(key in config)) {
      config[key] = defaultConfig[key]
    }
  }

  return config
}

export function getInviteLink(Suffix) {
  var hostname = window.location.hostname
  if (hostname === 'localhost') hostname = 'localhost:8080'
  var inviteLink = 'https://' + hostname + '/' + Suffix
  return inviteLink
}
