<script>
import { Pie, mixins } from 'vue-chartjs'
const { reactiveData } = mixins

export default {
  extends: Pie,
  mixins: [reactiveData],
  props: {
    data: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.renderChart(this.data, this.options)
  },
}
</script>
<style scoped></style>
