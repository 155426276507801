var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', [_c('v-card', {
    staticClass: "ml-5 mr-6"
  }, [_c('v-row', {
    staticClass: "pl-0 align-center",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": 8,
      "md": 2
    }
  }, [_c('v-select', {
    staticClass: "ml-0",
    attrs: {
      "items": _vm.languages,
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.language,
      callback: function ($$v) {
        _vm.language = $$v;
      },
      expression: "language"
    }
  })], 1), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": 1
    }
  }, [!_vm.copied ? _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.copyText
    }
  }, [_vm._v(" Copy ")]) : _vm._e()], 1)], 1), _c('v-divider'), _c('v-row', [_c('v-col', {
    staticClass: "py-0"
  }, [_c('codemirror', {
    ref: "myCm",
    class: _vm.fontSizeDisplay,
    attrs: {
      "value": _vm.value,
      "options": _vm.cmOptions
    },
    on: {
      "input": _vm.onCodeChange
    }
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }