<template>
  <v-card
    class="mx-auto center-cards px-1"
    height="100%"
    color="transparent"
    elevation="0"
  >
    <v-img
      :src="require('../../../assets/images/recruiter' + reason.image)"
      height="250px"
      width="250px"
      style="border-radius: 10px"
    ></v-img>

    <v-card-title class="justify-center text-body-1 title">
      {{ reason.title }}
    </v-card-title>
  </v-card>
</template>
<script>
export default {
  props: {
    reason: {
      type: Object,
    },
  },
}
</script>
<style scoped>
.title {
  word-break: normal;
}
.center-cards {
  display: grid;
  place-items: center;
}
</style>
