import site from 'codedrills_proto/io/codedrills/proto/site/content_view_service_grpc_web_pb'
import test from 'codedrills_proto/io/codedrills/proto/site/test_service_grpc_web_pb'
import user from 'codedrills_proto/io/codedrills/proto/user/user_service_grpc_web_pb'
import 'codedrills_proto/io/codedrills/proto/site/content_view_service_pb'
import 'codedrills_proto/io/codedrills/proto/site/test_service_pb'
import 'codedrills_proto/io/codedrills/proto/content/content_pb'
import { ApiCall } from '@/utils/api.js'

var site_proto = proto.io.codedrills.proto.site
var content_proto = proto.io.codedrills.proto.content
var user_proto = proto.io.codedrills.proto.user
const siteService = new site.ContentViewServicePromiseClient(
  process.env.VUE_APP_SITE_API_URL + '/site',
  null,
  null,
)
const testService = new test.TestServicePromiseClient(
  process.env.VUE_APP_SITE_API_URL + '/site',
  null,
  null,
)
const userService = new user.UserServicePromiseClient(
  process.env.VUE_APP_USER_API_URL + '/user',
  null,
  null,
)

const state = {
  problems: null,
  contests: null,
  testReportView: null,
  userPreview: null,
  listTestStatus: 0,
  listTestProblemStatus: 0,
  fetchTestReportStatus: 0,
}

const actions = {
  listTest: new ApiCall('listTest')
    .authRequired()
    .withServiceCall((r, h) => testService.listTest(r, h))
    .withRequest(() => {
      var req = new site_proto.ListTestRequest()
      return req
    })
    .onSuccess(({ commit }, res) => commit('setListTest', res))
    .build(),

  listTestProblem: new ApiCall('listTestProblem')
    .authRequired()
    .withServiceCall((r, h) => testService.listTestProblem(r, h))
    .withRequest(() => {
      var req = new site_proto.ListTestProblemRequest()
      return req
    })
    .onSuccess(({ commit }, res) => commit('setListTestProblem', res))
    .build(),

  fetchTestReport: new ApiCall('fetchTestReport')
    .authRequired()
    .withServiceCall((r, h) => testService.getTestReport(r, h))
    .withRequest(({ url, userId }) => {
      var req = new site_proto.GetTestReportRequest()
        .setContentId(
          new content_proto.ContentId().setContentAddress(
            new content_proto.ContentAddress()
              .setUrl(url)
              .setContentType(content_proto.ContentType.TEST),
          ),
        )
        .setUserId(userId)
      return req
    })
    .onSuccess(({ commit }, res) => commit('setTestResponseView', res))
    .build(),
}

const getters = {
  problems: (state) => {
    return state.problems
  },
  statusKeys() {
    var retStatus = {}
    for (var key in user_proto.ActivityStatus) {
      retStatus[user_proto.ActivityStatus[key]] = key
    }
    return retStatus
  },
  getContentType() {
    return content_proto.ContentType
  },
}

const mutations = {
  setListTest(state, res) {
    state.contests = res.getContentPreviewList()
  },
  setListTestProblem(state, res) {
    state.problems = res.getContentPreviewList()
  },
  listTestStatus(state, status) {
    state.listTestStatus = status
  },
  listTestProblemStatus(state, status) {
    state.listTestProblemStatus = status
  },
  fetchTestReportStatus(state, status) {
    state.fetchTestReportStatus = status
  },
  setTestResponseView(state, res) {
    state.testReportView = res.getTestReportView()
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
